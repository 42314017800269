export const REG_EXP_USER = /(https?:\/\/)?(www\.)?discord(app)?\.(gg|io|li|com)\/(users\/)(?<id>[0-9a-zA-Z_]{3,64})/i
export const REG_EXP = /(https?:\/\/)?(www\.)?discord(app)?\.(gg|io|li|com)\/(invite\/)?(?<id>[0-9a-zA-Z_]{3,64})/i
export const RE_INVITE_EXTRACT = /^(?:https?:\/\/)?(?:www\.)?(?:discord\.(?:gg|io|me|li)|(?:discord(?:app)?)\.com\/invite)\/(?<id>[0-9a-zA-Z_]{3,64})/i

export const parse = (value, REGEX) => {
  try {
    return value.match(REGEX)?.groups
  } catch (error) {
    console.debug(error)
  }
}

export const group = value => {
  try {
    return parse(value, REG_EXP)?.id
  } catch (error) {
    console.debug(error)
  }
}

export const user = value => {
  try {
    return parse(value, REG_EXP_USER)?.id
  } catch (error) {
    console.debug(error)
  }
}

export const invite = value => {
  try {
    return parse(value, RE_INVITE_EXTRACT)?.id
  } catch (error) {
    console.debug(error)
  }
}

export const publicFlags = {
  22 : 'ACTIVE_DEVELOPER',
  19 : 'BOT_HTTP_INTERACTIONS',
  18 : 'CERTIFIED_MODERATOR',
  17 : 'VERIFIED_DEVELOPER',
  16: 'VERIFIED_BOT',
  14: 'BUG_HUNTER_LEVEL_2',
  12: 'SYSTEM',
  10: 'TEAM_PSEUDO_USER',
  9: 'PREMIUM_EARLY_SUPPORTER',
  8: 'HYPESQUAD_ONLINE_HOUSE_3',
  7: 'HYPESQUAD_ONLINE_HOUSE_2',
  6: 'HYPESQUAD_ONLINE_HOUSE_1',
  3: 'BUG_HUNTER_LEVEL_1',
  2: 'HYPESQUAD',
  1: 'PARTNER',
  0: 'STAFF'
}

export const getFlags = (value) => {
  const output = []
  for (let bit = 0; bit < 24; bit++) {
    if (!!(value & (1 << bit)) && bit in publicFlags) {
      output.push(publicFlags[bit])
    }
  }
  return output
}