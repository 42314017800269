import router from '@/router'
import config from '@/config'
import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'

const {
  servers: { hoop }
} = config

const state = () => ({
  tasks: {},
  users: {},
  messages: {},
  channels: {},
  hashtags: {},
  stats: {},
  listChannels: {},
  userStats: {},
  searchResults: [],
  isLoaded: {},
  translatedCache: new Map(),
  cancelSources: {}
})

const getters = {
  allTasks: (state) => state.tasks,
  errors: state => state.errors,
  allUsers: state => state.users,
  allHashtags: state => state.hashtags,
  allListChannels: state => state.listChannels,
  channels: state => state.channels,
  userStats: state => state.userStats,
  isLoaded: state => state.isLoaded,
  stats: state => state.stats,
  allMessages: state => state.messages,
  allListTasks: state => state.listTasks
}

const mutations = {
  deleteTask: (state, taskId) => {
    delete state.tasks[taskId]
  },
  fetchTasksToView: (state, { taskId, page }) => {
    if (taskId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
    else state.pagination.nextPage = false
    state.listTasks[page] = taskId
  },
  fetchAllTasks (state, tasks) {
    tasks.map(task => {
      if (!state.tasks[task.id]) state.tasks[task.id] = task
    })
  },
  fetchTaskChats (state, { chats, id }) {
    if (!chats) return
    state.tasks[id].chats = chats
  },
  getChatHistorical: (state, { id, data }) => {
    if (!state.channels[id]) return
    state.channels[id].historical = data
  },
  setCancel (state, { prop, source }) {
    state.cancelSources[prop] = source
  },
  unsetCancel (state, { prop }) {
    delete state.cancelSources[prop]
  },
  startLoad: (state, payload) => {
    state.isLoaded[payload] = false
  },
  stopLoad: (state, payload) => {
    state.isLoaded[payload] = true
  },
  addUserData: (state, payload) => {
    if (!payload.id) return
    state.users[payload.id] = payload
  },
  addMessageData: (state, payload) => {
    if (!payload.id) return
    state.messages[payload.id] = payload
  },
  addUserDataChatsActivity (state, payload) {
    if (!payload.user_id) return
    state.users[payload.user_id].chats_activity = payload
  },
  fetchHashtagActivityById (state, payload) {
    if (!payload.hashtag_id) return
    if (!state.hashtags[payload.hashtag_id].activity) state.hashtags[payload.hashtag_id].activity = {}
    state.hashtags[payload.hashtag_id].activity[payload.key] = payload
  },
  fetchMessageLink (state, payload) {
    if (!payload.message_id) return
    state.messages[payload.message_id].pagination = payload
  },
  addUserDataActivity (state, payload) {
    if (!payload.user_id) return
    state.users[payload.user_id]['activity'][payload.key] = payload
  },
  addUserDataStats (state, payload) {
    if (!payload.user_id) return
    state.userStats[payload.user_id] = payload
  },
  addChannelData: (state, payload) => {
    if (!payload.id) return
    state.channels[payload.id] = payload
  },
  addChannelDataActivity (state, payload) {
    if (!payload.id) return
    state.channels[payload.id]['activity'][payload.key] = payload
  },
  addChannelDataStats (state, payload) {
    if (!payload.id) return
    state.channels[payload.id].stats = payload
  },
  fetchAllUsers (state, users) {
    if (!users) return
    users = users.map(user => {
      user.activity = {}
      state.users[user.id] = user
    })
  },
  listUsers (state, users) {
    const page = ++Object.keys(state.listUsers).length
    const usersId = users.map(user => user.id)
    state.listUsers[page] = usersId
  },
  addHiddenParamToQuery: (state, param) => Object.assign(state.queryParams, param),
  setPage: (state, page) => {
    state.pagination.currentPage = page
  },
  setPageWithType: (state, page) => {
    state.pagination[page.type] = page.page
  },
  clearUsers: state => {
    // @ts-ignore
    delete state.users
    state.users = {}
  },
  fetchAllMessages: (state, messages) => {
    messages.map(message => {
      if (message.photos) {
        for (var i in message.photos) {
          if (message.photos[i].file_location) {
            message.photos[i].file_location = config.servers.hoop + message.photos[i].file_location
          }
        }
      }
      if (message.documents) {
        for (var j in message.documents) {
          if (message.documents[j].file_location) {
            message.documents[j].file_location = config.servers.hoop + message.documents[j].file_location
          }
        }
      }
      if (!state.messages[message.id]) state.messages[message.id] = message
    })
  },
  fetchAllChannels: (state, channels) => {
    channels.map(channel => {
      if (!state.listChannels[channel.id]) state.listChannels[channel.id] = channel
    })
  },
  clearMessages: state => {
    // @ts-ignore
    delete state.messages
    state.messages = {}
  },
  updateStats: (state, stats) => {
    state.stats = stats
  },
  fetchAllHashtags: (state, hashtags) => {
    hashtags.map(hashtag => {
      if (!state.hashtags[hashtag.id])
        state.hashtags[hashtag.id] = hashtag
    })
  },
  fetchHashtag: (state, hashtag) => {
    state.hashtags[hashtag.id] = hashtag
  }
}
const actions = {
  deleteTask ({ commit, dispatch, rootState }, taskId) {
    return apiRequest(`/tasks/` + taskId, 'DELETE', {}, hoop).then(() => {
      commit('deleteTask', taskId)
    })
  },
  async addTask ({ commit }, task) {
    try {
      return await apiRequest(`/tasks`, 'POST', {}, hoop, task)
    } catch (err) {
      if (err.response) {
        switch (err.response && err.response.status) {
          case 400:
            notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
            break
          default:
            notify({ group: 'general', title: 'Service unavailable', type: 'error' })
            break
        }
      }
      throw err
    }
  },
  async editTask ({ commit }, { id, task }) {
    try {
      const { status, data } = await apiRequest(`/tasks/${id}`, 'PUT', {}, hoop, task)
      switch (status) {
        case 200:
          notify({ group: 'general', text: 'Updated', type: 'success' })
          break
      }
      return data
    } catch (err) {
      if (err.response) {
        switch (err.response && err.response.status) {
          case 400:
            notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
            break
          default:
            notify({ group: 'general', title: 'Service unavailable', type: 'error' })
            break
        }
      }
      throw err
    }
  },
  async massTaskAdd ({ commit, dispatch, rootState }, { tasks, needAddToCase }) {
    try {
      const { status, data } = await apiRequest(`/tasks/batch`, 'POST', {}, hoop, tasks)
      switch (status) {
        case 200:
          notify({ group: 'general', text: 'Created', type: 'success' })
          break
      }
      return data
    } catch (err) {
      if (err.response) {
        switch (err.response && err.response.status) {
          case 400:
            notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
            break
          default:
            notify({ group: 'general', title: 'Service unavailable', type: 'error' })
            break
        }
      }
      throw err
    }
  },
  fetchTaskById (_, id) {
    return apiRequest(`/tasks/${id}`, 'GET', {}, hoop)
  },
  fetchTasksToView ({ dispatch, commit, state }, tasks) {
    return new Promise((resolve, reject) => {
      let offsetPassed = router.currentRoute.value.query?.offset ?? 0
      let page = 0
      if (offsetPassed) {
        page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
      } else page = ++Object.keys(state.listTasks).length
      if (Number(offsetPassed) === 0) page = 1
      const taskId = tasks.map(task => task.id)
      commit('fetchTasksToView', { taskId, page })
      resolve()
    })
  },
  async fetchAllTasks ({ commit }, query) {
    try {
      const queryString = getQueryString(query)
      const { data } = await apiRequest(`/tasks/?${queryString}`, 'GET', {}, hoop)
      commit('fetchAllTasks', data.data)
      return data
    } catch (err) {
      if (err.response) {
        switch (err.response && err.response.status) {
          case 400:
            notify({ group: 'twitter_tasks', title: 'Parameter validation fails', type: 'error' })
            break
          default:
            notify({ group: 'twitter_tasks', title: 'Service unavailable', type: 'error' })
            break
        }
      }
      throw err
    }
  },
  fetchTaskChats ({ dispatch, commit, state, rootState }, id) {
    commit('startLoad', 'taskChats_' + id)
    apiRequest(`/v1/tasks/${id}/chats`, 'GET')
      .then(data => {
        const chatTasks = data.data
        commit('fetchTaskChats', { chats: chatTasks, id: id })
      }).finally(() => {
        commit('stopLoad', 'taskChats_' + id)
      })
  },
  fetchForceUserById ({ commit, state, dispatch, rootState }, id) {
    commit('startLoad', 'user_' + id)
    commit('startLoad', 'user_' + id + '_stats')
    return new Promise((resolve, reject) => {
      apiRequest('/users/' + id, 'GET', {}, hoop)
        .then(data => {
          var userData = data.data
          userData.activity = {}
          userData.answer_status = 'FOUND'
          commit('addUserData', userData)
          resolve(userData)
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            setTimeout(function () {
              dispatch('fetchUserById', id)
            }, 5000)
          }
          reject(err)
        })
        .finally(() => {
          commit('stopLoad', 'user_' + id)
        })
    })
  },
  fetchForceUserStatsById ({ commit, state, dispatch, rootState }, id) {
    commit('startLoad', 'user_' + id + '_stats')
    apiRequest(`/users/${id}/stats`, 'GET', {}, hoop)
      .then(data => {
        var userData = data.data
        if (userData) commit('addUserDataStats', userData)
      })
      .finally(() => {
        commit('stopLoad', `user_${id}_stats`)
      })
  },
  async getChatHistorical ({ commit, state }, id) {
    const { data } = await apiRequest(`/channels/${id}/history`, 'GET', {}, hoop)
    commit('getChatHistorical', { id, data })
    return data
  },
  async fetchTagChannels ({ commit, state, dispatch, rootState }, id) {
    try {
      const { data } = await apiRequest('/tags/' + id + '/channels/', 'GET', {}, hoop)
      return data
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  async fetchMessageById ({ commit, state, dispatch, rootState }, id) {
    try {
      const { data } = await apiRequest('/messages/' + id, 'GET', {}, hoop)
      return data
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  fetchChannelActivityById ({ commit, dispatch, state, rootState }, obj) {
    var id = obj[0]
    var type = obj[1]
    var query = getQueryString(obj[2])
    commit('startLoad', `channel_${id}_activity_${type}`)
    apiRequest(`/channels/${id}/activity/${type}?${query}`, 'GET', {}, hoop)
      .then(data => {
        var userData = data.data
        userData.id = id
        userData.key = type
        commit('addChannelDataActivity', userData)
      })
      .catch(err => {
        err['response'].callAgain = { name: 'hoop/fetchChannelActivityById', argument: obj }
        dispatch('checkError', err)
      })
      .finally(() => {
        commit('stopLoad', `channel_${id}_activity_${type}`)
      })
  },
  fetchUserChannels ({ dispatch, commit, state, rootState }, query) {
    return function (id) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'channels')
        const localQuery = { ...query }
        if (localQuery.search_fields && Array.isArray(localQuery.search_fields)) localQuery.search_fields = localQuery.search_fields.join('&search_fields=')
        const queryString = getQueryString(localQuery)
        apiRequest(`/users/${id}/channels/?${queryString}`, 'GET', {}, hoop)
          .then(data => {
            var chats = data.data.data
            chats.forEach((e, i, chats) => {
              chats[i].activity = {}
              chats[i].audience = {}
              if (chats[i].avatar && chats[i].avatar.file_location) chats[i].avatar.file_location = config.servers.hoop_static + chats[i].avatar.file_location
              chats[i].answer_status = 'FOUND'
              chats[i].stats = {}
            })
            commit('fetchAllChannels', chats)
            commit('stopLoad', `channels`)
            resolve({ ...data.data, data: chats })
          })
          .catch(err => {
            console.debug(err)
            if (err.response.status === 404) console.error(err)
            reject(err)
          })
      })
    }
  },
  fetchAllChannels ({ dispatch, commit, state, rootState }, query) {
    return new Promise((resolve, reject) => {
      commit('startLoad', 'channels')
      const localQuery = { ...query }

      if (localQuery.search) {
        localQuery.search = unicode.normalize(localQuery.search)
      }

      if (localQuery.search_fields && Array.isArray(localQuery.search_fields)) localQuery.search_fields = localQuery.search_fields.join('&search_fields=')
      const queryString = getQueryString(localQuery)
      apiRequest(`/channels/?${queryString}`, 'GET', {}, hoop)
        .then(data => {
          var chats = data.data.data
          chats.forEach((e, i, chats) => {
            chats[i].activity = {}
            chats[i].audience = {}
            if (chats[i].avatar && chats[i].avatar.file_location) chats[i].avatar.file_location = config.servers.hoop_static + chats[i].avatar.file_location
            chats[i].answer_status = 'FOUND'
            chats[i].stats = {}
          })
          commit('fetchAllChannels', chats)
          commit('stopLoad', `channels`)
          resolve({ ...data.data, data: chats })
        })
        .catch(err => {
          console.debug(err)
          if (err.response.status === 404) console.error(err)
          reject(err)
        })
    })
  },
  checkError ({ commit, dispatch }, err) {
    if (err.response && err.response.status === 401) {
      if (err.response.data.code === 'TOKEN_EXPIRED') {
        dispatch('refresh', null, { root: true }).then(() => {
          if (err.response.callAgain) {
            // dispatch(err.response.callAgain.name, err.response.callAgain.argument, { root: true })
          }
        })
      }
    }
  },
  fetchStats ({ commit, state, dispatch }) {
    if (!state.stats.hasOwnProperty('messages_count')) {
      commit('startLoad', `stats`)
    }
    apiRequest(`/stats`, 'GET', {}, hoop)
      .then(data => {
        commit('updateStats', data.data)
      })
      .catch(err => {
        err.response.callAgain = { name: 'hoop/fetchStats', argument: null }
        dispatch('checkError', err)
        if (err.response && err.response.status === 404) console.error(err)
      })
      .finally(() => {
        commit('stopLoad', `stats`)
      })
  },
  fetchMessageLink ({ commit, state, rootState }, { messageId, chatId }) {
    return new Promise((resolve, reject) => {
      apiRequest(`/channels/${chatId}/${messageId}/offset?with_comments=true&order_by=cdate&order_type=desc`, 'GET', {}, hoop)
        .then(data => {
          let offset = data.data
          const limit = 20
          const page = Math.floor(offset / (limit - 1) + 1)
          offset = (limit - 1) * page - (limit - 1)
          resolve({ id: chatId, message_id: messageId, message_pagination: { page, offset }, tab: 'messages' })
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  fetchUserChatsActivityById ({ commit, state, dispatch, rootState }, id) {
    commit('startLoad', `user_${id}_ca`)
    apiRequest(`/users/${id}/top/channels`, 'GET', {}, hoop)
      .then(data => {
        var userData = data.data
        userData.user_id = id
        commit('addUserDataChatsActivity', userData)
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
        }
      })
      .finally(() => {
        commit('stopLoad', `user_${id}_ca`)
      })
  },
  fetchUserActivityById ({ commit, state, dispatch, rootState }, obj) {
    var id = obj[0]
    var type = obj[1]
    commit('startLoad', `user_${id}_activity_${type}`)
    apiRequest(`/users/${id}/activity/${type}`, 'GET', {}, hoop)
      .then(data => {
        var userData = data.data
        userData.user_id = id
        userData.key = type
        commit('addUserDataActivity', userData)
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
        }
      })
      .finally(() => {
        commit('stopLoad', `user_${id}_activity_${type}`)
      })
  },
  async fetchHashtagActivityById ({ commit, dispatch, state }, { id, type }) {
    commit('startLoad', `hashtag_${id}_activity_${type}`)
    if (!state.hashtags[id]) {
      try {
        await dispatch('fetchHashtagById', id)
      } catch (err) {
        throw new Error(`Can't fetch tag`)
      }
    }
    try {
      var res = await apiRequest(`/hashtags/${id}/activity/${type}`, 'GET', {}, hoop)
      var tagData = res.data
      tagData.hashtag_id = id
      tagData.key = type
    } catch (err) {
      throw err
    } finally {
      commit('stopLoad', `hashtag_${id}_activity_${type}`)
    }
    commit('fetchHashtagActivityById', tagData)
    return res.data.activity
  },
  fetchUserById ({ commit, state, dispatch, rootState }, id) {
    if (state.users[id]) {
      commit('stopLoad', 'user_' + id)
      return
    }
    dispatch('fetchForceUserById', id)
  },
  async fetchAllHashtags ({ commit, state, dispatch }, query) {
    const queryString = getQueryString(query)
    let cancelled = false
    const prop = 'hashtags'
    commit('startLoad', prop)
    try {
      const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
      const prop = 'users_hashtag'
      const cancellation = getCancellation({ prop, commit, state })
      const { data } = await apiRequest('/tags?' + queryString, 'GET', {}, hoop, undefined, cancellation)
      commit('fetchAllHashtags', data.data)
      return data
    } catch (err) {
      if (err.__CANCEL__) cancelled = true
      throw err
    } finally {
      !cancelled && commit('stopLoad', prop)
    }
  },
  async fetchUsersByHashtag ({ commit, state }, { id, query, isExport }) {
    const queryString = getQueryString(query)
    let cancelled = false
    const prop = 'usershashtag'
    if (!isExport) commit('startLoad', prop)
    try {
      const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
      const cancellation = getCancellation({ prop, commit, state })
      const {
        data: { data: users, meta }
      } = await apiRequest(`/channels/${id}/users?` + queryString, 'GET', {}, hoop, undefined, cancellation)
      if (!isExport) commit('fetchAllUsers', users)
      return { users, meta }
    } catch (err) {
      if (err.__CANCEL__) cancelled = true
      throw err
    } finally {
      !cancelled && !isExport && commit('stopLoad', prop)
    }
  },
  fetchHashtagById ({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      commit('startLoad', 'hashtag_' + id)
      if (state.hashtags[id]) {
        commit('stopLoad', 'hashtag_' + id)
        return resolve(state.hashtags[id])
      } else {
        apiRequest('/tags/' + id, 'GET', {}, hoop)
          .then(res => {
            var hashtag = res.data
            commit('fetchHashtag', hashtag)
            resolve(hashtag)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'hashtag_' + id))
      }
    })
  },
  fetchUserStatsById ({ commit, state, dispatch, rootState }, id) {
    if (state.users[id] && state.users[id].stats) return
    dispatch('fetchForceUserStatsById', id)
  },
  async fetchAllUsers ({ dispatch, commit, state, rootState }, query) {
    const { isExport } = query
    if (isExport) delete query.isExport

    if (query.search) {
      query.search = unicode.normalize(query.search)
    }

    const queryString = query ? '?' + getQueryString(query) : ''
    let cancelled = false
    const prop = 'users'
    if (!isExport) commit('startLoad', prop)
    try {
      const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
      const cancellation = getCancellation({ prop, commit, state })
      const {
        data: { data: users, meta }
      } = await apiRequest(`/users/${queryString}`, 'GET', {}, hoop, undefined, cancellation)
      try {
        const { data: countries } = await apiRequest(`users/countries`, 'GET', {}, hoop)
        meta.countries = countries
      } catch {}
      if (!isExport) commit('fetchAllUsers', users)
      return { users, meta }
    } catch (err) {
      console.debug(err)
      if (err.response && err.response.status === 404) console.error(err)
      if (err.__CANCEL__) cancelled = true
      throw err
    } finally {
      !cancelled && !isExport && commit('stopLoad', prop)
    }
  },
  fetchChannelById ({ commit, state, dispatch, rootState }, id) {
    if (state.channels[id]) {
      commit('stopLoad', 'channel_' + id)
      return
    }
    commit('startLoad', 'channel_' + id)
    commit('startLoad', 'channel_' + id + '_stats')
    apiRequest('/channels/' + id, 'GET', {}, hoop)
      .then(data => {
        var userData = data.data
        userData.activity = {}
        userData.avatar_src = ''
        userData.answer_status = 'FOUND'
        commit('addChannelData', userData)
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          commit('addChannelData', { id: id, answer_status: 'NOT_FOUND' })
        }
      })
      .finally(() => {
        commit('stopLoad', 'channel_' + id)
      })
  },
  fetchChannelStatsById ({ commit, state, dispatch, rootState }, id) {
    if (state.channels[id] && state.channels[id].stats) {
      commit('stopLoad', `channel_${id}_stats`)
      return
    }
    commit('startLoad', 'channel_' + id + '_stats')
    apiRequest(`/v1/chats/${id}/stats`, 'GET')
      .then(data => {
        var userData = data.data
        userData.id = id
        commit('addChannelDataStats', userData)
      })
      .catch(err => {
        err.response.callAgain = { name: 'hoop/fetchChannelStatsById', argument: id }
        dispatch('checkError', err)
      })
      .finally(() => {
        commit('stopLoad', `channel_${id}_stats`)
      })
  },
  addParamToQuery ({ commit }, param) {
    router.replace({ query: param })
  },
  addHiddenParamToQuery ({ commit }, param) {
    commit('addHiddenParamToQuery', param)
  },
  setPage ({ commit }, page) {
    commit('setPage', page)
  },
  setPageWithType ({ commit }, page) {
    commit('setPageWithType', page)
  },
  async fetchHashtagMessages ({ commit, state }, { id, query, isExport }) {
    if (!id) throw new Error('id required')
    const queryString = getQueryString(query)
    let cancelled = false
    const prop = 'messages_hashtag_' + id
    if (!isExport) commit('startLoad', prop)
    try {
      const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
      const cancellation = getCancellation({ prop, commit, state })
      const {
        data: { data: messages, meta }
      } = await apiRequest(`/hashtags/${id}/messages?${queryString}`, 'GET', {}, hoop, undefined, cancellation)
      if (!isExport) commit('fetchAllMessages', messages)
      return { messages, meta }
    } catch (err) {
      if (err.__CANCEL__) cancelled = true
      throw err
    } finally {
      !cancelled && !isExport && commit('stopLoad', prop)
    }
  },
  async fetchUserMessages ({ commit, state }, params) {
    const { isExport } = params
    const id = params.id
    if (!id) throw new Error('id required')
    const queryString = getQueryString(params.query)
    let cancelled = false
    const prop = 'messages_user_' + id
    if (!isExport) commit('startLoad', prop)
    try {
      const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
      const cancellation = getCancellation({ prop, commit, state })
      const {
        data: { data: messages, meta }
      } = await apiRequest(`/users/${id}/messages/?${queryString}`, 'GET', {}, hoop, undefined, cancellation)
      if (!isExport) commit('fetchAllMessages', messages)
      return { messages, meta }
    } catch (err) {
      if (err.__CANCEL__) cancelled = true
      throw err
    } finally {
      !cancelled && !isExport && commit('stopLoad', prop)
    }
  },
  async fetchAllMessages ({ dispatch, commit, state, rootState }, query) {
    const { isExport } = query
    if (isExport) delete query.isExport

    if (query.search) {
      query.search = unicode.normalize(query.search)
    }

    const queryString = getQueryString(query)
    let cancelled = false
    const prop = 'messages'
    if (!isExport) commit('startLoad', prop)
    try {
      const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
      const cancellation = getCancellation({ prop, commit, state })
      const {
        data: { data: messages, meta }
      } = await apiRequest(`/messages/?with_comments=true&${queryString}`, 'GET', {}, hoop, undefined, cancellation)
      if (!isExport) commit('fetchAllMessages', messages)
      return { messages, meta }
    } catch (err) {
      console.error(err)
      if (err.response) {
        err.response.callAgain = {
          name: 'hoop/fetchAllMessages',
          argument: null
        }
        dispatch('checkError', err)
        if (err.response && err.response.status === 404) console.error(err)
      }
      if (err.__CANCEL__) cancelled = true
      throw err
    } finally {
      !cancelled && !isExport && commit('stopLoad', prop)
    }
  },
  translateText: async ({ state, rootState }, { text }) => {
    const translateTo = rootState.settings.translate
    try {
      const translatedCacheKey = JSON.stringify({
        translateTo,
        text
      })
      const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
      if (!hasTranslatedCache) {
        const {
          data: { translation }
        } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
          lang: translateTo,
          text: text
        })
        state.translatedCache.set(translatedCacheKey, { data: { translation } })
        return {
          data: {
            translation
          }
        }
      }
      return state.translatedCache.get(translatedCacheKey)
    } catch (error) {
      console.debug('[Translate/catch]', error)
    }
  },
  clearMessages ({ commit }) {
    commit('clearMessages')
  },
  clearUsers ({ commit }) {
    commit('clearUsers')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
