<template>
  <button
    :disabled="!text"
    class="uk-button-text uk-button translate-btn"
    :uk-tooltip="textRender"
    :class="{
      'translate-btn__loading': loading,
      'translate-btn__translated': translatedShown,
      'translate-btn__disabled': false
    }"
    @click="translate"
  >
    <font-awesome-icon
      :icon="['fa', 'language']"
      class="translate-btn__icon"
    />
    <span
      v-if="!reduced"
      class="translate-btn__text"
    >
      {{ textRender }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'Translate',
  props: {
    text: {
      required: true,
      type: String
    },
    translatedShown: null,
    translatedText: null,
    reduced: {
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    textRender () {
      return this.translatedShown ? this.$t('tgcp.messages.show-original') : this.$t('tgcp.messages.translate')
    }
  },
  methods: {
    async translate () {
      if (this.loading || !this.text) return
      if (this.translatedText) return this.$emit('translatedShown', !this.translatedShown)
      const translateTo = this.$store.state.settings.translate
      const { text } = this
      try {
        this.loading = true
        this.$emit('loading', true)
        const { apiRequest } = await import(/* webpackChunkName: "apiRequest" */ '@/utils/api')
        const { default: config } = await import(/* webpackChunkName: "config" */ '@/config')
        const {
          data: { translation }
        } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, { lang: translateTo, text })
        this.$emit('translatedText', translation) // this.translatedText = data.translation
        this.$emit('translatedShown', true)
      } catch {
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.translate-btn {
  transition: 0.2s;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }

  &__translated {
    min-width: max-content;
    svg {
      color: green;
    }
  }

  &__disabled,
  &__loading {
    opacity: 0.5;
  }

  &__disabled {
    cursor: not-allowed;
    &:hover,
    &:active {
      transform: none;
    }
  }
  &__text {
    margin-left: .3em;
  }
}
</style>
