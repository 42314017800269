import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import PageNotFound from './views/404'
import Channels from './views/TGCP/Channels.vue'
import Stats from './views/TGCP/Stats.vue'
import TwitterStats from './views/Twitter/Stats.vue'
import HoopStats from './views/Hoop/Stats.vue'
import Settings from './views/Settings.vue'
import TGCP from './views/TGCP.vue'
import Twitter from './views/Twitter.vue'
import Forums from './views/Forums.vue'
import Help from './views/Help'
import Threema from './views/Threema'
import Zalo from './views/Zalo'
import Whatsapp from './views/Whatsapp'
import WhatsappUserlist from './views/WhatsApp/WhatsappUserlist'
import WhatsappGroupslist from './views/WhatsApp/WhatsappGroupsList'
import WhatsappMessagelist from './views/WhatsApp/WhatsappMessagelist'
import WhatsappGroupDetails from './views/WhatsApp/GroupDetails'
import WhatsappUserDetails from './views/WhatsApp/UserDetails'
import WhatsappStats from './views/WhatsApp/Stats'
import WhatsappTasks from './views/WhatsApp/Tasks'
import Hoop from './views/Hoop'
import Changelog from './views/Changelog'
import ChannelList from './views/TGCP/ChannelList.vue'
import MessageList from './views/TGCP/MessageList.vue'
import UserDetails from './views/TGCP/UserDetails.vue'
import UserDetailsOverview from './views/TGCP/UserDetailsOverview.vue'
import UserDetailsAccounts from './views/TGCP/UserDetailsAccounts.vue'
import UserDetailsChannels from './views/TGCP/UserDetailsChannels.vue'
import UserDetailsPastChannels from './views/TGCP/UserDetailsPastChannels.vue'
import UserDetailsAdmin from './views/TGCP/UserDetailsAdmin.vue'
import UserDetailsPastAdmin from './views/TGCP/UserDetailsPastAdmin.vue'
import UserDetailsOwner from './views/TGCP/UserDetailsOwner.vue'
import UserDetailsMessages from './views/TGCP/UserDetailsMessages.vue'
import UserDetailsMediaMessages from './views/TGCP/UserDetailsMediaMessages.vue'
import UserDetailsReplies from './views/TGCP/UserDetailsReplies.vue'
import UserDetailsEntities from './views/TGCP/UserDetailsEntities.vue'
import ChannelDetails from './views/TGCP/ChannelDetails.vue'
import UserList from './views/TGCP/UserList.vue'
import TwitterUserList from './views/Twitter/UserList.vue'
import HoopUserList from './views/Hoop/UserList'
import TwitterMessagesList from './views/Twitter/MessageList.vue'
import HoopMessageList from './views/Hoop/MessageList.vue'
import ZaloUserList from './views/Zalo/UserList.vue'
import Tasks from './views/TGCP/Tasks.vue'
import TasksAll from './views/TGCP/TasksAll.vue'
import TasksBots from './views/TGCP/TasksBots.vue'
import TasksGeoClusters from './views/TGCP/TasksGeoClusters.vue'
import TasksScheduledSearch from './views/TGCP/TasksScheduledSearch.vue'
import Login from './components/Login.vue'
import { store } from '@/store'
import FaceSearch from '@/views/Osint/FaceSearch.vue'
import SimilarSearch from '@/views/Osint/SimilarSearch.vue'
import Nicknames from './views/GlobalSearch/Nicknames'
import Phones from './views/GlobalSearch/Phones'
import ThreemaPhones from './views/Threema/Search/Phones'
import ThreemaEmails from './views/Threema/Search/Emails'
import ThreemaNicknames from './views/Threema/Search/Nicknames'
import Emails from './views/GlobalSearch/Emails'
import Domains from './views/GlobalSearch/Domains'
import IPs from './views/IPs'
import NotAvailable from './views/NotAvailable'
import UsageStats from './views/UsageStats'
import AdsStats from './views/AdsStats'
import Organizations from './views/Organizations'
import Crypto from './views/Crypto'
import MessageReposts from './views/TGCP/MessageReposts'
import TwitterUserDetails from './views/Twitter/UserDetails.vue'
import HoopUserDetails from './views/Hoop/UserDetails'
import TwitterTasks from './views/Twitter/Tasks'
import HoopTasks from './views/Hoop/Tasks'
import Cases from './views/Cases'
import CaseDetails from './components/cases/CaseDetails.vue'
import CaseImport from './components/cases/CaseImport.vue'
import IpMsisdnDetect from './views/Access/IpMsisdnDetect'
import AccessLanding from './views/Access/Landing'
import OsintLanding from './views/Osint/Landing'
import ArchivedCases from './views/ArchivedCases.vue'
import ArchivedCaseDetails from './components/cases/ArchivedCaseDetails.vue'
import ZaloUserDetails from './views/Zalo/UserDetails.vue'
import Map from './components/map/Map.vue'
import MapEditable from './components/map/MapEditable.vue'
import config from '@/config'
import ForumsUserList from './views/Forums/UserList.vue'
import ForumsList from './views/Forums/ForumsList.vue'
import TopicsList from './views/Forums/TopicsList.vue'
import ForumMessageList from './views/Forums/MessageList.vue'
import ForumPlacementsList from './views/Forums/PlacementsList.vue'
import ForumsForumDetails from './views/Forums/ForumDetails.vue'
import ForumsUserDetails from './views/Forums/UserDetails.vue'
import ForumsTopicDetails from './views/Forums/TopicDetails.vue'
import ForumsGoodsList from './views/Forums/GoodsList.vue'
import CompanyDetails from './views/DataBases/CompanyDetails'
import ForumsStats from './views/Forums/Stats.vue'
import CaseTriggerDetails from './views/CaseTriggerDetails.vue'
import WebSources from './views/WebSources.vue'
import CaseStats from './components/cases/CaseStats'
import TwitterHashtagList from './views/Twitter/HashtagList'
import TwitterHashtagDetails from './views/Twitter/HashtagDetails'
import HoopHashtagList from './views/Hoop/HashtagList'
import HoopHashtagDetails from './views/Hoop/HashtagDetails'
import HoopChannelList from './views/Hoop/ChannelList'
import HoopChannelDetails from './views/Hoop/ChannelDetails'
import BoardsList from './views/Boards/BoardList'
import Boards from './views/Boards'
import TGCPMap from './views/TGCP/Map'
import NewUsers from './views/TGCP/NewUsers'
import ADSList from './views/Boards/ADSList'
import ADSDetail from './components/Boards/ADSDetail'
import OrganizationsSearch from './views/DataBases/Organizations/Search'

import TonMain from './views/Crypto/TonMain'
import TonBlockList from './views/Crypto/TonBlockList'
import TonBlockInfo from './views/Crypto/TonBlockInfo'
import TonTransactionList from './views/Crypto/TonTransactionList'
import TonTransactionInfo from './views/Crypto/TonTransactionInfo'
import TonAccountInfo from './views/Crypto/TonAccountInfo'
import TonAccountList from './views/Crypto/TonAccountList'
import TonSearchResults from './views/Crypto/TonSearchResults'

import CryptoSearch from './views/Crypto/Search'
import BTCAddress from './views/Crypto/BTCAddress'
import TonAddress from './views/Crypto/TonAddress'
import BTCTx from './views/Crypto/BTCTx'

import Discord from './views/Discord.vue'
import DiscordStats from './views/Discord/Stats.vue'
import DiscordGuildList from './views/Discord/GuildList.vue'
import DiscordGuildDetails from './views/Discord/GuildDetails.vue'
import DiscordDiscoverableList from './views/Discord/DiscoverableList.vue'
import DiscordUserList from './views/Discord/UserList.vue'
import DiscordUserDetails from './views/Discord/UserDetails.vue'
import DiscordMessageList from './views/Discord/MessageList.vue'
import DiscordTasksList from './views/Discord/TasksList.vue'
import DiscordChannelsItem from './components/Discord/ChannelDetails/Item'

import Imo from './views/Imo.vue'
import ImoStats from './views/Imo/Stats.vue'
import ImoGroupList from './views/Imo/GroupList.vue'
import ImoGroupDetails from './views/Imo/GroupDetails.vue'
import ImoUserList from './views/Imo/UserList.vue'
import ImoUserDetails from './views/Imo/UserDetails.vue'
import ImoMessageList from './views/Imo/MessageList.vue'
import ImoTasksList from './views/Imo/TasksList.vue'

import Tiktok from './views/Tiktok.vue'
import TiktokUserDetails from './views/Tiktok/UserDetails.vue'
import TiktokHashtagList from './views/Tiktok/HashtagList'
import TiktokHashtagDetails from './views/Tiktok/HashtagDetails'
import TiktokTasks from './views/Tiktok/Tasks'
import TiktokMessagesList from './views/Tiktok/MessageList.vue'
import TiktokMessageDetails from './views/Tiktok/MessageDetails.vue'
import TiktokUserList from './views/Tiktok/UserList.vue'
import TiktokStats from './views/Tiktok/Stats.vue'

const CaseMap = () => import(/* webpackChunkName: "caseMap" */ './components/cases/CaseMap')

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      inMenu: true
    },
    {
      path: '/changelog',
      name: 'changelog',
      component: Changelog,
      inMenu: true
    },
    {
      path: '/access/ip_msisdn_detect',
      name: 'ip_msisdn_detect',
      component: IpMsisdnDetect
    },
    {
      path: '/access/',
      name: 'access_tools_landing',
      component: AccessLanding
    },
    {
      path: '/osint/face_search',
      name: 'face_search',
      component: FaceSearch
    },
    {
      path: '/osint/similar_search',
      name: 'similar_search',
      component: SimilarSearch
    },
    {
      path: '/osint/',
      name: 'osint_tools_landing',
      component: OsintLanding
    },
    {
      path: '/leaks',
      name: 'leaks',
      component: () => import('@/views/Leaks'),
      children: [
        {
          path: 'public',
          name: 'leaks_table',
          component: () => import('@/views/Leaks/LeaksTable'),
          inMenu: true
        },
        {
          path: 'public/:routerSearch/:routerSearchType',
          name: 'leaks_table_search',
          component: () => import('@/views/Leaks/LeaksTable'),
          props: true
        }
      ]
    },
    {
      path: '/threema/search/phone/:phone',
      component: ThreemaPhones,
      props: true,
      name: 'threemaPhoneResults'
    },
    {
      path: '/threema',
      name: 'threema',
      inMenu: true,
      redirect: { name: 'threema_stats' }
    },
    {
      path: '/threema/stats',
      name: 'threema_stats',
      component: Threema,
      children: [
      ]
    },
    {
      path: '/threema/search/nickname/:nickname',
      component: ThreemaNicknames,
      props: true,
      name: 'threemaNicknameResults'
    },
    {
      path: '/threema/search/email/:email',
      component: ThreemaEmails,
      props: true,
      name: 'threemaEmailResults'
    },
    {
      path: '/facebook',
      name: 'facebook',
      component: () => import('@/views/Facebook/Search.vue'),
      inMenu: true,
      children: [
        {
          path: 'search/:type/:search',
          component: () => import('@/views/Facebook/SearchResults.vue'),
          props: true,
          name: 'facebookSearchResults'
        }
      ]
    },
    {
      path: '/zalo',
      name: 'zalo',
      inMenu: true,
      redirect: { name: 'zalo_stats' }
    },
    {
      path: '/zalo/stats',
      name: 'zalo_stats',
      component: Zalo,
      children: [
      ]
    },
    {
      path: '/zalo/user/:id',
      component: ZaloUserDetails,
      props: true,
      name: 'zalo_user_detail'
    },
    {
      path: '/zalo/user',
      component: ZaloUserList,
      name: 'zalo_user_list'
    },
    {
      path: '/instagram',
      name: 'instagram',
      component: () => import('@/views/Instagram'),
      inMenu: true,
      children: [
        {
          path: 'stats',
          props: true,
          name: 'instagram_stats',
          component: () => import('@/views/Instagram/Stats')
        },
        {
          path: 'user',
          name: 'instagram_user_list',
          props: true,
          component: () => import('@/views/Instagram/UserList')
        },
        {
          path: 'feed',
          name: 'instagram_feed_list',
          props: true,
          component: () => import('@/views/Instagram/Feed')
        },
        {
          path: 'user/:id',
          name: 'instagram_user_details',
          props: true,
          component: () => import('@/views/Instagram/UserDetails')
        },
        {
          path: 'tasks',
          name: 'instagram_task_list',
          props: true,
          component: () => import('@/views/Instagram/Tasks')
        }
      ]
    },
    {
      path: '/whatsapp',
      name: 'whatsapp',
      component: Whatsapp,
      inMenu: true,
      children: [
        {
          path: 'stats',
          props: true,
          name: 'whatsapp_stats',
          component: WhatsappStats
        },
        {
          path: 'user',
          name: 'whatsapp_user_list',
          component: WhatsappUserlist
        },
        {
          path: 'user/:phone',
          name: 'whatsapp_user_detail',
          props: true,
          component: WhatsappUserDetails
        },
        {
          path: 'group',
          name: 'whatsapp_group_list',
          component: WhatsappGroupslist

        },
        {
          path: 'group/:id/:tab?',
          name: 'whatsapp_group_details',
          props: true,
          component: WhatsappGroupDetails
        },
        {
          path: 'message',
          name: 'whatsapp_message_list',
          component: WhatsappMessagelist

        },
        {
          path: 'tasks',
          name: 'whatsapp_tasks',
          component: WhatsappTasks
        }
      ]
    },
    {
      path: '/hoop',
      component: Hoop,
      children: [
        {
          path: '',
          name: 'hoop',
          redirect: { name: 'hoop_stats' }
        },
        {
          path: 'stats',
          component: HoopStats,
          name: 'hoop_stats'
        },
        {
          path: 'tasks',
          component: HoopTasks,
          name: 'hoop_tasks'
        },
        {
          path: 'channels/:id/:messageId?',
          component: HoopChannelDetails,
          props: true,
          name: 'hoop_channel_details'
        },
        {
          path: 'channels',
          component: HoopChannelList,
          name: 'hoop_channel_list'
        },
        {
          path: 'users/:id',
          component: HoopUserDetails,
          props: true,
          name: 'hoop_user_details'
        },
        {
          path: 'users',
          component: HoopUserList,
          name: 'hoop_user_list'
        },
        {
          path: ':id?/messages',
          component: HoopMessageList,
          name: 'hoop_message_list'
        },
        {
          path: 'tags/:id',
          component: HoopHashtagDetails,
          props: true,
          name: 'hoop_hashtag_details'
        },
        {
          path: 'tags',
          component: HoopHashtagList,
          name: 'hoop_hashtag_list'
        }
      ]
    },
    {
      path: '/messengers/:name',
      props: true,
      name: 'messenger_stats',
      component: UsageStats
    },
    {
      path: '/ads',
      name: 'ads',
      component: Boards,
      children: [
        {
          path: ':country',
          props: true,
          name: 'ads_country',
          redirect: { name: 'ads_country_stats' }
        },
        {
          path: ':country/stats',
          props: true,
          name: 'ads_country_stats',
          component: AdsStats
        },
        {
          path: ':country/list',
          props: true,
          name: 'ads_list',
          component: ADSList
        },
        {
          path: ':country/boards',
          props: true,
          name: 'ads_boards_list',
          component: BoardsList
        },
        {
          path: 'list/:boardId',
          props: true,
          name: 'ads_country_list_board',
          component: ADSList
        },
        {
          path: ':country/list/:adId',
          props: true,
          name: 'ads_detail',
          component: ADSDetail
        }
      ],
      beforeEnter: (to, from, next) => {
        store.dispatch('ads/getGeo')
          .then((geo) => {
            if (geo.includes(to.params.country)) {
              next()
            } else {
              next('/404')
            }
          })
      }
    },
    {
      path: '/social/:name',
      name: 'social',
      component: NotAvailable
    },
    {
      path: '/nickname/:nickname/',
      component: Nicknames,
      props: true,
      name: 'nicknameResults',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/cards/:provider/:id',
      component: () => import('@/views/GlobalSearch/CardDetails.vue'),
      props: true,
      name: 'profilerCardDetails'
    },
    {
      path: '/name/:name/',
      component: () => import('@/views/GlobalSearch/Names.vue'),
      props: true,
      name: 'nameResults',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/phone/:phone/',
      component: Phones,
      props: true,
      name: 'phoneResults',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/email/:email/',
      component: Emails,
      props: true,
      name: 'emailResults',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/domain/:domain/',
      component: Domains,
      props: true,
      name: 'domainResults',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/ip/:ip/',
      component: IPs,
      props: true,
      name: 'ipResults',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/organizations',
      component: Organizations,
      children: [
        {
          path: '/',
          component: OrganizationsSearch,
          name: 'organizations_home'
        },
        {
          path: 'search',
          component: OrganizationsSearch,
          name: 'organizations_search'
        },
        {
          path: 'organization/:code/:number',
          name: 'organization_details',
          props: true,
          component: CompanyDetails
        }
      ]
    },
    {
      path: '/crypto',
      component: Crypto,
      beforeEnter: async (to, from, next) => {
        try {
          await store.dispatch('crypto/initTonLoad')
          next()
        } catch {
          next('404')
        }
      },
      children: [
        {
          path: '/',
          component: CryptoSearch,
          name: 'crypto_home'
        },
        {
          path: 'btc',
          component: CryptoSearch,
          name: 'crypto_btc_main'
        },
        {
          path: 'btc/address/:address',
          component: BTCAddress,
          props: true,
          name: 'crypto_btc'
        },
        {
          path: 'ton/address/:address',
          component: TonAddress,
          props: true,
          name: 'crypto_ton_address'
        },
        {
          path: 'btc/tx/:txid',
          component: BTCTx,
          props: true,
          name: 'crypto_btc_tx'
        },
        {
          path: 'ton',
          component: TonMain,
          name: 'crypto_ton_main_page'
        },
        {
          path: 'ton/blocks',
          component: TonBlockList,
          name: 'crypto_ton_block_list'
        },
        {
          path: 'ton/blocks/block',
          component: TonBlockInfo,
          name: 'crypto_ton_block_info'
        },
        {
          path: 'ton/accounts',
          component: TonAccountList,
          name: 'crypto_ton_account_list'
        },
        {
          path: 'ton/account/:id',
          component: TonAccountInfo,
          name: 'crypto_ton_account_info'
        },
        {
          path: 'ton/transactions',
          component: TonTransactionList,
          name: 'crypto_ton_transaction_list'
        },
        {
          path: 'ton/transaction/:id',
          component: TonTransactionInfo,
          name: 'crypto_ton_transaction_info'
        },
        {
          path: 'ton/search',
          component: TonSearchResults,
          name: 'crypto_ton_search_results'
        }
      ]
    },
    {
      path: '/twitter',
      component: Twitter,
      children: [
        {
          path: '',
          name: 'twitter',
          redirect: { name: 'twitter_stats' }
        },
        {
          path: 'stats',
          component: TwitterStats,
          name: 'twitter_stats'
        },
        {
          path: 'user/:id',
          component: TwitterUserDetails,
          props: true,
          name: 'twitter_user_details'
        },
        {
          path: 'user',
          component: TwitterUserList,
          name: 'twitter_user_list'
        },
        {
          path: 'messages',
          component: TwitterMessagesList,
          name: 'twitter_messages_list'
        },
        {
          path: 'hashtags/:id',
          component: TwitterHashtagDetails,
          props: true,
          name: 'twitter_hashtag_details'
        },
        {
          path: 'hashtags',
          component: TwitterHashtagList,
          name: 'twitter_hashtag_list'
        },
        {
          path: 'tasks',
          component: TwitterTasks,
          name: 'twitter_tasks'
        }
      ]
    },
    {
      path: '/discord',
      component: Discord,
      children: [
        {
          path: '',
          name: 'discord',
          redirect: { name: 'discord_stats' }
        },
        {
          path: 'stats',
          component: DiscordStats,
          name: 'discord_stats'
        },
        {
          path: 'guilds',
          name: 'discord_guild_list',
          component: DiscordGuildList
        },
        {
          path: 'guilds/:id',
          component: DiscordGuildDetails,
          props: true,
          name: 'discord_guild_details'
        },
        {
          path: 'channels',
          name: 'discord_channel_list',
          redirect: { name: 'discord_stats' }
        },
        {
          path: 'channels/:channel',
          component: DiscordChannelsItem,
          props: (route) => ({
            channel: route.params.channel,
            level: 0
          }),
          name: 'discord_channel_details'
        },
        {
          path: 'users',
          name: 'discord_user_list',
          component: DiscordUserList
        },
        {
          path: 'users/:id',
          component: DiscordUserDetails,
          props: true,
          name: 'discord_user_details'
        },
        {
          path: 'messages',
          name: 'discord_message_list',
          component: DiscordMessageList
        },
        {
          path: 'discoverable',
          name: 'discord_discoverable_list',
          component: DiscordDiscoverableList
        },
        {
          path: 'tasks',
          name: 'discord_task_list',
          component: DiscordTasksList
        }
      ]
    },
    {
      path: '/imo',
      component: Imo,
      children: [
        {
          path: '',
          name: 'imo',
          redirect: { name: 'imo_stats' }
        },
        {
          path: 'stats',
          component: ImoStats,
          name: 'imo_stats'
        },
        {
          path: 'groups',
          name: 'imo_group_list',
          component: ImoGroupList
        },
        {
          path: 'groups/:id',
          name: 'imo_group_details',
          component: ImoGroupDetails
        },
        {
          path: 'users',
          name: 'imo_user_list',
          component: ImoUserList
        },
        {
          path: 'users/:id+',
          name: 'imo_user_details',
          component: ImoUserDetails
        },
        {
          path: 'messages',
          name: 'imo_message_list',
          component: ImoMessageList
        },
        {
          path: 'tasks',
          name: 'imo_task_list',
          component: ImoTasksList
        }
      ]
    },
    {
      path: '/tiktok',
      component: Tiktok,
      children: [
        {
          path: '',
          name: 'tiktok',
          redirect: { name: 'tiktok_stats' }
        },
        {
          path: 'stats',
          component: TiktokStats,
          name: 'tiktok_stats'
        },
        {
          path: 'user/:id',
          component: TiktokUserDetails,
          props: true,
          name: 'tiktok_user_details'
        },
        {
          path: 'user',
          component: TiktokUserList,
          name: 'tiktok_user_list'
        },
        {
          path: 'messages',
          component: TiktokMessagesList,
          name: 'tiktok_messages_list'
        },
        {
          path: 'message/:id',
          component: TiktokMessageDetails,
          props: true,
          name: 'tiktok_message_details'
        },
        {
          path: 'hashtags/:id',
          component: TiktokHashtagDetails,
          props: true,
          name: 'tiktok_hashtag_details'
        },
        {
          path: 'hashtags',
          component: TiktokHashtagList,
          name: 'tiktok_hashtag_list'
        },
        {
          path: 'tasks',
          component: TiktokTasks,
          name: 'tiktok_tasks'
        }
      ]
    },
    {
      path: '/forums',
      component: Forums,
      children: [
        {
          path: '',
          name: 'forums',
          redirect: { name: 'forums_stats' }
        },
        {
          path: 'stats',
          component: ForumsStats,
          name: 'forums_stats'
        },
        {
          path: 'user',
          component: ForumsUserList,
          name: 'forums_user_list'
        },
        {
          path: 'user/:id',
          component: ForumsUserDetails,
          props: true,
          name: 'forums_user_details'
        },
        {
          path: 'forum',
          component: ForumsList,
          name: 'forums_list'
        },
        {
          path: 'topic',
          component: TopicsList,
          name: 'forums_topics_list',
          props: () => ({
            isRoot: true
          })
        },
        {
          path: 'goods',
          component: ForumsGoodsList,
          name: 'forums_goods_list'
        },
        {
          path: 'forum/:forumId/topic/:topicId',
          component: ForumsTopicDetails,
          props: true,
          name: 'forums_topic_details'
        },
        {
          path: 'message',
          component: ForumMessageList,
          name: 'forums_message_list'
        },
        {
          path: 'placements',
          components: ForumPlacementsList,
          name: 'forums_placements_list'
        },
        {
          path: 'forum/:id',
          component: ForumsForumDetails,
          props: true,
          name: 'forums_forum_details'
        },
        {
          path: 'status',
          name: 'forums_status',
          component: () => import('@/views/Forums/Status.vue')
        },
        {
          path: 'message/:messageId/:topicId/:forumId',
          name: 'forums_go_to_message',
          props: true,
          async beforeEnter (to, from, next) {
            const { messageId, topicId, forumId } = to.params
            const params = await store.dispatch('forums/fetchMessageLink', { id: messageId, topic_id: topicId, forum_id: forumId })
            next({
              name: 'forums_topic_details',
              props: {
                forumId: params.forumId,
                topicId: params.topicId,
                messagePage: params.messagePage
              },
              query: {
                id: params.id,
                tab: params.tab,
                page: params.messagePage
              },
              params
            })
          }
        }
      ]
    },
    {
      path: '/cases/',
      component: () => import('@/views/CasesRouterView.vue'),
      name: 'cases',
      children: [
        {
          path: '',
          component: Cases,
          name: 'cases_list'
        },
        {
          path: 'archived',
          name: 'cases_archived_alt',
          component: ArchivedCases
        },
        {
          path: 'archived/:caseId',
          name: 'case_archived_details',
          component: ArchivedCaseDetails,
          props: route => ({
            id: route?.params?.caseId
          })
        },
        {
          path: ':caseId',
          name: 'case_details',
          props: true,
          redirect: { name: 'case_details_profiles' },
          component: CaseDetails,
          children: [
            {
              path: 'profiles',
              name: 'case_details_profiles',
              props: true,
              component: () => import('@/components/cases/ProfilesList')
            },
            {
              path: 'storeds',
              name: 'case_details_storeds',
              props: true,
              component: () => import('@/components/cases/StoredObjectsList')
            },
            {
              path: 'tasks',
              name: 'case_details_tasks',
              props: route => ({
                id: route?.params?.caseId
              }),
              component: () => import('@/components/cases/TasksList')
            },
            {
              path: 'notes',
              name: 'case_details_notes',
              props: true,
              component: () => import('@/components/cases/NotesList')
            }
          ],
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              next()
            } catch {
              next('404')
            }
          }
        },
        {
          path: ':id/import',
          name: 'case_mass_import',
          props: true,
          component: CaseImport
        },
        {
          path: ':caseId/graph',
          name: 'case_graph',
          props: true,
          component: () => import('./components/cases/CaseGraph.vue'),
          meta: { isWideLayout: true }
        },
        {
          path: ':caseId/triggers',
          name: 'case_triggers',
          props: true,
          component: () => import('@/components/cases/Triggers/Triggers.vue'),
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              next()
            } catch {
              next('404')
            }
          }
        },
        {
          path: ':caseId/feed',
          name: 'case_feed',
          props: true,
          component: () => import('@/components/cases/Feed/Feed.vue'),
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              next()
            } catch {
              next('404')
            }
          }
        },
        {
          path: ':caseId/settings',
          name: 'case_settings',
          props: true,
          component: () => import('@/components/cases/Settings/General.vue'),
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              next()
            } catch {
              next('404')
            }
          }
        },
        {
          path: ':id/stats',
          name: 'case_stats',
          props: true,
          component: CaseStats
        },
        {
          path: ':id/map',
          name: 'case_map',
          props: true,
          component: CaseMap
        },
        {
          path: ':caseId/profile/:profileId',
          name: 'case_profile_details',
          props: true,
          redirect: { name: 'case_profile_general' },
          component: () => import('@/views/Profile.vue'),
          pathToRegexpOptions: { strict: true },
          children: [
            {
              path: 'general',
              name: 'case_profile_general',
              props: true,
              component: () => import('@/components/cases/ProfileGeneral')
            },
            {
              path: 'suggestions',
              name: 'case_profile_suggestions',
              props: true,
              component: () => import('@/components/cases/Suggestions')
            },
            {
              path: 'monitor',
              name: 'case_profile_monitor',
              props: true,
              component: () => import('@/components/OSINT/Monitor/PhonesList.vue')
            },
            {
              path: 'changes',
              name: 'case_profile_changes',
              props: true,
              component: () => import('@/components/cases/ProfileHistory')
            }
          ],
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              await store.dispatch('cases/getProfileById', to.params.profileId)
              next()
            } catch {
              next('404')
            }
          }
        },
        {
          path: ':caseId/trigger/:id',
          name: 'case_trigger_link',
          props: true,
          component: CaseTriggerDetails,
          pathToRegexpOptions: { strict: true },
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              next()
            } catch {
              next('404')
            }
          }
        },
        {
          path: ':caseId/web',
          name: 'case_web',
          props: true,
          component: () => import('@/views/WebReader.vue'),
          beforeEnter: async (to, from, next) => {
            try {
              await store.dispatch('cases/getCaseById', to.params.caseId)
              next()
            } catch {
              next('404')
            }
          }
        }
      ]
    },

    {
      path: '/tgcp',
      component: TGCP,
      children: [
        {
          path: '',
          name: 'tgcp',
          redirect: { name: 'tgcp_stats' }
        },
        {
          path: 'channel',
          name: 'channels',
          component: Channels,
          activate: 'channels',
          children: [
            {
              path: '',
              component: ChannelList,
              activate: 'channels',
              name: 'tgcp_channels_list'
            }
          ]
        },
        {
          path: 'user',
          name: 'tgcp_user_list',
          component: UserList
        },
        {
          path: 'user/page/:page',
          name: 'tgcp_user_list_by_page',
          component: UserList
        },
        {
          path: 'user/:id',
          component: UserDetails,
          props: true,
          name: 'tgcp_user_details',
          redirect: {
            name: 'tgcp_user_detail_overview'
          },
          children: [
            {
              path: 'overview',
              component: UserDetailsOverview,
              props: true,
              name: 'tgcp_user_detail_overview',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'accounts',
              component: UserDetailsAccounts,
              props: true,
              name: 'tgcp_user_detail_accounts',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'channels',
              component: UserDetailsChannels,
              props: true,
              name: 'tgcp_user_detail_channels',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'past-channels',
              component: UserDetailsPastChannels,
              props: true,
              name: 'tgcp_user_detail_past_channels',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'admin',
              component: UserDetailsAdmin,
              props: true,
              name: 'tgcp_user_detail_admin',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'past-admin',
              component: UserDetailsPastAdmin,
              props: true,
              name: 'tgcp_user_detail_past_admin',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'owner',
              component: UserDetailsOwner,
              props: true,
              name: 'tgcp_user_detail_owner',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'messages',
              component: UserDetailsMessages,
              props: true,
              name: 'tgcp_user_detail_messages',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'media-messages',
              component: UserDetailsMediaMessages,
              props: true,
              name: 'tgcp_user_detail_media_messages',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'replies',
              component: UserDetailsReplies,
              props: true,
              name: 'tgcp_user_detail_replies',
              meta: { ignoreScrollBehavior: true }
            },
            {
              path: 'entities',
              component: UserDetailsEntities,
              props: true,
              name: 'tgcp_user_detail_entities',
              meta: { ignoreScrollBehavior: true }
            }
          ]
        },
        {
          path: 'user/:userId/graph',
          component: () => import('./components/TGCP/UserDetails/UserReplyGraph.vue'),
          props: true,
          name: 'tgcp_user_detail_graph',
          meta: { isWideLayout: true }
        },
        {
          // NOTE: Вероятно, не используется
          path: 'reposts/:chatId/:messageId',
          component: MessageReposts,
          props: true,
          name: 'tgcp_message_reposts'
        },
        {
          path: 'message/:messageId/:chatId',
          name: 'tgcp_go_to_message',
          props: true,
          async beforeEnter (to, from, next) {
            const { messageId, chatId } = to.params
            const params = await store.dispatch('tgcp/fetchMessageLink', { messageId, chatId })
            next({
              name: 'tgcp_channel_detail',
              props: {
                id: params.chatId
              },
              query: {
                message_id: params.message_id,
                details: 'messages_tab',
                info: 'current_tab'
              },
              params
            })
          }
        },
        {
          path: 'channel/:id',
          component: ChannelDetails,
          props: true,
          name: 'tgcp_channel_detail'
        },
        {
          path: 'stats',
          name: 'tgcp_stats',
          component: Stats
        },
        {
          path: 'messages',
          name: 'tgcp_messages',
          component: MessageList
        },
        {
          path: 'message/:chatId/:messageId/similar',
          name: 'tgcp_messages_similar',
          props: true,
          component: () => import('@/components/TGCP/MessageList/SimilarMessages.vue')
        },
        {
          path: 'map',
          name: 'tgcp_map',
          component: TGCPMap,
          meta: { isWideLayout: true }
        },
        {
          path: 'newusers',
          name: 'tgcp_newusers',
          component: NewUsers
        },
        {
          path: 'tasks',
          module: 'tgcp',
          component: Tasks,
          inMenu: true,
          children: [
            {
              path: '',
              name: 'tgcp_tasks',
              redirect: {
                name: 'tgcp_tasks_all'
              }
            },
            {
              path: 'all',
              name: 'tgcp_tasks_all',
              component: TasksAll
            },
            {
              path: 'bots',
              name: 'tgcp_tasks_bots',
              component: TasksBots
            },
            {
              path: 'clusters',
              name: 'tgcp_tasks_clusters',
              component: TasksGeoClusters
            },
            {
              path: 'scheduled-search',
              name: 'tgcp_tasks_scheduled_search',
              component: TasksScheduledSearch
            }
          ]
        }
      ]
    },
    {
      path: '/help',
      name: 'help',
      component: Help
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: true
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      inRightMenu: true,
      children: [
        {
          path: 'general',
          name: 'settings_general'
        },
        {
          path: 'search',
          name: 'settings_search'
        }
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      component: Home,
      inRightMenu: true
    },
    {
      path: '/map',
      name: 'global_map',
      component: Map,
      meta: { isWideLayout: true }
    },
    {
      path: '/web/sources',
      name: 'web_sources',
      component: WebSources
    },
    {
      path: '/web/status',
      name: 'web_status',
      component: () => import('@/views/WebReader/Status.vue')
    },
    {
      path: '/web/sources/:sourceId/edit',
      name: 'web_sources_edit',
      props: true,
      component: () => import('@/views/WebReader/AdvancedSourceAdd.vue')
    },
    {
      path: '/web/sources/add',
      name: 'web_sources_add',
      component: () => import('@/views/WebReader/AdvancedSourceAdd.vue')
    },
    {
      path: '/web/sources/:sourceId/view',
      name: 'web_sources_view',
      props: true,
      component: () => import('@/views/WebReader.vue')
    },
    {
      path: '/web/monitor',
      name: 'web_monitor',
      props: true,
      component: () => import('@/views/WebReader.vue')
    },
    {
      path: '/map/:coords',
      name: 'global_map_by_coords',
      component: Map,
      meta: { isWideLayout: true }
    },
    {
      path: '/map/:coords/:data',
      name: 'global_map_by_coords_with_mark',
      component: Map
    },
    {
      path: '/mapEditable',
      name: 'map_ediatable',
      component: MapEditable
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/manuals/ASIRIS_Platform_Operator_Manual_ENG.pdf',
      name: 'manual'
    },
    {
      path: '/404',
      name: '404',
      component: PageNotFound
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: '404' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    const { ignoreScrollBehavior } = to.meta

    if (ignoreScrollBehavior) {
      return false
    }

    if (savedPosition) {
      return savedPosition

    } else if (to.hash) {
      return { selector: to.hash }

    } else if (from.path === to.path) {
      return {}
    }

    return { x: 0, y: 0 }
  }
})
router.onError(error => console.debug(error))
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title ? to.meta.title + ' — ' + config.siteTitle : config.siteTitle
  if (to.path !== '/login' && !store.getters.isAuthenticated) { // Not logged in? You get booted to /login
    await store.dispatch('logout', to.fullPath)
    next()
  } else if (to.path === '/login' && store.getters.isAuthenticated) { // Let's not allow a login transition
    return next('/')
  } else if (to.path === '/logout') {
    await store.dispatch('logout')
    next()
  } else {
    next()
  }
})
export default router
