<script>
import Loader from '@/components/TGCP/Loader'
import TableLine from '@/components/Crypto/Ton/Transactions/TableLine'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TonTransactionsTable',
  components: {
    Loader,
    TableLine
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    update: {
      type: Boolean,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    },
    hidden: {
      type: Boolean,
      required: true
    },
    account: {
      type: String,
      required: false
    },
    excludeMC: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      firstLT: 0,
      lastLT: 0,
      itemCount: 20,
      emptyTable: false,
      pagination: {
        page: 1,
        total: 0
      },
      maxExploredPage: 0,
      sortby: {
        order_desc: true
      }
    }
  },
  watch: {
    sortby: {
      handler () {
        if (this.pagination.page === 1) this.updateValues()
        else this.pagination.page = 1
      },
      deep: true
    },
    excludeMC: {
      handler () {
        if (this.pagination.page === 1) this.updateValues()
        else this.pagination.page = 1
      },
      deep: true
    },
    account: {
      handler () {
        this.updateValues()
      },
      deep: true
    },
    'pagination.page' : {
      async handler (to, from) {
        if (this.update) {
          if (to === 1 || (to > from && to > this.maxExploredPage)) { 
            this.maxExploredPage = to
            await this.updateValues()
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonTransactions', 'getQueryTotal', 'getTonAccounts']),
    isLoaded () {
      if (!this.update) return true
      return this.$store.state.crypto.isLoaded['ton_transactions'] ?? false
    },
    hidePagination () {
      return !this.isLoaded || (this.pagination.total ? this.pagination.total : this.keys.length ) < this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage + 1 : lastPage + 2
    }
  },
  methods: {
    ...mapActions('crypto', ['updateTonTransactions']),
    setExtraFields () {
      const { keys, getTonTransactions, getTonAccounts, getQueryTotal, account } = this
      this.pagination.total = account ? getTonAccounts[account].transaction_amount : getQueryTotal('trn')
      if (keys.length > 0) {
        if (keys[0] in getTonTransactions) {
          this.firstLT = BigInt(getTonTransactions[keys[0]].created_lt)
        }
        if (keys[keys.length - 1] in getTonTransactions) {
          this.lastLT = BigInt(getTonTransactions[keys[keys.length - 1]].created_lt)
        }
      }
    },
    async updateValues (next = true) {
      const { keys, itemCount, pagination, excludeMC, account, lastLT, firstLT, sortby } = this
      if (!this.update) return
      this.emptyTable = false
      if (keys.length === 0 || pagination.page === 1)
        await this.updateTonTransactions({ limit: itemCount, seqOffset: null, excludeMC, account, order: sortby.order_desc ? 'DESC' : 'ASC' })
      else {
        await this.updateTonTransactions({ limit: itemCount, seqOffset: next ? lastLT : firstLT, excludeMC, account, cutPage: pagination.page, order: sortby.order_desc ? 'DESC' : 'ASC' })
      }
      this.setExtraFields()
      if (this.keys.length === 0) this.emptyTable = true
    },
    setPage (page) {
      this.pagination.page = page
    }
  },
  async mounted () {
    await this.updateValues()
  }
}
</script>

<template>
  <div>
    <table 
      v-show="!hidden"
      class="uk-table uk-table-striped uk-margin-remove-vertical"
      style="padding-bottom: 0;"
    >
      <colgroup>
        <col width="13%" />
        <col width="13%" />
        <col width="25%" />
        <col width="13%" />
        <col width="13%" />
        <col width="13%" />
        <col width="10%" />
      </colgroup>
      <thead>
        <tr>
          <th style="width:13%; min-width: 20px">
            {{ $t('crypto.ton.id') }}
          </th>
          <th style="width:13%; min-width: 20px">
            {{ $t('crypto.ton.type') }}
          </th>
          <th 
            class="uk-table-expand" 
            style="width:25%;"
          >
            {{ $t('crypto.ton.operation_name') }}
          </th>
          <th 
            class="uk-text-right" 
            style="width:13%;"
          >
            {{ $t('crypto.general.amount') }}
          </th>
          <th 
            class="uk-text-right" 
            style="width:13%;"
          >
            {{ $t('crypto.general.sender') }}
          </th>
          <th 
            class="uk-text-right" 
            style="width:13%;"
          >
            {{ $t('crypto.general.receiver') }}
          </th>
          <th 
            class="uk-text-right uk-text-nowrap" 
            style="width:10%;" 
            :class="{'hover-header' : update}" 
            @click="sortby.order_desc = !sortby.order_desc"
          >
            {{ $t('crypto.general.created') + (update ? (sortby.order_desc ? ' ▼' : ' ▲') : '') }}
          </th>
        </tr>
      </thead>
      <template v-if="emptyTable">
        <div class="uk-flex uk-margin-top">
          {{ $t('crypto.warning.nothing_found') }}
        </div>
      </template>
      <template v-else-if="!isLoaded && !hidden">
        <tr class="uk-text-center">
          <td colspan="7">
            <Loader />
          </td>
        </tr>
      </template>
      <template v-else>
        <tbody>
          <TableLine 
            v-for="trn in keys.slice((pagination.page - 1)*itemCount, (pagination.page)*itemCount)"
            :key="trn + (account ? account : '_1')"
            :trn="getTonTransactions[trn]"
            :account="account"
          />
        </tbody>
      </template>
    </table>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>