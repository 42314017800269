<template>
  <div>
    <div class="uk-card uk-card-default channel uk-text-break">
      <div class="uk-card-body uk-text-break">
        <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
          <AddStoredButton
            v-if="showStoredButton"
            provider="telegram"
            type="chat"
            :data="channel"
          />
        </div>
        <div class="channel_badge">
          <span v-if="!isLoadedStats"/>
          <span v-if="channel.broadcast">
            {{ $t('tgcp.channel_details.channel_title') }}
          </span>
          <span v-else>
            {{ $t('tgcp.channel_details.chat_title') }}
          </span>
        </div>
        <div
          class="uk-grid-small uk-flex-middle"
          uk-grid
        >
          <div class="uk-width-1-6@m ">
            <router-link
              :to="{
                name: 'tgcp_channel_detail',
                params: { id: String(channel['tg_id']) }
              }"
            >
              <Avatar
                :avatar="channel.avatar"
                :type="'channel'"
              />
            </router-link>
          </div>
          <div class="uk-width-2-3@l uk-width-3-5@s">
            <ChannelInfo
              :id="channel['tg_id']"
              :channel="channel"
            />
          </div>
          <div class="uk-width-expand">
            <ChannelStat :id="channel['tg_id']"/>
            <div>
              <SyncChat
                :channel="channel"
                class="uk-float-center uk-width-1-1"
              />
              <router-link
                :to="{
                  name: 'tgcp_channel_detail',
                  params: { id: String(channel['tg_id']) }
                }"
                style="min-height: 40px"
                class=" uk-width-1-1
                                  uk-button
                                  uk-button-primary
                                  uk-padding-small
                                  uk-padding-remove-top
                                  uk-padding-remove-bottom
                                  uk-padding-small
                                  uk-padding-remove-top
                                  uk-padding-remove-bottom
                                "
              >
                {{ $t('tgcp.channels.full_info') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
import Avatar from '@/components/TGCP/Avatar.vue'
import ChannelInfo from '@/components/TGCP/ChannelList/ChannelInfo.vue'
import ChannelStat from '@/components/TGCP/ChannelList/ChannelStat.vue'
import SyncChat from '@/components/TGCP/ChannelDetails/Syncchat'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import { mapGetters } from 'vuex'

export default {
  name: 'ChannelCard',
  components: {
    Avatar,
    ChannelInfo,
    ChannelStat,
    SyncChat,
    AddStoredButton
  },
  props: {
    channel:{
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('tgcp', ['allChannels']),
    channelStat () {
      return this.$store.state.tgcp.channels[this.channel.tg_id]['stats']
    },
    isLoadedStats () {
      return this.$store.state.tgcp.isLoaded['channel_' + this.channel.tg_id + '_stats']
    }
  }
}
</script>

<style lang="scss" scoped>
    .channel {
        position: relative;
        overflow: hidden;
    }
    .channel_badge {
        background-color: #444;
        box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
        height: 100px;
        left: -50px;
        position: absolute;
        top: -50px;
        width: 100px;
        color: white;
        line-height: 15;
        font-size: 0.7rem;
        -webkit-transform: rotate(-45deg);
    }
</style>
