<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div class="uk-margin-bottom"/>
      <div v-if="!siLoaded['placements']">
        <Loader />
      </div>
      <div v-else>
        <NotFound v-if="IsLoaded['goods'] && goods.length === 0" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'PlacementsList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader
  },
  props: {
    forumId: {
      type: [String, Number],
      required: true
    },
    goodId: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      default: 'good',
      validator: val => ['all', 'good']
    }
  },
  data () {
    return {
      placements: [],
      query: {
        limit: 100,
        offset: 0
      }
    }
  },
  computed: {
    ...mapGetters('forums', [
      'isLoaded',
      'allGoods'
    ])
  },
  methods: {
    ...mapActions('forums', [
      'fetchAllPlacements',
      'addParamToQuery',
      'fetchGoodPlacements'
    ]),
    setPage (page) {
      this.query.offset = this.query.limti * (page - 1)
      this.pagination.page = page
      this.type !== 'all' &&
        this.addParamToQuery({
          page: this.pagination.page
        })
      this.update()
    },
    clearData () {
      this.placements = []
    },
    async update () {
      try {
        switch (this.type) {
            case 'good':
              return this.setData(
                this.placements =
                  await this.fetchGoodPlacements({
                    query: this.query,
                    goodId: this.goodId,
                    forumId: this.forumId
                  })
              )
        }
      } catch {
        this.clearData()
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      }
    }
  },
  mounted () {
    this.setPage(Number(this.$route.query.page) || 1)
  }
}
</script>
