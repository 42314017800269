<template>
  <div
    style="padding-bottom: 20px"
    class="uk-margin-top"
  >
    <div class="uk-width-1-1">
      <span>
        <button
          v-if="currentCase"
          class="uk-margin-left uk-button-primary uk-button uk-width-1-6"
          @click="createProfile"
        >
          {{ selectMode === 'normal' ? $t('cases.profile.add-profile') : $t('cases.confirm') + ' (' + countSelectedCards + ') ' + $t('cases.items') }}
        </button>
        <VueServerSelect
          v-if="currentCase && selectMode === 'selected'"
          v-model="currentProfileId"
          :defaultOptions="[ createNewProfileItem ]"
          :loadDataPortion="loadProfiles"
          :refreshList="refreshProfileList"
          class="uk-display-inline-block uk-margin-small-left uk-form-width-small uk-form-width-medium uk-text-small"
        />
        <span
          v-if="allowLeaks() && passwords[cleaned_nickname] && passwords[cleaned_nickname].length"
          class="uk-margin-small-left uk-width-1-6"
        >
          <Passwords
            :search="nickname"
            :initialValues="passwords[cleaned_nickname]"
            type="email_user"
            :isOverLimit="isOverLimits[cleaned_nickname]"
          />
        </span>
      </span>
    </div>

    <div
      class="uk-grid uk-margin-top"
      data-uk-grid-margin
    >
      <div class="uk-width-expand">
        <div
          v-if="total === current"
          class="uk-width-auto uk-align-right uk-margin-remove"
        >
          <exportCSV
            style="margin-top:20px; margin-bottom:0px;"
            :data="list"
            :search="cleaned_nickname"
          />
        </div>
        <UsedProviders
          v-if="allProviders"
          class="uk-align-right uk-width-1-4 uk-margin-small-right"
          :search="cleaned_nickname"
          type="nickname"
        />
        <ul
          id="viewTab"
          class="uk-tab"
          uk-tab
        >
          <li class="uk-active">
            <a href="#">
              {{ $t('search.founds') }}
              <span
                v-if="list"
                class="uk-badge"
              >
                {{ list.length }}
              </span>
            </a>
          </li>
          <li class="uk-disabled">
            <a>
              {{ $t('search.mentions') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <ul class="uk-subnav uk-subnav-pill">
              <li :class="{ 'uk-active': !filter }">
                <a
                  href="#"
                  @click="filter=''"
                >
                  {{ $t('search.types.all') }}
                </a>
              </li>
              <li
                v-for="item in types"
                :key="item"
                :class="{ 'uk-active': filter === item }"
              >
                <a
                  href="#"
                  @click="filter = item"
                >
                  {{ $t('search.types.' + item) }}
                  <span
                    :class="{ 'badge-inverse': filter === item }"
                    class="uk-badge uk-margin-small-left"
                  >
                    {{ list.filter(card => card.genericType === item).length }}
                  </span>
                </a>
              </li>
            </ul>
            <Loader
              v-if="!isLoaded['providers']"
            />
            <div
              v-else
              class="uk-margin-top"
            >
              <progress
                v-if="total > current"
                id="js-progressbar"
                class="uk-progress"
                :value="current"
                :max="total"
              />
              <div
                v-if="total <= current && !filteredList.length"
              >
                <Loader
                  v-if="!allProviders.length"
                />
                <NotFound
                  v-if="current > 0 && allProviders.length"
                />
              </div>
              <div
                class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid filter_area"
              >
                <div
                  v-for="(item, id) in filteredList"
                  :key="id"
                  uk-inline
                  class="uk-inline uk-grid-margin"
                >
                  <usercard
                    :key="item.ext_id || item.getId()"
                    :generalUser="item"
                    :selectMode="selectMode"
                    @selected="toggleUserCard"
                    @opendetails="activeItem = id"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!hidePagination">
      <Paginate
        v-model="pagination.page"
        :page-count="lastPage"
        :click-handler="setPage"
        :prev-text="'Prev'"
        :next-text="'Next'"
        container-class="uk-pagination uk-flex-center"
      />
    </div>
    <rightSidebar>
      <ProviderDetails
        v-if="showProviderDetails"
        :data="providerSidebarData"
        :raw="rawParsed"
      />
    </rightSidebar>
    <ExportDialog
      :id="nickname"
      :search="filter"
      namespace="fetcher"
      type="users"
      subtype="nickname"
      :messages="filteredList"
      :totalCount="filteredList.length"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UIkit from 'uikit'
import restrictions from '../../config/restrictions'
import { parseRaw } from '@/utils/tryParse'
import { defineAsyncComponent } from 'vue'

import UsedProviders from '@/components/GlobalSearch/UsedProviders'
import Passwords from '@/components/leaks/Offcanvas.vue'
import Loader from '@/components/TGCP/Loader'
import rightSidebar from '@/components/RightSidebar'
import Paginate from '@/components/Paginate'
import AddUserCardToProfile from '@/mixins/addUserCardsToProfile'

export default {
  name: 'Nicknames',
  components: {
    usercard: defineAsyncComponent(() => import('@/components/UserCard2')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Paginate,
    VueServerSelect: defineAsyncComponent(() => import('@/components/VueServerSelect')),
    UsedProviders,
    Loader,
    Passwords,
    rightSidebar,
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    exportCSV: defineAsyncComponent(() => import('@/components/GlobalSearch/Export.vue'))
  },
  mixins: [
    AddUserCardToProfile
  ],
  props: {
    nickname: {
      required: true,
      type: String
    },
    providers: {
      required: false,
      type: String
    },
    showfilter: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeItem: null,
      details: {},
      filter: '',
      currentGeneralUser: undefined,
      query: {
        limit: 39,
        offset: 0
      },
      pagination: {
        page: 1,
        total: 0,
        isNextPage: false
      }
    }
  },
  watch: {
    nickname (from, to) {
      this.update()
    },
    excludedSources : {
      handler (to, from) {
        if (from === null) this.update()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['excludedSources']),
    ...mapGetters('fetcher', ['allProviders', 'isLoaded']),
    ...mapGetters('leaks', ['passwords', 'isOverLimits']),
    rawParsed () {
      const data = this.providerSidebarData
      if (!data) return false
      return parseRaw(data)
    },
    showProviderDetails () {
      return this.rawParsed && Object.keys(this.rawParsed).length
    },
    providerSidebarData () {
      return this.activeItem !== null && this.filteredList[this.activeItem]
    },
    cleaned_nickname () {
      if (this.nickname[0] === '@') {
        return this.nickname.replace('@', '')
      }
      return this.nickname
    },
    total () {
      return this.$store.state.fetcher.total
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return lastPage
    },
    current () {
      return this.$store.state.fetcher.count
    },
    types () {
      if (!this.list) return []
      return [ ...new Set(this.list.map(el => el.genericType)) ].sort()
    },
    filteredList () {
      if (!this.list) return []
      if (!this.filter) return this.list
      return this.list.filter(card => card.genericType === this.filter)
    },
    leaks () {
      return this.$store.state.leaks.leaks
    },
    list () {
      let list = this.$store.state.fetcher.nickname[this.cleaned_nickname] || []
      let set = []
      list = list.filter(card => {
        if (!card.ext_id) return true
        let i = set.findIndex(el => el.ext_id === card.ext_id)
        if (i === -1) {
          set.push({ ...card })
          return true
        } else {
          Object.assign({ ...set[i] }, { ...card })
        }
        return false
      })
      return list
    },
    hidePagination () {
      if ((this.filter && this.filter !== 'leak') || (this.pagination.isNextPage === false && this.pagination.page === 1) || this.profilesTotal === this.query.limit) return true
      else return false
    },
    onlyLeaks () {
      return this.pagination.page > 1
    },
    profileName () {
      return this.nickname
    }
  },
  methods: {
    ...mapActions('leaks', ['loadLeaks']),
    seeAccounts (gu) {
      this.currentGeneralUser = false
      this.currentGeneralUser = gu
      UIkit.switcher('#viewTab').show(1)
    },
    setPage (page) {
      this.pagination.page = page
      this.query.offset = this.query.limit * (page - 1)
      this.update()
    },
    allowLeaks () {
      let userId = this.$store.state.current_user_id
      if (restrictions[userId]) {
        if (restrictions[userId].module.indexOf('leak') > -1) {
          return false
        }
      }
      return true
    },
    updateDetails (raw) {
      this.details = raw
    },
    update () {
      this.filter = ''
      this.loadLeaks({
        searchType: 'login',
        searchString: this.cleaned_nickname
      })
      const action = 'fetcher/fetch'
      const providers = this.providers && this.providers.split(',')
      this.$store.dispatch(action, {
        search: this.cleaned_nickname,
        type: 'nickname',
        providers: providers
      })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
<style>
.badge-inverse {
  background: white;
  color: #1e87f0;
}
</style>
