<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <form @submit.prevent="search">
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
          :placeholder="$t('tgcp.stats.search-by-phone-or-input-invite-link')"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          type="submit"
        >
          <span
            uk-search-icon
          />
        </button>
      </form>
    </div>
    <div
      class="uk-child-width-1-3@s uk-grid-match"
      uk-grid
    >
      <div >
        <router-link
          to="/whatsapp/user"
          class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.users_count"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.users_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.users_count') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/whatsapp/group"
          class="uk-card uk-card-small uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.groups_count"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.groups_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('whatsapp.stats.groups') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/whatsapp/message"
          class="uk-card uk-card-small uk-card-secondary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.messages_count"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.messages_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('whatsapp.stats.messages') }}
          </h3>
        </router-link>
      </div>
    </div>
    <div
      class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
      uk-grid
    >
      <div >
        <router-link
          to="/whatsapp/user"
          class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.user_avatars_count"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.user_avatars_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('whatsapp.stats.profile-images') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/whatsapp/group"
          class="uk-card uk-card-small  uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.user_avatars_count"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.group_avatars_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('whatsapp.stats.groups-images') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/whatsapp/tasks"
          class="uk-card uk-card-small uk-card-secondary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.tasks_count"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.tasks_count) }} ({{ numberFormat(tasks_success_perc(stats)) }}%)
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('whatsapp.stats.tasks') }}
          </h3>
        </router-link>
      </div>
    </div>
    <JoinChatResult
      v-if="joinShown"
      :joinlink="joinlink"
    />
    <UsageStats name="whatsapp" />
    <ParsedStats
      provider="whatsapp"
      route-name="whatsapp_user_list"
      route-prop="country"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import UsageStats from '@/views/UsageStats'
import UIkit from 'uikit'
import JoinChatResult from '@/components/WhatsApp/JoinChatResult'
import ParsedStats from '@/components/TGCP/Stats/ParsedStats'
import { numberFormat } from '@/filters/globalFilters'

export default {
  name: 'Stats',
  components: {
    Loader,
    UsageStats,
    JoinChatResult,
    ParsedStats
  },
  data () {
    return {
      searchString: '',
      joinShown: false,
      joinlink: ''
    }
  },
  computed: {
    ...mapGetters({ stats: 'whatsapp/stats' }),
    isLoaded () {
      return this.$store.state.whatsapp.isLoaded['stats']
    }
  },
  methods: {
    numberFormat,
    search () {
      var search = this.searchString.replace('+', '')
      var regexLinkCheck = new RegExp('(chat\\.whatsapp\\.com)/([A-Za-z0-9/_-]+)', 'i')
      var result = regexLinkCheck.exec(search)
      if (result) {
        this.showJoinDialog(search)
      } else {
        this.$router.push('/whatsapp/user?search=' + search)
      }
    },
    showJoinDialog (joinlink) {
      this.joinlink = joinlink
      this.joinShown = true
      this.$nextTick(() => {
        UIkit.modal('#joinchat').show()
      })
    },
    tasks_success_perc (stats) {
      return (stats.successful_tasks_count / stats.tasks_count) * 100
    },
    ...mapActions({ update: 'whatsapp/fetchStats' })
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
