<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div>
        <form>
          <span v-if="type === 'all'">
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('twitter.hashtags.search-with-wildcard') "
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="update"
            >
              <span uk-search-icon/>
            </button>
          </span>
        </form>
      </div>
      <SearchInDbHint />
      <div class="uk-margin-top uk-card uk-card-default uk-card-body">
        <div v-if="!hidePagination || !isLoaded['hashtags']">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
        <table class="uk-table uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr class="uk-text-center uk-text-nowrap uk-text-center">
              <th class="uk-text-center">
                <button
                  class="uk-button uk-button-text uk-text-nowrap uk-text-center"
                  @click="setOrder('id')"
                >
                  {{ $t('twitter.hashtags.id') }}
                  {{ query.order_by === 'id' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center">
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('value')"
                >
                  {{ $t('twitter.hashtags.value') }}
                  {{ query.order_by === 'value' ? selectedOrder : '' }}
                </button>
              </th>
              <th
                v-if="type === 'all'"
                class="uk-text-center"
              >
                {{ $t('tiktok.stats.posts_count') }}
              </th>
              <th
                v-if="type === 'all'"
                class="uk-text-center"
              >
                {{ $t('twitter.hashtags.users') }}
              </th>
              <th
                v-if="type === 'together'"
                class="uk-text-center"
              >
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('count')"
                >
                  {{ $t('twitter.hashtags.together_count') }}
                  {{ query.order_by === 'count' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center">
                {{ $t('twitter.hashtags.actions') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="isLoaded['hashtags']">
            <Item
              v-for="hashtag in hashtags"
              :key="hashtag.id"
              :tag="hashtag"
            />
          </tbody>
        </table>
        <div
          v-if="!isLoaded['hashtags']"
          class="uk-align-cetner uk-width-1-1"
        >
          <Loader />
        </div>
        <NotFound v-if="isLoaded['hashtags'] && hashtags.length === 0" />
        <div v-if="!hidePagination || !isLoaded['hashtags']">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="id"
      :search="query.search"
      namespace="tiktok"
      type="hashtags"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'TiktokHashtagList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Tiktok/Hashtags/Item')),
    Paginate,
    Loader
  },
  props: {
    id: {
      type: [Number, String],
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'together'].includes(val)
    }
  },
  data () {
    return {
      hashtags: [],
      orderList: ['id', 'value', ...(this.type === 'together' ? ['count'] : [])],
      query: {
        limit: 10,
        offset: 0,
        search: this.$route.query.search || '',
        order_by: this.$route.query.order_by || this.type === 'all' ? 'id' : 'count',
        order_type: this.$route.query.order_type || this.type === 'all' ? 'desc' : 'asc'
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.setPage(1)
    }
  },
  computed: {
    ...mapGetters('tiktok', ['isLoaded']),
    hidePagination () {
      return Number(this.pagination.total) < Number(this.query.limit)
    },
    lastPage () {
      return Math.floor(Number(this.pagination.total) / this.query.limit)
    },
    selectedOrder () {
      return this.query.order_type === 'asc' ? '↑' : '↓'
    },
    hasExport () {
      const { query: { search }, $route: { name } } = this
      if (name === 'tiktok_hashtag_list' && search && this.hashtags.length) {
        return true
      }
      if (name !== 'tiktok_hashtag_list' && this.hashtags.length) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions('tiktok', ['fetchAllHashtags', 'addParamToQuery', 'fetchTogetherHashtags']),
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      if (this.type === 'all') this.addParamToQuery({ page: this.pagination.page })
      this.update()
    },
    setOrder (by) {
      this.query.order_type = this.query.order_type === 'desc' ? 'asc' : 'desc'
      this.query.order_by = by
      this.addParamToQuery({ order_by: this.query.order_by }) && this.addParamToQuery({ order_type: this.query.order_type }) && this.update()
    },
    setData (res) {
      this.hashtags = res.data
      this.pagination.total = res.meta.results_count
    },
    handleError () {
      this.hashtags = []
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    async update () {
      if (!['all', 'user', 'together'].includes(this.type)) return
      const [method, query] = ['all', 'user'].includes(this.type) ? ['fetchAllHashtags', this.query] : ['fetchTogetherHashtags', { id: this.id, query: this.query }]
      try {
        const data = await this[method](query)
        this.setData(data)
      } catch (err) {
        !err.__CANCEL__ && this.handleError(err)
      }
    }
  },
  mounted () {
    this.setPage(Number(this.$route.query.page) || 1)
  }
}
</script>
