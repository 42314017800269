<template>
  <div v-if="!isLoaded">
    <div class="uk-flex uk-flex-center">
      <Loader />
    </div>
  </div>
  <div v-else>
    <table 
      v-if="!hidden" 
      class="uk-table uk-table-striped uk-margin-remove-top uk-table-middle"
      style="padding-bottom: 0;"
    >
      <thead>
        <tr>
          <th class="uk-width-1-6">
            {{ $t('crypto.ton.workchain') }}
          </th>
          <th class="uk-width-1-6">
            {{ $t('crypto.ton.shard') }}
          </th>
          <th 
            class="uk-text-nowrap uk-width-1-6" 
            :class="{'hover-header' : update}" 
            @click="sortby.order_desc = !sortby.order_desc"
          >
            {{ $t('crypto.ton.block') + (update ? (sortby.order_desc ? ' ▼' : ' ▲') : '') }}
          </th>
          <th class="uk-width-1-6 uk-text-center">
            {{ $t('crypto.ton.transactions-count') }}
          </th>
          <th 
            class="uk-table-expand uk-text-right" 
            style="margin-right: 0.3rem;"
          >
            {{ $t('crypto.general.scanned') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <TableLine
          v-for="block in displayedKeys"
          :key="block + '_'"
          :block="getTonBlocks[block]" 
          :link-block="true"
        />
      </tbody>
    </table>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-text-center uk-text-truncate"
            style="max-width: 250px; width: 40px; cursor: default;"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import TableLine from '@/components/Crypto/Ton/Blocks/TableLine'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TonBlocksTable',
  components: {
    Loader,
    TableLine
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    update: {
      type: Boolean,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    },
    hidden: {
      type: Boolean,
      required: true
    },
    lineLink: {
      type: Boolean,
      required: true
    },
    excludeEmpty: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      firstMC: 0,
      lastMC: 0,
      itemCount: 7,
      pagination: {
        page: 1,
        total: 0
      },
      maxExploredPage: 0,
      sortby: {
        order_desc: true
      }
    }
  },
  watch: {
    sortby: {
      handler () {
        if (this.pagination.page === 1) this.updateValues()
        else this.pagination.page = 1
      },
      deep: true
    },
    'pagination.page' : {
      async handler (to, from) {
        if (this.update) {
          if (to === 1 || (to > from && to > this.maxExploredPage)) { 
            this.maxExploredPage = to
            await this.updateValues()
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonBlocks', 'getQueryTotal']),
    isLoaded () {
      if (!this.update) return true
      return this.$store.state.crypto.isLoaded['ton_blocks'] ?? false
    },
    hidePagination () {
      return !this.isLoaded || this.pagination.total < this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage : lastPage + 1
    },
    displayedKeys () {
      const { keys, getTonBlocks, itemCount, pagination } = this
      const out = []
      keys.slice((pagination.page - 1)*itemCount, (pagination.page)*itemCount).map(key => out.push(...[key, ...getTonBlocks[key].shard_keys]))
      return out
    }
  },
  methods: {
    ...mapActions('crypto', ['updateBlockValues']),
    setExtraFields () {
      const { keys, getTonBlocks, itemCount, pagination, getQueryTotal } = this
      if (keys.length > 0) {
        this.pagination.total = getQueryTotal('block')
        if (keys[0] in getTonBlocks) {
          this.firstMC = getTonBlocks[keys[0]].seq_no
        }
        for (const block of keys.slice(0, itemCount * (pagination.page)).reverse()) {
          if (getTonBlocks[block].workchain === -1) {
            this.lastMC = getTonBlocks[block].seq_no
            return
          }
        }
      }
    },
    async updateValues (next = true) {
      const { keys, itemCount, pagination, lastMC, firstMC, sortby } = this
      if (!this.update) return
      if (keys.length === 0 || pagination.page === 1)
        await this.updateBlockValues({ limit: itemCount, seqOffset: null, order: sortby.order_desc ? 'DESC' : 'ASC' })
      else {
        await this.updateBlockValues({ limit: itemCount, seqOffset: next ? lastMC : firstMC, cutPage: pagination.page, order: sortby.order_desc ? 'DESC' : 'ASC' })
      }
      this.setExtraFields()
    },
    setPage (page) {
      this.pagination.page = page
    }
  },
  mounted () {
    this.updateValues()
  }
}
</script>