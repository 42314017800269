<template>
  <div>
    <div v-if="isLoaded['user_' + phone] === false">
      <Loader />
    </div>
    <div v-else-if="isLoaded['user_' + phone] && !user">
      <NotFound />
    </div>
    <div v-else>
      <div
        v-if="user"
        class="uk-card uk-card-default uk-padding wa-item"
      >
        <h3 class="uk-card-title uk-text-left ">
          {{ $t('tgcp.user_details.general_info_title') }}
        </h3>
        <div class="uk-card-body  uk-grid">
          <div class="uk-position-right uk-padding">
            <CardToProfileButton
              :card="userCard"
              :profile-name-by-default="user.jid || user.phone"
              boundary=".wa-item"
            />
            <AddStoredButton
              provider="whatsapp"
              type="account"
              :data="user"
            />
          </div>
          <div
            class="uk-grid-small uk-margin uk-width-1-2"
            uk-grid
          >
            <div class="uk-width-1-3@m">
              <AvatarList
                v-if="avatarCdnWithoutNull(user).avatars.length"
                :data="avatarCdnWithoutNull(user)"
              />
              <Avatar
                v-else
                :avatar="user.avatar_cdn_path"
              />
            </div>
            <div class="uk-width-expand uk-text-left">
              <h3 class="uk-margin-remove-bottom">
                {{ user.name }}
              </h3>
              {{ $t('whatsapp.user-details.phone') }}
              <router-link
                class="uk-margin-top"
                :to="{
                  name: 'phoneResults',
                  params: { phone: normalizePhone(user.phone) }}"
              >
                {{ normalizeWAPhone(user.phone) }}
              </router-link> <a
                :href="'https://wa.me/' + normalizePhone(user.phone)"
                :uk-tooltip="$t('whatsapp.user-details.open-in-whatsapp')"
                class="uk-margin-small-left"
              >
                <font-awesome-icon :icon="['fab', 'whatsapp']" />
              </a>
              <br/>
              <span v-if="user.country">
                <span
                  :class="`flag-icon flag-icon-${user.country.toLowerCase()}`"
                  style="margin: 0 0 4px 4px;"
                /> {{ countryTitle(user.country) }}
              </span>
              <p v-if="bio">
                {{ bio }}
              </p>
              <Translate
                v-if="bio"
                class="uk-margin-small-right"
                :text="bio"
                :translatedText="translatedBioText"
                :translatedShown="translatedBioShown"
                @translatedText="translatedBioText = $event"
                @translatedShown="translatedBioShown = $event"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="user"
        class="uk-margin-medium-top"
      >
        <ul
          id="tabs"
          uk-tab
        >
          <li
            class="uk-flex"
            :class="checkForTab('overview')"
          >
            <a
              id="overview_tab_header"
              class="uk-padding-remove"
              href="#overview_tab"
            >
              {{ $t('whatsapp.user-details.overview') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('messages')"
          >
            <a
              id="messages_tab_header"
              class="uk-padding-remove"
              href="#messages_tab"
            >
              {{ $t('whatsapp.user-details.messages') }}
              <span class="uk-badge">
                {{ user.messages_count }}
              </span>
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('groups')"
          >
            <a
              id="groups_tab_header"
              class="uk-padding-remove"
              href="#groups_tab"
              @click="activateTab('groups_tab')"
            >
              {{ $t('whatsapp.user-details.groups') }}
              <span class="uk-badge">
                {{ user.groups_count }}
              </span>
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('owned')"
          >
            <a
              id="owned_tab_header"
              class="uk-padding-remove"
              href="#owned_tab"
              @click="activateTab('owned_tab')"
            >
              {{ $t('whatsapp.user-details.owned-groups') }}
              <span class="uk-badge">
                {{ user.owned_groups_count }}
              </span>
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('accounts')"
          >
            <a
              id="accounts_tab_header"
              class="uk-padding-remove"
              href="#accounts_tab"
              @click="activateTab('accounts_tab')"
            >
              {{ $t('whatsapp.user-details.accounts') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <a name="overview_tab"/>
            <BarGraph
              :phone="phone"
              type="groups"
            />
            <LineGraph
              :phone="phone"
              type="day"
            />
            <LineGraph
              :phone="phone"
              type="week"
            />
            <LineGraph
              :phone="phone"
              type="year"
            />
          </li>
          <li>
            <a name="messages_tab"/>
            <MessagesList
              :userPhone="phone"
              type="user"
            />
          </li>
          <li>
            <a name="groups_tab"/>
            <GroupsList
              :userPhone="phone"
              type="user"
            />
          </li>
          <li>
            <a name="owned_tab"/>
            <GroupsList
              :userPhone="phone"
              type="owner"
            />
          </li>
          <li>
            <a name="accounts_tab"/>
            <Accounts
              :identity="user.phone"
              type="whatsapp"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import LineGraph from '@/components/WhatsApp/User/LineGraph'
import BarGraph from '@/components/WhatsApp/User/BarGraph'
import Accounts from '@/views/GlobalSearch/Accounts/Accounts'
import MessagesList from '@/views/WhatsApp/WhatsappMessagelist'
import GroupsList from '@/views/WhatsApp/WhatsappGroupsList'
import { mapActions, mapGetters } from 'vuex'
import countries from '@/utils/countryCodesNamesFull.json'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import GeneralUser from '@/utils/providers/generalUser'
import Avatar from '@/components/WhatsApp/Avatar'
import AvatarList from '@/components/WhatsApp/AvatarList'
import { normalizeWAPhone } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'WhatsappUserDetail',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    CardToProfileButton: defineAsyncComponent(() => import('@/components/CardToProfileButton')),
    Translate: defineAsyncComponent(() => import('@/components/Translate')),
    Loader,
    MessagesList,
    GroupsList,
    LineGraph,
    BarGraph,
    Accounts,
    AddStoredButton,
    AvatarList,
    Avatar
  },
  props: {
    phone: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      aciveTab: [],
      translatedBioShown: false,
      translatedBioText: ''
    }
  },
  watch: {
    phone () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('whatsapp', [
      'isLoaded',
      'allUsers'
    ]),
    ...mapGetters('fetcher', [
      'allAccounts'
    ]),
    user () {
      return this.allUsers[this.phone]
    },
    bio () {
      return this.translatedBioShown ? this.translatedBioText : this?.user?.status || ''
    },
    avatar () {
      if (!this.user.avatar_cdn_path) return false
      return this.$gateway(this.user.avatar_cdn_path)
    },
    userCard () {
      const card = new GeneralUser()
      card.location = this.user.country
      card.type = 'whatsapp'
      card.genericType = 'messenger'
      card.externalLink = false
      card.phone = '+' + this.user.phone
      card.user_id = this.user.jid
      card.avatar = this.$gateway(this.user.avatar_cdn_path)

      return card
    }
  },
  methods: {
    ...mapActions('whatsapp', [
      'fetchUserByPhone'
    ]),
    normalizeWAPhone,
    avatarCdnWithoutNull (user) {
      try {
        const avatars = user.avatars.filter(avatar => avatar['avatar_cdn_path'])
        return {
          ...user,
          avatars
        }
      } catch (error) {
        console.debug(error)
      }
    },
    countryTitle: function (code) {
      return countries[code]
    },
    activateTab (tab) {
      !this.aciveTab.includes(tab) &&
        this.aciveTab.push(tab)
    },
    checkForTab () {
      return 'uk-active'
    },
    update () {
      this.fetchUserByPhone(this.phone)
    },
    normalizePhone (value) {
      if (!value) return ''
      value = value.replace('@c.us', '')
      if (value.indexOf('+') !== 0) value = '+' + value
      return value
    }
  },
  mounted () {
    this.update()
  }
}
</script>
