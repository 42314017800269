<script>
import { toBase64Web } from '@/utils/ton-filters'
import Loader from '@/components/TGCP/Loader'
import PropLine from '../Atoms/PropLine'
import CopyableText from '../Atoms/CopyableText'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import BalanceCell from '@/components/Crypto/Ton/Atoms/BalanceCell'
import StatusCell from '../Atoms/StatusCell'

export default {
  name: 'AccPropsTable',
  components: {
    PropLine,
    CopyableText,
    AddressField,
    StatusCell,
    BalanceCell,
    Loader
  },
  props: {
    acc : {
      type: Object,
      required: true
    },
    short: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    externalLink () { 
      return {
        'Ton.cx': `https://ton.cx/address/${this.acc.address.base64}`,
        'Toncoin': `https://explorer.toncoin.org/account?workchain=&shard=&seqno=&roothash=&filehash=&account=${this.acc.address.base64}`,
        'TonWhales': `https://tonwhales.com/explorer/address/${this.acc.address.base64}`,
        'Ton.sh': `https://ton.sh/address/${this.acc.address.base64}`,
        'Tonviewer': `https://tonviewer.com/${this.acc.address.base64}`,
        'tonscan.org' : `https://tonscan.org/address/${this.acc.address.base64}`,
        'Ton NFT': `https://explorer.tonnft.tools/address/${this.acc.address.base64}`,
        'TonObserver': `https://tonobserver.com/explorer/info?address=${this.acc.address.base64}`,
        'Anton.tools' : `https://explorer.anton.tools/accounts/${this.acc.address.base64}#overview`,
        'dton': `https://dton.io/a/${this.acc.address.base64}`
      }
    }
  },
  methods: {
    toBase64Web
  }
}
</script>

<template>
  <table 
    v-if="acc" 
    class="uk-table uk-table-middle uk-margin-remove-vertical uk-table-striped"
  >
    <tbody>
      <PropLine 
        v-if="(acc.label && acc.label.name) || short" 
        :bold="true"
      >
        <template #name>
          {{ $t(`crypto.ton.label`) }}
        </template>
        <template #value>
          {{ acc?.label?.name ?? '-' }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.hex`) }}
        </template>
        <template #value>
          <CopyableText :text="acc.address.hex">
            {{ acc.address.hex }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.base64`) }}
        </template>
        <template #value>
          <CopyableText :text="acc.address.base64">
            {{ acc.address.base64 }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.status`) }}
        </template>
        <template #value>
          <StatusCell :status="acc.status"/>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.balance`) }}
        </template>
        <template #value>
          <BalanceCell 
            :balance="acc.balance"
            :full="true"
          />
        </template>
      </PropLine>
      <PropLine v-if="acc.fake">
        <template #name>
          <p class="uk-text-danger uk-margin-remove">
            {{ $t(`crypto.ton.fake`) }}
          </p>
        </template>
        <template #value>
          {{ acc.fake }}
        </template>
      </PropLine>
      <PropLine 
        v-if="acc.types && acc.types.length > 0"
        :wrap="true"
      >
        <template #name>
          {{ $t(`crypto.ton.contract`) }}
        </template>
        <template #value>
          <div
            v-if="acc.loaded || short"
          >
            <router-link 
              v-for="item of acc.types"
              :key="item + acc.address.base64"
              :to="`/crypto/ton/accounts?contract=${item}`"
              class="uk-margin-right"
              uk-icon="icon:link"
              style="line-height: 1.5; color: #DA7D02;"
            >
              {{ item }}
            </router-link>
          </div>
          <Loader 
            v-else
            :ratio="1"
          />
        </template>
      </PropLine>
      <PropLine v-if="acc.minter_address && !short">
        <template #name>
          {{ $t(`crypto.ton.minter`) }}
        </template>
        <template #value>
          <CopyableText
            v-if="acc.loaded" 
            :text="acc.minter_address.base64"
          >
            <AddressField 
              :addr="acc.minter_address"
              :break_word="false"
              :full="true"
            />
          </CopyableText> 
          <Loader 
            v-else
            :ratio="1"
          />
        </template>
      </PropLine>
      <PropLine v-if="acc.owner_address && !short">
        <template #name>
          {{ $t(`crypto.ton.owner`) }}
        </template>
        <template #value>
          <CopyableText
            v-if="acc.loaded"
            :text="acc.owner_address.base64"
          >
            <AddressField 
              :addr="acc.owner_address"
              :break_word="false"
              :full="true"
            />
          </CopyableText>
          <Loader 
            v-else
            :ratio="1"
          />
        </template>
      </PropLine>
      <PropLine v-if="!short">
        <template #name>
          {{ $t(`crypto.ton.block`) }}
        </template>
        <template #value>
          <CopyableText
            v-if="acc.loaded" 
            :text="`${acc.workchain}:${acc.shard}:${acc.block_seq_no}`"
          >
            <router-link 
              :to="`/crypto/ton/blocks/block?workchain=${acc.workchain}&shard=${acc.shard}&seq_no=${acc.block_seq_no}#overview`"
            >
              {{ `${acc.workchain}:${acc.shard}:${acc.block_seq_no}` }}
            </router-link>
          </CopyableText>
          <Loader 
            v-else
            :ratio="1"
          />
        </template>
      </PropLine>
      <PropLine v-if="acc.last_tx_hash && !short">
        <template #name>
          {{ $t(`crypto.ton.last_tx_hash`) }}
        </template>
        <template #value>
          <CopyableText
            v-if="acc.loaded"
            :text="acc.last_tx_hash"
          >
            <router-link 
              :to="`/crypto/ton/transaction/${toBase64Web(acc.last_tx_hash)}#overview`"
            >
              {{ acc.last_tx_hash }}
            </router-link>
          </CopyableText>
          <Loader 
            v-else
            :ratio="1"
          />
        </template>
      </PropLine>    
      <PropLine v-if="acc.data && !short">
        <template #name>
          {{ $t(`crypto.ton.data`) }}
        </template>
        <template #value>
          <CopyableText :text="acc.data">
            {{ acc.data }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine v-if="acc.data_hash && !short">
        <template #name>
          {{ $t(`crypto.ton.data_hash`) }}
        </template>
        <template #value>
          <CopyableText :text="acc.data_hash">
            {{ acc.data_hash }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine v-if="acc.code && !short">
        <template #name>
          {{ $t(`crypto.ton.code`) }}
        </template>
        <template #value>
          <CopyableText :text="acc.code">
            {{ acc.code }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine v-if="acc.code_hash && !short">
        <template #name>
          {{ $t(`crypto.ton.code_hash`) }}
        </template>
        <template #value>
          <CopyableText :text="acc.code_hash">
            {{ acc.code_hash }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine 
        v-if="acc.label?.categories && acc.label.categories.length > 0 && !short" 
        :wrap="true"
      >
        <template #name>
          {{ $t(`crypto.general.categories`) }}
        </template>
        <template #value>
          <p 
            v-for="cat of acc.label.categories"
            :key="cat + acc.address.base64"
            class="uk-margin-remove-vertical uk-margin-right uk-display-inline"
            :class="{'red' : cat === 'scam'}"
          >
            {{ cat[0].toUpperCase() + cat.replaceAll('_', ' ').slice(1,) }}
          </p>
        </template>
      </PropLine>
      <PropLine 
        v-if="!short"
        :wrap="true"
      >
        <template #name>
          {{ $t(`crypto.general.external`) }}
        </template>
        <template #value>
          <template v-for="key of Object.keys(externalLink)">
            <a 
              v-if="externalLink[key]"
              :key="key"
              :href="externalLink[key]"
              class="uk-margin-right"
              uk-icon="icon:link"
              target="_blank"
              style="line-height: 1.5; color: #DA7D02;"
            >
              {{ key }}
            </a>
          </template>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.updated_at`) }}
        </template>
        <template #value>
          {{ new Date(acc.updated_at).toLocaleString() }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ "" }}
        </template>
        <template #value>
          <router-link 
            uk-icon="icon: forward"
            :to="`/crypto/ton/${short ? 'account' : 'address'}/${acc.address.hex}`"
            style="line-height: 1.5; color: #DA7D02;"
          >
            {{ $t(`crypto.${short ? 'full' : 'less'}-details`) }}
          </router-link>
        </template>
      </PropLine>
    </tbody>
  </table>
</template>