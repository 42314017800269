<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            v-if="type === 'all'"
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-large"
            />
            <button
              type="submit"
              class="uk-button uk-button-primary uk-search-icon"
            >
              <span uk-search-icon />
            </button>
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <template v-else>
          <Item
            :guilds="guilds"
            class="uk-margin-top"
          />
        </template>
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'DiscordGuildList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Discord/GuildList/Item')),
    Paginate,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all'].includes(val)
    }
  },
  data () {
    return {
      guilds: [],
      query: {
        search: this.$route.query.search || '',
        page: this.$route.query.page || 1
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.update()
    }
  },
  computed: {
    ...mapGetters('discord', ['isLoaded']),
    hasDataToView () {
      return this.guilds.length > 0
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < 12
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / 12)
      return this.pagination.total / lastPage - 12 === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded['discoverable' + this.type] : this.isLoaded['discoverable']
    }
  },
  methods: {
    ...mapActions('discord', ['fetchDiscoverableGuilds', 'addParamToQuery']),
    init () {
      const page = +this.query.page
      this.setPage(page)
    },
    setPage (page) {
      this.pagination.page = page
      this.update()
    },
    setData (guilds) {
      this.guilds = guilds.data
      this.pagination.total = Math.min(1000, guilds.results_count)
    },
    searchHandler () {
      this.setPage(1)
    },
    handleError () {
      this.guilds = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    update () {
      let { type } = this
      const data = {
        id: this.id,
        query: { ...this.query, page: this.pagination.page }
      }
      if (type === 'all') {
        this.addParamToQuery(data.query)
      } else return
      const opts = {
        all: ['fetchDiscoverableGuilds', data.query]
      }
      const [method, payload] = opts[type]

      return this[method](payload)
        .then(guilds => this.setData(guilds))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>
  