import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class TgcpChannelsAll extends Base {
  schema = Schemas.tgcp.channel
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields']
  STATS_FIELDS = ['admins_count', 'all_admins_count', 'all_users_count', 'first_message_date', 'former_admins_count', 'former_users_count', 'last_message_date', 'last_sync', 'media_messages_count', 'messages_count', 'photos_count', 'users_count']
  fetch = async ({ dispatch, options }) => {
    const { query, channels, allowedFields } = options
    if (!!channels && channels.length) {
      return channels
    } else {
      const statsFlag = allowedFields.some(r => this.STATS_FIELDS.includes(r))
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      let entry = Array(1)
      let continueFlag = true
      while (entry.length && localQuery.offset < 5000 && continueFlag) {
        entry = await dispatch('fetchAllChannels', localQuery)
        if (entry.length < 100) continueFlag = false
        if (statsFlag)
          for (const index in entry) {
            try {
              const data = await dispatch('getStats', {
                id: entry[index].tg_id,
                type: 'chats'
              })
              entry[index] = {
                ...entry[index],
                ...data
              }
            } catch (error) {
              console.debug(error)
            }
          }
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
      }
      return entries
    }
  }
}

export default TgcpChannelsAll
