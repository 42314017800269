<template>
  <div class="uk-margin-top">
    <div class="uk-margin">
      <form @submit.prevent="search">
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
          :placeholder="`Zalo Usern\u200came or P\u200chone Nu\u200cmber`"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click="search"
        >
          <span uk-search-icon/>
        </button>
      </form>
    </div>
    <div
      class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
      uk-grid
    >
      <div >
        <router-link
          to="/zalo/user"
          class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3 v-else-if="statsProblem">
            &mdash;
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.total_users_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.users_count') }}
          </h3>
        </router-link>
      </div>
      <div >
        <div
          to="/tgcp/channel"
          class="uk-card uk-card-small  uk-card-primary uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3 v-else-if="statsProblem">
            &mdash;
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.mobile_no_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            MSISDNs
          </h3>
        </div>
      </div>
      <div >
        <router-link
          to="/zalo/user"
          class="uk-card uk-card-small  uk-card-secondary uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3 v-else-if="statsProblem">
            &mdash;
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.full_profiles_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('zalo.full-profiles') }}
          </h3>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
import { numberFormat } from '@/filters/globalFilters'
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'

export default {
  name: 'ZaloStats',
  components: {
    Loader
  },
  data () {
    return {
      searchString: ''
    }
  },
  computed: {
    statsProblem: function () {
      return this.$store.state.zalo.statsProblem
    },
    isLoaded: function () {
      return this.$store.state.zalo.isLoaded['stats']
    },
    stats: function () {
      return this.$store.state.zalo.stats
    }
  },
  methods: {
    ...mapActions({ update: 'zalo/fetchStats' }),
    numberFormat,
    validateEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    search () {
      let search = this.searchString
      if (parseMax(search, 'RU')) {
        try {
          if (parseMax(search, 'RU').isValid()) {
            search = parseMax(search, 'RU').format('INTERNATIONAL').split(' ').join('')
            this.$router.push({ name: 'zalo_user_list', query: { search_type: 'phone_no', search } })
          } else if (parseMax('+' + search).isValid()) {
            search = parseMax('+' + search).format('INTERNATIONAL').split(' ').join('')
            this.$router.push({ name: 'zalo_user_list', query: { search_type: 'phone_no', search } })
          } else {
            this.$router.push({ name: 'zalo_user_list', query: { search_type: 'display_name', search } })
          }
        } catch (e) {
          if (this.validateEmail(search)) {
            this.$router.push({ name: 'zalo_user_list', query: { search_type: 'display_name', search } })
          } else {
            this.$router.push({ name: 'zalo_user_list', query: { search_type: 'display_name', search } })
          }
        }
      } else if (parseMax('+' + search)) {
        if (parseMax('+' + search).isValid()) {
          search = parseMax('+ ' + search).format('INTERNATIONAL').split(' ').join('')
          this.$router.push({ name: 'zalo_user_list', query: { search_type: 'phone_no', search } })
        } else {
          this.$router.push({ name: 'zalo_user_list', query: { search_type: 'display_name', search } })
        }
      } else {
        this.$router.push({ name: 'zalo_user_list', query: { search_type: 'display_name', search } })
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
