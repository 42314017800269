<template>
  <VueSlider
    ref="slider"
    v-model="result"
    :data="range"
    :options="options"
    :tooltip="tooltip"
    :marks="marks"
  />
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'VueSliderBar',
  components: {
    VueSlider
  },
  props: {
    value: {
      required: true,
      type: null
    },
    range: {
      required: true,
      type: Array
    },
    min: {
      required: true,
      type: Number,
      default: 1
    },
    max: {
      required: true,
      type: Number,
      default: 8
    },
    marks: {
      required: false,
      type: null,
      default: true
    },
    tooltip: {
      type: String,
      default: 'always'
    }
  },
  data () {
    return {
      options: {
        // https://nightcatsama.github.io/vue-slider-component/
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: false,
        direction: 'ltr',
        data: null,
        min: this.min,
        max: this.max,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltipPlacement: 'top',
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: this.min,
        maxRange: this.max,
        order: false,
        marks: true,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0
      }
    }
  },
  computed: {
    result: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
