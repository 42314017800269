import L from 'leaflet'
import airportIconUrl from '@/assets/mapIcons/airport.png'
export default {
  methods: {
    showAirportPopup (feature, layer) {
      const popupContent =
            '<b>Scalerank</b>: ' + feature.properties.scalerank +
            '<br/><b>Type</b>: ' + feature.properties.type +
            '<br/><b>Name</b>: ' + feature.properties.name +
            '<br/><b>Abbrev</b>: ' + feature.properties.abbrev +
            '<br/><b>Location</b>: ' + feature.properties.location +
            '<br/><b>GPS Code</b>: ' + feature.properties.gps_code +
            '<br/><b>IATA Code</b>: ' + feature.properties.iata_code +
            '</br></br><b>Latitude</b>: ' + feature.geometry.coordinates[1] +
            '</br><b>Longitude</b>: ' + feature.geometry.coordinates[0] +
            '</br></br><a href=' + feature.properties.wikipedia + ' target="_blank" rel="noopener noreferrer">Wikipedia</a> ' +
            '</br></br><b>Created</b>: ' + feature.properties.created_at +
            '</br><b>Updated</b>: ' + feature.properties.updated_at

      layer.bindPopup(
        popupContent,
        { autoPan: true }
      )
      layer.options.icon = L.icon({
        iconUrl: airportIconUrl,
        iconSize: [25, 25],
        popupAnchor: [1, -7]
      })
    },
    async addAirports () {
      // let geojson = await import('@/components/map/geojson/airports.json')
      let geojson = await fetch('/geojson/airports.json')
      geojson = await geojson.json()
      window.airports.addLayer(
        L.geoJson(geojson, {
          onEachFeature: this.showAirportPopup
        })
      )
    }
  },
  created () {
    window.airports = new L.MarkerClusterGroup({
      disableClusteringAtZoom: 18,
      animate: false,
      iconCreateFunction: function (cluster) {
        let count = cluster.getChildCount()

        let c = ' airport-marker-cluster-'
        if (count < 10) c += 'small'
        else if (count < 50) c += 'medium'
        else c += 'large'
        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        })
      }
    })
  },
  beforeUnmount () {
    if (window.airports) {
      window.airports.clearLayers()
    }
  }
}
