<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t(`tgcp.user_details.${type}_activity_title`) }}
        </h3>
      </div>
      <div
        class="uk-card-body uk-height-max-large"
        style="min-height: 449px"
      >
        <Loader
          v-if="!isLoaded['hashtag_' + id + '_activity_' + type]"
        />
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TwitterHashtagLineGraph',
  components: {
    Loader,
    VueApexCharts
  },
  props: {
    id: {
      required: true,
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'day',
      validator: val => ['day', 'week', 'year'].includes(val)
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          formatter: val => +val > 2 ? val : ''
        },
        xaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_hours')
          }
        },
        yaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          },
          min: 0
        }
      }
    }
  },
  watch: {
    hashtags: {
      handler (newVal, oldVal) {
        if (oldVal !== newVal)
          this.update()
      },
      deep: true
    },
    id: {
      handler () {
        this.update()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('twitter', [
      'allHashtags',
      'isLoaded'
    ]),
    // chartOptions () {
    //   var type = this.popup
    //   return {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     legend: {
    //       display: false
    //     },

    //   }
    // },
    hashtags () {
      return this.allHashtags[this.id]
    },
    popup () {
      switch (this.type) {
          case 'day': return this.$t('graph.hour')
          case 'week': return this.$t('graph.day')
          case 'year': return this.$t('graph.month')
          default: return this.$t('graph.hour')
      }
    },
    chatsActivity () {
      var activity = this.allHashtags[this.id]['activity'][this.type]['activity']
      const series = [{
        name: this.$i18n.t('tgcp.user_details.graph_messages'),
        data: []
      }]
      for (var i in activity) {
        let counter
        switch (this.type) {
            case 'day':
              counter = Number(i)
              break
            case 'week':
              counter = this.$i18n.t('tgcp.user_details.graph_weekdays_' + i)
              break
            case 'year':
              counter = this.$i18n.t('tgcp.user_details.graph_months_' + i)
        }
        series[0].data.push({
          y: activity[i],
          x: counter
        })
      }
      return series
    }
  },
  methods: {
    ...mapActions('twitter', [
      'fetchHashtagActivityById'
    ]),
    update () {
      if (this.allHashtags[this.id]) {
        return this.fetchHashtagActivityById({
          id: this.id,
          type: this.type
        })
      }
    }
  }
}
</script>
