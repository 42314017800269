<template>
  <div class="uk-card uk-card-default uk-card-small">
    <div
      v-if="forum"
      class="uk-card-body uk-position-relative"
      :class="{ 'hide-nsfw' : hideForum} "
    >
      <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
        <AddStoredButton
          v-if="showStoredButton"
          provider="forums"
          type="forum"
          :data="forum"
        />
      </div>
      <div
        class="uk-grid-small uk-flex-middle"
        uk-grid
      >
        <div class="uk-width-expand@m">
          <div class="uk-width-expand@m  uk-text-left">
            <h3 class="uk-margin-remove-top uk-margin-remove-bottom">
              {{ localGroup.title || localGroup.name }}
              <span
                class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted"
              >
                #{{ forum.id }}
              </span>
              <button
                class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
                @click="translateName"
              >
                <font-awesome-icon
                  :uk-tooltip="translated.title || translated.name ? $t('tgcp.channels.show-original') : $t('tgcp.channels.translate')"
                  :icon="['fa', 'language']"
                  :class="translated.title || translated.name ? 'uk-text-primary' : 'uk-text-default'"
                />
                <span v-if="translated.title || translated.name">
                  {{ $t('tgcp.channels.show-original') }}
                </span>
                <span v-else>
                  {{ $t('tgcp.channels.translate') }}
                </span>
              </button>
            </h3>
          </div>
          <div class="uk-width-auto uk-align-left uk-text-left">
            <span v-if="forum.url">
              {{ forum.url }} <a
                :href="forum.url"
                uk-icon="link"
                class="ui-link"
              />
            </span>
            <div
              v-if="localGroup.description"
              class="uk-text-meta uk-margin-small-top uk-margin-right"
            >
              <span v-html="extractedDataDescription"/><button
                class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
                @click="translateDescription"
              >
                <font-awesome-icon :icon="['fa', 'language']" />
                <span v-if="translated.description">
                  {{ $t('tgcp.channels.show-original') }}
                </span>
                <span v-else>
                  {{ $t('tgcp.channels.translate') }}
                </span>
              </button>
              <br />
            </div>
          </div>
          <div class="uk-clearfix" />
          <ul
            v-if="forum.first_message_timestamp || forum.last_message_timestamp"
            class="uk-list uk-list-divider uk-width-auto uk-text-small uk-text-left uk-align-left"
          >
            <li v-if="forum.first_message_timestamp">
              {{ $t('forums.list.first_message_timestamp') }}: {{ dayjs(forum.first_message_timestamp) }}
            </li>
            <li
              v-if="forum.last_message_timestamp"
            >
              {{ $t('forums.list.last_message_timestamp') }}: {{ dayjs(forum.last_message_timestamp) }}
            </li>
          </ul>
        </div>
        <div class="uk-width-auto uk-text-left my-width">
          <ul class="uk-list uk-list-divider">
            <li>
              {{ $t('forums.list.users') }} {{ forum.users_count }}
            </li>
            <li>
              {{ $t('forums.list.messages') }} {{ forum.messages_count }}
            </li>
            <li>
              {{ $t('forums.list.topics') }} {{ forum.topics_count }}
            </li>
          </ul>
        </div>
        <div class="uk-width-1-5@m">
          <div class="">
            <router-link
              :to="{
                name: 'forums_forum_details',
                params: { id: String(forum.id) }}"
              class="uk-button uk-button-primary uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            >
              {{ $t('tgcp.channels.full_info') }}
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="hideForum"
        class="uk-position-absolute uk-position-top-center uk-padding unhide-block uk-text-bold uk-text-large"
        @click="hideForum = false"
      >
        {{ $t('forums.show-nsfw') }}
      </div>
    </div>
  </div>
</template>

<script>
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import extractData from '@/utils/dataExtraction'
import translate from '@/mixins/translate'
import { mapGetters } from 'vuex'

export default {
  name: 'ForumsForumItem',
  components: {
    AddStoredButton
  },
  mixins: [translate],
  props: {
    forum: {
      type: Object,
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      localGroup: { ...this.forum },
      translated: {
        title: false,
        name: false,
        description: false
      },
      hideNsfw: true
    }
  },
  computed: {
    ...mapGetters(['settings']),
    extractedDataDescription () {
      if (!this.forum) return
      return extractData(this.localGroup.description)
    },
    hideForum: {
      get () {
        return this.$route.name === 'forums_list' && this.settings?.blur_nsfw_forums && this.forum?.nsfw && this.hideNsfw
      },
      set (value) {
        this.hideNsfw = value
      }
    }
  },
  methods: {
    async translateName () {
      if (this.forum.title) {
        const { title } = await this.translateProps(['title'], this.localGroup, this.forum)
        this.localGroup.title = title || this.localGroup.title
      } else {
        const { name } = await this.translateProps(['name'], this.localGroup, this.forum)
        this.localGroup.name = name || this.localGroup.name
      }
    },
    async translateDescription () {
      const { description } = await this.translateProps(['description'], this.localGroup, this.forum)
      this.localGroup.description = description || this.localGroup.description
    }
  }
}
</script>
<style>
.my-width {
  min-width: 200px
}
</style>
