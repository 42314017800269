<template>
  <div class="home uk-container">
    <form>
      <div class="uk-margin-top">
        <form @submit.prevent="search">
          <input
            v-model.trim="searchString"
            class="uk-input uk-form-width-medium uk-form-width-large"
            type="text"
            :placeholder="searchPlaceHolder"
          />
          <vueSelect
            v-model="currentSearchType"
            :options="searchTypeTranslated"
            label="label"
            class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
          />
          <button
            class="uk-button uk-button-primary uk-search-icon"
            :disabled="!isValidSearchString"
            @click="search"
          >
            <span uk-search-icon/>
          </button>
        </form>
      </div>
    </form>
    <div
      class="uk-grid"
      data-uk-grid-margin
    >
      <div
        v-if="introAvailable"
        class="uk-width-2-3@m uk-align-center"
      >
        <NewsList/>
      </div>
    </div>
  </div>
</template>

<script>
import NewsList from '@/components/news/NewsList.vue'
import vueSelect from '@/components/VueSelect'
import mainSearch from '@/utils/search'
import restrictions from '../config/restrictions'

export default {
  name: 'Home',
  components: {
    NewsList,
    vueSelect
  },
  data () {
    return {
      searchString: '',
      searchType: 'auto'
    }
  },
  computed: {
    introAvailable () {
      const userId = this.$store.state.current_user_id
      if (restrictions[userId]) {
        if (restrictions[userId].module.indexOf('intro') > -1) {
          return false
        }
      }
      return true
    },
    isValidSearchString () {
      return this.searchString.length
    },
    currentSearchType: {
      get () {
        return this.searchType
      },
      set (obj) {
        this.searchType = obj && obj.value
      }
    },
    searchTypeTranslated () {
      // disabled fields: address, geolocation, organization, password
      return [
        { value: 'auto', label: this.$t('search.auto_select') },
        { value: 'phone', label: this.$t('search.phone_select') },
        { value: 'nickname', label: this.$t('search.nickname_select') },
        { value: 'ip', label: this.$t('search.ip_select') },
        { value: 'domain', label: this.$t('search.domain_select') },
        { value: 'email', label: this.$t('search.email_select') },
        { value: 'name', label: this.$t('search.name_select') },
        { value: 'password', label: this.$t('search.password_select'), disabled: true },
        { value: 'address', label: this.$t('search.address_select'), disabled: true },
        { value: 'geo', label: this.$t('search.geo_select'), disabled: true },
        { value: 'organization', label: this.$t('search.org_select'), disabled: true }
      ]
    },
    searchPlaceHolder () {
      switch (this.searchType) {
          case 'auto':
            return this.$i18n.t('search.search_placeholder')
          case 'phone':
            return this.$i18n.t('search.search_placeholder_phone')
          case 'nickname':
            return this.$i18n.t('search.search_placeholder_nickname')
          case 'ip':
            return this.$i18n.t('search.search_placeholder_ip')
          case 'domain':
            return this.$i18n.t('search.search_placeholder_domain')
          case 'email':
            return this.$i18n.t('search.search_placeholder_email')
      }
      return this.$i18n.t('search.search_placeholder')
    }
  },
  methods: {
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    search () {
      var search = this.searchString
      if (this.searchType !== 'auto') {
        switch (this.searchType) {
            case 'phone':
              this.$router.push({ name: 'phoneResults', params: { phone: search } })
              break
            case 'nickname':
              this.$router.push({ name: 'nicknameResults', params: { nickname: search } })
              break
            case 'ip':
              this.$router.push({ name: 'ipResults', params: { ip: search } })
              break
            case 'domain':
              this.$router.push({ name: 'domainResults', params: { domain: search } })
              break
            case 'email':
              this.$router.push({ name: 'emailResults', params: { email: search } })
              break
            case 'password':
            case 'name':
              this.$router.push({ name: 'nameResults', params: { name: search } })
              break
            case 'geo':
            case 'organization':
            case 'address':
              break
        }
      } else mainSearch(this.$router, search)
    }
  }
}
</script>
