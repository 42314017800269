import Base from '../Base.js'
import Schemas from '../../Schemas'

class CasesAll extends Base {
  schema = Schemas.case
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { query } = options
    let localQuery = {
      ...query,
      offset: 0,
      limit: 100
    }
    let entries = []
    while (localQuery.offset < this.total) {
      const { cases } = await dispatch('fetchCases', localQuery)
      const entry = cases
      entries = entries.concat(entry)
      localQuery.offset += localQuery.limit
      this.loaded = (entries.length / this.total) * 100
    }
    return entries
  }
}

export default CasesAll
