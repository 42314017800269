import axios from 'axios'


const defaultDefinition = {
  pageSize: 'A4',
  footer (currentPage, pageCount) {
    if (currentPage === 1) {
      return ''
    }

    return {
      margin: [0, 10, 0, 0],
      columns: [
        {
          style: 'footer',
          text: `Page ${currentPage} of ${pageCount}`,
          alignment: 'center'
        },
        {
          style: 'footer',
          text: dayjs().format('MM/DD/YYYY'),
          alignment: 'center'
        }
      ]
    }
  },
  pageMargins: [40, 90, 40, 30],
  defaultStyle: {
    font: 'BenderBangla'
  },
  styles: {
    header: {
      fontSize: 18,
      bold: true,
      textAlign: 'center'
    },
    subheader: {
      fontSize: 16,
      bold: true
    },
    footer: {
      color: 'a6a6a6'
    },
    quote: {
      italics: true
    },
    small: {
      fontSize: 8
    }
  }
}

function blobToBase64 (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

class PDF {
  constructor () {
    this.docDefinition = defaultDefinition
    this.docDefinition.content = []

    this.level1Number = 0
    this.level2Number = 0
    this.level3Number = 0
  }

  async getPdfMake () {
    const pdfMake = (await import('pdfmake/build/pdfmake')).default

    pdfMake.fonts = {
      BenderBangla: {
        normal: `${location.origin}/fonts/BenderBangla-LightKr.ttf`,
        bold: `${location.origin}/fonts/BenderBanglaKr.ttf`,
        italics: `${location.origin}/fonts/BenderBangla-LightItalicKr.ttf`,
        bolditalics: `${location.origin}/fonts/BenderBangla-ItalicKr.ttf`
      }
    }

    return pdfMake
  }

  async addLogo () {
    const response = await axios.get('/img/logo.png', {
      responseType: 'blob'
    })

    const image = await blobToBase64(response.data)

    this.docDefinition.header = () => {
      return {
        image,
        width: 50,
        margin: [20, 10, 0, 0]
      }
    }
  }

  async addImage (url) {
    let response = null

    try {
      response = await axios.get(url, {
        responseType: 'blob'
      })
    } catch (error) {
      return
    }

    if (!response.data.size) {
      return
    }

    const image = await blobToBase64(response.data)

    this.addItem({
      image,
      width: 200,
      margin: [0, 2, 0, 5]
    })
  }

  addTOC () {
    this.addItem({
      toc: {
        title: {
          text: 'Contents',
          style: 'header',
          margin: [0, 20, 0, 10]
        },
        numberStyle: {
          bold: true
        }
      }
    })
  }

  addHeader (text, level = 1) {
    const item = {
      tocItem: true,
      margin: [0, 20, 0, 10],
      tocMargin: [0, 10, 0, 0]
    }

    if (level === 1) {
      item.style = 'header'
      this.level1Number++
      this.level2Number = 0
      this.level3Number = 0
      text = this.level1Number + ' ' + text
      item.pageBreak = 'before'
    }

    if (level === 2) {
      item.style = 'subheader'
      this.level2Number++
      this.level3Number = 0
      text = this.level1Number + '.' + this.level2Number + ' ' + text
    }

    if (level === 3) {
      item.style = 'subheader'
      item.tocItem = false
      this.level3Number++
      text = this.level1Number + '.' + this.level2Number + '.' + this.level3Number + ' ' + text
    }

    item.text = text

    this.addItem(item)
  }

  addItem (item) {
    this.docDefinition.content.push(item)
  }

  async download (name) {
    const pdfMake = await this.getPdfMake()

    pdfMake.createPdf(this.docDefinition).download(name)

    return Promise.resolve()
  }
}

export default PDF
