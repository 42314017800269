<template>
  <div>
    <div class="table__pagination uk-flex uk-flex-between uk-flex-middle  uk-flex-wrap uk-flex-right">
      <div
        v-if="hasTotalMSISDN"
        class="uk-margin-small-left"
      >
        {{ $t('pagination_statistics.total_msisdn') }}: {{ numberFormat(totalMSISDN) }}
      </div>
      <div class="uk-margin-small-left">
        {{ paginationStatistic }}
      </div>
    </div>
    <table class="uk-table uk-table-responsive uk-table-striped uk-table-hover uk-table-small uk-table-divider">
      <thead>
        <tr>
          <th
            v-for="item in headerTitles"
            :key="item.column"
            @click="setSort(item.column, $event)"
          >
            <div class="uk-flex uk-flex-between">
              {{ item.title }}
              <span
                v-if="sortColumn === item.column"
                :uk-icon="`icon: ${sortDirection === 'asc' ? 'chevron-down' : 'chevron-up' }`"
              />
            </div>
          </th>
        </tr>
        <tr v-if="realOptions.filterable.length > 0">
          <th
            v-for="item in headerTitles"
            :key="item.column"
          >
            <slot
              v-if="realOptions.filterable.includes(item.column)"
              :name="`filter__${item.column}`"
            >
              <input
                class="uk-input"
                :name="item.column"
                type="text"
                :placeholder="`${$t('tgcp.tasks.filter')} ${item.title}`"
                autocomplete="off"
                @input="setFilter(item.column, $event)"
              />
            </slot>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in paginatedTableData"
          :key="`row_${index}`"
        >
          <td
            v-for="column in columns"
            :key="`cell_${index}_${column}`"
            class="table__cell"
            :class="{
              'uk-text-truncate': column === 'title'
            }"
          >
            <slot
              :name="`cell__${column}`"
              :rowData="item"
            >
              {{ item[column] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table__pagination uk-flex uk-flex-middle  uk-flex-wrap uk-flex-center">
      <div v-if="totalPageCount > 1">
        <Paginate
          v-model="currentPage"
          :page-count="totalPageCount"
          :click-handler="setPage"
          prev-text="&lt;"
          next-text="&gt;"
          container-class="vuejs-pagination-container"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from '@/components/Paginate'
import { numberFormat } from '@/filters/globalFilters'

export default {
  name: 'Table',
  components: {
    Paginate
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      defaultOptions: {
        filterAlgorithm: {},
        filterable: [],
        sortable: [],
        headings: {},
        perPageItemsCount: 10,
        displayPagesCount: 10
      },
      filter: {},
      sortColumn: '',
      sortDirection: 'asc',
      filterDebouncer: null,
      currentPage: 1
    }
  },
  computed: {
    totalMSISDN () {
      const totalMSISDN = this?.options?.totalMSISDN
      if (typeof totalMSISDN === 'number') {
        return totalMSISDN
      }
      return 0
    },
    hasTotalMSISDN () {
      return typeof this?.options?.totalMSISDN === 'number'
    },
    realOptions () {
      return { ...this.defaultOptions, ...this.options }
    },
    headerTitles () {
      let titles = []
      for (let column of this.columns) {
        let title = ''
        if (this.realOptions.headings[column]) {
          if (typeof this.realOptions.headings[column] === 'function') {
            title = this.realOptions.headings[column]()
          } else {
            title = this.realOptions.headings[column]
          }
        } else {
          title = column.toUpperCase().replace('_', ' ')
        }
        titles.push({ column, title })
      }
      return titles
    },
    sortedAndFilteredTableData () {
      if (
        (this.realOptions.filterable.length === 0) &&
        (this.realOptions.sortable.length === 0)
      ) {
        return this.tableData
      }

      let tempData = this.tableData.filter(item => {
        for (let filterColumn in this.filter) {
          const filterValue = this.filter[filterColumn]

          if (!filterValue) {
            continue
          }

          const propertyValue = item[filterColumn]
          const filterAlgorithm =
            this.realOptions.filterAlgorithm[filterColumn] ||
            this.defaultFilterAlgorithm

          const isValid = filterAlgorithm(propertyValue, filterValue)

          if (!isValid) {
            return false
          }
        }

        return true
      })

      if (this.sortColumn !== '') {
        tempData.sort((a, b) => {
          if (Number.isInteger(a[this.sortColumn]) && Number.isInteger(b[this.sortColumn])) return a[this.sortColumn] - b[this.sortColumn]
          const aProp = a[this.sortColumn] || ''
          const bProp = b[this.sortColumn] || ''

          if (typeof aProp === 'string' && typeof bProp === 'string') {
            return aProp.toLowerCase().localeCompare(bProp.toLowerCase())
          } else {
            const aNum = parseFloat(aProp)
            const bNum = parseFloat(bProp)

            return aNum - bNum
          }
        })

        if (this.sortDirection === 'desc') {
          tempData = tempData.reverse()
        }
      }

      return tempData
    },
    paginationOffset () {
      return (this.currentPage * this.realOptions.perPageItemsCount) - this.realOptions.perPageItemsCount
    },
    endOffset () {
      const calculatedEndOffset = this.paginationOffset + this.realOptions.perPageItemsCount
      const totalRows = this.sortedAndFilteredTableData.length
      if (calculatedEndOffset < totalRows) {
        return calculatedEndOffset
      } else {
        return this.sortedAndFilteredTableData.length
      }
    },
    paginatedTableData () {
      return this.sortedAndFilteredTableData.slice(this.paginationOffset, this.paginationOffset + this.realOptions.perPageItemsCount)
    },
    totalPageCount () {
      return Math.ceil(this.sortedAndFilteredTableData.length / this.realOptions.perPageItemsCount)
    },
    paginationStatistic () {
      if (this.totalPageCount > 1) {
        return this.$t('pagination_statistics.manyPages', {
          from: this.paginationOffset + 1,
          to: this.endOffset,
          of: this.sortedAndFilteredTableData.length
        })
      } else {
        return this.$t('pagination_statistics.onePage', { of: this.sortedAndFilteredTableData.length })
      }
    }
  },
  methods: {
    numberFormat,
    defaultFilterAlgorithm (value, filterValue) {
      return value?.toLowerCase().includes(filterValue.toLowerCase())
    },
    setFilter (column, event) {
      const caller = this
      if (this.filterDebouncer) {
        clearTimeout(this.filterDebouncer)
      }
      this.filterDebouncer = setTimeout(() => {
        caller.setPage(1)
        caller.$set(caller.filter, column, event.target.value)
        caller.filterDebouncer = null
      }, 500)
    },
    setSort (column, event) {
      if (!this.realOptions.sortable.includes(column)) return

      if (this.sortColumn === column) {
        if (this.sortDirection === 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
          this.sortColumn = ''
        }
      } else {
        this.sortColumn = column
      }
    },
    setPage (page) {
      this.currentPage = page
    }
  }
}
</script>

<style>
.table__cell {
  text-align: left;
}
.vuejs-pagination-container {
  padding-left: 0;
}
</style>
