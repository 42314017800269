export default {
  data () {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        colors: [ '#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba', '#D60000', '#F46300', '#0358B6', '#44DE28', '#bec1d4', '#d6bcc0', '#bb7784', '#8e063b', '#4a6fe3', '#8595e1', '#b5bbe3', '#e6afb9', '#e07b91', '#d33f6a', '#11c638', '#8dd593', '#c6dec7', '#ead3c6', '#f0b98d', '#ef9708', '#0fcfc0', '#9cded6', '#d5eae7', '#f3e1eb', '#f6c4e1', '#f79cd4' ],
        labels: [],
        dataLabels: {
          formatter: (val, { seriesIndex }) => this.reCalculatePercentage
            ? ((this.series[seriesIndex] * 100) / this.series.reduce((acc, val) => acc + val)).toFixed(1) + '%'
            : val.toFixed(1) + '%'
        },
        legend: {
          position: 'top',
          onItemHover: {
            highlightDataSeries: false
          },
          onItemClick: {
            toggleDataSeries: true
          }
        },
        noData: {
          text: this.$t('osint.monitor.no-data'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        }
      },
      excludedSeries: [],
      reCalculatePercentage: true
    }
  },
  computed: {
    keepPercentageBtn () {
      return {
        icon: `
        <span
        uk-icon="lock"
        uk-tooltip="${this.$t('graph.recalculate-percentage')}"
        />`,
        click: (chart, options, e) => {
          this.reCalculatePercentage = !this.reCalculatePercentage
          this.legendClickHandler()
        }
      }
    }
  },
  methods: {
    legendClickHandler (config, seriesIndex) {
      this.excludedSeries = this.excludedSeries.includes(seriesIndex)
        ? this.excludedSeries.filter(el => el !== seriesIndex)
        : [ ...this.excludedSeries, seriesIndex ]

      let series = [ ...this.series ].map((el, i) => {
        if (this.excludedSeries.includes(i)) {
          el = 0
        }
        return el
      })
      let labels = this.chartOptions.labels.map((el, i) => {
        if (this.excludedSeries.includes(i)) { return `<s>${el}</s>` } else { return el }
      })
      this.$refs.chart.updateOptions({
        chart: {
          toolbar: {
            tools: {
              customIcons: this.excludedSeries.length
                ? [
                  {
                    ...this.keepPercentageBtn,
                    class: this.reCalculatePercentage ? 'uk-margin-medium-top uk-text-primary' : ' uk-margin-medium-top uk-text-muted'
                  }
                ]
                : []
            }
          }
        }
      }, false, false, false)
      this.$refs.chart.updateSeries(series)
      this.$refs.chart.updateOptions({
        labels
      }, false, false, false)
    }
  }
}
