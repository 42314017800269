import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('tgcp', ['translateText']),
    resetTransaltionMixin () {
      for (const key of Object.keys(this.translated)) {
        this.translated[key] = false
      }
    },
    async translateProps (props, obj, original) {
      let expectedCollection = []
      try {
        for (const prop of props) {
          if (
            obj[prop] &&
            !this.translated[prop]
          ) {
            this.translated[prop] = true
            expectedCollection.push(this.translateText({ text: obj[prop] }))
          } else {
            this.translated[prop] = false
            expectedCollection.push(Promise.resolve({
              data: {
                translation: original[prop]
              }
            }))
          }
        }
        const propsCollection = await Promise.allSettled(expectedCollection)
        const translations = props.reduce((accumulator, prop, index) => {
          const { value: { data: { translation } } } = propsCollection[index]
          if (translation) {
            accumulator[prop] = translation
          }
          return accumulator
        }, {})
        return translations
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    }
  }
}
