<template>
  <div class="uk-margin-top">
    <div
      class="uk-grid uk-margin-top"
      data-uk-grid-margin
    >
      <div class="uk-width-expand">
        <loader v-if="loading" />
        <div
          v-else
          class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid"
        >
          <div
            v-for="item in cards"
            :key="item.getId()"
            class="uk-grid-margin"
          >
            <usercard
              :key="item.getId()"
              :generalUser="item"
              :showAddProfileButton="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Threema from '@/utils/providers/threema'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ThreemaPhones',
  components: {
    'usercard': defineAsyncComponent(() => import('@/components/UserCard2')),
    'loader': defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  props: {
    phone: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      loading: false,
      cards: [],
      details: {}
    }
  },
  watch: {
    phone: function (from, to) {
      this.update()
    }
  },
  computed: {
    intPhone: function () {
      const phoneUtil = parseMax(this.phone)
      return phoneUtil.format('INTERNATIONAL')
    },
    phoneArray: function () {
      const phoneUtil = parseMax(this.phone)
      var obj = {}
      obj['type'] = phoneUtil.getType()
      obj['country'] = phoneUtil.country
      obj['national'] = phoneUtil.format('NATIONAL')
      obj['international'] = phoneUtil.format('INTERNATIONAL')
      return obj
    }
  },
  methods: {
    ...mapActions('threema', [
      'fetchProfile'
    ]),
    ...mapActions('cases', [
      'profileAdd'
    ]),
    updateDetails (raw) {
      this.details = raw
    },
    update: async function () {
      try {
        this.loading = true
        const cards = await this.fetchProfile({ searchData: this.phone.replace('+', ''), searchType: 'mobile_no' })
        this.cards = cards.map(el => Threema.formatData(el))
      } catch {
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
