import { apiRequest } from '@/utils/api'
import config from '@/config'

export default {
  namespaced: true,
  state: {
    isLoaded: {},
    statsProblem: false,
    stats: {}
  },
  mutations: {
    resetStatsProblem: (state) => {
      state.statsProblem = false
    },
    statsProblem: (state) => {
      state.statsProblem = true
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addStats: (state, stats) => {
      state.stats = stats
    }
  },
  actions: {
    async fetchProfile ({ dispatch, commit, state, rootState }, {searchData, searchType}) {
        const { data } = await apiRequest(`/search/${searchType}?q=` + encodeURIComponent(searchData), 'GET', {}, config.servers.threema)
        return data.identities
    },
    fetchStats ({ dispatch, commit, state, rootState }) {
      commit('startLoad', 'stats')
      commit('resetStatsProblem')
      apiRequest(`/stats`, 'GET', {}, config.servers.threema)
        .then(data => {
          let stats = data.data
          commit('addStats', stats)
        })
        .catch(err => {
          commit('statsProblem')
          console.debug(err)
        })
        .finally(() => {
          commit('stopLoad', 'stats')
        })
    }
  }
}
