import { apiRequest } from '@/utils/api'
import config from '@/config'

class Leak {
  _limit = 500
  genericType = 'leak'
  findByLogin (login) {
    return this._loadDataByType('login', login)
  }
  findByEmail (email) {
    return this._loadDataByType('email', email)
  }
  findByDomain (domain) {
    return this._loadDataByType('domain', domain)
  }
  _loadDataByType (type, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        apiRequest(`${type}/${data}`, 'GET', {}, config.servers.leaks)
          .then(res => {
            const result = {
              isOverLimit: res.data.length > this._limit,
              items: res.data.length > this._limit ? res.data.slice(0, this._limit) : res.data
            }
            result.items = result.items.map(item => this._transformLeak(item))
            resolve(result)
          })
          .catch(err => {
            reject(err)
          })
      } else {
        reject(new Error(`Empty ${type}`))
      }
    })
  }
  _transformLeak (leak) {
    const loginPasswordPair = leak.line.split(':')
    let login = ''
    let password = ''
    if (loginPasswordPair && loginPasswordPair.length > 1) {
      login = loginPasswordPair.splice(0, 1)
      password = loginPasswordPair.join(':')
    } else if (loginPasswordPair.length === 1) {
      login = loginPasswordPair.splice(0, 1)
    }
    return {
      login: login[0] || '',
      password: password,
      sources: leak.sources || [],
      sourcesString: leak.sources ? leak.sources.join(', ') : ''
    }
  }
}
export default Leak
