import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class TwitterGroupsTogether extends Base {
  schema = Schemas.tiktok.hashtag
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields', 'id']
  fetch = async ({ dispatch, options }) => {
    const { id, query, groups } = options
    if (!!groups && groups.length) {
      return groups
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      while (localQuery.offset < this.total) {
        const { data } = await dispatch('fetchTogetherHashtags', { id, query: localQuery})
        const entry = data.map(item => { return {...item, posts_count: item.count}})
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default TwitterGroupsTogether
