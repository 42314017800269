<template>
  <div>
    <router-link
      :to="{name: 'tgcp_user_detail_graph', params: { userId: String(id) } } "
      class="uk-button uk-button-primary"
    >
      {{ $t('tgcp.user_details.open_graph') }}
    </router-link>
    <div v-if="!isLoaded">
      <Loader/>
    </div>
    <div v-else>
      <table class="uk-table">
        <tr >
          <th class="uk-text-center">
            {{ $t('tgcp.user_details.replies_user_title') }}
          </th><th class="uk-text-center">
            {{ $t('tgcp.user_details.replies_reply_title') }}
          </th><th class="uk-text-center">
            {{ $t('tgcp.user_details.back_replies_user_title') }}
          </th>
        </tr>
        <tr
          v-for="(reply, i) in replies"
          :key="reply.author.tg_id + i"
        >
          <td>
            <router-link :to="{name: 'tgcp_user_detail_overview', params: { id: String(reply.author.tg_id) } }">
              <span v-if="reply.author.first_name || reply.author.last_name || reply.author.username">
                {{ reply.author.first_name }} {{ reply.author.last_name }} <span v-if="reply.author.username">
                  (@{{ reply.author.username }})
                </span>
              </span>
              <span v-else>
                #{{ reply.author.tg_id }}
              </span>
            </router-link>
          </td>
          <td>
            {{ reply.replies }}
          </td>
          <td>
            {{ reply.back_replies }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'

export default {
  components: {
    Loader
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  computed: {
    replies: function () {
      return this.$store.state.tgcp.users[this.id]['replies']
    },
    isLoaded: function () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id + '_replies']
    }
  }
}
</script>
