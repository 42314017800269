<template>
  <div
    id="ipInsight"
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h3 class="uk-modal-title uk-text-center">
        {{ $t('ip.IP-address-insights') }}
      </h3>
      <div class="uk-card-body">
        <Loader v-if="!isLoaded" />
        <div v-else>
          <table class="uk-table">
            <tr>
              <th colspan="2">
                {{ $t('ip.location-data') }}
              </th>
            </tr>
            <tr v-if="country.name">
              <td>
                {{ $t('ip.country') }}
              </td><td>
                <a
                  :href="`https://www.google.com/maps/place/${country.name}/`"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ country.name }}
                </a> ({{ country.confidence }} %)
              </td>
            </tr><tr v-if="city.name">
              <td>
                {{ $t('ip.city') }}
              </td><td>
                <a
                  :href="`https://www.google.com/maps/place/${city.name}/`"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ city.name }}
                </a> ({{ city.confidence }} %)
              </td>
            </tr><tr>
              <td>
                {{ $t('ip.coordinates') }}
              </td><td>
                <router-link :to="{name:'global_map_by_coords', params: {coords:`${ipInsight.location.latitude},${ipInsight.location.longitude}`}}">
                  <span uk-icon="icon: location"/>{{ ipInsight.location.latitude }}, {{ ipInsight.location.longitude }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th colspan="2">
                {{ $t('ip.ip-address-information') }}
              </th>
            </tr>
            <tr v-if="ipInsight.traits.is_anonymous">
              <td colspan="2">
                {{ $t('ip.anonymous-network') }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.is_vpn">
              <td colspan="2">
                {{ $t('ip.vpn-network') }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.is_hosting_provider">
              <td colspan="2">
                {{ $t('ip.hosting-provider') }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.is_public_proxy">
              <td colspan="2">
                {{ $t('ip.public proxy') }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.is_tor_exit_node">
              <td colspan="2">
                {{ $t('ip.tor-exit-node') }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.organization">
              <td>
                {{ $t('ip.organization') }}
              </td><td>
                {{ ipInsight.traits.organization }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.isp">
              <td>
                {{ $t('ip.isp') }}
              </td><td>
                {{ ipInsight.traits.isp }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.autonomous_system_organization">
              <td>
                {{ $t('ip.autonomous-system-organization') }}
              </td><td>
                {{ ipInsight.traits.autonomous_system_organization }}
              </td>
            </tr>
            <tr v-if="ipInsight.traits.domain">
              <td>
                {{ $t('ip.domain') }}
              </td><td>
                {{ ipInsight.traits.domain }}
              </td>
            </tr>
            <tr>
              <td/><td/>
            </tr>
          </table>
        </div>
        <button
          class="uk-modal-close uk-button uk-button-secondary"
          type="button"
        >
          {{ $t('ip.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'IpInsight',
  components: {
    Loader
  },
  props: {
    ip: {
      required: true,
      type: String
    }
  },
  watch: {
    ip (from, to) {
      this.update()
    }
  },
  computed: {
    city () {
      return this.getPlace('city')
    },
    country () {
      return this.getPlace('country')
    },
    ipInsight () {
      return this.$store.state.fetcher.ipInsights[this.ip]
    },
    isLoaded () {
      return this.$store.state.fetcher.isLoaded['ip_' + this.ip]
    }
  },
  methods: {
    update () {
      const action = 'fetcher/fetchIpInsight'
      this.$store.dispatch(action, this.ip)
    },
    getPlace (place) {
      const currentLanguage = this.$store.getters.getCurrentLanguage
      const city = this.ipInsight[place]
      if (city) {
        const returnData = {
          confidence: city.confidence
        }
        if (city.names[currentLanguage]) {
          returnData['name'] = city.names[currentLanguage]
        } else {
          returnData['name'] = city.names['en']
        }
        return returnData
      }
      return {}
    }
  },
  mounted () {
    this.update()
  }
}
</script>
