<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('twitter.user_details.day_activity_title') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <Loader
          v-if="!isLoaded"
        />
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import Loader from '@/components/TGCP/Loader'
import { mapGetters } from 'vuex'

export default {
  name: 'TiktokUserDayGraph',
  components: {
    Loader,
    VueApexCharts
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          formatter: val => +val > 2 ? val : ''
        },
        xaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_hours')
          }
        },
        yaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          }
        }
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapGetters('tiktok', [
      'allUsers'
    ]),
    chatsActivity () {
      if (!this.allUsers) return
      var dayActivity = this.allUsers[this.id]['activity']['day']['activity']
      const series = [{
        name: this.$i18n.t('tgcp.user_details.graph_messages'),
        data: []
      }]
      for (const i in dayActivity) {
        series[0].data.push({
          y: dayActivity[i],
          x: i
        })
      }
      return series
    },
    isLoaded () {
      return this.$store.state.tiktok.isLoaded['user_' + this.id + '_activity_posts_day']
    }
  },
  methods: {
    update () {
      const action = 'tiktok/fetchUserActivityById'
      this.$store.dispatch(action, [this.id, 'day'])
    }
  },
  mounted () {
    this.update()
  }
}
</script>
