<template>
  <tr class="uk-text-center uk-text-break">
    <td
      v-if="localTask"
      class="uk-text-break"
    >
      <span>
        {{ localTask.task }}
        {{ localTask.geo && [ geoSquare._southWest.lat, geoSquare._southWest.lng, geoSquare._northEast.lat, geoSquare._northEast.lng ] }}
      </span>
    </td>
    <td class="uk-text-break">
      <router-link
        v-if="task.channel"
        :to="{ name: 'hoop_channel_details', params: { id: task.channel.id } }"
        :uk-tooltip="`title: ${description}`"
      >
        {{ task.channel.title }}
      </router-link>
    </td>
    <td class="uk-text-break">
      {{ localTask.description }}
    </td>
    <td class="uk-text-break">
      {{ localTask.sync_date ? dayjs(localTask.sync_date) : $t('twitter.tasks.waiting-for-sync') }}
    </td>
    <td class="uk-text-break">
      {{ dayjs(localTask.created) }}
    </td>
    <td
      :class="{
        'uk-disabled': loading
      }"
    >
      <div
        class="uk-flex uk-width-1-1 uk-flex-between uk-flex-middle"
      >
        <AddStoredButton
          provider="hoop"
          :ext_id="extId"
          type="task"
          :data="task"
        />
        <a
          :uk-tooltip="`title: ${$t('tgcp.tasks.priority_field_title')}`"
          uk-icon="icon: warning; ratio: 1"
          :value="null"
          :class="localTask.priority > 4 ? 'uk-text-primary' : 'uk-text-muted'"
          @click="updatePriority"
        />
        <button
          class="uk-button uk-button-text uk-margin-left-small"
          type="button"
          style="height:40px;width:40px;"
          :uk-tooltip="$t('twitter.tasks.delete')"
          @click="$emit('delete', localTask.id)"
        >
          <span uk-icon="trash"/>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import L from 'leaflet'
import { defineAsyncComponent } from 'vue'

import { mapActions } from 'vuex'
export default {
  name: 'HoopTasksListItem',
  components: {
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton'))
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localTask: null,
      loading: false
    }
  },
  computed: {
    description () {
      try {
        if (this.task.channel && this.task.channel.description) {
          return this.task.channel.description
        }
      } catch (error) {
        console.debug(error)
      }
      return ''
    },
    geoSquare () {
      if (!this.task.geo) return
      const geo = this.task.geo
      return new L.latLngBounds(new L.latLng({ lat: geo[0].lat.toFixed(4), lng: geo[0].lon.toFixed(4) }), new L.latLng({ lat: geo[1].lat.toFixed(4), lng: geo[1].lon.toFixed(4) }))
    },
    extId () {
      return `hoop-task-${this.task.id}`
    }
  },
  methods: {
    ...mapActions('hoop', [
      'editTask'
    ]),
    search () {
      const task = this.task.task
      if (this.task.geo && this.task.geo.length) {
        return this.$router.push({ name: 'hoop_message_list', query: { bbox: `${this.geoSquare._southWest.lat},${this.geoSquare._southWest.lng},${this.geoSquare._northEast.lat},${this.geoSquare._northEast.lng}` } })
      }
      switch (task[0]) {
          case '#':
            return this.$router.push({ name: 'hoop_hashtag_list', query: { search: task.substring(1) } })
          case '@':
            return this.$router.push({ name: 'hoop_user_list', query: { search: task.substring(1) } })
          default:
            return this.$router.push({ name: 'hoop_message_list', query: { search: task } })
      }
    },
    async updatePriority () {
      this.loading = true
      try {
        this.localTask = await this.editTask({ id: this.task.id, task: { priority: +this.localTask.priority > 4 ? 1 : 5 } })
      } catch {
        this.reset()
      } finally {
        this.loading = false
      }
    },
    async updateRealtime () {
      this.loading = true
      try {
        this.localTask = await this.editTask({ id: this.task.id, task: { realtime: !this.localTask.realtime } })
      } catch {
        this.reset()
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.localTask = { ...this.task }
    }
  },
  created () {
    this.reset()
  }
}
</script>
