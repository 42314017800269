<template>
  <div class="home uk-container">
    <div>
      <form
        class="uk-grid-small uk-form-small uk-display-inline-block"
        uk-grid
        @submit.prevent="searchHandler"
      >
        <div class="uk-display-inline-block">
          <input
            v-model.trim="query.search"
            type="text"
            :placeholder="$t('tgcp.channels.search_placeholder')"
            class="uk-input uk-form-width-small uk-form-width-medium"
          />
          <button
            class="uk-button uk-button-primary uk-search-icon"
            @click.prevent="searchHandler"
          >
            <span uk-search-icon/>
          </button>
        </div>
        <div class="uk-display-inline-block">
          <div class="uk-display-inline-block">
            {{ $t('forums.users.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <vueSelect
            v-model="currentOrderType"
            :options="orderTypeTranslated"
            label="label"
            class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
          />
        </div>
      </form>
      <SearchInDbHint />
      <div
        v-if="loading"
        class="uk-margin"
      >
        <Loader />
      </div>
      <NotFound
        v-if="loaded && topics.length === 0 && !forumId && !parentId"
        class="uk-margin-top"
      />
      <div
        class="uk-grid uk-margin-top uk-margin-bottom"
        data-uk-grid-margin
      >
        <div
          v-if="!loading"
          class="uk-grid-small
            uk-child-width-1-1@s uk-flex-left
            uk-width-1-1
            uk-grid filter_area"
        >
          <span
            v-for="topic in topics"
            :key="topic.id"
          >
            <Item
              :topic="topic"
              :level="level"
              class="uk-margin-small-top"
            />
          </span>
        </div>
      </div>
      <div v-if="!hidePagination && loaded">
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="changePageHandler"
          prev-text="<"
          next-text=">"
          :container-class="'uk-pagination uk-flex-center uk-margin-small-top'"
        />
      </div>
    </div>
    <NotFound v-if="!loading && !topics.length" />
    <Paginate
      v-model="pagination.page"
      :page-count="lastPage"
      :click-handler="setPage"
      prev-text="<"
      next-text=">"
      container-class="uk-pagination uk-flex-center"
    />
    <ExportDialog
      v-if="hasExport"
      :id="userId || tagId"
      :search="query.search"
      namespace="hoop"
      type="channels"
      :subtype="userId ? 'user' : tagId ? 'tag' : ''"
      :query="query"
      :totalCount="pagination?.total ?? 0"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import forumsSearch from '@/mixins/forumsSearch'
import vueSelect from '@/components/VueSelect'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'ChannelList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Paginate,
    Item: defineAsyncComponent(() => import('@/components/Hoop/ChannelList/Item.vue')),
    Loader,
    vueSelect
  },
  mixins: [forumsSearch],
  props: {
    userId: {
      type: [Number, String],
      required: false
    },
    forumId: {
      type: [Number, String],
      required: false
    },
    tagId: {
      type: [Number, String],
      default: ''
    },
    parentId: {
      type: [Number, String],
      default: ''
    },
    level: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'full',
      validator: val => ['full', 'nested'].includes(val)
    }
  },
  data () {
    return {
      topics: [],
      loading: false,
      query: {
        limit: this.level === 0 ? 40 : 20,
        offset: 0,
        order_by: this.$route.query.order_by || 'cdate',
        order_type: this.$route.query.desc || 'asc',
        search: (!this.userId && !this.forumId && !this.parentId && this.$route.query.search) || ''
      },
      pagination: {
        page: 1,
        total: null
      },
      orderList: ['title', 'messages_count', 'cdate', 'last_sync_date'],
      paramsToRoute: ['search', 'order_by', 'order_type', 'page'],
      parent_id: this.parentId || '0'
    }
  },
  watch: {
    parentId () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('hoop', ['isLoaded']),
    hasExport () {
      return (this.type !== 'full' || this.query?.search) && this.topics?.length > 0
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('hoop.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    isParrent () {
      return this.type === 'all'
    },
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    loaded () {
      if (this.userId) return this.isLoaded['topics_user_' + this.userId]
      if (this.forumId) return this.isLoaded['topics_forum_' + this.forumId]
      return this.isLoaded['topics']
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    ...mapActions('hoop', ['fetchAllChannels', 'addParamToQuery']),
    async update () {
      const { userId, query, setData, clearData } = this
      this.loading = true
      let p
      if (userId) {
        p = (await this.$store.dispatch('hoop/fetchUserChannels', query))(this.userId)
      } else if (this.tagId) {
        p = this.$store.dispatch('hoop/fetchTagChannels', this.tagId)
      } else {
        if (this.parentId) this.query.parent_id = this.parentId
        p = this.fetchAllChannels(this.query)
      }
      p.then(setData)
        .catch(clearData)
        .finally(() => (this.loading = false))
    },
    clearData (err) {
      this.topics = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err.message, type: 'error' })
    },
    setData (res) {
      const isChannelsActivity = res.data.channels_activity !== void 0
      this.topics = isChannelsActivity ? res.data.channels_activity : res.data
      this.pagination.total = res.meta.results_count
    }
  }
}
</script>
