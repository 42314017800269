
import L from 'leaflet'

import { mapActions } from 'vuex'
import disabledIconUrl from '@/assets/mapIcons/marker-icon.png'

export default {
  methods: {
    ...mapActions('tgcp', ['fetchGeoTasks']),
    getGeoTasks () {
      this.fetchGeoTasks().then(tasks => {
        this.mapInstance.fireEvent('dataloading')
        const markers = tasks.map(task => {
          return L.marker(task, {
            icon: L.icon({
              iconUrl: disabledIconUrl,
              iconSize: [25, 41],
              iconAnchor: [12, 38]
            }),
            radius: 5
          })
        })
        window.geoTasks.addLayers(markers, {
          chunkedLoading: true
        })
        this.mapInstance.fireEvent('dataload')
      })
    },
    addGeotask ({ latlng: { lat, lng } }) {
      const coords = [ lat.toFixed(5), lng.toFixed(5) ].join(';')
      this.addTask({
        'description': '',
        'priority': true,
        'collect_media': false,
        'task': coords,
        'type': 'geo'
      }).then(res => {
        L.marker({ lat, lng }, {
          icon: L.icon({
            iconUrl: disabledIconUrl,
            iconSize:     [25, 41],
            iconAnchor:   [12, 38]
          }),
          radius: 5
        }).addTo(this.mapInstance)
      }).catch(console.error)
    }
  },
  created () {
    window.geoTasks = new L.MarkerClusterGroup({
      disableClusteringAtZoom: 12,
      animate: false,
      spiderfyOnMaxZoom: false,
      chunkedLoading: true,
      iconCreateFunction: function (cluster) {
        const count = cluster.getChildCount()

        let c = ' geotask-marker-cluster-'
        if (count < 50) c += 'small'
        else if (count < 500) c += 'medium'
        else c += 'large'

        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        })
      }
    })
  },
  beforeUnmount () {
    if (window.geoTasks) {
      window.geoTasks.clearLayers()
    }
  }
}
