<template>
  <div>
    <div class="uk-padding-small uk-position-relative uk-visible-toggle uk-light">
      <GeneralAvatar :src="url"/>
    </div>
  </div>
</template>

<script>

import GeneralAvatar from '@/components/GeneralAvatar'

export default {
  components: {
    GeneralAvatar
  },
  props: {
    avatar: {
      required: true
    },
    type: {
      required: true,
      type: String
    }
  },
  computed: {
    url () {
      if (!this.avatar || !this.avatar.file_location) {
        if (this.type === 'channel') {
          return '/img/nogroupavatar.png'
        } else {
          return '/img/noavatar.png'
        }
      }
      return this.avatar.file_location
    }
  }
}
</script>
