<template>
  <ul
    v-if="breadcrumbs && breadcrumbs.length && forum?.id && topic?.id"
    class="uk-padding-remove uk-link-muted uk-text-muted uk-text-left"
  >
    <li style=" display:inline;">
      <router-link
        :to="{ name: 'forums_forum_details',
               params: {
                 id: forum.id
               }}"
      >
        {{ forum.title || forum.name || topic.forum_id }}
      </router-link>
    </li>
    <li
      v-for="topic in breadcrumbs"
      :key="topic.id"
      style=" display:inline;"
      :uk-tooltip="topic.name"
    >
      /
      <router-link
        :to="{
          name: 'forums_topic_details',
          params: {
            topicId: topic.id,
            forumId: forum.id
          }}"
        style="display:inline;"
      >
        {{ html(topic.name) }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TopicBreadcrumbs',
  props: {
    topicId: {
      type: [String, Number],
      required: true
    },
    entries: {
      type: Array,
      required: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('forums', [
      'allForums',
      'allTopics'
    ]),
    breadcrumbsLength () {
      if (!this.allTopics[this.topicId]) return 0
      return this.allTopics[this.topicId].breadcrumbs.map(el => el.name).join('').length + (this.forum?.name ? this.forum.name.length : 0)
    },
    breadcrumbs () {
      if (!this.allTopics[this.topicId]) return []
      if (this.entries) {
        return this.entries
      }
      return this.allTopics[this.topicId]?.breadcrumbs
    },
    html () {
      const max = Math.max(5, Math.floor((this.expanded ? 150 : 100) / this.breadcrumbs.length) - 4 )
      return (text) => {
        text = (text && text.replace(/<[^>]*>?/gm, '')) || ''
        return this.breadcrumbsLength > (this.expanded ? 185 : 113)
          ? text.slice(0, max) + '...'
          : text
      }
    },
    topic () {
      return this.topicId in this.allTopics ? this.allTopics[this.topicId] : null
    },
    forum () {
      return this.topic ? (this.topic.forum_id in this.allForums ? this.allForums[this.topic.forum_id] : null) : null
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchForumById',
      'fetchTopicById'
    ]),
    async update () {
      if (!(this.topicId in this.allTopics)) {
        await this.fetchTopicById(this.topicId)
      } 
      if (!(this.topic.forum_id in this.allForums)) {
        await this.fetchForumById(this.topic.forum_id)
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
