<template>
  <div class=" ">
    <div class="uk-margin">
      <MapTimeline/>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>

import MapTimeline from '@/components/map/MapTimeline'

export default {
  name: 'TgcpMap',
  components: {
    MapTimeline
  },
  data () {
    return {
      searchString: '',
      searchType: 'messages'
    }
  }
}
</script>
