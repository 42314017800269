// @ts-check
/**
 * @typedef {import('./Strategies/Base').StrategyOptions} StrategyOptions
 * @typedef {Pick<StrategyOptions, 'namespace' | 'type'>} IsTxtOptions
 * @typedef {{ data: string, isTxt: IsTxt }} FileDataStruct
 * @typedef {{ (options: IsTxtOptions): boolean }} IsTxt
 * @typedef {Pick<StrategyOptions, 'type' | 'subtype' | 'namespace' | 'format' | 'entries' | 'allowedFields' | 'localizeFields'>} Options
 */

import Csv from './Formats/Csv.js'
import Txt from './Formats/Txt.js'
import Utils from './Utils'
import store from './store.js'

/**
 * @class
 * @readonly
 */
class FileData {
  data = ''
  /**
   * @readonly
   * @constructor
   * @param {Options} Options
   */
  constructor (Options) {
    try {
      if (Options.entries !== void 0) {
        const entries = Utils.selectProperties({ entries: Options.entries, properties: Options.allowedFields })
        const withDate = Utils.date(entries)
        const isTxt = FileData.isTxt({
          namespace: Options.namespace,
          type: Options.type
        })
        if (isTxt) {
          this.data = new Txt(withDate).data
          return
        }
        if (Options.format === 'csv') {
          this.data = new Csv({ entries: withDate, Options }).data
          return
        }
      }
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {IsTxtOptions} options
   */
  static isTxt (options) {
    try {
      return options.namespace === 'tgcp' && options.type === 'messages' && store.state.settings.language === 'ru'
    } catch (error) {
      console.debug(error)
    }
    return false
  }
}

export default FileData
