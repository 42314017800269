<script>
import { blockKeyGen, truncString, blockParse, trnParse, addParse, asciiParse, toBase64Rfc, toBase64Web } from '@/utils/ton-filters'
import { mapActions } from 'vuex'
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'TonSearchBar',
  components: {
    Loader
  },
  data () {
    return {
      search: '',
      lastSearch: '',
      searchRes: [],
      status: 'EMPTY',
      lastStatus: 'EMPTY'
    }
  },
  watch : {
    async search (to, from) {
      this.parse()
      if (this.$route.path === '/crypto/ton/search' && to !== this.$route?.query?.search) this.$router.replace(`/crypto/ton/search?search=${this.search}`)
    }
  },
  methods: {
    ...mapActions('crypto', ['searchTon']),
    blockKeyGen,
    truncString,
    blockParse,
    trnParse,
    addParse,
    asciiParse,
    toBase64Rfc,
    toBase64Web,
    emptyEverything () {
      this.status = 'EMPTY'
    },
    async performSearch (query) {
      const { searchTon } = this
      this.status = 'WAITING'
      this.searchRes = []
      // request to store
      const res = await searchTon({ req: query })
      // check if empty
      if (res.length === 0) {
        this.status = 'NOTHING'
        this.lastSearch = this.search
        this.lastStatus = this.status
        return
      }
      // set values
      this.searchRes = res
      this.lastSearch = this.search
      // now set show flag
      this.status = 'FOUND'
      this.lastStatus = this.status
    },
    async parse () {
      const { search, lastSearch, status, lastStatus, blockParse, trnParse, addParse, asciiParse, toBase64Rfc } = this
      if (search === '') this.status = 'EMPTY'
      if (search === lastSearch) {
        this.status = lastStatus
        this.lastSearch = '§'
        return
      }
      else {
        let input = null
        // parcing
        input = blockParse(search)
        if (input) {
          await this.performSearch(input)
          return
        }
        input = trnParse(search)
        if (input) {
          input.hash = toBase64Rfc(input.value.hash)
          await this.performSearch(input)
          return
        }
        input = addParse(search)
        if (input) {
          await this.performSearch(input)
          return
        }
        input = asciiParse(search)
        if (input) {
          await this.performSearch(input)
          return
        }
        // if nothing found
        this.status = 'INCORRECT'
        this.lastStatus = status
        this.lastSearch = search
      }
    },
    goToLink (res) {
      switch (res.type) {
          case 'account': this.$router.push({name: 'crypto_ton_account_info', params: { id: res.value.hex }, hash: '#overview'}); break
          case 'block': this.$router.push({name: 'crypto_ton_block_info', query: { workchain: res.value.workchain, shard: res.value.shard.toString(), seq_no: res.value.seq_no }, hash: '#overview'}); break
          case 'transaction': this.$router.push({name: 'crypto_ton_transaction_info', params: { id: this.toBase64Web(res.value.hash)}, hash: '#overview'}); break
          case 'label': this.$router.push({name: 'crypto_ton_account_info', params: { id: res.value }, hash: '#overview'}); break
          default:
      }
      this.search = ''
    }
  },
  mounted () {
    if (this.$route.path === '/crypto/ton/search' && this.$route.query && this.$route.query.search) this.search = this.$route.query.search.toString()
  },
  beforeUnmount () {
    this.emptyEverything()
  }
}
</script>

<template>
  <form 
    id="overview" 
    class="uk-search uk-background-default uk-search-default uk-width-1-1" 
    :class="{ 'open-results' : status !== 'EMPTY' }" 
    style="height: 40px"
    @focusout="emptyEverything" 
    @submit.prevent.stop="$router.push(`/crypto/ton/search?search=${search}`)"
  >
    <router-link 
      :to="`/crypto/ton/search?search=${search}`" 
      style="position: absolute; padding: 8px" 
    >
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_443_12881)">
          <path 
            d="M19.2888 16.617L23.5718 20.899L22.1568 22.314L17.8748 18.031C16.2816 19.3082 14.2998 20.0029 12.2578 20C7.28981 20 3.25781 15.968 3.25781 11C3.25781 6.032 7.28981 2 12.2578 2C17.2258 2 21.2578 6.032 21.2578 11C21.2607 13.042 20.566 15.0237 19.2888 16.617ZM17.2828 15.875C18.5519 14.5699 19.2607 12.8204 19.2578 11C19.2578 7.132 16.1248 4 12.2578 4C8.38981 4 5.25781 7.132 5.25781 11C5.25781 14.867 8.38981 18 12.2578 18C14.0782 18.0029 15.8277 17.2941 17.1328 16.025L17.2828 15.875Z" 
            fill="black" 
            fill-opacity="0.3"
          />
        </g>
        <defs>
          <clipPath id="clip0_443_12881">
            <rect 
              width="24" 
              height="24" 
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </router-link>
    <input 
      id="searchbar" 
      v-model.trim="search" 
      class="uk-search-input" 
      type="search" 
      autocomplete="off"
      :placeholder="$t('crypto.general.search')" 
      aria-label="Search" 
      style="height: 40px; padding-left: 48px"
      @focus="parse"
    />
    <table 
      v-if="status !== 'EMPTY' && $route.path !== '/crypto/ton/search'" 
      class="uk-table results-table uk-position-absolute uk-position-bottom uk-width-1-1 uk-margin-remove-vertical uk-table-divider" 
      style="top: 100%; z-index: 100; background-color: white"
    >
      <tbody v-if="status === 'FOUND'">
        <tr 
          v-for="res in searchRes.slice(0, 5)"
          :key="res.show"
          class="uk-flex uk-text-left uk-flex-column hover"
        >
          <td 
            style="padding: 0.3rem 1rem; max-width: 70vw;" 
            @mousedown="goToLink(res)"  
          >
            <h4 
              v-if="res.type === 'account'" 
              class="uk-margin-remove-vertical uk-text-truncate" 
              style="cursor: pointer;"
            >
              {{ res.show ?? res.hex }}
            </h4>
            <h4 
              v-else-if="res.type === 'transaction'" 
              class="uk-margin-remove-vertical uk-text-truncate" 
              style="cursor: pointer;"
            >
              {{ res.show ?? res.hash }}
            </h4>
            <h4 
              v-else-if="res.type === 'label'" 
              class="uk-margin-remove-vertical uk-text-truncate" 
              style="cursor: pointer;"
            >
              {{ res.show ?? res }}
            </h4>
            <h4 
              v-else-if="res.type === 'block'" 
              class="uk-margin-remove-vertical uk-text-ellipsis" 
              style="cursor: pointer;"
            >
              {{ res.show ?? blockKeyGen(res.workchain, truncString(res.shard.toString(), 3) , res.seq_no) }}
            </h4>
            <p class="uk-margin-remove-vertical">
              {{ $t(`crypto.route.${res.type === 'label' ? 'account' : res.type}`) }}
            </p>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="status === 'NOTHING'">
        <tr>
          <td>
            {{ $t('crypto.general.nothing-found') }}
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="status === 'INCORRECT'">
        <tr>
          <td>
            {{ $t('crypto.general.incorrect-input') }}
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="status === 'WAITING'">
        <tr>
          <td>
            <Loader :ratio="1"/>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</template>

<style scoped lang="scss">
#searchbar::placeholder {
    color: rgba(0,0,0,.3)
}
</style>