<template>
  <div>
    <div
      v-if="isLoaded && avatars[id].length"
      ref="slider"
      uk-slider="finite: true"
      style="height: 200px"
      class="uk-position-relative uk-visible-toggle uk-padding-small"
    >
      <ul
        class="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m"
      >
        <li
          v-for="(image, i) in localAvatars"
          :key="image.file_location"
          @click="showLightBox($event, i)"
        >
          <GeneralAvatar :src="image.file_location"/>
        </li>
      </ul>
      <div uk-grid>
        <button
          class="uk-position-small uk-position-bottom-left"
          uk-slidenav-previous
          uk-slider-item="previous"
        />
        <button
          class="uk-position-small uk-position-bottom-right"
          uk-slidenav-next
          uk-slider-item="next"
          @click="loadMore"
        />
      </div>
    </div>
    <Avatar
      v-else
      :avatar="getAvatar(type)"
      type="user"
    />
  </div>
</template>

<script>
import UIkit from 'uikit'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'AvatarList',
  components: {
    Avatar: defineAsyncComponent(() => import('@/components/TGCP/Avatar')),
    GeneralAvatar: defineAsyncComponent(() => import('@/components/GeneralAvatar'))
  },
  props: {
    id: {
      required: true,
      type: [Number, String]
    },
    type: {
      default: 'user',
      required: false,
      type: String,
      validator: (value) => ['user', 'channel'].includes(value)
    }
  },
  data () {
    return {
      index: 0
    }
  },
  computed: {
    ...mapGetters('tgcp', [
      'channelAvatars',
      'userAvatars'
    ]),
    avatars () {
      return this.type === 'channel' ? this.channelAvatars : this.userAvatars
    },
    isLoaded () {
      return this.type === 'channel'
        ? this.$store.state.tgcp.isLoaded[`channel_${this.id}_avatars`]
        : this.$store.state.tgcp.isLoaded[`user_${this.id}_avatars`]
    },
    localAvatars () {
      if (!this.avatars[this.id]) {
        return []
      }

      if (this.long) {
        return [...this.avatars[this.id]].slice(this.index, this.index + 50)
      }

      return this.avatars[this.id]
    },
    long () {
      return this.avatars[this.id] && this.avatars[this.id].length > 50
    },
    sources () {
      if (!this.avatars[this.id]) {
        return []
      }

      return this.avatars[this.id].map(item => {
        return { source: item.file_location, type: 'image' }
      })
    }
  },
  methods: {
    getAvatar (type) {
      switch (type) {
          case 'user':
            return this.$store.state.tgcp.users[this.id].avatar
          case 'channel':
            return this.$store.state.tgcp.channels[this.id].avatar
          default:
            return this.$store.state.tgcp.users[this.id].avatar
      }
    },
    count (arr) {
      return arr.length
    },
    ...mapActions('tgcp', [
      'fetchChannelAvatarsById',
      'fetchUserAvatarsById'
    ]),
    showLightBox (event, localIndex) {
      event.preventDefault()
      this.$myLightbox(this.sources, this.index + localIndex)
    }
  },
  created () {
    if (!this.id) {
      return
    }

    if (this.type === 'channel') {
      this.fetchChannelAvatarsById(this.id)
    } else {
      this.fetchUserAvatarsById(this.id)
    }
  }
}
</script>
