<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div class="uk-margin">
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
        >
          <div class="uk-display-inline-block">
            <input
              v-model.trim="query.message"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              @keyup.enter="setPage(1)"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="setPage(1)"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!isLoaded['messages']">
        <Loader />
      </div>
      <div v-else>
        <template v-if="isLoaded['messages'] && messages.length === 0">
          <NotFound class="uk-align-center" />
        </template>
        <template v-else>
          <Item
            v-for="message in messages"
            :key="message.id"
            :data-scroll-into-view="message.id"
            class="uk-margin-top"
            :message="message"
          />
        </template>
      </div>
      <div
        v-if="!hidePagination"
        class="uk-margin"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <ExportDialog
      v-if="showExport"
      :id="groupId || userPhone"
      :search="query.search"
      namespace="whatsapp"
      type="messages"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Item from '@/components/WhatsApp/MessagesList/Item'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import vueSelect from '@/components/VueSelect'
import { mapActions, mapGetters } from 'vuex'
import scrollIntoView from '@/utils/scrollIntoView'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'WhatsappMessageList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item,
    Loader,
    Paginate,
    vueSelect
  },
  props: {
    userPhone: {
      type: [String, Number]
    },
    groupId: {
      type: String
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'group'].includes(val)
    }
  },
  data () {
    return {
      messages: [],
      selectedMessages: [],
      orderList: ['sender_phone', 'group_id', 'timestamp'],
      query: {
        limit: 20,
        message: this.$route.query.message || '',
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'timestamp',
        order_type: this.$route.query.order_type || 'desc'
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    '$route.query.messageId': {
      immediate: true,
      async handler (value) {
        try {
          if (
            value &&
            Array.isArray(this.messages)
          ) {
            const entry = this.messages.find(({ id }) => id === value)
            if (entry) {
              try {
                await this.$nextTick()
                try {
                  scrollIntoView({ value })
                } catch (error) {
                  console.debug(error)
                }
              } catch (error) {
                console.debug(error)
              }
            } else {
              await this.update()
              try {
                await this.$nextTick()
                scrollIntoView({ value })
              } catch (error) {
                console.debug(error)
              }
            }
          }
        } catch (error) {
          console.debug(error)
        }
      }
    }
  },
  computed: {
    ...mapGetters('whatsapp', ['isLoaded']),
    showExport () {
      if (this.type === 'all' && this.query.message && this.messages.length) {
        return true
      }
      if (this.type !== 'all' && this.messages.length) {
        return true
      }
      return false
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.update()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('whatsapp.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    hidePagination () {
      return this.lastPage < 2
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    from () {
      if (this.type === 'group') {
        return 'from_whatsapp_' + this.type + '_' + this.groupId
      }
      if (this.type === 'user') {
        return 'from_whatsapp_' + this.type + '_' + this.userPhone
      }
      return 'from_whatsapp_all'
    }
  },
  methods: {
    ...mapActions('whatsapp', ['fetchAllMessages', 'addParamToQuery', 'fetchUsersMessages', 'fetchGroupMessages']),
    init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      this.setPage(page)
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setData (res) {
      this.messages = res.data
      this.pagination.total = res.meta.results_count
    },
    clearData () {
      this.messages = []
      this.pagination.page = 1
      this.pagination.total = null
    },
    async update () {
      try {
        if (this.type === 'all') {
          this.addParamToQuery(this.query)
        }
        let localQuery = {}
        try {
          const messageId = this?.$route?.query?.messageId
          const current = {}
          if (messageId) {
            current.messageId = messageId
          }
          let query = []
          if (this?.query) {
            query = this.query
          }
          localQuery = {
            ...query,
            ...current
          }
        } catch (error) {
          console.debug(error)
        }
        try {
          switch (this.type) {
              case 'user':
                return this.setData(
                  await this.fetchUsersMessages({
                    query: localQuery,
                    phone: this.userPhone
                  })
                )
              case 'group':
                return this.setData(
                  await this.fetchGroupMessages({
                    query: localQuery,
                    groupId: this.groupId
                  })
                )
              default:
                return this.setData(
                  await this.fetchAllMessages(localQuery)
                )
          }
        } catch (err) {
          this.clearData()
          this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
        }
      } catch (error) {
        console.debug(error)
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
