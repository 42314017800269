<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="channel.answer_status === 'NOT_FOUND'" />
      <div v-else>
        <div
          class="uk-grid uk-grid-small"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation
              :channel="channel"
              @resolved="update"
            />
          </div>
          <div class="uk-width-1-4@m">
            <ChannelStats :id="id" />
          </div>
        </div>
      </div>
      <div
        class="uk-margin-medium-top"
      >
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'overview_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'overview_tab'
                }
              }"
            >
              {{ $t('tgcp.channel_details.overview_title') }}
            </router-link>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'users_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.users_count)
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'users_tab'
                }
              }"
            >
              <span v-if="!channel.broadcast">
                {{ $t('tgcp.channel_details.users_title') }}
              </span>
              <span v-else>
                {{ $t('tgcp.channel_details.subscribers_title') }}
              </span>
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedStats" />
              <span v-else-if="stats">
                {{ stats.users_count }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'former_users_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.former_users_count)
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'former_users_tab'
                }
              }"
            >
              <span v-if="!channel.broadcast">
                {{ $t('tgcp.channel_details.former_users_title') }}
              </span>
              <span v-else>
                {{ $t('tgcp.channel_details.former_subscribers_title') }}
              </span>
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedStats" />
              <span v-else-if="stats">
                {{ stats.former_users_count }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'admins_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.admins_count)
            }"
          >
            <router-link
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'admins_tab'
                }
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.admins_title') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedStats"/>
              <span v-else-if="stats">
                {{ stats.admins_count }}
              </span>
              <span
                v-else
              >
                -
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'former_admins_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.former_admins_count)
            }"
          >
            <router-link
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'former_admins_tab'
                }
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.former_admins') }}
            </router-link>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <Loader
                v-if="!isLoadedStats"
              />
              <span
                v-else-if="stats"
              >
                {{ stats.former_admins_count }}
              </span>
              <span
                v-else
              >
                -
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'all_owners_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.all_owners_count)
            }"
          >
            <router-link
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'all_owners_tab'
                }
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.owners') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedStats"/>
              <span v-else-if="stats">
                {{ stats.all_owners_count }}
              </span>
              <span
                v-else
              >
                -
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'messages_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.messages_count)
            }"
          >
            <router-link
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'messages_tab'
                }
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.messages_title') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedStats"/>
              <span v-else-if="stats">
                {{ stats.messages_count }}
              </span>
              <span
                v-else
              >
                -
              </span>
            </span>
          </li>
          <li
            :class="{
              'uk-active': $route.query.details === 'media_messages_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.media_messages_count)
            }"
            class="uk-flex"
          >
            <router-link
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'media_messages_tab'
                }
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.messages_with_media_title') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedStats"/>
              <span v-else-if="stats">
                {{ stats.media_messages_count }}
              </span>
              <span
                v-else
              >
                -
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'entities_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'entities_tab'
                }
              }"
            >
              {{ $t('tgcp.channel_details.entities') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <Loader v-if="!isLoadedEntityStats" />
              <span v-else-if="entity.task || entity.total.count">
                {{ entity.total.count }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </li>
          <li
            v-if="topicsEnabled"
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'topics_tab',
              'uk-disabled': Object.values(topicsEnabled).length === 0
            }"
          >
            <router-link
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'topics_tab'
                }
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.topics_list') }}
            </router-link>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <Loader
                v-if="!topicsEnabled"
              />
              <span
                v-else-if="topicsEnabled"
              >
                {{ Object.values(topicsEnabled).length }}
              </span>
              <span
                v-else
              >
                -
              </span>
            </span>
          </li>
        </ul>
        <ul
          style="min-height: 500px"
          class="uk-switcher"
        >
          <li
            v-if="$route.query.details === 'overview_tab'"
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'overview_tab'
            }"
          >
            <ChannelActivityGraph
              v-if="!channel.broadcast"
              :id="id"
            />
            <ChannelDayGraph
              :id="id"
            />
            <ChannelWeekGraph
              :id="id"
            />
            <ChannelYearGraph
              :id="id"
            />
            <ChannelWordsGraph
              v-if="entity.total.count"
              :id="id"
            />
            <div
              class="my-grid"
            >
              <ChannelAuditoryCountry
                :id="id"
              />
              <TgcpChannelForwardsGraph
                :id="id"
              />
            </div>
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'users_tab'
            }"
          >
            <UserListMini
              v-if="$route.query.details === 'users_tab'"
              :id="id"
              :active="$route.query.details === 'users_tab'"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'former_users_tab'
            }"
          >
            <UserListMini
              v-if="$route.query.details === 'former_users_tab'"
              :id="id"
              :active="$route.query.details === 'former_users_tab'"
              :participantStatus="'former'"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'admins_tab'
            }"
          >
            <UserListMini
              v-if="$route.query.details === 'admins_tab'"
              :id="String(id)"
              :active="$route.query.details === 'admins_tab'"
              participantType="admin"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'former_admins_tab'
            }"
          >
            <UserListMini
              v-if="$route.query.details === 'former_admins_tab'"
              :id="String(id)"
              :participantType="'admin'"
              :active="$route.query.details === 'former_admins_tab'"
              :participantStatus="'former'"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'all_owners_tab'
            }"
          >
            <UserListMini
              v-if="$route.query.details === 'all_owners_tab'"
              :id="String(id)"
              :active="$route.query.details === 'all_owners_tab'"
              participantType="owner"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'messages_tab'
            }"
          >
            <MessageListMini
              v-if="$route.query.details === 'messages_tab'"
              :chatId="String(id)"
              :page="messagePage"
              :offset="messageOffset"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'media_messages_tab'
            }"
          >
            <MessageListMini
              v-if="$route.query.details === 'media_messages_tab'"
              :chatId="String(id)"
              :containMedia="true"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'entities_tab'
            }"
          >
            <Entities
              :chatId="String(id)"
              moduleName="tgcp"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'topics_tab'
            }"
          >
            <TopicsList
              v-if="$route.query.details === 'topics_tab'"
              :id="id"
              :active="$route.query.details === 'topics_tab'"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TgcpChannelDetails',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    ChannelStats: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelStats')),
    ChannelActivityGraph: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelActivityGraph')),
    TgcpChannelForwardsGraph: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelForwardsGraph')),
    ChannelAuditoryCountry: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelAuditoryCountry')),
    ChannelDayGraph: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelDayGraph')),
    ChannelWeekGraph: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelWeekGraph')),
    ChannelYearGraph: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/ChannelYearGraph')),
    ChannelWordsGraph: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/WordsGraph.vue')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/TGCP/ChannelDetails/GeneralInformation')),
    UserListMini: defineAsyncComponent(() => import('@/components/TGCP/UserList/UserListMini')),
    TopicsList: defineAsyncComponent(() => import('@/components/TGCP/TopicsList/TopicsList')),
    Entities: defineAsyncComponent(() => import('@/components/general/Entities')),
    MessageListMini: defineAsyncComponent(() => import('@/components/TGCP/MessageList/MessageListMini'))
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      messagePagination: {
        offset: 0,
        page: 1
      }
    }
  },
  watch: {
    id () {
      this.update()
    },
    '$route.query.details' (to, from) {
      if (to === 'messages_tab' && from === 'entities_tab' && this.$route.query.message_id) {
        this.getMessageLink()
      }
    }
  },
  computed: {
    stats () {
      if (!this.$store.state.tgcp.channels[this.id]) return
      return this.$store.state.tgcp.channels[this.id]['stats']
    },
    entity () {
      let entity = this.$store.state.tgcp.channels[this.id]['entity_data']
      if (!entity) {
        entity = {
          task: false,
          total: {
            count: 0
          }
        }
      }
      return entity
    },
    channel () {
      return this.$store.state.tgcp.channels[this.id]
    },
    topicsEnabled () {
      return this.$store.state.tgcp.channelTopics[this.id] ?? null
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['channel_' + this.id] && this.channel
    },
    isLoadedStats () {
      return this.$store.state.tgcp.isLoaded['channel_' + this.id + '_stats']
    },
    isLoadedEntityStats () {
      return this.$store.state.tgcp.isLoaded['channel_' + this.id + '_entity_data']
    },
    messagePage () {
      if (this.$route.params.message_pagination) return this.$route.params.message_pagination.page
      // eslint-disable-next-line camelcase
      if (this?.messagePagination?.page) return this.messagePagination.page
      else return 1
    },
    messageOffset () {
      if (this.$route.params.message_pagination) return this.$route.params.message_pagination.offset
      // eslint-disable-next-line camelcase
      if (this?.messagePagination?.offset) return this.messagePagination.offset
      else return 0
    },
    messageId () {
      return this.$route.params.message_id
    }
  },
  methods: {
    ...mapActions('tgcp', ['fetchChannelById', 'fetchEntitiesCount', 'getChannelTopics']),
    init () {
      this.update()
    },
    async update () {
      await this.fetchChannelById(this.id)
      await this.fetchEntitiesCount(this.id)
      if (this.channel.forum) {
        await this.getChannelTopics(this.id)
      }
    },
    async getMessageLink () {
      try {
        let params = null
        try {
          if (
            // eslint-disable-next-line camelcase
            this?.$route?.query?.message_id &&
            this.id
          ) {
            params = await this.$store.dispatch('tgcp/fetchMessageLink', {
              messageId: this.$route.query.message_id,
              chatId: this.id
            })
          }
        } catch (error) {
          console.debug(error)
        }
        // eslint-disable-next-line camelcase
        if (!Number.isNaN(params?.message_pagination?.page) && params?.message_pagination?.page) {
          this.messagePagination.page = params.message_pagination.page
        } else {
          this.messagePagination.page = 1
        }
        // eslint-disable-next-line camelcase
        if (!Number.isNaN(params?.message_pagination?.offset) && params?.message_pagination?.offset) {
          this.messagePagination.offset = params.message_pagination.offset
        } else {
          this.messagePagination.offset = 0
        }
      } catch (error) {
        console.debug(error)
      }
    }
  },
  created () {
    this.getMessageLink()
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.info) updateQuery = { ...updateQuery, info : 'current_tab' }
    else if (updateQuery.info !== 'current_tab') updateQuery.info = 'current_tab'
    if (!updateQuery?.details) updateQuery = { ...updateQuery, details : 'overview_tab' }
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    this.update()
  }
}
</script>

<style>
.uk-badge.uk-margin-small-left svg {
  width: 100%;
  height: 100%;
  max-width: 1rem;
}
</style>

<style scoped lang="scss">
[class*='uk-animation-'] {
  animation-fill-mode: none !important;
}

.my-grid {
  display: grid;
  @media screen and (min-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}
</style>
