import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class HoopChannelsTag extends Base {
  schema = Schemas.hoop.channel
  OPTIONS_SCHEMA = ['id', 'query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { id, channels } = options
    if (!!channels && channels.length) {
      return channels
    } else {
      const { data } = await dispatch('fetchTagChannels', id)
      return data
    }
  }
}

export default HoopChannelsTag
