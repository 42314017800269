<template>
  <div class="uk-padding">
    <div
      v-if="hasCases"
      class="uk-card uk-card-default uk-margin-top"
    >
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ allCases[id].case_title }} - {{ $t('cases.mass-import') }}
        </h3>
        <div class="uk-position-top-right uk-margin-right uk-margin-top"/>
      </div>
      <div class="uk-card-body">
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div class="uk-width-1-4@m  uk-text-left">
            <p><i>{{ $t('cases.upload-any-file') }}</i></p>
            <form
              enctype="multipart/form-data"
              class="uk-form-horizontal  uk-form"
              @submit.prevent="startFileProcess"
            >
              <div uk-form-custom="target: true">
                <input
                  id="upload"
                  type="file"
                  name="files[]"
                  style="height: 2.5rem;"
                  @change="handleFileSelect"
                />
                <input
                  class="uk-input uk-form-width-medium"
                  type="text"
                  :placeholder="$t('cases.select-file') + ' (txt/csv)'"
                  disabled
                  style="height: 2.5rem;"
                />
                <div
                  class="uk-width-1-1 uk-child-width-1-1
                    uk-margin-top"
                />
              </div>
              <hr/>
              <div class="uk-width-1-1 uk-float-right">
                <input
                  type="submit"
                  class="uk-button uk-width-1-1 uk-margin-top uk-button-primary"
                  :disabled="!this.file"
                  :value="$t('cases.process_button')"
                  @click.prevent="startFileProcess"
                />
              </div>
              <div
                class="uk-width-1-1 uk-float-right"
              >
                <button
                  :disabled="isProcessing"
                  type="submit"
                  class="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
                  @click.prevent="createProfiles"
                >
                  <div
                    v-if="isProcessing"
                    uk-spinner
                  />
                  {{ $t('cases.create-profiles') }}
                </button>
              </div>
            </form>
          </div>
          <div class="uk-width-1-2@m  uk-text-left">
            <p v-if="!records.length">
              {{ $t('cases.after-uploading-you-can') }}
            </p>
            <div v-else>
              <table class="uk-table">
                <tr>
                  <th/>
                  <th>
                    {{ $t('cases.original-string') }}
                  </th>
                  <th>
                    {{ $t('cases.detected') }}
                  </th>
                  <th/>
                </tr>
                <tr
                  v-for="(record, index) in records"
                  :key="record.profile_id"
                >
                  <td>
                    <span
                      style="width:20px"
                      :uk-icon="iconFrom(record.type)"
                      :uk-tooltip="tooltipFrom(record.type)"
                    />
                  </td>
                  <td>
                    {{ record.original }}
                  </td>
                  <td>
                    <router-link
                      v-if="record.type==='email'"
                      :to="{ name: 'emailResults', params: { email: record.detected }}"
                    >
                      {{ record.detected }}
                    </router-link>
                    <router-link
                      v-else-if="record.type==='phone'"
                      :to="{ name: 'phoneResults', params: { phone: record.detected }}"
                    >
                      {{ record.detected }}
                    </router-link>
                    <router-link
                      v-else-if="record.type==='ip'"
                      :to="{ name: 'ipResults', params: { ip: record.detected }}"
                    >
                      {{ record.detected }}
                    </router-link>
                    <span v-else>
                      {{ record.detected }}
                    </span>
                  </td>
                  <td>
                    <input
                      v-if="record.type!=='none'"
                      v-model="importList"
                      type="checkbox"
                      :value="index"
                      class="uk-checkbox"
                      checked="true"
                    />
                  </td>
                  <td v-if="0 && record.profile_id">
                    <router-link
                      :to="{
                        name: 'case_profile_details',
                        params: {
                          profileId: record.profile_id,
                          caseId: id
                        }
                      }"
                    >
                      #{{ record.profile_id }}
                    </router-link>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="offcanvasProfiles"
      uk-offcanvas="flip: true;"
    >
      <div class="my-max-width uk-background-default uk-position-fixed uk-position-right uk-padding-small uk-overflow-auto">
        <button
          class="uk-offcanvas-close"
          type="button"
          uk-close
        />
        <h3 class="uk-text-break">
          <router-link :to="{name: 'case_details', params: { caseId: id }}">
            {{ allCases[id].case_title }}
          </router-link> - {{ $t('cases.mass-import') }}
        </h3>
        <div
          v-for="(profile, index) in offcanvasProfiles"
          :key="profile.id + index"
          class="uk-alert-success uk-text-break"
          uk-alert
        >
          <p>{{ profile.name }}</p>
          <router-link
            :to="{ name: 'case_profile_details', params: { caseId: profile.caseId, profileId: profile.id }}"
            class="uk-button uk-button-success"
          >
            <template v-if="profile.status === 'success'">
              {{ $t('cases.profile.go-to-profile') }} <span uk-icon="icon: arrow-right"/>
            </template>
            <template v-if="profile.status === 'error'">
              {{ $t('cases.error') }}
            </template>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import { mapActions, mapGetters } from 'vuex'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
const isIp = require('is-ip')
export default {
  name: 'CaseMassImport',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      file: null,
      generatingPdf: false,
      loading: false,
      isProcessing: false,
      description: '',
      importList: [],
      records: [],
      caseInfo: {
        title: (this.allCases && this.allCases[this.id].case_title) || '',
        description: (this.allCases && this.allCases[this.id].case_description) || ''
      },
      error: {
        title: false,
        description: false,
        send: false
      },
      offcanvasProfiles: []
    }
  },
  computed: {
    ...mapGetters('cases', [
      'allCases'
    ]),
    hasCases () {
      if (!this.id) return false
      return Object.hasOwnProperty.call(this.allCases, this.id)
    }
  },
  methods: {
    ...mapActions('cases', [
      'profileAdd',
      'addProfileTag'
    ]),
    async createProfiles () {
      this.isProcessing = true
      let offcanvasProfiles = []
      for (let j in this.importList) {
        let i = this.importList[j]
        if (this.records[i].type !== 'none') {
          let data = {
            seed_data: this.records[i].detected
          }
          let caller = this
          try {
            let res = await this.profileAdd({
              data,
              caseId: this.id
            })
            await caller.addProfileTag([caller.id, res.data.profile.profile_id, 'import: ' + caller.description])
            offcanvasProfiles.push({
              status: 'success',
              name: data.seed_data,
              caseId: this.id,
              id: res.data.profile.profile_id
            })
          } catch {
            offcanvasProfiles.push({
              status: 'error',
              name: data.seed_data,
              caseId: this.id
            })
          }
        }
      }
      this.offcanvasProfiles = offcanvasProfiles
      this.isProcessing = false
      UIkit.offcanvas(this.$refs.offcanvasProfiles).show()
    },
    iconFrom: function (type) {
      let icon = ''
      switch (type) {
          case 'none':
            icon = 'close'
            break
          case 'ip':
            icon = 'server'
            break
          case 'url':
            icon = 'link'
            break
          case 'email':
            icon = 'mail'
            break
          case 'cc':
            icon = 'credit-card'
            break
          case 'phone':
            icon = 'phone'
            break
      }
      return icon
    },
    tooltipFrom: function (type) {
      let icon = ''
      switch (type) {
          case 'none':
            icon = this.$t('graph.error-detecting')
            break
          case 'url':
            icon = this.$t('graph.detected-as-link')
            break
          case 'ip':
            icon = this.$t('graph.detected-as-ip-address')
            break
          case 'email':
            icon = this.$t('graph.detected-as-Email')
            break
          case 'cc':
            icon = this.$t('graph.detected-as-credit-card-number')
            break
          case 'phone':
            icon = this.$t('graph.detected-as-phone-number')
            break
      }
      return icon
    },
    validateEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    isUrl: function (str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return !!pattern.test(str)
    },
    parseRow: function (line) {
      let phoneSearch = line.split(' ').join('')
      if (phoneSearch.indexOf('84') === 0) {
        phoneSearch = '+' + phoneSearch
      }
      if (isIp(line)) {
        return { 'type': 'ip', 'detected': line }
      } else if (this.validateEmail(line)) {
        return { 'type': 'email', 'detected': line }
      } else if (this.isUrl(line)) {
        return { 'type': 'url', 'detected': line }
      } else if (phoneSearch.indexOf('+') === 0 && parseMax(phoneSearch)) {
        try {
          if (parseMax(line, parseMax(phoneSearch).country).isValid() || parseMax(phoneSearch).country === 'VN') {
            line = parseMax(phoneSearch).format('INTERNATIONAL').split(' ').join('')
            return { 'type': 'phone', 'detected': line }
          } else {
            return { 'type': 'phone', 'detected': line }
          }
        } catch (e) {
          return false
        }
      } else if (parseMax(line, 'RU')) {
        try {
          if (parseMax(line, 'RU').isValid()) {
            line = parseMax(line, 'RU').format('INTERNATIONAL').split(' ').join('')
            return { 'type': 'phone', 'detected': line }
          } else if (parseMax('+' + line).isValid()) {
            line = parseMax('+' + line).format('INTERNATIONAL').split(' ').join('')
            return { 'type': 'phone', 'detected': line }
          } else {
            return { 'type': 'phone', 'detected': line }
          }
        } catch (e) {
          return false
        }
      } else if (parseMax('+' + phoneSearch)) {
        if (parseMax('+' + phoneSearch).isValid() || parseMax('+' + phoneSearch).country === 'VN') {
          line = parseMax('+ ' + phoneSearch).format('INTERNATIONAL').split(' ').join('')
          return { 'type': 'phone', 'detected': line }
        } else {
          return { 'type': 'phone', 'detected': line }
        }
      } else {
        return false
      }
    },
    processFile: function (evt) {
      this.records = []
      this.importList = []
      var lines = evt.target.result.split(/\r?\n/).filter(Boolean)
      for (var i in lines) {
        const line = lines[i]
        this.totalLines = this.totalLines + 1
        var task = this.parseRow(line)
        if (task === false) {
          this.error++
          if (line.trim()) this.records.push({ original: line, 'type': 'none' })
        } else {
          task.original = line
          this.records.push(task)
        }
        if (this.records[this.records.length - 1].type !== 'none') {
          this.importList.push(this.records.length - 1)
        }
      }
    },
    startFileProcess: function () {
      const types = ['text/csv', 'text/plain']
      if (!types.includes(this.file.type)) {
        this.$notify({ group: 'general', text: this.$t('cases.select-file') + ' (txt/csv)', type: 'error' })

        return false
      }
      if (this.file) {
        this.totalLines = 0
        this.added = 0
        this.already = 0
        this.error = 0
        this.reader.readAsText(this.file)
      }
      return false
    },
    handleFileSelect: function (evt) {
      var reader = new FileReader()

      // assigning handler
      reader.onloadend = this.processFile
      var file = evt.target.files[0]
      this.totalLines = 0
      this.added = 0
      this.already = 0
      this.error = 0
      this.description = file.name
      this.reader = reader
      this.file = file
    }
  }
}
</script>
<style scoped>
.my-max-width {
  max-width: 300px
}
</style>
