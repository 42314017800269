<template>
  <div
    class="uk-card uk-card-default uk-margin-remove-top uk-margin-bottom"
    style="padding-bottom: 12px;"
  >
    <div 
      class="uk-flex uk-flex-middle uk-width-1-1 uk-card-header uk-margin-remove" 
      style="justify-content: space-between;"
    >
      <h2 
        class="uk-margin-remove"
        style="padding-top: 10px;"
      >
        {{ $t('crypto.route.accounts') }}
      </h2>
      <div 
        v-if="$route.path === '/crypto/ton/accounts'" 
        class="uk-flex uk-flex-right"
      >
        <div class="uk-width-1-1 uk-text-small">
          <Selector
            :item-count="selectedContract"
            :def="true"
            :amount="null"
            :start-line="null"
            :options="optionsContract"
            @setValue="(e) => selectedContract = e"
          />
        </div>
        <a 
          v-if="selectedContract !== 'All'" 
          uk-icon="icon: close" 
          style="align-self: center;"
          @click="selectedContract = 'All'" 
        />
      </div>
    </div>
    <AccountsTable 
      :keys="keys" 
      :update="true" 
      :default-length="10" 
      :hidden="false" 
      :contract="filterValue"
    />
  </div>
</template>
  
<script>
import AccountsTable from '@/components/Crypto/Ton/Accounts/Table'
import Selector from '@/components/Crypto/Ton/Atoms/Selector'
import { getQueryString } from '@/utils/api'
import { mapGetters } from 'vuex'
  
export default {
  name: 'TonAccountsList',
  components: {
    AccountsTable,
    Selector
  },
  data () {
    return {
      selectedContract: 'All'
    }
  },
  watch: {
    selectedContract () {
      this.setRoute()
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonExploredAccounts', 'getTonInterfaces']),
    keys () {
      return this.getTonExploredAccounts
    },
    optionsContract () {
      const bare = ['All']
      bare.push(...Object.keys(this.getTonInterfaces))
      return bare.sort()
    },
    filterValue () { return this.$route.query?.contract ?? 'All' }
  },
  methods: {
    routeChecker () {
      const { $route } = this
      if ($route.path !== '/crypto/ton/accounts') return
      if ('contract' in this.$route.query && $route.query.contract !== '') {
        this.selectedContract = $route.query.contract?.toString() ?? 'All'
      } else {
        this.selectedContract = 'All'
      }
    },
    setRoute () {
      const { $route } = this
      if ($route.path !== '/crypto/ton/accounts') return
      let contract = null
      if (this.selectedContract !== 'All') contract = this.selectedContract
      if (this.selectedContract !== this.filterValue) {
        const queryString = getQueryString({ contract } , true)
        if (queryString || $route.fullPath.split('?')[1] !== queryString) this.$router.replace(`/crypto/ton/accounts?${queryString}`)
      }
    }
  },
  created () {
    this.routeChecker()
  }
}
</script>