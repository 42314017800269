<template>
  <div class="uk-margin-top">
    {{ $t('search.by_ip_title') }} <b>
      {{ $route.params.ip }}
    </b>
    <div class="uk-card   uk-card-default uk-card-body">
      <h4>
        {{ $t('ip.available-instruments') }}
      </h4>
      <ul class="uk-list">
        <li
          class="uk-button uk-button-primary"
          @click="showInsight"
        >
          {{ $t('ip.IP-address-insights') }}
        </li>
      </ul>
    </div>

    <div class="uk-grid uk-margin-top data-uk-grid-margin">
      <div class="uk-width-expand">
        <ul class="uk-tab">
          <li class="uk-active">
            <a href="">
              {{ $t('search.founds') }} <span
                v-if="list"
                class="uk-badge"
              >
                {{ list.length }}
              </span>
            </a>
          </li>
        </ul>
        <ul class="uk-subnav uk-subnav-pill">
          <li :class="{ 'uk-active': (filter==='') }">
            <a
              href="#"
              @click="filter=''"
            >
              {{ $t('ip.all') }}
            </a>
          </li>
          <li :class="{ 'uk-active': (filter==='messenger') }">
            <a
              href="#"
              @click="filter='messenger'"
            >
              {{ $t('ip.messengers') }}
            </a>
          </li>
          <li :class="{ 'uk-active': (filter==='social') }">
            <a
              href="#"
              @click="filter='social'"
            >
              {{ $t('ip.social') }}
            </a>
          </li>
          <li :class="{ 'uk-active': (filter==='msisdn') }">
            <a
              href="#"
              @click="filter='msisdn'"
            >
              {{ $t('ip.MSISDN') }}
            </a>
          </li>
        </ul>
        <progress
          v-if="total > current"
          id="js-progressbar"
          class="uk-progress"
          :value="current"
          :max="total"
        />
        <div class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid">
          <div v-if="total <= current && filteredList.length === 0">
            <NotFound />
          </div>
          <div
            v-for="(item,id) in filteredList"
            :key="id"
            class="uk-grid-margin"
          >
            <usercard
              :key="item.getId"
              :generalUser="item"
            />
          </div>
        </div>
      </div>
    </div>
    <IpInsight
      v-if="insightShown"
      :ip="ip"
    />
  </div>
</template>

<script>
import UIkit from 'uikit'
import IpInsight from '@/components/IpInsight'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Ip',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    usercard: defineAsyncComponent(() => import('@/components/UserCard2')),
    IpInsight
  },
  props: {
    ip: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      details: {},
      insightShown: false,
      filter: '',
      query: {
        limit: 39,
        offset: 0
      },
      pagination: {
        page: 1,
        total: null,
        isNextPage: false
      }
    }
  },
  watch: {
    ip: function (from, to) {
      this.update()
    }
  },
  computed: {
    filteredList () {
      if (this.list) {
        return this.list.filter(card => {
          if (this.filter) return card.genericType === this.filter
          return true
        })
      } else {
        return []
      }
    },
    total: function () {
      return this.$store.state.fetcher.total
    },
    current: function () {
      return this.$store.state.fetcher.count
    },
    list: function () {
      if (this.$store.state.fetcher.list[this.ip]) { return this.$store.state.fetcher.list[this.ip] } else return []
    }
  },
  methods: {
    showInsight () {
      this.insightShown = true
      this.$nextTick(() => {
        UIkit.modal('#ipInsight').show()
      })
    },
    updateDetails (raw) {
      this.details = raw
    },
    update () {
      this.insightShown = false
      const action = 'fetcher/fetchIp'
      this.$store.dispatch(action, this.ip)
    }
  },
  mounted () {
    this.update()
  }
}
</script>
