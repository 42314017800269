<template>
  <div>
    <Loader v-if="isLoaded['forums_' + id] === false"/>
    <div v-else>
      <div>
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div class="uk-width-1-1@m">
            <gu
              v-if="forum"
              :forum="forum"
            />
          </div>
        </div>
      </div>
      <div
        v-if="forum"
        class="uk-margin-medium-top"
      >
        <ul
          uk-tab
        >
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#topics',
              'uk-disabled': !forum.topics_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{ hash: '#topics' }"
              @click="visitTab('#topics')"
            >
              {{ $t('forums.topics') }}
            </router-link>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ forum.topics_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#users',
              'uk-disabled': !forum.users_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{ hash: '#users' }"
              @click="visitTab('#users')"
            >
              {{ $t('forums.users-title') }}
            </router-link>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ forum.users_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#messages',
              'uk-disabled': !forum.messages_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{ hash: '#messages' }"
              @click="visitTab('#messages')"
            >
              {{ $t('forums.messages') }}
            </router-link>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ forum.messages_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#goods',
              'uk-disabled': !forum.goods_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{ hash: '#goods' }"
              @click="visitTab('#goods')"
            >
              {{ $t('forums.goods') }}
            </router-link>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ forum.goods_count }}
              </span>
            </span>
          </li>
        </ul>
        <ul
          v-if="forum"
          class="uk-switcher"
        >
          <li
            v-if="forum.topics_count"
          >
            <a
              name="topics"
            />
            <TopicsList
              v-if="visitedTabs.includes('#topics')"
              :forumId="id"
              type="topic"
            />
          </li>
          <li
            v-if="forum.users_count"
          >
            <a
              name="users"
            />
            <UserList
              v-if="visitedTabs.includes('#users')"
              :forumId="id"
              type="members"
            />
          </li>
          <li
            v-if="forum.messages_count"
          >
            <a
              name="messages"
            />
            <MessageList
              v-if="visitedTabs.includes('#messages')"
              :forumId="id"
              type="forum"
            />
          </li>
          <li
            v-if="forum.goods_count"
          >
            <a
              name="goods"
            />
            <GoodsList
              v-if="visitedTabs.includes('#goods')"
              :forumId="id"
              type="forum"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import GeneralInformation from '@/components/Forums/ForumDetails/GeneralInformation'
import UserList from '@/views/Forums/UserList'
import MessageList from '@/views/Forums/MessageList'
import TopicsList from '@/views/Forums/TopicsList'
import GoodsList from '@/views/Forums/GoodsList'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ForumsForumDetails',
  components: {
    Loader,
    gu: GeneralInformation,
    TopicsList,
    MessageList,
    UserList,
    GoodsList
  },
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      visitedTabs: [this.$route.hash || '#topics']
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapGetters('forums', [
      'allForums',
      'isLoaded'
    ]),
    forum () {
      let forum = this.allForums[this.id]
      return forum
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchForumById'
    ]),
    update () {
      this.fetchForumById(this.id)
    },
    visitTab (tab) {
      this.visitedTabs = [ ...new Set([ ...this.visitedTabs, tab ]) ]
    }
  },
  created () {
    if (!this.$route.hash) this.$router.replace({ hash: '#topics' })
  },
  mounted () {
    this.$nextTick(() => this.update())
  }
}
</script>
