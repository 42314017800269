<template>
  <div>
    <div
      ref="element"
      uk-modal
    >
      <div class="uk-modal-dialog">
        <button
          class="uk-modal-close-default"
          type="button"
          uk-close
        />
        <div class="uk-modal-header">
          <h2
            v-if="title"
            class="uk-modal-title uk-text-center"
          >
            {{ title }}
          </h2>
        </div>
        <div class="uk-modal-body">
          <Tabs
            v-if="hasEntries"
            :key="key"
            :entries="currentEntries"
            @select="select"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UIkit from 'uikit'
import Tabs from './Tabs.vue'
export default {
  name: 'SearchingFacesDialog',
  components: {
    Tabs
  },
  props: {
    title: {
      type: String
    },
    entries: {
      type: Array
    }
  },
  data () {
    return {
      modal: null,
      currentEntries: []
    }
  },
  watch: {
    entries: {
      immediate: true,
      deep: true,
      handler (value) {
        try {
          this.entriesHandler(value)
        } catch (error) {
          console.debug(error)
        }
      }
    },
    hasEntries: {
      immediate: true,
      handler (value) {
        try {
          this.handler(value)
        } catch (error) {
          console.debug(error)
        }
      }
    }
  },
  computed: {
    entriesLength () {
      return this.currentEntries?.length || 0
    },
    hasEntries () {
      return this.entriesLength > 0
    },
    key () {
      if (this.hasEntries) {
        return this.currentEntries.join(',')
      }
      return ''
    }
  },
  methods: {
    show () {
      try {
        if (this.modal) {
          this.modal.show()
        }
      } catch (error) {
        console.debug(error)
      }
    },
    hide () {
      try {
        if (this.modal) {
          this.currentEntries = []
          this.modal.hide()
        }
      } catch (error) {
        console.debug(error)
      }
    },
    initialization () {
      try {
        if (this.$refs?.element) {
          this.modal = UIkit.modal(this.$refs.element)
          UIkit.util.on(this.$refs.element, 'hide', () => {
            try {
              this.currentEntries = []
            } catch (error) {
              console.debug(error)
            }
          })
        }
      } catch (error) {
        console.debug(error)
      }
    },
    select (entry) {
      try {
        this.$emit('select', entry)
        this.hide()
      } catch (error) {
        console.debug(error)
      }
    },
    handler (value) {
      try {
        if (value) {
          this.show()
        }
      } catch (error) {
        console.debug(error)
      }
    },
    entriesHandler (value) {
      try {
        this.currentEntries = value
      } catch (error) {
        console.debug(error)
      }
    }
  },
  mounted () {
    try {
      this.initialization()
    } catch (error) {
      console.debug(error)
    }
  }
}
</script>
