<template>
  <div
    class="uk-grid-small uk-child-width-1-1 uk-text-left"
    uk-grid
  >
    <div
      class="uk-text-right uk-width-1-1"
    >
      <button
        class="uk-button-text uk-button translate-btn"
        :uk-tooltip="textRender"
        :class="{
          'translate-btn__loading': loading,
          'translate-btn__translated': translatedShown,
          'translate-btn__disabled': false
        }"
        @click="translatedShown = !translatedShown"
      >
        <font-awesome-icon
          :icon="['fa', 'language']"
          class="translate-btn__icon"
        />
        <span
          class="translate-btn__text"
        >
          {{ textRender }}
        </span>
      </button>
    </div>
    
    <div
      v-for="(history, i) in historical"
      :key="i"
    >
      <item
        :data="history"
        :is-translated="translatedShown"
        @loading="$e => loading = $e"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ForumsHistoricalData',
  components: {
    item: defineAsyncComponent(() => import('@/components/Forums/UserDetails/HistoricalDataItem.vue'))
  },
  props: {
    historical: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      translatedShown: false,
      loading: false
    }
  },
  computed: {
    textRender () {
      return this.translatedShown ? this.$t('tgcp.messages.show-original') : this.$t('tgcp.messages.translate')
    }
  }
}
</script>
