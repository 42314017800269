import GeneralUser from './generalUser'
// import config from '@/config'

var Zalo = class {
  formatData (data) {
    var dataList = [data]
    if (Array.isArray(data)) {
      dataList = data
    }
    var returnData = []
    for (var i in dataList) {
      data = dataList[i]
      var gu = new GeneralUser()
      if (data.avatar_url) {
        gu.avatar = data.avatar_url
      }
      if (data.username) {
        gu.name = data.username
      }
      if (data.display_name) {
        gu.fullName = data.display_name
      }
      gu.type = 'zalo'
      gu.genericType = 'messenger'
      gu.user_id = data.uid
      gu.externalLink = '/zalo/user/' + data.uid
      if (data.phone_no.indexOf('+') < 0) {
        data.phone_no = '+' + data.phone_no
      }
      gu.phone = data.phone_no
      if (data.status_text) {
        gu.bio = data.status_text
      }
      gu.raw = data
      if (gu.raw.birthday) {
        if (gu.raw.birthday.split('-').length < 3) {
          let bd = gu.raw.birthday.split('-')
          gu.raw.birthday = bd[1] + ' ' + new Date(new Date('1970-01-01').setMonth(bd[0] - 1)).toLocaleString('default', { month: 'short' })
        }
      }
      if (gu.raw.gender !== null) {
        if (gu.raw.gender) {
          gu.raw.sex = 'Female'
        } else {
          gu.raw.sex = 'Male'
        }
      }
      returnData.push(gu)
    }
    return returnData
  }
  findByPhone (phone, dispatch) {
    return dispatch('zalo/fetchProfileByPhoneNumber', phone, { root: true })
      .then(function (data) {
        return Promise.resolve(new Zalo().formatData(data))
      })
      .catch(function () {
        return {}
      })
  }
  findByNickName (nickname, dispatch) {
    return dispatch('zalo/fetchProfileByUserName', nickname, { root: true })
      .then(function (data) {
        return Promise.resolve(new Zalo().formatData(data))
      })
      .catch(function () {
        return {}
      })
  }
}
export default new Zalo()
