const ADDRESS_REGEX = new RegExp(/[UEk0][Qf][\w\-]{46}/)
const TX_REGEX_OLD = new RegExp(/^\d+[\$:](?<hash>[\w\+\-\/]{43}\=)[\$:][\w\+\-\/]+$/, 'i')
// address links
const TONWEB_ADDRESS_REGEX = new RegExp(`^ton://transfer/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONSCAN_ADDRESS_REGEX = new RegExp(`^https://tonscan.org/address/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONWHALES_ADDRESS_REGEX = new RegExp(`^https://tonwhales.com(?:\/[a-z]{2})?/explorer/address/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONAPI_ADDRESS_REGEX = new RegExp(`^https://tonapi.io/account/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONSH_ADDRESS_REGEX = new RegExp(`^https://ton.sh/address/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONVIEWER_ADDRESS_REGEX = new RegExp(`^https://tonviewer.com/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONNFT_ADDRESS_REGEX = new RegExp(`^https://explorer.tonnft.tools/address/(?<address>${ADDRESS_REGEX.source})`, 'i')
const TONOBS_ADDRESS_REGEX = new RegExp(`^https://tonobserver.com/explorer/info?address=(?<address>${ADDRESS_REGEX.source})`, 'i')
const DTON_ADDRESS_REGEX = new RegExp(`^https://dton.io/a/(?<address>${ADDRESS_REGEX.source})`, 'i')

export const shortTON = (rawTON) => {
    return (Number(BigInt(rawTON) * 100n) / 100000000000).toFixed(3).toString()
}

export const fullTON = (rawTON, delta = true) => {
    return (BigInt(rawTON) > 0n && delta ? '+' : '') + (Number(BigInt(rawTON) * 100n) / 100000000000).toString()
}

export const roundTON = (rawTON, delta = false) => {
    const out = (Number(BigInt(rawTON) * 100n) / 100000000000)
    return (BigInt(rawTON) > 0n && delta ? '+' : '') + Math.round(out).toString()
}

export const tinyTON = (rawTON) => {
    const out = (Number(BigInt(rawTON) * 100n) / 100000000000)
    return out.toFixed(5).toString()
}

export const blockKeyGen = (workchain, shard, seqNo) => `${workchain}:${shard}:${seqNo}`

export const msToISO = (time) => new Date(time).toISOString().split('.')[0] +'Z'

export const toCompact = (input) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return formatter.format(input)
}

export const isMobile = () => useDevice().isMobile

export const opToHex = (op) => `0x${op.toString(16)}`

export const colorAmount = (ton) => BigInt(ton) > 0n ? 'green' : BigInt(ton) === 0n ? '' : 'red'

export const CEXAddress = (input) => input[0] === 'f' ? ('-1:' + input.substring(2)) : input.substring(0, 1) + ':' + input.substring(2)

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value)
}

export const isHex = (value) => {
    return /^-?[\da-fA-F]+$/.test(value)
}

export const isValidName = (value) => {
    return /^[_\da-zA-z]+$/.test(value)
}

export const truncString = (value, keepLeft = 3, keepRight = keepLeft) => {
    if (value.length < (keepLeft + keepRight)) return value
    return value.slice(0, keepLeft) + '...' + value.slice(value.length - keepRight, value.length)
}

export const chainTitle = (id) => {
    if (id===0) return 'BaseChain'
    if (id===-1) return 'MasterChain'
    return id
}

export const toBase64Web = (base64) => base64.replace(/\+/g, '-').replace(/\//g, '_')

export const toBase64Rfc = (base64) => base64.replace(/\-/g, '+').replace(/_/g, '/')

const feeFormatter = new Intl.NumberFormat('fullwide', {
    maximumFractionDigits: 9,
    minimumFractionDigits: 0
})

const currencyFormatter = new Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
})

export const formatTons = function formatNanoTonsLimitNumberLength (value, decimals = 9, round = false) {
    const valueMultiplier = Number.isInteger(decimals)
        ? Math.pow(10, decimals)
        : 10**9

    return round
        ? currencyFormatter.format(value / valueMultiplier)
        : feeFormatter.format(value / valueMultiplier)
}

export const badAddresses = {
    '0:0000000000000000000000000000000000000000000000000000000000000000' : {
        name: 'Burner',
        hex: '0:0000000000000000000000000000000000000000000000000000000000000000',
        base64: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
    },
    '-1:ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffef' : {
        name: 'Burner',
        hex: '0:0000000000000000000000000000000000000000000000000000000000000000',
        base64: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
    },
    '-1:0000000000000000000000000000000000000000000000000000000000000000' : {
        name: 'System',
        hex: '-1:0000000000000000000000000000000000000000000000000000000000000000',
        base64: 'Ef8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAU'
    },
    '-1:3333333333333333333333333333333333333333333333333333333333333333' : {
        name: 'Elector',
        hex: '-1:3333333333333333333333333333333333333333333333333333333333333333',
        base64: 'Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF'
    },
    '-1:34517c7bdf5187c55af4f8b61fdc321588c7ab768dee24b006df29106458d7cf' : {
        name: 'Logs test',
        hex: '-1:34517c7bdf5187c55af4f8b61fdc321588c7ab768dee24b006df29106458d7cf',
        base64: 'Ef80UXx731GHxVr0-LYf3DIViMerdo3uJLAG3ykQZFjXz2kW'
    },
    '-1:5555555555555555555555555555555555555555555555555555555555555555' : {
        name: 'Config',
        hex: '-1:5555555555555555555555555555555555555555555555555555555555555555',
        base64: 'Ef9VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVbxn'
    },
    '0:07235bc6bb0edde161be0ad7c3ad6a843cdb6652e2a32851057899996aaa9f59' : {
        name: 'BSC Bridge',
        hex: '0:07235bc6bb0edde161be0ad7c3ad6a843cdb6652e2a32851057899996aaa9f59',
        base64: 'EQAHI1vGuw7d4WG-CtfDrWqEPNtmUuKjKFEFeJmZaqqfWTvW'
    },
    '0:aecef20e5cb8c7dadbf7e60638b73848bd70aa6560ee2bd91484519728b22683' : {
        name: 'ETH Bridge',
        hex: '0:aecef20e5cb8c7dadbf7e60638b73848bd70aa6560ee2bd91484519728b22683',
        base64: 'EQCuzvIOXLjH2tv35gY4tzhIvXCqZWDuK9kUhFGXKLImgxT5'
    }
}

export const knownOp = {
    0: 'System op',
    1: 'Received TONs',
    2: 'Sending TONs',
    99: 'Multisend'
}

export const trnParse = (input) => {
    if ((input.length === 44 && input.endsWith('=')) || input.match(/^[\da-f]{64}$/))
        return {type: 'transaction',
            value: {
                hash: input
            }
        }
    return null
}

export const blockParse = (input) => {
    const checker = input.split(':')
    if (checker.length !== 3 && checker.length !== 2) return null
    if (checker[0] !== '-1' && checker[0] !== '0') return null
    if (!(isNumeric(checker[1]))) return null
    if (checker[2] && !(isNumeric(checker[2]))) return null
    return {
        type: 'block',
        value: {
            workchain: Number(checker[0]),
            shard: checker[2] ? BigInt(checker[1]) : -9223372036854775808n,
            seq_no: checker[2] ? Number(checker[2]) : Number(checker[1])
        }
    }
}

export const removeClutter = (input) => {
    if (input)
        for (let regex of [
            // addresses
            TONWEB_ADDRESS_REGEX, TONSCAN_ADDRESS_REGEX,TONWHALES_ADDRESS_REGEX,
            TONAPI_ADDRESS_REGEX,TONSH_ADDRESS_REGEX, TONVIEWER_ADDRESS_REGEX,
            TONNFT_ADDRESS_REGEX,TONOBS_ADDRESS_REGEX, DTON_ADDRESS_REGEX,
            // transactions
            TX_REGEX_OLD

        ]) {
            if (regex.test(input)) {
                const trim = regex.exec(input)
                if (trim?.length && trim[1]) return trim[1] 
            }
        }
    return input
}

export const addParse = (input) => {
    input = removeClutter(input)

    if (input.length !== 48 && input.length !== 66 && input.length !== 67) return null
    if ((input.length === 66 && input[1] === ':' && input.split(':')[1].match(/^[\da-fA-F]{64}$/)) 
        || (input.length === 67 && input[2] === ':' && input.split(':')[1].match(/^[\da-fA-F]{64}$/))
        || input.match(ADDRESS_REGEX)) return {
        type: 'account',
        value: {
            hex: input
        }
    }
    return null

}

export const asciiParse = (input) => {
    if (input && input.match(/^[ -~]+$/)) return {
        type: 'label',
        value: input
    }
    return null
}
