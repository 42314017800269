<template>
  <div
    class="home uk-container"
  >
    <div
      class="uk-margin"
    >
      <form
        @submit.prevent="search"
      >
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
        />
        <vueSelect
          v-model="currentSearchType"
          :options="searchTypeTranslated"
          label="label"
          class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
        >
          <span
            uk-search-icon
          />
        </button>
      </form>
      <SearchInDbHint class="uk-margin-bottom" />
      <div
        class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
        uk-grid
      >
        <div>
          <router-link
            :to="{ name: 'forums_user_list' }"
            class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
          >
            <Loader
              v-if="!isLoaded"
            />
            <h3
              v-else-if="!stats.users_count"
            >
              -
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(stats.users_count) }}
            </h3>
            <h3
              class="uk-text-muted uk-margin-remove-top"
            >
              {{ $t('tgcp.stats.users_count') }}
            </h3>
          </router-link>
        </div>
        <div>
          <router-link
            :to="{ name: 'forums_list' }"
            class="uk-card uk-card-small uk-card-primary uk-card-hover uk-card-body uk-dark"
          >
            <Loader
              v-if="!isLoaded"
            />
            <h3
              v-else-if="!stats.forums_count"
            >
              -
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(stats.forums_count) }}
            </h3>
            <h3
              class="uk-text-muted uk-margin-remove-top"
            >
              {{ $t('forums.forums') }}
            </h3>
          </router-link>
        </div>
        <div>
          <router-link
            :to="{ name: 'forums_topics_list' }"
            class="uk-card uk-card-small uk-card-secondary uk-card-hover uk-card-body uk-dark"
          >
            <Loader
              v-if="!isLoaded"
            />
            <h3
              v-else-if="!stats.topics_count"
            >
              -
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(stats.topics_count) }}
            </h3>
            <h3
              class="uk-text-muted uk-margin-remove-top"
            >
              {{ $t('forums.topics') }}
            </h3>
          </router-link>
        </div>
        <div>
          <router-link
            :to="{ name: 'forums_message_list' }"
            class="uk-card uk-card-small uk-card-default  uk-card-hover uk-card-body uk-dark"
          >
            <Loader
              v-if="!isLoaded"
            />
            <h3
              v-else-if="!stats.messages_count"
            >
              -
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(stats.messages_count) }}
            </h3>
            <h3
              class="uk-text-muted uk-margin-remove-top"
            >
              {{ $t('forums.messages') }}
            </h3>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import vueSelect from '@/components/VueSelect'
import { mapGetters, mapActions } from 'vuex'
import { numberFormat } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ForumsStats',
  components: {
    Loader,
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    vueSelect
  },
  data () {
    return {
      searchString: '',
      searchType: 'forums_message_list'
    }
  },
  computed: {
    ...mapGetters({ stats: 'forums/stats' }),
    currentSearchType: {
      get () {
        return this.searchType
      },
      set (obj) {
        this.searchType = obj && obj.value
        this.update()
      }
    },
    searchTypeTranslated () {
      return [
        { value: 'forums_message_list', label: this.$t('search.messages') },
        { value: 'forums_list', label: this.$t('search.forums') },
        { value: 'forums_user_list', label: this.$t('search.users') },
        { value: 'forums_topics_list', label: this.$t('search.topics') }
      ]
    },
    isLoaded () {
      return this.$store.state.forums.isLoaded.stats
    }
  },
  methods: {
    numberFormat,
    search () {
      let query = { search: this.searchString }
      if (this.searchType === 'forums_message_list') query = { message: this.searchString }
      this.$router.push({ name: this.searchType, query })
    },
    ...mapActions({ update: 'forums/fetchStats' })
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
