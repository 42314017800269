<template>
  <router-link 
    v-if="error" 
    :to="{ path: '/crypto/ton' }"
  >
    {{ 'An error occured while loading block! Go to overview page..' }}
  </router-link>
  <div 
    v-else-if="!isLoaded" 
    class="uk-flex uk-flex-center"
  >
    <Loader />
  </div>
  <div
    v-else-if="block"
    class="uk-flex uk-flex-column uk-remove-margin"
    style="row-gap: 12px;" 
  >
    <div 
      class="uk-card uk-card-default uk-margin-remove"
    >
      <h2 
        class="uk-margin-remove uk-card-header"
        style="padding-top: 10px;"
      >
        {{ $t('crypto.route.block') + ` ${wc}:${truncString(sh, 3, 4)}:${sq}` }}
      </h2>
      <PropsTable
        :block="block"
      />
    </div>
    <ul 
      v-if="block?.transaction_keys.length" 
      class="uk-child-width-expand uk-text-medium tab-styler uk-margin-remove-bottom" 
      uk-tab
    >
      <li 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#transactions' || $route.hash === '#overview')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#transactions', query: $route.query}" >
          {{ $t('crypto.route.transactions') }}
          <span class="uk-badge uk-margin-small-left">
            {{ trKeys.length }}
          </span>
        </router-link>
      </li>
      <li 
        v-if="inMessageKeys.length + outMessageKeys.length > 0" 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#messages')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#messages', query: $route.query}">
          {{ $t('crypto.route.messages') }}
          <span class="uk-badge uk-margin-small-left">
            {{ inMessageKeys.length + outMessageKeys.length }}
          </span>
        </router-link>
      </li>
      <li 
        v-if="loadedAccountKeys.length + unloadedAccountKeys.length > 0" 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#accounts')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#accounts', query: $route.query}">
          {{ $t('crypto.route.accounts') }}
          <span class="uk-badge uk-margin-small-left">
            {{ loadedAccountKeys.length + unloadedAccountKeys.length }}
          </span>
        </router-link>
      </li>
      <li 
        v-if="block.shard_keys.length > 0" 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#shards')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#shards', query: $route.query}">
          {{ $t('crypto.ton.shards') }}
          <span class="uk-badge uk-margin-small-left">
            {{ block.shard_keys.length }}
          </span>
        </router-link>
      </li>
    </ul>
    <div class="uk-card uk-card-default uk-margin-remove">
      <div 
        v-if="($route.hash === '#transactions' || $route.hash === '#overview') && block?.transaction_keys.length" 
        id="transactions_block"
      >
        <div
          v-if="block.shard_keys.length > 0"
          class="uk-child-width-auto uk-text-left uk-margin-small uk-margin-left uk-margin-small-top" 
        >
          <label>
            <input 
              v-model="deepTrKeys" 
              class="uk-checkbox uk-margin-small-right" 
              type="checkbox"
            />
            {{ $t('crypto.options.deep_transactions') }}
          </label>
        </div>
        <TransactionsTable 
          :keys="trKeys" 
          :update="false" 
          :default-length="10" 
          :hidden="trKeys.length === 0" 
          :account="null"
          :exclude-m-c="false"
        />
      </div>
      <div 
        v-if="($route.hash === '#messages' ) && block?.shard_keys" 
        id="messages_block"
      >
        <div 
          v-if="block.shard_keys.length > 0"
          class="uk-child-width-auto uk-text-left uk-margin-small uk-margin-left uk-margin-small-top" 
        >
          <label>
            <input 
              v-model="deepTrKeys" 
              class="uk-checkbox uk-margin-small-right" 
              type="checkbox"
            />
            {{ $t('crypto.options.deep_messages') }}
          </label>
        </div>
        <h3 
          v-if="inMessageKeys.length > 0" 
          class="uk-margin-small-top uk-margin-small"
        >
          {{ $t('crypto.general.in_msg') + ` (${inMessageKeys.length})` }}
        </h3>
        <MessagesTable 
          :show-link="true" 
          :item-selector="false" 
          :default-length="10" 
          :update="false" 
          :keys="inMessageKeys" 
          :hidden="inMessageKeys.length === 0"
        />
        <h3 
          v-if="outMessageKeys.length > 0" 
          class="uk-margin-small-top uk-margin-small"
        >
          {{ $t('crypto.general.out_msg')+ ` (${outMessageKeys.length})` }}
        </h3>
        <MessagesTable 
          :show-link="true" 
          :item-selector="false" 
          :default-length="10" 
          :update="false" 
          :keys="outMessageKeys" 
          :hidden="outMessageKeys.length === 0"
        />
      </div>
      <div 
        v-if="($route.hash === '#accounts' ) && loadedAccountKeys.length + unloadedAccountKeys.length > 0" 
        id="accounts_block"
      >
        <div 
          v-if="block.shard_keys.length > 0"
          class="uk-child-width-auto uk-text-left uk-margin-small uk-margin-left uk-margin-small-top" 
        >
          <label>
            <input 
              v-model="deepTrKeys" 
              class="uk-checkbox uk-margin-small-right" 
              type="checkbox"
            />
            {{ $t('crypto.options.deep_accounts') }}
          </label>
        </div>
        <AccountsTable 
          :default-length="10" 
          :keys="loadedAccountKeys" 
          :hidden="loadedAccountKeys.length === 0" 
          :update="false" 
          :item-selector="false" 
          :contract="'All'"
        />
      </div>
      <div 
        v-if="($route.hash === '#shards' ) && block.shard_keys.length > 0" 
        id="shards"
      >
        <BlocksTable 
          :default-length="5" 
          :update="false" 
          :keys="block.shard_keys" 
          :hidden="block.shard_keys.length === 0" 
          :line-link="false" 
          :exclude-empty="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import PropsTable from '@/components/Crypto/Ton/Blocks/PropsTable'
import MessagesTable from '@/components/Crypto/Ton/Messages/Table'
import BlocksTable from '@/components/Crypto/Ton/Blocks/Table'
import AccountsTable from '@/components/Crypto/Ton/Accounts/Table'
import TransactionsTable from '@/components/Crypto/Ton/Transactions/Table'
import { blockKeyGen, truncString } from '@/utils/ton-filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TonBlockInfo',
  components: {
    Loader,
    PropsTable,
    BlocksTable,
    MessagesTable,
    TransactionsTable,
    AccountsTable
  },
  data () {
    return {
      error: false,
      deepTrKeys: false
    }
  },
  watch: {
    sq: {
      handler (to, from) {
        if (from && to) this.loadBlock()
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonBlocks', 'deepTonTransactionKeys', 'getTonMessageKeys', 'getTonAccountKeys']),
    wc () { return this.$route?.query?.workchain },
    sh () { return this.$route?.query?.shard },
    sq () { return this.$route?.query?.seq_no },
    key () { 
      const { wc, sh, sq } = this
      if (wc !== undefined && sh !== undefined && sq !== undefined)
        return this.blockKeyGen(this.wc, this.sh, this.sq)
      return null
    },
    isLoaded () {
      return this.sq ? this.$store.state.crypto.isLoaded['ton_block_' + this.sq] : false
    },
    block () {
      return this.getTonBlocks[this.key] ?? null
    },
    trKeys () {
      return this.deepTrKeys ? this.deepTonTransactionKeys(this.key) : this.block?.transaction_keys ?? []
    },
    inMessageKeys () {
      return this.getTonMessageKeys(this.trKeys, true, false)
    },
    outMessageKeys () {
      return this.getTonMessageKeys(this.trKeys, false, true)
    },
    loadedAccountKeys () {
      return this.getTonAccountKeys([...this.inMessageKeys, ...this.outMessageKeys])
    },
    unloadedAccountKeys () {
      return this.getTonAccountKeys([...this.inMessageKeys, ...this.outMessageKeys], false)
    }
  },
  methods: {
    blockKeyGen,
    truncString,
    ...mapActions('crypto', ['fetchTonBlock', 'fetchBareTonAccounts']),
    async loadBlock () {
      this.error = false
      await this.fetchTonBlock({ workchain: this.wc, shard: this.sh, seqNo: this.sq, full: true})
      this.fetchBareTonAccounts(this.unloadedAccountKeys)
      if (!this.block) {
        this.error = true
        return
      }
    }
  },
  async mounted () {
    await this.loadBlock()
  }
}
</script>