<template>
  <div 
    class="uk-flex uk-flex-column"
    style="column-gap: 0.5rem"
  >
    <div>
      <SearchBar/>
    </div>
    <div 
      class="uk-flex uk-margin-top" 
      style="column-gap: 0.5rem"
    >
      <div class="uk-flex-inline uk-width-1-1 uk-background-default uk-padding-small uk-flex-column">
        <h3 
          class="uk-align-center" 
          style="margin-bottom: 0.5rem;"
        >
          {{ $t('crypto.general.last_blocks') }}
        </h3>
        <BlockList/>   
      </div>
      <div 
        class="uk-flex-inline uk-width-1-1 uk-background-default uk-padding-small uk-flex-column" 
        style="; height: fit-content"
      > 
        <h3 
          class="uk-align-center" 
          style="margin-bottom: 0.5rem;"
        >
          {{ $t('crypto.general.end_stats') }}
        </h3>
        <Statistics/>   
      </div>
    </div>
  </div>
</template>

<script>
import BlockList from '@/components/Crypto/Ton/Main/BlockList'
import SearchBar from '@/components/Crypto/Ton/Search/SearchBar'
import Statistics from '@/components/Crypto/Ton/Main/Statistics'
import { mapActions } from 'vuex'

export default {
  name: 'TonMainPage',
  components: {
    BlockList,
    Statistics,
    SearchBar
  },
  methods: {
    ...mapActions('crypto', ['mainTonPageLoad'])
  },
  async mounted () {
    await this.mainTonPageLoad()
  }
}
</script>