<template>
  <div
    v-if="user"
    class="uk-width-expand"
  >
    <h3 class="uk-card-title uk-margin-remove-bottom uk-text-left">
      {{ user.display_name }}
    </h3>
    <p class="uk-text-meta uk-margin-remove-top uk-text-left">#<router-link
      :to="{ name: 'forums_user_details', params: { id: user.id }}"
    >{{ user.id }}</router-link>
      <span
        v-if="user.forum_user_id"
        class="forum_user_id-inHeader"
      >{{ this.$t('forums.users.sort.forum_user_id') }}: {{ user.forum_user_id }}</span>
      <br />
      @<router-link
        :to="{
          name: 'nicknameResults',
          params: { nickname: user.display_name }}"
      >{{ user.display_name }}</router-link>
    </p>
    <p
      v-if="extractedDataBio"
      class="uk-text-meta uk-text-left uk-margin-right"
    > <span v-html="extractedDataBio"/><br />
    </p>
    <div>
      <div class="uk-margin-bottom">
        <h4
          v-if="user.status"
          class="uk-text-small uk-margin-remove-top uk-text-muted uk-text-left"
        >
          {{ escape(user.status) }}
        </h4>
        <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-top">
          <li v-if="user.url">
            Link: {{ user.url }} <a
              :href="user.url"
              uk-icon="link"
              class="ui-link"
            />
          </li>
          <li v-if="user.phone_numer">
            {{ $t('tgcp.userinfo.phone') }}: {{ user.phone_number }}
          </li>
          <li v-if="user.birthdate">
            {{ $t('tgcp.userinfo.birthdate') }}: {{ dayjs(new Date(user.birthdate),true) }}
          </li>
          <li v-if="user.age">
            {{ $t('tgcp.userinfo.age') }}: {{ user.age }}
          </li>
          <li v-if="user.registration_date">
            {{ $t('tgcp.userinfo.registration-date') }}: {{ dayjs(user.registration_date,true) }}
          </li>
          <li v-if="user.last_visit_date ">
            {{ $t('tgcp.userinfo.last-visit') }}: {{ dayjs(user.last_visit_date) }}
          </li>
          <li
            v-if="user.signature"
            class="uk-text-truncate"
          >
            {{ $t('tgcp.userinfo.signature') }}: {{ escape(user.signature) }}
          </li>
          <li v-if="user.website">
            {{ $t('tgcp.userinfo.website') }}: {{ user.website }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import extractData from '@/utils/dataExtraction'

export default {
  name: 'UserInfo',
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  computed: {
    extractedDataBio () {
      if (!this.user) return
      return extractData(this.user.bio)
    }
  },
  methods: {
    escape (text) {
      return text.replace(/[{}]/g, '').replace(/<[^>]*>/gm, '')
    }
  }
}
</script>
<style scoped>
.forum_user_id-inHeader {
  margin-left:1rem; padding-left:1rem;border-left: 1px solid #999
}
</style>
