import { library } from '@fortawesome/fontawesome-svg-core'
import { faAt, faEye, faBookmark as fasBookmark, faDesktop, faMobile, faTablet, faBookDead, faGlobe, faLanguage, faBackward, faFastBackward, faForward, faFastForward,  faPlay, faPause, faRetweet, faSync, faComments } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as farBookmark, faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons'
import { faTelegramPlane, faQq, faOdnoklassniki, faFoursquare, faBitbucket, faTrello, faPeriscope, faTwitch, faGitlab, faEbay, faFlickr, faPatreon, faMedium, faReddit, faFacebookF, faVk, faTwitter, faInstagram, faSkype, faViber, faSlack, faDiscord, faLine, faWeixin, faWhatsapp, faSafari, faFirefox, faEdge, faChrome, faWindows, faApple, faAndroid, faLinux, faInternetExplorer } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBackward)
library.add(faPlay)
library.add(faPause)
library.add(faFastBackward)
library.add(faForward)
library.add(faComments)
library.add(faOdnoklassniki)
library.add(faFastForward)
library.add(faEye)
library.add(faMoneyBillAlt)
library.add(faLine)
library.add(faDiscord)
library.add(faTrello)
library.add(faPatreon)
library.add(faLanguage)
library.add(faMedium)
library.add(faViber)
library.add(faSlack)
library.add(faFacebookF)
library.add(faQq)
library.add(faSkype)
library.add(faTwitter)
library.add(faInstagram)
library.add(faVk)
library.add(faEbay)
library.add(faTelegramPlane)
library.add(faBitbucket)
library.add(faGitlab)
library.add(faTwitch)
library.add(faWeixin)
library.add(faFoursquare)
library.add(faFlickr)
library.add(faReddit)
library.add(faBookDead)
library.add(faGlobe)
library.add(faWhatsapp)
library.add(faPeriscope)
library.add(fasBookmark, farBookmark)
library.add(faAt)
library.add(faSafari, faFirefox, faEdge, faChrome)
library.add(faWindows, faApple, faAndroid, faLinux)
library.add(faDesktop, faMobile, faTablet, faRetweet)
library.add(faInternetExplorer)
library.add(faSync)
export default FontAwesomeIcon
