<template>
  <div
    style="padding-bottom: 20px"
    class="uk-margin-top"
  >
    <span v-if="!providers">
      <span v-if="phoneArray">
        <span
          class="uk-margin-left uk-button-primary uk-button uk-width-1-6"
          @click="showHLR"
        >
          {{ $t('hlr.check_hlr') }}
        </span>
        <span
          v-if="allowSS7()"
          class="uk-margin-left uk-button-primary uk-button"
          @click="showIMSI"
        >
          {{ $t('hlr.find_imsi') }}
        </span>
        <span
          v-if="allowSS7()"
          class="uk-margin-left uk-button-primary uk-button"
          @click="showLocation"
        >
          {{ $t('hlr.find_location') }}
        </span>
        <button
          v-if="currentCase"
          class="uk-margin-left uk-button-primary uk-button uk-width-1-6"
          @click="createProfile"
        >
          {{ selectMode === 'normal' ? $t('cases.profile.add-profile') : $t('cases.confirm') + ' (' + countSelectedCards + ') ' + $t('cases.items') }}
        </button>
        <VueServerSelect
          v-if="currentCase && selectMode === 'selected'"
          v-model="currentProfileId"
          :defaultOptions="[ createNewProfileItem ]"
          :loadDataPortion="loadProfiles"
          :refreshList="refreshProfileList"
          class="uk-display-inline-block uk-margin-small-left uk-form-width-small uk-form-width-medium uk-text-small"
        />
      </span>
      <table class="uk-table uk-table-divider">
        <tbody>
          <tr
            v-for="(phoneData, key) in phoneArray"
            :key="key"
          >
            <td>
              {{ $t('phone_table.' + key) }}:
            </td>
            <td>
              {{ phoneData }}
            </td>
          </tr>
          <tr v-if="isTonVirtual">
            <td>
              {{ $t('phone_table.owner') }}
            </td>
            <td>
              <Loader
                v-if="!tonIsLoaded"
                ratio="1.0"
              />
              <router-link
                v-else
                :to="{
                  name: 'crypto_ton_address',
                  params: {
                    address: tonAddress
                  }
                }"
              >
                {{ tonOwner }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </span>
    <div
      class="uk-grid uk-margin-top"
      data-uk-grid-margin
    >
      <div class="uk-width-expand">
        <div
          v-if="total === current"
          class="uk-width-auto uk-align-right uk-margin-remove"
        >
          <exportCSV
            style="margin-top:20px; margin-bottom:0px;"
            :data="list"
            :search="$route.params.phone"
          />
        </div>
        <span
          v-if="!providers"
        >
          <UsedProviders
            v-if="allProviders"
            class="uk-align-right uk-width-1-4 uk-margin-small-right"
            :search="$route.params.phone"
            type="phone"
          />
          <ul class="uk-tab">
            <li class="uk-active">
              <a href="#">
                {{ $t('search.founds') }}
                <span
                  v-if="list"
                  class="uk-badge"
                >
                  {{ list.length }}
                </span>
              </a>
            </li>
            <li class="uk-disabled">
              <a>
                {{ $t('search.mentions') }}
              </a>
            </li>
          </ul>
          <ul class="uk-subnav uk-subnav-pill">
            <li :class="{ 'uk-active': !filter }">
              <a
                href="#"
                @click="filter=''"
              >
                {{ $t('search.types.all') }}
              </a>
            </li>
            <li
              v-for="item in types"
              :key="item"
              :class="{ 'uk-active': filter === item }"
            >
              <a
                href="#"
                @click="filter = item"
              >
                {{ $t('search.types.' + item) }}
                <span
                  :class="{ 'badge-inverse': filter === item }"
                  class="uk-badge uk-margin-small-left"
                >
                  {{ list.filter(card => card.genericType === item).length }}
                </span>
              </a>
            </li>
          </ul>
        </span>
        <Loader
          v-if="!isLoaded['providers']"
        />
        <div
          v-else
        >
          <progress
            v-if="total > current"
            id="js-progressbar"
            class="uk-progress"
            :value="current"
            :max="total"
          />
          <div v-if="total <= current && filteredList.length === 0">
            <NotFound v-if="current > 0 && allProviders.length"/>
          </div>
          <div class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid">
            <div
              v-for="(item, id) in filteredList"
              :key="id"
              class=" uk-grid-margin"
            >
              <usercard
                :key="item.getId()"
                :generalUser="item"
                :selectMode="selectMode"
                @selected="toggleUserCard"
                @opendetails="activeItem = id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <HLR
      v-if="hlrShown"
      :phone="intPhone"
    />
    <IMSI
      v-if="imsiShown"
      :phone="intPhone"
    />
    <Location
      v-if="locationShown"
      :phone="intPhone"
    />
    <rightSidebar>
      <ProviderDetails
        v-if="showProviderDetails"
        :data="providerSidebarData"
        :raw="rawParsed"
      />
    </rightSidebar>
    <ExportDialog
      :id="phone"
      :search="filter"
      namespace="fetcher"
      type="users"
      subtype="phone"
      :messages="filteredList"
      :totalCount="filteredList.length"
    />
  </div>
</template>

<script>
import UIkit from 'uikit'
import { mapActions, mapGetters } from 'vuex'
import { default as parseMax } from 'libphonenumber-js/min'
import restrictions from '../../config/restrictions'
import { parseRaw } from '@/utils/tryParse'
import HLR from '@/components/HLR'
import IMSI from '@/components/IMSIResolve'
import rightSidebar from '@/components/RightSidebar'
import Location from '@/components/FindLocation'
import Loader from '@/components/TGCP/Loader'
import AddUserCardToProfile from '@/mixins/addUserCardsToProfile'
import { defineAsyncComponent } from 'vue'
import { truncString } from '@/utils/ton-filters'

export default {
  name: 'Phones',
  components: {
    usercard: defineAsyncComponent(() => import('@/components/UserCard2')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    VueServerSelect: defineAsyncComponent(() => import('@/components/VueServerSelect')),
    IMSI,
    Location,
    Loader,
    UsedProviders: defineAsyncComponent(() => import('@/components/GlobalSearch/UsedProviders')),
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    rightSidebar,
    exportCSV: defineAsyncComponent(() => import('@/components/GlobalSearch/Export.vue')),
    HLR
  },
  mixins: [
    AddUserCardToProfile
  ],
  props: {
    phone: {
      required: true,
      type: String
    },
    providers: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      activeItem: null,
      details: {},
      hlrShown: false,
      imsiShown: false,
      locationShown: false,
      filter: ''
    }
  },
  watch: {
    phone (from, to) {
      this.update()
    },
    excludedSources : {
      handler (to, from) {
        if (from === null) this.update()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['excludedSources']),
    ...mapGetters('cases', [
      'currentCase'
    ]),
    ...mapGetters('crypto', [
      'tonPhones'
    ]),
    ...mapGetters('fetcher', [
      'profilesCount',
      'allProviders',
      'isLoaded'
    ]),
    rawParsed () {
      const data = this.providerSidebarData
      if (!data) return false
      return parseRaw(data)
    },
    showProviderDetails () {
      return this.rawParsed && Object.keys(this.rawParsed).length
    },
    providerSidebarData () {
      return this.activeItem !== null && this.filteredList[this.activeItem]
    },
    isTonVirtual () {
      if (this.phone.startsWith('+888')) return true
      return false
    },
    intPhone () {
      const phoneUtil = parseMax(this.phone)
      return phoneUtil.format('INTERNATIONAL')
    },
    phoneArray () {
      if (!RegExp(/\+?[0-9]{1,15}$/gi).test(this.phone)) return
      if (this.isTonVirtual) {
        let obj = {}
        obj['type'] = 'Virtual TON Number'
        obj['country'] = '-'
        obj['national'] = this.phone
        obj['international'] = this.phone
        return obj
      }
      const phoneUtil = parseMax(this.phone, 'RU')
      if (!phoneUtil) return
      let obj = {}
      obj['type'] = phoneUtil.getType()
      obj['country'] = phoneUtil.country
      obj['national'] = phoneUtil.format('NATIONAL')
      obj['international'] = phoneUtil.format('INTERNATIONAL')
      return obj
    },
    tonData () {
      return this.tonPhones[this.phone.replace('+','')] || []
    },
    tonIsLoaded () {
      return this.$store.state.crypto.isLoaded['tonphone_'+this.phone.replace('+','')]
    },
    tonOwner () {
      if (!this.tonData) {
        return ''
      }
      return this.truncString(this.tonData.address, 5)
    },
    tonAddress () {
      if (!this.tonData) {
        return ''
      }
      return this.tonData.address
    },
    filteredList () {
      if (!this.list) return []
      if (!this.filter) return this.list
      return this.list.filter(card => card.genericType === this.filter)
    },
    total () {
      return this.$store.state.fetcher.total
    },
    current () {
      return this.$store.state.fetcher.count
    },
    types () {
      if (!this.list) return []
      return [ ...new Set(this.list.map(el => el.genericType)) ].sort()
    },
    list () {
      let list = this.$store.state.fetcher.phone[this.phone] || []
      let set = []
      list = list.filter(card => {
        if (!card.ext_id) return true
        let i = set.findIndex(el => el.ext_id === card.ext_id)
        if (i === -1) {
          set.push({ ...card })
          return true
        } else {
          Object.assign({ ...set[i] }, { ...card })
        }
        return false
      })
      return list
    },
    profileName () {
      return this.phone
    }
  },
  methods: {
    ...mapActions('cases', [
      'profileAdd'
    ]),
    ...mapActions('crypto', [
      'fetchTonAddressByPhone'
    ]),
    truncString,
    showHLR () {
      this.hlrShown = true
      this.$nextTick(() => {
        UIkit.modal('#hlr').show()
      })
    },
    allowLeaks () {
      let userId = this.$store.state.current_user_id
      if (restrictions[userId]) {
        if (restrictions[userId].module.indexOf('leak') > -1) {
          return false
        }
      }
      return true
    },
    allowSS7 () {
      let userId = this.$store.state.current_user_id
      if (restrictions[userId]) {
        if (restrictions[userId].module.indexOf('ss7') > -1) {
          return false
        }
      }
      return false
    },
    showIMSI () {
      this.imsiShown = true
      this.$nextTick(() => {
        UIkit.modal('#imsi').show()
      })
    },
    showLocation () {
      this.locationShown = true
      this.$nextTick(() => {
        UIkit.modal('#find_location').show()
      })
    },
    updateDetails (raw) {
      this.details = raw
    },
    update () {
      this.filter = ''
      const action = 'fetcher/fetch'
      const providers = this.providers && this.providers.split(',')
      this.$store.dispatch(action, {
        search: this.phone,
        type: 'phone',
        providers: providers
      })
      if (this.isTonVirtual) {
        this.fetchTonAddressByPhone(this.phone)
      }
      this.hlrShown = false
      this.imsiShown = false
    },
    checkPhoneLeaks () {
      UIkit.offcanvas('#phoneleak').show()
    }
  },
  mounted () {
    this.update()
  }
}
</script>
