<template>
  <ChannelListMini
    :id="id"
    participantType="participant"
    participantStatus="former"
  />
</template>

<script>
import ChannelListMini from '@/components/TGCP/ChannelList/ChannelListMini'

export default {
  name: 'TgcpUserDetailsPastChannels',
  components: {
    ChannelListMini
  },
  props: {
    id: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
