<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h4>
          {{ $t('crypto.telegram-usernames') }}
        </h4>
      </div>
      <div class="uk-card-body uk-padding-remove">
        <div class="uk-flex uk-flex-column">
          <span
            v-if="!userNFTItemsReady"
            class="uk-margin-auto"
            uk-spinner="ratio: 3"
          />
          <table
            v-else
            class="uk-table uk-table-striped"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('crypto.username') }}
                </th>
                <th>
                  {{ $t('crypto.address') }}
                </th>
              </tr>
            </thead>
            <tr
              v-for="userNFTItem in userNFTItems"
              :key="userNFTItemAddress(userNFTItem)"
            >
              <td class="uk-text-left">
                <router-link :to="userNFTItemNameRoute(userNFTItem)">{{
                  userNFTItemName(userNFTItem)
                }}</router-link>
              </td>
              <td class="uk-text-break uk-text-left">
                {{ userNFTItemAddress(userNFTItem) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TelegramUsernames',
  props: {
    owner: {
      type: String
    }
  },
  watch: {
    owner: {
      immediate: true,
      handler (owner) {
        this.pullUserNFTItemsByCollection(owner)
      }
    }
  },
  computed: {
    userNFTItemsReady () {
      return this.$store.getters['crypto/userNFTItemsByCollectionReady']
    },
    userNFTItems () {
      return this.$store.getters['crypto/userNFTItemsByCollection']
    }
  },
  methods: {
    userNFTItemName (userNFTItem) {
      if (userNFTItem?.metadata?.name !== undefined) {
        return '@'.concat(userNFTItem.metadata.name)
      }
      return ''
    },
    userNFTItemAddress (userNFTItem) {
      return userNFTItem?.address || ''
    },
    userNFTItemNameRoute (userNFTItem) {
      if (userNFTItem?.metadata?.name !== undefined) {
        return {
          name: 'nicknameResults',
          params: {
            nickname: userNFTItem.metadata.name
          }
        }
      }
      return null
    },
    pullUserNFTItemsByCollection (owner) {
      if (typeof owner === 'string' && owner.length > 0) {
        this.$store.dispatch('crypto/pullUserNFTItemsByCollection', {
          owner
        })
      }
    }
  }
}
</script>
