<template>
  <div>
    <div class="uk-card uk-card-default uk-card-body">
      <h3>
        {{ $t('tgcp.tasks.bots-list') }}
      </h3>
      <div>
        <table class="uk-table uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr>
              <th
                class="uk-text-center"
                style="padding-left:0.5rem;padding-right:0rem; width:1.5rem"
              >
&nbsp;
              </th>
              <th
                class="uk-text-center"
              >
                <button
                  class="uk-button uk-button-text"
                  @click="setOrder('task')"
                >
                  {{ $t('tgcp.tasks.task_th') }}
                  {{ query.order_by === 'task' ? selectedOrder : '' }}
                </button>
              </th>
              <th
                class="uk-text-center"
              >
                {{ $t('tgcp.tasks.bot-username') }}
              </th>
              <th/>
              <th
                class="uk-text-center"
                width="100"
              >
                <button
                  class="uk-button uk-button-text"
                  :uk-tooltip="$t('tgcp.tasks.last_sync_th')"
                  @click="setOrder('last_sync')"
                >
                  {{ $t('tgcp.tasks.last_sync_dt_th') }}
                  {{ query.order_by === 'last_sync' ? selectedOrder : '' }}
                </button>
              </th>
              <th
                class="uk-text-center"
                width="100"
              >
                <button
                  class="uk-button uk-button-text"
                  @click="setOrder('created')"
                >
                  {{ $t('tgcp.tasks.created_th') }}
                  {{ query.order_by === 'created' ? selectedOrder : '' }}
                </button>
              </th>
              <th
                class="uk-text-center"
              >
                {{ $t('tgcp.tasks.status_th') }}
              </th>
              <th
                class="uk-text-center"
                width="100"
              >
                {{ $t('tgcp.tasks.options_th') }}
              </th>
              <th
                class="uk-text-center"
                width="100"
              >
                {{ $t('tgcp.tasks.actions_th') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-if="tasks"
          >
            <TaskListItem
              v-for="task in tasks"
              :key="task.id"
              :task="task"
              :botmode="true"
              :stored="stored.find(el => String(el.ext_id) === String(task))"
              @update="update"
            />
          </tbody>
        </table>
      </div>
    </div>
    <div class="uk-grid">
      <div class=" uk-width-1-2@m uk-margin-top uk-padding-left@m">
        <CreateBot
          :botcount="tasks.length"
          @update="update"
        />
      </div>
      <div
        class="uk-width-1-2@m uk-margin-top uk-padding-left@m"
      >
        <div
          class="uk-card uk-align-center uk-text-center uk-card-default uk-card-body"
        >
          <h3>
            {{ $t('tgcp.tasks.usage-instructions') }}
          </h3>
          <ul
            class="uk-list uk-list-bullet uk-text-left"
          >
            <li>
              {{ $t('tgcp.tasks.create-a-bot-by-filling') }}
            </li>
            <li>
              {{ $t('tgcp.tasks.wait-for-the-bot-to-get-created') }}
            </li>
            <li>
              {{ $t('tgcp.tasks.add-the-bot-to-the-target-chats') }}
            </li>
            <li>
              {{ $t('tgcp.tasks.press-the-resync-button') }}
            </li>
            <li>
              {{ $t('tgcp.tasks.chat-information-will-be-added') }}
            </li>
          </ul>
          <h4>
            {{ $t('tgcp.tasks.usage-notes') }}
          </h4>
          <ul class="uk-list uk-list-bullet uk-text-left">
            <li>
              {{ $t('tgcp.tasks.each-bot-can-be-used') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TasksBots',
  components: {
    TaskListItem: defineAsyncComponent(() => import('@/components/TGCP/Tasks/listItem')),
    CreateBot: defineAsyncComponent(() => import('@/components/TGCP/Tasks/CreateBot'))
  },
  props: {
    filter: {
      type: String,
      default: 'invite',
      validator: val => ['username', 'joinchat', 'invite', 'geo'].includes(val)
    }
  },
  data () {
    return {
      tasks: [],
      stored: [],
      timer: false,
      query: {
        limit: 21,
        offset: 0,
        task_type: this.filter,
        order_by: this.$route.query.order_by || 'created',
        order_type: this.$route.query.order_type || 'desc'
      }
    }
  },
  computed: {
    selectedOrder () {
      return this.query.order_type === 'asc' ? '↑' : '↓'
    }
  },
  methods: {
    ...mapActions('tgcp', ['fetchTasksByType']),
    ...mapActions('cases', ['fetchAllStoredObjects']),
    async refresh () {
      try {
        this.tasks = await this.fetchTasksByType(this.query)
        clearTimeout(this.timer)
        this.timer = setTimeout(this.refresh, 60000)
      } catch {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
      }
    },
    setOrder (by) {
      const { query } = this
      query.offset = 0
      if (query.order_by === by) {
        query.order_type = query.order_type === 'desc' ? 'asc' : 'desc'
      } else {
        query.order_type = 'desc'
      }
      query.order_by = by
      this.refresh()
    },
    update () {
      this.fetchTasksByType(this.query)
        .then(tasks => (this.tasks = tasks))
        .catch(err => console.debug(err))

      this.fetchAllStoredObjects({ filter: ['task'] }).then(stored => (this.stored = stored))

      this.timer = setTimeout(this.refresh, 60000)
    }
  },
  mounted () {
    return this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>
