import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import config from '@/config'

const { servers: { webapi } } = config

export default {
  namespaced: true,
  state: {
    isLoaded: {},
    webSources: {},
    sourcesProblem: false,
    sourcesLoaded: false,
    sourceStatuses: [],
    sourceBuffer: [],
    sourceLock: false
  },
  getters: {
    isLoaded: state => state.isLoaded,
    sourceStatuses: state => state.sourceStatuses
  },
  mutations: {
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    updateSources (state, payload) {
      state.webSources = {}
      payload.forEach(el => {
        state.webSources[el.id] = el
      })
    },
    sourcesProblem: (state) => {
      state.webSources = {}
      state.sourcesProblem = true
    },
    sourcesLoaded: (state) => {
      state.sourcesLoaded = true
    },
    updateSource (state, payload) {
      if (!state.webSources[payload.id]) return
      state.webSources[payload.id] = payload
    },
    deleteSource (state, payload) {
      if (!state.webSources[payload.id]) return
      delete state.webSources[payload.id]
    },
    setSourcesStatuses (state, payload) {
      state.sourceStatuses = payload
    },
    setSourceLock: (state, payload) => {
      state.sourceLock = payload
    }
  },
  actions: {
    async fetchSources ({ commit, state, dispatch, rootState }, query) {
      if (!state.sourcesLoaded) { commit('startLoad', 'websources') }
      try {
        const { data: { data, meta } } = await apiRequest(`/sources?${getQueryString(query)}`, 'GET', {}, webapi)
        commit('updateSources', data)
        return { meta, ids: data.map(el => el.id) }
      } catch {
        commit('sourcesProblem')
      } finally {
        commit('sourcesLoaded')
        commit('stopLoad', 'websources')
      }
    },
    async getStats ({ commit, state, dispatch }) {
      commit('startLoad')
      try {
        const { data } = await apiRequest(`stats/`, 'GET', {}, webapi)
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad')
      }
    },
    async getFilters ({ commit, state, dispatch }) {
      commit('startLoad')
      try {
        const { data } = await apiRequest(`filters/`, 'GET', {}, webapi)
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad')
      }
    },
    async getNewsById ({ commit, state, dispatch }, id) {
      commit('startLoad')
      try {
        const { data } = await apiRequest(`news/${id}`, 'GET', {}, webapi)
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad')
      }
    },
    async getSourceById ({ commit }, id) {
      try {
        const { data } = await apiRequest(`sources/${id}`, 'GET', {}, webapi)
        commit('updateSources', [ data ])
        return data
      } catch (err) {
        throw err
      }
    },
    async searchDTO ({ state, commit }, params) {
      commit('startLoad', 'articles')
      try {
        if (!state.sourceLock) {
          commit('setSourceLock', true)
          const { data } = await apiRequest(`search`, 'POST', {}, webapi, params)
            .finally(() => {
              commit('setSourceLock', false)
            })
          return data
        }
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad', 'articles')
      }
    },
    async searchFilters ({ commit, state, dispatch }, query) {
      commit('startLoad')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      try {
        const { data } = await apiRequest(`filters/sites?${getQueryString(query)}`, 'GET', {}, webapi)
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad')
      }
    },
    async addAdvacedSource ({ commit }, source) {
      try {
        await apiRequest(`/sources`, 'POST', {}, config.servers.webapi, source)
      } catch (err) {
        throw err
      }
    },
    async editSource ({ commit }, { id, source }) {
      try {
        const { data } = await apiRequest(`/sources/${id}`, 'PUT', {}, config.servers.webapi, source)
        commit('updateSource', data)
      } catch (err) {
        throw err
      }
    },
    async deleteSource ({ commit }, { id }) {
      try {
        const { data } = await apiRequest(`/sources/${id}/empty`, 'DELETE', {}, config.servers.webapi)
        commit('deleteSource', { id })
      } catch (err) {
        throw err
      }
    },
    async autoDetectConfig ({ commit }, url) {
      try {
        const { data } = await apiRequest(`/web/autoDetect?url=${url}`, 'GET', {}, config.servers.asiris_api)
        return data
      } catch (err) {
        throw err
      }
    },
    async getFeedPreview ({ commit }, source) {
      try {
        const { data } = await apiRequest(`/web/feedPreview`, 'POST', {}, config.servers.asiris_api, source)
        return data
      } catch (err) {
        throw err
      }
    },
    async getTestPreview ({ commit }, { url, source }) {
      try {
        const { data } = await apiRequest(`/web/testPreview?${getQueryString({ url }, true)}`, 'POST', {}, config.servers.asiris_api, source)
        return data
      } catch (err) {
        throw err
      }
    },
    async fetchSourcesStatuses ({ commit }) {
      commit('startLoad', 'sourcesStatus')
      const { data: { data } } = await apiRequest(`/sources/status`, 'GET', {}, webapi)
      commit('setSourcesStatuses', data)
      commit('stopLoad', 'sourcesStatus')
    }
  }
}
