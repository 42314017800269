<template>
  <UserReplies :id="id" />
</template>

<script>
import UserReplies from '@/components/TGCP/UserDetails/UserReplies'

export default {
  name: 'TgcpUserDetailsReplies',
  components: {
    UserReplies
  },
  props: {
    id: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
