<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('hoop.user_details.top-channels') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <Loader
          v-if="!isLoaded"
        />
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
          @click="clickHandler"
          @xAxisLabelClick="handleLabelClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'HoopUserTopChannelsGraph',
  components: {
    Loader,
    VueApexCharts
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          selection: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'numeric',
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          },
          labels: {
            formatter: (val) => val > 0 && val < 1 ? '' : val
          },
          forceNiceScale: true,
          min: 0
        },
        dataLabels: {
          enabled: false
        },
        noData: {
          text: this.$t('osint.monitor.no-data'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        tooltip: {
          shared: false,
          marker: {
            show: false
          }
        }
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapGetters('hoop', [
      'allUsers'
    ]),
    chatsActivity () {
      const chatsActivity = this.allUsers[this.id].chats_activity.channels_activity
      let series = [{
        name: this.$t('twitter.messages.messages-count'),
        data: []
      }]
      for (var i in chatsActivity.slice(0, 10)) {
        if (chatsActivity[i].messages_count) {
          series[0].data.push({
            x: chatsActivity[i].title,
            y: chatsActivity[i].messages_count,
            channel_id: chatsActivity[i].id
          })
        }
      }
      return series
    },
    isLoaded () {
      return this.$store.state.hoop.isLoaded['user_' + this.id + '_ca']
    },
    userChatsActivity () {
      return this.allUsers[this.id].chats_activity.chats_activity
    }
  },
  methods: {
    ...mapActions('hoop', [
      'fetchAllChannels'
    ]),
    update () {
      const action = 'hoop/fetchUserChatsActivityById'
      this.$store.dispatch(action, this.id)
    },
    clickHandler (event, item, { dataPointIndex }) {
      if (dataPointIndex === -1) return
      const channelId = this.chatsActivity[0]['data'][dataPointIndex].channel_id
      if (!channelId) return
      const routeData = this.$router.resolve({
        name: 'hoop_channel_details',
        params: {
          id: String(channelId)
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleLabelClick (event, item, { labelIndex }) {
      if (labelIndex === -1) {
        return
      }

      const id = this.chatsActivity[0]['data'][labelIndex].channel_id

      if (!id) {
        return
      }

      const routeData = this.$router.resolve({
        name: 'hoop_channel_details',
        params: {
          id
        }
      })

      window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    this.update()
  }
}
</script>
