<template>
  <div>
    <div >
      <div class="uk-modal-header">
        <h2
          class="uk-modal-title"
          style="font-size: 1.5rem;"
        >
          {{ caseInfo ? $t('cases.edit_case') : $t('cases.add_case') }}
        </h2>
      </div>
      <div class="uk-modal-body uk-padding-remove-top">
        <div class="uk-flex uk-flex-center" >
          <input
            v-model.trim="caseTitle"
            class="uk-input uk-margin-top"
            :class="{ 'uk-form-danger': error.title }"
            autofocus
            :placeholder="$t('cases.case_title')"
          />
        </div>
        <div class="uk-margin">
          <textarea
            v-model="caseDescription"
            class="uk-textarea"
            rows="5"
            :class="{ 'uk-form-danger': error.description }"
            :placeholder="$t('cases.case_description')"
          />
        </div>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button
          class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
          type="button"
          @click="cancel"
        >
          {{ $t('cases.cancel') }}
        </button>
        <button
          id="submit"
          class="uk-button uk-button-primary"
          :class="[loading && 'uk-hidden' ]"
          type="button"
          @click="send"
        >
          {{ $t('cases.add') }}
        </button>
        <div
          uk-spinner
          class="uk-margin-left"
          :class="[!loading && 'uk-hidden' ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UIkit from 'uikit'

export default {
  name: 'CaseModal',
  props: {
    caseInfo: {
      type: Object
    },
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      caseTitle: '',
      caseDescription: '',
      error: {
        title: false,
        description: false,
        send: false
      }
    }
  },
  methods: {
    ...mapActions('cases', [
      'caseAdd',
      'editCase'
    ]),
    cancel () {
      this.caseTitle = this.caseInfo ? this.caseInfo.title : ''
      this.caseDescription = this.caseInfo ? this.caseInfo.description : ''
      UIkit.modal('#' + this.id).hide()
    },
    send () {
      this.loading = true
      if (!this.caseTitle) {
        this.error.title = true
        this.loading = false
        return
      }
      const caseInfo = {
        case_title: this.caseTitle,
        case_description: this.caseDescription
      }
      this.caseInfo
        ? this.editCase({ id: this.caseInfo.case_id, caseData: caseInfo })
          .then(() => {
            UIkit.modal('#' + this.id).hide()
            this.$emit('edited')
            this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('cases.sucessfully_added'), type: 'success' })
          })
          .catch(() => {
            this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
        : this.caseAdd(caseInfo)
          .then(({ data }) => {
            UIkit.modal('#' + this.id).hide()
            this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('cases.sucessfully_added'), type: 'success' })
            this.caseTitle = ''
            this.caseDescription = ''
            this.$emit('added', data.case_id)
          })
          .catch(() => {
            this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
  mounted () {
    UIkit.util.on('#' + this.id, 'show', () => {
      this.caseTitle = this.caseInfo ? this.caseInfo.case_title : ''
      this.caseDescription = this.caseInfo ? this.caseInfo.case_description : ''
    })
  }
}
</script>
<style scoped>
  .uk-height-medium-small {
    height: 250px
  }
</style>
