<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div v-if="!isLoaded['boards']">
        <Loader />
      </div>
      <div v-else>
        <NotFound
          v-if="isLoaded['boards'] && boards.length === 0"
          class="uk-margin-top"
        />
        <template v-else>
          <Item
            v-for="board in boards"
            :key="board.id"
            :board="board"
            class="uk-margin-top"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/Boards/BoardListItem'
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'BoardsList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Item,
    Loader
  },
  props: {
    country: {
      type: String
    }
  },
  data () {
    return {
      boards: [],
      query: {
        limit: 70,
        offset: 0,
        geos: this.country
      },
      pagination: {
        page: 1,
        total: null,
        nextPage: false
      }
    }
  },
  watch: {
    country (to, from) {
      this.query.geos = to
      this.update()
    }
  },
  computed: {
    ...mapGetters('ads', ['isLoaded'])
  },
  methods: {
    ...mapActions('ads', ['fetchAllBoards', 'getCategories']),
    update () {
      this.fetchAllBoards(this.query)
        .then(boards => (this.boards = boards))
        .catch(console.error)
      this.getCategories()
        .then(categories => (this.categories = categories))
        .catch(console.error)
    }
  },
  mounted () {
    return this.update()
  }
}
</script>
