<template>
  <div
    class="uk-card uk-card-default  uk-margin-remove-top uk-margin-bottom"
    style="padding-bottom: 12px;"
  >
    <div 
      class="uk-flex uk-flex-middle uk-width-1-1 uk-card-header uk-margin-remove" 
      style="justify-content: space-between;"
    >
      <h2 
        class="uk-margin-remove"
        style="padding-top: 10px;"
      >
        {{ $t('crypto.route.blocks') }}
      </h2>
      <div class="uk-child-width-auto uk-text-right">
        <label>
          <input 
            v-model="excludeEmpty" 
            class="uk-checkbox uk-margin-small-right" 
            type="checkbox"
          />
          {{ $t('crypto.options.exclude_blocks') }}
        </label>
      </div>
    </div>
    <BlocksTable 
      :keys="keys" 
      :update="true" 
      :default-length="10" 
      :item-selector="true" 
      :hidden="false" 
      :line-link="true"
      :exclude-empty="excludeEmpty"
    />
  </div>
</template>

<script>
import BlocksTable from '@/components/Crypto/Ton/Blocks/Table'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TonBlockList',
  components: {
    BlocksTable
  },
  data () {
    return {
      excludeEmpty: false
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonExploredBlocks', 'getTonBlockKeys']),
    keys () { 
      const { excludeEmpty } = this
      return this.getTonBlockKeys(this.getTonExploredBlocks, excludeEmpty)
    }
  },
  methods: {
    ...mapMutations('crypto', ['cleanTonBlocks'])
  },
  beforeUnmount () {
    this.cleanTonBlocks()
  }
}
</script>