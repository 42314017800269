<template>
  <div class="">
    <div class="uk-container">
      <div class="uk-margin">
        <h3 class="uk-heading-divider">
          {{ $t('ads.stats.title') }} {{ $t('countries.' + country) }}
        </h3>
        <form @submit.prevent="search">
          <input
            v-model="searchString"
            class="uk-input uk-form-width-medium uk-form-width-large"
            type="text"
            :placeholder="$t('tgcp.stats.message_search_placeholder')"
          />
          <button
            class="uk-button uk-button-primary uk-search-icon"
            @click.prevent="search"
          >
            <span uk-search-icon/>
          </button>
        </form>
        <SearchInDbHint />
      </div>
      <div
        class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
        uk-grid
      >
        <div >
          <div
            to=""
            class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
          >
            <Loader v-if="!isLoaded"/>
            <h3 v-else-if="statsProblem">
              &mdash;
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(countryData.users) }}
            </h3>
            <h3 class="uk-text-muted uk-margin-remove-top">
              {{ $t('ads.stats.sellers_count') }}
            </h3>
          </div>
        </div>
        <div >
          <router-link
            :to="`/ads/${country}/boards`"
            class="uk-card uk-card-small  uk-card-primary uk-card-hover uk-card-body uk-dark"
            style="text-decoration:none;"
          >
            <Loader v-if="!isLoaded"/>
            <h3 v-else-if="statsProblem">
              &mdash;
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(countryData.boards) }}
            </h3>
            <h3 class="uk-text-muted uk-margin-remove-top">
              {{ $t('ads.stats.boards_count') }}
            </h3>
          </router-link>
        </div>
        <div >
          <router-link
            :to="`/ads/${country}/list`"
            class="uk-card uk-card-small  uk-card-primary uk-card-hover uk-card-body uk-dark"
            style="text-decoration:none;"
          >
            <Loader v-if="!isLoaded"/>
            <h3 v-else-if="statsProblem">
              &mdash;
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(countryData.ads_count) }}
            </h3>
            <h3 class="uk-text-muted uk-margin-remove-top">
              {{ $t('ads.stats.ads_count') }}
            </h3>
          </router-link>
        </div>
        <div >
          <div
            to="/tgcp/tasks"
            class="uk-card uk-card-small uk-card-secondary  uk-card-hover uk-card-body uk-dark"
          >
            <Loader v-if="!isLoaded"/>
            <h3 v-else-if="statsProblem">
              &mdash;
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ numberFormat(countryData.msidn) }}
            </h3>
            <h3 class="uk-text-muted uk-margin-remove-top">
              {{ $t('ads.stats.msisdns_count') }}
            </h3>
          </div>
        </div>
        <div >
          <div class="uk-card uk-card-small  uk-card-default uk-card-hover uk-card-body uk-dark">
            <Loader v-if="!isLoaded"/>
            <h3 v-else-if="statsProblem">
              &mdash;
            </h3>
            <h3
              v-else
              class="uk-card-title"
            >
              {{ dayjs(countryData.sync) }}
            </h3>
            <h3 class="uk-text-muted uk-margin-remove-top">
              {{ $t('ads.stats.sync_date') }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { numberFormat } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'AdsCountryStats',
  components: {
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Loader
  },
  props: {
    country: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      searchString: ''
    }
  },
  watch: {
    country (from, to) {
      this.update()
    }
  },
  computed: {
    statsProblem () {
      return this.$store.state.ads.statsProblem
    },
    countryData () {
      return this.$store.state.ads.countryStats[this.country]
    },
    isLoaded () {
      return this.$store.state.ads.isLoaded['country_stats_' + this.country]
    }
  },
  methods: {
    numberFormat,
    update () {
      this.$store.dispatch('ads/getGeoStats', this.country)
    },
    search () {
      this.$router.push({
        name: 'ads_list',
        query: {
          search: this.searchString
        }
      })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
