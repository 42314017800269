<template>
  <div>
    <div
      class="uk-section uk-flex uk-flex-middle uk-animation-fade"
      style="background:url('/img/worldIndiaLow.svg');background-position: center;background-size: cover;background-position-x: 100px;"
    >
      <div class="uk-width-1-1">
        <div class="uk-container">
          <div
            class="uk-grid-margin uk-grid uk-grid-stack"
            uk-grid
          >
            <div class="uk-width-1-1@m">
              <div class="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
                <div
                  v-if="showLogoOnLoginPage"
                  style="margin-top:-26px"
                >
                  <img
                    :src="logoUrl"
                    height="64"
                    width="64"
                  />
                  <h3 class="uk-card-title uk-text-center uk-margin-small-top">
                    {{ $t('login.window_title') }}
                  </h3>
                </div>
                <h3
                  v-else
                  class="uk-card-title uk-text-center"
                >
                  {{ $t('login.window_title') }}
                </h3>
                <form @submit.prevent="loginHandler">
                  <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                      <span
                        class="uk-form-icon"
                        uk-icon="icon: mail"
                      />
                      <input
                        v-model.trim="email"
                        class="uk-input uk-form-large"
                        type="text"
                        :placeholder="$t('login.login_placeholder')"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                      <span
                        class="uk-form-icon"
                        uk-icon="icon: lock"
                      />
                      <input
                        v-model.trim="password"
                        class="uk-input uk-form-large"
                        type="password"
                        :placeholder="$t('login.password_placeholder')"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                      <span
                        class="uk-form-icon"
                        uk-icon="icon: phone"
                      />
                      <input
                        v-model.trim="otp"
                        class="uk-input uk-form-large"
                        type="text"
                        :placeholder="$t('login.2fa_placeholder')"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <button
                      type="submit"
                      class="uk-button uk-button-primary uk-button-large uk-width-1-1"
                      :disabled="!password || !email || isLoading"
                    >
                      {{ $t('login.login_btn') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import UIkit from 'uikit'
const removeEmpty = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([k, v]) => v)
      .map(([k, v]) => (typeof v === 'object' ? [k, removeEmpty(v)] : [k, v]))
  )
export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      permanent: '',
      otp: '',
      tfa: false,
      logoUrl: '',
      showLogoOnLoginPage: false,
      isLoading: false
    }
  },
  watch: {
    forceChangePassword (newVal) {
      if (!newVal) this.redirect()
    }
  },
  computed: {
    ...mapGetters([
      'forceChangePassword'
    ])
  },
  methods: {
    ...mapActions(['login']),
    async initLogoUrl () {
      const config = (await import(/* webpackChunkName:"config" */'@/config')).default
      this.logoUrl = config.logoUrl
      this.showLogo = config.showLogoOnLoginPage
    },
    redirect () {
      if (sessionStorage.getItem('redirect') === '/logout' || sessionStorage.getItem('redirect') === '/login') this.$router.push('/')
      else this.$router.push(sessionStorage.getItem('redirect') || '/')
    },
    async loginHandler () {
      const { email, password, otp, permanent } = this
      this.isLoading = true
      try {
        await this.login(removeEmpty({ email, password, permanent, otp }))
        localStorage.setItem('last_activity', Date.now())
        this.redirect()
      } catch (err) {
        let text = ''
        if (err.response) {
          switch (err.response.status) {
              case 422: text = (err.response.data.fields.json.otp && this.$t('login.error-during-otp-verify')) ||
                          (err.response.data.fields.json.email && this.$t('login.email-or-password-is-invalid'))
                        break
              case 403: text = 'User account deactivated or expired. Please contact your sales manager.'
                        break
              case 500: text = 'The service is unavailable'
                        break
              default: text = 'Unknown error'
          }
        } else {
          text = 'Could not connect to server'
        }
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text, type: 'error' })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.initLogoUrl()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const modals = document.getElementsByClassName('uk-modal')
      for (const modal of modals) {
        // Do not call the method $destroy,
        // because this will cause the remaining overlay to overlap the login and password fields
        const isOpened = modal.classList.contains('uk-open')
        const wrappedModal = UIkit.modal(modal)
        if (isOpened && wrappedModal) {
          wrappedModal.hide()
        }
      }
    })
  }
}
</script>
