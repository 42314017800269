<template>
  <div>
    <h4>
      {{ $t('search.edit_providers') }}
    </h4>
    <div
      v-if="loading"
    >
      <Loader/>
    </div>
    <div
      v-else
      uk-grid
    >
      <div
        class="uk-width-1-4 uk-text-left"
      >
        <ul
          class="uk-tab-left"
          uk-tab="connect: #edit-sources; animation: uk-animation-fade"
        >
          <li
            v-for="source in sources"
            :key="'source_' + source"
            :class="{
              'uk-active': active === source
            }"
          >
            <a
              @click="active = source"
            >
              {{ $t('search.types.' + source) }}
              ({{ allProviders.filter(el => el.category === source).filter(provider => type === 'user' 
                ? !excluded.includes(provider.name)
                : !caseExcludedSources(caseId).includes(provider.name)).length
              }}/{{ allProviders.filter(el => el.category === source).length }})
            </a>
          </li>
        </ul>
        <div
          class="uk-margin-top"
        >
          <button
            class="uk-button uk-button-default uk-align-center uk-width-1-1"
            @click="resetAll"
          >
            Reset to default
          </button>
        </div>
      </div>
      <div
        class="uk-width-expand@m"
      >
        <ul
          id="edit-sources"
          class="uk-switcher"
        >
          <li
            v-for="source in sources"
            :key="source"
          >
            <div
              class="uk-text-left"
              :style="'columns:' + (Math.floor(allProviders.filter(el => el.category === source).length / 14) || 1)"
            >
              <div
                v-for="provider in allProviders.filter(el => el.category === source)"
                :key="'provider_' + provider.name"
                class="uk-margin-small-bottom uk-width-1-1 uk-text-nowrap"
              >
                <label>
                  <input
                    class="uk-checkbox"
                    type="checkbox"
                    :checked="type === 'user'
                      ? !excluded.includes(provider.name)
                      : !caseExcludedSources(caseId).includes(provider.name)"
                    @click.prevent="changeExcludedProviders(provider.name)"
                  />
                  {{ provider.description }}
                </label>
                <span
                  v-if="provider.dangerous"
                  class="uk-text-warning"
                  uk-icon="warning"
                  :uk-tooltip="`${$t('search.dangerous.base')} ${$t('search.dangerous.' + provider.name)}`"
                />
                <span
                  class="uk-text-small uk-text-muted"
                >
                  |
                </span>
                <span
                  v-for="search in [ ...provider.search_by ].sort().reverse()"
                  :key="'icon_search' + search"
                  class="uk-text-small uk-text-muted"
                  style="margin-right: 2px;"
                  :uk-tooltip="$t('search.search-by.' + search)"
                  :uk-icon="icon(search)"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { defineAsyncComponent } from 'vue'
export default {
  name: 'SettingsSources',
  components: {
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  props: {
    type: {
      type: String,
      default: 'user',
      validator: val => [ 'user', 'case' ].includes(val)
    },
    caseId: {
      type: [ String, Number ]
    }
  },
  data () {
    return {
      active: this.sources && this.sources[0],
      loading: false
    }
  },
  computed: {
    ...mapGetters('fetcher', [
      'allProviders'
    ]),
    ...mapGetters([
      'excludedSources'
    ]),
    ...mapGetters('cases', [
      'caseExcludedSources'
    ]),
    sources () {
      return [ ...new Set(this.allProviders.map(el => el.category).sort((a, b) => {
        if (a === 'social') return -1
        return a - b || a === 'social'
      })) ]
    },
    excluded () {
      return this.excludedSources ?? []
    },
    icon: () => type => {
      switch (type) {
          case 'nickname': return 'icon: user'
          case 'email': return 'icon: mail'
          case 'phone': return 'icon: phone'
          case 'id': return 'icon: hashtag'
      }
    }
  },
  methods: {
    ...mapActions('fetcher', [
      'updateProviders'
    ]),
    ...mapActions([
      'putExcludeSource'
    ]),
    ...mapActions('cases', [
      'putProfilerSettings'
    ]),
    ...mapMutations('fetcher', [
      'clearSearch'
    ]),
    async changeExcludedProviders (provider) {
      let excluded = this.type === 'user'
        ? [ ...this.excluded ]
        : [ ...this.caseExcludedSources(this.caseId) ]
      if (!excluded.includes(provider)) {
        excluded = [ ...excluded, provider ]
      } else {
        excluded = excluded.filter(el => el !== provider)
      }
      if (this.type === 'user') await this.putExcludeSource(excluded)
      else await this.putProfilerSettings({ caseId: this.caseId, settings: { excluded_sources: excluded } })
      this.clearSearch()
    },
    async resetAll () {
      if (this.type === 'user') await this.putExcludeSource([])
      else await this.putProfilerSettings({ caseId: this.caseId, settings: { excluded_sources: [] } })
      this.clearSearch()
    }
  },
  async created () {
    this.loading = true
    await this.updateProviders()
    this.loading = false
  }
}
</script>

<style>

</style>
