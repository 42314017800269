<template>
  <div>
    <UserChannelsGraph :id="id" />
    <UserDayGraph :id="id" />
    <UserWeekGraph :id="id" />
    <UserYearGraph :id="id" />
  </div>
</template>

<script>
import UserChannelsGraph from '@/components/TGCP/UserDetails/UserChannelsGraph'
import UserDayGraph from '@/components/TGCP/UserDetails/UserDayGraph'
import UserWeekGraph from '@/components/TGCP/UserDetails/UserWeekGraph'
import UserYearGraph from '@/components/TGCP/UserDetails/UserYearGraph'

export default {
  name: 'TgcpUserDetailsOverview',
  components: {
    UserChannelsGraph,
    UserDayGraph,
    UserWeekGraph,
    UserYearGraph
  },
  props: {
    id: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
