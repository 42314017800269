<template>
  <tr v-if="task">
    <td
      class="uk-width-1-5 uk-text-break uk-text-center"
      style="padding-left:0.5rem; padding-right:0rem; width:1.5rem"
    >
      <input
        v-if="!botmode"
        class="uk-checkbox"
        type="checkbox"
        :checked="checked"
        @click.stop
        @change.stop.prevent="$emit('check-changed', $event.target.checked)"
      />
    </td>
    <td
      class="uk-text-nowrap task-column"
    >
      {{ taskNameFilter(task.joinlink) }}
      <span>
        <a
          :href="`https://chat.whatsapp.com/${task.joinlink}`"
        >
          <font-awesome-icon
            :icon="['fab', 'whatsapp']"
          />
        </a>
      </span>
    </td>
    <td class="uk-text-left uk-table-expand">
      <span v-if="task.type === 'invite'">
        <span v-if="task.bot_username">
          <a
            uk-icon="icon: copy"
            @click="copyToClipboard(`@${task.bot_username}`)"
          />
          @{{ task.bot_username }}
        </span>
        <span v-else>
          {{ $t('whatsapp.tasks.waiting-for-bot-creation') }}
        </span>
        <div
          v-if="task.chats && task.chats.length"
          class="uk-text-left uk-margin-top"
        >
          <i>
            {{ $t('whatsapp.tasks.groups-bot-in-now') }}
          </i>
        </div>
        <div v-else-if="task.chats">
          {{ $t('whatsapp.tasks.not-in-chats-yet') }}
        </div>
      </span>
      <ul
        v-if="task.group"
        class="uk-list uk-text-left uk-text-small uk-margin-remove-top"
      >
        <li>
          <router-link
            :to="{
              name: 'whatsapp_group_details',
              params: {
                id: task.group.id
              }
            }"
          >
            {{ subject }}
          </router-link>
        </li>
      </ul>
    </td>
    <td>
      <span v-if="task.group">
        {{ task.group.members_count }}
      </span>
      <span v-else>
        -
      </span>
    </td>
    <td>
      <span/>
      {{ task.description }}
    </td>
    <td>
      <span v-if="task.finished">
        {{ dayjs(task.finished) }}
      </span>
      <span v-else>
        {{ $t('whatsapp.tasks.status_never_synced') }}
      </span>
    </td>
    <td>
      {{ dayjs(task.created) }}
    </td>
    <td>
      {{ taskStatus }}
    </td>
    <td>
      <div class="uk-margin uk-flex uk-flex-middle uk-flex-center options__cell">
        <div class="uk-margin-small-right">
          <a
            :uk-tooltip="`title: ${$t('tgcp.tasks.priority_field_title')}`"
            uk-icon="icon: warning; ratio: 1"
            value="null"
            :class="task.priority > 8 ? 'uk-text-primary' : 'uk-text-muted'"
            @click="
              update({
                id: task.id,
                priority: task.priority > 8 ? 1 : 9
              })
            "
          />
        </div>
      </div>
    </td>
    <td>
      <div class="uk-margin uk-flex uk-flex-middle uk-flex-center options__cell">
        <AddStoredButton
          provider="whatsapp"
          type="task"
          :data="task"
        />
        <button
          v-if="task.last_sync"
          class="uk-button uk-button-text uk-margin-left-small"
          type="button"
          style="height:40px;width:40px;"
          :disabled="task.priority > 8 && task.is_active"
          :uk-tooltip="$t('whatsapp.tasks.resync')"
          @click="
            update({
              id: task.id,
              priority: 9
            })
          "
        >
          <span uk-icon="refresh"/>
        </button>
        <button
          class="uk-button uk-button-text uk-margin-left-small"
          type="button"
          style="height:40px;width:40px;"
          :uk-tooltip="$t('whatsapp.tasks.delete')"
          @click="deleteTaskMethod(task)"
        >
          <span uk-icon="trash"/>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import UIkit from 'uikit'
window.UIkit = UIkit

export default {
  name: 'WhatsAppTaskListItem',
  components: {
    AddStoredButton
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    botmode: {
      type: Boolean,
      required: false,
      default: false
    },
    stored: {
      type: Object,
      required: false
    },
    checked: null,
    checkedTasks: Array
  },
  data () {
    return {
      spinners: [],
      storedItem: this.stored
    }
  },
  computed: {
    isRowClickable () {
      return this.checkedTasks && this.checkedTasks.length
    },
    taskStatus () {
      if (this.task.status === 'group_full') {
        return this.$t('whatsapp.tasks.group-is-full')
      }
      if (this.task.status === 'bad_link') {
        return this.$t('whatsapp.tasks.invalid-link')
      }
      if (this.task.status === 'revoked') {
        return this.$t('whatsapp.tasks.revoked')
      }
      if (this.task.status === 'created') {
        return this.$t('whatsapp.tasks.created')
      }
      if (this.task.status === 'started') {
        return this.$t('whatsapp.tasks.started')
      }
      if (this.task.status === 'success') {
        return this.$t('whatsapp.tasks.success')
      }
      return this.$t('whatsapp.tasks.waiting-for-parse')
      // return this.task.status.charAt(0).toUpperCase() + this.task.status.slice(1)
    },
    marker () {
      return ''
      // if (!this.task) return null
      // if (!this.task.type === 'geo') return null
      // return this.task.task.split(';')
    },
    subject () {
      if (
        !this?.task?.group?.subject &&
        this?.task?.group?.id
      ) {
        return '#'.concat(this.task.group.id)
      }
      return this.task.group.subject
    }
  },
  methods: {
    ...mapActions('whatsapp', ['editTask', 'deleteTask', 'deleteGeoTask', 'fetchAllTasks']),
    taskNameFilter (joinlink) {
      if (!joinlink) return ''
      return joinlink.replace('https://chat.whatsapp.com/', '').replace('invite/', '')
    },
    taskRowClickHandler () {
      if (!this.isRowClickable) return
      this.$emit('check-changed', !this.checked)
    },
    isLoading (prop) {
      return this.spinners.includes(prop)
    },
    async changeProp ({ prop, val, id }) {
      this.spinners.push(prop)
      try {
        await this.update({ id, [prop]: val })
      } finally {
        this.spinners.splice(this.spinners.indexOf(prop), 1)
      }
    },
    async update (options) {
      try {
        await this.editTask(options)
      } finally {
        this.$forceUpdate()
      }
    },
    deleteTaskAction () {
      this.deleteTask(this.task.id).then(this.$emit.bind(this, 'update')) // updating all list
    },
    async deleteTaskMethod (task) {
      this.deleteTaskId = task
      try {
        await UIkit.modal.confirm(this.$t('whatsapp.tasks.confirm_delete_msg'))
        this.deleteTaskAction()
        task.type === 'geo' && this.deleteGeoTask(task.task)
        this.$emit('delete')
      } catch {}
    },
    async copyToClipboard (val) {
      const { default: copyToClipboard } = await import(/* webpackChunkName: "copyToClipboard" */ '@/utils/copyToClipboard')
      copyToClipboard(val)
      this.$notify({ group: 'general', text: this.$t('utils.copied-to-clipboard'), type: 'general', duration: 700 })
    }
  }
}
</script>

<style lang="scss" scoped>
.task-column {
  min-width: 70px;
}
.collect-media__icon {
  width: 22px;
}
.options__cell {
  height: 25px;
}

ul {
  ::before {
    display: none;
  }
}
</style>
