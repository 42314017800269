// @ts-check
/**
 * @typedef { import('./Queue').Process } Process
 */

import sanitize from 'sanitize-filename'
import FileData from './FileData.js'

/**
 * @class
 * @readonly
 */
class File {
  /**
   * @private
   * @readonly
   * @type {{[index: string]: string}}
   */
  static MIME_TYPES = {
    csv: 'text/csv',
    txt: 'text/plain'
  }
  /**
   * @readonly
   * @param {Process} Process
   */
  static get (Process) {
    try {
      if (Process.payload !== void 0) {
        /** @type {Blob} */
        let objectURL = new Blob()
        const payload = new FileData({
          namespace: Process.Strategy.options.namespace,
          type: Process.Strategy.options.type,
          subtype: Process.Strategy.options.subtype,
          entries: Process.payload,
          format: Process.Strategy.preset.format,
          allowedFields: Process.Strategy.options.allowedFields,
          localizeFields: Process.Strategy.options.localizeFields
        })
        let BlobType = File.MIME_TYPES[Process.Strategy.preset.format]
        let format = Process.Strategy.preset.format
        const isTxt = FileData.isTxt({
          namespace: Process.Strategy.options.namespace,
          type: Process.Strategy.options.type
        })
        if (isTxt) {
          BlobType = File.MIME_TYPES.txt
          format = 'txt'
        }
        objectURL = new Blob([payload.data], {
          type: BlobType
        })
        let anchor = document.createElement('a')
        const currentURL = URL.createObjectURL(objectURL)
        anchor.href = currentURL
        anchor.download = File.normalizeName(Process.description) + '.' + format
        anchor.click()
      }
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @private
   * @readonly
   * @param {string} name
   */
  static normalizeName (name) {
    try {
      return sanitize(name).toLowerCase()
    } catch (error) {
      console.debug(error)
    }
  }
}

export default File
