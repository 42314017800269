import scroll from '@/utils/scrollIntoView'

let value = ''
const scrollIntoView = {
  mounted (element) {
    try {
      element.style.transition = '.5s'
    } catch (error) {
      console.debug(error)
    }
  },
  beforeMount (...theArgs) {
    try {
      if (theArgs?.[1]?.value) {
        value = theArgs[1].value
        queueMicrotask(() => {
          try {
            scroll(theArgs[1])
          } catch (error) {
            console.debug(error)
          }
        })
      }
    } catch (error) {
      console.debug(error)
    }
  },
  updated (...theArgs) {
    try {
      if (value !== theArgs?.[1]?.value) {
        queueMicrotask(() => {
          try {
            scroll(theArgs[1])
          } catch (error) {
            console.debug(error)
          }
        })
      }
    } catch (error) {
      console.debug(error)
    }
  }
}

export default {
  install (app) {
    app.directive('scroll-into-view', scrollIntoView)
  }
}
