<template>
  <div class="uk-margin-top">
    <div
      v-if="!stats"
      class="uk-card uk-card-default uk-margin-top uk-card-body"
    >
      {{ $t('not_found.not_available') }}
    </div>
    <div v-else>
      <h2 class="uk-heading-divider uk-margin-top uk-width-1-1">
        {{ $t('tgcp.stats.number-of-parsed-phones-by-country') }}
      </h2>
      <div
        class="uk-grid"
        uk-grid="masonry: true"
      >
        <div class="uk-width-1-2@m">
          <div class="uk-card uk-card-default uk-margin-top">
            <div class="uk-card-body">
              <Loader v-if="!isLoaded"/>
              <Table
                v-else
                :columns="$options.tableColumns"
                :options="tableOptions"
                :table-data="tableData"
              >
                <template v-slot:cell__country_name="row">
                  <router-link
                    :to="{
                      name: routeName,
                      query: {
                        [routeProp]: row.rowData.country_code
                      }
                    }"
                  >
                    {{ row.rowData.country_name }}
                  </router-link>
                </template>
                <template v-slot:cell__users_count="row">
                  <FormattedNumber
                    :data="row.rowData"
                    column="users_count"
                  />
                </template>
              </Table>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2@m">
          <div class="uk-card uk-card-default uk-margin-top">
            <div class="uk-card-body">
              <Loader v-if="!isLoaded"/>
              <VueApexCharts
                v-else-if="stats && stats.length"
                ref="chart"
                :series="series"
                :options="customChartOptions"
                @legendClick="legendClickHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import Table from '@/components/general/CustomTable/Table'
import PieChartDefault from '@/mixins/PieChartDefault.js'
import FormattedNumber from '@/components/general/DataTable/FormattedNumber'
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'ParsedPhonesStats',
  components: {
    Table,
    VueApexCharts,
    FormattedNumber,
    Loader
  },
  mixins: [
    PieChartDefault
  ],
  props: {
    provider: {
      type: String,
      default: 'tgcp'
    },
    routeName: {
      type: String,
      default: 'tgcp_user_list'
    },
    routeProp: {
      type: String,
      default: 'country_code'
    }
  },
  data () {
    return {
      isLoaded: false
    }
  },
  tableColumns: ['country_name', 'users_count'],
  tableOptions: {
    perPageItemsCount: 10,
    filterable: ['country_name', 'users_count'],
    filterAlgorithm: {
      users_count (value, query) {
        return value >= query
      }
    },
    sortable: ['country_name', 'users_count'],
    headings: {
      country_name: () => 'Country',
      users_count: () => 'Parsed users'
    }
  },
  computed: {
    totalMSISDN () {
      const tableData = this?.tableData
      let total = 0
      if (
        Array.isArray(tableData) &&
        tableData?.length > 0
      ) {
        for (const data of tableData) {
          const usersCount = data?.['users_count']
          if (typeof usersCount === 'number') {
            total += usersCount
          }
        }
      }
      return total || 0
    },
    tableOptions () {
      let tableOptions = {}
      if (typeof this?.$options === 'object') {
        tableOptions = this.$options.tableOptions
      }
      let totalMSISDN = 0
      if (
        typeof this?.totalMSISDN === 'number' &&
        this?.totalMSISDN > 0
      ) {
        totalMSISDN = this.totalMSISDN
      }
      return {
        ...tableOptions,
        totalMSISDN
      }
    },
    customChartOptions () {
      let chartOptions = {}
      let chart = {}
      let events = {}
      if (Object.keys(this.chartOptions).length > 0) {
        chartOptions = this.chartOptions
      }
      if (chartOptions.propertyIsEnumerable('chart')) {
        chart = this.chartOptions.chart
      }
      if (chart.propertyIsEnumerable('events')) {
        events = this.chartOptions.chart.events
      }
      return {
        ...chartOptions,
        chart: {
          ...chart,
          events: {
            ...events,
            dataPointSelection: (...args) => {
              if (args?.[0] === null) {
                return
              }
              const dataPointIndex = args
                ?.[2]
                ?.dataPointIndex
              const countryName = this
                ?.chartOptions
                ?.labels
                ?.[dataPointIndex]
              const countryCode = this
                ?.stats
                .filter(entry => {
                  return entry?.['country_name'] === countryName
                })
                ?.[0]
                ?.['country_code']
              if (
                typeof countryCode === 'string' &&
                typeof this?.$router?.push === 'function'
              ) {
                this.$router.push({
                  name: this?.routeName,
                  query: {
                    [this?.routeProp]: countryCode
                  }
                })
              }
            }
          }
        }
      }
    },
    stats () {
      if (!this.$store.state[this.provider]) {
        return null
      }

      return this.$store.state[this.provider].parsedStats
    },
    tableData () {
      return this.stats.filter(item => item.users_count > 1000)
    },
    piedata () {
      if (!this.stats && !this.stats.length) return
      const data = {
        datasets: [
          {
            data: [],
            backgroundColor: ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba', '#D60000', '#F46300', '#0358B6', '#44DE28']
          }
        ],
        labels: []
      }
      let others = 0
      for (const country of this.stats) {
        if (country.users_count > 2642990) {
          data.datasets[0].data.push(country.users_count)
          data.labels.push(country.country_name)
        } else {
          others += country.users_count
        }
      }
      data.datasets[0].data.push(others)
      data.labels.push('Other')
      return data
    }
  },
  methods: {
    async update () {
      const action = this.provider + '/fetchParsedStats'
      this.isLoaded = false
      await this.$store.dispatch(action)
      this.isLoaded = true
      if (!this.stats && !this.stats.length) return
      let others = 0
      for (const country of this.stats) {
        if (country.users_count > 2642990) {
          this.series.push(country.users_count)
          this.chartOptions.labels.push(country.country_name)
        } else {
          others += country.users_count
        }
      }
      this.series.push(others)
      this.chartOptions.labels.push('Other')
    }
  },
  mounted () {
    this.update()
  }
}
</script>
