<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <ListSettings @paramsChanged="paramsChanged" />
      <SearchInDbHint />
      <div v-if="!loaded">
        <loader/>
      </div>
      <NotFound v-if="loaded && !hasDataToView" />
      <div
        v-if="loaded"
        class="uk-grid uk-margin-top"
        data-uk-grid-margin
      >
        <div
          class="uk-grid-small
            uk-child-width-1-3@s uk-flex-left
            uk-grid filter_area uk-width-1-1"
        >
          <div
            v-for="(user, i) in users"
            :key="user.id"
            class="uk-grid-margin"
          >
            <usercard2
              :key="user.getId()"
              :generalUser="user"
              :showAddProfileButton="true"
              @opendetails="activeItem = i"
            />
          </div>
        </div>
      </div>
      <div v-if="!hidePagination && loaded">
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <rightSidebar>
      <ProviderDetails
        v-if="showProviderDetails"
        :data="providerSidebarData"
        :raw="rawParsed"
      />
    </rightSidebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import rightSidebar from '@/components/RightSidebar'
import { parseRaw } from '@/utils/tryParse'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'ZaloUserList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    ListSettings: defineAsyncComponent(() => import('@/components/Zalo/UserList/ListSettings')),
    usercard2: defineAsyncComponent(() => import('@/components/UserCard2')),
    loader: defineAsyncComponent(() => import('@/components/TGCP/Loader')),
    Paginate,
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    rightSidebar
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      validator: val => ['follows', 'followers', 'friends'].includes(val)
    }
  },
  data () {
    return {
      users: [],
      query: {
        l: 33, // limit
        o: 0, // offset
        q: this.$route.query.search
      },
      pagination: {
        page: 1,
        total: null,
        isNextPage: false
      },
      activeItem: null
    }
  },
  watch: {
    '$route.query' () {
      if (event instanceof PopStateEvent) { // нажали впере/назад
        const page = +this.$route.query.page || 1
        this.query.o = this.query.l * (page - 1)
        this.pagination.page = page
      }
    }
  },
  computed: {
    ...mapGetters('zalo', ['isLoaded']),
    rawParsed () {
      const data = this.providerSidebarData
      if (!data) return false
      return parseRaw(data)
    },
    showProviderDetails () {
      return this.rawParsed && Object.keys(this.rawParsed).length
    },
    providerSidebarData () {
      return this.activeItem !== null && this.users[this.activeItem]
    },
    hasDataToView () {
      return this.users.length > 0
    },
    hidePagination () {
      if ((this.pagination.isNextPage === false && Number(this.pagination.page) === 1) || (Number(this.pagination.page) === 1 && this.users.length < this.query.l)) return true
      else return false
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.l)
      return this.pagination.total / lastPage - this.query.l === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded['users' + this.type] : this.isLoaded['users']
    }
  },
  methods: {
    ...mapActions('zalo', ['fetchAllUsers', 'searchUsers', 'addParamToQuery']),
    paramsChanged (params, isInit) {
      if (!isInit) {
        this.query.o = 0
        this.pagination.page = 1
      }
      this.update(params)
    },
    setPage (page) {
      page = +page || 1
      this.query.o = this.query.l * (page - 1)
      this.pagination.page = page
      if (this.id) return
      const routePage = +this.$route.query.page || 1
      if (routePage !== page) this.addParamToQuery({ page })
      this.update()
    },
    setData (users) {
      this.users = users.users
      this.pagination.total = (users.meta && users.meta.total) || 0
      this.pagination.isNextPage = users.users.length === this.query.l
    },
    async update (params) {
      if (this.$route.query.search) {
        if (params) this.searchUsers(params).then(this.setData)
      } else {
        this.fetchAllUsers({ ...this.query, ...(params && params.params) }).then(this.setData)
      }
    }
  },
  mounted () {
    const queryPage = +this.$route.query.page
    this.pagination.page = queryPage || 1
    if (!queryPage && !Object.keys(this.$route.query).length) this.$router.replace({ query: { page: this.pagination.page } })
    if (!this.$route.query.search) this.update()
  }
}
</script>
