import GeneralUser from './generalUser'

var Threema = class {
  formatData (data) {
    var gu = new GeneralUser()
    if (data[0]) {
      data = data[0]
    }
    if (!data.threema_id) {
      return {}
    }
    gu.user_id = data.threema_id
    gu.fullName = data.threema_id
    if (data.mobile_no) {
      if (data.mobile_no.indexOf('+') < 0) {
        data.mobile_no = '+' + data.mobile_no
      }
      gu.phone = data.mobile_no
    }
    if (data.email) {
      gu.email = data.email
    }
    gu.raw = data
    gu.type = 'threema'
    gu.genericType = 'messenger'

    return gu
  }
  findByNickName (nickname, dispatch) {
    return dispatch('threema/fetchProfile', nickname, { root: true })
      .then(function (data) {
        return Promise.resolve(new Threema().formatData(data))
      })
      .catch(function () {
        return {}
      })
  }
  findByEmail (email, dispatch) {
    return dispatch('threema/fetchProfile', email, { root: true })
      .then(function (data) {
        return Promise.resolve(new Threema().formatData(data))
      })
      .catch(function () {
        return {}
      })
  }
  findByPhone (phone, dispatch) {
    phone = phone.replace('+', '')
    return dispatch('threema/fetchProfile', phone, { root: true })
      .then(function (data) {
        return Promise.resolve(new Threema().formatData(data))
      })
      .catch(function () {
        return {}
      })
  }
}
export default new Threema()
