<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom hoop-item">
      <div
        v-if="user.is_virtual"
        class="hoop-item__badge"
      >
        <span>
          {{ $t('hoop.user_details.alias') }}
        </span>
      </div>
      <div class="uk-card-header border-bottom-none">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('twitter.user_details.general_info_title') }}
        </h3>
      </div>
      <div class="uk-card-body uk-padding-remove-top">
        <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
          <CardToProfileButton
            :card="userCard"
            :profile-name-by-default="profileName"
            :userIdForPhoneResolving="user.id"
            userTypeForPhoneResolving="messenger"
            userProviderForPhoneResolving="hoop"
            boundary=".hoop-item"
          />
          <AddStoredButton
            provider="hoop"
            type="user"
            :data="user"
          />
        </div>

        <ul uk-tab>
          <li class="uk-active">
            <a
              id="current_tab"
              href="#current_tab"
            >
              {{ $t('tgcp.tasks.current-data') }}
            </a>
          </li>
          <li v-if="user.names.length">
            <a
              href="#history_tab"
            >
              {{ $t('tgcp.channel_details.historical-data') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <a name="current_tab" />
            <div
              class="uk-grid"
              data-uk-grid-margin
            >
              <div class="uk-width-1-4@m">
                <AvatarList :data="user" />
              </div>
              <div
                class="uk-width-expand@m  uk-text-left"
              >
                <h3
                  v-html="extractedDisplayName"
                  class="uk-margin-remove-bottom"
                />
                <h4 class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted">
                  #{{ user.id }}
                </h4>
                <br v-if="user.phone" />
                <h4
                  v-if="user.phone"
                  class="uk-margin-top uk-text-muted uk-text-small"
                >
                  <router-link :to="{ name: 'phoneResults', params: { phone: user.phone }}">
                    {{ user.phone }}
                  </router-link>
                </h4>
                <br v-if="user.virtual_phone" />
                <h4
                  v-if="user.virtual_phone"
                  class="uk-margin-top uk-text-muted uk-text-small"
                >
                  {{ $t('hoop.user_details.virtual_phone') }}: {{ user.virtual_phone }}
                </h4>
                <div
                  uk-grid
                >
                  <div
                    class="uk-width-1-3 uk-text-break"
                  >
                    <button
                      v-if="user.display_name"
                      class="uk-button-text uk-button uk-text-small uk-text-muted"
                      @click="translateDisplayName"
                    >
                      <font-awesome-icon :icon="['fa', 'language']" />
                      <span v-if="translated['display_name']">
                        {{ $t('twitter.user_details.show-original') }}
                      </span>
                      <span v-else>
                        {{ $t('twitter.user_details.translate') }}
                      </span>
                    </button>
                    <ul class="uk-list uk-text-small">
                      <li v-if="user.country">
                        {{ $t('tgcp.tasks.country') }}
                        <span :uk-tooltip="$t('tgcp.tasks.received-from-users-phone')">
                          {{ countries[user.country] }} ({{ user.country }})
                        </span>
                      </li>
                      <li>
                        {{ $t('hoop.user_details.last_online') }}:
                        {{ user.last_online ? dayjs(user.last_online) : $t('tgcp.user_details.never') }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.account-created') }}:
                        {{ user.cdate ? dayjs(user.cdate) : $t('tgcp.user_details.never') }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.account-synced') }}:
                        {{ user.last_sync_date ? dayjs(user.last_sync_date) : $t('tgcp.user_details.never') }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a name="history_tab" />
            <HistoricalData
              v-if="user.names.length"
              :historical="user.names"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import translate from '@/mixins/translate'
import AvatarList from '@/components/Hoop/AvatarList'
import GeneralUser from '@/utils/providers/generalUser'
import countries from '@/utils/countryCodesNamesFull.json'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'HoopUserGeneralInfo',
  components: {
    AvatarList,
    AddStoredButton,
    CardToProfileButton: defineAsyncComponent(() => import('@/components/CardToProfileButton')),
    HistoricalData: defineAsyncComponent(() => import('@/components/Hoop/UserDetails/HistoricalData.vue'))
  },
  mixins: [translate],
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      localUser: { ...this.user },
      countries,
      translated: {}
    }
  },
  computed: {
    extractedDisplayName () {
      if (!this.localUser) return
      return this.localUser.display_name
    },
    stats () {
      return this.$store.state.hoop.userStats[this.user.id]
    },
    userCard () {
      const card = new GeneralUser()
      try {
        card.type = 'hoop'
        card.genericType = 'messenger'
        card.user_id = this.user.id
        if (this.user.display_name) {
          card.fullName = this.user.display_name
        }
        if (this.user.phone) {
          card.phone = this.user.phone
        }
        if (this.user.avatar_url) {
          card.avatar = this.user.avatar_url
        }
      } catch (error) {
        console.debug(error)
      }
      return card
    },
    profileName () {
      return this.user.phone || (this.user.display_name ? this.user.display_name : 'hoop_user_' + this.user.id)
    }
  },
  methods: {
    async translateDisplayName () {
      const data = await this.translateProps(['display_name'], this.localUser, this.user)
      this.localUser.display_name = data.display_name || this.localUser.display_name
    }
  }
}
</script>
<style
  lang="scss"
>
  .hoop-item {
    position: relative;
    overflow: hidden;
    &__badge {
      background-color: #444;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.8);
      height: 100px;
      left: -50px;
      position: absolute;
      top: -50px;
      width: 100px;
      color: white;
      line-height: 15;
      font-size: 0.7rem;
      -webkit-transform: rotate(-45deg);
    }
  }
</style>
<style scoped>
.border-bottom-none {
  border-bottom: 0 !important;
}
</style>
