import { tokenize } from 'simple-html-tokenizer'
let xss = require('xss')

const textHighlight = (text, search) => {
  if (!search) return text
  const searchWords = search.split(' ')
  const filteredWords = searchWords.filter(word => word.length > 2)
  const tokens = tokenize(text)
  let markupText = ''
  for (const token of tokens) {
    if (token.type === 'StartTag') {
      markupText += `<${token.tagName}`
      for (const attr of token.attributes) {
        markupText += ` ${attr[0]}="${attr[1]}"`
      }
      markupText += '>'
    }
    if (token.type === 'EndTag') { markupText += `</${token.tagName}>` }
    if (token.type === 'Chars') {
      let preparedText = token.chars
      filteredWords.forEach(word => {
        search = new RegExp(`\\s?${word.replace(/[-[\]{}()*+?.,\\^$|#\s]/, '\\$&')}\\S??`, 'gi')
        preparedText = preparedText.replace(search, find => {
          return `<span class="uk-text-primary">${find}</span>`
        })
      })
      markupText += preparedText
    }
  }
  return xss(markupText)
}
export default textHighlight
