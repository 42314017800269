<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="user.answer_status === 'NOT_FOUND'" />
      <div v-else>
        <div
          class="uk-grid uk-grid-small"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation
              :user="user"
            />
          </div>
          <div class="uk-width-1-4@m">
            <div
              class="uk-card uk-card-default uk-margin-bottom"
            >
              <div class="uk-card-header">
                <h3 class="uk-card-title">
                  {{ $t('imo.user_details.stats') }}
                </h3>
              </div>
              <div class="uk-card-body uk-padding-small">
                <ul
                  class="uk-list uk-list-divider uk-text-left uk-width-expand"
                >
                  <li>
                    {{ $t('imo.user_details.messages_count') }}
                    <span class="uk-float-right">
                      {{ user.messages_count }}
                    </span>
                  </li>
                  <li>
                    {{ $t('imo.user_details.groups_count') }}
                    <span class="uk-float-right">
                      {{ user.groups_count }}
                    </span>
                  </li>
                  <li>
                    {{ $t('imo.user_details.owned_groups_count') }}
                    <span class="uk-float-right">
                      {{ user.owned_groups_count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isLoaded && user.answer_status !== 'NOT_FOUND'"
        class="uk-margin-top"
      >
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'overview_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'overview_tab'
                }
              }"
            >
              {{ $t('imo.user_details.overview') }}
            </router-link>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'current_groups_tab',
              'uk-disabled': !user?.groups_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'current_groups_tab'
                }
              }"
            >
              {{ $t('imo.user_details.current_groups_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ user.groups_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'former_groups_tab',
              'uk-disabled': !user?.former_groups_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'former_groups_tab'
                }
              }"
            >
              {{ $t('imo.user_details.former_groups_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ user.former_groups_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'owned_groups_tab',
              'uk-disabled': !user?.owned_groups_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'owned_groups_tab'
                }
              }"
            >
              {{ $t('imo.user_details.owned_groups_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ user.owned_groups_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'messages_tab',
              'uk-disabled': !user?.messages_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'messages_tab'
                }
              }"
            >
              {{ $t('imo.user_details.messages_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ user.messages_count }}
            </span>
          </li>
        </ul>
        <div class="uk-card uk-card-default uk-padding">
          <ul
            class="uk-switcher"
          >
            <li
              v-if="$route.query.details === 'overview_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'overview_tab'
              }"
            >
              <GroupsGraph 
                :id="id"
              />
              <DailyGraph 
                :id="id"
                type="user"
              />
              <WeeklyGraph 
                :id="id"
                type="user"
              />
              <AnnualGraph
                :id="id"
                type="user"
              />
            </li>
            <li
              v-if="$route.query.details === 'current_groups_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'current_groups_tab'
              }"
            >
              <GroupList 
                :id="user.discover_anon_id"
                type="groups"
              />
            </li>
            <li
              v-if="$route.query.details === 'former_groups_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'former_groups_tab'
              }"
            >
              <GroupList 
                :id="user.discover_anon_id"
                type="groups"
                status="former"
              />
            </li>
            <li
              v-if="$route.query.details === 'owned_groups_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'owned_groups_tab'
              }"
            >
              <GroupList 
                :id="user.discover_anon_id"
                type="groups"
                status="owned"
              />
            </li>
            <li
              v-if="$route.query.details === 'messages_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'messages_tab'
              }"
            >
              <MessageList 
                :id="user.discover_anon_id"
                type="user"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ImoUserDetails',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/Imo/UserDetails/GeneralInformation')),
    GroupList: defineAsyncComponent(() => import('@/views/Imo/GroupList')),
    MessageList: defineAsyncComponent(() => import('@/views/Imo/MessageList')),
    GroupsGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityGroupsGraph')),
    DailyGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityDailyGraph')),
    WeeklyGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityWeeklyGraph')),
    AnnualGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityYearGraph'))
  },
  watch: {
    id () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('imo', ['allUsers']),
    id () {
      const id = this.$route.params.id
      if (Array.isArray(id)) return id.join('/')
      return id ?? null
    },
    user () {
      return (this.id in this.allUsers ? this.allUsers[this.id] : null)
    },
    isLoaded () {
      return this.user ? true : false
    }
  },
  methods: {
    ...mapActions('imo', ['fetchUserById']),
    init () {
      this.update()
    },
    async update () {
      await this.fetchUserById(this.id)
    }
  },
  created () {
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.info) updateQuery = { ...updateQuery, info : 'current_tab' }
    else if (updateQuery.info !== 'current_tab') updateQuery.info = 'current_tab'
    if (!updateQuery?.details) updateQuery = { ...updateQuery, details : 'overview_tab' }
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    this.update()
  }
}
</script>

<style scoped>
.cover:hover .with_info_button-button {
    visibility: visible;
  }
</style>