import { apiRequest, tonApiRequest, getQueryString, getQueryArrayString } from '@/utils/{API}'
import { isNumeric, opToHex, blockKeyGen, truncString, toBase64Rfc, toBase64Web } from '@/utils/ton-filters'

import { TELEGRAM_USERNAMES_NFT_COLLECTION, MUST_BE_A_STRING, MUST_NOT_BE_EMPTY, MUST_BE_A_NUMBER } from '@/utils/consts'
import config from '@/config'
import { parseJson } from '@ton.js/json-parser'

const bigintFields = {
  shard: true, 
  balance: true, 
  jetton_balance: true, 
  last_tx_lt: true,
  src_shard: true,
  dst_shard: true,
  src_tx_lt: true,
  dst_tx_lt: true,
  in_amount: true,
  out_amount: true,
  total_fees: true,
  amount: true,
  ihr_fee: true,
  fwd_fee: true,
  created_lt: true
}

export default {
  namespaced: true,
  state: {
    tonBlocks: {},
    tonMessages: {},
    tonTransactions: {},
    tonTransactionMsgFlag: {},
    tonTransactionHexes: {},
    tonAccounts: {},
    tonAccountPairs: {},
    tonAccountBases: {},
    tonAccountPhones: {},
    // wallets and nfts
    tonJettonHolders: {},
    tonNftHolders: {},
    tonMetadata: {},
    tonMetaRelations: {},
    // Arrays with keys to fetch the correct info from maps
    tonLatestBlocks: [],
    tonLatestTransactions: [],
    // Arrays with keys for loading info from api
    exploredTonBlocks: [],
    exploredTonTransactions: [],
    exploredTonMessages: [],
    exploredTonAccounts: [],
    // Total number of resutls left for the api queries
    totalQueryTonTransactions: 0,
    totalQueryTonMessages: -1,
    totalQueryTonBlocks: 0,
    totalQueryTonAccounts: -1,
    totalQueryTonSearch: 0,
    // Stats
    tonStats : {},
    // Interfaces
    tonInterfaces : {},
    tonOperations : {},
    // Search
    tonLastSearch: {},
    tonSearchResults: [],
    // old
    isLoaded: {},
    statsProblem: false,
    stats: {},
    addresses: [],
    tonAddresses: [],
    tonAddressesVsPhones: [],
    tonPhones: {},
    txs: [],
    userNFTItemsByCollection: [],
    userNFTItemsByCollectionReady: true,
    userNFTItems: [],
    userNFTItemsLimit: 20,
    userNFTItemsReady: true,
    jettons: [],
    jettonsReady: true
  },
  getters: {
    getTonStats: (state) => state.tonStats,
    getTonInterfaces: (state)  => state.tonInterfaces,
    getTonOperations: (state)  => state.tonOperations,
    getTonBlocks: (state)  => state.tonBlocks,
    getTonExploredBlocks: (state)  => state.exploredTonBlocks,
    getTonAccounts: (state)  => state.tonAccounts,
    getTonExploredAccounts: (state)  => state.exploredTonAccounts,
    getTonAccountBases: (state)  => state.tonAccountBases,
    getTonAccountPhones: (state)  => state.tonAccountPhones,
    getTonNftHolders: (state) => state.tonNftHolders,
    getTonJettonHolders: (state) => state.tonJettonHolders,
    getTonMetadata: (state) => state.tonMetadata,
    getTonMessages: (state)  => state.tonMessages,
    getTonExploredMessages: (state)  => state.exploredTonMessages,
    getTonTransactions: (state)  => state.tonTransactions,
    getTonExploredTransactions: (state)  => state.exploredTonTransactions,
    getTonTransactionMsgFlag: (state)  => state.tonTransactionMsgFlag,
    getTonTransactionHexes: (state)  => state.tonTransactionHexes,
    getTonSearchResults: (state)  => state.tonSearchResults,
    getTonLatestBlocks: (state) => state.tonLatestBlocks.map((key) => state.tonBlocks[key]),
    getTonLatestTransactions: (state) => state.tonLatestTransactions.map((key) => state.tonTransactions[key]),
    getTonBlockShards: (state) => (key) => state.tonBlocks[key].shard_keys.map((shardKey) => state.tonBlocks[shardKey]),
    getTonBlockKeys: (state) => (keys, excludeEmpty) => excludeEmpty ? keys.filter((item) => state.tonBlocks[item].transactions_count > 0) : keys,
    deepTonTransactionKeys: (state) => (key) => {
      const output = [...state.tonBlocks[key].transaction_keys]
      state.tonBlocks[key].shard_keys.forEach((shrd) => output.push(...state.tonBlocks[shrd].transaction_keys))
      return output
    },
    getTonMessageKeys: (state) => (keys, in_, out_) => {
      const output = []
      for (const key of keys) {
        if (key in state.tonTransactions) {
          if (state.tonTransactions[key].in_msg_hash && in_) output.push(state.tonTransactions[key].in_msg_hash)
          if (out_) output.push(...state.tonTransactions[key].out_msg_keys)
        }
      }
      return output
    },
    getTonAccountKeys: (state) => (keys, loaded_ = true) => {
      const output = []
      for (const key of keys) {
        if (key in state.tonMessages) {
          const dst = state.tonMessages[key].dst_address?.hex
          const src = state.tonMessages[key].src_address?.hex
          if (dst && ((loaded_ && dst in state.tonAccounts) || (!loaded_ && !(dst in state.tonAccounts)))) output.push(dst)
          if (src && ((loaded_ && src in state.tonAccounts) || (!loaded_ && !(src in state.tonAccounts)))) output.push(src)
        }
      }
      return [...new Set(output)]
    },
    getTonMetaItems: (state) => (keys) => {
      const output = {}
      keys.filter(key => key in state.tonMetadata).forEach(key => output[key] = state.tonMetadata[key])
      return output
    },
    getTonMetaRelations: (state) => (keys) => {
      const output = {}
      keys.filter(key => key in state.tonMetaRelations).forEach(key => output[key] = state.tonMetaRelations[key])
      return output
    },
    nextTonPageFlag: (state) => (loaded, type) => {
      switch (type) {
        case 'trn': return loaded >= state.totalQueryTonTransactions
        case 'block': return loaded >= state.totalQueryTonBlocks
        case 'acc': return loaded >= state.totalQueryTonAccounts
        case 'msg': return loaded >= state.totalQueryTonMessages
        case 'src': return loaded >= state.totalQueryTonSearch
        default: return false
      }
    },
    getQueryTotal: (state) => (type) => {
      switch (type) {
        case 'trn': return state.totalQueryTonTransactions
        case 'block': return state.totalQueryTonBlocks
        case 'acc': return state.totalQueryTonAccounts
        case 'msg': return state.totalQueryTonMessages
        case 'src': return state.totalQueryTonSearch
        default: return 0
      }
    },
    // old
    tonPhones: state => state.tonPhones,
    tonAddressess: state => state.tonAddresses,
    tonAddressesVsPhones: state => state.tonAddressesVsPhones,
    isLoaded: state => state.isLoaded,
    userNFTItemsByCollection: state => state?.userNFTItemsByCollection,
    userNFTItemsByCollectionReady: state => state?.userNFTItemsByCollectionReady,
    userNFTItemsLimit: state => state?.userNFTItemsLimit || 20,
    userNFTItemsMinOffset: getters => getters.userNFTItemsLimit + 1,
    userNFTItems: state => state?.userNFTItems,
    userNFTItemsReady: state => state?.userNFTItemsReady,
    jettons: state => state.jettons,
    jettonsReady: state => state.jettonsReady
  },
  mutations: {
    cleanTonBlocks: (state) => {
      state.exploredTonBlocks = []
      state.tonLatestBlocks = []
      state.tonBlocks = {}
    },
    saveTonBlock: (state, payload) => {
      state.tonBlocks[payload.key] = payload.data
    },
    saveTonTransaction: (state, payload) => {
      state.tonTransactions[payload.key] = payload.data
    },
    saveTonTransactionHex: (state, payload) => {
      state.tonTransactionHexes[payload.key] = payload.data
    },
    saveTonTransactionMsgFlag: (state, payload) => {
      state.tonTransactionMsgFlag[payload.key] = payload.data
    },
    saveTonAccount: (state, payload) => {
      state.tonAccounts[payload.key] = payload.data
    },
    saveTonAccountBase: (state, payload) => {
      state.tonAccountBases[payload.key] = payload.data
    },
    saveTonAccountPhones: (state, payload) => {
      state.tonAccountPhones[payload.key] = payload.data
    },
    saveAccountPair: (state, payload) => {
      state.tonAccountPairs[payload.key] = payload.data
    },
    saveAccountField: (state, payload) => {
      if (payload.key in state.tonAccounts) {
        state.tonAccounts[payload.key][payload.field] = payload.data
      }
    },
    saveTonMetadata: (state, payload) => {
      state.tonMetadata[payload.key] = payload.data
    },
    saveTonMetaRelation: (state, payload) => {
      state.tonMetaRelations[payload.key] = payload.data
    },
    saveTonJettonHolder: (state, payload) => {
      state.tonJettonHolders[payload.key] = payload.data
    },
    saveTonNftHolder: (state, payload) => {
      state.tonNftHolders[payload.key] = payload.data
    },
    saveTonMessage: (state, payload) => {
      state.tonMessages[payload.key] = payload.data
    },
    saveTonStat: (state, payload) => {
      state.tonStats[payload.key] = payload.data
    },
    saveTonInterface: (state, payload) => {
      state.tonInterfaces[payload.key] = payload.data
    },
    saveTonOperation: (state, payload) => {
      state.tonOperations[payload.key] = payload.data
    },
    saveQueryTotal: (state, payload) => {
      switch (payload.type) {
        case 'trn': state.totalQueryTonTransactions = payload.data; return
        case 'block': state.totalQueryTonBlocks = payload.data; return
        case 'acc': state.totalQueryTonAccounts = payload.data; return
        case 'msg': state.totalQueryTonMessages = payload.data; return
        case 'src': state.totalQueryTonSearch = payload.data; return
        default: return
      }
    },
    saveTonSearchResults: (state, payload) => {
      state.tonSearchResults = [...payload.data]
    },
    saveTonLastSearch: (state, payload) => {
      state.tonLastSearch = payload.data
    },
    updateTonStats: (state, payload) => {
      state.tonStats = {}
      Object.keys(payload.data).forEach(key => {
        state.tonStats[key] = payload.data[key]
      })
    },
    resetTonLatestBlocks: (state) => {
      state.tonLatestBlocks = []
    },
    appendTonLatestBlocks: (state, payload) => {
      state.tonLatestBlocks.push(payload.data)
    },
    resetTonExploredBlocks: (state) => {
      state.exploredTonBlocks = []
    },
    appendTonExploredBlocks: (state, payload) => {
      state.exploredTonBlocks.push(payload.data)
    },
    adjustTonExploredBlocks: (state, payload) => {
      state.exploredTonBlocks.slice(0, payload.data)
    },
    resetTonExploredTransactions: (state) => {
      state.exploredTonTransactions = []
    },
    appendTonExploredTransactions: (state, payload) => {
      state.exploredTonTransactions.push(payload.data)
    },
    appendTonAccountArray: (state, payload) => {
      if (payload.key in state.tonAccounts) {
        state.tonAccounts[payload.key][payload.field].push(payload.data)
      }
    },
    resetTonExploredAccounts: (state) => {
      state.exploredTonAccounts = []
    },
    appendTonExploredAccounts: (state, payload) => {
      state.exploredTonAccounts.push(payload.data)
    },
    addTonInterface: (state, payload) => {
      state.tonInterfaces[payload.key] = payload.data
    },
    addTonOperation: (state, payload) => {
      state.tonOperations[payload.key] = payload.data
    },
    setUserNFTItemsByCollectionReady: (state, payload) => {
      state.userNFTItemsByCollectionReady = payload
    },
    setUserNFTItemsByCollection: (state, payload) => {
      state.userNFTItemsByCollection = payload
    },
    setUserNFTItemsReady: (state, payload) => {
      state.userNFTItemsReady = payload
    },
    setUserNFTItems: (state, payload) => {
      state.userNFTItems = payload
    },
    setJettonsReady: (state, payload) => {
      state.jettonsReady = payload
    },
    setJettons: (state, payload) => {
      state.jettons = payload
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    savePhoneData: (state, payload) => {
      state.tonPhones[payload.key] = payload.data
    },
    saveAddress: (state, payload) => {
      state.addresses[payload.key] = payload.data
    },
    saveTonAddress: (state, payload) => {
      state.tonAddresses[payload.key] = payload.data
    },
    saveTonPhonesData: (state, payload) => {
      state.tonAddressesVsPhones[payload.key] = payload.data
    },
    saveTx: (state, payload) => {
      state.txs[payload.key] = payload.data
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    }
  },
  actions: {
    convertBase64ToHex: ({ dispatch, commit, state, rootState }, value) => {
      const raw = atob(value)
      let result = ''
      for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16)
        result += (hex.length === 2 ? hex : '0' + hex)
      }
      return result
    },
    async processTonAccount ({ dispatch, commit, state, rootState }, account) {
      const accountKey = account.address.hex

      // Don't override account if the stored state matched given
      if (accountKey in state.tonAccounts) {
        if (state.tonAccounts[accountKey].block_seq_no > account.block_seq_no) return accountKey
      }

      const mappedAccount = {}
      if (!(accountKey in state.tonAccounts)) {
        mappedAccount.owned_nfts = []
        mappedAccount.minted_nfts = []
        mappedAccount.jetton_wallets = []
        mappedAccount.transaction_keys = []
        mappedAccount.transaction_amount = 0
        mappedAccount.jetton_amount = 0
        mappedAccount.nft_amount = 0
        mappedAccount.minted_amount = 0
        mappedAccount.loaded = false

        commit('saveTonAccountBase', { key: account.address.base64, data: accountKey })
        commit('saveAccountPair', { key: account.address.hex, date: account.address.base64 })
      }
      Object.assign(mappedAccount, account)

      if (accountKey in state.tonAccounts)
        commit('saveTonAccount', { key: accountKey, data: { ...state.tonAccounts[accountKey], ...mappedAccount} })
      else 
        commit('saveTonAccount', { key: accountKey, data: mappedAccount })
      return accountKey
    },
    async processTonMessage ({ dispatch, commit, state, rootState }, message, trKey, trType, parseAccounts = true) {
      const messageKey = message.hash

      // Don't override messages
      if (messageKey in state.tonMessages) {
        if (trKey) {
          if (trType === 'dst' && (state.tonMessages[messageKey].dst_tx_key === null || state.tonMessages[messageKey].dst_tx_key?.includes('|'))) state.tonMessages[messageKey].dst_tx_key = trKey
          if (trType === 'src' && (state.tonMessages[messageKey].src_tx_key === null || state.tonMessages[messageKey].src_tx_key?.includes('|'))) state.tonMessages[messageKey].src_tx_key = trKey
        }
        return messageKey
      }
      if (message.type === 'EXTERNAL_IN' && message.src_address) delete message.src_address
      if (message.type === 'EXTERNAL_OUT' && message.dst_address) delete message.dst_address
      const mappedMessage = {}

      mappedMessage.src_tx_key = (message.src_tx_lt && message.src_address) ?  `${message.src_address.base64}|${message.src_tx_lt}` : null
      mappedMessage.dst_tx_key = (message.dst_tx_lt && message.dst_address) ?  `${message.dst_address.base64}|${message.dst_tx_lt}` : null

      if (trType === 'src' && trKey) mappedMessage.src_tx_key = trKey
      if (trType === 'dst' && trKey) mappedMessage.dst_tx_key = trKey
      
      if (message.src_state) {
        if (parseAccounts) mappedMessage.src_state_key = await dispatch('processTonAccount', message.src_state)
        else mappedMessage.src_state_key = message.src_state.address.hex
        delete message.src_state
      }
      if (message.dst_state) {
        if (parseAccounts) mappedMessage.dst_state_key = await dispatch('processTonAccount', message.dst_state)
        else mappedMessage.src_state_key = message.dst_state.address.hex
        delete message.dst_state
      }
      
      Object.assign(mappedMessage, message)

      commit('saveTonMessage', { key: messageKey, data: mappedMessage })

      return messageKey
    },
    async processTonTransaction ({ dispatch, commit, state, rootState }, transaction, parseAccount = true) {
      try {
        const transactionKey = transaction.hash

        // Don't override transactions
        if (transactionKey in state.tonTransactions) return transactionKey

        const mappedTransaction = {}
        // mappedTransaction.hex = dispatch('convertBase64ToHex', transactionKey)

        mappedTransaction.hex = await dispatch('convertBase64ToHex', transaction.hash)
        commit('saveTonTransactionHex', { key: mappedTransaction.hex, data: transactionKey })

        mappedTransaction.out_msg_keys = []
        mappedTransaction.delta = 0n + BigInt(transaction.in_amount ?? 0n) - BigInt(transaction.out_amount ?? 0n)
        mappedTransaction.msg_fees = null

        let opType = 0

        if (transaction.account) {
          if (parseAccount) mappedTransaction.account_key = await dispatch('processTonAccount', transaction.account)
          else mappedTransaction.account_key = transaction.account.address.hex
          delete transaction.account
        }
        if (transaction.in_msg) {
          await dispatch('processTonMessage', transaction.in_msg, transactionKey, 'dst', parseAccount)
          if (transaction.in_msg.fwd_fee) {
            mappedTransaction.msg_fees = transaction.in_msg.fwd_fee
          }
          transaction.in_msg.operation_name ? opType = transaction.in_msg.operation_name :
            (transaction.in_msg.operation_id ? opType = `Contract op=${opToHex(transaction.in_msg.operation_id)}` : opType = 1)
          delete transaction.in_msg
        }
        if (transaction.out_msg !== undefined) {
          if (transaction.out_msg?.length) {
            for (const msg of transaction.out_msg) {
              if (msg.fwd_fee) {
                if (mappedTransaction.msg_fees) mappedTransaction.msg_fees += msg.fwd_fee
                else mappedTransaction.msg_fees = msg.fwd_fee
              }
              msg.operation_name ? ( (opType === 0 || opType === 1) ? opType = msg.operation_name : opType = 99) :
                (msg.operation_id ? ( (opType === 0 || opType === 1) ? opType = `Contract op=0x${opToHex(msg.operation_id)}` : opType = 99) :
                  opType = 2)
              const msgKey = await dispatch('processTonMessage', msg, transactionKey, 'src', parseAccount)
              mappedTransaction.out_msg_keys.push(msgKey)
            }
            delete transaction.out_msg
          }
        }
        mappedTransaction.full_fees = (transaction.total_fees ?? 0n) + (mappedTransaction.msg_fees ?? 0n)
        mappedTransaction.opType = opType
        Object.assign(mappedTransaction, transaction)

        commit('saveTonTransaction', { key: transactionKey, data: mappedTransaction })
        commit('saveTonTransactionMsgFlag', { key: transactionKey, data: false })
        return transactionKey
      } catch (error) {
        console.log(error)
      }
    },
    async processTonBlock ({ dispatch, commit, state, rootState }, { block, full = true }) {
      const blockKey = blockKeyGen(block.workchain, block.shard, block.seq_no)

      // Don't override existing blocks
      if (blockKey in state.tonBlocks && state.tonBlocks[blockKey].loaded) return blockKey

      const mappedBlock = {}
      mappedBlock.shard_keys = []
      mappedBlock.transaction_keys = []
      mappedBlock.loaded = false
      
      if (block.master !== undefined) {
        if (block.master) {
          mappedBlock.master_key = `${block.master.workchain}:${block.master.shard}:${block.master.seq_no}`
        }
        delete block.master
      }
      if (block.shards !== undefined) {
        if (block.shards) {
          for (const sh of block.shards) {
            const shKey = await dispatch('processTonBlock', { block: sh, full } )
            mappedBlock.shard_keys.push(shKey)
          }
        }
        delete block.shards
      }
      if (full && block.transactions !== undefined && block.transactions_count > 0) {
        if (block.transactions) {
          for (const tx of block.transactions) {
            const txKey = await dispatch('processTonTransaction', tx)
            mappedBlock.transaction_keys.push(txKey)
          }
        }
        delete block.transactions
      }

      Object.assign(mappedBlock, block)

      if (!(blockKey in state.tonBlocks) && !full) {
        commit('saveTonBlock', { key: blockKey, data: mappedBlock })
      } else {
        commit('saveTonBlock', { key: blockKey, data: { ...state.tonBlocks[blockKey], ...mappedBlock, loaded: true } })
      }

      // commit('saveTonBlock', { key: blockKey, data: mappedBlock })

      return blockKey
    },
    async initTonLoad ({ dispatch, commit, state, rootState }) {
      if (Object.keys(state.tonInterfaces).length === 0) {
        try {
          const { data } = await tonApiRequest(`/contracts/interfaces`, 'GET')
          const parsed = parseJson(data, (key, value, context) => (
              (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
          for (const inter of parsed.results) {
            commit('addTonInterface', { key: inter.name, data: inter})
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (Object.keys(state.tonOperations).length === 0) {
        try {
          const { data } = await tonApiRequest(`/contracts/operations`, 'GET')
          const parsed = parseJson(data, (key, value, context) => (
              (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
          for (const oper of parsed.results) {
            commit('addTonOperation', { key: oper.operation_name, data: oper})
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    async mainTonPageLoad ({ dispatch, commit, state, rootState }) {
      commit('updateTonStats', {data: {}})
      commit('resetTonLatestBlocks')
      commit('startLoad', 'ton_blocks_main')
      commit('startLoad', 'ton_stats')

      try {
        const latestReq = {
          workchain: -1,
          with_transactions: false,
          order: 'DESC',
          limit: 10
        }
        const query = getQueryArrayString(latestReq, false)
      
        const { data } = await tonApiRequest(`/blocks?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        for (const key in parsed.results) {
          const block = await dispatch('processTonBlock', { block: parsed.results[key], full: false })
          commit('appendTonLatestBlocks', { data: block })
        }
      } catch (error) {
        console.log(error)
      }
      try {
        const { data } = await tonApiRequest(`/statistics`, 'GET')
        const stats = JSON.parse(data)
        Object.keys(stats).forEach(key => {
          if (stats[key] instanceof Array) delete stats[key]
        })
        commit('updateTonStats', {data: stats})
      } catch (error) {
        console.log(error)
      }
      commit('stopLoad', 'ton_blocks_main')
      commit('stopLoad', 'ton_stats')
    },
    async updateBlockValues ({ dispatch, commit, state, rootState }, { limit = 10, seqOffset, cutPage = 1, order = 'DESC' } ) {
      commit('startLoad', 'ton_blocks')
      const fullReq = {
        workchain: -1,
        with_transactions: false,
        order,
        limit
      }
      if (seqOffset) {
        fullReq.after = seqOffset
        commit('adjustTonExploredBlocks', { data: limit*(cutPage-1) })
      }
      if (!seqOffset) commit('resetTonExploredBlocks')
      const query = getQueryArrayString(fullReq, false)
      try {
        const { data } = await tonApiRequest(`/blocks?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        commit('saveQueryTotal', { type: 'block', data: parsed.total })
        for (const key in parsed.results) {
          const block = await dispatch('processTonBlock', { block: parsed.results[key], full: false })
          commit('appendTonExploredBlocks', { data: block })
        }
      } catch (error) {
        console.log(error)
      }
      commit('stopLoad', 'ton_blocks')
    },
    async fetchTonBlock ({ dispatch, commit, state, rootState }, { workchain, shard, seqNo, full }) {
      commit('startLoad', 'ton_block_' + seqNo)
      if (blockKeyGen(workchain, shard, seqNo) in state.tonBlocks && state.tonBlocks[blockKeyGen(workchain, shard, seqNo)].loaded) {
        commit('stopLoad', 'ton_block_' + seqNo)
        return blockKeyGen(workchain, shard, seqNo)
      }
      const fullReq = {
        workchain,
        shard,
        with_transactions: full,
        seq_no: seqNo
      }
      const query = getQueryArrayString(fullReq, false)
      try {
        const { data } = await tonApiRequest(`/blocks?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        if (parsed.results && parsed.results.length > 0) {
          const key = await dispatch('processTonBlock', { block: parsed.results[0], full })
          // this.fetchBareAccounts(this.getAccountKeys(this.getMessageKeys(this.deepTransactionKeys(key), true, true), false))
          commit('stopLoad', 'ton_block_' + seqNo)
          return key
        } else return null
      } catch (error) {
        console.log(error)
      }
      commit('stopLoad', 'ton_block_' + seqNo)
    },
    async updateTonTransactions ({ dispatch, commit, state, rootState }, { limit = 10, seqOffset, excludeMC, account = null, order = 'DESC' } ) {
      commit('startLoad', 'ton_transactions')
      const fullReq = {
        order,
        limit
      }
      if (seqOffset) fullReq.after = seqOffset
      if (!seqOffset) commit('resetTonExploredTransactions')
      if (account) fullReq.address = account
      if (excludeMC) fullReq.workchain = '0'

      const query = getQueryArrayString(fullReq, false)
      try {
        const { data } = await tonApiRequest(`/transactions?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        commit('saveQueryTotal', { type: 'trn', data: parsed.total })
        for (const key in parsed.results) {
          const trn = await dispatch('processTonTransaction', parsed.results[key], false)
          commit('appendTonExploredTransactions', { data: trn })
        }
        if (account) {
          commit('saveAccountField', { key: account, field: 'transaction_amount', data: parsed.total})
          commit('saveAccountField', { key: account, field: 'transaction_keys', data: [...state.exploredTonTransactions] })
        }
      } catch (error) {
        console.log(error)
      }
      commit('stopLoad', 'ton_transactions')
    },
    async fetchTonTransaction ({ dispatch, commit, state, rootState }, hash) {
      commit('startLoad', 'ton_transaction_' + hash)

      if (hash in state.tonTransactions) {
        commit('stopLoad', 'ton_transaction_' + hash)
        return hash
      }

      let fullReq = {}

      if (hash.includes('|')) fullReq = { address: toBase64Web(hash.split('|')[0]), created_lt: hash.split('|')[1]}
      else fullReq = { hash }
      const query = getQueryArrayString(fullReq, true)
      try {
        const { data } = await tonApiRequest(`/transactions?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        if (parsed.results && parsed.results.length > 0) {
          await dispatch('processTonTransaction', parsed.results[0])
          commit('stopLoad', 'ton_transaction_' + hash)
          if (hash !== parsed.results[0].hash) {
            hash = parsed.results[0].hash
            commit('stopLoad', 'ton_transaction_' + hash)
          }
          // await this.fetchBareAccounts(this.getAccountKeys(this.getMessageKeys([hash], true, true), false))
          return hash
        } else return null
      } catch (error) {
        console.log(error)
      }
      commit('stopLoad', 'ton_transaction_' + hash)
      return hash
    },
    async updateTonAccounts ({ dispatch, commit, state, rootState }, { limit = 10, seqOffset, contract, order = 'DESC' } ) {
      commit('startLoad', 'ton_accounts')
      
      const fullReq = {
        interface: contract === 'All' ? null : contract,
        order,
        limit,
        latest: true
      }
      if (seqOffset) fullReq.after = seqOffset
      if (!seqOffset) commit('resetTonExploredAccounts')

      const query = getQueryArrayString(fullReq, false)
      try {
        const { data } = await tonApiRequest(`/accounts?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        commit('saveQueryTotal', { type: 'acc', data: parsed.total })
        for (const key in parsed.results) {
          const acc = await dispatch('processTonAccount', parsed.results[key])
          commit('appendTonExploredAccounts', { data: acc })
        }
      } catch (error) {
        console.log(error)
      }
      commit('stopLoad', 'ton_accounts')
    },
    async fetchBareTonAccounts ({ dispatch, commit, state, rootState }, hex) {
      // hex = hex.filter(key => !(key in badAddresses))
      if (hex.length === 0) return hex
      try {
        const fullReq = {
          address: hex,
          limit: 100,
          latest: true
        }
        const query = getQueryArrayString(fullReq, true)
        // get latest account state
        const { data } = await tonApiRequest(`/accounts?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        if (parsed.results && parsed.results.length > 0)
          parsed.results.forEach((acc) => {
            dispatch('processTonAccount', acc)
          })
        } catch (error) {
          console.log(error)
        }
      return hex
    },
    async fetchTonAccount ({ dispatch, commit, state, rootState }, { hex, preload = true, short = false }) {
      commit('startLoad', 'ton_account_' + hex)

      if (hex in state.tonAccountBases) {
        commit('stopLoad', 'ton_account_' + hex)
        hex = state.tonAccountBases[hex]
      }
      
      const fullReq = {
        address: hex,
        latest: true
      }

      const query = getQueryArrayString(fullReq, true)
      try {
        const { data } = await tonApiRequest(`/accounts?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        if (parsed.results && parsed.results.length > 0) {
          const key = await dispatch('processTonAccount', parsed.results[0])
          commit('stopLoad', 'ton_account_' + hex)
          if (hex !== key) {
            hex = key
            commit('stopLoad', 'ton_account_' + hex)
          } 
        } else return null
      } catch (error) {
        console.log(error)
      }

      if (!preload) {
        commit('stopLoad', 'ton_account_' + hex)
        return hex
      }

      if (state.tonAccounts[hex]?.transaction_keys.length === 0)
        await dispatch('updateTonTransactions', { limit: 20, seqOffset: null, excludeMC: false, account: hex })

      if (state.tonAccounts[hex]?.jetton_wallets.length === 0) {
        await dispatch('loadTonAccountJettons', {account: hex, preload: false, limit: short ? 10 : 1, seqOffset: null })
      }
      if (state.tonAccounts[hex]?.owned_nfts.length === 0)
        await dispatch('loadTonAccountNFTs', { account: hex, preload: true, minterFlag: false, limit: 18, seqOffset: null })
    
      if (!short && state.tonAccounts[hex]?.minted_nfts.length === 0)
        await dispatch('loadTonAccountNFTs', { account: hex, preload: true, minterFlag: true, limit: 18, seqOffset: null })

      if (!short && state.tonAccounts[hex]?.types?.includes('nft_collection') || state.tonAccounts[hex]?.types?.includes('jetton_minter')) {
        await dispatch('loadTonTopHolders', { hex, limit: 10 })
      }

      if (!(hex in state.tonAccountPhones)) {
        await dispatch('fetchTonAddressPhones', hex)
      }

      commit('stopLoad', 'ton_account_' + hex)
      if (!short) commit('saveAccountField', { key: hex, field: 'loaded', data: true })
      return hex
    },
    async loadTonTopHolders ({ dispatch, commit, state, rootState }, { hex, limit }) {
      try {
        const fullReq = {
          minter_address: hex,
          limit
        }

        const query = getQueryString(fullReq, true)
        const { data } = await tonApiRequest(`/accounts/aggregated?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))

        if (parsed.items && parsed.owned_items) {
          commit('saveTonNftHolder', { key: hex, data :{
            items: parsed.items,
            owned_items: parsed.owned_items,
            owners_count: parsed.owners_count
            }
          })
        }

        if (parsed.wallets) {
          commit('saveTonJettonHolder', { key: hex, data :{
              wallets: parsed.wallets,
              total_supply: parsed.total_supply,
              owned_balance: parsed.owned_balance
            }
          })
        }
      } catch (error) {
        console.log(error)
      }

      commit('stopLoad', 'ton_account_top_' + hex)
    },
    async loadTonAccountJettons ({ dispatch, commit, state, rootState }, { account, preload, limit = 18, seqOffset}) {
      commit('startLoad', 'ton_account_jettons_' + account)

      try {
        const req = {
          limit,
          latest: true,
          order: 'DESC',
          interface: 'jetton_wallet',
          owner_address: account
        }

        if (seqOffset) req.after = seqOffset
        const query = getQueryArrayString(req, true)

        const { data } = await tonApiRequest(`/accounts?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        
        commit('saveAccountField', { key: account, field: 'jetton_amount', data: parsed.total })

        if (!seqOffset) commit('saveAccountField', { key: account, field: 'jetton_wallets', data: [] })

        if (parsed.results && parsed.results.length > 0) {
          const metaReq = []
          for (const acc of parsed.results) {
            const accKey = await dispatch('processTonAccount', acc)

            const minter = acc.minter_address
            if (minter) metaReq.push(minter.hex)
            metaReq.push(acc.address.hex)
            
            if (minter && !(accKey in state.tonMetaRelations)) {
              commit('saveTonMetaRelation', { key: accKey, data: { owner: state.tonAccounts[account].address, minter }})
            }
            commit('appendTonAccountArray', { key: account, field: 'jetton_wallets', data: accKey })
          }

          if (preload) await dispatch('requestTonMetaBulk', metaReq)
        }
      } catch (error) {
        console.log(error)
      }

      commit('stopLoad', 'ton_account_jettons_' + account)
    },
    async loadTonAccountNFTs ({ dispatch, commit, state, rootState }, { account, preload, minterFlag, limit = 18, seqOffset}) {
      commit('startLoad', 'ton_account_nfts_' + account)
      
      try {
        const req = {
          limit,
          latest: true,
          order: 'DESC',
          interface: 'nft_item'
        }
        if (seqOffset) req.after = seqOffset
        minterFlag ? req.minter_address = account : req.owner_address = account
        const query = getQueryArrayString(req, true)

        const { data } = await tonApiRequest(`/accounts?${query}`, 'GET')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        
        minterFlag ? commit('saveAccountField', { key: account, field: 'minted_amount', data: parsed.total }) :
          commit('saveAccountField', { key: account, field: 'nft_amount', data: parsed.total })

        if (!seqOffset) minterFlag ? commit('saveAccountField', { key: account, field: 'minted_nfts', data: [] }) :
          commit('saveAccountField', { key: account, field: 'owned_nfts', data: [] })

        if (parsed.results && parsed.results.length > 0) {
          const metaReq = []
          for (const acc of parsed.results) {
            const accKey = await dispatch('processTonAccount', acc)

            const minter = acc.minter_address
            if (minter) metaReq.push(minter.hex)
            metaReq.push(acc.address.hex)
            
            if (minter && !(accKey in state.tonMetaRelations)) {
              commit('saveTonMetaRelation', { key: accKey, data: { owner: state.tonAccounts[account].address, minter }})
            }
            minterFlag ? commit('appendTonAccountArray', { key: account, field: 'minted_nfts', data: accKey }) :
              commit('appendTonAccountArray', { key: account, field: 'owned_nfts', data: accKey })
          }

          if (preload) await dispatch('requestTonMetaBulk', metaReq)
        }

      } catch (error) {
        console.log(error)
      }

      commit('stopLoad', 'ton_account_nfts_' + account)
    },
    async requestTonMetaBulk ({ dispatch, commit, state, rootState }, hex) {
      // return
      commit('startLoad', 'ton_meta')

      if (hex.length === 0) {
        commit('stopLoad', 'ton_meta')
        return hex
      }

      try {
        const fullReq = {
          address: [...new Set(hex)]
        }
        const query = getQueryArrayString(fullReq, true)

        const { data } = await tonApiRequest(`/metadata/states/latest?${query}`, 'GET', '')
        const parsed = parseJson(data, (key, value, context) => (
            (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
        
        if (parsed.results && parsed.results.length > 0)
          parsed.results.forEach((meta) => {
            commit('saveTonMetadata', { key: meta.address, data: 
              {
                name: meta.name ?? truncString(meta.address.base64, 5),
                symbol: meta.symbol ?? meta.name ?? '',
                image_url: meta.error ? '' : (meta.compressed_image_filename ? ('https://anton.tools/static/' + meta.compressed_image_filename) : ''),
                decimals: meta.decimals ?? 9,
                description: meta.description ?? 'No description'
              }
            })
          })
          hex.filter(item => !(item in state.tonMetadata)).forEach(item => {
            commit('saveTonMetadata', { key: item, data: 
              {
                name: 'Not found',
                symbol:'',
                image_url: '',
                decimals: 9,
                description: 'Not found'
              }
            })
          })
      } catch (error) {
        console.log(error)
      }

      commit('stopLoad', 'ton_meta')
    },
    async searchTon ({ dispatch, commit, state, rootState }, { req, limit = 20, useLastSearch = false, offset }) {
      try {
        commit('startLoad', 'ton_search')

        if (!offset) commit('saveTonSearchResults', { data: [] })

        if (useLastSearch && state.tonLastSearch) req = state.tonLastSearch
        commit('saveTonLastSearch', { data: req })

        if (!req) {
          commit('stopLoad', 'ton_search')
          return state.tonSearchResults
        } 

        if (req.type == 'label') {

          try {
            const fullReq = {
              name: req.value,
              limit,
              offset
            }
            const query = getQueryArrayString(fullReq, true)
            // get latest account state
            const { data } = await tonApiRequest(`/labels?${query}`, 'GET')
            const parsed = parseJson(data, (key, value, context) => (
                (key in bigintFields && isNumeric(context.source) ? BigInt(context.source) : value)))
            commit('saveQueryTotal', { type: 'src', data: parsed.total })
            if (!useLastSearch) commit('saveTonSearchResults', { data: [] })
            if (parsed.results && parsed.results.length > 0) {
              const res = [...state.tonSearchResults]
              for (const acc of parsed.results) {
                res.push({
                  type: 'label',
                  value: acc.address.hex,
                  show: acc.name
                })
              }
              commit('saveTonSearchResults', { data: res })
            }
          } catch (err) {
            return state.tonSearchResults
          } finally {
            commit('stopLoad', 'ton_search')
          }

        } else if (req.type == 'account') {

          if (req.value.hex in state.tonAccounts) { 
            commit('saveTonSearchResults', { data: [req] })
            commit('stopLoad', 'ton_search')
            return [req] 
          }

          if (req.value.hex in state.tonAccountBases) {
            commit('saveTonSearchResults', { 
              data: [{
                type: 'account',
                value: {
                  hex: state.tonAccountBases[req.value.hex]
                },
                show: req.value.hex 
              }] 
            })
          } else {
            const key = await dispatch('fetchTonAccount', { hex: req.value.hex, preload: false })
            if (key) commit('saveTonSearchResults', { 
              data:  [{
                type: 'account',
                value: {
                  hex: key
                },
                show: req.value.hex
              }]
            })
          }

        } else if (req.type === 'block') {

          if (blockKeyGen(req.value.workchain, req.value.shard, req.value.seq_no) in state.tonBlocks) { 
            commit('saveTonSearchResults', { data: [req] })
            commit('stopLoad', 'ton_search')
            return [req]
          }

          const key = await dispatch('fetchTonBlock', { workchain: req.value.workchain, shard: req.value.shard, seqNo: req.value.seq_no, full: false })
          if (key) commit('saveTonSearchResults', { 
            data: [{
              type: 'block',
              value: {
                workchain: state.tonBlocks[key].workchain,
                shard: state.tonBlocks[key].shard,
                seq_no: state.tonBlocks[key].seq_no
              },
              show: blockKeyGen(req.value.workchain, truncString(req.value.shard.toString(), 3), req.value.seq_no)
            }]
          })
        } else if (req.type === 'transaction') {

          if (req.value.hash in state.tonTransactions) { 
            commit('saveTonSearchResults', { data: [req] })
            commit('stopLoad', 'ton_search')
            return [req]
          }

          if (req.value.hash in state.tonTransactionHexes) {
            commit('saveTonSearchResults', { 
              data: [{
                type: 'transaction',
                value: {
                  hash: state.tonTransactionHexes[req.value.hash]
                },
                show: req.value.hash
              }]
            })
          } else {
            const key = await dispatch('fetchTonTransaction', req.value.hash)
            if (key) commit('saveTonSearchResults', { 
              data: [{
                type: 'transaction',
                value: {
                  hash: key
                },
                show: req.value.hash
              }]
            })
          }
        }

        commit('stopLoad', 'ton_search')

        return state.tonSearchResults
      } catch (error) {
        console.log(error)
      }
    },
    pullUserNFTItemsByCollection ({ commit }, { owner = null } = {}) {
      if (typeof owner !== 'string') {
        return Promise.reject(new Error('owner parameter '.concat(MUST_BE_A_STRING)))
      }
      if (owner.length === 0) {
        return Promise.reject(new Error('owner parameter '.concat(MUST_NOT_BE_EMPTY)))
      }
      if (typeof config?.servers?.['asiris_api'] !== 'string') {
        return Promise.reject(new Error('config.servers.["asiris_api"] '.concat(MUST_BE_A_STRING)))
      }
      if (config.servers['asiris_api'].length === 0) {
        return Promise.reject(new Error('config.servers.["asiris_api"] '.concat(MUST_NOT_BE_EMPTY)))
      }
      return new Promise((resolve, reject) => {
        commit('setUserNFTItemsByCollectionReady', false)
        const searchParams = new URLSearchParams({
          owner,
          collection: TELEGRAM_USERNAMES_NFT_COLLECTION,
          limit: 500,
          offset: 0
        })
        const searchParamsString = searchParams.toString()
        const REQUEST_PATH = '/tools/tonapi/nft/searchItems'
        const REQUEST_URL = REQUEST_PATH.concat('?', searchParamsString)
        const REQUEST_CONFIG = config.servers['asiris_api']
        const REQUEST_PARAMS = [
          REQUEST_URL,
          'GET',
          {},
          REQUEST_CONFIG
        ]
        apiRequest(...REQUEST_PARAMS)
        .then(apiResponse => {
          const data = apiResponse.data['nft_items']
          commit('setUserNFTItemsByCollection', data)
          resolve(data)
        })
        .catch(error => {
          console.debug(error)
          reject(error)
        })
        .finally(() => {
          commit('setUserNFTItemsByCollectionReady', true)
        })
      })
    },
    pullUserNFTItems ({ commit, getters }, { owner = null, offset = 0 } = {}) {
      if (typeof owner !== 'string') {
        return Promise.reject(new Error('owner parameter '.concat(MUST_BE_A_STRING)))
      }
      if (owner.length === 0) {
        return Promise.reject(new Error('owner parameter '.concat(MUST_NOT_BE_EMPTY)))
      }
      if (typeof offset !== 'number') {
        return Promise.reject(new Error('offset parameter '.concat(MUST_BE_A_NUMBER)))
      }
      if (typeof config?.servers?.['asiris_api'] !== 'string') {
        return Promise.reject(new Error('config.servers.["asiris_api"] '.concat(MUST_BE_A_STRING)))
      }
      if (config.servers['asiris_api'].length === 0) {
        return Promise.reject(new Error('config.servers.["asiris_api"] '.concat(MUST_NOT_BE_EMPTY)))
      }
      return new Promise((resolve, reject) => {
        commit('setUserNFTItemsReady', false)
        let searchQuery = {
          limit: getters.userNFTItemsLimit,
          owner,
          offset
        }
        const apiRequestCall = () => {
          const searchParams = new URLSearchParams(searchQuery)
          const searchParamsString = searchParams.toString()
          const REQUEST_PATH = '/tools/tonapi/nft/searchItems'
          const REQUEST_URL = REQUEST_PATH.concat('?', searchParamsString)
          const REQUEST_CONFIG = config.servers['asiris_api']
          const REQUEST_PARAMS = [
            REQUEST_URL,
            'GET',
            {},
            REQUEST_CONFIG
          ]
          return apiRequest(...REQUEST_PARAMS)
        }
        const nftItems = APIResponse => APIResponse.data['nft_items']
        apiRequestCall()
        .then(APIResponse => {
          const data = nftItems(APIResponse)
          commit('setUserNFTItems', data)
        })
        .then(() => {
          searchQuery.offset = searchQuery.offset + getters.userNFTItemsMinOffset
        })
        .then(apiRequestCall)
        .then(APIResponse => {
          const data = nftItems(APIResponse)
          if (
            Array.isArray(data) &&
            data.length > 0
          ) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
        .catch(error => {
          console.debug(error)
        })
        .finally(() => {
          commit('setUserNFTItemsReady', true)
        })
      })
    },
    pullJettons ({ commit }, account) {
      if (typeof account !== 'string') {
        return Promise.reject(new Error('account parameter '.concat(MUST_BE_A_STRING)))
      }
      if (account.length === 0) {
        return Promise.reject(new Error('account parameter '.concat(MUST_NOT_BE_EMPTY)))
      }
      if (typeof config?.servers?.['asiris_api'] !== 'string') {
        return Promise.reject(new Error('config.servers.["asiris_api"] '.concat(MUST_BE_A_STRING)))
      }
      if (config.servers['asiris_api'].length === 0) {
        return Promise.reject(new Error('config.servers.["asiris_api"] '.concat(MUST_NOT_BE_EMPTY)))
      }
      const searchParams = new URLSearchParams({
        account
      })
      const searchParamsString = searchParams.toString()
      const REQUEST_PATH = '/tools/tonapi/jetton/getBalances'
        const REQUEST_URL = REQUEST_PATH.concat('?', searchParamsString)
        const REQUEST_CONFIG = config.servers['asiris_api']
        const REQUEST_PARAMS = [
          REQUEST_URL,
          'GET',
          {},
          REQUEST_CONFIG
        ]
        commit('setJettonsReady', false)
        apiRequest(...REQUEST_PARAMS)
        .then(apiResponse => {
          const data = apiResponse.data.balances
          commit('setJettons', data)
        })
        .catch(error => {
          console.debug(error)
        })
        .finally(() => {
          commit('setJettonsReady', true)
        })
    },
    fetchTonAddressPhones ({ dispatch, commit, state, rootState }, address) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'tonaddressphones_' + address)
        apiRequest(`/getPhonesByHolder?holder_address=` + encodeURIComponent(address), 'GET', {}, config.servers.tganon)
          .then(data => {
            if (data.data) {
              commit('saveTonAccountPhones', { key: address, data: data.data })
              commit('saveTonPhonesData', {key: address, data: data.data})
              resolve(data.data)
            } else {
              reject(new Error(500))
            }
          }).catch(() => {
          reject(new Error(500))
        }).finally(() => {
          commit('stopLoad', 'tonaddressphones_' + address)
        })
      })
    },
    fetchTonAddressByPhone ({ dispatch, commit, state, rootState }, phone) {
      return new Promise((resolve, reject) => {
        phone = phone.replace('+','')
        commit('startLoad', 'tonphone_' + phone)
        apiRequest(`/getPhoneHolderAccount?phone=` + encodeURIComponent(phone), 'GET', {}, config.servers.tganon)
          .then(data => {
            if (data.data) {
              commit('savePhoneData', {key: phone, data: data.data})
              if (data.data.address) {
                commit('saveTonAddress', {key: data.data.address, data: data.data})
              }
              resolve(data.data)
            } else {
              reject(new Error(500))
            }
          }).catch((error) => {
            console.log(error)
          reject(new Error(500))
        }).finally(() => {
          commit('stopLoad', 'tonphone_' + phone)
        })
      })
    },
    fetchTonAddress ({ dispatch, commit, state, rootState }, address) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'tonaddress_' + address)
        apiRequest(`/tools/tonapi/account/getInfo?account=` + encodeURIComponent(address), 'GET', {}, config.servers.asiris_api)
          .then(data => {
            if (data.data) {
              if (data.data.address) {
                commit('saveTonAddress', {key: data.data.address.raw, data: data.data})
              }
              resolve(data.data)
            } else {
              reject(new Error(500))
            }
          }).catch(() => {
          reject(new Error(500))
        }).finally(() => {
          commit('stopLoad', 'tonaddress_' + address)
        })
      })
    },
    fetchAddress ({ dispatch, commit, state, rootState }, address) {
      commit('startLoad', 'address_' + address)
      return new Promise((resolve, reject) => {
        apiRequest(`/btc/address/` + encodeURIComponent(address), 'GET', {}, config.servers.crypto)
          .then(data => {
            if (data.data) {
              if (data.data.info.status === 'success') {
                commit('saveAddress', { key: address, data: data.data })
                resolve(data.data)
              } else {
                reject(new Error(data.data.info.statusCode))
              }
            } else {
              reject(new Error(500))
            }
          }).catch(() => {
            reject(new Error(500))
          }).finally(() => {
            commit('stopLoad', 'address_' + address)
          })
      })
    },
    fetchTxs ({ dispatch, commit, state, rootState }, txid) {
      commit('startLoad', 'tx_' + txid)
      return new Promise((resolve, reject) => {
        apiRequest(`/btc/tx/` + encodeURIComponent(txid), 'GET', {}, config.servers.crypto)
          .then(data => {
            data = data.data

            if (data.status === 'success') {
              if (data.data) {
                commit('saveAddress', { key: txid, data: data.data.data })
                resolve(data.data.data)
              }
            } else {
              reject(new Error(data.statusCode))
            }
          }).catch(() => {
            reject(new Error(500))
          }).finally(() => {
            commit('stopLoad', 'tx_' + txid)
          })
      })
    }
  }
}
