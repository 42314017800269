<template>
  <table class="uk-table uk-table-middle uk-table-striped uk-margin-remove">
    <tbody class="uk-table-divider">
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.workchain`) }}
        </template>
        <template #value>
          {{ chainTitle(block.workchain) }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.shard`) }}
        </template>
        <template #value>
          {{ block.shard }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.seq_no`) }}
        </template>
        <template #value>
          {{ block.seq_no }}
        </template>
      </PropLine>
      <PropLine v-if="block.master_key">
        <template #name>
          {{ $t(`crypto.ton.master_key`) }}
        </template>
        <template #value>
          <CopyableText :text="block.master_key">
            <router-link 
              :to="`/crypto/ton/blocks/block?workchain=${masterLink.workchain}&shard=${masterLink.shard}&seq_no=${masterLink.seq_no}#overview`"
            >
              {{ block.master_key }}
            </router-link>
          </CopyableText>
        </template>
      </PropLine>
      <PropLine v-if="!block.master_key">
        <template #name>
          {{ $t(`crypto.ton.shard_keys`) }}
        </template>
        <template #value>
          {{ block.shard_keys.length }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.transaction_keys`) }}
        </template>
        <template #value>
          {{ block.transactions_count }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.root_hash`) }}
        </template>
        <template #value>
          <CopyableText :text="block.root_hash">
            {{ block.root_hash }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.file_hash`) }}
        </template>
        <template #value>
          <CopyableText :text="block.file_hash">
            {{ block.file_hash }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine :wrap="true">
        <template #name>
          {{ $t(`crypto.general.external`) }}
        </template>
        <template #value>
          <template v-for="key of Object.keys(externalLink)">
            <a 
              v-if="externalLink[key]"
              :key="key"
              :href="externalLink[key]"
              class="uk-margin-right"
              uk-icon="icon:link"
              target="_blank"
              style="line-height: 1.5; color: #DA7D02;"
            >
              {{ key }}
            </a>
          </template>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.scanned_at`) }}
        </template>
        <template #value>
          {{ new Date(block.scanned_at).toLocaleString() }}
        </template>
      </PropLine>
    </tbody>
  </table>        
</template>

<script>
import { chainTitle } from '@/utils/ton-filters'
import PropLine from '../Atoms/PropLine'
import CopyableText from '../Atoms/CopyableText'

export default {
  name: 'BlockPropsTable',
  components: {
    PropLine,
    CopyableText
  },
  props: {
    block : {
      type: Object,
      required: true
    }
  },
  computed: {
    masterLink () {
      const arr = this.block?.master_key ? this.block.master_key.split(':') : '0:1:2'.split(':')
      return {
        workchain: arr[0],
        shard: arr[1],
        seq_no: arr[2]
      }
    },
    externalLink () { 
      return {
        'Toncoin': `https://explorer.toncoin.org/search?workchain=${this.block.workchain}&shard=8000000000000000&seqno=${this.block.seq_no}&lt=&utime=&roothash=&filehash=`,
        'TonWhales': this.block.workchain === -1 ? `https://tonwhales.com/explorer/block/${this.block.seq_no}`: null,
        'Anton.tools' : `https://explorer.anton.tools/blocks?workchain=${this.block.workchain}&shard=${this.block.shard}&seq_no=${this.block.seq_no}#overview`,
        'Ton.sh': `https://ton.sh/block/${this.block.workchain}/${this.block.seq_no}`
      }
    }
  },
  methods: {
    chainTitle
  }
}
</script>