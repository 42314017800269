<template>
  <router-link 
    v-if="error" 
    :to="{ path: '/crypto/ton' }"
  >
    {{ 'An error occured while loading transaction! Go to overview page..' }}
  </router-link>
  <div 
    v-else-if="!isLoaded" 
    class="uk-flex uk-flex-center"
  >
    <Loader />
  </div>
  <div
    v-else-if="acc"
    class="uk-flex uk-flex-column uk-remove-margin"
    style="row-gap: 12px;" 
  >
    <div 
      class="uk-card uk-card-default uk-margin-remove"
    >
      <h3 
        class="uk-margin-remove uk-text-truncate uk-card-header"
        style="padding-top: 10px;"
      >
        {{ $t('crypto.route.account') + ` ${hex}` }}
      </h3>
      <PropsTable
        :acc="acc"
      />
    </div>
    <ul
      v-if="acc?.loaded" 
      class="uk-child-width-expand uk-text-medium tab-styler uk-card-header" 
      uk-tab
    >
      <li 
        v-if="trKeys.length > 0" 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#transactions' || $route.hash === '#overview')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#transactions', query: $route.query}">
          {{ $t('crypto.route.transactions') }}
          <span class="uk-badge uk-margin-small-left">
            {{ trKeys.length }}
          </span>
        </router-link>
      </li>
      <li
        v-for="item in routes.slice(1,)"
        :key="item.route + item.count"
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === `#${item.route}`)}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: `#${item.route}`, query: $route.query}">
          {{ $t(item.t) }}
          <span class="uk-badge uk-margin-small-left">
            {{ item.count }}
          </span>
        </router-link>
      </li>
    </ul>
    <div
      v-if="acc?.loaded" 
      class="uk-card uk-card-default uk-margin-remove"
    >
      <div 
        v-if="($route.hash === '#transactions' || $route.hash === '#overview')" 
        id="accounts_tx"
      >
        <TransactionsTable 
          :keys="trKeys" 
          :default-length="20" 
          :hidden="trKeys.length === 0" 
          :update="true" 
          :account="hex"
          :excludeMC="false"
        />
      </div>
      <div 
        v-if="($route.hash === '#phones')" 
        id="accounts_phones"
      >
        <PhoneTable
          :keys="getTonAccountPhones[hex]" 
          :default-length="10"
          :owner="hex"
        />
      </div>
      <div 
        v-if="($route.hash === '#jettons')" 
        id="accounts_jettons"
      >
        <JettonsTable
          :keys="jtKeys" 
          :default-length="10"
          :owner="hex"
        />
      </div>
      <div 
        v-if="($route.hash === '#nfts')" 
        id="account_nfts"
      >
        <NFTGrid
          :keys="ownerKeys" 
          :default-length="18"
          :account="hex"
          :minterFlag="false"
        />
      </div>
      <div 
        v-if="($route.hash === '#minter')" 
        id="account_minter"
      >
        <NFTGrid
          :keys="minterKeys" 
          :default-length="18"
          :account="hex"
          :minterFlag="true"
        />
      </div>
      <div 
        v-if="$route.hash === '#nft_holders' && acc.address.hex in getTonNftHolders" 
        id="nft_holders"
      >
        <TopNFTHolders 
          :keys="getTonNftHolders[acc.address.hex].owned_items ?? []" 
          :minter="hex" 
          :default-length="10" 
        />
      </div>
      <div 
        v-if="$route.hash === '#jetton_holders' && acc.address.hex in getTonJettonHolders" 
        id="jetton_holders"
      >
        <TopJettonHolders 
          :keys="getTonJettonHolders[acc.address.hex].owned_balance ?? []" 
          :minter="hex" 
          :default-length="10" 
        />
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import PropsTable from '@/components/Crypto/Ton/Accounts/PropsTable'
import JettonsTable from '@/components/Crypto/Ton/Accounts/JettonsTable'
import NFTGrid from '@/components/Crypto/Ton/Accounts/NFTGrid'
import TopNFTHolders from '@/components/Crypto/Ton/Accounts/TopNFTHolders'
import TopJettonHolders from '@/components/Crypto/Ton/Accounts/TopJettonHolders'
import PhoneTable from '@/components/Crypto/Ton/Accounts/PhoneTable'
import TransactionsTable from '@/components/Crypto/Ton/Transactions/Table'
import { mapGetters, mapActions } from 'vuex'
import { toBase64Rfc } from '@/utils/ton-filters'

export default {
  name: 'TonAccountInfo',
  components: {
    Loader,
    PropsTable,
    TransactionsTable,
    JettonsTable,
    NFTGrid,
    TopNFTHolders,
    TopJettonHolders,
    PhoneTable
  },
  data () {
    return {
      error: false,
      deepTrKeys: false
    }
  },
  watch: {
    hex: {
      handler (to, from) {
        if (from && to) this.loadAccount()
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts', 'getTonJettonHolders', 'getTonNftHolders', 'getTonAccountPhones']),
    hex () { return this.$route?.params?.id ? this.$route.params.id : null },
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_account_' + this.hex] ?? false
    },
    isHashLoaded () {
      return this.$store.state.crypto.isLoaded['ton_' + (this.$route.hash !== '#overview' ? this.$route.hash.slice(1) : 'transactions')] ?? false
    },
    acc () {
      return this.getTonAccounts[this.hex] ?? null
    },
    trKeys () {
      return this.acc?.transaction_keys?.length > 0 ? this.acc.transaction_keys : []
    },
    jtKeys () {
      return this.acc?.jetton_wallets?.length > 0 ? this.acc.jetton_wallets : []
    },
    minterKeys () {
      return this.acc?.minted_nfts?.length > 0 ? this.acc.minted_nfts : []
    },
    ownerKeys () {
      return this.acc?.owned_nfts?.length > 0 ? this.acc.owned_nfts : []
    },
    routes () {
      const { trKeys, jtKeys, ownerKeys, minterKeys, acc, getTonJettonHolders, getTonNftHolders, getTonAccountPhones } = this
      const output = []
      if (trKeys.length > 0) output.push({ route: 'transactions', t: 'crypto.route.transactions', count: acc ? acc.transaction_amount : 0 })
      if (this.hex in getTonAccountPhones && getTonAccountPhones[this.hex].length > 0) output.push({ route: 'phones', t: 'crypto.route.phones', count: acc ? getTonAccountPhones[this.hex].length : 0 })
      if (jtKeys.length > 0) output.push({ route: 'jettons', t: 'crypto.route.jettons', count: acc ? acc.jetton_amount : 0})
      if (acc.address.hex in getTonJettonHolders) output.push({ route: 'jetton_holders', t: 'crypto.ton.jetton_holders', count: getTonJettonHolders[acc.address.hex].wallets})
      if (ownerKeys.length > 0) output.push({ route: 'nfts', t: 'crypto.route.nfts', count: acc ? acc.nft_amount : 0})
      if (minterKeys.length > 0) output.push({ route: 'minter', t: 'crypto.ton.minter', count: acc ? acc.minted_amount : 0})
      if (acc.address.hex in getTonNftHolders) output.push({ route: 'nft_holders', t: 'crypto.ton.nft_holders', count: getTonNftHolders[acc.address.hex].owners_count})
      // if (Object.keys(getMethods.value).length > 0) output.push({ route: 'get_methods', t: 'ton.get_methods'})
      return output
    }
  },
  methods: {
    toBase64Rfc,
    ...mapActions('crypto', ['fetchTonAccount']),
    async loadAccount () {
      this.error = false
      if (!this.$route.hash) this.$router.replace(this.$route.fullPath + '#overview')
      const key = await this.fetchTonAccount({ hex: this.hex })
      if (this.hex != key) this.$router.replace({ name: 'crypto_ton_account_info', params: { id: key }})
      // await store.fetchBareAccounts(unloadedAccountKeys.value)
      if (!this.acc) {
        this.error = true
        return
      }
    }
  },
  async mounted () {
    await this.loadAccount()
  }
}
</script>