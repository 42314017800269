<template>
  <div
    class="uk-card uk-card-default uk-margin-remove-top uk-margin-bottom"
    style="padding-bottom: 12px;"
  >
    <div 
      class="uk-flex uk-flex-middle uk-width-1-1 uk-card-header uk-margin-remove" 
      style="justify-content: space-between;"
    >
      <h2 
        class="uk-margin-remove"
        style="padding-top: 10px;"
      >
        {{ $t('crypto.route.transactions') }}
      </h2>
      <div class="uk-child-width-auto uk-text-right">
        <label>
          <input 
            v-model="excludeMC" 
            class="uk-checkbox uk-margin-small-right" 
            type="checkbox"
          />
          {{ $t('crypto.options.basechain') }}
        </label>
      </div>
    </div>
    <TransactionsTable 
      :keys="keys" 
      :update="true" 
      :default-length="10" 
      :hidden="false" 
      :account="null"
      :exclude-m-c="excludeMC"
    />
  </div>
</template>
  
  <script>
import TransactionsTable from '@/components/Crypto/Ton/Transactions/Table'
import { mapGetters } from 'vuex'
  
export default {
  name: 'TonTransactionList',
  components: {
    TransactionsTable
  },
  data () {
    return {
      excludeMC: false
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonExploredTransactions']),
    keys () {
      return this.getTonExploredTransactions
    }
  }
}
  </script>