<template>
  <div>
    <div
      class="uk-grid"
      uk-grid
    >
      <div
        v-if="isLoaded && accessLevel"
        class="uk-width-expand uk-grid-small uk-align-center uk-margin-remove-bottom"
        uk-grid
      >
        <addProfile
          :caseId="caseId"
          @added="setPage(1)"
        />
        <button
          class="uk-button uk-button-primary uk-margin-small-right "
          @click="refreshCheck"
        >
          <span
            uk-icon="icon: refresh"
          />
          {{ $t('cases.recheck') }}
        </button>
        <router-link
          tag="button"
          :to="{
            name:'case_mass_import',
            params: {id: caseId}
          }"
          class="uk-button  uk-button-primary"
        >
          <span
            uk-icon="icon: cloud-upload"
          />
          {{ $t('cases.import') }}
        </router-link>
      </div>
    </div>
    <hr
      v-if="isLoaded && accessLevel"
    />
    <div
      class="uk-margin-bottom"
    >
      <div
        class="uk-width-1-1 uk-margin-small-bottom uk-grid-stacked "
        uk-grid
      >
        <div
          class="uk-inline uk-margin-right uk-width-expand"
        >
          <input
            v-model.trim="query.search"
            type="text"
            class="uk-input uk-width-expand"
            :placeholder="$t('cases.search-placeholder')"
            @keyup.enter="setPage(1)"
          />
          <button
            class="uk-form-icon uk-form-icon-flip uk-button-primary"
            uk-icon="icon: search"
            @click="setPage(1)"
          />
        </div>
        <div
          class="uk-width-auto uk-padding-remove"
        >
          {{ $t('forums.users.sort.sort_by') }}
          <vueSelect
            v-model="currentOrderBy"
            :options="orderListTranslated"
            label="label"
            class="uk-display-inline-block
              uk-form-width-small"
          />
          <vueSelect
            v-model="currentOrderType"
            :options="orderTypeTranslated"
            label="label"
            class="uk-display-inline-block
              uk-form-width-small"
          />
        </div>
        <div
          class="uk-width-auto uk-padding-remove uk-margin-small-left"
        >
          {{ $t('cases.profiles.limit') }}
          <select
            v-model="query.limit"
            style="width: 50px"
            class="uk-select"
          >
            <option
              v-for="limit in limits"
              :key="'limit' + limit"
            >
              {{ limit }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <span
          v-for="tag in state.tags"
          :key="tag"
          class="uk-margin-small-left"
        >
          <label
            v-if="tag"
          >
            <input
              :id="tag"
              v-model="query.tags"
              class="uk-checkbox"
              type="checkbox"
              :value="tag"
              @change="update"
            />
            {{ tag }}
          </label>
        </span>
      </div>
    </div>
    <div v-if="!isLoaded['profile_list_' + caseId]">
      <Loader />
    </div>
    <div
      v-else
      class="uk-grid-match uk-grid-small"
      uk-grid
    >
      <div
        v-for="profile in profiles"
        :key="profile.profile_id"
        class="uk-width-1-3"
      >
        <ProfileCard
          :data="profile"
          :caseId="caseId"
          @deleteProfile="update"
          @tag="addTag"
        />
      </div>
    </div>

    <div v-if="lastPage !== 1">
      <Paginate
        v-model="pagination.page"
        :page-count="lastPage"
        :click-handler="setPage"
        prev-text="<"
        next-text=">"
        container-class="uk-pagination uk-flex-center"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProfileCard from '@/components/cases/ProfileCard'
import Paginate from '@/components/Paginate'
import Loader from '@/components/TGCP/Loader'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'

export default {
  name: 'ProfilesList',
  components: {
    ProfileCard,
    Paginate,
    Loader,
    addProfile: defineAsyncComponent(() => import('@/components/cases/Profile/addProfile.vue')),
    vueSelect
  },
  props: {
    caseId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      profiles: [],
      orderList: [
        'updatedAt',
        'createdAt',
        'suggestions_count'
      ],
      state: {
        text: '',
        tags: []
      },
      query: {
        search: '',
        limit: localStorage.getItem('profile_limit_settings') || 9,
        offset: 0,
        tags: [],
        order_by: 'updatedAt',
        order_type: 'desc'
      },
      pagination: {
        page: 1,
        total: 0
      },
      limits: [ 9, 15, 21, 30, 60 ]
    }
  },
  watch: {
    caseId (to, from) {
      this.query.search = ''
      this.query.offset = 0
      this.pagination.page = 1
      this.update()
    },
    'query.limit' (to, from) {
      localStorage.setItem('profile_limit_settings', to)
      this.setPage(1)
    }
  },
  computed: {
    ...mapGetters('cases', [
      'isLoaded'
    ]),
    isPrevPage () {
      return this.pagination.page === 1 ? 'uk-disabled' : 'uk-active'
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    accessLevel () {
      if (!this.$store.state.cases.cases[this.caseId]) return
      const accessLevel = this.$store.state.cases.cases[this.caseId].access_level
      return ['rw', 'f'].includes(accessLevel)
    },
    orderListTranslated () {
      return this.orderList.map(word => ({
        label: this.$t('cases.profile.sort.' + word),
        value: word
      }))
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.setPage(1)
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.setPage(1)
      }
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    }
  },
  methods: {
    ...mapActions('cases', [
      'getProfiles',
      'recheckCase'
    ]),
    addTag (tag) {
      if (this.query.tags.includes(tag)) {
        this.query.tags = this.query.tags.filter(el => el !== tag)
      } else {
        this.query.tags = [ ...this.query.tags, tag ]
      }
      this.update()
    },
    refreshCheck () {
      this.recheckCase(this.caseId)
        .then(() => this.$notify({
          group: 'general',
          title: this.$t('notifications.success'),
          text: this.$t('cases.recheck-scheduled'),
          type: 'success'
        }))
    },
    update () {
      if (!this.caseId) return
      this.getProfiles({
        caseId: this.caseId,
        query: this.query
      })
        .then(([profiles, total, tags]) => {
          if (this.pagination.page > 1 && !profiles.length) {
            return this.setPage(this.pagination.page - 1)
          }
          this.state.tags = tags
          this.profiles = profiles
          this.pagination.total = total
        })
        .catch(() => this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.cant-get-profiles'), type: 'error' }))
    },
    setPage (page) {
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = page
      this.update()
    }
  },
  mounted () {
    this.update()
  }
}
</script>

<style>
.uk-pagination .active a {
    color: #666;
}
</style>
