<template>
  <span class="module">
    <VueLoadImage v-if="src">
      <template v-slot:image>
        <div
          class="cover"
          :class="{
            'cover-with-loading': this.waitRenderingFinished,
            'cover-without-loading': !this.waitRenderingFinished
          }"
          :data-src="src"
          :style="`background-image: url('${src}')${waitRenderingFinished ? `, url('/img/loading.svg')` : ''}`"
        >
          <AvatarThumbnail
            :src="src"
          />
        </div>
      </template>
      <template v-slot:preloader>
        <img
          class="uk-border-circle"
          src="/img/loading.svg"
          uk-img
        />
      </template>
      <template v-slot:error>
        <img
          slot="error"
          class="uk-border-circle"
          :data-src="'/img/noavatar.png'"
          uk-img
        />
      </template>
    </VueLoadImage>
    <img
      v-else
      class="uk-border-circle"
      :data-src="'/img/noavatar.png'"
      uk-img
    />
  </span>
</template>

<script>
import VueLoadImage from 'vue-load-image'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'GeneralAvatar',
  components: {
    VueLoadImage,
    AvatarThumbnail: defineAsyncComponent(() => import('@/components/AvatarThumbnail'))
  },
  props: {
    src: {
      required: true
    },
    waitRenderingFinished: {
      type: Boolean,
      default: true
    },
    ratio: {
      required: false,
      type: Number,
      default: 3
    }
  },
  computed: {
    size () {
      let size = 30
      size = size * this.ratio
      return size + 'px'
    }
  }
}
</script>

<style scoped>
.cover {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  pointer-events: all;
}

.cover-with-loading {
  background-size: cover, cover;
  background-position: center center, center center;
}

.cover-without-loading {
  background-size: cover;
  background-position: center center;
}
</style>
<style>
  .cover:hover .with_info_button-button {
    visibility: visible;
  }
</style>
