<template>
  <div
    id="joinchat"
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h3 class="uk-modal-title">
        {{ $t('whatsapp.whatsApp-joining-chat') }}
      </h3>
      <div class="uk-card-body uk-padding-remove-top">
        <div v-if="!isLoaded" >
          <Loader />
        </div>
        <div v-else>
          <div v-if="answer.group_joined">
            <span>
              {{ $t('whatsapp.group-joined-successfully') }}
            </span>
            <br/>
            <span>
              {{ $t('whatsapp.users-in-group') }}: <router-link
                class="uk-modal-close"
                :to="{ name: 'whatsapp_group_details', params: { id: String(answer.group_info.id) }}"
              >
                {{ amount }}
              </router-link>
            </span><br/>
            <span>
              {{ $t('whatsapp.owner') }}: <router-link
                class="uk-modal-close"
                :to="{ name: 'phoneResults', params: { phone: owner }}"
              >
                {{ owner }}
              </router-link>
            </span><br/>
            <span>
              {{ $t('whatsapp.id') }}Id: <router-link
                class="uk-modal-close"
                :to="{ name: 'whatsapp_group_details', params: { id: String(answer.group_info.id) }}"
              >
                {{ answer.group_info.id }}
              </router-link>
            </span><br/>
            <span>
              {{ $t('whatsapp.name') }}: {{ answer.group_info.subject }}
            </span><br/>
          </div>
          <div v-else>
            <span>
              {{ $t('whatsapp.problem-joining-group') }}
            </span>
            <span
              v-for="error in answer.errors"
              :key="error"
            >
              {{ error }}
            </span><br/>
            <span>
              {{ $t('whatsapp.id') }} <router-link
                class="uk-modal-close"
                :to="{ name: 'whatsapp_group_details', params: { id: String(answer.group_info.id) }}"
              >
                {{ answer.group_info.id }}
              </router-link>
            </span><br/>
            <span>
              {{ $t('whatsapp.name') }} {{ answer.group_info.subject }}
            </span><br/>
          </div><br/><br/>
        </div>
        <button
          class="uk-modal-close uk-button uk-button-secondary"
          type="button"
        >
          {{ $t('whatsapp.close') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/TGCP/Loader'
import UIkit from 'uikit'

export default {
  name: 'Joinchatresult',
  components: {
    Loader
  },
  props: {
    joinlink: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      answer: {},
      loading: false
    }
  },
  watch: {
    joinlink: function (from, to) {
      this.update()
    }
  },
  computed: {
    isLoaded: function () {
      return !this.loading
    },
    owner: function () {
      return '+' + this.answer.group_info.owner.replace('@c.us', '')
    },
    amount: function () {
      return this.answer.group_info.participants.length
    }
  },
  methods: {
    closeMe () {
      UIkit.modal('#joinchat').hide()
    },
    update () {
      const action = 'whatsapp/joinChat'
      let caller = this
      caller.loading = true
      this.$store.dispatch(action, this.joinlink).then(function (data) {
        caller.answer = data
      }).finally(function () {
        caller.loading = false
      })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
