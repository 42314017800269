import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      createNewProfileItem: null,
      currentProfileId: null,
      profilesInCurrentCase: [],
      searchProfilesInCase: '',
      selectedUserCards: {},
      selectMode: 'normal',
      refreshProfileList: false
    }
  },
  watch: {
    currentCase (newCase, oldCase) {
      if (newCase === oldCase) return
      if (this.selectMode !== 'normal') {
        this.refreshProfileList = true
      }
    }
  },
  computed: {
    ...mapGetters('cases', [
      'currentCase'
    ]),
    countSelectedCards () {
      return Object.keys(this.selectedUserCards).length
    },
    profilesInCase () {
      return [ this.createNewProfileItem, ...this.profilesInCurrentCase ]
    },
    profileName () {
      // Must be overridden inside components using this mixin
      return ''
    }
  },
  methods: {
    ...mapActions('cases', [
      'profileAdd',
      'getProfiles',
      'attachUserCardsToProfile'
    ]),
    createProfile () {
      if (this.selectMode === 'normal') {
        this.selectMode = 'selected'
      } else {
        this.refreshProfileList = false
        this.selectMode = 'normal'
        let caller = this
        let root = this.$root
        const cards = Object.values(this.selectedUserCards)
        const profileId = (this.currentProfileId) ? this.currentProfileId.value : -1
        if (cards && cards.length > 0) {
          this.attachUserCardsToProfile({
            cards: cards,
            profileName: this.profileName,
            caseId: this.currentCase,
            profileId: profileId
          }).then((res) => {
            root.$notify({
              group: 'general',
              title: res.status === 'created' ? root.$t('cases.profile.profile-was-created') : root.$t('cases.profile.profile-was-updated'),
              type: 'success',
              ignoreDuplicates: true,
              data: {
                primaryCallback: () => Promise.resolve().then(() => {
                  root.$router.push(`/cases/${caller.currentCase}/profile/${res.profileId}`)
                }),
                primaryText: root.$t('cases.profile.go-to-profile')
              }
            })
          }).finally(() => {
            if (caller) {
              caller.profilesInCurrentCase = []
            }
          })
        } else {
          caller.profilesInCurrentCase = []
          this.$notify({
            group: 'general',
            title: this.$t('cases.error'),
            type: 'error',
            ignoreDuplicates: true,
            text: this.$t('cases.empty-card-list')
          })
        }
      }
    },
    async loadProfiles (search, offset = 0, count = 20) {
      try {
        this.refreshProfileList = false
        let profilesResponse
        if (!search) {
          profilesResponse = await this.getProfiles({ query: { offset: offset, limit: count } })
        } else {
          profilesResponse = await this.getProfiles({ query: { search: search, offset: offset, limit: count } })
        }
        if (profilesResponse && profilesResponse.length > 0) {
          return profilesResponse[0].map(item => {
            return { value: parseInt(item.profile_id), label: item.seed_data }
          })
        } else {
          return []
        }
      } catch (e) {
        console.error(e)
      }
      return []
    },
    toggleUserCard (data) {
      if (data.checked) {
        this.$set(this.selectedUserCards, data.user.user_id, data.user)
      } else {
        this.$delete(this.selectedUserCards, data.user.user_id)
      }
    }
  },
  mounted () {
    this.currentProfileId = {
      value: -1,
      label: this.$t('cases.profile.create-profile')
    }
    this.createNewProfileItem = this.currentProfileId
  }
}
