const START = '_N_'
const END = '_N_'
const A = /<a[^><]+>.+<\/a>/gi
const URI = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-z]{1,10}\b([-a-zA-Z0-9@:%_\\+.~#?!&//=]*)/gi
const EMAIL = /[^\s]+@[^\s]+\.[^\s]+/g
const NAME = /@[^\s]+/g
const HASH = /#[^\s]+/g
const processor = () => {
  let index = 0
  let memory = []
  const key = index => `${START}${index}${END}`
  const code = value => {
    try {
      memory[index] = value
      const current = key(index)
      index++
      return current
    } catch (error) {
      console.debug(error)
    }
  }
  return {
    decode: value => value
    .replace(A, a => code(a))
    .replace(URI, uri => code(uri))
    .replace(EMAIL, email => code(email))
    .replace(NAME, name => code(name))
    .replace(HASH, hash => code(hash)),
    encode: value => {
      let accumulator = value
      try {
        for (const index in memory) {
          accumulator = accumulator
            .replace(key(index), memory[index])
        }
      } catch (error) {
        console.debug(error)
      }
      return accumulator
    }
  }
}

export default processor

