<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header my-card-header">
        <h3 class="uk-card-title uk-text-left">
          <Resync
            v-if="user.user_name"
            class="uk-margin-right"
            :username="user.user_name"
          />
          {{ $t('twitter.user_details.general_info_title') }}
        </h3>
      </div>
      <div class="uk-card-body my-card-body">
        <div
          class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small"
        >
          <AddStoredButton
            provider="twitter"
            type="account"
            :data="user"
          />
        </div>
        <ul uk-tab>
          <li class="uk-active">
            <a
              id="current_tab"
              href="#current_tab"
            >
              {{ $t('tgcp.tasks.current-data') }}
            </a>
          </li>
          <li v-if="history.length">
            <a
              id="history_tab"
              href="#history_tab"
            >
              {{ $t('tgcp.tasks.historical-data') }}
            </a>
          </li>
          <li
            v-if="linkedFrom.length > 0"
          >
            <a
              href="#linked-from_tab"
            >
              {{ $t('tgcp.channel_details.linked_from') }}
            </a>
          </li>
          <li
            v-if="linksTo.length > 0"
          >
            <a
              href="#links-to_tab"
            >
              {{ $t('tgcp.channel_details.links_to') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li class="uk-active">
            <a name="current_tab" />
            <div
              class="uk-grid"
              data-uk-grid-margin
            >
              <div class="uk-width-1-4@m">
                <AvatarList
                  v-if="avatarCdnWithoutNull(user).avatars.length"
                  :data="avatarCdnWithoutNull(user)"
                />
                <Avatar
                  v-else
                  :avatar="user.avatar_url"
                />
              </div>
              <div class="uk-width-expand@m uk-text-left">
                <h3 class="uk-margin-remove-bottom">
                  {{ user.screen_name }}
                </h3>
                <h4
                  class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted"
                >
                  #{{ user.id }}
                </h4>
                <h4
                  v-if="user.user_name"
                  class="uk-margin-remove-top uk-margin-remove-botto uk-text-muted uk-text-small"
                >
                  <router-link
                    :to="{ name: 'nicknameResults', params: { nickname: user.user_name } }"
                  >@{{ user.user_name }}</router-link>
                  <a
                    :href="`https://twitter.com/${user.user_name}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    :uk-tooltip="$t('twitter.user_details.open-in-twitter')"
                    class="uk-margin-small-left"
                  >
                    <font-awesome-icon :icon="['fab', 'twitter']" />
                  </a>
                </h4>
                <p
                  v-if="user.is_verified"
                  class="uk-text-success"
                >
                  <span uk-icon="icon: check"/> {{ $t('twitter.user_details.verified') }}
                </p>
                <p v-if="user.is_private">
                  <span uk-icon="icon: lock"/> {{ $t('twitter.user_details.private') }}
                </p>
                <p
                  v-if="user.is_deleted"
                  class="uk-text-danger"
                >
                  <span uk-icon="icon: ban"/> {{ $t('twitter.user_details.deleted') }}
                </p>

                <div uk-grid>
                  <div class="uk-width-1-3 uk-text-break">
                    <p
                      v-html="extractedDataBio"
                      class="uk-margin-remove-bottom"
                    />
                    <button
                      class="uk-button-text uk-button uk-text-small uk-text-muted"
                      @click="translateDescription"
                    >
                      <font-awesome-icon :icon="['fa', 'language']" />
                      <span
                        v-if="translated['description']"
                      >
                        {{ $t('twitter.user_details.show-original') }}
                      </span>
                      <span v-else>
                        {{ $t('twitter.user_details.translate') }}
                      </span>
                    </button>
                    <ul class="uk-list uk-text-small">
                      <li v-if="user.url">
                        {{ $t('twitter.user_details.url') }}:
                        <a
                          :href="user.url"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ user.url }}
                        </a>
                      </li>
                      <li
                        v-if="user.location"
                      >
                        {{ $t('twitter.user_details.location') }}: {{ user.location }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.account-created') }}: {{ user.created_at ? dayjs(user.created_at) : $t('tgcp.user_details.never') }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.account-synced') }}: {{ user.sync_date ? dayjs(user.sync_date) : $t('tgcp.user_details.never') }}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <ul
                      v-if="stats"
                      class="uk-list uk-text-small"
                    >
                      <li>
                        {{ $t('twitter.user_details.followers') }}: {{ stats.followers_count }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.following') }}: {{ stats.follows_count }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.post_count') }}: {{ stats.post_count }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.likes_count') }}: {{ stats.likes_count }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li v-if="history.length">
            <a name="history_tab" />
            <HistoricalData :historical="history" />
          </li>
          <li v-if="linkedFrom.length > 0">
            <a
              name="linked-from_tab"
            />
            <Relations
              :data="linkedFrom"
            />
          </li>
          <li v-if="linksTo.length > 0">
            <a
              name="links-to_tab"
            />
            <Relations
              :data="linksTo"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '@/components/Twitter/Avatar'
import AvatarList from '@/components/Twitter/AvatarList'
import extractData from '@/utils/dataExtraction'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import translate from '@/mixins/translate'
import HistoricalData from '@/components/Twitter/UserDetails/HistoricalData.vue'
import {mapActions, mapGetters} from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TwitterUserGeneralInfo',
  components: {
    Avatar,
    AddStoredButton,
    HistoricalData,
    AvatarList,
    Resync: defineAsyncComponent(() => import('@/components/Twitter/UserDetails/Resync.vue')),
    Relations: defineAsyncComponent(() => import('@/components/Relations.vue'))
  },
  mixins: [translate],
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      history: [],
      translated: {}
    }
  },
  computed: {
    ...mapGetters('twitter', ['linkedFrom', 'linksTo']),
    extractedDataBio () {
      if (!this.user) return
      return extractData(this.localUser.description)
    },
    localUser () {
      return { ...this.user }
    },
    stats () {
      return this.$store.state.twitter.userStats[this.user.id]
    }
  },
  methods: {
    ...mapActions('twitter', [
      'fetchUserHistory',
      'fetchUserRelations'
    ]),
    avatarCdnWithoutNull (user) {
      try {
        const avatars = user.avatars.filter(avatar => avatar['avatar_cdn_path'])
        return {
          ...user,
          avatars
        }
      } catch (error) {
        console.debug(error)
      }
    },
    async translateDescription () {
      const { description } = await this.translateProps(['description'], this.localUser, this.user)
      this.localUser.description = description || this.localUser.description
    }
  },
  created () {
    try {
      this.fetchUserHistory(this.user.id)
        .then(history => {
          this.history = history
        })
      if (typeof this?.user?.id !== 'undefined') {
        this.fetchUserRelations(this.user.id)
      }
    } catch (error) {
      console.debug(error)
    }
  }
}
</script>
<style scoped>
  .my-card-header {
    border-bottom: 0;
    padding-bottom: 0
  }
  .my-card-body {
    padding-top: 0
  }
</style>
