<template>
  <Accounts
    :identity="user.username"
    type="tgcp"
  />
</template>

<script>
import Accounts from '@/views/GlobalSearch/Accounts/Accounts'

export default {
  name: 'TgcpUserDetailsAccounts',
  components: {
    Accounts
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  computed: {
    user () {
      return this.$store.state.tgcp.users[this.id]
    }
  }
}
</script>

<style scoped>

</style>
