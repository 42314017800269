<template>
  <div>
    <SearchBar/>
    <div v-if="keys.length === 0">
      <h1 class="uk-margin-remove-vertical">
        {{ $t('crypto.general.nothing-found') }}
      </h1>
    </div>
    <div 
      v-else 
      class="uk-flex uk-flex-column"
    >
      <h1 class="uk-margin-remove-vertical">
        {{ $t('crypto.route.search') }}
      </h1>
      <SearchTable 
        :keys="keys" 
        :search="search" 
        :default-length="20"
      />
    </div>
  </div>
</template>
    
<script>
import SearchBar from '@/components/Crypto/Ton/Search/SearchBar'
import SearchTable from '@/components/Crypto/Ton/Search/Table'
import { mapGetters } from 'vuex'
  
export default {
  name: 'TonSearchResults',
  components: {
    SearchTable,
    SearchBar
  },
  computed: {
    ...mapGetters('crypto', ['getTonSearchResults']),
    keys () {
      return this.getTonSearchResults
    },
    search () {
      return this.$route?.query?.search? this.$route.query.search.toString() : null
    }
  }
}
</script>