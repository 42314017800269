import Base from '../Base.js'
import Schemas from '../../Schemas'

class ProfilesAll extends Base {
  schema = Schemas.profile
  OPTIONS_SCHEMA = ['messages', 'allowedFields', 'localizeFields']
  fetch = async ({ options }) => {
    return options.messages
  }
}

export default ProfilesAll
