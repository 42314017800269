<template>
  <div>
    <div class="uk-card uk-card-default uk-padding-small">
      <div class="uk-flex uk-flex-between">
        <span class="uk-float-left uk-text-muted">
          #{{ note.note_id }}
        </span>
        <span class="uk-flex uk-flex-between uk-flex-middle">
          <span class="uk-margin-small-right uk-text-muted">
            {{ dayjs(note.updatedAt) }}
          </span>
          <button
            class="uk-button uk-button-text"
            uk-icon="trash"
            @click.prevent="remove"
          />
        </span>
      </div>
      <div class="note-text uk-margin-small-top uk-text-break">
        <VueMarkdown
          :html="false"
          :source="moreShown ? note.note_text : text"
          :prerender="cutHtml"
          :postrender="externalLinksFilter"
        />
      </div>
      <span
        v-if="more"
        class="uk-button uk-button-text"
        @click="moreShown = !moreShown"
      >
        {{ moreShown ? $t('providers.show-less') : $t('providers.show-more') }}
      </span>
    </div>
    <div
      ref="editNoteModal"
      uk-modal
    >
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">
          {{ $t('cases.notes.edit_note_title') }}
        </h2>
        <div class="uk-modal-body">
          <textarea
            v-model.trim="state.text"
            class="uk-textarea"
            rows="5"
          />
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button
            class="uk-button uk-button-default uk-modal-close  uk-margin-small-right"
            type="button"
            @click="cancel"
          >
            {{ $t('cases.notes.cancel_button') }}
          </button>
          <button
            class="uk-button uk-button-primary"
            type="button"
            @click="confirm"
          >
            {{ $t('cases.notes.update_button') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import UIkit from 'uikit'
import externalLinksFilter from '@/filters/externalLinksFilter'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'NoteCard',
  components: {
    VueMarkdown: defineAsyncComponent(() => import(/* webpackChunkName: "markdown3" */ 'vue3-markdown-it'))
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    externalLinksFilter: {
      type: Function,
      default: externalLinksFilter
    }
  },
  data () {
    return {
      state: {
        text: this.note.note_text
      },
      moreShown: false
    }
  },
  computed: {
    text () {
      return this.more ? this.note.note_text.slice(0, this.dropdown ? 200 : 400) + '...' : this.note.note_text
    },
    more () {
      return this.dropdown ? this.note.note_text.length > 300 : this.note.note_text.length > 600
    }
  },
  methods: {
    ...mapActions('cases', [
      'deleteNote',
      'editNote'
    ]),
    ...mapMutations('cases', [
      'changeNoteText'
    ]),
    async remove () {
      await this.deleteNote({ caseId: this.note.case_id, noteId: this.note.note_id })
      this.$emit('update')
    },
    showModal () {
      UIkit.modal(this.$refs.editNoteModal).show()
    },
    cancel () {
      UIkit.modal(this.$refs.editNoteModal).hide()
    },
    async confirm () {
      try {
        await this.editNote({ noteId: this.note.note_id, caseId: this.note.case_id, text: this.state.text })
        UIkit.modal(this.$refs.editNoteModal).hide()
      } catch {
        this.$notify({
          group: 'general',
          title: this.$t('notifications.error'),
          text: this.$t('cases.error'),
          type: 'error'
        })
      }
    },
    cutHtml (html) {
      return html && html.replace(/(<([^>]+)>)/gi, '')
    }
  }
}
</script>

<style>
.note-text {
  white-space: pre-line;
}
</style>
