<template>
  <div
    class="home uk-margin-top"
  >
    <div
      class="uk-grid-match"
      uk-grid
    >
      <div
        class="uk-width-1-1"
      >
        <TasksList
          :key="updated"
          class="uk-card uk-card-body uk-card-default uk-width-1-1"
        />
      </div>
      <div class="uk-width-1-2 uk-margin-top">
        <AddTask @added="updated = !updated" />
      </div>
      <div class="uk-width-1-2 uk-margin-top">
        <AddBatchTasks
          class="uk-card uk-card-default uk-card-body"
          @added="updated = !updated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TasksList from '@/components/Discord/Tasks/List.vue'
import AddTask from '@/components/Discord/Tasks/AddTask.vue'
import AddBatchTasks from '@/components/Discord/Tasks/AddBatchTasks.vue'

export default {
  name: 'DiscordTasks',
  components: {
    TasksList,
    AddTask,
    AddBatchTasks
  },
  data () {
    return {
      updated: false
    }
  }
}
</script>
