<template>
  <div class="channels">
    <router-view />
    <router-view
      name="helper"
      class="us__content us__content--helper"
    />
  </div>
</template>

<script>
export default {
  name: 'Channels'
}
</script>
