import langs from 'langs'
import countries from '@/utils/countryCodesNamesFull.json'
import categories from '@/utils/categories.json'

const numberFormat = value => {
  if (!value) return '0'
  return new Intl.NumberFormat().format(value)
}

const normalizeWAPhone = value => {
  if (!value) return ''
  value = value.replace('@c.us', '')
  if (value.indexOf('+') !== 0) value = '+' + value
  return value
}

const langCode = value => {
  if (!value) return ''
  if (value.length > 3) return ''
  let lang = ''
  if (value.length === 3) lang = langs.where('3', value)
  if (value.length === 2) lang = langs.where('1', value)
  if (!lang) return ''
  return lang.name
}

const categoryCodeToName = value => {
  if (!value) return ''
  return categories[value]['name']
}
const countryCodeToName = value => {
  if (!value) return ''
  try {
    let cname = countries[value.toUpperCase()]
    if (cname) return cname
  } catch (e) {}
  return value
}

const size = size => {
  if (!size) return '0'
  if (size < 1024) return size + ' B'
  let i = Math.floor(Math.log(size) / Math.log(1024))
  let num = (size / Math.pow(1024, i))
  let round = Math.round(num)
  num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
  return `${num} ${'KMGTPEZY'[i - 1]}B`
}

const capitalize = s => {
  if (!s || !s.length) return s
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export {
  numberFormat,
  normalizeWAPhone,
  langCode,
  countryCodeToName,
  categoryCodeToName,
  size,
  capitalize
}
