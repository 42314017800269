<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            class="uk-display-inline-block"
          >
            <EntityTip
              namespace="discord"
              type="User"
            >
              <template #input>
                <div class="uk-flex">
                  <input
                    v-model="query.search"
                    type="text"
                    :placeholder="$t('tgcp.channels.search_placeholder')"
                    class="uk-input uk-form-width-small uk-form-width-medium"
                  />
                  <button
                    type="submit"
                    class="uk-button uk-button-primary uk-search-icon"
                  >
                    <span uk-search-icon />
                  </button>
                </div>
              </template>
            </EntityTip>
          </div>
          <div class="uk-display-inline-block">
            {{ $t('twitter.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block">
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div
        v-if="hasSearchFields" 
        class="uk-display-inline-block uk-margin-small-top"
        style="padding-left: 50px;"
        uk-grid
      >
        <div 
          class="uk-form-small uk-display-inline-block"
        >
          <label 
            v-for="field in searchList"
            :key="field + type"
          >
            <input
              v-model="query.search_fields"
              class="uk-checkbox uk-margin-left"
              type="checkbox"
              :value="field"
            />
            {{ $t(`discord.user_details.${field}`) }}
          </label>
        </div>
      </div>
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <template v-else>
          <Item
            :users="users"
            class="uk-margin-top"
          />
        </template>
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'

export default {
  name: 'DiscordUserList',
  components: {
    EntityTip: defineAsyncComponent(() => import('@/components/EntityTip/Tip.vue')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Discord/UserList/Item')),
    Paginate,
    vueSelect,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'guild'].includes(val)
    }
  },
  data () {
    return {
      users: [],
      searchList: ['username', 'global_name'],
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || 'asc',
        search: this.$route.query.search || '',
        search_fields: []
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.update()
    },
    'query.search' : {
      immediate: false,
      handler (value) {
        globalThis.eventBus.$emit('EntityTip.id', value)
      }
    }
  },
  computed: {
    ...mapGetters('discord', ['isLoaded']),
    orderList () {
      switch (this.type) {
          case 'all': return ['id', 'username', 'global_name']
          case 'guild': return ['id']
          default: return ['id']
      }
    },
    hasDataToView () {
      return this.users.length > 0
    },
    hasSearchFields () {
      return this.type === 'all'
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded[`${this.type}_${this.id}_users`] : this.isLoaded['users']
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.searchHandler()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('discord.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('discord.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('discord.sort.sort_type_desc') }
      ]
    }
  },
  methods: {
    ...mapActions('discord', ['fetchAllUsers', 'fetchGuildUsers', 'addParamToQuery']),
    init () {
      const page = Math.floor(this.query.offset / this.query.limit + 1)
      this.setPage(page)

      const searchFields = this.$route.query.search_fields
      if (searchFields) this.query.search_fields = searchFields && Array.isArray(searchFields) ? searchFields : [searchFields]
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setData (users) {
      this.users = users.data
      this.pagination.total = users.meta.results_count
    },
    searchHandler () {
      if (!this.hasSearchFields) {
        const {
          search_fields,
          ...withoutSearchFields
        } = this.query
        this.query = withoutSearchFields
      }
      this.setPage(1)
    },
    handleError (err) {
      this.users = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    update () {
      let { type } = this
      const data = {
        id: this.id,
        query: { ...this.query }
      }
      if (type === 'all') {
        this.addParamToQuery(this.query)
      }
      const opts = {
        all: ['fetchAllUsers', data.query],
        guild: ['fetchGuildUsers', { query: data.query, id: data.id }]
      }
      const [method, payload] = opts[type]

      return this[method](payload)
        .then(users => this.setData(users))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>
