<script>
import { toBase64Web, opToHex } from '@/utils/ton-filters'
import TableDateCell from '@/components/Crypto/Ton/Atoms/TableDateCell'
import StatusCell from '@/components/Crypto/Ton/Atoms/StatusCell'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import BalanceCell from '@/components/Crypto/Ton/Atoms/BalanceCell'

export default {
  name: 'TonMessageLine',
  components: {
    TableDateCell,
    StatusCell,
    AddressField,
    BalanceCell
  },
  props: {
    msg: {
      type: Object,
      required: true
    }
  },
  methods: {
    toBase64Web,
    opToHex
  }
}
</script>

<template>
  <tr v-if="msg">
    <td>
      <div 
        class="uk-flex" 
        style="gap: 4px"
      >
        <div 
          v-if="msg.src_tx_key" 
          class="uk-margin-remove"
        >
          <router-link 
            :to="{ name: 'crypto_ton_transaction_info', params: { id: toBase64Web(msg.src_tx_key) }, hash: '#overview'}" 
            class="uk-text-truncate"
          > 
            {{ 'src' }}
          </router-link>
        </div>
        <div v-else
             class="uk-margin-remove"
        >
          <div>
            {{ $t('crypto.general.none').toLowerCase() }}
          </div>
        </div>
        {{ '/' }}
        <div 
          v-if="msg.dst_tx_key" 
          class="uk-margin-remove"
        >
          <router-link 
            :to="{ name: 'crypto_ton_transaction_info', params: { id: toBase64Web(msg.dst_tx_key) }, hash: '#overview'}" 
            class="uk-text-truncate"
          >
            {{ 'dst' }}
          </router-link>
        </div>
        <div 
          v-else 
          class="uk-margin-remove"
        >
          <div>
            {{ $t('crypto.general.none').toLowerCase() }}
          </div>
        </div>
      </div>
    </td>
    <td>
      <StatusCell :status="msg.type"/>
    </td>
    <td class="uk-text-truncate uk-text-left">
      {{ msg.operation_name? msg.operation_name : 
        ( msg.operation_id? `op=0x${opToHex(msg.operation_id ?? 0)}` : "&#8203;") }}
    </td>
    <td class="uk-text-truncate"> 
      <div 
        class="uk-flex uk-flex-column uk-text-left" 
        style="gap: 24px"
      >
        <AddressField 
          :addr="msg.src_address ?? null" 
          :break_word="false"
        />
        <div 
          v-if="msg.src_contract"
          class="uk-text-nowrap uk-text-left" 
        >   
          {{ msg.dst_contract }}
        </div>
        <div v-else>
          {{ "&#8203;" }}
        </div>
      </div>
    </td>
    <td class="uk-text-truncate"> 
      <div 
        class="uk-flex uk-flex-column uk-text-left" 
        style="gap: 24px"
      >
        <AddressField 
          :addr="msg.dst_address ?? null" 
          :break_word="false"
        />
        <div 
          v-if="msg.dst_contract"
          class="uk-text-nowrap uk-text-left" 
        >   
          {{ msg.dst_contract }}
        </div>
        <div v-else>
          {{ "&#8203;" }}
        </div>
      </div>
    </td>
    <td>
      <div 
        class="uk-flex uk-flex-column" 
        style="gap: 24px"
      >
        <BalanceCell 
          :balance="msg.amount ?? 0n"
          :place="'end'"
        />
        <BalanceCell 
          :balance="msg.fwd_fee ? msg.fwd_fee : 0"
          :place="'end'"
          :full="true"
          :tooltip="'fwd_fee'"
        />
      </div>
    </td>
    <td>
      <TableDateCell :date-time="msg.created_at"/>
    </td>  
  </tr>
</template>