const REG_EXP = /(https?:)?\/\/twitter\.com\/(?<userName>[^/]+)/i

const parse = value => {
  if (
    typeof value === 'string' &&
    REG_EXP instanceof RegExp
  ) {
    return value.match(REG_EXP)
  }
}

const groups = value => {
  if (
    typeof value === 'string' &&
    parse instanceof Function
  ) {
    return parse(value)?.groups
  }
}

export const userName = value => {
  if (
    typeof value === 'string' &&
    groups instanceof Function
  ) {
    return groups(value)?.userName
  }
}
