<template>
  <div class="uk-margin-top">
    {{ $t('search.by_nickname_title') }} <b>
      {{ cleaned_nickname }}
    </b> <br/>
    <div
      class="uk-grid uk-margin-top"
      data-uk-grid-margin
    >
      <div class="uk-width-expand">
        <loader v-if="loading" />
        <div
          v-else
          class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid"
        >
          <div
            v-for="item in cards"
            :key="item.getId()"
            class=" uk-grid-margin"
          >
            <usercard
              :key="item.getId()"
              :generalUser="item"
              :showAddProfileButton="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Threema from '@/utils/providers/threema'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Nicknames',
  components: {
    'usercard': defineAsyncComponent(() => import('@/components/UserCard2')),
    'loader': defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  props: {
    nickname: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      loading: false,
      cards: [],
      details: {}
    }
  },
  computed: {
    ...mapGetters('fetcher', [
      'nickList'
    ]),
    cleaned_nickname: function () {
      if (this.nickname[0] === '@') {
        return this.nickname.replace('@', '')
      }
      return this.nickname
    }
  },
  methods: {
    ...mapActions('threema', [
      'fetchProfile'
    ]),
    updateDetails (raw) {
      this.details = raw
    },
    async update () {
      try {
        this.loading = true
        const cards = await this.fetchProfile({ searchData: this.cleaned_nickname, searchType: 'identity' })
        this.cards = cards.map(el => Threema.formatData(el))
      } catch {
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
