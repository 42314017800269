<template>
  <div>
    <Loader v-if="!isLoaded['user_' + id]"/>
    <div v-else>
      <NotFound v-if="user.answer_status==='NOT_FOUND'" />
      <div v-else>
        <div class="uk-width-1-1">
          <gi :user="user" />
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <ul uk-tab>
          <li
            class="uk-flex"
            :class="checkForTab('overview_tab')"
          >
            <a
              id="overview_tab"
              class="uk-padding-remove"
              href="#overview_tab"
              @click="activateTab('overview_tab')"
            >
              {{ $t('twitter.channel_details.overview_title') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('accounts_tab')"
          >
            <a
              id="accounts_tab"
              class="uk-padding-remove"
              href="#accounts_tab"
              @click="activateTab('accounts_tab')"
            >
              {{ $t('twitter.channel_details.accounts') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('posts_tab')"
          >
            <a
              id="posts_tab"
              class="uk-padding-remove"
              href="#posts_tab"
              @click="activateTab('posts_tab')"
            >
              {{ $t('tiktok.user_details.posts_count') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.posts_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('comments_tab')"
          >
            <a
              id="comments_tab"
              class="uk-padding-remove"
              href="#comments_tab"
              @click="activateTab('comments_tab')"
            >
              {{ $t('tiktok.user_details.comments_count') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.comments_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('following_tab')"
          >
            <a
              id="following_tab"
              ref="following_tab"
              class="uk-padding-remove"
              href="#following_tab"
              @click="activateTab('following_tab')"
            >
              {{ $t('twitter.channel_details.following') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.following_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('follower_tab')"
          >
            <a
              id="follower_tab"
              ref="follower_tab"
              class="uk-padding-remove"
              href="#follower_tab"
              @click="activateTab('follower_tab')"
            >
              {{ $t('twitter.channel_details.followers') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.follower_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('friend_tab')"
          >
            <a
              id="friend_tab"
              class="uk-padding-remove"
              href="#friend_tab"
              @click="activateTab('friend_tab')"
            >
              {{ $t('twitter.channel_details.friends') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.friend_count }}
              </span>
            </span>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li :class="checkForTab('overview_tab')">
            <a name="overview_tab"/>
            <uthg :id="id" />
            <udg :id="id" />
            <uwg :id="id" />
            <umg :id="id" />
          </li>
          <li :class="checkForTab('accounts_tab')">
            <a name="accounts_tab"/>
            <Accounts
              :identity="user.unique_id"
              type="tiktok"
              @opendetails="showSidebar"
            />
          </li>
          <li
            v-if="user.posts_count"
            :class="checkForTab('posts_tab')"
          >
            <a name="posts_tab"/>
            <MessageList
              v-if="activeTab === 'posts_tab'"
              :id="id"
              type="user"
            />
          </li>
          <li
            :class="checkForTab('comments_tab')"
          >
            <a name="comments_tab"/>
            <CommentsList
              v-if="activeTab === 'comments_tab'"
              :id="id"
              type="userComments"
            />
          </li>
          <li
            v-if="user.following_count"
            :class="checkForTab('following_tab')"
          >
            <a name="following_tab"/>
            <UserList
              v-if="activeTab === 'following_tab'"
              :id="id"
              type="following"
            />
          </li>
          <li
            v-if="user.follower_count"
            :class="checkForTab('follower_tab')"
          >
            <a name="follower_tab"/>
            <UserList
              v-if="activeTab === 'follower_tab'"
              :id="id"
              type="follower"
            />
          </li>
          <li
            v-if="user.friend_count"
            :class="checkForTab('friend_tab')"
          >
            <a name="friend_tab"/>
            <UserList
              v-if="activeTab === 'friend_tab'"
              :id="id"
              type="friends"
            />
          </li>
        </ul>
      </div>
    </div>
    <RightSidebar>
      <ProviderDetails
        v-if="detail"
        :data="detail"
        :raw="detail.raw"
      />
    </RightSidebar>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import RightSidebar from '@/components/RightSidebar'
import UserTopHashesGraph from '@/components/Tiktok/UserDetails/UserTopHashesGraph'
import UserDayGraph from '@/components/Tiktok/UserDetails/UserDayGraph'
import UserWeekGraph from '@/components/Tiktok/UserDetails/UserWeekGraph'
import UserYearGraph from '@/components/Tiktok/UserDetails/UserYearGraph'
import GeneralInformation from '@/components/Tiktok/UserDetails/GeneralInformation'
import UserList from '@/views/Tiktok/UserList'
import CommentsList from '@/views/Tiktok/CommentsList'
import MessageList from '@/views/Tiktok/MessageList'
import Accounts from '@/views/GlobalSearch/Accounts/Accounts'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'


export default {
  name: 'TiktokUserDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    Loader,
    uthg: UserTopHashesGraph,
    udg: UserDayGraph,
    uwg: UserWeekGraph,
    umg: UserYearGraph,
    gi: GeneralInformation,
    CommentsList,
    MessageList,
    UserList,
    Accounts,
    RightSidebar
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      detail: null
    }
  },
  watch: {
    id (to) {
      this.update()
    },
    activeTab (newTab) {
      const [tab] = newTab.split('_')
      if (newTab === 'comments_tab') this.$refs[newTab].click()
      if (!this.user[tab + '_count'] && tab !== 'accounts') {
        this.activateTab('overview_tab')
      }
      if (['follows_tab', 'followers_tab'].includes(newTab)) {
        this.$refs[newTab].click()
      }
    }
  },
  computed: {
    ...mapGetters('tiktok', [
      'allUsers',
      'isLoaded',
      'userStats'
    ]),
    activeTab () {
      return this.$route.query.tab || 'overview_tab'
    },
    user () {
      return this.allUsers[this.id]
    },
    getStats () {
      return this.userStats[this.id]
    },
    isLoadedStats () {
      return this.isLoaded['user_' + this.id + '_stats']
    }
  },
  methods: {
    ...mapActions('tiktok', [ 'fetchForceUserById', 'fetchForceUserStatsById' ]),
    checkForTab (tabName) {
      const activeTab = this.activeTab
      const [tab] = tabName.split('_')
      if (tabName === activeTab) {
        return 'uk-active'
      }
      if (tabName === 'comments_tab') return ''
      if (!this.user[tab + '_count'] && tab !== 'accounts' && tab !== 'overview') return 'uk-disabled'
      else return ''
    },
    activateTab (tab) {
      tab && this.$router.push({ query: { tab } })
    },
    update () {
      this.fetchForceUserById(this.id) &&
        this.fetchForceUserStatsById(this.id)
    },
    showSidebar (value) {
      this.detail = value
    }
  },
  mounted () {
    this.update()
  }
}
</script>
