<template>
  <div
    style="padding-bottom: 20px"
    class="uk-margin-top"
  >
    <div class="uk-width-1-1">
      <span>
        <button
          v-if="currentCase"
          class="uk-margin-left uk-button-primary uk-button uk-width-1-6"
          @click="createProfile"
        >
          {{ selectMode === 'normal' ? $t('cases.profile.add-profile') : $t('cases.confirm') + ' (' + countSelectedCards + ') ' + $t('cases.items') }}
        </button>
        <VueServerSelect
          v-if="currentCase && selectMode === 'selected'"
          v-model="currentProfileId"
          :defaultOptions="[ createNewProfileItem ]"
          :loadDataPortion="loadProfiles"
          :refreshList="refreshProfileList"
          class="uk-display-inline-block uk-margin-small-left uk-form-width-small uk-form-width-medium uk-text-small"
        />
        <span
          class="uk-margin-small-left uk-width-1-6"
        >
          <Passwords
            type="email"
            :search="email"
            :initialValues="passwords[email]"
            :isOverLimit="isOverLimits[email]"
          />
        </span>
      </span>
    </div>
    <div
      v-if="possible"
      class="uk-margin-small-top"
    >
      {{ $t('threema.possible-' + possible.type) }}:
      <i>
        {{ possible.data }}
      </i>
      <router-link
        :to="{ name: possible.type + 'Results', params: { [possible.type]: possible.data } }"
        class="uk-icon-link uk-margin-small-left uk-icon"
        uk-icon="search"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          data-svg="search"
        >
          <circle
            fill="none"
            stroke="#000"
            stroke-width="1.1"
            cx="9"
            cy="9"
            r="7"
          />
          <path
            fill="none"
            stroke="#000"
            stroke-width="1.1"
            d="M14,14 L18,18 L14,14 Z"
          />
        </svg>
      </router-link>
    </div>
    <div
      class="uk-grid uk-margin-top"
      data-uk-grid-margin
    >
      <div class="uk-width-expand ">
        <div
          class="uk-width-auto uk-align-right uk-margin-remove"
        >
          <exportCSV
            v-if="total === current"
            style="margin-top:20px; margin-bottom:0px;"
            :data="list"
            :search="$route.params.email"
          />
        </div>
        <span v-if="!providers">
          <UsedProviders
            v-if="allProviders"
            class="uk-align-right uk-width-1-4 uk-margin-small-right"
            :search="email"
            type="email"
          />
          <ul class="uk-tab">
            <li class="uk-active">
              <a href="">
                {{ $t('search.founds') }}
                <span
                  v-if="list"
                  class="uk-badge"
                >
                  {{ list.length }}
                </span>
              </a>
            </li>
            <li class="uk-disabled">
              <a>
                {{ $t('search.mentions') }}
              </a>
            </li>
          </ul>
          <ul class="uk-subnav uk-subnav-pill">
            <li :class="{ 'uk-active': !filter }">
              <a
                href="#"
                @click="filter=''"
              >
                {{ $t('search.types.all') }}
              </a>
            </li>
            <li
              v-for="item in types"
              :key="item"
              :class="{ 'uk-active': filter === item }"
            >
              <a
                href="#"
                @click="filter = item"
              >
                {{ $t('search.types.' + item) }}
                <span
                  :class="{ 'badge-inverse': filter === item }"
                  class="uk-badge uk-margin-small-left"
                >
                  {{ list.filter(card => card.genericType === item).length }}
                </span>
              </a>
            </li>
          </ul>
        </span>
        <Loader
          v-if="!isLoaded['providers']"
          class="uk-margin uk-text-center"
        />
        <div
          v-else
          class="uk-width-expand"
        >
          <progress
            v-if="total>current"
            id="js-progressbar"
            class="uk-progress"
            :value="current"
            :max="total"
          />
          <div v-if="total <= current && filteredList.length === 0">
            <NotFound v-if="current > 0 && allProviders.length" />
          </div>
          <div class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid">
            <div
              v-for="(item, id) in filteredList"
              :key="id"
              class=" uk-grid-margin"
            >
              <usercard
                :key="item.getId()"
                :generalUser="item"
                :selectMode="selectMode"
                @selected="toggleUserCard"
                @opendetails="activeItem = id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <rightSidebar>
      <ProviderDetails
        v-if="showProviderDetails"
        :data="providerSidebarData"
        :raw="rawParsed"
      />
    </rightSidebar>
    <ExportDialog
      :id="email"
      :search="filter"
      namespace="fetcher"
      type="users"
      subtype="email"
      :messages="filteredList"
      :totalCount="filteredList.length"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { default as parseMax } from 'libphonenumber-js/min'
import restrictions from '../../config/restrictions'
import { parseRaw } from '@/utils/tryParse'
import Loader from '@/components/TGCP/Loader'
import { defineAsyncComponent } from 'vue'

import UsedProviders from '@/components/GlobalSearch/UsedProviders'
import Passwords from '@/components/leaks/Offcanvas.vue'
import rightSidebar from '@/components/RightSidebar'

import AddUserCardToProfile from '@/mixins/addUserCardsToProfile'

export default {
  name: 'Emails',
  components: {
    usercard: defineAsyncComponent(() => import('@/components/UserCard2')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    VueServerSelect: defineAsyncComponent(() => import('@/components/VueServerSelect')),
    UsedProviders,
    Passwords,
    Loader,
    rightSidebar,
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    exportCSV: defineAsyncComponent(() => import('@/components/GlobalSearch/Export.vue'))
  },
  mixins: [
    AddUserCardToProfile
  ],
  props: {
    email: {
      required: true,
      type: String
    },
    providers: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      activeItem: null,
      details: {},
      filter: ''
    }
  },
  watch: {
    email (from, to) {
      this.update()
    },
    excludedSources : {
      handler (to, from) {
        if (from === null) this.update()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['excludedSources']),
    ...mapGetters('cases', [
      'currentCase'
    ]),
    ...mapGetters('fetcher', [
      'profilesCount',
      'allProviders',
      'isLoaded'
    ]),
    ...mapGetters('leaks', ['passwords', 'isOverLimits']),
    rawParsed () {
      const data = this.providerSidebarData
      if (!data) return false
      return parseRaw(data)
    },
    showProviderDetails () {
      return this.rawParsed && Object.keys(this.rawParsed).length
    },
    providerSidebarData () {
      return this.activeItem !== null && this.filteredList[this.activeItem]
    },
    types () {
      if (!this.list) return []
      return [ ...new Set(this.list.map(el => el.genericType)) ].sort()
    },
    filteredList () {
      if (!this.list) return []
      if (!this.filter) return this.list
      return this.list.filter(card => card.genericType === this.filter)
    },
    possible: function () {
      if (!this.email) return
      let splitted = this.email.split('@')
      let phone = parseMax('+' + splitted[0])
      if (phone && phone.isValid()) return { type: 'phone', data: phone.number }
      return { type: 'nickname', data: splitted[0] }
    },
    total () {
      return this.$store.state.fetcher.total
    },
    current () {
      return this.$store.state.fetcher.count
    },
    list () {
      let list = this.$store.state.fetcher.email[this.email] || []
      if (!list) return
      let set = []
      list = list.filter(card => {
        if (!card.ext_id) return true
        let i = set.findIndex(el => el.ext_id === card.ext_id)
        if (i === -1) {
          set.push({ ...card })
          return true
        } else {
          Object.assign({ ...set[i] }, { ...card })
        }
        return false
      })
      return list
    },
    profileName () {
      return this.$route.params.email
    }
  },
  methods: {
    ...mapActions('leaks', [
      'loadLeaks'
    ]),
    updateDetails (raw) {
      this.details = raw
    },
    update () {
      this.filter = ''
      this.loadLeaks({
        searchType: 'email',
        searchString: this.email
      })
      const action = 'fetcher/fetch'
      this.$store.dispatch(action, {
        search: this.email,
        type: 'email'
      })
    },
    allowLeaks () {
      let userId = this.$store.state.current_user_id
      if (restrictions[userId]) {
        if (restrictions[userId].module.indexOf('leak') > -1) {
          return false
        }
      }
      return true
    }
  },
  mounted () {
    this.update()
  }
}
</script>
