import { apiRequest, getQueryString } from '@/utils/api'
import config from '@/config'
import router from '@/router'
import zalo from '@/utils/providers/zalo'
import unicode from '@/utils/unicode'

export default {
  namespaced: true,
  state: {
    isLoaded: {},
    statsProblem: false,
    stats: {},
    users: {},
    usersForStored: {}
  },
  getters: {
    isLoaded: state => state.isLoaded
  },
  mutations: {
    resetStatsProblem: (state) => {
      state.statsProblem = false
    },
    statsProblem: (state) => {
      state.statsProblem = true
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addStats: (state, stats) => {
      state.stats = stats
    },
    fetchAllUsers: (state, users) => {
      if (!users) return
      users.map(user => {
        state.users[user.user_id] = user
      })
    },
    fetchProfileByUID: (state, user) => {
      if (!user) return
      state.users[user.uid] = user
    }
  },
  actions: {
    /*
    GET /search/uid?q=<ID пользователя>
GET /search/phone_no?q=<номер телефона в формате E.164 (с плюсом или без)>
GET /search/username?q=<имя пользователя>
GET /search/display_name?q=<имя пользователя в профиле>
Ответы в формате JSON:
{
    "users": [
        {
            "uid": <ID пользователя, число>,
            "phone_no": <номер телефона в формате E.164, строка или null>,
            "username": <строка имени пользователя или null (присутствует редко)>,
            "gender": <пол 0 - мужской, 1 - женский, может быть null>,
            "birthday": <день рождения, строка yyyy-mm-dd или mm-dd или null>,
            "display_name": <строка, имя в профиле или null>,
            "status_text": <текст в статусе или null>
        }, ...
    ]
}
     */
    fetchProfileByPhoneNumber ({ dispatch, commit, state, rootState }, searchData) {
      return new Promise((resolve, reject) => {
        apiRequest(`/users/search/phone_no?q=` + encodeURIComponent(searchData), 'GET', {}, config.servers.zalo)
          .then(data => {
            if (data.data) {
              resolve(data.data.users)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchProfileByUserName ({ dispatch, commit, state, rootState }, searchData) {
      return new Promise((resolve, reject) => {
        apiRequest(`/users/search/username?q=` + encodeURIComponent(searchData), 'GET', {}, config.servers.zalo)
          .then(data => {
            if (data.data) {
              resolve(data.data.users)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchProfileByDisplayName ({ dispatch, commit, state, rootState }, searchData) {
      return new Promise((resolve, reject) => {
        apiRequest(`/users/search/display_name?q=` + encodeURIComponent(searchData), 'GET', {}, config.servers.zalo)
          .then(data => {
            if (data.data) {
              resolve(data.data.users)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchProfileByUID ({ dispatch, commit, state, rootState }, searchData) {
      commit('startLoad', 'user_' + searchData)
      return new Promise((resolve, reject) => {
        apiRequest(`/users/search/uid?q=` + encodeURIComponent(searchData), 'GET', {}, config.servers.zalo)
          .then(res => {
            let user = res.data.users[0]
            user && commit('fetchProfileByUID', user)
            if (res.data) {
              resolve(res.data.users)
            }
          }).catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'user_' + searchData))
      })
    },
    getFeed ({ state, commit }, query) {
      commit('startLoad', 'feed_' + query.uid)
      const queryString = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/feed?` + queryString, 'GET', {}, config.servers.zalo)
          .then(res => {
            let feed = res.data
            resolve(feed)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'feed_' + query.uid))
      })
    },
    fetchStats ({ dispatch, commit, state, rootState }) {
      commit('startLoad', 'stats')
      commit('resetStatsProblem')
      apiRequest(`/stats`, 'GET', {}, config.servers.zalo)
        .then(data => {
          let stats = data.data
          commit('addStats', stats)
        })
        .catch(err => {
          commit('statsProblem')
          console.debug(err)
        })
        .finally(() => {
          commit('stopLoad', 'stats')
        })
    },
    async fetchAllUsers ({ state, commit, rootState }, query) {
      commit('startLoad', 'users')
      const queryString = query ? '?' + getQueryString(query) : ''
      try {
        const { data } = await apiRequest(`/users/list` + queryString, 'GET', {}, config.servers.zalo)
        const users = data.users.map(user => zalo.formatData(user)[0])
        commit('fetchAllUsers', users)
        return { users, meta: data.meta }
      } finally {
        commit('stopLoad', 'users')
      }
    },
    async searchUsers ({ state, commit, rootState }, params) {
      commit('startLoad', 'users')

      if (params.params?.q) {
        params.params.q = unicode.normalize(params.params.q)
      }

      const queryString = params.params ? getQueryString(params.params) : ''
      try {
        const { data } = await apiRequest(`/users/search/` + params.search_type + '?' + queryString, 'GET', {}, config.servers.zalo)
        const users = data.users.map(user => zalo.formatData(user)[0])
        commit('fetchAllUsers', users)
        return { users }
      } finally {
        commit('stopLoad', 'users')
      }
    },
    async resolveAsGeneralUser ({ state, commit, rootState }, id) {
      commit('startLoad', 'users')
      try {
        const response = await apiRequest('/id_lookup_services/zalo/?search=' + id, 'GET',{}, config.servers.fetcher_proxy)
        if (response && response.data && response.data.data && response.data.data.length > 0) {
          return response.data.data[0]
        }
      } finally {
        commit('stopLoad', 'users')
      }
      return null
    },
    addParamToQuery: (state, query) => {
      const old = router.currentRoute.value.query
      if (typeof query === 'object') {
        Object.keys(query).forEach(key => !query[key] && delete query[key])
        query = { ...old, ...query }
      }
      router.push({ query })
    }
  }
}
