// @ts-check
/**
 * @typedef { import('./Strategies/Base').Strategy } Strategy
 * @typedef {{ strategy: null|Strategy, setStrategy: (Strategy: Strategy) => void, start: () => void}} ContextStruct
 */

import Queue from './Queue.js'

/**
 * @class
 * @readonly
 * @implements ContextStruct
 */
class Context {
  /**
   * @type {null|Strategy}
   */
  strategy = null
  /**
   * @readonly
   * @constructor
   * @param {Strategy} Strategy
   */
  constructor (Strategy) {
    try {
      this.strategy = Strategy
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {Strategy} Strategy
   * @return {void}
   */
  setStrategy (Strategy) {
    try {
      this.strategy = Strategy
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @return {void}
   */
  start () {
    try {
      if (this.strategy !== null) {
        Queue.add(this.strategy)
      }
    } catch (error) {
      console.debug(error)
    }
  }
}

export default Context
