<template>
  <div>
    <div class="uk-card uk-card-default uk-card-small uk-margin-top">
      <div
        v-if="user"
        class="uk-card-body"
      >
        <div class="uk-align-right">
          <CardToProfileButton
            :card="userCard"
            :profile-name-by-default="user.jid || user.phone"
          />
          <AddStoredButton
            provider="whatsapp"
            :ext_id="externalId"
            type="account"
            :data="user"
          />
        </div>
        <div>
          <div>
            <router-link
              :to="{
                name: 'whatsapp_user_detail',
                params: { phone: String(user.phone) }}"
              class="uk-padding-small uk-align-center uk-padding uk-light uk-padding-remove-bottom"
            >
              <GeneralAvatar :src="avatar"/>
            </router-link>
          </div>
          <div>
            <h3 class="uk-margin-remove-bottom">
              {{ user.name }}
            </h3>
            {{ $t('tgcp.channels.phone') }}
            <router-link
              :to="{
                name: 'phoneResults',
                params: { phone: normalizePhone(user.phone) }}"
            >
              {{ normalizeWAPhone(user.phone) }}
            </router-link>
          </div>
          <span v-if="user.country">
            <span
              :class="`flag-icon flag-icon-${user.country.toLowerCase()}`"
              style="margin: 0 0 4px 4px;"
            /> {{ countryTitle(user.country) }}
          </span>
          <span v-else>
&nbsp;
          </span>
          <div>
            <div class="uk-margin-small-top">
              <router-link
                :to="{
                  name: 'whatsapp_user_detail',
                  params: { phone: String(user.phone) }}"
                class="uk-button uk-button-primary uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
              >
                {{ $t('tgcp.channels.full_info') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import countries from '@/utils/countryCodesNamesFull.json'
import CardToProfileButton from '@/components/CardToProfileButton'
import GeneralUser from '@/utils/providers/generalUser'
import GeneralAvatar from '@/components/GeneralAvatar'
import { mapActions } from 'vuex'
import { normalizeWAPhone } from '@/filters/globalFilters'

export default {
  name: 'WhatsappUserlistItem',
  components: {
    AddStoredButton,
    CardToProfileButton,
    GeneralAvatar
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    externalId () {
      return 'whatsapp_user_' + this.user.jid + this.user.phone
    },
    avatar () {
      if (!this.user.avatar_cdn_path) return false
      return this.$gateway(this.user.avatar_cdn_path)
    },
    userCard () {
      const card = new GeneralUser()
      card.location = this.user.country
      card.type = 'whatsapp'
      card.genericType = 'messenger'
      card.externalLink = false
      if (this.user.phone && this.user.phone.indexOf('+') < 0) {
        card.phone = '+' + this.user.phone
        card.user_id = this.user.phone + '@s.whatsapp.net'
      }
      card.avatar = this.$gateway(this.user.avatar_cdn_path)
      return card
    }
  },
  methods: {
    ...mapActions('whatsapp', [
      'fetchUserByPhone'
    ]),
    normalizeWAPhone,
    countryTitle (code) {
      return countries[code]
    },
    normalizePhone (value) {
      if (!value) return ''
      value = value.replace('@c.us', '')
      if (value.indexOf('+') !== 0) value = '+' + value
      return value
    }
  }
}
</script>

<style scoped src="flag-icons-svg/css/flag-icons.css"></style>