<template>
  <div
    class="uk-width-1-4 uk-align-right"
    style="margin-top:20px; margin-bottom:0px;"
  >
    <div class="uk-inline uk-width-1-1">
      <button
        class="uk-button uk-button-default button-with-icon"
        type="button"
        :class="{ 'button-with-icon_hasIcon' : hasErrors}"
      >
        {{ $t('search.used_sources_btn') }}
        <span
          v-if="hasErrors"
          uk-icon="warning"
          class="uk-text-white button_with-icon_raised-icon"
        />
      </button>
      <div
        uk-dropdown="mode: click; pos: bottom-right; "
        class="uk-text-left providers uk-margin-bottom"
      >
        <div
          class="uk-text-center uk-margin-bottom "
        >
          <router-link
            class="uk-button uk-button-text"
            :to="{
              name: 'settings_search'
            }"
          >
            {{ $t('search.edit_providers') }}
          </router-link>
        </div>

        <button
          v-if="hasErrors"
          class="uk-button  uk-button-text uk-button-small resync-button"
          @click="resync"
        >
          <span uk-icon="refresh" />
        </button>


        <div
          :style="'columns:' + +(Math.floor(usedProviders({ type, search }).length / 19) + 2)"
        >
          <div
            v-for="provider in usedProviders({ type, search })"
            :key="provider.name"
            class="uk-margin-small-bottom uk-width-1-1 uk-text-nowrap"
          >
            <span
              :class="classCalc(provider.status)"
              :uk-icon="iconCalc(provider.status)"
              :uk-tooltip="tooltipTranslate(provider.status)"
            />
            <span
              class="uk-text-break"
            >
              {{ provider.description }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UsedProviders',
  props: {
    search: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('fetcher', [
      'usedProviders'
    ]),
    hasErrors () {
      return this.usedProviders( { type: this.type, search:this.search }).reduce( (acc, el) => acc || (el.status ==='fail' || el.status ==='timeout'), false) && this.total === this.current
    },
    total () {
      return this.$store.state.fetcher.total
    },
    current () {
      return this.$store.state.fetcher.count
    }
  },
  methods: {
    ...mapActions('fetcher', ['cleanUpSearch', 'fetch']),
    async resync () {
      let forcedList = this.usedProviders( { type: this.type, search:this.search }).reduce( (acc, el) => (el.status ==='fail') ? [el.name,...acc]: acc, [])
      await this.cleanUpSearch({
        search: this.search,
        type: this.type
      })
      await this.fetch({
        search: this.search,
        type: this.type,
        force: forcedList
      })
    },
    classCalc (status) {
      const opts = {
        success: 'uk-text-success',
        nodata: 'uk-text-emphasis',
        fail: 'uk-text-danger',
        timeout: 'uk-text-warning'
      }
      return opts[status] || 'uk-text-clock'
    },
    iconCalc (status) {
      const opts = {
        success: 'check',
        nodata: 'close',
        fail: 'question',
        timeout: 'question'
      }
      const res = opts[status] || 'clock'
      return 'icon: ' + res
    },
    tooltipTranslate (status) {
      if (typeof status === 'string') status = this.$t('search.' + status.toLowerCase())
      return 'title: ' + status // 'success', 'nodata', 'fail', 'waiting'
    }
  }
}
</script>

<style scoped>
  .button-with-icon {
    position: relative;
    padding-right: 35px;
    padding-left: 35px;
  }
  .button-with-icon_hasIcon {
    padding-right: 50px;
    padding-left: 20px;
  }
  .resync-button {
    padding: 0 5px 0;
    position: absolute;
    right: 10px;
    color: lightgray;
    top: 10px;
  }
  .resync-button:hover {
    color: #333;
  }
  .resync-button::before {
    display: none
  }
  .button_with-icon_raised-icon {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    background: indianred;
    width: 40px;
    padding-top: 10px;
    height: 30px;
    margin-top: -1px;
  }
.providers {
  column-gap: 40px;
  column-rule-style: solid;
  column-rule-width: 1px;
  column-fill: balance-all;
}
</style>
