<template>
  <div>
    <div
      v-if="allCases[caseId]"
      class="uk-card uk-card-default uk-margin-top"
    >
      <div
        class="uk-card-header uk-padding-small uk-text-middle"
      >
        <div
          uk-grid
        >
          <div
            style="min-width: 250px"
            class="uk-width-expand uk-text-break uk-margin-left"
          >
            <h3 class="uk-card-title uk-text-left uk-margin-small-bottom">
              {{ allCases[caseId].case_title }}
              <span class="uk-text-muted uk-text-small uk-padding-small">
                #{{ allCases[caseId].case_id }}
              </span>
            </h3>
          </div>
          <div
            class="uk-width-auto"
            uk-grid
          >
            <div
              class="uk-text-left uk-text-small uk-text-break uk-text-middle"
            >
              <span>
                {{ $t('cases.updated') }}: {{ dayjs(allCases[caseId].updatedAt) }}
              </span>
              <br />
              <span>
                {{ $t('cases.created') }}: {{ dayjs(allCases[caseId].createdAt) }}
              </span>
            </div>
          </div>
          <div
            class="uk-width-auto uk-grid-collapse"
            style="min-width: 20px;padding-top: 10px;"
            uk-grid
          >
            <a
              class="uk-button uk-button-text uk-margin-small-right"
              type="button"
              :class="defaultCase === allCases[caseId].case_id
                && 'uk-text-primary'"
              :title="$t('cases.pin_case')"
              uk-icon="star"
              @click="pinCase"
            />
            <a
              v-if="allCases[caseId] && ['rw', 'f'].includes(allCases[caseId].access_level)"
              class="uk-button uk-button-text uk-margin-small-right"
              type="button"
              :title="$t('cases.edit_case')"
              uk-icon="pencil"
              @click="$router.push({ name: 'case_settings', params: { caseId: caseId } })"
            />
            <a
              v-if="allCases[caseId] && ['rw', 'f'].includes(allCases[caseId].access_level)"
              class="uk-button uk-button-text uk-margin-small-right"
              :uk-tooltip="$t('cases.export')"
              @click="showModalExport"
            >
              <loader
                v-if="generatingPdf"
                :ratio="1"
              />
              <span
                v-else
                uk-icon="file-text"
              />
            </a>
            <a
              v-if="allCases[caseId] && allCases[caseId].access_level === 'f'"
              class="uk-button uk-button-text uk-margin-small-right"
              :uk-tooltip="$t('cases.share_case_button')"
              uk-icon="users"
              @click="showModalShare"
            />
            <a
              v-if="allCases[caseId] && ['rw', 'f'].includes(allCases[caseId].access_level)"
              class="uk-button uk-button-text uk-margin-small-right"
              type="button"
              :title="$t('cases.archive_case')"
              uk-icon="pull"
              @click="archiveCase(allCases[caseId]) &&
                $router.push('/cases/')"
            />
          </div>
        </div>
      </div>

      <div
        v-if="allCases[caseId] && allCases[caseId].case_description"
        class="uk-card-body"
      >
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div class="uk-width-4-4@m  uk-text-left">
            <h3
              class="uk-margin-remove-top uk-text-break"
            >
              <Markdown
                :key="caseId"
                :html="false"
                :linkify="false"
                :source="caseDescription"
              />
            </h3>
          </div>
        </div>
      </div>
      <div

        ref="exportCase"
        uk-modal
      >
        <div
          class="uk-modal-dialog"
          style="width:60vw"
        >
          <div class="uk-modal-header">
            <h2 class="uk-modal-title">
              {{ $t('cases.export') }}
            </h2>
            <button
              class="uk-modal-close-default"
              type="button"
              uk-close
            />
          </div>
          <div class="uk-modal-body" >
            <div class="uk-grid" >
              <div
                v-if="0"
                class="uk-width-1-4@m uk-width-1-2@s"
              >
                <span
                  :uk-tooltip="$t('cases.generate-PDF-Report')"
                  class="uk-button uk-button-default uk-modal-close "
                  style="width:100%"
                  @click="pdf"
                >
                  <loader
                    v-if="generatingPdf"
                    :ratio="1"
                  />
                  <span v-else >
                    <span uk-icon="file-pdf"/> {{ $t('cases.PDF') }}
                  </span>
                </span>
              </div>
              <div class="uk-width-1-4@m uk-width-1-2@s">
                <span
                  :uk-tooltip="$t('cases.generate-CSV-from-profiles')"
                  style="width:100%"
                  class="uk-button uk-button-default uk-modal-close "
                  @click="csv(true)"
                >
                  <loader
                    v-if="generatingPdf"
                    :ratio="1"
                  />
                  <span v-else>
                    <span uk-icon="file-text"/>  {{ $t('cases.CSV') }}
                  </span>
                </span>
              </div>
              <div class="uk-width-1-4@m uk-width-1-2@s">
                <span
                  :uk-tooltip="$t('cases.generate-CSV-from-profiles-entity')"
                  style="width:100%"
                  class="uk-button uk-button-default uk-modal-close "
                  @click="csv()"
                >
                  <loader
                    v-if="generatingPdf"
                    :ratio="1"
                  />
                  <span v-else>
                    <span uk-icon="file-text"/> {{ $t('cases.CSV-entity') }}
                  </span>
                </span>
              </div>
              <div class="uk-width-1-4@m uk-width-1-2@s">
                <a
                  :uk-tooltip="$t('cases.send-us-email-regarding-new-format')"
                  :href="`mailto:${contactEmail}?subject=New%20Export%20format&body=Hello%20I%20would%20like%20to%20propose addition%20of%20new%20export format ...`"
                  style="width:100%"
                  class="uk-button uk-button-default "
                >
                  <span uk-icon="comment"/> {{ $t('cases.custom') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="shareCase"
        uk-modal
        bg-close="false"
        @hidden="shareModal = false"
      >
        <div
          class="uk-modal-dialog"
        >
          <div class="uk-modal-header">
            <h2
              class="uk-modal-title"
            >
              {{ $t('cases.share_case') }}
            </h2>
            <CaseInvite
              v-if="shareModal"
              :caseId="caseId"
              @inviteCodeGenerated="code => copyCode = code"
            />
          </div>
          <SingleUseField
            v-if="copyCode"
            class="uk-padding"
            style="border-bottom: 1px solid #e5e5e5;"
            @closeField="copyCode = ''"
          >
            <div class="uk-text-large uk-text-center uk-margin-bottom">
              {{ $t('cases.copy-code') }}
            </div>
            <CopyableText
              class="uk-flex"
              :text="copyCode"
            >
              <input
                class="uk-input uk-margin-small-right"
                style="width: fit-content;"
                :value="copyCode"
                readonly
              />
            </CopyableText>
            <div class="uk-text-meta uk-text-small">
              <span
                uk-icon="icon: info;ratio: 0.5"
              /> {{ $t('cases.copy-once') }}
            </div>
          </SingleUseField>
          <div class="uk-modal-body">
            <CaseUserList
              v-if="allCases[caseId] && allCases[caseId].access_level === 'f'"
              :caseId="caseId"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="uk-grid uk-margin-top"
        uk-grid
      >
        <div class="uk-width-expand">
          <ul
            class="uk-child-width-expand"
            uk-tab
          >
            <li
              :class="{
                'uk-active': $route.name === 'case_details_profiles'
              }"
            >
              <router-link
                :to="{
                  name: 'case_details_profiles'
                }"
              >
                {{ $t('cases.profiles.profiles') }}
              </router-link >
            </li>
            <li
              :class="{
                'uk-active': $route.name === 'case_details_storeds'
              }"
            >
              <router-link
                :to="{
                  name: 'case_details_storeds'
                }"
              >
                {{ $t('cases.stored-objects') }}
              </router-link >
            </li>
            <li
              :class="{
                'uk-active': $route.name === 'case_details_tasks'
              }"
            >
              <router-link
                :to="{
                  name: 'case_details_tasks'
                }"
              >
                {{ $t('cases.tasks.tasks') }}
              </router-link >
            </li>
            <li
              :class="{
                'uk-active': $route.name === 'case_details_notes'
              }"
            >
              <router-link
                :to="{
                  name: 'case_details_notes'
                }"
              >
                {{ $t('cases.notes.notes_title') }}
              </router-link >
            </li>
          </ul>
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseUserList from '@/components/cases/Userlist'
import CaseInvite from '@/components/cases/Invite'
import SingleUseField from '@/components/SingleUseField'
import CopyableText from '@/components/Crypto/Ton/Atoms/CopyableText'
import Loader from '@/components/TGCP/Loader'
import config from '@/config'
import { mapActions, mapGetters } from 'vuex'
import UIkit from 'uikit'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'CaseDetails',
  components: {
    Markdown: defineAsyncComponent(() => import(/* webpackChunkName: "markdown3" */ 'vue3-markdown-it')),
    CaseUserList,
    SingleUseField,
    CopyableText,
    CaseInvite,
    loader: Loader
  },
  props: {
    caseId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      generatingPdf: false,
      loading: false,
      shareModal: false,
      caseInfo: {
        title: (this.allCases && this.allCases[this.caseId].case_title) || '',
        description: (this.allCases && this.allCases[this.caseId].case_description) || ''
      },
      error: {
        title: false,
        description: false,
        send: false
      },
      copyCode: ''
    }
  },
  computed: {
    ...mapGetters('cases', [
      'allCases'
    ]),
    contactEmail () {
      return config.contactEmail
    },
    caseDescription () {
      return this.allCases[this.caseId].case_description
    },
    defaultCase () {
      return this.$store.state.settings.defaultCase
    }
  },
  methods: {
    ...mapActions('cases', [
      'editCase',
      'archiveCase',
      'addCaseTag',
      'csvExport',
      'createCasePDF',
      'getCaseById'
    ]),
    update () {
      this.getCaseById(this.caseId)
    },
    map () {
      this.$router.push('/cases/118/map')
    },
    pdf () {
      this.generatingPdf = true
      this.createCasePDF(this.caseId)
        .finally(() => {
          this.generatingPdf = false
        })
    },
    csv (groupBySource) {
      var caller = this
      const caseId = this.caseId
      caller.generatingPdf = true
      this.csvExport({ caseId: caseId, groupBySource: groupBySource })
        .then((data) => {
          let link = document.createElement('a')
          let blob = new Blob(['\uFEFF' + data], {
            type: 'text/csv;charset=utf-8;'
          })
          link.download = 'case_' + caseId + '.csv'
          link.id = 'case_' + caseId + '.csv'
          link.id = 'lnkDownload'
          link.href = URL.createObjectURL(blob)
          link.click()
          link.remove()
        }).finally(() => {
          caller.generatingPdf = false
        })
    },
    pinCase () {
      this.$store.dispatch('setDefaultCase', { defaultCase: this.caseId })
    },
    cancel (caseInfo) {
      UIkit.modal('#editCaseInfo').hide()
    },
    send () {
      this.loading = true
      const caseInfo = {
        case_title: this.caseInfo.title || this.allCases[this.caseId].case_title,
        case_description: this.caseInfo.description || this.allCases[this.caseId].case_description
      }
      this.editCase({ caseId: this.caseId, caseData: caseInfo })
        .then(() => {
          UIkit.modal('#editCaseInfo').hide()
          this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('cases.successfully_updated'), type: 'success' })
        })
        .catch(() => {
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    showModal () {
      UIkit.modal('#editCaseInfo').show()
    },
    showModalShare () {
      this.shareModal = true
      UIkit.modal(this.$refs.shareCase).show()
    },
    showModalExport () {
      UIkit.modal(this.$refs.exportCase).show()
    }
  },
  mounted () {
    this.modalInstance = UIkit.modal(this.$refs.shareCase)
    UIkit.util.on(this.$refs.shareCase, 'hidden', () => {
      this.shareModal = false
    })
    this.update()
  },
  beforeUnmount () {
    this.modalInstance.$destroy(true)
  }
}
</script>
