<template>
  <div>
    <div class="uk-flex uk-flex-center uk-flex-wrap uk-flex-wrap-around uk-flex-middle">
      <div class="uk-width-expand email-input">
        <input
          v-model.trim="email"
          :disabled="isLoading"
          type="text"
          autofocus
          placeholder="email"
          :class="errors && 'uk-form-danger'"
          class="uk-input"
          @keydown="errors = false"
          @keydown.enter="send"
        />
      </div>
      <div class="uk-width-auto">
        <vueSelect
          v-model="currentAccessLevel"
          :class="{ 'uk-text-muted': isLoading }"
          :options="acesseLevelsTranslated"
          label="label"
          class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
        />
      </div>
      <div
        v-if="code"
        class="uk-width-1-1 uk-margin-top uk-margin-bottom"
      >
        {{ $t('cases.delivery-fail') }}: {{ this.code }}
      </div>
    </div>
    <div class="uk-text-center uk-align-center">
      <button class="uk-button uk-button-default uk-modal-close uk-margin-right">
        {{ $t('cases.cancel') }}
      </button>
      <button
        :disabled="!isValidEmail || isLoading"
        class="uk-button uk-button-primary"
        @click="send"
      >
        <span
          v-if="isLoading"
          uk-spinner
        />
        <span
          v-else
        >
          {{ $t('cases.send') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import vueSelect from '@/components/VueSelect'
import { mapActions } from 'vuex'
export default {
  name: 'CaseInvite',
  components: {
    vueSelect
  },
  props: {
    caseId: {
      type: [Number, String],
      required: true
    }
  },
  emits: ['inviteCodeGenerated'],
  data () {
    return {
      email: '',
      accessLevel: 'r',
      errors: false,
      isLoading: null,
      code: ''
    }
  },
  computed: {
    isValidEmail () {
      /* eslint-disable no-control-regex */
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(
        this.email
      )
      /* eslint-enable no-control-regex */
    },
    currentAccessLevel: {
      get () {
        return this.accessLevel
      },
      set (obj) {
        this.accessLevel = obj && obj.value
      }
    },
    acesseLevelsTranslated () {
      return ['r', 'rw', 'f'].map(lvl => ({
        value: lvl,
        label: this.$t('access.' + lvl)
      }))
    }
  },
  methods: {
    ...mapActions('cases', [
      'sendInvintation',
      'getAllCaseUsers'
    ]),
    send () {
      if (!this.isValidEmail) {
        return (this.errors = true)
      }
      this.errors = false
      this.isLoading = true
      this.sendInvintation({
        caseId: this.caseId,
        email: this.email,
        accessLevel: this.accessLevel
      })
        .then(res => {
          this.getAllCaseUsers(this.caseId)
          this.email = ''
          this.$notify({
            group: 'general',
            title: this.$t('notifications.success'),
            type: 'success'
          })
          this.code = ''
          if (res?.success) this.$emit('inviteCodeGenerated', res.code)
        })
        .catch(err => {
          this.errors = true
          // TODO clarify return format, it is hard to understand what is being returned
          this.$notify({
            group: 'general',
            title: this.$t('notifications.error'),
            text: this.$t(err.response.data.message),
            type: 'error'
          })
          this.code = err.response.data.code
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.uk-text-muted {
  pointer-events: none;
}
.email-input {
  min-width: 80px;
}
</style>
