<template>
  <div>
    <Loader v-if="!isLoaded['hashtag_' + id]"/>
    <div v-else-if="allHashtags[id]">
      <h3>
        {{ $t('twitter.hashtags.hashtag-details') }}
      </h3>
      <h2>
        #{{ allHashtags[id].value }}
      </h2>
    </div>
    <NotFound v-else/>
    <div class="uk-margin-medium-top">
      <ul
        ref="uk-tab"
        uk-tab
      >
        <li
          class="uk-flex"
        >
          <a
            href="#overview_tab"
            class="uk-padding-remove"
            @click="activateTab('overview_tab')"
          >
            {{ $t('twitter.hashtags.overview') }}
          </a>
        </li>
        <li
          v-if="hash?.users_count"
          class="uk-flex"
        >
          <a
            href="users_tab"
            class="uk-padding-remove"
            @click="activateTab('users_tab')"
          >
            {{ $t('twitter.hashtags.users') }}
          </a>
          <span class="uk-badge uk-margin-small-left">
            {{ hash.users_count }}
          </span>
        </li>
        <li
          v-if="hash?.posts_count"
          class="uk-flex"
        >
          <a
            href="messages_tab"
            class="uk-padding-remove"
            @click="activateTab('messages_tab')"
          >
            {{ $t('tiktok.hashtags.posts') }}
          </a>
          <span class="uk-badge uk-margin-small-left">
            {{ hash.posts_count }}
          </span>
        </li>
        <li
          class="uk-flex"
        >
          <a
            href="together_tab"
            class="uk-padding-remove"
            @click="activateTab('together_tab')"
          >
            {{ $t('twitter.hashtags.tags-used-together') }}
          </a>
        </li>
      </ul>
      <ul
        ref="uk-switcher"
        class="uk-switcher"
      >
        <li>
          <a name="overview_tab"/>
          <span
            v-if="activatedTabs.includes('overview_tab')"
          >
            <HashtagLineGraph
              :id="id"
              type="day"
            />
            <HashtagLineGraph
              :id="id"
              type="week"
            />
            <HashtagLineGraph
              :id="id"
              type="year"
            />
          </span>
        </li>
        <li>
          <a name="users_tab"/>
          <UserList
            v-if="activatedTabs.includes('users_tab')"
            :id="id"
            type="hashtag"
          />
        </li>
        <li>
          <a name="messages_tab"/>
          <MessageList
            v-if="activatedTabs.includes('messages_tab')"
            :id="id"
            type="hashtag"
          />
        </li>
        <li>
          <a name="together_tab"/>
          <HashtagList
            v-if="activatedTabs.includes('together_tab')"
            :id="id"
            type="together"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import UserList from '@/views/Tiktok/UserList'
import MessageList from '@/views/Tiktok/MessageList'
import HashtagList from '@/views/Tiktok/HashtagList'
import HashtagLineGraph from '@/components/Tiktok/Hashtags/LineGraph'
import UIkit from 'uikit'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TiktokHashtagDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    UserList,
    MessageList,
    HashtagList,
    HashtagLineGraph
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      activatedTabs: this.$route.query.tab || ['overview_tab']
    }
  },
  watch: {
    id: {
      handler () {
        this.activatedTabs = ['overview_tab'] // force rerender graphs
        this.update()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('tiktok', [
      'allHashtags',
      'isLoaded'
    ]),
    hash () {
      return this.allHashtags[this.id]
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'fetchHashtagById'
    ]),
    activateTab (tab) {
      !this.activatedTabs.includes(tab) &&
        this.activatedTabs.push(tab)
    },
    handleError () {
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    update () {
      this.fetchHashtagById(this.id)
        .catch(err => this.handleError(err))
    }
  },
  mounted () {
    if (!this.$route.query?.tab) {
      UIkit.tab(this.$refs['uk-tab']).show(0)
      UIkit.switcher(this.$refs['uk-switcher']).show(0)
    }
  }
}
</script>
