import config from '@/config'
import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import router from '@/router'
const { servers: { forums } } = config

export default {
  namespaced: true,
  state: {
    usersFetching: new Map(),
    users: {},
    messages: {},
    forums: {},
    topics: {},
    goods: {},
    stats: {},
    statuses: {},
    isLoaded: {},
    csv: {
      ready: 0,
      autostart: false,
      data: {}
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    allForums: state => state.forums,
    allUsers: state => state.users,
    allTopics: state => state.topics,
    allGoods: state => state.goods,
    stats: state => state.stats,
    forumsStatuses: state => state.statuses
  },
  mutations: {
    addUsersFetching (state, payload) {
      if (!state.usersFetching.has(payload.id)) {
        state.usersFetching.set(payload.id)
      }
    },
    deleteUsersFetching (state, payload) {
      state.usersFetching.delete(payload.id)
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    updateStats: (state, stats) => {
      state.stats = stats
    },
    setStatuses (state, payload) {
      state.statuses = payload
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    fetchAllGoods (state, goods) {
      if (!goods) return
      goods.map(good => state.goods[good.id] = good)
    },
    fetchAllUsers (state, users) {
      if (!users) return
      users = users.map(user => state.users[user.id] = user)
    },
    fetchAllForums (state, forums) {
      if (!forums) return
      forums = forums.map(forum => state.forums[forum.id] = forum)
    },
    fetchAllTopics (state, topics) {
      if (!topics) return
      topics = topics.map(topic => state.topics[topic.id] = topic)
    },
    fetchAllMessages (state, messages) {
      if (!messages) return
      messages = messages.map(message => state.messages[message.id] = message)
    },
    fetchUser (state, user) {
      if (!user) return
      state.users[user.id] = user
    },
    fetchTopicById (state, topic) {
      if (!topic) return
      state.topics[topic.id] = topic
    },
    fetchForum (state, forum) {
      if (!forum) return
      state.forums[forum.id] = forum
    },
    fetchGoodPlacements (state, placement) {
      if (!placement) return
      state.goods['placements'][placement.id] = placement
    },
    addParamToQuery: (state, { isInit, ...query }) => {
      const path = router.currentRoute.value.path
      const old = router.currentRoute.value.query
      if (typeof query === 'object') {
        query = Object.assign({}, old, query)

        if (old.id && Number(old.page) !== query.page) query.id = null

        for (const key in query) {
          if (!query[key]) delete query[key]
        }
      }
      if (getQueryString(old) === getQueryString(query)) return
      router[isInit ? 'replace' : 'push']({ path, query })
    }
  },
  actions: {
    async fetchUserHistory ({ commit, dispatch }, id) {
      return (await apiRequest(`/users/${id}/history`, 'GET', {}, forums)).data.data
    },
    fetchAllUsers ({ commit, dispatch }, query) {
      commit('startLoad', 'users')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      return apiRequest(`/users${queryString}`, 'GET', {}, forums)
        .then(({ data } = {}) => {
          commit('fetchAllUsers', data.data)
          return Promise.resolve(data)
        })
        .finally(() => commit('stopLoad', 'users'))
    },
    fetchForumUsers ({ commit, dispatch }, params) {
      commit('startLoad', 'users_forum_' + params.forumId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return new Promise((resolve, reject) => {
        apiRequest(`/forums/${params.forumId}/users${queryString}`, 'GET', {}, forums)
          .then(res => {
            let users = res.data.data
            commit('fetchAllUsers', users)
            resolve(res.data)
          })
          .catch(reject)
          .finally(() => commit('stopLoad', 'users_forum_' + params.forumId))
      })
    },
    fetchTopicUsers ({ commit, dispatch }, params) {
      commit('startLoad', 'users_topic_' + params.topicId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return apiRequest(`/forums/${params.forumId}/topics/${params.topicId}/users${queryString}`, 'GET', {}, forums)
        .then(({ data }) => {
          commit('fetchAllUsers', data.data)
          return Promise.resolve(data)
        })
        .finally(() => commit('stopLoad', 'users_topic_' + params.topicId))
    },
    fetchUserById ({ commit, state }, id) {
      if (('user_' + id) in state.isLoaded) return
      if (state.users[id]) return Promise.resolve(state.users[id])
      commit('startLoad', 'user_' + id)
      return new Promise((resolve, reject) => {
        const fetch = apiRequest(`/users/${id}`, 'GET', {}, forums)
          .then(res => {
            let user = res.data
            commit('fetchUser', user)
            resolve(user)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => {
            commit('deleteUsersFetching', { id })
            commit('stopLoad', 'user_' + id)
          })
        commit('addUsersFetching', { id })
        return fetch
      })
    },
    fetchStats ({ commit, state, dispatch, rootState }, id) {
      if (!state.stats.hasOwnProperty('tweets_count')) {
        commit('startLoad', `stats`)
      }
      apiRequest(`/stats`, 'GET', {}, forums)
        .then((data) => {
          commit('updateStats', data.data)
        }).catch(err => {
          if (err.response.status === 404) console.error(err)
        }).finally(() => {
          commit('stopLoad', `stats`)
        })
    },
    fetchForumById ({ commit, state }, id) {
      if (('forum_' + id) in state.isLoaded) return
      if (id in state.forums) return Promise.resolve(state.forums[id])
      commit('startLoad', 'forum_' + id)
      return new Promise((resolve, reject) => {
        apiRequest(`/forums/${id}`, 'GET', {}, forums)
          .then(res => {
            let forum = res.data
            commit('fetchForum', forum)
            resolve(forum)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'forums_' + id))
      })
    },
    fetchAllPlacements ({ commit, state }, query) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'placements')
        apiRequest('/placements', 'GET', {}, forums)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'placements'))
      })
    },
    fetchGoodPlacements ({ commit, state, dispatch }, { query, forumId, goodId }) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'placements_good' + goodId)
        apiRequest(`/forums/${forumId}/goods/${goodId}/placements`, 'GET', {}, forums)
          .then(res => {
            let placements = res.data.data
            for (let i = 0; i < placements.length; i++) {
              if (!state.goods[goodId]) {
                try {
                  dispatch('fetchGooodById', goodId)
                    .then(() => commit('fetchGoodPlacements', placements[i]))
                } catch {
                  continue
                }
              } else {
                commit('fetchGoodPlacements', placements[i])
              }
            }
            resolve(placements)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'placements_good' + goodId))
      })
    },
    fetchAllForums ({ commit }, query) {
      commit('startLoad', 'forums')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      return new Promise((resolve, reject) => {
        apiRequest(`/forums${queryString}`, 'GET', {}, forums)
          .then(res => {
            let forums = res.data.data
            commit('fetchAllForums', forums)
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'forums'))
      })
    },
    fetchAllTopics ({ commit }, query) {
      commit('startLoad', 'topics')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      return apiRequest(`/topics${queryString}`, 'GET', {}, forums)
        .then(({ data } = {}) => {
          commit('fetchAllTopics', data.data)
          return Promise.resolve(data)
        })
        .finally(() => commit('stopLoad', 'topics'))
    },
    fetchForumTopics ({ commit }, params) {
      commit('startLoad', 'topics_forum_' + params.forumId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return apiRequest(`/forums/${params.forumId}/topics${queryString}`, 'GET', {}, forums)
        .then(({ data } = {}) => {
          commit('fetchAllMessages', data.data)
          return Promise.resolve(data)
        })
        .finally(() => commit('stopLoad', 'topics_forum_' + params.forumId))
    },
    fetchUserTopics ({ commit }, params) {
      commit('startLoad', 'topics_user_' + params.userId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return new Promise((resolve, reject) => {
        let posted = ''
        if (params?.posted) {
          posted = '/posted'
        }
        apiRequest(`/users/${params.userId}/topics${posted}${queryString}`, 'GET', {}, forums)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'topics_user_' + params.userId))
      })
    },
    fetchTopicById ({ state, commit }, id) {
      if (id in state.topics) return Promise.resolve(state.topics[id])
      commit('startLoad', 'topic_' + id)
      return new Promise((resolve, reject) => {
        apiRequest(`/topics/${id}`, 'GET', {}, forums)
          .then(res => {
            let topic = res.data
            commit('fetchTopicById', topic)
            resolve(topic)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'topic_' + id))
      })
    },
    fetchAllGoods ({ commit }, query) {
      commit('startLoad', 'goods')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      return apiRequest(`/goods?` + getQueryString(query), 'GET', {}, forums)
        .then(({ data } = {}) => {
          commit('fetchAllGoods', data.data)
          return Promise.resolve(data)
        })
        .finally(() => commit('stopLoad', 'goods'))
    },
    fetchUserGoods ({ commit }, { query, userId }) {
      commit('startLoad', 'goods')
      return new Promise((resolve, reject) => {
        var queryString = getQueryString(query)
        apiRequest(`/users/${userId}/goods?` + queryString, 'GET', {}, forums)
          .then(res => {
            commit('fetchAllGoods', res.data.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'goods'))
      })
    },
    fetchForumGoods ({ commit }, { query, forumId }) {
      commit('startLoad', 'goods')
      return new Promise((resolve, reject) => {
        var queryString = getQueryString(query)
        apiRequest(`/forums/${forumId}/goods?` + queryString, 'GET', {}, forums)
          .then(res => {
            commit('fetchAllGoods', res.data.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'goods'))
      })
    },
    fetchAllMessages ({ commit }, query) {
      commit('startLoad', 'messages')

      if (query.message) {
        query.message = unicode.normalize(query.message)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      return new Promise((resolve, reject) => {
        apiRequest(`/messages${queryString}`, 'GET', {}, forums)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages'))
      })
    },
    fetchForumMessages ({ commit }, params) {
      commit('startLoad', 'messages_forum_' + params.forumId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return new Promise((resolve, reject) => {
        apiRequest(`/forums/${params.forumId}/messages${queryString}`, 'GET', {}, forums)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages_forum_' + params.forumId))
      })
    },
    fetchTopicMessages ({ commit }, params) {
      commit('startLoad', 'messages_topic_' + params.topicId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return new Promise((resolve, reject) => {
        apiRequest(`/forums/${params.forumId}/topics/${params.topicId}/messages${queryString}`, 'GET', {}, forums)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages_topic_' + params.topicId))
      })
    },
    fetchUserMessages ({ commit }, params) {
      commit('startLoad', 'messages_user_' + params.userId)
      const queryString = params.query ? '?' + getQueryString(params.query) : ''
      return new Promise((resolve, reject) => {
        apiRequest(`/users/${params.userId}/messages${queryString}`, 'GET', {}, forums)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages_user_' + params.userId))
      })
    },
    fetchMessageLink ({ commit, state, rootState }, message) {
      return new Promise((resolve, reject) => {
        if (!message && !message.forum_id && !message.topic_id) reject(new Error('Not enough arguments'))
        apiRequest(`/forums/${message.forum_id}/topics/${message.topic_id}/messages/${message.id}/offset`, 'GET', {}, forums)
          .then(res => {
            let offset = res.data
            const limit = 21
            const page = Math.floor(offset / (limit - 1) + 1)
            offset = ((limit - 1) * page) - (limit - 1)
            resolve({
              topicId: message.topic_id,
              forumId: message.forum_id,
              messagePage: page,
              id: message.id,
              tab: 'messages_tab'
            })
          }).catch((err) => {
            reject(err)
          })
      })
    },
    addParamToQuery ({ commit }, param) {
      commit('addParamToQuery', param)
    },
    async fetchStatuses ({ commit }) {
      commit('startLoad', 'forumsStatus')
      const { data: { data } } = await apiRequest(`/forums/status`, 'GET', {}, forums)
      commit('setStatuses', data)
      commit('stopLoad', 'forumsStatus')
    }
  }
}
