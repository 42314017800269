<template>
  <div class="uk-card uk-card-default uk-card-small">
    <div class="uk-card-body">
      <div class="uk-align-right">
        <AddStoredButton
          v-if="showStoredButton"
          provider="forums"
          type="good"
          :data="good"
        />
      </div>
      <div
        class="uk-grid-small"
        uk-grid
      >
        <div
          v-if="user"
          class="uk-width-1-6@m uk-text-center"
          style="align-content: center;"
        >
          <Avatar
            :avatar="user.avatar"
            :type="'user'"
          />
          <h4 class="uk-margin-remove uk-text-break">
            {{ user.display_name }}
          </h4>
          <span class="uk-text-small uk-margin-remove-bottom uk-text-muted">
            <router-link
              :to="{
                name: 'forums_user_details',
                params: {
                  id: String(user.id)
                },
                hash: '#goods'
              }"
            >
              #{{ user.id }}
            </router-link>
          </span>
          <h4
            v-if="user.forum"
            class="uk-text-small uk-margin-remove-top uk-text-muted"
          >
            <router-link
              :to="{
                name: 'forums_forum_details',
                params: { id: String(user.forum.id) }}"
            >
              {{ user.forum.name }}
            </router-link>
          </h4>
          <div
            class="uk-width-1-1"
          >
            <p
              v-if="user.bio"
            >
              {{ user.bio }}
            </p>
          </div>
        </div>
        <div class="uk-width-2-3@m">
          <h3>
            {{ translatedTitleShown ? translatedTitle : good.title }}
            <Translate
              class="uk-margin-small-right"
              :text="good.title"
              :reduced="true"
              :translatedText="translatedTitle"
              :translatedShown="translatedTitleShown"
              @translatedText="translatedTitle = $event"
              @translatedShown="translatedTitleShown = $event"
            />
          </h3>
          <p
            v-if="good.ext_id"
            class="uk-text-left uk-margin-remove"
          >
            <strong>
              ID:
            </strong> {{ good.ext_id }}
          </p>
          <h4
            class="uk-heading-bullet uk-text-left uk-margin-remove"
          >
            {{ good.category }}
          </h4>
          <p
            class="uk-text-small uk-text-left uk-margin-remove-top uk-text-muted"
          >
            {{ good.url }}
          </p>
          <div
            class="uk-text-left uk-margin-remove-top"
          >
            <span
              v-if="description"
            >
              <span
                v-html="messageExtracted"
              />
            </span>
            <div 
              v-if="addData" 
              class="uk-margin-small-top uk-flex uk-flex-column"
            >
              <template 
                v-for="value, key in addData"
                :key="value + key"
              >
                <div v-if="key !== 'struct'">
                  <strong>
                    {{ key.toUpperCase()[0] + key.slice(1,).replace('_', ' ') }}:
                  </strong> {{ value }}
                </div>
              </template>
            </div>
            <div v-if="addData && addData.struct">
              <h4
                class="uk-heading-bullet uk-text-left uk-margin-remove"
              >
                Structure
              </h4>
              <div class="uk-margin-top">
                <RecursiveStruct
                  v-for="item of addData['struct']"
                  :key="item.name"
                  :struct="item"
                  :good-id="good.id"
                />
              </div>
            </div>
            <button
              v-if="messageExtracted && messageExtracted.length > limit"
              class="uk-button uk-button-text uk-margin-small-left"
              @click="short = !short"
            >
              {{ short ? $t('cases.stored.message-card.more') : $t('cases.stored.message-card.less') }}
            </button>
          </div>
        </div>
        <div 
          class="uk-width-1-6@m uk-height-1-1"
        >
          <div class="uk-margin-small-bottom">
            <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-bottom uk-text-muted">
              <li>
                <i>
                  <time class="filter">
                    {{ dayjs(good.cdate) }}
                  </time>
                </i>
              </li>
            </ul>
          </div>
          <div
            v-if="image"
            class="uk-margin-bottom"
          >
            <img
              :data-src="image"
              height
              alt
              uk-img
            />
          </div>
          <div class="uk-margin-small-top">
            <Translate
              class="uk-margin-small-right"
              :text="description"
              :translatedText="translatedText"
              :translatedShown="translatedShown"
              @translatedText="translatedText = $event"
              @translatedShown="translatedShown = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Twitter/Avatar'
import extractData from '@/utils/dataExtraction'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import { defineAsyncComponent } from 'vue'
import RecursiveStruct from '@/components/Forums/GoodsList/RecursiveStruct.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'GoodsItem',
  components: {
    Avatar,
    AddStoredButton,
    RecursiveStruct,
    Translate: defineAsyncComponent(() => import('@/components/Translate'))
  },
  props: {
    good: {
      required: true,
      type: Object
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      short: true,
      limit: 1000,
      translatedText: false,
      translatedShown: false,
      translatedTitle: false,
      translatedTitleShown: false
    }
  },
  computed: {
    ...mapGetters('forums', ['allUsers', 'allForums']),
    user () {
      return this.allUsers[this.good.user_id]
    },
    image () {
      if (!this.good.image_url) return false
      return this.good.image_url
    },
    description () {
      return this.good.description ?? ''
    },
    addData () {
      return typeof this.good.additional_data === 'object' ? this.good.additional_data : null
    },
    messageExtracted () {
      const text = this.translatedShown ? extractData(this.translatedText) : extractData(this.description)
      if (!text) return ''
      if (text.length > this.limit && this.short) {
        return text.substring(0, this.limit) + '...'
      }
      return text
    }
  },
  methods: {
    ...mapActions('forums', ['fetchUserById', 'fetchForumById'])
  },
  mounted () {
    if (!this.user) this.fetchUserById(this.good.user_id)
  }
}
</script>
