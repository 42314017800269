<template>
  <span>
    <button
      v-if="!shown"
      class="uk-button uk-button-default"
      @click="showModal"
    >
      {{ $t('settings.change_password.change_password') }}
    </button>
    <div
      ref="changePasswordModal"
      :uk-modal="`bg-close:${!shown}; esc-close: ${!shown}`"
      @keyup.enter="confirm"
    >
      <div class="uk-modal-dialog uk-padding">
        <div class="uk-modal-title uk-text-center">
          <h2 class="uk-text-center uk-margin-remove-bottom">
            {{ $t('settings.change_password.change_password') }}
          </h2>
          <span
            class="uk-text-small uk-text-muted uk-text-center uk-margin-bottom"
          >
            {{ $t('settings.change_password.once-a-day') }}
          </span>
        </div>
        <form
          class="uk-modal-body"
          @submit.prevent="confirm"
        >
          <input
            v-model="password"
            class="uk-input"
            :class="{ 'uk-form-danger': errors.password }"
            type="password"
            :placeholder="$t('settings.change_password.current_password')"
            @input="errors.password && clearErrors('password')"
          />
          <input
            v-model="newPassword"
            class="uk-input uk-margin-top"
            :class="{ 'uk-form-danger': errors.confirmation }"
            type="password"
            :placeholder="$t('settings.change_password.new_password')"
            @input="errors.confirmation && clearErrors('confirmation')"
          />
          <input
            v-model="confirmation"
            class="uk-input uk-margin-top"
            :class="{ 'uk-form-danger': errors.confirmation }"
            :placeholder="$t('settings.change_password.confirm_password')"
            type="password"
            @input="errors.confirmation && clearErrors('confirmation')"
          />
          <input
            v-if="twoFA"
            v-model="otp"
            class="uk-input uk-margin-top"
            :class="{ 'uk-form-danger': errors.otp }"
            :placeholder="$t('login.2fa_placeholder')"
            type="text"
            @input="errors.otp && clearErrors('otp')"
          />
          <div class="uk-align-center uk-width-auto uk-text-center">
            <button
              v-if="!shown"
              class="uk-margin-right uk-width-1-3 uk-button uk-modal-close uk-button-default"
            >
              {{ $t('settings.change_password.close') }}
            </button>
            <button
              :disabled="(!password || !newPassword || !confirmation || loading)"
              class="uk-width-1-3 uk-button uk-button-primary"
              type="submit"
            >
              <Loader
                v-if="loading"
                :ratio="0.7"
              />
              <span v-else>
                {{ $t('settings.change_password.confirm') }}
              </span>
            </button>
          </div>
        </form>
        <div class="uk-modal-footer uk-text-center">
          {{ $t('settings.change_password.requirements') }}
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import UIkit from 'uikit'
const removeEmpty = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([k, v]) => v)
      .map(([k, v]) => (typeof v === 'object' ? [k, removeEmpty(v)] : [k, v]))
  )
export default {
  name: 'ChangePassword',
  components: {
    Loader
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      password: '',
      loading: false,
      newPassword: '',
      confirmation: '',
      otp: '',
      policy: '',
      modalInstance: '',
      errors: {
        password: false,
        confirmation: false
      },
      twoFA: false
    }
  },
  computed: {
    ...mapGetters(['settings'])
  },
  methods: {
    ...mapMutations([
      'setForceChangePassword'
    ]),
    ...mapActions([
      'changePassword',
      'getCurrentUser'
    ]),
    async showModal () {
      try {
        const { data } = await this.getCurrentUser()
        this.twoFA = data.has_otp
      } catch {
        this.twoFA = true
      } finally {
        UIkit.modal(this.modalInstance).show()
      }
    },
    clearData () {
      this.password = undefined
      this.newPassword = undefined
      this.confirmation = undefined
      this.otp = undefined
    },
    clearErrors (field) {
      if (field === 'password') this.errors.password = false
      if (field === 'confirmation') this.errors.confirmation = false
      if (field === 'otp') this.errors.otp = false
    },
    confirm () {
      const { password, newPassword, confirmation, otp } = this
      if (!password || !newPassword || !confirmation) return
      this.loading = true

      this.changePassword(removeEmpty({ password, new_password: newPassword, new_password_confirm: confirmation, otp }))
        .then(() => {
          this.clearData()
          this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('settings.change_password.password_changed'), type: 'success' })
          UIkit.modal(this.modalInstance).hide()
          this.setForceChangePassword(false)
        })
        .catch(err => {
          if (err.response) {
            switch (err.response.status) {
                case 422:
                  if (err.response.data.fields.json.otp) {
                    this.errors.otp = true
                    this.otp = ''
                    this.$notify({ group: 'general', title: err.response.data.fields.json.otp, type: 'error' })
                  } else if (err.response.data.fields.json.email) {
                    this.errors.password = true
                    this.clearData()
                    this.$notify({ group: 'general', title: err.response.data.fields.json.email, type: 'error' })
                  } else if (err.response.data.fields.json.new_password) {
                    this.errors.confirmation = true
                    this.newPassword = ''
                    this.confirmation = ''
                    this.otp = ''
                    this.$notify({ group: 'general', title: err.response.data.fields.json.new_password, type: 'error' })
                  } else if (err.response.data.fields.json.new_password_confirm) {
                    this.errors.confirmation = true
                    this.newPassword = ''
                    this.confirmation = ''
                    this.otp = ''
                    this.$notify({ group: 'general', title: err.response.data.fields.json.new_password_confirm, type: 'error' })
                  }
                  break
                case 403:
                  this.$notify({ group: 'general', title: `Can't change your password so often`, type: 'error' })
                  this.clearData()
                  break
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async mounted () {
    this.modalInstance = this.$refs.changePasswordModal
    UIkit.util.on(this.modalInstance, 'hidden', this.clearData)
    if (this.shown) {
      await this.showModal()
    }
  }
}
</script>

<style>

</style>
