<template>
  <ImportExportTasks
    :allTasks="allTasksComputed"
    :massTaskAdd="massTaskAdd"
    :prepareCallback="prepareCallback"
    :with-collect-media="false"
    :with-sync-period="false"
    :with-joinlink-extraction="false"
    :with-bot="false"
    @update="$emit('update')"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'WhatsappImportExportTaskForm',
  components: {
    ImportExportTasks: defineAsyncComponent(() => import('@/components/ImportExportTasks'))
  },
  computed: {
    ...mapGetters('whatsapp', ['allTasks']),
    allTasksComputed () {
      return Object.values(this.allTasks).map(({ joinlink }) => joinlink)
    }
  },
  methods: {
    ...mapActions('whatsapp', ['massTaskAdd']),
    prepareCallback (file, isTaskExist) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-useless-escape
        const regexp = /(http(s)?:\/\/)?chat\.whatsapp\.com(\/invite)?\/([A-z0-9_-]{22})/gim
        const name = (file && file.name) || 'n/a'
        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onloadend = ({ target: { result: text } = {} } = {}) => {
          const joinlinks = [...text.matchAll(regexp)].map(([joinlink]) => joinlink)
          const tasksDuplicates = [...new Set(joinlinks)]
          const tasksFiltered = tasksDuplicates.filter(link => !isTaskExist(link)) // unique
          const tasks = tasksFiltered.map(joinlink => ({ joinlink, description: name }))
          if (!tasks.length) reject(name)
          resolve({
            tasks,
            duplicates: joinlinks.length - tasksDuplicates.length,
            alreadyAdded: tasksDuplicates.length - tasksFiltered.length
          })
        }
      })
    }
  }
}
</script>
