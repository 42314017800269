<template>
  <div>
    <div
      class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
      uk-grid
    >
      <form
        class="uk-grid-small uk-form-small uk-display-inline-block"
        uk-grid
        @submit.prevent="searchHandler"
      >
        <div class="uk-display-inline-block">
          <input
            v-model.trim="query.message"
            type="text"
            :placeholder="$t('tgcp.channels.search_placeholder')"
            class="uk-input uk-form-width-small uk-form-width-medium"
          />
          <span>
            <vueSelect
              v-if="userId"
              v-model="forTopic"
              :searchable="true"
              :filterable="false"
              :clearable="false"
              :options="topics"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              :searchMethod="findTopics"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="searchHandler"
            >
              <span uk-search-icon/>
            </button>
          </span>
        </div>
        <div class="uk-display-inline-block">
          <div class="uk-display-inline-block">
            {{ $t('forums.users.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <vueSelect
            v-model="currentOrderType"
            :options="orderTypeTranslated"
            label="label"
            class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
          />
        </div>
        <div class="uk-display-inline-block uk-position-relative">
          <flatPickr
            v-model="fromDate"
            :placeholder="$t('tgcp.messages.from_date')"
            class="uk-input uk-form-width-small uk-form-width-medium"
            :config="{
              maxDate: toDate || new Date()
            }"
          />
          <button
            v-if="fromDate"
            class="uk-position-right uk-animation-fade uk-animation-fast"
            uk-icon="close"
            style="z-index:2;padding-right: 5px"
            @click="fromDate = null"
          />
        </div>
        <div class="uk-display-inline-block uk-position-relative">
          <flatPickr
            v-model="toDate"
            :placeholder="$t('tgcp.messages.to_date')"
            class="uk-input uk-form-width-small uk-form-width-medium"
            :config="{
              minDate: fromDate || '2010-05-11',
              maxDate: new Date()
            }"
          />
          <button
            v-if="toDate"
            class="uk-position-right uk-animation-fade uk-animation-fast"
            uk-icon="close"
            style="z-index:2;padding-right: 5px"
            @click="toDate = null"
          />
        </div>
      </form>
    </div>
    <SearchInDbHint />
    <div v-if="!loaded">
      <Loader/>
    </div>
    <NotFound v-if="loaded && messages.length === 0 && !forumId && !topicId" />
    <div
      v-if="loaded"
      class="uk-padding-small"
    >
      <Item
        v-for="message in messages"
        :key="message.id"
        :id="'id' + message.id"
        :data-scroll-into-view="message.id"
        :message="message"
        :breadcrumbs="!topicId"
        class="uk-margin-top"
      />
    </div>
    <div v-if="!hidePagination && loaded">
      <Paginate
        v-model="pagination.page"
        :page-count="lastPage"
        :click-handler="changePageHandler"
        prev-text="<"
        next-text=">"
        container-class="uk-pagination uk-flex-center"
      />
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="userId || forumId || topicId"
      :search="query.message"
      namespace="forums"
      type="messages"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import forumsSearch from '@/mixins/forumsSearch'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'ForumsMessageList',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    vueSelect,
    Item: defineAsyncComponent(() => import('@/components/Forums/MessageList/Item')),
    Paginate,
    flatPickr
  },
  mixins: [forumsSearch],
  props: {
    userId: {
      type: [Number, String],
      required: false
    },
    forumId: {
      type: [Number, String],
      required: false
    },
    topicId: {
      type: [Number, String],
      required: false
    },
    page: {
      type: [Number, String]
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'hashtag', 'user', 'topic', 'forum'].includes(val)
    }
  },
  data () {
    return {
      selectedMessages: [],
      topics: [{ label: this.$t('all'), value: '' }],
      messages: [],
      orderList: [
        'forum_message_id',
        'user_id',
        'forum_id',
        'topic_id',
        'timestamp',
        'edit_date'
      ],
      query: {
        limit: this.type === 'topic' ? 20 : 40,
        offset: 0,
        order_by: this.$route.query.order_by || 'timestamp',
        order_type: this.$route.query.order_type || this.$route.path.includes('topic') ? 'asc' : 'desc',
        message: this.$route.query.message || '',
        topic_id: '',
        to_date: this.$route.query.to_date || null,
        from_date: this.$route.query.from_date || null
      },
      pagination: {
        page: 1,
        total: null
      },
      paramsToRoute: ['message', 'order_by', 'order_type', 'page', 'to_date', 'from_date'],
      id: this.$route.query.id,
      loaded: false
    }
  },
  watch: {
    loadedComputed (val) {
      if (val) setTimeout(() => (this.loaded = true), 100) // FIX for Safari
      else this.loaded = false
    },
    '$route.query' () {
      this.init()
    }
  },
  computed: {
    ...mapGetters('forums', ['isLoaded']),
    hasExport () {
      return (this.type !== 'all' || this.query?.message) && this.messages?.length > 0
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.updateQuery()
        this.searchHandler()
      }
    },
    forTopic: {
      get () {
        return this.query.topic_id
      },
      set (obj) {
        this.query.topic_id = obj.value
        this.updateQuery()
        this.searchHandler()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.updateQuery()
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('forums.users.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    toDate : {
      get () {
        return this.query.to_date ?? null
      },
      set (value) {
        if (this.query.to_date === value) return
        this.query.to_date = value
        this.updateQuery()
        this.searchHandler()
      }
    },
    fromDate : {
      get () {
        return this.query.from_date ?? null
      },
      set (value) {
        if (this.query.from_date === value) return
        this.query.from_date = value
        this.updateQuery()
        this.searchHandler()
      }
    },
    isParrent () {
      return this.type === 'all'
    },
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    loadedComputed () {
      if (this.userId) return this.isLoaded['messages_user_' + this.userId]
      if (this.topicId) return this.isLoaded['messages_topic_' + this.topicId]
      if (this.forumId) return this.isLoaded['messages_forum_' + this.forumId]
      return this.isLoaded['messages']
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchAllTopics',
      'fetchAllMessages',
      'fetchForumMessages',
      'fetchTopicMessages',
      'fetchUserTopics',
      'fetchUserMessages',
      'addParamToQuery'
    ]),
    async topicsMethod (params = { search: '', limit: 0 }) {
      try {
        const { search, limit } = params
        const { data } = await this.fetchUserTopics({
          userId: this.userId,
          query: {
            search,
            limit,
            order_by: 'user_messages_count',
            order_type: 'desc'
          },
          posted: true
        })
        this.topics = [{ label: this.$t('all'), value: '' }]
        this.topics = data.reduce((topics, entry) => {
          try {
            if (!topics.find(topic => topic.value === entry.id)) {
              topics = topics.concat({
                label: entry.name || entry.username,
                value: entry.id,
                second: this.$t('tgcp.messages.messages') + ': ' + entry.user_messages_count
              })
            }
          } catch (error) {
            console.debug(error)
          }
          return topics
        }, this.topics)
      } catch (error) {
        console.debug(error)
      }
    },
    findTopics (search) {
      try {
        if (search) {
          this.topicsMethod({ search })
        } else {
          this.topicsMethod({ limit: 50 })
        }
      } catch (error) {
        console.debug(error)
      }
    },
    init () {
      if (event instanceof PopStateEvent) { // юзер нажал вперед/назад
        this.paramsToRoute.forEach(key => {
          const val = this.$route.query[key]
          if (val) this.query[key] = val
        })
        const offset = this.query.offset || 0
        const page = Math.floor(offset / (this.query.limit - 1) + 1)
        this.searchHandler({ page, isInit: true })
      }
    },
    async update () {
      const { query, userId, topicId, forumId, setData, clearData } = this
      try {
        let response
        if (userId) {
          response = await this.fetchUserMessages({ userId, query })
        } else if (topicId) {
          response = await this.fetchTopicMessages({ forumId, topicId, query })
        } else if (forumId) {
          response = await this.fetchForumMessages({ forumId, query })
        } else {
          response = await this.fetchAllMessages(query)
        }
        setData(response)
      } catch (err) {
        clearData(err)
      }
    },
    clearData (err) {
      this.topics = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err.message, type: 'error' })
    },
    setData (res) {
      this.messages = res.data
      this.pagination.total = res.meta.results_count
    }
  },
  created () {
    try {
      if (this.page) {
        this.query.offset = (this.page - 1 * this.query.limit)
        this.pagination.page = this.page
      }
      if (this.userId) {
        this.findTopics()
      }
    } catch (error) {
      console.debug(error)
    }
  }
}
</script>
