<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="user.answer_status === 'NOT_FOUND'" />
      <div v-else>
        <div
          class="uk-grid uk-grid-small"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation
              :user="user"
            />
          </div>
          <div class="uk-width-1-4@m"/>
        </div>
      </div>
      <div>
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'overview_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'overview_tab'
                }
              }"
            >
              {{ $t('discord.channel_details.fields_title') }}
            </router-link>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'guilds_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'guilds_tab'
                }
              }"
            >
              {{ $t('discord.user_details.guild_title') }}
            </router-link>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'messages_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'messages_tab'
                }
              }"
            >
              {{ $t('discord.user_details.message_title') }}
            </router-link>
          </li>
          <li
            v-if="user.phones && user.phones.length > 0"
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'phones_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'phones_tab'
                }
              }"
            >
              {{ $t('discord.user_details.phone_title') }}
              <span class="uk-badge">
                {{ user.phones.length }}
              </span>
            </router-link>
          </li>
        </ul>
        <div class="uk-card uk-card-default uk-padding uk-padding-small">
          <ul
            class="uk-switcher"
          >
            <li
              v-if="$route.query.details === 'overview_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'overview_tab'
              }"
            >
              <table class="uk-table uk-table-divider uk-table-left">
                <thead>
                  <th class="uk-table-shrink uk-text-right">
                    {{ $t('discord.user_details.parameter') }}
                  </th>
                  <th class="uk-table-expand">
                    {{ $t('discord.user_details.value') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td class="uk-text-right uk-text-nowrap">
                      {{ $t('discord.user_details.public_flags') }}
                    </td>
                    <td>
                      <div 
                        class="uk-margin-remove-bottom uk-flex uk-flex-row uk-flex-wrap uk-flex-left"
                        style="gap: 1rem"
                      >
                        <template v-if="publicFlags">
                          <p 
                            v-for="flag in publicFlags"
                            :key="user.id + flag"
                            class="uk-text-muted uk-margin-remove"
                          >
                            {{ flag }}
                          </p>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                    </td>
                  </tr>
                  <tr 
                    style="max-width: 50rem;"
                  >
                    <td class="uk-text-right uk-text-nowrap">
                      {{ $t('discord.user_details.banner') }}
                    </td>
                    <td
                      v-if="banner" 
                    >
                      <div 
                        style="position: relative;"
                        class="cover uk-align-left"
                      >
                        <img
                          slot="preloader"
                          :src="banner.url"
                          uk-img
                        />
                        <AvatarThumbnail
                          :src="banner.url"
                        />
                      </div>
                    </td>
                    <td
                      v-else
                      class="uk-text-left"
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li
              v-if="$route.query.details === 'guilds_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'guilds_tab',
              }"
            >
              <GuildList
                :id="user.id"
                :type="'user'"
              />
            </li>
            <li
              v-if="$route.query.details === 'messages_tab'"
              :class="{
                'uk-active': $route.query.details === 'messages_tab'
              }"
            >
              <MessageList
                :id="user.id"
                :type="'user'"
              />
            </li>
            <li
              v-if="$route.query.details === 'phones_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'phones_tab'
              }"
            >
              <ul
                class="uk-list-divider uk-width-1-1 uk-margin-remove uk-padding-remove"
              >
                <li
                  v-for="phone in user.phones"
                  :key="phone"
                  class="uk-margin-remove uk-padding-small"
                  uk-grid
                >
                  <div
                    class="uk-width-1-1 uk-text-large uk-text-left uk-padding-remove"
                  >
                    <router-link :to="{ name: 'phoneResults', params: { phone: '+' + phone.phone } }">
                      +{{ phone.phone }}
                    </router-link>
                  </div>
                  <div
                    class="uk-width-1-1 uk-margin-remove uk-padding-remove"
                  >
                    <div class="uk-text-left">
                      {{ $t('discord.user_details.label') + ' ' + phone.name_for_phone_search }}
                    </div>
                    <div class="uk-text-left">
                      {{ $t('discord.user_details.sync_date') + ' ' + dayjs(phone.sync_date) }}
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { getFlags } from '@/utils/discord'

export default {
  name: 'DiscordUserDetails',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    AvatarThumbnail: defineAsyncComponent(() => import('@/components/AvatarThumbnail')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/Discord/UserDetails/GeneralInformation')),
    MessageList: defineAsyncComponent(() => import('@/views/Discord/MessageList')),
    GuildList: defineAsyncComponent(() => import('@/views/Discord/GuildList'))
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  watch: {
    id () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('discord', ['userAvatars', 'allUsers', 'userBanners']),
    user () {
      return (this.id in this.allUsers ? this.allUsers[this.id] : null)
    },
    isLoaded () {
      return this.user ? true : false
    },
    banner () {
      return (this.id in this.userBanners && this.userBanners[this.id].url ? this.userBanners[this.id] : null)
    },
    publicFlags () {
      return this.user?.public_flags ? this.getFlags(Number(this.user.public_flags)) : null
    }
  },
  methods: {
    ...mapActions('discord', ['fetchUserById']),
    getFlags,
    init () {
      this.update()
    },
    async update () {
      await this.fetchUserById(this.id)
    }
  },
  created () {
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.info) updateQuery = { ...updateQuery, info : 'current_tab' }
    else if (updateQuery.info !== 'current_tab') updateQuery.info = 'current_tab'
    if (!updateQuery?.details) updateQuery = { ...updateQuery, details : 'overview_tab' }
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    this.update()
  }
}
</script>

<style scoped>
.cover:hover .with_info_button-button {
  visibility: visible;
}
</style>
