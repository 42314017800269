<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="group.answer_status === 'NOT_FOUND'" />
      <div v-else>
        <div
          class="uk-grid uk-grid-small"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation
              :group="group"
            />
          </div>
          <div class="uk-width-1-4@m">
            <div
              class="uk-card uk-card-default uk-margin-bottom"
            >
              <div class="uk-card-header">
                <h3 class="uk-card-title">
                  {{ $t('imo.group_details.stats') }}
                </h3>
              </div>
              <div class="uk-card-body uk-padding-small">
                <ul
                  class="uk-list uk-list-divider uk-text-left uk-width-expand"
                >
                  <li>
                    {{ $t('imo.group_details.messages_count') }}
                    <span class="uk-float-right">
                      {{ group.messages_count }}
                    </span>
                  </li>
                  <li>
                    {{ $t('imo.group_details.members_count') }}
                    <span class="uk-float-right">
                      {{ group.members_count }}
                    </span>
                  </li>
                  <li>
                    {{ $t('imo.group_details.admins_count') }}
                    <span class="uk-float-right">
                      {{ group.admins_count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isLoaded && group.answer_status !== 'NOT_FOUND'"
        class="uk-margin-top"
      >
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'overview_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'overview_tab'
                }
              }"
            >
              {{ $t('imo.group_details.overview') }}
            </router-link>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'users_tab',
              'uk-disabled': !group?.members_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'users_tab'
                }
              }"
            >
              {{ $t('imo.group_details.users_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ group.members_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'former_users_tab',
              'uk-disabled': !group?.former_members_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'former_users_tab'
                }
              }"
            >
              {{ $t('imo.group_details.former_users_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ group.former_members_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'admins_tab',
              'uk-disabled': !group?.admins_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'admins_tab'
                }
              }"
            >
              {{ $t('imo.group_details.admins_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ group.admins_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'former_admins_tab',
              'uk-disabled': !group?.former_admins_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'former_admins_tab'
                }
              }"
            >
              {{ $t('imo.group_details.former_admins_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ group.former_admins_count }}
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'messages_tab',
              'uk-disabled': !group?.messages_count
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'messages_tab'
                }
              }"
            >
              {{ $t('imo.group_details.messages_tab') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              {{ group.messages_count }}
            </span>
          </li>
        </ul>
        <div class="uk-card uk-card-default uk-padding">
          <ul
            class="uk-switcher"
          >
            <li
              v-if="$route.query.details === 'overview_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'overview_tab'
              }"
            >
              <MemberGraph 
                :id="id"
              />
              <DailyGraph 
                :id="id"
                type="group"
              />
              <WeeklyGraph 
                :id="id"
                type="group"
              />
              <AnnualGraph
                :id="id"
                type="group"
              />
            </li>
            <li
              v-if="$route.query.details === 'users_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'users_tab'
              }"
            >
              <UserList 
                :id="group.bgid"
                type="group"
                participantType="members"
              />
            </li>
            <li
              v-if="$route.query.details === 'former_users_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'former_users_tab'
              }"
            >
              <UserList 
                :id="group.bgid"
                type="group"
                participantType="members"
                participantStatus="former"
              />
            </li>
            <li
              v-if="$route.query.details === 'admins_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'admins_tab'
              }"
            >
              <UserList 
                :id="group.bgid"
                type="group"
                participantType="admins"
              />
            </li>
            <li
              v-if="$route.query.details === 'former_admins_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'former_admins_tab'
              }"
            >
              <UserList 
                :id="group.bgid"
                type="group"
                participantType="admins"
                participantStatus="former"
              />
            </li>
            <li
              v-if="$route.query.details === 'messages_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'messages_tab'
              }"
            >
              <MessageList 
                :id="group.bgid"
                type="group"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ImoGroupDetails',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/Imo/GroupDetails/GeneralInformation')),
    UserList: defineAsyncComponent(() => import('@/views/Imo/UserList')),
    MessageList: defineAsyncComponent(() => import('@/views/Imo/MessageList')),
    MemberGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityMemberGraph')),
    DailyGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityDailyGraph')),
    WeeklyGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityWeeklyGraph')),
    AnnualGraph: defineAsyncComponent(() => import('@/components/Imo/Graphs/ActivityYearGraph'))
  },
  watch: {
    id () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('imo', ['allGroups']),
    id () {
      return this.$route.params.id ?? null
    },
    group () {
      return (this.id in this.allGroups ? this.allGroups[this.id] : null)
    },
    isLoaded () {
      return this.group ? true : false
    }
  },
  methods: {
    ...mapActions('imo', ['fetchGroupById']),
    init () {
      this.update()
    },
    async update () {
      await this.fetchGroupById(this.id)
    }
  },
  created () {
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.info) updateQuery = { ...updateQuery, info : 'current_tab' }
    else if (updateQuery.info !== 'current_tab') updateQuery.info = 'current_tab'
    if (!updateQuery?.details) updateQuery = { ...updateQuery, details : 'overview_tab' }
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    this.update()
  }
}
</script>

<style scoped>
.cover:hover .with_info_button-button {
    visibility: visible;
  }
</style>
