import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class FetcherUsersAll extends Base {
  schema = Schemas.fetcher
  OPTIONS_SCHEMA = ['nickname', 'providers', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { nickname, providers } = options
      let params = {
        search: nickname,
        providers: providers && providers.split(',')
      }
    let entries = await dispatch('fetch', params)
    this.loaded = 100
    return entries
  }
}

export default FetcherUsersAll
