<template>
  <div>
    <div
      id="casebar"
      class="uk-visible@m uk-background-default uk-width-expand uk-position-z-index  uk-position-fixed uk-position-bottom"
      style="height:2rem; z-index: 3"
    >
      <span
        class="current_case uk-align-left"
        :style="'display: inline;margin-left:' + (isMainSideMenuCollapsed ? '80' : '210') + 'px;'"
      >
        <div class="uk-inline">
          <button
            class="uk-button uk-button-text"
            type="button"
          >
            <span v-if="allCases[currentCase]">
              {{ allCases[currentCase].case_title }}
            </span>
            <span v-else>
              {{ $t('cases.choose_case') }}
            </span>
          </button>
          <div
            uk-dropdown="mode: click; pos:top-left; flip: false"
            class="uk-text-left uk-width-medium"
            style="max-width:300px"
          >
            <span>
              <button
                type="button"
                class="uk-button uk-margin-small-bottom uk-button-text"
                @click="showModal()"
              >
                {{ $t('cases.add_new') }}
              </button>
              <button
                type="button"
                class="uk-button  uk-button-text"
                style="float:right"
                @click="setCurrentCase(null)"
              >
                {{ $t('cases.reset_case') }}
              </button>
            </span>
            <ul class="uk-nav uk-dropdown-nav uk-align-left uk-width-1-1">
              <li
                v-for="caseInfo in cases"
                :key="caseInfo.case_id"
                @click="activateCase(caseInfo.case_id)"
              >
                <button
                  class="uk-button uk-button-text uk-text-left uk-text-ellipsis"
                  :title="caseInfo.case_title"
                >
                  {{ caseInfo.case_title }}
                </button>
                <span
                  class="uk-text-right"
                >
                  <router-link
                    class="uk-float-right uk-width-1-6"
                    :to="{ name: 'case_details',
                           params: { caseId: caseInfo.case_id } } "
                    uk-icon="icon: arrow-right"
                  />
                  <button
                    :uk-tooltip="$t('cases.default-case')"
                    class="uk-margin-left uk-float-right"
                    uk-icon="star"
                    :class="defaultCase === caseInfo.case_id
                      && 'uk-text-primary'"
                    @click="pinCase(caseInfo.case_id)"
                  />
                </span>
              </li>
            </ul>
            <div class="uk-width-1-1 uk-margin-small-bottom">
              <input
                v-model.trim="query.search"
                type="text"
                class="uk-input uk-input-text uk-width-1-1"
                :placeholder="$t('cases.search')"
                @input="search"
              />
            </div>
            <div v-if="!hidePagination">
              <Paginate
                v-model="pagination.page"
                :page-count="lastPage"
                :click-handler="setPage"
                prev-text="<"
                next-text=">"
                :container-class="'uk-pagination uk-flex-center uk-margin-small-top'"
              />
            </div>
          </div>
        </div>
      </span>
      <span
        class="uk-text-right uk-text-muted uk-text-small uk-margin-right"
        style="font-style: italic;padding-top:0.4rem; float:right;"
      >
        {{ $t('options.version') }}: <a
          class="uk-text-muted"
          uk-toggle="#changelog"
        >
          {{ version }} ({{ releaseDate }})
        </a>
      </span>
      <span
        v-show="currentCase"
      >
        <button
          type="button"
          class="uk-button uk-button-text uk uk-float-right uk-margin-small-right"
          style="float: right;padding-bottom:0.1rem;padding-top:0.3rem;!important"
          uk-icon="file-text"
          :uk-tooltip="$t('cases.notes.notes_title')"
        />
        <div
          ref="notesdropdown"
          class="uk-card uk-card-default uk-width-1-4 uk-padding-small"
          uk-dropdown="mode: click;pos: top-right;flip:false;offset: 5;boundary: #casebar; boundary-align: true"
          style="right: 20px!important;left: 0!important;"
        >
          <CaseNotes
            v-if="currentCase"
            :caseId="currentCase"
            :dropdown="true"
            @modal-shown="$event => addNoteModalShown = $event"
          />
        </div>
      </span>
    </div>
    <div
      id="addCaseInfo"
      uk-modal
    >
      <CaseModal
        :id="'addCaseInfo'"
        class="uk-modal-dialog"
        @added="update"
      />
    </div>
    <div
      style="z-index: 1000; position: fixed; left: 0; top: 0; width: 100%;"
    >
      <progress
        v-for="(value, index) in Object.values(attachProgress)"
        :key="`progress_${index}`"
        class="uk-progress uk-progress-mini"
        :value="value"
        max="100"
      />
    </div>
    
    <div 
      id="changelog"
      uk-modal
    >
      <div
        class="change uk-modal-dialog uk-modal-body uk-padding-remove"
      >
        <button 
          class="uk-modal-close-default" 
          type="button" 
          style="position: sticky; float: right;"
          uk-close
        />
        <Changelog />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from '@/components/Paginate'
import { mapActions, mapGetters } from 'vuex'
import CaseModal from '@/components/cases/CaseModal'
import packageJSON from '../../package'
import UIkit from 'uikit'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'CasesBar',
  components: {
    CaseModal,
    Paginate,
    CaseNotes: defineAsyncComponent(() => import('@/components/cases/NotesList.vue')),
    Changelog: defineAsyncComponent(() => import('@/views/Changelog.vue'))
  },
  data () {
    return {
      noteText: '',
      cases: [],
      addNoteModalShown: false,
      loading: false,
      timer: false,
      activeCase: null,
      query: {
        limit: this.defaultCase ? 9 : 10,
        offset: 0,
        search: ''
      },
      pagination: {
        page: 1,
        total: null
      }
    }
  },
  watch: {
    defaultCase () {
      this.update()
    },
    allCases () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('cases', [
      'allCases',
      'currentCase',
      'attachProgress'
    ]),
    ...mapGetters(['isMainSideMenuCollapsed']),
    defaultCase () {
      return this.$store.state.settings.defaultCase
    },
    version () {
      const splittedVersion = packageJSON.version.split('.')
      if (splittedVersion[2] === '0') {
        return packageJSON.version.split('.').slice(0, 2).join('.')
      }
      return packageJSON.version
    },
    releaseDate () {
      return packageJSON.releaseDate
    },
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    lastPage () {
      const lastPage = Math.floor((this.pagination.total) / (this.query.limit))
      return this.pagination.total / lastPage - (this.query.limit) === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('cases', [
      'fetchCases',
      'setCurrentCase',
      'noteAdd',
      'fetchEvents',
      'getProfileById',
      'getCaseById'
    ]),
    showModal () {
      UIkit.modal('#addCaseInfo').show()
    },
    activateCase (id) {
      return this.setCurrentCase(id)
    },
    pinCase (id) {
      this.$store.dispatch('setDefaultCase', { defaultCase: id })
      this.setCurrentCase(id)
    },
    processEvents (events) {
      this.timer = setTimeout(this.eventsFetch, 30000)
      let alreadyId = []
      for (const i in events) {
        let event = events[i]
        let text = this.$t('cases.profile-text') + ' '
        if (event.profile.name) text += event.profile.name
        else if (event.profile.seed) text += event.profile.seed
        else text += event.profile_id
        if (event.user_id.toString() === this.$store.state.current_user_id.toString()) {
          continue
        }
        if (event.event_type !== 'create' && event.event_type !== 'post') continue
        if (event.user_id < 0) {
          text += ` ${this.$t('cases.has-been-updated-by-ASIRIS-system')}: `
        } else {
          text += ` ${this.$t('cases.has-been-updated-by-other-user')}: `
        }
        switch (event.type) {
            case 'suggestion':
              text += ` ${this.$t('cases.suggestion-has-been-added')}`
              if (event.event_type !== 'create') continue
              break
            case 'profiles':
              text += ` ${this.$t('cases.profile-has-been-created')}`
              break
            case 'images':
              text += ` ${this.$t('cases.image-has-been-found')}`
              break
            case 'names':
              text += ` ${this.$t('cases.name-has-been-added')}`
              break
            case 'emails':
              text += ` ${this.$t('cases.email-has-been-added')}`
              break
            case 'phones':
              text += ` ${this.$t('cases.phone-number-has-been-added')}`
              break
            case 'links':
              text += ` ${this.$t('cases.link-has-been-added')}`
              break
        }
        let caller = this
        let id = event.user_id + '_' + event.profile_id
        if (event.type === 'suggestion') {
          id += '_suggestion'
        }
        if (alreadyId.indexOf(id) > -1) continue
        alreadyId.push(id)
        this.getProfileById(event.profile_id)
        this.$notify({ group: 'general',
                       title: this.$t('cases.profile_update'),
                       text,
                       duration: 20000,
                       type: 'general',
                       data: {
                         primaryCallback: () => Promise.resolve().then(() => {
                           caller.$router.push(`/cases/${event.profile.case_id}/profile/${event.profile_id}`)
                         }),
                         primaryText: this.$t('cases.go-to-profile')
                       } })
      }
    },
    clearData (err) {
      this.cases = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err?.message, type: 'error' })
    },
    setData (res) {
      this.cases = res.cases.filter(el => el.case_id !== this.defaultCase)
      this.pagination.total = res.total
    },
    setPage (page) {
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = page
      this.update()
    },
    confirmNote () {
      this.noteAdd({ text: this.noteText })
        .then(() => {
          this.noteText = ''
          UIkit.modal(this.$refs.addNoteModalBar).hide() &&
            this.update()
        })
        .catch(() => this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' }))
    },
    eventsFetch () {
      this.fetchEvents().then(this.processEvents)
    },
    search () {
      this.query.offset = 0
      this.pagination.page = 1
      this.update()
    },
    async update (id) {
      try {
        this.setData(await this.fetchCases(this.query))
      } catch (err) {
        this.clearData()
      }
      if (this.defaultCase && !this.query.search) {
        this.getCaseById(id || this.defaultCase)
          .then((caseInfo) => {
            !this.cases.find(el => el.case_id === this.defaultCase) &&
              this.cases.unshift(this.allCases[this.defaultCase])
          })
      }
      this.eventsFetch()
    }
  },
  mounted () {
    UIkit.util.on(this.$refs.notesdropdown, 'beforehide', e => {
      this.addNoteModalShown && e.preventDefault()
    })
    this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
    .change {
      flex: 1 1 auto;
      overflow-y: auto;
      height: 85vh;
    }
    .uk-text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50%;
        display: inline-block;
    }
    .uk-progress {
      margin-top: 0!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
    }
    .uk-progress-mini {
      height: 6px;
    }
    progress {
      border-radius: 0!important;
    }
    progress::-webkit-progress-bar {
      border-radius: 0!important;
    }
    progress::-webkit-progress-value {
      border-radius: 0!important;
    }
    progress::-moz-progress-bar {
      border-radius: 0!important;
    }
</style>
