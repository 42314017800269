import config from '@/config'
import { store } from '@/store'
const axios = require('axios')

var jwtDecode = require('jwt-decode')
var lock = false
const sleep = m => new Promise(resolve => setTimeout(resolve, m))

axios.interceptors.request.use(async config => {
  if (config.type === 'apiRequest' || config.type === 'tonApiRequest') {
    if (!store.getters.auth_token) {
      lock = false
      store.dispatch('logout')
      throw new axios.Cancel('No token')
    }
    // eslint-disable-next-line
    while (lock) await sleep(500)
    var { auth_token } = JSON.parse(localStorage.getItem('asiris'))
    const jwtData = jwtDecode(auth_token)
    var expiryDate = (jwtData.exp - 30) * 1000
    if (expiryDate < Date.now()) {
      lock = true
      try {
        await store.dispatch('refresh')
        // eslint-disable-next-line
        auth_token = JSON.parse(localStorage.getItem('asiris')).auth_token
      } catch {
        throw new axios.Cancel('refresh error')
      } finally {
        lock = false
      }
    }
    // eslint-disable-next-line
    config.headers['Authorization'] = 'Bearer ' + auth_token
  }
  return config
}, err => {
  return Promise.reject(err)
})

const apiCall = ({ url, method, headers, baseURL = config.servers.auth, ...data }) => axios({
  baseURL,
  timeout: 4000,
  url,
  method,
  data,
  headers
})

const apiAuth = (url, method, headers = {}, data, server = config.servers.auth) => {
  headers['Authorization'] = 'Bearer ' + store.state.refresh_token
  return axios({
    baseURL: server,
    url,
    method,
    headers,
    data,
    type: 'apiAuth'
  })
}

const apiFileRequest = (url, method, responseType = 'stream', api, headers = {}, ...data) => {
  return axios({
    baseURL: api,
    url,
    method,
    headers,
    responseType,
    responseEncoding: 'binary',
    data: data[0],
    type: 'apiRequest'
  })
}

const apiRequest = (url, method, headers = {}, baseURL = config.servers.tgcp, data, cancel) => {
  const request = (resolve, reject) => {
    let source
    if (cancel) {
      Symbol['isExport'] || cancel.cancel()
      cancel.unset()
      source = axios.CancelToken.source()
      cancel && cancel.set(source)
    }
    axios({
      baseURL,
      url,
      method,
      headers,
      data,
      cancelToken: source && source.token,
      type: 'apiRequest'
    })
      .then(resolve)
      .catch((err) => {
        console.debug(err)
        if (axios.isCancel(err)) {
        }
        if (err.code === 'ECONNABORTED') {
          // handle timeout maybe retry if such arguments available
        }
        reject(err)
      })
      // .finally(() => cancel && cancel.unset(source))
  }
  return new Promise(async (resolve, reject) => {
    return request(resolve, reject)
  })
}

const getQueryString = (query, caseSensitive) => {
  if (!query) return ''
  return Object.keys(query).reduce((acc, key) => {
    if (query[key]=== null && key === 'is_active') return acc
    if (!query[key] && !['is_active','broadcast'].includes(key)) return acc
    const queryStr = caseSensitive ? String(query[key]) : String(query[key]).toLowerCase()
    const res = key === 'search_fields' ? queryStr : encodeURIComponent(queryStr)
    if (!res) return acc
    acc = acc ? acc + '&' : acc
    return acc + key + '=' + res
  }, '')
}

export function tonApiRequest (url, method, branch = 'v0', headers = {}, baseURL = config.servers.ton, data)  {
  const request = (resolve, reject) => {
    axios({
      baseURL: baseURL + (branch ? branch : ''),
      url,
      method,
      headers,
      data,
      transformResponse: [data  => data],
      type: 'tonApiRequest'
    })
      .then(resolve)
      .catch((err) => {
        console.debug(err)
        if (axios.isCancel(err)) {
        }
        if (err.code === 'ECONNABORTED') {
          // handle timeout maybe retry if such arguments available
        }
        reject(err)
      })
      // .finally(() => cancel && cancel.unset(source))
  }
  return new Promise(async (resolve, reject) => {
    return request(resolve, reject)
  })
}

export function getQueryArrayString (query, caseSensitive) {
  if (!query) return ''
  return Object.keys(query).reduce((acc, key) => {
      if (query[key]=== null && key === 'is_active') return acc
      if ((!query[key] && !/^-?\d+$/.test(query[key])) && !['is_active','broadcast'].includes(key)) return acc
      if (query[key] instanceof Array) {
        const arr = query[key]
        if (Array.isArray(arr)) {
          let ret = ''
          for (const item of arr) {
            ret = ret ? ret + '&' : ret
            ret = ret + key + '=' + item
          }
          acc = acc ? acc + '&' : acc
          return acc + ret
        }
        return ''
      } else {
        const queryStr = caseSensitive ? String(query[key]) : String(query[key]).toLowerCase()
        const res = key === 'search_fields' ? queryStr : encodeURIComponent(queryStr)
        if (!res) return acc
        acc = acc ? acc + '&' : acc
        return acc + key + '=' + res
      }
  }, '')
}

export {
  apiCall,
  apiRequest,
  getQueryString,
  apiAuth,
  apiFileRequest
}
