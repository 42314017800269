import { apiRequest } from '@/utils/api'

export default {
  namespaced: true,
  state: {
    isLoaded: {},
    news: {}
  },
  gettres: {
    news: state => state.news,
    isLoaded: state => state.isLoaded
  },
  mutations: {
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    fetchNews: (state, { key, news }) => {
      state.news[key] = news
    }
  },
  actions: {
    fetchNews ({ dispatch, commit, state, rootState }, locale = 'ru') {
      commit('startLoad', 'news')
      apiRequest(`https://api.defence.tools/asiris/proxy/news/?search=${locale}`, 'GET', { 'Authorization': 'Bearer ' + rootState.auth_token })
        .then(data => {
          let news = data.data
          Object.keys(news).forEach((e, i) => {
            commit('fetchNews', { key: e.match(/([\d]{3,4}-[\d]{2,2}-[\d]{2,2})/)[0], news: news[e] })
          })
        })
        .catch(err => {
          console.debug(err)
          if (err.response.status === 404) console.error(err)
        })
        .finally(() => {
          commit('stopLoad', 'news')
        })
    }
  }
}
