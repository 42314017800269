<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <h3 class="uk-card-header uk-text-left my-border-bottom my-spacing-bottom my-margin-left">
        {{ $t('tgcp.user_details.general-information') }}
      </h3>
      <div class="uk-card-body uk-padding-remove-top">
        <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
          <AddStoredButton
            provider="forums"
            type="account"
            :data="user"
          />
        </div>
        <ul
          uk-tab
        >
          <li class="uk-active">
            <a
              id="current_tab"
              href="#current_tab"
            >
              {{ $t('tgcp.tasks.current-data') }}
            </a>
          </li>
          <li v-if="history.length">
            <a
              id="history_tab"
              href="#history_tab"
            >
              {{ $t('tgcp.tasks.historical-data') }}
            </a>
          </li>
        </ul>

        <ul class="uk-switcher">
          <li>
            <a name="current_tab" />
            <div
              class="uk-grid"
              data-uk-grid-margin
            >
              <div class="uk-width-1-4@m">
                <AvatarList :data="user" />
              </div>
              <div class="uk-width-expand@m uk-text-left">
                <h3 class="uk-margin-remove-top uk-margin-remove-bottom">
                  {{ user.first_name }} {{ user.last_name }}
                </h3>
                <div
                  class=""
                  uk-grid
                >
                  <div class="uk-width-auto">
                    <h3 class="uk-text-left">
                      {{ user.display_name }}
                      <router-link
                        :to="{ name: 'nicknameResults',
                               params: { nickname: user.display_name }}"
                        class="uk-icon-link  uk-icon"
                        uk-icon="search"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-svg="search"
                        >
                          <circle
                            fill="none"
                            stroke="#000"
                            stroke-width="1.1"
                            cx="9"
                            cy="9"
                            r="7"
                          />
                          <path
                            fill="none"
                            stroke="#000"
                            stroke-width="1.1"
                            d="M14,14 L18,18 L14,14 Z"
                          />
                        </svg>
                      </router-link>
                    </h3>
                  </div>
                </div>
                <span
                  class="uk-text-small uk-margin-remove-bottom uk-text-muted"
                >
                  #{{ user.id }}
                </span>
                <span
                  v-if="user.forum_user_id"
                  class="uk-text-small uk-margin-remove-bottom uk-text-muted forum_user_id-inHeader"
                >
                  {{ this.$t('forums.users.sort.forum_user_id') }}: {{ user.forum_user_id }}
                </span>
                <h4
                  v-if="user.status"
                  class="uk-text-small uk-text-muted my-margin-bottom"
                >
                  {{ escape(user.status) }}
                </h4>
                <p 
                  v-if="user.bio"
                  v-html="extractedData"
                  class="uk-text-meta uk-text-left uk-margin-right"
                />
                <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-top">
                  <li v-if="user.url">
                    Link: {{ user.url }} <a
                      :href="user.url"
                      uk-icon="link"
                      class="ui-link"
                    />
                  </li>
                  <li>
                    {{ $t('tgcp.user_details.forum') }} <router-link
                      :to="{
                        name: 'forums_forum_details',
                        params: {
                          id: user.forum.id
                        }
                      }"
                    >
                      {{ user.forum.name }}
                    </router-link>
                    <span v-if="user.forum_user_id">
                      | {{ $t('forums.users.sort.forum_user_id') }}: {{ user.forum_user_id }}
                    </span>
                  </li>
                  <li v-if="user.phone_numer">
                    {{ $t('tgcp.user_details.phone' ) }}: {{ user.phone_number }}
                  </li>
                  <li v-if="user.birthdate">
                    {{ $t('tgcp.user_details.birthdate') }}: {{ user.birthdate }}
                  </li>
                  <li v-if="user.age">
                    {{ $t('tgcp.user_details.age') }}: {{ user.age }}
                  </li>
                  <li v-if="user.registration_date">
                    {{ $t('tgcp.user_details.registration-date') }}: {{ dayjs(user.registration_date, true) }}
                  </li>
                  <li v-if="user.last_visit_date ">
                    {{ $t('tgcp.user_details.last-visit') }}: {{ dayjs(user.last_visit_date, true) }}
                  </li>
                  <li
                    v-if="user.signature"
                    class="uk-text-break"
                  >
                    {{ $t('tgcp.user_details.signature') }}: {{ escape(user.signature) }}
                  </li>
                  <li v-if="user.website">
                    {{ $t('tgcp.user_details.website') }}: {{ user.website }}
                  </li>
                </ul>
              </div>
              <div class="uk-width-1-4@m uk-padding-remove uk-text-left">
                <ul class="uk-list">
                  <li v-if="user.email">
                    Email:
                    <router-link
                      :to="{ name: 'emailResults',
                             params: { email: user.email }}"
                      class="uk-icon-link  uk-icon"
                    >
                      {{ user.email }}
                    </router-link>
                  </li>
                  <li v-if="user.telegram_user">
                    Telegram:
                    <router-link
                      :to="{ name: 'nicknameResults',
                             params: { nickname: user.telegram_user }}"
                      class="uk-icon-link  uk-icon"
                      uk-icon="search"
                    >
                      {{ user.telegram_user }}
                    </router-link>
                  </li>
                  <li v-if="user.aim">
                    AIM: {{ user.aim }}
                  </li>
                  <li v-if="user.icq">
                    ICQ: {{ user.icq }}
                  </li>
                  <li v-if="user.jabber">
                    Jabber: {{ user.jabber }}
                  </li>
                  <li v-if="user.ip">
                    IP: {{ user.ip }}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li v-if="history.length">
            <a name="history_tab" />
            <HistoricalData :historical="history" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import AvatarList from '@/components/Forums/AvatarList'
import extractData from '@/utils/dataExtraction'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import HistoricalData from '@/components/Forums/UserDetails/HistoricalData.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ForumsUserGeneralInfo',
  components: {
    AvatarList,
    AddStoredButton,
    HistoricalData
  },
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      history: []
    }
  },
  computed: {
    avatarCdnPath () {
      if (this.user.avatar_cdn_path) {
        return this.$gateway(this.user.avatar_cdn_path)
      }
      return null
    },
    extractedData () {
      return this?.user?.bio ? extractData(this.user.bio) : ''
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchUserHistory'
    ]),
    escape (text) {
      return text.replace(/[{}]/g, '').replace(/<[^>]*>/gm, '')
    }
  },
  created () {
    try {
      this.fetchUserHistory(this.user.id)
        .then(history => {
          this.history = history
        })
    } catch (error) {
      console.debug(error)
    }
  }
}
</script>
<style scoped>
.my-margin-top {
  margin-top: 20px;
}
.my-spacing-bottom {
  padding-bottom: 0;
  margin-bottom: 0
}
.my-border-bottom {
  border-bottom:  0;
}
.my-margin-left {
  margin-left: 10px
}
.forum_user_id-inHeader {
  margin-left:1rem; padding-left:1rem;border-left: 1px solid #999
}
</style>
