<template>
  <div>
    <div
      class="uk-card uk-card-default uk-card-small"
      :class="{ 'hide-nsfw' : hideTopic, 'quoted': level > 0}"
    >
      <Breadcrumbs
        v-if="hasBreadcrumbs"
        :topicId="topic.id"
        :expanded="true"
        class="uk-margin-remove-bottom uk-margin-small-left"
      />
      <div
        class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-remove"
      >
        <AddStoredButton
          v-if="showStoredButton"
          type="topic"
          provider="forums"
          :data="topic"
        />
      </div>
      <div
        v-if="topic"
        class="uk-card-body"
      >
        <div
          class="uk-flex uk-flex-column uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-remove"
        >
          <div>
            <AddStoredButton
              v-if="showStoredButton"
              type="topic"
              provider="forums"
              :data="topic"
            />
          </div>
          <div v-if="topicURL">
            <a
              :href="topicURL"
              uk-icon="link"
              class="ui-link"
            />
          </div>
        </div>
        <div
          class="uk-grid-small uk-flex-middle uk-text-break uk-flex-between uk-flex-nowrap"
          uk-grid
        >
          <div
            v-if="topic"
            class="uk-width-2-3"
          >
            <router-link
              :to="{ name: 'forums_topic_details', params: { topicId: String(topic.id), forumId: String(topic.forum_id) }}"
              class="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom uk-width-1-1"
            >
              <h3
                v-html="xss ? xss : $t('forums.unnamed-topic') "
                class="uk-card-title uk-margin-remove-bottom uk-text-left"
              />
            </router-link>
            <div
              class="uk-text-meta uk-float-left uk-align-left uk-text-left"
            >
              <router-link
                :to="{ name: 'forums_topic_details', params: { topicId: String(topic.id), forumId: String(topic.forum_id) }}"
              >
                #{{ topic.id + ' ' }}
              </router-link>
              <template v-if="forum && hasName">
                {{ $t('forums.list.forum') }}
                <router-link
                  :to="{ name: 'forums_forum_details', params: { id: topic.forum_id }}"
                >
                  {{ forum.name }}
                </router-link>
              </template>
              <template v-if="topic.creator_id">
                {{ ' ' + $t('forums.list.creator') }}:
                <router-link
                  :to="{ name: 'forums_user_details', params: { id: user.id || topic.creator_id }}"
                >
                  {{ user.display_name || topic.creator_id }}
                </router-link>
              </template>
            </div>
          </div>
          <div 
            class="uk-width-auto uk-text-left uk-margin-small-top"
            style="min-width: 140px"
          >
            <ul
              v-if="topic.children_count === 0 && topic.messages_count > 0"
              class="uk-text-meta uk-text-left uk-list"
            >
              <li>
                {{ $t('forums.messages') }}: {{ topic.messages_count }}
              </li>
              <li>
                {{ $t('forums.users-title') }}: {{ topic.users_count }}
              </li>
            </ul>
          </div>
          <div
            v-if="$route.name !== 'forums_topic_details'"
            class="uk-width-1-5 uk-margin-small-top"
          >
            <button
              v-if="topic.children_count > 0"
              class="uk-button uk-button-default"
              @click="next = !next"
            >
              {{ next ? $t('list.hide') : $t('list.child-themes') + ' (' + topic.children_count + ')' }}
            </button>
            <div v-if="topic.children_count === 0 && topic.messages_count > 0">
              <router-link
                class="uk-button uk-button-default"
                :to="{ name: 'forums_topic_details', params: { topicId: topic.id, forumId: topic.forum_id }}"
              >
                {{ $t('forums.list.go-to-messages') }}
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="uk-text-left"
        >
          <span v-html="extractedData(topic.description)"/>
        </div>
        <div
          v-if="messages && topic.messages_count > 0 && (topic.children_count || isFromDetail)"
        >
          <ul
            class="uk-margin-top"
            uk-tab
            @click="activateTab"
          >
            <li
              class="uk-flex"
            >
              <a
                id="users_tab"
                class="uk-padding-remove"
                href="#users_tab"
              >
                {{ $t('forums.messages') }}
              </a>
              <span class="uk-badge uk-margin-small-left">
                <span>
                  {{ topic.messages_count }}
                </span>
              </span>
            </li>
            <li
              class="uk-flex"
              :class="checkForTab('users')"
            >
              <a
                id="users_tab"
                class="uk-padding-remove"
                href="#users_tab"
              >
                {{ $t('forums.users-title') }}
              </a>
              <span class="uk-badge uk-margin-small-left">
                <span>
                  {{ topic.users_count }}
                </span>
              </span>
            </li>
          </ul>
          <ul
            v-if="topic"
            class="uk-switcher"
          >
            <li>
              <a name="messages_tab"/>
              <MessageList

                :id="topic.id + 'topic'"
                :page="messagePage"
                :class="type !== 'full' ? 'uk-height-large uk-overflow-auto' : ''"
                :forumId="topic.forum_id"
                :topicId="topic.id"
                type="topic"
              />
            </li>
            <li>
              <a name="users_tab"/>
              <UserList
                v-if="activeTab.includes('users_tab')"
                :forumId="topic.forum_id"
                :topicId="topic.id"
                type="members"
              />
            </li>
          </ul>
        </div>
        <ForumsTopicsList
          v-if="next"
          :key="topic.id"
          class="uk-margin-left"
          :forumId="topic.forum_id"
          :parentId="topic.id"
          :level="isFromDetail ? level : level + 1"
          type="topic"
        />
      </div>
      <div
        v-if="hideTopic"
        class="uk-position-absolute uk-position-top-center uk-padding unhide-block uk-text-bold uk-text-large"
        @click="hideTopic = false"
      >
        {{ $t('forums.show-nsfw') }}
      </div>
    </div>
  </div>
</template>

<script>
import extractData from '@/utils/dataExtraction'
import { mapActions, mapGetters } from 'vuex'
import xss from 'xss'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ForumsTopicItem',
  components: {
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton')),
    UserList: defineAsyncComponent(() => import('@/views/Forums/UserList')),
    Breadcrumbs: defineAsyncComponent(() => import('@/components/Forums/TopicDetails/Breadcrumbs'))
  },
  props: {
    topic: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    },
    isFromDetail: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'nested',
      validator: val => ['full', 'nested'].includes(val)
    },
    messagePage: {
      type: [Number, String]
    },
    showStoredButton: {
      type: Boolean,
      default: true
    },
    hasBreadcrumbs: {
      type: [Boolean, Number],
      default: false
    }
  },
  data () {
    return {
      next: this.type === 'full' && this.topic.children_count,
      messages: this.type === 'full',
      activeTab: [this.$route.query.tab || 'messages_tab'],
      hideNsfw: true
    }
  },
  computed: {
    ...mapGetters(['settings']),
    ...mapGetters('forums', ['allForums', 'allUsers']),
    hasName () {
      return this.$route.name !== 'forums_forum_details'
    },
    forum () {
      return this.allForums[this.topic.forum_id]
    },
    user () {
      return this.allUsers[this.topic.creator_id] || {}
    },
    topicURL () {
      if (!this?.topic?.url?.startsWith('/')) {
        return this?.topic?.url
      }
      return ''
    },
    xss () {
      return this?.topic?.name ? xss(this.topic.name, {
        whiteList: {},
        stripIgnoreTag: true,
        stripIgnoreTagBody: ['script']
      }) : ''
    },
    hideTopic: {
      get () {
        return this.level === 0 && this.$route.name === 'forums_topics_list' && this.settings?.blur_nsfw_forums && this.topic?.forum_nsfw && this.hideNsfw
      },
      set (value) {
        this.hideNsfw = value
      }
    }
  },
  methods: {
    ...mapActions('forums', ['fetchForumById', 'fetchUserById']),
    extractedData (text) {
      return extractData(text)
    },
    activateTab (tab) {
      tab = tab.target ? tab.target.id : tab
      if (!tab) return
      !this.activeTab.includes(tab) &&
        this.activeTab.push(tab)
    },
    checkForTab: function (tab) {
      if (tab === this.activeTab) {
        return 'uk-active'
      }
      if (tab === 'users' && this.topic.users_count === 0) return 'uk-disabled'
      if (tab === 'messages' && this.topic.messages_count === 0) return 'uk-disabled'
    }
  },
  beforeCreate () {
    this.$options.components.ForumsTopicsList = require('@/views/Forums/TopicsList').default
    this.$options.components.MessageList = require('@/views/Forums/MessageList').default
  },
  mounted () {
    this.fetchForumById(this.topic.forum_id)
    if (this.topic.creator_id) this.fetchUserById(this.topic.creator_id)
  }
}
</script>
<style scoped>
.uk-card-small .uk-card-body {
  padding: 0 15px 10px
}
.uk-card-title {
  font-size: 1.3rem;
  line-height: 1.2
}
</style>
