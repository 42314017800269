<template>
  <div>
    <h3>
      {{ $t('imo.tasks.import_task_title') }}
    </h3>
    <Loader v-if="loading"/>
    <div v-if="total > 0">
      {{ $t('imo.tasks.upload_results') }}
      <table class="uk-table">
        <tr>
          <th>
            {{ $t('imo.tasks.total_tasks') }}
          </th>
          <td>
            {{ total }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('imo.tasks.added_tasks') }}
          </th>
          <td>
            {{ added }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('imo.tasks.duplicate_tasks') }}
          </th>
          <td>
            {{ duplicates }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('imo.tasks.failed_tasks') }}
          </th>
          <td>
            {{ error }}
          </td>
        </tr>
      </table>
    </div>
    <form
      enctype="multipart/form-data"
      class="uk-form-horizontal uk-form"
      @submit.prevent="add"
    >
      <div uk-form-custom="target: true">
        <input
          id="imo-upload-task"
          ref="imoBatchImportFile"
          type="file"
          name="files=[]"
          style="height: 2.5rem"
          @change="handleFileSelect"
        />
        <input
          class="uk-input uk-form-width-medium"
          type="text"
          :placeholder="$t('imo.tasks.select_file')"
          :class="errors.file && 'uk-form-danger'"
          disabled
          style="height: 2.5rem;"
        />
      </div>
      <div class="uk-width-1-1 uk-float-right">
        <input
          type="submit"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
          :value="$t('tgcp.tasks.process_title')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'ImoAddBatchTasks',
  components: {
    Loader
  },
  data () {
    return {
      total: 0,
      added: 0,
      duplicates: 0,
      error: 0,
      tasks: [],
      description: '',
      errors: {
        file: false
      }
    }
  },
  computed: {
    ...mapGetters('imo', ['isLoaded']),
    loading () {
      return this.isLoaded['tasks_batch'] === false
    }
  },
  methods: {
    ...mapActions('imo', [
      'massTaskAdd'
    ]),
    clear () {
      this.total = 0
      this.added = 0
      this.duplicates = 0
      this.error = 0
      this.tasks = []
    },
    add () {
      const { tasks } = this
      if (tasks.length) {
        return this.massTaskAdd({ tasks })
          .then(res => {
            this.added = res.created.length
            this.duplicates = res.exists.length
            this.error = res.errors.length
            this.errors.file = false
            this.$emit('added')
          })
      }
    },
    handleFileSelect () {
      let file = this.$refs.imoBatchImportFile?.files[0]
      this.errors.file = false
      if (!file) {
        this.$notify({ group: 'general', title: this.$i18n.t('imo.tasks.no_file'), text, type: 'warn' })
        this.errors.file = true
        return
      }
      let regexp = new RegExp(/[^,;&\n ]{1,}/, 'g')
      let reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onloadend = evt => {
        this.clear()
        var text = evt.target.result
        let tasks = text.match(regexp)
        this.total = tasks.length
        tasks.forEach(task => {
          this.tasks.push({
            joinlink: task,
            description: this.description
          })
        })
      }
    }
  }
}
</script>
