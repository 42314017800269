import { apiRequest } from '@/utils/api'
import GeneralUser from '../../utils/providers/generalUser'

import config from '@/config'
const { servers: { lookups } } = config

const allowedTypes = [ 'id', 'email', 'phone' ]

export default {
  namespaced: true,
  state: {
    id: [],
    email: [],
    phone: [],
    isLoaded: {}
  },
  getters: {
    resultsId: state => state.id,
    resultsEmail: state => state.email,
    resultsPhone: state => state.phone,
    isLoaded: state => state.isLoaded
  },
  mutations: {
    setResults (state, { type, payload }) {
      if (!allowedTypes.includes(type)) return
      state[type] = payload
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    }
  },
  actions: {
    async fetch ({ commit }, { search, type }) {
      if (!allowedTypes.includes(type)) throw new Error('You have to provide a type')
      commit('startLoad', type)
      try {
        const url = type === 'id'
      ? `/id_lookup_services/facebook/?search=${search}`
      : `/${type}_services/facebook/?search=${encodeURIComponent(search)}&full_answer=true`

      const { data: { data }} = await apiRequest(url, 'GET', {}, lookups)
      if (!data) {
        return null
      }

      commit('setResults', { type, payload: data.map(el => new GeneralUser(el)) })
      return data
      } catch {
        commit('setResults', { type, payload: [] })
      }finally {
        commit('stopLoad', type)
      }



    }
  }
}
