export default {
  methods: {
    changeDate (e) {
      if (e.length !== 2) return
      this.dateRange = e.map(date => this.dayjs(date, true))
      this.update({
        from_date: this.dateRange[0],
        to_date: this.dateRange[1]
      })
    }
  }
}
