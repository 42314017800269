const Buffer = require('buffer/').Buffer

export const telegramExp = /((https?:)?(\/\/)?(w{3}\.)?(t\.me|telegram\.me|t\\-do\.ru|tlgg\.ru)\/(((joinchat\/|\+)(?<URL_REF>[a-zA-Z-][\w-]{21}|[0-9a-zA-Z_-][\w-]{14,16}|[0-9a-fA-F-]{32}))|((s\/)?(?<URL_NAME>(?!(joinchat)[a-z][a-z0-9_]{4,31})[a-z][a-z0-9_]{4,31})(\/(?<URL_ID>[0-9]+))?(\/(?<URL_ID2>[0-9]+))?)))|^\s*@?(?<NAME>[a-z][a-z0-9_]{4,31})\s*$|(https?:)?\/\/(?<URL_NAME_A>[a-z][a-z0-9_]{4,31})\.t\.me/i
export const telegramGeo = /^[+-]?(([1-8]?[0-9])(.[0-9]{1,6})?|90(.0{1,6})?);[+-]?((([1-9]?[0-9]|1[0-7][0-9])(.[0-9]{1,6})?)|180(.0{1,6})?)$/
export const notTelegramExp = /(https?:)?(\/\/)?(w{3}\.)?(t\.me|telegram\.me|t\\-do\.ru|tlgg\.ru)\/addstickers\/.+/
export const telegramChatExp = /(https?:)?(\/\/)?(w{3}\.)?(t\.me|telegram\.me|t\\-do\.ru|tlgg\.ru)\/c\/(?<URL_NAME>\d+)\/(?<URL_ID>\d+)/
const regexps = {
  isJoinLink: /^https?:\/\/(.+?)\/(?:joinchat\/|\+)(.+)$/i,
  isNotUserId: /^AAAA.+$/i,
  telegramExp
}
const isJoinLink = (link) => {
  return regexps.isJoinLink.test(link)
}

export const isTelegramLink = value => {
  try {
    const data = telegramExp.exec(value)
    return (
      data?.groups?.URL_REF ||
      data?.groups?.URL_NAME ||
      data?.groups?.URL_NAME_A
    )
  } catch (error) {
    console.debug(error)
  }
  return false
}

const extractLinkId = (link) => {
  const linkData = regexps.isJoinLink.exec(link)
  if (linkData && linkData[2]) {
    return linkData[2]
  } else {
    return ''
  }
}

const isUserId = (id) => {
  return id && String(id).length === 22 && !regexps.isNotUserId.test(id)
}

const extractUserId = (id) => {
  if (!id) return ''

  return Buffer.from(id, 'base64').readUInt32BE(0, 4)
}

export default {
  isJoinLink,
  extractLinkId,
  isUserId,
  extractUserId
}
