<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div>
        <form>
          <span v-if="type === 'all'">
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('twitter.hashtags.search-with-wildcard')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
          </span>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="update"
            >
              <span uk-search-icon/>
            </button>
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div class="uk-margin-top uk-card uk-card-default uk-card-body">
        <div v-if="!hidePagination || !isLoaded['hashtags']">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
        <table class="uk-table uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr class="uk-text-center uk-text-nowrap uk-text-center">
              <th class="uk-text-center">
                <button
                  class="uk-button uk-button-text uk-text-nowrap uk-text-center"
                  @click="setOrder('id')"
                >
                  {{ $t('twitter.hashtags.id') }}
                  {{ query.order_by === 'id' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center">
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('value')"
                >
                  {{ $t('twitter.hashtags.value') }}
                  {{ query.order_by === 'value' ? selectedOrder : '' }}
                </button>
              </th>
              <th
                v-if="type === 'all'"
                class="uk-text-center"
              >
                {{ $t('hoop.stats.channels') }}
              </th>
              <th
                v-if="type === 'together'"
                class="uk-text-center"
              >
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('count')"
                >
                  {{ $t('twitter.hashtags.together_count') }}
                  {{ query.order_by === 'count' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center">
                {{ $t('twitter.hashtags.actions') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="isLoaded['hashtags']">
            <Item
              v-for="hashtag in hashtags"
              :key="hashtag.id"
              :tag="hashtag"
            />
          </tbody>
        </table>
        <div
          v-if="!isLoaded['hashtags']"
          class="uk-align-cetner uk-width-1-1"
        >
          <Loader />
        </div>
        <NotFound v-if="isLoaded['hashtags'] && hashtags.length === 0" />
        <div v-if="!hidePagination || !isLoaded['hashtags']">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'

export default {
  name: 'TwitterHashtagList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Hoop/Hashtags/Item')),
    vueSelect,
    Paginate,
    Loader
  },
  props: {
    id: {
      type: [Number, String],
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'together'].includes(val)
    }
  },
  data () {
    return {
      hashtags: [],
      orderList: ['id', 'value', ...(this.type === 'together' ? ['count'] : [])],
      query: {
        limit: 10,
        offset: 0,
        search: this.$route.query.search || '',
        order_by: this.$route.query.order_by || this.type === 'all' ? 'id' : 'value',
        order_type: this.$route.query.order_type || this.type === 'all' ? 'asc' : 'desc'
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.setPage(1)
    }
  },
  computed: {
    ...mapGetters('hoop', ['isLoaded']),
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.update()
      }
    },
    orderListTranslated () {
      const orderList = ['id', 'value']
      return orderList.map(word => ({ label: this.$t('hoop.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('twitter.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('twitter.sort.sort_type_desc') }
      ]
    },
    hidePagination () {
      return Number(this.pagination.total) < Number(this.query.limit)
    },
    lastPage () {
      return Math.floor(Number(this.pagination.total) / this.query.limit)
    },
    selectedOrder () {
      return this.query.order_type === 'asc' ? '↑' : '↓'
    }
  },
  methods: {
    ...mapActions('hoop', ['fetchAllHashtags', 'addParamToQuery', 'fetchTogetherHashtags']),
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      if (this.type === 'all') this.addParamToQuery({ page: this.pagination.page })
      this.update()
    },
    setOrder (by) {
      this.query.order_type = this.query.order_type === 'desc' ? 'asc' : 'desc'
      this.query.order_by = by
      this.addParamToQuery({ order_by: this.query.order_by }) && this.addParamToQuery({ order_type: this.query.order_type }) && this.update()
    },
    setData (res) {
      this.hashtags = res.data
      this.pagination.total = res.meta.results_count
    },
    handleError () {
      this.hashtags = []
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    async update () {
      if (!['all', 'user', 'together'].includes(this.type)) return
      const [method, query] = ['all', 'user'].includes(this.type) ? ['fetchAllHashtags', this.query] : ['fetchTogetherHashtags', { id: this.id, query: this.query }]
      try {
        const data = await this[method](query)
        this.setData(data)
      } catch (err) {
        !err.__CANCEL__ && this.handleError(err)
      }
    }
  },
  mounted () {
    this.setPage(Number(this.$route.query.page) || 1)
  }
}
</script>
