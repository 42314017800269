<template>
  <VSelect
    v-model="selectedItem"
    :options="options"
    :filterable="false"
    :resetOnOptionsChange="false"
    :clearable="false"
    @open="onOpen"
    @close="onClose"
    @search="onDebouncedSearch"
  >
    <template
      v-if="hasNextPage"
      #list-footer
    >
      <li
        ref="load"
        class="loader"
      />
    </template>
  </VSelect>
</template>

<script>
import VSelect from 'vue-select'

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  name: 'VueServerSelect',
  components: {
    VSelect
  },
  props: {
    count: {
      type: Number,
      default: 30
    },
    defaultOptions: {
      type: Array
    },
    loadDataPortion: {
      required: true,
      type: Function
    },
    refreshList: {
      type:Boolean,
      default: false
    },
    modelValue: null
  },
  emits: ['update:modelValue'],
  data: () => ({
    hasNextPage: true,
    observer: null,
    options: [],
    offset: 0,
    search: '',
    searchDebounceTimer: null
  }),
  watch: {
    refreshList (value) {
      if (value) {
        this.offset = 0
        this.search = ''
        this.loadData(true)
      }
    }
  },
  computed: {
    selectedItem: {
      get () {
        const caller = this
        if (!this.modelValue) {
          return this.modelValue
        }
        const result = this.options.find(item => item.value === caller.modelValue.value)
        if (result) {
          return result
        } else {
          return (this.options && this.options.length > 0) ? this.options[0] : null
        }
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async loadData (reload = false) {
      try {
        const portion = await this.loadDataPortion(this.search, this.offset, this.count)
        if (reload) {
          this.options = []
        }

        this.hasNextPage = portion.length !== 0

        if (this.options.length === 0) {
          this.defaultOptions.forEach(item => {
            this.options.push(item)
          })
        }
        portion.forEach(item => {
          this.options.push(item)
        })
      } catch (e) {
        console.error(e)
      }
    },
    onDebouncedSearch (search) {
      if (this.searchDebounceTimer) {
        clearTimeout(this.searchDebounceTimer)
      }
      let caller = this
      this.searchDebounceTimer = setTimeout(() => {
        caller.offset = 0
        caller.search = search
        caller.loadData(true)
      }, 200)
    }
  },
  async mounted () {
    if (this.options.length === 0) {
      await this.loadData()
    }
    this.observer = new IntersectionObserver(async function (entries, observer) {
      for (const entry in entries) {
        if (entry.isIntersecting) {
          const ul = target.offsetParent
          const scrollTop = target.offsetParent.scrollTop
          this.offset += this.count
          await this.loadData()
          await this.$nextTick()
          ul.scrollTop = scrollTop
        }
      }
    })
  }
}
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
