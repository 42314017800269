<template>
  <div class="uk-card uk-card-default uk-card-body">
    <h3>
      {{ $t('imo.tasks.add_task_title') }}
    </h3>
    <form @submit.prevent="submit">
      <Loader v-if="processing"/>
      <div v-else>
        <div class="uk-inline uk-width-1-1">
          <input
            v-model.trim="task"
            type="text"
            :class="{'uk-form-danger': errors.validation}"
            :placeholder="$t('imo.tasks.group_invite')"
            class="uk-input"
          />
        </div>
        <textarea
          v-model="description"
          class="uk-textarea uk-margin-top"
          :placeholder="$t('imo.tasks.task_description')"
        />
      </div>
      <div
        class="uk-margin-top uk-text-center"
      >
        <div>
          <a
            uk-icon="icon: warning; ratio: 1"
            :value="null"
            :class="priority ? 'uk-text-primary' : 'uk-text-muted'"
            :uk-tooltip="`title: ${$t('imo.tasks.priority_field_title')}`"
            @click="priority = !priority"
          />
          <a
            uk-icon="icon: bolt; ratio: 1"
            :value="null"
            class="uk-margin-left"
            :uk-tooltip="'title: ' + $t('imo.tasks.realtime')"
            :class="realtime ? 'uk-text-primary' : 'uk-text-muted'"
            @click="realtime = !realtime"
          />
        </div>
      </div>
      <div class="uk-margin">
        <button
          class="uk-button uk-button-primary"
          type="submit"
        >
          {{ $t('imo.tasks.submit') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'
import { inviteLinkCheck } from '@/utils/imo'

export default {
  name: 'ImoAddTasks',
  components: {
    Loader
  },
  props: {
    link: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      task: this.link ?? '',
      description: '',
      priority: false,
      realtime: false,
      processing: false,
      errors: {
        validation: false
      }
    }
  },
  computed: {
    validLink () {
      return this.inviteLinkCheck(this.task)
    }
  },
  methods: {
    ...mapActions('imo', [
      'addTask'
    ]),
    inviteLinkCheck,
    async submit () {
      const { task, description, priority, realtime } = this
      if (!this.validLink) {
        this.errors.validation = true
        return
      }
      this.processing = true
      try {
        const status = await this.addTask({ joinlink: task, description, priority: priority ? 5 : 1, high_priority: realtime })

        switch (status) {
            case 200:
              this.$notify({ group: 'general', text: this.$t('imo.tasks.already_exists'), type: 'warn' })
              break
            case 201:
              this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('whatsapp.tasks.task_added_msg'), type: 'success' })
              break
        }

        this.errors.validation = false
        this.$emit('added')
        this.task = ''
        this.description = ''
        this.priority = false
      } catch {
        this.errors.validation = true
      } finally {
        this.processing = false
      }
    }
  }
}
</script>
