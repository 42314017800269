<script>
import Loader from '@/components/TGCP/Loader'
import { formatTons } from '@/utils/ton-filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NTFGrid',
  components: {
    Loader
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    account: {
      type: String,
      required: true
    },
    minterFlag: {
      type: Boolean,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      firstLT: 0,
      lastLT: 0,
      itemCount: 18,
      pagination: {
        page: 1,
        total: 0
      },
      maxExploredPage: 0
    }
  },
  watch: {
    account: {
      handler () {
        if (this.pagination.page === 1) this.updateValues()
        else this.pagination.page = 1
      },
      deep: true
    },
    defaultLength: {
      handler (to, from) {
        this.itemCount = to
        if (this.pagination.page === 1) this.updateValues()
        else this.pagination.page = 1
      },
      deep: true
    },
    'pagination.page' : {
      async handler (to, from) {
        if (to === 1 || (to > from && to > this.maxExploredPage)) { 
          this.maxExploredPage = to
          await this.updateValues()
        }
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts', 'getTonMetaItems', 'getTonMetaRelations', 'getTonMetadata']),
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_account_nfts_'  + this.account] ?? false
    },
    hidePagination () {
      return !this.isLoaded || (this.pagination.total ? this.pagination.total : this.keys.length ) < this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage : lastPage + 1
    },
    currentBatch () {
      const { keys, pagination, itemCount } = this
      return keys.slice((pagination.page - 1) *itemCount, (pagination.page)*itemCount)
    },
    nftList () {
      return this.getTonMetaItems(this.currentBatch)
    },
    nftRelations () {
      return this.getTonMetaRelations(this.currentBatch)
    }
  },
  methods: {
    ...mapActions('crypto', ['loadTonAccountNFTs']),
    formatTons,
    setExtraFields () {
      const { keys, getTonAccounts, account, minterFlag } = this
      if (keys.length > 0) {
        this.pagination.total = minterFlag ? getTonAccounts[account].minted_amount : getTonAccounts[account].nft_amount
        if (keys[0] in getTonAccounts) {
          this.firstLT = BigInt(getTonAccounts[keys[0]].last_tx_lt)
        }
        if (keys[keys.length - 1] in getTonAccounts) {
          this.lastLT = BigInt(getTonAccounts[keys[keys.length - 1]].last_tx_lt)
        }
      }
    },
    async updateValues (next = true) {
      const { keys, itemCount, pagination, lastLT, firstLT, account, minterFlag } = this
      if (keys.length === 0 || pagination.page === 1)
        await this.loadTonAccountNFTs({ limit: itemCount, preload: true, seqOffset: null, account, minterFlag })
      else {
        await this.loadTonAccountNFTs({ limit: itemCount, preload: true, seqOffset: next ? lastLT : firstLT, account, minterFlag })
      }
      this.setExtraFields()
    },
    setPage (page) {
      this.pagination.page = page
    }
  },
  async mounted () {
    if (this.keys.length === 0 || this.keys.length === Object.keys(this.nftList).length) {
      await this.loadTonAccountNFTs({ account: this.account, preload: true, limit: 18, seqOffset: null, minterFlag: this.minterFlag })

    } else if (this.keys.length > 0 && Object.keys(this.nftList).length !== this.keys.length) {
      await this.loadTonAccountNFTs({ account: this.account, preload: true, limit: 18, seqOffset: null, minterFlag: this.minterFlag })
    }
    this.setExtraFields()
  }
}
</script>

<template>
  <div>
    <div 
      v-if="!isLoaded" 
      class="uk-flex uk-flex-center"
    >
      <Loader :ratio="2"/>
    </div>
    <div 
      v-else-if="isLoaded" 
      class="uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-6@xl uk-padding-small" 
      uk-grid
    >
      <div 
        v-for="nft, key in nftList"
        :key="nft.name + key"
      >
        <div class="uk-card">
          <div class="uk-card-media-top">
            <img 
              v-if="nft.image_url" 
              :src="nft.image_url" 
              width="500" 
              height="500" 
              alt="Error"
              style="border-radius: 8px;" 
            />
            <img 
              v-else 
              style="border-radius: 8px;" 
              src="/img/no-picture.png" 
              width="250" 
              height="250" 
              alt=""
            />
            <div 
              v-if="getTonAccounts[key]?.fake" 
              class="uk-position-top-left uk-overlay uk-margin-small-top uk-margin-small-left uk-background-muted uk-text-danger uk-text-large uk-text-bold" 
              style="padding: 0 5px"
            >
              Fake
            </div>
          </div>
          <div class="uk-card-body uk-text-truncate uk-padding-small">
            <router-link 
              :to="`/crypto/ton/account/${key}#overview`" 
              :uk-tooltip=" nft.name ? nft.name : 'No name'"
            >
              {{ nft.name ? nft.name : "No name" }}
            </router-link>
            <h4 
              class="uk-margin-remove-top uk-text-truncate" 
              :uk-tooltip="getTonMetadata[nftRelations[key]?.minter.hex]?.name ? getTonMetadata[nftRelations[key]?.minter?.hex]?.name : 'No collection'"
            > 
              {{ getTonMetadata[nftRelations[key]?.minter.hex]?.name ? getTonMetadata[nftRelations[key].minter.hex].name : 'No collection' }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>