<template>
  <div 
    v-if="addr === null"
  >
    {{ $t('crypto.general.empty') }}
  </div> 
  <div
    v-else-if="disable"
  >
    {{ addr.hex in badAddresses ? badAddresses[addr.hex].name : accName }}
  </div>
  <router-link 
    v-else-if="addr.hex in badAddresses"
    :to="{ name: 'crypto_ton_address', params: { address: addr.hex }, hash: '#overview'} " 
    :style="breakWord ? 'word-break: break-all;': ''"
    :disabled="disable"
  >
    {{ badAddresses[addr.hex].name }}
  </router-link>
  <router-link 
    v-else
    :to="{ name: 'crypto_ton_address', params: { address: addr.hex }, hash: '#overview'} " 
    :style="breakWord ? 'word-break: break-all;': ''"
    :disabled="disable"
  >
    {{ accName }}
  </router-link>
</template>


<script>
import { badAddresses, truncString } from '@/utils/ton-filters'
import { mapGetters } from 'vuex'

export default {
  name: 'TonAddressField',
  components: {
  },
  props: {
    addr: {
      type: Object,
      required: false
    },
    showHex: {
      type: Boolean,
      required: false
    },
    breakWord: {
      type: Boolean,
      required: false,
      default: false
    },
    full: {
      type: Boolean,
      required: false
    },
    disable: {
      type: Boolean,
      default: null,
      required: false
    }
  },
  data () {
    return {
      badAddresses
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts']),
    accName () {
      const { getTonAccounts, addr, full, showHex, truncString } = this
      return getTonAccounts[addr.hex]?.label?.name ?? (full ? (showHex? addr.hex : addr.base64) : truncString(showHex? addr.hex : addr.base64, 5))
    }
  },
  methods: {
    truncString
  }
}
</script>