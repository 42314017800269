<template>
  <div>
    <ul uk-tab>
      <li
        v-for="(scopedSlot, key) in scopedSlots"
        :key="key"
      >
        <a @click="currentSlot = key">
          {{ tabNameMethod(key) }}
          <span class="uk-badge uk-margin-small-left">
            {{ tabCounters(key) }}
          </span>
        </a>
      </li>
    </ul>

    <ul class="uk-switcher uk-margin uk-background-default">
      <li
        v-for="(scopedSlot, key) in scopedSlots"
        :key="key"
      >
        <slot 
          v-if="currentSlot === key" 
          :name="key"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'UITabs',
  props: {
    tabNameMethod: {
      type: Function,
      default: tabName => tabName
    },
    tabCounters: {
      type: Function,
      default: tabName => tabName
    }
  },
  data () {
    return {
      currentSlot: ''
    }
  },
  computed: {
    scopedSlots () {
      return this.$slots
    }
  },
  mounted () {
    this.currentSlot = Object.keys(this.scopedSlots)[0]
  }
}
</script>
