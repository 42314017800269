import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class ForumsMessagesUser extends Base {
  schema = Schemas.forums.message
  OPTIONS_SCHEMA = ['id', 'query', 'allowedFields', 'localizeFields', 'messages', 'translateMessages']
  fetch = async ({ dispatch, options }) => {
    const { id, query, messages, translateMessages } = options
    if (!!messages && messages.length) {
      return messages
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      const params = {
        userId: id,
        query: localQuery
      }
      while (localQuery.offset < this.total) {
        const { data } = await dispatch('fetchUserMessages', params)
        let entry = data
        try {
          if (translateMessages) {
            entry = await Promise.all(
              entry.map(async entry => {
                const {
                  data: { translation }
                } = await dispatch('utils/translateText', { text: entry.text })
                entry.translated_text = translation
                return entry
              })
            )
          }
        } catch (err) {
          console.debug(err)
        }
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default ForumsMessagesUser
