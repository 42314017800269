<template>
  <div
    class="home uk-container"
  >
    <div
      class="uk-margin"
    >
      <form
        @submit.prevent="search"
      >
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
        />
        <vueSelect
          v-model="currentSearchType"
          :options="searchTypeTranslated"
          label="label"
          class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
        >
          <span
            uk-search-icon
          />
        </button>
      </form>
      <SearchInDbHint class="uk-margin-bottom" />
      <div
        class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match"
        uk-grid
      >
        <template 
          v-for="(stat, name, index) in stats"
          :key="index"
        >
          <div>
            <component
              :is="name.includes('input_excluded_links') ? 'span' : 'router-link'"
              :to="{ name: routeName(name) }"
              class="uk-card uk-card-small uk-card-body uk-dark"
              :class="{
                'uk-card-default' : index % 3 === 0,
                'uk-card-primary': index % 3 === 1,
                'uk-card-secondary': index % 3 === 2,
                'uk-card-hover': !name.includes('avatar')
              }"
            >
              <Loader v-if="!isLoaded"/>
              <h3 v-else-if="!stat">
                -
              </h3>
              <h3
                v-else-if="name !== 'tasks_count'"
                class="uk-card-title"
              >
                {{ numberFormat(stat) }}
              </h3>
              <h3
                v-else
                class="uk-card-title"
              >
                {{ numberFormat(stat) }} ({{ numberFormat(taskSuccess(stats)) }}%)
              </h3>
              <h3 class="uk-text-muted uk-margin-remove-top">
                {{ $t(`discord.stats.${name}`) }}
              </h3>
            </component>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import vueSelect from '@/components/VueSelect'
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { numberFormat } from '@/filters/globalFilters'

export default {
  name: 'DiscordStats',
  components: {
    Loader,
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    vueSelect
  },
  data () {
    return {
      searchString: '',
      searchType: 'discord_guild_list'
    }
  },
  computed: {
    ...mapGetters({ stats: 'discord/stats' }),
    currentSearchType: {
      get () {
        return this.searchType
      },
      set (obj) {
        this.searchType = obj && obj.value
        this.update()
      }
    },
    searchTypeTranslated () {
      return [
        { value: 'discord_guild_list', label: this.$t('search.guilds') },
        { value: 'discord_user_list', label: this.$t('search.users') },
        { value: 'discord_message_list', label: this.$t('search.messages') }
      ]
    },
    isLoaded () {
      return this.$store.state.discord.isLoaded.stats
    }
  },
  methods: {
    numberFormat,
    routeName (stat) {
      switch (stat) {
          case 'users_count': return 'discord_user_list'
          case 'guilds_count': return 'discord_guild_list'
          case 'messages_count': return 'discord_message_list'
          default: return 'discord_stats'
      }
    },
    search () {
      let query = { search: this.searchString }
      this.$router.push({ name: this.searchType, query })
    },
    ...mapActions({ update: 'discord/fetchStats' })
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
  