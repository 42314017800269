<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('tgcp.user_details.chat_activity_title') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <Loader
          v-if="!isLoaded"
        />
        <VueApexCharts
          v-else
          height="100%"
          :options="chartOptions"
          :series="chatsActivity"
          @click="clickHandler"
          @xAxisLabelClick="handleLabelClick"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import Loader from '@/components/TGCP/Loader'
import IntegerAxisFormatter from '@/utils/IntegerAxisFormatter'
import { mapActions } from 'vuex'

export default {
  name: 'ForumsTopicsOverview',
  components: {
    Loader,
    VueApexCharts
  },
  props: {
    id: {
      required: true,
      type: String
    },
    forumId: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      topics: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          selection: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'numeric',
          title: {
            text: this.$i18n.t('forums.users.details.messages_count')
          },
          labels: {
            formatter: IntegerAxisFormatter.format
          },
          forceNiceScale: true,
          min: 0
        },
        dataLabels: {
          enabled: false
        },
        noData: {
          text: this.$t('osint.monitor.no-data'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        tooltip: {
          shared: false,
          marker: {
            show: false
          },
          y: {
            show: true,
            formatter: IntegerAxisFormatter.format
          }
        }
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    chatsActivity () {
      let series = [{
        name: this.$i18n.t('forums.users.details.messages_count'),
        data: []
      }]
      for (const item of this.topics) {
        if (item.value) {
          series[0].data.push({
            x: item.label,
            y: item.value,
            topic_id: item.id
          })
        }
      }
      return series
    },
    isLoaded () {
      return this.chatsActivity.length > 0
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchUserTopics'
    ]),
    async topicsMethod (params = { search: '', limit: 0 }) {
      try {
        const { search, limit } = params
        const { data } = await this.fetchUserTopics({
          userId: this.id,
          query: {
            search, 
            limit,
            order_by: 'user_messages_count',
            order_type: 'desc'
          },
          posted: true
        })
        this.topics = data.reduce((topics, entry) => {
          try {
            if (!topics.find(topic => topic.value === entry.id)) {
              topics = topics.concat({
                label: entry.name || entry.username,
                id: entry.id,
                value: entry.user_messages_count
              })
            }
          } catch (error) {
            console.debug(error)
          }
          return topics
        }, this.topics)
      } catch (error) {
        console.debug(error)
      }
    },
    clickHandler (event, item, { dataPointIndex }) {
      if (dataPointIndex === -1) return
      const routeData = this.$router.resolve({
        name: 'forums_topic_details',
        params: {
          topicId: String(this.chatsActivity[0]['data'][dataPointIndex].topic_id), 
          forumId: this.forumId
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleLabelClick (event, item, { labelIndex }) {
      if (labelIndex === -1) {
        return
      }

      const routeData = this.$router.resolve({
        name: 'forums_topic_details',
        params: {
          topicId: this.chatsActivity[0]['data'][labelIndex].topic_id, 
          forumId: this.forumId
        }
      })

      window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    this.topicsMethod()
  }
}
</script>
  