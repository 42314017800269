// @ts-check
/**
 * @typedef {{ type: string, subtype: string, namespace: string, search: string, participantType: string, participantStatus: string, localizeFields: boolean, allowedFields: string[], fileName?: string, id?: string, format?: string, for?: string, entries?: object[] }} StrategyOptions
 * @typedef {import('./Strategies/Base').Schema} Schema
 * @typedef {import('./Queue').Process} Process
 * @typedef {import('./Queue').ProcessPayload} ProcessPayload
 */

// @ts-ignore
import store from './store.js'
// @ts-ignore
import dayjs from 'dayjs'
// @ts-ignore
import tz from 'dayjs/plugin/timezone'
// @ts-ignore
import utc from 'dayjs/plugin/utc'
// @ts-ignore
import i18n from '../../i18n.js'

import { Value } from './Utils.d'

/**
 * @class
 * @readonly
 */
class Utils {
  /**
   * @readonly
   * @param {string} name
   * @returns {void|Capitalize<string>}
   */
  static nameNormalize (name: string): void | Capitalize<string> {
    try {
      if (typeof name === 'string') {
        return name.charAt(0).toUpperCase() + name.slice(1) as Capitalize<string>
      }
    } catch (error) {
      console.debug(error)
    }
  }

  /**
   * @readonly
   * @param {{ entries: object[], properties: string[] }} options
   * @return {object[]}
   */
  static selectProperties (options: { properties: string[]; entries: object[] }) {
    try {
      if (options.properties.length) {
        const currentEntries = options.entries.map(entry => {
          try {
            let currentEntry = Object.entries(entry)
            let filtered = currentEntry.filter(entry => {
              try {
                const [key] = entry
                const currentProperties = options.properties.map(property => {
                  try {
                    const currentProperty = property.toString()
                    return currentProperty
                  } catch (error) {
                    console.debug(error)
                  }
                  return property
                })
                const hasKey = currentProperties.includes(key)
                const translated = key === 'translated_text'
                const isPhoneNumber = key === 'phone_number'
                const isContextKey = key === 'main'
                if (hasKey || translated || isPhoneNumber || isContextKey) {
                  return true
                }
              } catch (error) {
                console.debug(error)
              }
              return false
            })
            return Object.fromEntries(filtered)
          } catch (error) {
            console.debug(error)
          }
          return entry
        })
        return currentEntries
      }
    } catch (error) {
      console.debug(error)
    }
    return options.entries
  }

  /**
   * @readonly
   * @param {StrategyOptions} options
   * @return {string}
   */
  static getDescription (options: { type: string, subtype: string, namespace: string, search: string, participantType: string, participantStatus: string, localizeFields: boolean, allowedFields: string[], fileName?: string, id?: string, format?: string, for?: string, entries?: object[], query?: any }): string {
    try {
      
      // If filename provided just return it
      if (options?.fileName) return options.fileName
      // Otherwise build finename from the given options
      let current = ''
      current += options?.namespace ? (options.namespace === 'tgcp' ? 'telegram' : options.namespace) : 'default'
      current += options?.participantStatus ? `_${options.participantStatus}` : ''
      if (options?.subtype?.includes('/')) {
        current += '_' + options.subtype.split('/').reverse().join('_')
      } else if (options?.subtype) {
        current += `_${options.subtype}`
      }
      current += options?.type ? `_${options.type}` : ''
      current += options?.participantType ? `_as_${options.participantType}` : ''
      current += options?.id ? `_id_${options.id}` : ''
      current += options?.search ? `_search_${options.search}` : (options?.query?.search ? `_search_${options.query.search}` : '')

      return current
      
    } catch (error) {
      console.debug(error)
    }
    return ''
  }

  /**
   * @readonly
   * @param {object[]} entries
   * @return {object[]}
   */
  static unique (entries: { id: any }[]) {
    try {
      return entries.reduce((next, entry, index) => {
        try {
          const nextIndex = entries.findIndex(({ id }) => {
            try {
              return entry.id === id
            } catch (error) {
              console.debug(error)
            }
            return -1
          })
          if (nextIndex === index) {
            // @ts-ignore
            next.push(entry)
          }
        } catch (error) {
          console.debug(error)
        }
        return next
      }, [])
    } catch (error) {
      console.debug(error)
    }
    return entries
  }

  /**
   * @readonly
   * @param {{entries: object[], schema: Schema}} params
   * @return {object}
   */
  static currentValues (params: {entries: object[], schema: string[]}) {
    try {
      const { entries, schema } = params
      const currentEntries = entries.map(entry => {
        try {
          const currentEntry = Object.entries(entry).map(([key, value]) => {
            try {
              const currentKey = schema.find(current => {
                try {
                  return current.toString() === key
                } catch (error) {
                  console.debug(error)
                }
                return undefined
              })
              let currentValue = value
              let transform
              if (currentKey) {
                // @ts-ignore
                transform = currentKey.transform
              }
              if (transform && currentValue) {
                currentValue = transform(value)
                return [key, currentValue]
              }
              return [key, value]
            } catch (error) {
              console.debug(error)
            }
            return [key, value]
          })
          return Object.fromEntries(currentEntry)
        } catch (error) {
          console.debug(error)
        }
      })
      return currentEntries
    } catch (error) {
      console.debug(error)
    }
    return params.entries
  }

  /**
   * @readonly
   * @param {object[]} entries
   * @return {object[]}
   */
  static convertTypes (entries: object[]) {
    try {
      return entries.map(entry => {
        try {
          /**
          * @type {Iterable<readonly [PropertyKey, any]>}
          */
          let currentEntry = Object.entries(entry).reduce((next, entry) => {
            try {
              const [key, value] = entry
              const not = value === null || typeof value === 'undefined'
              const flatArray = Array.isArray(value) && value.every(entry => {
                try {
                  return typeof entry === 'number' || typeof entry === 'string'
                } catch (error) {
                  console.debug(error)
                }
              })
              if (not) {
                // @ts-ignore
                next.push([key, ''])
              } else if (typeof value === 'boolean') {
                let currentValue = 'no'
                if (value) {
                  currentValue = 'yes'
                }
                // @ts-ignore
                next.push([key, i18n.global.t(`csv.${currentValue}`)])
              } else if (flatArray) {
                // @ts-ignore
                next.push([key, value.toString()])
              } else {
                // @ts-ignore
                next.push([key, value])
              }
            } catch (error) {
              console.debug(error)
            }
            return next
          }, [])
          return Object.fromEntries(currentEntry)
        } catch (error) {
          console.debug(error)
        }
        return entry
      })
    } catch (error) {
      console.debug(error)
    }
    return entries
  }

  /**
   * @readonly
   * @param {string} date
   * @return {string}
   */
  static currentDate (date: string) {
    try {
      dayjs.extend(tz)
      dayjs.extend(utc)

      const format = 'YYYY-MM-DD HH:mm'
      const timezone = store.state.settings.timezone
      const dateString = String(date)
      if (typeof date === 'string' && !dateString.length) {
        return ''
      }
      const index = dateString.length - 1
      const hasCase = dateString[index].toUpperCase() !== 'Z'
      if (Number.isNaN(+date) && hasCase && !/\+/.test(dateString)) date += 'Z'
      const dateTimeUtc = dayjs(date).utc()
      const timestamp = dateTimeUtc.unix()
      const offset = dayjs().tz(timezone).utcOffset() * 60
      const dateTimeLocal = dayjs.unix(timestamp + offset).utc()
      return dateTimeLocal.format(format)
    } catch (error) {
      console.debug(error)
    }
    return date
  }

  /**
   * @readonly
   * @param {object[]} entries
   * @return {object[]}
   */
  static date (entries: object[]) {
    try {
      return entries.map(entry => {
        try {
          let currentEntry = Object.entries(entry).map(([key, value]) => {
            try {
              if (isNaN(value) && Date.parse(value)) {
                return [key, Utils.currentDate(value)]
              }
            } catch (error) {
              console.debug(error)
            }
            return [key, value]
          })
          return Object.fromEntries(currentEntry)
        } catch (error) {
          console.debug(error)
        }
        return entry
      })
    } catch (error) {
      console.debug(error)
    }
    return entries
  }
  static value<Interface, Property> (key: Value.Key<Interface, Property>, transform: Value.Transform<Interface, Property>): Value.Transformer<Interface, Property> | Value.Key<Interface, Property> {
    try {
      return new class Transformer extends String {
        transform: Value.Transform<Interface, Property> = value => ''
        constructor (key: Value.Key<Interface, Property>) {
          super(key)
          try {
            this.transform = transform
          } catch (error) {
            console.debug(error)
          }
        }
      }(key)
    } catch (error) {
      console.debug(error)
    }
    return key
  }
}

export const value = Utils.value
export default Utils
