import _ from 'lodash'
export function deepDiff (object, base) {
  function changes (object, base) {
		return _.transform(object, function (result, value, key) {
			if (!_.isEqual(value, base[key])) {
				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value
			}
		})
	}
	return changes(object, base)
}
const removeEmpty = obj =>
  Object.fromEntries(
    Object.entries(obj)
			.filter(([k, v]) => v)
			.map(([k, v]) => (typeof v === 'object' ? [k, removeEmpty(v)] : [k, v]))
			.filter(([k, v]) => (typeof v === 'object') ? Object.keys(v).length : v)
  )
export const deepDiffArray = arr => arr.length > 1
? arr
.reduce((acc, curr, i, full) => {
	if (full[i+1]) return [ ...acc, deepDiff(curr, full[i+1]) ]
	else return [ ...acc ]
},[])
.map(el => removeEmpty(el))
: arr