import { createStore } from 'vuex'

import ads from './modules/ads'
import auth from './modules/auth'
import options from './modules/options'
import discord from './modules/discord'
import fetcher from './modules/fetcher'
import facebook from './modules/facebook'
import tgcp from './modules/tgcp'
import threema from './modules/threema'
import twitter from './modules/twitter'
import tiktok from './modules/tiktok'
import news from './modules/news'
import cases from './modules/cases'
import leaks from './modules/leaks'
import zalo from './modules/zalo'
import imo from './modules/imo'
import instagram from './modules/instagram'
import organizations from './modules/organizations'
import webreader from './modules/webreader'
import whatsapp from './modules/whatsapp'
import forums from './modules/forums'
import crypto from './modules/crypto'
import osint from './modules/osint'
import hoop from './modules/hoop'
import utils from './modules/utils.js'
import sessionStoragePlugin from './plugins/sessionStorage'
import localStoragePlugin from './plugins/localStorage'

const debug = process.env.NODE_ENV !== 'production'
export const store = createStore({
  state: {
    auth_token: false,
    refresh_token: false,
    permanent_save: false,
    user_name: '',
    current_user_id: false,
    password: '',
    is_admin: false,
    organisation_id: null,
    balance: 0,
    settings: {
      language: 'en',
      timezone: 'GMT',
      defaultCase: null,
      translate: 'en',
      silent_token_write_off: false,
      search_save: false,
      blur_nsfw_forums: false,
      choose_messages_to_export: false,
      limits: {
        'asiris.tgcp.default_priority': false,
        'tgcp.max_invite_tasks' : 0
      }
    },
    refresh_timestamp: false,
    timeout_handler: false,
    errors: [],
    main_side_menu_is_collapsed: false,
    password_expired: false,
    search_history: {
      web: [],
      feed: []
    }
  },
  getters: {
    isAuthenticated: state => !!state.auth_token,
    auth_token: state => state.auth_token,
    settings: state => state.settings,
    getProfile: state => ({ name: state.user_name, balance: state.balance }),
    excludedSources: state => state.settings.user_id ? (state.settings.excluded_sources || []) : null,
    isMainSideMenuCollapsed: state => state.main_side_menu_is_collapsed,
    forceChangePassword: state => state.password_expired,
    searchHistory: state => state.search_history,
    tgcpDefaultPriority: state => state.settings.limits['asiris.tgcp.default_priority'],
    botLimit: state => state.settings.limits['tgcp.max_invite_tasks']
  },
  mutations: {
    setToken (state, token) {
      state.auth_token = token
    },
    setUserId (state, userId) {
      state.current_user_id = userId
    },
    setSettings: (state, settings) => {
      state.settings = settings
    },
    setRefreshTimestamp (state, timestamp) {
      state.refresh_timestamp = timestamp
    },
    setRefreshToken (state, token) {
      state.refresh_token = token
    },
    setOrganisationId (state, id) {
      state.organisation_id = id
    },
    setRole (state, role) {
      state.is_admin = role === 'admin' || role === 'org_admin'
    },
    setForceChangePassword (state, expired) {
      state.password_expired = expired
    },
    setTimeoutHandler (state, handler) {
      state.timeout_handler = handler
    },
    loadSearchHistory (state) {
      for (const key in state.search_history) {
        state.search_history[key] = state.search_history[key].filter(item => typeof item === 'string')
      }
    },
    setSearchHistory (state, { search, module }) {
      state.search_history[module] = [ ...new Set([ search,  ...state.search_history[module] ]) ]
    },
    setTwoFA (state, twoFAEnabled) {
      state.settings.twoFAEnabled = twoFAEnabled
    },
    setPassword (state, pass) {
      state.password = pass
    },
    setPermanentSave (state, bool) {
      state.permanent_save = bool
    },
    setUserName (state, login) {
      state.user_name = login
    },
    setBalance (state, balance) {
      state.balance = balance
    },
    setLanguage (state, language) {
      state.settings.translate = language
    },
    setSilentWriteOff (state, silentWriteOff) {
      state.settings.silent_token_write_off = silentWriteOff
    },
    setSearchSave (state, searchSave) {
      state.settings.search_save = searchSave
    },
    setNsfwBlur (state, nsfwBlur) {
      state.settings.blur_nsfw_forums = nsfwBlur
    },
    setChooseMessages (state, payload) {
      state.settings.choose_messages_to_export = payload
    },
    setTimezone (state, tzName) {
      state.settings.timezone = tzName
    },
    setDefaultCase (state, defaultCase) {
      state.settings.defaultCase = defaultCase
    },
    setMainSideMenuCollapsed (state, isCollapsed) {
      state.main_side_menu_is_collapsed = isCollapsed
    },
    fetchSessions (state, sessions) {
      state.settings.sessions = sessions
    },
    clearCasesAndProfiles: state => {
      state.cases.cases = {}
      state.cases.profilesList = {}
      state.cases.profile = {}
    },
    clearTasks: state => {
      state.tgcp.tasks = {}
      state.tgcp.listTasks = {}
    }
  },
  modules: {
    ads,
    auth,
    options,
    discord,
    fetcher,
    facebook,
    tgcp,
    twitter,
    tiktok,
    threema,
    news,
    cases,
    leaks,
    zalo,
    forums,
    webreader,
    whatsapp,
    organizations,
    crypto,
    instagram,
    imo,
    osint,
    hoop,
    utils
  },
  plugins: [sessionStoragePlugin, localStoragePlugin],
  strict: debug
})