import { parsePhoneNumberFromString as parseMax, isValidPhoneNumber as checkValidity } from 'libphonenumber-js/max'
const isIp = require('is-ip')

const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
const validateLink = link => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{1,10}\b([-a-zA-Z0-9@:%_+.~#?!&//=]*)/gi.test(link.toLowerCase())

const mainSearch = (router, search) => {
  search = search.trim()
  let phoneSearch = search.split(' ').join('')
  // VN
  if (phoneSearch.indexOf('84') === 0) {
    phoneSearch = '+' + phoneSearch
  }
  // Telegram Virtual Numbers
  if (phoneSearch.indexOf('888') === 0) {
    phoneSearch = '+' + phoneSearch
  }
  if (isIp(search)) {
    router.push({ name: 'ipResults', params: { ip: search } })
  } else if (validateEmail(search)) {
    router.push({ name: 'emailResults', params: { email: search } })
  } else if (phoneSearch.indexOf('+') === 0 && parseMax(phoneSearch)) {
    try {
      if ( phoneSearch.indexOf('+888') === 0 || parseMax(search, parseMax(phoneSearch).country).isValid() || parseMax(phoneSearch).country === 'VN') {
        search = parseMax(phoneSearch).format('INTERNATIONAL').split(' ').join('')
        router.push({ name: 'phoneResults', params: { phone: search } })
      } else {
        router.push({ name: 'phoneResults', params: { phone: search } })
      }
    } catch (e) {
      if (search.split(' ').length > 1) {
        return router.push({ name: 'nameResults', params: { name: search } })
      }
      router.push({ name: 'nicknameResults', params: { nickname: search } })
    }
  } else if (parseMax(search, 'RU')) {
    try {
      if (checkValidity(search, 'RU')) {
        search = parseMax(search, 'RU').format('INTERNATIONAL').split(' ').join('')
        router.push({ name: 'phoneResults', params: { phone: search } })
      } else if (checkValidity('+' + search, 'RU')) {
        search = parseMax('+' + search).format('INTERNATIONAL').split(' ').join('')
        router.push({ name: 'phoneResults', params: { phone: search } })
      } else {
        if (search.split(' ').length > 1) {
          return router.push({ name: 'nameResults', params: { name: search } })
        }
        router.push({ name: 'nicknameResults', params: { nickname: search } })
      }
    } catch (e) {
      if (search.split(' ').length > 1) {
        return router.push({ name: 'nameResults', params: { name: search } })
      }
      router.push({ name: 'nicknameResults', params: { nickname: search } })
    }
  } else if (parseMax('+' + phoneSearch)) {
    if (parseMax('+' + phoneSearch).isValid() || parseMax('+' + phoneSearch).country === 'VN') {
      search = parseMax('+ ' + phoneSearch).format('INTERNATIONAL').split(' ').join('')
      router.push({ name: 'phoneResults', params: { phone: search } })
    } else {
      if (search.split(' ').length > 1) {
        return router.push({ name: 'nameResults', params: { name: search } })
      }
      router.push({ name: 'nicknameResults', params: { nickname: search } })
    }
  } else {
    if (search.split(' ').length > 1) {
      return router.push({ name: 'nameResults', params: { name: search } })
    }
    if (validateLink(search)) {
      return router.push({ name: 'domainResults', params: { domain: search.replace(/https?:\/\/(www\.)?/gi, '').split('/')[0] }})
    }
    router.push({ name: 'nicknameResults', params: { nickname: search } })
  }
}
export default mainSearch
