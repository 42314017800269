<template>
  <tr>
    <td class="uk-width-1-5 uk-text-left">
      <slot name="name"/>
    </td>
    <td 
      class="uk-flex" 
      :class="{'uk-text-bold' : bold, 'uk-flex-wrap' : wrap}"
    >
      <slot name="value"/>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TonPropLine',
  props: {
    wrap: {
      type: Boolean,
      required: false
    },
    bold: {
      type: Boolean,
      required: false
    }
  }
}
</script>