<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <transition
          enter-active-class="uk-animation-slide-top-small"
          leave-active-class="uk-animation-slide-top-small uk-animation-reverse"
        >
          <MapEditable
            v-if="map"
            class="uk-height-medium"
            name="tiktok"
            type="set"
            :geo="query.bbox && query.bbox.split(',')"
            :onSelect="mapSelectHandler"
            :onDeselect="mapDeselectHandler"
          />
        </transition>
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            v-if="type === 'all' || type === 'user'"
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="searchHandler"
            >
              <span
                uk-search-icon
              />
            </button>
          </div>
          <div
            class="uk-display-inline-block"
          >
            <div
              class="uk-display-inline-block"
            >
              {{ $t('twitter.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div
        v-if="!hidePagination"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center uk-margin-small-top"
        />
      </div>
      <template v-if="!loaded">
        <div class="uk-margin-medium-top">
          <Loader/>
        </div>
      </template>
      <template v-else>
        <Item
          v-for="message in messages"
          :key="message.id"
          :message="message"
          class="uk-margin-top"
        />
      </template>
      <NotFound
        v-if="loaded && messages.length === 0"
      />
      <div
        v-if="!hidePagination"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="id"
      namespace="tiktok"
      type="posts"
      :subtype="type"
      :search="query.search"
      :query="query"
      :totalCount="pagination?.total ?? 0"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'

export default {
  name: 'MessageList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Tiktok/MessageList/Item')),
    Paginate,
    vueSelect,
    MapEditable: defineAsyncComponent(() => import('@/components/map/MapEditable.vue')),
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'hashtag', 'comments'].includes(val)
    }
  },
  data () {
    return {
      messages: [],
      query: {
        limit: 20,
        offset: 0,
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || 'desc',
        search: this.$route.query.search || '',
        bbox: this.$route.query.bbox || null
      },
      map: false,
      pagination: {
        page: 1,
        total: 0
      },
      selectedMessages: [],
      isReply: undefined,
      commentsReady: true
    }
  },
  watch: {
    id () {
      return this.update()
    },
    map (val) {
      if (!val) {
        this.query.bbox = null
        this.searchHandler()
      }
    },
    currentReply () {
      this.searchHandler()
    }
  },
  computed: {
    ...mapGetters('tiktok', ['allMessages', 'isLoaded']),
    isComments () {
      return this.type === 'comments'
    },
    currentReply: {
      get () {
        return this.isReply
      },
      set (reply) {
        this.isReply = reply.value
      }
    },
    reply () {
      return [
        {
          label: this.$t('twitter.filter.all'),
          value: undefined
        },
        {
          label: this.$t('twitter.filter.hide-replies'),
          value: 'false'
        },
        {
          label: this.$t('twitter.filter.replies'),
          value: 'true'
        }
      ]
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.searchHandler()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      const orderList = ['id']
      return orderList.map(word => ({ label: this.$t('twitter.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('twitter.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('twitter.sort.sort_type_desc') }
      ]
    },

    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      if (this.isComments) {
        return this.commentsReady
      }
      return this.type !== 'all' ? this.isLoaded[`messages_${this.type}_${this.id}`] : this.isLoaded['messages']
    },
    userOrChat () {
      const { id, type } = this
      return type + (id ? '_' + id : '')
    },
    hasExport () {
      return (this.type !== 'all' || this.query?.search || this.query?.bbox) && this.messages.length > 0
    },
    typedId () {
      const { type, id } = this
      const typedId = String
      typedId.prototype.type = type
      if (id) return typedId(id)
      return ''
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'fetchAllMessages',
      'addParamToQuery',
      'fetchUserMessages',
      'fetchHashtagMessages',
      'fetchCommentsByMessageID',
      'fetchCommentsByUserID'
    ]),
    searchHandler () {
      this.submit({ page: 1 })
      this.pagination.page = 1
    },
    submit () {
      const params = {
        is_reply: this.isReply,
        limit: 20,
        offset: 0,
        order_by: this.query.order_by,
        order_type: this.query.order_type,
        search: this.query.search,
        bbox: this.query.bbox
      }
      this.query = params
      this.update()
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setPagination (messages) {
      this.messages = messages.messages
      this.pagination.total = messages.meta.results_count
    },
    update () {
      const data = {
        id: this.id,
        query: this.query
      }
      const opts = {
        user: ['fetchUserMessages', data],
        hashtag: ['fetchHashtagMessages', data],
        comments: ['fetchCommentsByMessageID', this.id],
        userComments: ['fetchCommentsByUserID', this.id],
        all: ['fetchAllMessages', data.query]
      }
      const [method, payload] = opts[this.type] || []
      if (!method) return
      if (this.type === 'all') this.$router.push({ query: { ...data.query } })
      if (this.isComments) {
        this.commentsReady = false
      }
      return this[method](payload)
        .then(messages => {
          if (this.isComments) {
            this.commentsReady = true
          }
          return this.setPagination(messages)
        })
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    },
    handleError () {
      this.messages = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    mapSelectHandler (val) {
      this.query.bbox = val._southWest.lat.toFixed(4) + ',' + val._southWest.lng.toFixed(4) + ',' + val._northEast.lat.toFixed(4) + ',' + val._northEast.lng.toFixed(4)
      this.searchHandler()
    },
    mapDeselectHandler () {
      this.query.bbox = null
      this.searchHandler()
    }
  },
  created () {
    if (this.$route.query.offset) {
      this.setPage(Math.floor((this.$route.query.offset / this.query.limit) + 1))
    } else {
      this.update()
    }
    if (this.$route.query.bbox) {
      this.map = true
    }
  }
}
</script>
