<template>
  <div
    class="uk-align-center"
    uk-grid
  >
    <form
      class="uk-form-horizontal uk-form"
      @submit.prevent="search"
    >
      <div class="uk-inline" >
        <input
          v-model.trim="query.q"
          class="uk-input uk-form-width-large"
          type="text"
          :placeholder="placeholder"
        />
        <select
          v-model="query.type"
          class="uk-select uk-input uk-form-width-small"
          autofocus
        >
          <option
            disabled
            :value="undefined"
          >
            {{ $t('crypto.autodetect') }}
          </option>
          <option
            value="btc"
          >
            BTC
          </option>
        </select>
        <select
          v-model="query.searchBy"
          class="uk-select uk-input uk-form-width-small"
          autofocus
        >
          <option
            value="address"
          >
            {{ $t('crypto.address') }}
          </option>
          <option
            value="txid"
          >
            TxId
          </option>
        </select>
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click="search"
        >
          <span uk-icon="icon: search"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
export default {
  name: 'CryptSearchList',
  components: {},
  data () {
    return {
      address: {},
      companies: [],
      query: {
        q: this.$route.query.q || '',
        type: 'btc',
        searchBy: 'address'
      },
      pagination: {
        page: 1,
        total_pages: 0
      }
    }
  },
  computed: {
    placeholder () {
      if (this.query.searchBy === 'address') return this.$t('crypto.search-for-btc-address')
      if (this.query.searchBy === 'txid') return this.$t('crypto.search-for-btc-address')
      return ''
    }
  },
  methods: {
    ...mapActions('crypto', [
      'fetchAddress'
    ]),
    setPage (page) {
      this.pagination.page = page
    },
    loadAddress () {
      let caller = this
      this.fetchAddress(this.query.q).then(function (data) {
        caller.address = data
      })
    },
    handleError () {
      this.companies = []
      this.pagination.page = 1
      this.pagination.total_pages = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    search () {
      this.pagination.page = 1
      this.setPage(1)
      if (this.query.searchBy === 'address') {
        // this.$router.push('/crypto/btc/address/' + this.query.q)
        this.loadAddress()
      } else if (this.query.searchBy === 'txid') {
        this.$router.push('/crypto/btc/txid/' + this.query.q)
      }
    }
  }
}
</script>
