import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class TiktokUsersAll extends Base {
  schema = Schemas.tiktok.post
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields', 'parentProps']
  fetch = async ({ dispatch, options }) => {
    const { parentProps: {
        id, type
      }, query, users } = options
    if (!!users && users.length) {
      return users
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      while (localQuery.offset < this.total) {
        let messages = []
        if (type === 'user') {
            messages = (await dispatch('fetchUserMessages', { id, query: localQuery, isExport: true })).messages
        } else if (type === 'hashtag') {
            messages = (await dispatch('fetchHashtagMessages', { id, query: localQuery, isExport: true })).messages
        } else if (type === 'userComments') {
          messages = (await dispatch('fetchCommentsByUserID', id)).messages
        } else {
            messages = (await dispatch('fetchAllMessages', { ...localQuery, isExport: true })).messages
        }
        const entry = messages
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default TiktokUsersAll
