<template>
  <div>
    <Loader v-if="!isLoaded['hashtag_' + id]"/>
    <div v-else-if="allHashtags[id]">
      <h3>
        {{ $t('hoop.tag_details.title') }}
      </h3>
      <h2>
        #{{ allHashtags[id].value }}
      </h2>
    </div>
    <NotFound v-else/>
    <div class="uk-margin-medium-top">
      <ul uk-tab>
        <li
          class="uk-flex"
        >
          <a
            href="channels_tab"
            class="uk-padding-remove"
            @click="activateTab('channels_tab')"
          >
            {{ $t('hoop.stats.channels') }}
          </a>
          <span v-if="hash?.channels_count"
                class="uk-badge uk-margin-small-left"
          >
            {{ hash.channels_count }}
          </span>
        </li>
      </ul>
      <ul class="uk-switcher">
        <li>
          <a name="messages_tab"/>
          <ChannelList
            v-if="activatedTabs.includes('channels_tab')"
            :tag-id="id"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import ChannelList from '@/views/Hoop/ChannelList'
import { defineAsyncComponent } from 'vue'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HoopHashtagDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    ChannelList
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      activatedTabs: this.$route.query.tab || ['channels_tab']
    }
  },
  watch: {
    id: {
      handler () {
        this.activatedTabs = ['channels_tab'] // force rerender graphs
        this.update()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('hoop', [
      'allHashtags',
      'isLoaded'
    ]),
    hash () {
      return this.allHashtags[this.id]
    }
  },
  methods: {
    ...mapActions('hoop', [
      'fetchHashtagById'
    ]),
    activateTab (tab) {
      !this.activatedTabs.includes(tab) &&
        this.activatedTabs.push(tab)
    },
    handleError () {
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    update () {
      this.fetchHashtagById(this.id)
        .catch(err => this.handleError(err))
    }
  },
  mounted () {
    this.activateTab('channels_tab')
  }
}
</script>
