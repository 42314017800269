import i18n from '@/i18n'

const addMainMessageField = (messages, mainMessageId) => {
  return messages.map(message => {
    if (message.message_id === mainMessageId) message.main = '+'
    else message.main = '-'
    return message
  })
}
const removeEscapeCharacters = obj => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      obj[key] = JSON.stringify(obj[key]).replace(/\[\]|null/gm, '')
    } else {
      obj[key] = String(obj[key]).replace(/(\r\n|\n|\r)/gm, ' ')
    }
  })
  return obj
}
const filterFields = (raw, allowed) => Object.keys(raw)
  .filter(key => allowed.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key]
    return obj
  }, {})

const pullAvatar = obj => {
  obj.avatar = obj.avatar !== null ? obj.avatar.file_location : ''
  return obj
}

const filteredArr = (messages, prop = 'message_id') => messages.reduce((acc, current) => { // remove dublecates
  const x = acc.find(message => message[prop] === current[prop])
  if (!x) {
    return acc.concat([current])
  } else {
    return acc
  }
}, [])

const dayjsCurrentTimestamp = ({ date, format = 'YYYY-MM-DD HH:mm', timezone = 'Etc/UTC' }) => {
  const dateStr = String(date)
  if (typeof date === 'string' && !dateStr.length) return '' // date = "2019-11-19T04:00:57"
  if (Number.isNaN(+date) && dateStr[dateStr.length - 1].toUpperCase() !== 'Z' && !/\+/.test(dateStr)) date += 'Z'
  const dateTimeUtc = dayjs(date).utc()
  const timestamp = dateTimeUtc.unix()
  const offset = dayjs().tz(timezone).utcOffset() * 60
  const dateTimeLocal = dayjs.unix(timestamp + offset).utc()
  const formatted = dateTimeLocal.format(format)
  return formatted
}

const localizeCSVFields = (raw, type) => Object.keys(raw).reduce((obj, key) => {
  obj[i18n.t(`csv.${type}.${key}`)] = raw[key]
  return obj
}, {})

const messageMarkup = (item) => {
  const { message, markup } = item

  if (!markup || !Array.isArray(markup)) {
    return message
  }

  const supporedMarkup = markup.filter(({ name }) => name === 'TextUrl')

  if (supporedMarkup.length === 0) {
    return message
  }

  let commonOffset = 0
  let text = message

  supporedMarkup.forEach(rule => {
    const link = ` (${rule.url})`
    const offset = rule.offset + rule.length + commonOffset

    text = `${text.slice(0, offset)}${link}${text.slice(offset)}`

    commonOffset += link.length
  })

  return text
}

export {
  addMainMessageField,
  removeEscapeCharacters,
  filterFields,
  pullAvatar,
  filteredArr,
  messageMarkup,
  dayjsCurrentTimestamp,
  localizeCSVFields
}
