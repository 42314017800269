const sanitize = str => str
  .replace(/False/gim, false)
  .replace(/True/gim, true)
  .replace(/None/gim, null)
  .replace(/'/g, '"')

const tryParse = (raw = {}, prop) => {
  if (!(prop in raw)) return raw
  const newRaw = raw[prop]
  if (typeof newRaw === 'object') return newRaw
  if (typeof newRaw !== 'string') return raw
  try {
    let json = newRaw
    if (typeof json === 'string') {
      const jsonStr = sanitize(json)
      json = JSON.parse(jsonStr)
    }
    if (typeof json === 'string') json = JSON.parse(json)
    return json
  } catch {
    return false
  }
}

const parseRaw = (raw, props = ['raw', 'other_json', 'json_data', 'jsone_data']) => {
  const json = props.reduce((acc, prop) => tryParse(acc || raw, prop) || acc, false)
  props.forEach(prop => delete json[prop])
  return json
}

const strToArray = (...array) => [...array].flat().reduce((acc, el) => typeof el === 'string' ? [...acc, ...el.split(',').filter(el => el.trim())] : acc, [])

export {
  parseRaw,
  strToArray
}

export default tryParse
