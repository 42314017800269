<template>
  <div>
    <Loader v-if="!message.id"/>
    <div v-else>
      <div
        class="uk-card uk-card-default uk-card-small"
      >
        <div class="uk-card-body">
          <a :name="`message_${message.id}`"/>
          <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
            <AddStoredButton
              v-if="showStoredButton"
              provider="tiktok"
              type="message"
              :data="message"
            />
          </div>
          <div
            class="uk-grid-small uk-flex"
            uk-grid
          >
            <div
              v-if="user"
              :id="`tgcp_avatar_${message.message_id}`"
              class="uk-width-small@m "
            >
              <Avatar
                :avatar="user.avatar_url"
                :type="'user'"
              />
              <h5
                v-if="user.nickname"
                class="uk-card-title uk-text-center uk-margin-remove-bottom uk-margin-remove-top"
                style="font-size: 1.2rem;"
              >
                {{ user.nickname }}
              </h5>
              <h6
                v-if="user"
                class="uk-flex uk-flex-column my-align-items uk-text-center uk-margin-remove-bottom uk-margin-remove-top"
              >
                <span v-if="user.unique_id">
                  @<router-link
                    :to="{
                      name: 'nicknameResults',
                      params: { nickname: user.unique_id }}"
                  >
                    {{ user.unique_id }}
                  </router-link>
                </span>
                <span class="uk-flex">
                  <span>
                    #
                  </span>
                  <router-link
                    :to="{
                      name: 'tiktok_user_details',
                      params: { id: message.author_id }}"
                  >
                    {{ message.author_id }}
                  </router-link>
                </span>
              </h6>
            </div>
            <div
              v-if="message"
              class="uk-width-2-3@m uk-width-expand uk-flex uk-flex-column"
            >
              <div
                v-if="message"
                class="uk-width-expand"
              >
                <div class="uk-margin-left uk-text-left uk-margin-remove-top uk-text-break">
                  <div
                    v-html="extractedData"
                    class="uk-margin-small-top "
                  />

                  <div
                    v-if="message.play_url_h264"
                  >
                    <video
                      controls="controls"
                      :poster="poster"
                    >
                      <source
                        :src="message.play_url_h264"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div
                    v-else-if="message.cover"
                  >
                    <img
                      :src="`${gateway(message.cover.cdn_path)}`"
                    />
                  </div>
                </div>
              </div>
              <div class="uk-grid uk-grid-column-small">
                <div class="uk-margin-left uk-text-left">
                  <a
                    v-if="message.author"
                    :uk-tooltip="$t('tiktok.open-in-tiktok')"
                    :href="`https://tiktok.com/@${message.author.unique_id}/${message.id}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    uk-icon
                  >
                    <tiktokIcon/>
                  </a>
                </div>
                <div class="uk-margin-left">
                  <ul class="uk-list uk-list-divider uk-text-left">
                    <li v-if="message.retweets_count">
                      <span
                        :uk-tooltip="$t('twitter.messages.retweets')"
                        uk-icon
                      >
                        <font-awesome-icon :icon="['fa', 'retweet']" />
                      </span>
                      {{ message.retweets_count }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="uk-width-1-6@m" >
              <div class="uk-margin-left ">
                <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-bottom uk-text-muted">
                  <li>
                    <i>
                      <time
                        class="filter"
                        datetime="2016-04-01T19:00"
                      >
                        {{ dayjs(message.created_at) }}
                      </time>
                    </i>
                  </li>
                </ul>
              </div>
              <div class="uk-margin-large-top">
                <button
                  class="uk-button-text uk-button"
                  @click="translate"
                >
                  <font-awesome-icon :icon="['fa', 'language']" /> <span v-if="translatedShown">
                    {{ $t('twitter.messages.show-original') }}
                  </span><span v-else>
                    {{ $t('twitter.messages.translate') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <ul uk-tab>
          <li
            class="uk-flex"
            :class="checkForTab('comments_tab')"
          >
            <a
              id="comments_tab"
              class="uk-padding-remove"
              href="#comments_tab"
              @click="activateTab('comments_tab')"
            >
              Comments
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ message.comments_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('music_tab')"
          >
            <a
              id="music_tab"
              class="uk-padding-remove"
              href="#music_tab"
              @click="activateTab('music_tab')"
            >
              Music
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('stickers_tab')"
          >
            <a
              id="stickers_tab"
              class="uk-padding-remove"
              href="#stickers_tab"
              @click="activateTab('stickers_tab')"
            >
              Stickers
            </a>
          </li>
          <li
            v-if="captions"
            class="uk-flex"
            :class="checkForTab('captions_tab')"
          >
            <a
              id="captions_tab"
              class="uk-padding-remove"
              href="#captions_tab"
              @click="activateTab('captions_tab')"
            >
              Captions
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li
            v-if="message.comments_count"
            :class="checkForTab('comments_tab')"
          >
            <a name="comments_tab"/>
            <CommentsList
              :id="id"
              type="comments"
            />
          </li>
          <li :class="checkForTab('music_tab')">
            <a name="music_tab"/>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-body my-card-body">
                <div v-if="message.music_json">
                  <div>
                    Composition: {{ message.music_json.author }} - {{ message.music_json.title.replace('оригинальный звук -','original audio -') }}
                  </div>
                  <div v-if="message.music_json.owner_id">
                    Creator:
                    <router-link
                      :to="{
                        name: 'tiktok_user_details',
                        params: { id: message.music_json.owner_id }}"
                    >
                      {{ message.music_json.owner_nickname }}
                    </router-link>
                  </div>
                  <div>
                    <audio
                      v-if="message.music_json.play_url"
                      v-audio-stop
                      preload="none"
                      controls="controls"
                    >
                      <source
                        :src="message.music_json.play_url.uri"
                      />
                    </audio>
                  </div>
                </div>
                <div v-else>
                  No music data
                </div>
              </div>
            </div>
          </li>
          <li :class="checkForTab('stickers_tab')">
            <a name="stickers_tab"/>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-body my-card-body uk-text-left">
                <div v-if="stickers && stickers.length > 0">
                  <ul>
                    <li
                      v-for="sticker in stickers"
                      :key="sticker.index"
                    >
                      <span v-if="sticker.type===18">
                        Text: {{ sticker.text_info }}
                      </span>
                      <span v-else-if="sticker.type===8">
                        Mention: <router-link
                          :to="{
                            name: 'tiktok_user_details',
                            params: { id: sticker.mention_info.user_id }}"
                        >
                          {{ sticker.mention_info.nickname }}
                        </router-link> (@{{ sticker.mention_info.user_name }})
                      </span>
                      <span v-else-if="sticker.type===9">
                        Hashtag:  <router-link
                          :to="{
                            name: 'tiktok_hashtag_details',
                            params: {
                              id: sticker.hashtag_info.hashtag_id
                            }
                          }"
                        >
                          #{{ sticker.hashtag_info.hashtag_name }}
                        </router-link>
                      </span>
                      <span v-else-if="sticker.type===3">
                        Vote: {{ sticker.vote_info.question }}
                        <ul>
                          <li
                            v-for="option in sticker.vote_info.options"
                            :key="option.option_id"
                          >
                            {{ option.option_text }} - {{ option.vote_count }} votes
                          </li>
                        </ul>
                      </span>
                      <span v-else-if="sticker.type===17">
                        Answer to question: {{ sticker.question_info.content }}
                        <router-link
                          :to="{
                            name: 'tiktok_user_details',
                            params: { id: sticker.question_info.user_id }}"
                        >
                          @{{ sticker.question_info.username }}
                        </router-link>
                      </span>
                      <span v-else>
                        Unsupported Sticker
                      </span>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  No Interaction stickers for this video
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="captions"
            :class="checkForTab('captions_tab')"
          >
            <a name="captions_tab"/>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-body my-card-body uk-text-left">
                <div v-if="autoCaptions">
                  <ul>
                    <li
                      v-for="sticker in autoCaptions"
                      :key="sticker.text"
                    >
                      {{ sticker.text }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <Loader />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import Avatar from '@/components/Tiktok/Avatar'
import textHighlight from '@/utils/textHighlight'
import extractData from '@/utils/dataExtraction'
import config from '@/config'
import { apiRequest } from '@/utils/api'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import { mapGetters, mapActions } from 'vuex'
import contextProcessor from '@/utils/context-processor'
import tiktokIcon from '@/components/icons/tiktok-icon.vue'
import CommentsList from '@/views/Tiktok/CommentsList'

export default {
  name: 'MessageDetails',
  components: {
    Avatar,
    Loader,
    AddStoredButton,
    tiktokIcon,
    CommentsList
  },
  props: {
    id: {
      type: String,
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activatedTabs: this.$route.query.tab || ['comments_tab'],
      translatedText: false,
      translatedShown: false,
      autoCaptions: false,
      message: {}
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.update()
      }
    }
  },
  computed: {
    ...mapGetters('tiktok', [
      'allUsers'
    ]),
    accessLevel () {
      if (!this.$store.state.cases.cases[this.currentCase]) return
      return this.$store.state.cases.cases[this.currentCase].access_level
    },
    captions () {
      if (!this.message.interaction_stickers)
        return false
      let captionStickers = this.message.interaction_stickers.filter(sticker => sticker.type === 20 || sticker.type === 11)
      if (captionStickers && captionStickers.length>0) {
        console.log(captionStickers)
        let autocaptionUrl = captionStickers[0].auto_video_caption_info.auto_captions[0].url.url_list[0]
        let autocaptionUrl2 = captionStickers[0].auto_video_caption_info.auto_captions[0].url.url_list[1]
        this.fetchAutoCaption(autocaptionUrl, autocaptionUrl2)
        return true
      }
      return false
    },
    stickers () {
      if (!this.message.interaction_stickers) return []
      return this.message.interaction_stickers.filter(sticker => sticker.type === 18 || sticker.type === 8  || sticker.type === 9  || sticker.type === 3 || (sticker.type===17 || sticker.question_info))
    },
    extractedData () {
      if (!this.message) return
      if (this.translatedShown) {
        return extractData(this.translatedText)
      }
      const message = textHighlight(this.message.description, this.$route.query.search)
      return extractData(message)
    },
    poster () {
      if (this.message.cover) {
        return this.gateway(this.message.cover.cdn_path)
      }
      return false
    },
    user () {
      return this.message.author
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'fetchMessageLink',
      'fetchUserById',
      'fetchOneMessageByID'
    ]),
    fetchAutoCaption (url, urlReserve) {
      apiRequest(url).then( data => {
        this.autoCaptions = data.data.utterances
      }).catch(err => {
        console.debug(err)
        apiRequest(urlReserve).then( data => {
          this.autoCaptions = data.data.utterances
        })
      })
    },
    checkForTab (tabName) {
      const activeTab = this.activeTab
      const [tab] = tabName.split('_')
      if (tabName === activeTab) {
        return 'uk-active'
      }
      if (!this.message[tab + '_count'] && tab !== 'stickers' && tab !== 'music' && tab !== 'captions') return 'uk-disabled'
      else return ''
    },
    activateTab (tab) {
      !this.activatedTabs.includes(tab) &&
        this.activatedTabs.push(tab)
    },
    gateway (value) {
      return this.$gateway(value)
    },
    translate () {
      if (!this.translatedShown) {
        if (!this.translatedText && this.message) {
          const translateTo = this.$store.state.settings.translate
          let translateText = this.message.tweet_text
          const context = contextProcessor()
          const prevText = context.decode(translateText)
          apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, { lang: translateTo, text: prevText })
            .then(data => {
              this.translatedText = context.encode(data.data.translation)
              this.translatedShown = true
            }).catch(console.error)
        } else {
          this.translatedShown = true
        }
      } else {
        this.translatedShown = false
      }
    },
    update () {
      this.fetchOneMessageByID([this.id])
        .then(message => {
          this.message = message
        })

    }
  },
  mounted () {
    this.update()
  }
}
</script>
<style scoped>
.my-align-items {
  align-items: center
}
</style>
