<template>
  <div class="uk-margin-top">
    <div
      class="uk-grid uk-margin-top"
      data-uk-grid
    >
      <div class="uk-width-expand">
        <div v-if="!loaded">
          <Loader />
        </div>
        <div v-if="loaded && (!allAccounts[identity] || !allAccounts[identity].length)">
          <NotFound />
        </div>
        <div
          v-if="allAccounts[identity]"
          class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid"
        >
          <div

            v-for="(account, id) in allAccounts[identity]"
            :key="id"
            class="uk-grid-margin"
          >
            <UserCard2
              :generalUser="account"
              @opendetails="$emit('opendetails', account)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserCard2 from '@/components/UserCard2'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'GlobalSearchAccounts',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    UserCard2
  },
  props: {
    identity: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: val => ['tgcp', 'whatsapp', 'twitter', 'tiktok'].includes(val)
    }
  },
  computed: {
    ...mapGetters('fetcher', [
      'allAccounts'
    ]),
    loaded () {
      return this.$store.state.fetcher.isLoaded.accounts
    }
  },
  methods: {
    ...mapActions('fetcher', [
      'fetchAccounts'
    ]),
    update () {
      const types = {
        tgcp: 'http://t.me/',
        whatsapp: 'http://wa.me/',
        twitter: 'https://twitter.com/',
        tiktok: 'https://tiktok.com/'
      }
      const url = types[this.type]
        ? types[this.type] + this.identity
        : ''
      this.fetchAccounts({ url, identity: this.identity })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
