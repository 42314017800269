<template>
  <router-link 
    v-if="error" 
    :to="{ path: '/crypto/ton' }"
  >
    {{ 'An error occured while loading transaction! Go to overview page..' }}
  </router-link>
  <div 
    v-else-if="!isLoaded" 
    class="uk-flex uk-flex-center"
  >
    <Loader />
  </div>
  <div
    v-else-if="trn"
    class="uk-flex uk-flex-column uk-remove-margin"
    style="row-gap: 12px;" 
  >
    <div 
      class="uk-card uk-card-default uk-margin-remove"
    >
      <h3 
        class="uk-margin-remove uk-text-truncate uk-card-header"
        style="padding-top: 10px;"
      >
        {{ $t('crypto.route.transaction') + ` ${hash}` }}
      </h3>
      <PropsTable
        :trn="trn"
      />
    </div>
    <ul 
      v-if="inMessageKeys.length + outMessageKeys.length > 0"
      class="uk-child-width-expand uk-text-medium tab-styler uk-card-header" 
      uk-tab
    >
      <li 
        v-if="inMessageKeys.length + outMessageKeys.length > 0" 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#messages' || $route.hash === '#overview')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#messages', query: $route.query}">
          {{ $t('crypto.route.messages') }}
          <span class="uk-badge uk-margin-small-left">
            {{ inMessageKeys.length + outMessageKeys.length }}
          </span>
        </router-link>
      </li>
      <li 
        v-if="loadedAccountKeys.length + unloadedAccountKeys.length > 0" 
        class="uk-margin-remove-left" 
        :class="{'uk-active' : ($route.hash === '#accounts')}" 
        style="min-width: fit-content;"
      >
        <router-link :to="{ hash: '#accounts', query: $route.query}">
          {{ $t('crypto.route.accounts') }}
          <span class="uk-badge uk-margin-small-left">
            {{ loadedAccountKeys.length + unloadedAccountKeys.length }}
          </span>
        </router-link>
      </li>
    </ul>
    <div class="uk-card uk-card-default uk-margin-remove">
      <div 
        v-if="($route.hash === '#messages' || $route.hash === '#overview')" 
        id="messages_tx"
      >
        <h3 
          v-if="inMessageKeys.length > 0" 
          class="uk-margin-small-top uk-margin-small"
        >
          {{ $t('crypto.general.in_msg') + ` (${inMessageKeys.length})` }}
        </h3>
        <MessagesTable 
          :show-link="true" 
          :item-selector="false" 
          :default-length="10" 
          :update="false" 
          :keys="inMessageKeys" 
          :hidden="inMessageKeys.length === 0"
        />
        <h3 
          v-if="outMessageKeys.length > 0" 
          class="uk-margin-small-top uk-margin-small"
        >
          {{ $t('crypto.general.out_msg')+ ` (${outMessageKeys.length})` }}
        </h3>
        <MessagesTable 
          :show-link="true" 
          :item-selector="false" 
          :default-length="10" 
          :update="false" 
          :keys="outMessageKeys" 
          :hidden="outMessageKeys.length === 0"
        />
      </div>
      <div 
        v-if="($route.hash === '#accounts' ) && loadedAccountKeys.length + unloadedAccountKeys.length > 0" 
        id="accounts_tx"
      >
        <AccountsTable 
          :default-length="10" 
          :keys="loadedAccountKeys" 
          :hidden="loadedAccountKeys.length === 0" 
          :update="false" 
          :item-selector="false" 
          :contract="'All'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import PropsTable from '@/components/Crypto/Ton/Transactions/PropsTable'
import MessagesTable from '@/components/Crypto/Ton/Messages/Table'
import AccountsTable from '@/components/Crypto/Ton/Accounts/Table'
import { mapGetters, mapActions } from 'vuex'
import { toBase64Rfc } from '@/utils/ton-filters'

export default {
  name: 'TonTransactionInfo',
  components: {
    Loader,
    PropsTable,
    MessagesTable,
    AccountsTable
  },
  data () {
    return {
      error: false,
      deepTrKeys: false
    }
  },
  watch: {
    hash: {
      handler (to, from) {
        if (from && to) this.loadTransaction()
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonTransactions', 'getTonMessageKeys', 'getTonAccountKeys']),
    hash () { return this.$route?.params?.id ? toBase64Rfc(this.$route.params.id) : null },
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_transaction_' + this.hash] ?? false
    },
    trn () {
      return this.getTonTransactions[this.hash] ?? null
    },
    inMessageKeys () {
      return this.getTonMessageKeys([this.hash], true, false)
    },
    outMessageKeys () {
      return this.getTonMessageKeys([this.hash], false, true)
    },
    loadedAccountKeys () {
      return this.getTonAccountKeys([...this.inMessageKeys, ...this.outMessageKeys])
    },
    unloadedAccountKeys () {
      return this.getTonAccountKeys([...this.inMessageKeys, ...this.outMessageKeys], false)
    }
  },
  methods: {
    toBase64Rfc,
    ...mapActions('crypto', ['fetchTonTransaction', 'fetchBareTonAccounts']),
    async loadTransaction () {
      this.error = false
      if (!this.$route.hash) this.$router.replace(this.$route.fullPath + '#overview')
      const key = await this.fetchTonTransaction(this.hash)
      if (this.hash != key) this.$router.replace({ name: 'crypto_ton_transaction_info', params: { id: key }})
      this.fetchBareTonAccounts(this.unloadedAccountKeys)
      if (!this.trn) {
        this.error = true
        return
      }
    }
  },
  async mounted () {
    await this.loadTransaction()
  }
}
</script>