<template>
  <div>
    {{ numberFormat(data[column]) }}
  </div>
</template>
<script>
import { numberFormat } from '@/filters/globalFilters'

export default {
  name: 'DtFormattednumber',
  props: {
    data: null,
    column: null,
    index: null
  },
  methods: {
    numberFormat
  }
}
</script>
