import router from '@/router'
import config from '@/config'
import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'
const { servers: { discord } } = config

const flatternObj = (obj) => {
  const flattened = {}
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flatternObj(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export default {
  namespaced: true,
  state: {
    guilds: {},
    guildIcons: {},
    guildBanners: {},
    guildStats: {},
    guildHistory: {},
    guildInvites: {},
    guildChannels: {},
    guildLayerChannels: {},
    guildMessages: {},
    guildUsers: {},

    channels: {},
    channelMessages: {},

    users: {},
    userAvatars: {},
    userHistoryAvatars: {},
    userBanners: {},
    userMessages: {},
    userGuilds: {},

    messages: {},

    tasks: {},
    stats: {},
    searchResults: [],
    isLoaded: {},
    translatedCache: new Map(),
    cancelSources: {}
  },
  getters: {
    allGuilds: state => state.guilds,
    guildStats: state => state.guildStats,
    guildHistory: state => state.guildHistory,
    guildIcons: state => state.guildIcons,
    guildBanners: state => state.guildBanners,
    guildInvites: state => state.guildInvites,
    guildChannels: state => state.guildChannels,
    guildLayerChannels: state => state.guildLayerChannels,
    guildMessages: state => state.guildMessages,
    channels: state => state.channels,
    channelMessages: state => state.channelMessages,
    allUsers: state => state.users,
    userAvatars: state => state.userAvatars,
    userHistoryAvatars: state => state.userHistoryAvatars,
    userBanners: state => state.userBanners,
    userMessages: state => state.userMessages,
    messages: state => state.messages,
    isLoaded: (state) => state.isLoaded,
    stats: (state) => state.stats,
    allTasks: (state) => state.tasks
  },
  mutations: {
    setCancel (state, { prop, source }) {
      state.cancelSources[prop] = source
    },
    unsetCancel (state, { prop }) {
      delete state.cancelSources[prop]
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addGuildData: (state, payload) => {
      if (!payload.id) return
      state.guilds[payload.id] = payload
    },
    addGuildStats: (state, payload) => {
      payload.forEach(payload => {
        if (!payload.id) return
        state.guildStats[payload.id] = payload.data
      })
    },
    addGuildHistory: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state.guildHistory[payload.id] = payload.data
    },
    addGuildUsers: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state.guildUsers[payload.id] = payload.data
    },
    addGuildInvites: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state.guildInvites[payload.id] = payload.data
    },
    addGuildIcon: (state, payload) => {
      if (!payload.id && !payload.icon_url) return
      state.guildIcons[payload.id] = { url: payload.icon_url, animated: payload.icon_animated }
    },
    addGuildBanner: (state, payload) => {
      if (!payload.id && !payload.banner_url) return
      state.guildBanners[payload.id] = { url: payload.banner_url, animated: payload.banner_animated }
    },
    addChannelData: (state, { payload, loadFlag }) => {
      if (!payload.id) return
      if (!(payload.guild_id in state.guildChannels)) {
        state.guildChannels[payload.guild_id] = []
      }
      if (!payload.parent_id) {
        if (!(state.guildChannels[payload.guild_id].includes(payload.id))) {
          state.guildChannels[payload.guild_id].push(payload.id)
        }
      } else {
        if (!(payload.parent_id in state.guildLayerChannels)) {
          state.guildLayerChannels[payload.parent_id] = []
        }
        if (!state.guildLayerChannels[payload.parent_id].includes(payload.id)) {
          state.guildLayerChannels[payload.parent_id].push(payload.id)
        }
      }
      state.channels[payload.id] = payload

      if (loadFlag && payload.guild_id in state.guilds) {
        state.guilds[payload.guild_id].channels_loaded = true
      }
    },
    addUserData: (state, payload) => {
      if (!payload.id) return
      state.users[payload.id] = payload
    },
    addUserAvatar: (state, payload) => {
      if (!payload.id && !payload.avatar_url) return
      const avatar = { url: null, animated: false, decoration_url: null, decoration_animated: false}
      if (payload.avatar_url) {
        avatar.url = payload.avatar_url
        avatar.animated = payload.avatar_animated
      }
      if (payload.avatar_decoration_url) {
        avatar.decoration_url = payload.avatar_decoration_url
        avatar.decoration_animated = payload.avatar_decoration_animated
      }
      state.userAvatars[payload.id] = { ...avatar }
    },
    addUserAvatarHistory: (state, payload) => {
      if (!payload.id || !payload.avatars) return
      state.userHistoryAvatars[payload.id] = [...payload.avatars]
    },
    addUserBanner: (state, payload) => {
      if (!payload.id && !payload.banner_url) return
      state.userBanners[payload.id] = { url: payload.banner_url, animated: payload.banner_animated }
    },
    addUserGuilds: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state.userGuilds[payload.id] = payload.data
    },
    addMessageData: (state, payload) => {
      if (!payload.id || !payload.type) return
      if (payload.type === 'all') {
        state.messages[payload.id] = payload.message
      }
      if (payload.type === 'user') {
        if (!(payload.message.author_id in state.userMessages)) {
          state.userMessages[payload.message.author_id] = {}
        }
        state.userMessages[payload.message.author_id][payload.id] = payload.message
      }
      if (payload.type === 'channel') {
        if (!(payload.message.channel_id in state.channelMessages)) {
          state.channelMessages[payload.message.channel_id] = {}
        }
        state.channelMessages[payload.message.channel_id][payload.id] = payload.message
      }
      if (payload.type === 'guild') {
        if (!(payload.message.guild_id in state.guildMessages)) {
          state.guildMessages[payload.message.guild_id] = {}
        }
        state.guildMessages[payload.message.guild_id][payload.id] = payload.message
      }
    },
    addTask: (state, task) => {
      if (task.id) state.tasks[task.id] = task
    },
    addTasksBatch: (state, tasks) => {
      tasks.forEach(task => state.tasks[task.id] = task)
    },
    deleteTask: (state, taskId) => {
      delete state.tasks[taskId]
    },
    clearGuilds: (state) => {
      delete state.guilds
      state.guilds = {}
    },
    updateStats: (state, stats) => {
      state.stats = stats
    }
  },
  actions: {
    checkError ({ commit, dispatch }, err) {
      if (err.response && err.response.status === 401) {
        if (err.response.data.code === 'TOKEN_EXPIRED') {
          dispatch('refresh', null, { root: true }).then(() => {
            if (err.response.callAgain) {
              // dispatch(err.response.callAgain.name, err.response.callAgain.argument, { root: true })
            }
          })
        }
      }
    },
    async deleteTask ({ commit, dispatch, rootState }, taskId) {
      await apiRequest(`/tasks/` + taskId, 'DELETE', {}, twitter)
      commit('deleteTask', taskId)
    },
    async addTask ({ commit }, task) {
      try {
        return await apiRequest(`/tasks`, 'POST', {}, twitter, task)
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async editTask ({ commit }, { id, task }) {
      try {
        const { status, data } = await apiRequest(`/tasks/${id}`, 'PUT', {}, twitter, task)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Updated', type: 'success' })
            break
        }
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async massTaskAdd ({ commit, dispatch, rootState }, { tasks, needAddToCase }) {
      try {
        const { status, data } = await apiRequest(`/tasks/batch`, 'POST', {}, twitter, tasks)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Created', type: 'success' })
            break
        }
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    fetchStats ({ commit, state, dispatch, rootState }, id) {
      if (!state.stats.hasOwnProperty('guilds_count')) {
        commit('startLoad', `stats`)
      }
      apiRequest(`/stats`, 'GET', {}, discord)
        .then((data) => {
          commit('updateStats', data.data)
        }).catch(err => {
          err.response.callAgain = { name: 'discord/fetchStats', argument: null }
          dispatch('checkError', err)
          if (err.response && err.response.status === 404) console.error(err)
        }).finally(() => {
          commit('stopLoad', `stats`)
        })
    },
    // TODO: Repurpose for guild data fetching
    // getFromCacheOrRequestUserData ({ state, dispatch, rootState }, id) {
    //   if (state.users[id]) {
    //     return Promise.resolve({ data: state.users[id] })
    //   } else {
    //     return new Promise((resolve, reject) => {
    //       apiRequest(`/users/${id}`, 'GET', {}, twitter).then(function (data) {
    //         return resolve(data)
    //       })
    //     })
    //   }
    // },
    // getFromCacheOrRequestUserAvatar ({ state, dispatch, rootState }, id) {
    //   if (state.users['id'] && state.userAvatars[id]) {
    //     return Promise.resolve({ data: { file_location: state.userAvatars[id] } })
    //   } else {
    //     return apiRequest(`/v1/users/${id}/avatar`, 'GET')
    //   }
    // },
    // getUserReplies ({ state, dispatch, rootState }, id) {
    //   return apiRequest(`/v1/users/${id}/replies/`, 'GET')
    //     .then((data) => {
    //       var userData = data.data
    //       userData.id = id
    //       return Promise.resolve(userData)
    //     })
    // },
    // getFullUserData ({ state, dispatch, rootState }, id) {
    //   return Promise.all([
    //     dispatch('getFromCacheOrRequestUserData', id),
    //     dispatch('getFromCacheOrRequestUserAvatar', id)
    //   ].map(reflect)).then(function (values) {
    //     var user = {}
    //     for (var i in values) {
    //       if (values[i].status === 'fulfilled') {
    //         var data = values[i].value.data
    //         if (data.hasOwnProperty('file_location')) {
    //           user.avatar_src = config.servers.tgcp + data.file_location
    //         } else {
    //           user = { ...data, ...user }
    //         }
    //       }
    //     }
    //     return Promise.resolve(user)
    //   })
    // },
    async fetchDiscoverableGuilds ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'discoverable')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'guilds_all'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { hits, nbHits } } = await apiRequest(`resolver/guilds/discoverable/search${queryString}`, 'GET', {}, discord, undefined, cancellation)
        hits.forEach(guild => {
          commit('addGuildData', guild)
          commit('addGuildIcon', guild)
          commit('addGuildBanner', guild)
        })
        return { data: hits, results_count: nbHits }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', 'discoverable')
      }
    },
    async fetchAllGuilds ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'guilds')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'guilds_all'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/guilds${queryString}`, 'GET', {}, discord, undefined, cancellation)
        data.forEach(guild => {
          guild = { ...guild, answer_status: 'FOUND' }
          commit('addGuildData', guild)
          commit('addGuildIcon', guild)
          commit('addGuildBanner', guild)
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', 'guilds')
      }
    },
    fetchGuildById,
    async fetchGuildByIdWithoutCommitting ({ commit, state, dispatch }, params) {
      if (params in state.guilds && state.guilds[params].sync_date) {
        commit('stopLoad', 'guild_' + params)
        return state.guilds[params]
      }
      const data = await fetchGuildById({ commit: () => {}, dispatch: () => {} }, params)
      return data
    },
    fetchGuildStatsById ({ commit, state, dispatch, rootState }, id) {
      if (id in state.guilds && id in state.guildStats) {
        commit('stopLoad', `guild_${id}_stats`)
        return
      }
      commit('startLoad', 'guild_' + id + '_stats')
      apiRequest(`/guilds/${id}/stats`, 'GET', {}, discord)
        .then((data) => {
          const guildData = { id, data: [...data]}
          commit('addGuildStats', guildData)
        }).catch((err) => {
          err.response.callAgain = { name: 'discord/fetchGuildStatsById', argument: id }
          dispatch('checkError', err)
        }).finally(() => {
          commit('stopLoad', `guild_${id}_stats`)
        })
    },
    fetchGuildHistory ({ commit, state, dispatch, rootState }, id) {
      if (id in state.guilds && id in state.guildHistory) {
        commit('stopLoad', `guild_${id}_history`)
        return
      }
      commit('startLoad', `guild_${id}_history`)
      apiRequest(`/guilds/${id}/history`, 'GET', {}, discord)
        .then((data) => {
          const guildData = { id, data: data.data.data}
          if (guildData) commit('addGuildHistory', guildData)
        }).finally(() => {
        commit('stopLoad', `guild_${id}_history`)
      })
    },
    fetchGuildInvites ({ commit, state, dispatch, rootState }, id) {
      if (id in state.guilds && id in state.guildHistory) {
        commit('stopLoad', `guild_${id}_invites`)
        return
      }
      commit('startLoad', `guild_${id}_invites`)
      apiRequest(`/guilds/${id}/invites`, 'GET', {}, discord)
        .then((data) => {
          const guildData = { id, data: data.data.data}
          if (guildData) commit('addGuildInvites', guildData)
        }).finally(() => {
        commit('stopLoad', `guild_${id}_invites`)
      })
    },
    async resolveGuildInvite ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `invite_${id}`)
      try {
        const { data } = await apiRequest(`/resolver/invite/${id}`, 'GET', {}, discord)
        return data
      } catch (error) {
        console.debug(error)
      } finally {
        commit('stopLoad', `invite_${id}`)
      }
    },
    async fetchGuildChannels ({ commit, state, dispatch, rootState }, id) {
      if (id in state.guilds && state.guilds[id].channels_loaded) {
        commit('stopLoad', `guild_${id}_channels`)
        return
      }
      commit('startLoad', `guild_${id}_channels`)
      try {
        const { data } = await apiRequest(`/guilds/${id}/channels`, 'GET', {}, discord)
        const channels = data.data
        for (const channel of channels) {
          commit('addChannelData', { payload: channel, loadFlag: true })
        }
      } catch (err) {
        console.log(err)
      } finally {
        commit('stopLoad', `guild_${id}_channels`)
      }
    },
    async fetchGuildUsers ({ commit, state, dispatch, rootState }, { query, id }) {
      commit('startLoad', `guild_${id}_users`)

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''

      try {
        const { data: { data, meta } } = await apiRequest(`/guild/${id}/members${queryString}`, 'GET', {}, discord)
        for (const user of data) {
          commit('addGuildUsers', user)
        }
        return { data, meta }
      } catch (err) {
        console.log(err)
      } finally {
        commit('stopLoad', `guild_${id}_users`)
      }
    },
    async fetchChannelById ({ commit, state, dispatch, rootState }, id) {
      if (id in state.channels) {
        commit('stopLoad', `channel_${id}`)
        return
      }
      commit('startLoad', `channel_${id}`)
      try {
        const { data } = await apiRequest(`/channels/${id}`, 'GET', {}, discord)
        commit('addChannelData', { payload: data, loadFlag: false })
        return data
      } catch (err) {
        console.log(err)
      } finally {
        commit('stopLoad', `channel_${id}`)
      }
    },
    async fetchAllUsers ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'users')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'users_all'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/users${queryString}`, 'GET', {}, discord, undefined, cancellation)
        data.forEach(user => {
          user = { ...user, answer_status: 'FOUND' }
          commit('addUserData', user)
          commit('addUserAvatar', user)
          commit('addUserAvatarHistory', user)
          commit('addUserBanner', user)
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', 'users')
      }
    },
    async fetchUserGuilds ({ commit, state, dispatch, rootState }, { query, id }) {
      commit('startLoad', `user_${id}_guilds`)

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''

      try {
        const { data: { data, meta } } = await apiRequest(`/user/${id}/guilds${queryString}`, 'GET', {}, discord)
        for (const user of data) {
          commit('addUserGuilds', user)
        }
        return { data, meta }
      } catch (err) {
        console.log(err)
      } finally {
        commit('stopLoad', `user_${id}_guilds`)
      }
    },
    async fetchAllMessages ({ dispatch, commit, state, rootState }, { query, type = 'all', id = null}) {
      const actionType = type === 'all' ? 'messages' : `${type}_${id}_messages`
      commit('startLoad', actionType)

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'messages'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/messages${queryString}`, 'GET', {}, discord, undefined, cancellation)
        data.forEach(message => {
          commit('addMessageData', { id: message.id, type, message})
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', actionType)
      }
    },
    async fetchMessageById ({ dispatch, commit, state, rootState }, id) {
      if (id in state.messages) {
        return state.messages[id]
      }
      try {
        const { data } = await apiRequest(`/messages/${id}`, 'GET', {}, discord)
        commit('addMessageData', { id: data.id, message: data, type: 'all'})
        return data
      } catch (error) {
        console.debug(error)
      }
    },
    async getMessageOffset ({ dispatch, commit, state, rootState }, { channelId, messageId }) {
      if (!channelId || !messageId ) return
      const { data: offset } = await apiRequest(`/channels/${channelId}/${messageId}/offset?order_by=id&order_type=ASC`, 'GET', {}, discord)
      return offset
    },
    fetchUserById,
    async fetchUserByIdWithoutCommitting ({ commit, state, dispatch }, params) {
      if (params in state.users && state.users[params].sync_date) {
        commit('stopLoad', 'user_' + params)
        return state.users[params]
      }
      const data = await fetchUserById({ commit: () => {}, dispatch: () => {} }, params)
      return data
    },
    fetchTaskById (_, id) {
      return apiRequest(`/tasks/${id}`, 'GET', {}, discord)
    },
    async fetchAllTasks ({ commit }, query) {
      try {
        const queryString = getQueryString(query)
        const { data } = await apiRequest(`/tasks/?${queryString}`, 'GET', {}, discord)
        commit('addTasksBatch', data.data)
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'twitter_tasks', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'twitter_tasks', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async addTask ({ commit }, task) {
      commit('startLoad', 'add_task')
      try {
        const { status, data } = await apiRequest(`/tasks`, 'POST', {}, discord, task)

        commit('stopLoad', 'add_task')
        return { status, data }
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        commit('stopLoad', 'add_task')
        throw err
      }
    },
    async massTaskAdd ({ commit, dispatch, rootState }, { tasks }) {
      commit('startLoad', 'tasks_batch')

      try {
        const { status, data } = await apiRequest(`/tasks/batch`, 'POST', {}, discord, tasks)
        switch (status) {
          case 200:
            if (data.created && data.created.length !== 0) {
              notify({ group: 'general', text: `Created ${data.created.length} tasks`, type: 'success' })
            }
            if (data.exists && data.exists.length !== 0) {
              notify({ group: 'general', text: `${data.exists.length} tasks exist`, type: 'warn' })
            }
            if (data.errors && data.errors.length !== 0) {
              notify({ group: 'general', text: `${data.errors.length} errors while creating tasks`, type: 'error' })
            }
            break
        }
        commit('stopLoad', 'tasks_batch')
        return data
      } catch (err) {
        commit('stopLoad', 'tasks_batch')
        throw err
      }
    },
    async editTask ({ commit }, { id, task }) {
      try {
        const { status, data } = await apiRequest(`/tasks/${id}`, 'PUT', {}, discord, task)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Updated', type: 'success' })
            break
        }
        return data
      } catch (err) {
        throw err
      }
    },
    async deleteTask ({ commit, dispatch, rootState }, taskId) {
      await apiRequest(`/tasks/` + taskId, 'DELETE', {}, discord)
      commit('deleteTask', taskId)
    },
    translateText: async ({ state, rootState }, { text }) => {
      const translateTo = rootState.settings.translate
      try {
        const translatedCacheKey = JSON.stringify({
          translateTo,
          text
        })
        const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
        if (!hasTranslatedCache) {
          const {
            data: { translation }
          } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
            lang: translateTo,
            text: text
          })
          state.translatedCache.set(translatedCacheKey, { data: { translation } })
          return {
            data: {
              translation
            }
          }
        }
        return state.translatedCache.get(translatedCacheKey)
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    },
    clearGuilds ({ commit }) {
      commit('clearGuilds')
    },
    addParamToQuery ({ commit }, param) {
      const oldQuery = router.currentRoute.value.query
      if (!(Object.keys(oldQuery).every((key) =>  String(oldQuery[key]) === String(param[key] ?? ''))
        && Object.keys(param).every((key) =>  String(param[key]) === String(oldQuery[key] ?? ''))))
        router.replace({ query: param })
    }
  }
}

async function fetchUserById ({ commit, state, dispatch, rootState }, id) {
  commit('startLoad', 'user_' + id)
  try {
    const { data } = await apiRequest('/users/' + id, 'GET', {}, discord)
    let userData = { ...data, answer_status: 'FOUND' }
    commit('addUserData', userData)
    commit('addUserAvatar', userData)
    commit('addUserAvatarHistory', userData)
    commit('addUserBanner', userData)
    return userData
  } catch (err) {
    if (err && err.status === 404) {
      commit('addUserData', { id: id, 'answer_status': 'NOT_FOUND' })
    }
  } finally {
    commit('stopLoad', 'user_' + id)
  }
}

async function fetchGuildById ({ commit, state, dispatch, rootState }, id) {
  commit('startLoad', 'guild_' + id)
  apiRequest('/guilds/' + id, 'GET', {}, discord)
    .then((data) => {
      let guildData = { ...data.data, answer_status: 'FOUND' }
      commit('addGuildData', guildData)
      commit('addGuildIcon', guildData)
      commit('addGuildBanner', guildData)
      return guildData
    }).catch((err) => {
      if (err.response && err.response.status === 404) {
        commit('addGuildData', { id: id, 'answer_status': 'NOT_FOUND' })
      }
    }).finally(() => {
      commit('stopLoad', 'guild_' + id)
    })
}
