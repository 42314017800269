<template>
  <div
    class="uk-margin-top"
  >
    <div
      class="uk-grid"
      data-uk-grid-margin
    >
      <div class="uk-width-1-1">
        <UsedProviders
          v-if="allProviders"
          class="uk-align-right uk-width-1-4 uk-margin-small-right"
          :search="domain"
          type="domain"
        />
        <ul
          id="viewTab"
          class="uk-tab"
          uk-tab
        >
          <li class="uk-active">
            <a href="">
              {{ $t('search.founds') }}
              <span
                v-if="list"
                class="uk-badge"
              >
                {{ list.length }}
              </span>
            </a>
          </li>
          <li class="uk-disabled">
            <a>
              {{ $t('search.mentions') }}
            </a>
          </li>
        </ul>
        <ul
          class="uk-switcher"
        >
          <li>
            <ul
              class="uk-subnav uk-subnav-pill"
            >
              <li
                :class="{
                  'uk-active': !filter
                }"
              >
                <a
                  href="#"
                  @click="filter = ''"
                >
                  {{ $t('search.types.all') }}
                </a>
              </li>
              <li
                v-for="item in types"
                :key="item"
                :class="{ 'uk-active': filter === item }"
              >
                <a
                  href="#"
                  @click="filter = item"
                >
                  {{ $t('search.types.' + item) }}
                  <span
                    :class="{ 'badge-inverse': filter === item }"
                    class="uk-badge uk-margin-small-left"
                  >
                    {{ list.filter(card => card.genericType === item).length }}
                  </span>
                </a>
              </li>
            </ul>
            <Loader
              v-if="!isLoaded['providers']"
            />
            <div
              v-else
              class="uk-margin-top"
            >
              <progress
                v-if="total > current"
                id="js-progressbar"
                class="uk-progress"
                :value="current"
                :max="total"
              />
              <div
                v-if="total <= current && !filteredList.length"
              >
                <Loader
                  v-if="!allProviders.length"
                />
                <NotFound
                  v-if="current > 0 && allProviders.length"
                />
              </div>
              <div class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid">
                <div
                  v-for="(item, i) in filteredList"
                  :key="item.id"
                  uk-inline
                  class="uk-inline uk-grid-margin"
                >
                  <usercard
                    :key="item.ext_id || item.getId()"
                    :generalUser="item"
                    @opendetails="activeItem = i"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <rightSidebar>
      <ProviderDetails
        v-if="showProviderDetails"
        :data="providerSidebarData"
        :raw="rawParsed"
      />
    </rightSidebar>
    <ExportDialog
      :id="domain"
      :search="filter"
      namespace="fetcher"
      type="users"
      subtype="domain"
      :messages="filteredList"
      :totalCount="filteredList.length"
    />
  </div>
</template>

<script>
import { parseRaw } from '@/utils/tryParse'
import { mapGetters } from 'vuex'
import rightSidebar from '@/components/RightSidebar'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Domains',
  components: {
    usercard: defineAsyncComponent(() => import('@/components/UserCard2')),
    rightSidebar,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader')),
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    UsedProviders: defineAsyncComponent(() => import('@/components/GlobalSearch/UsedProviders'))
  },
  props: {
    domain: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      activeItem: null,
      details: {},
      filter: ''
    }
  },
  watch: {
    domain (from, to) {
      this.update()
    },
    excludedSources : {
      handler (to, from) {
        if (from === null) this.update()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['excludedSources']),
    ...mapGetters('fetcher', ['allProviders', 'isLoaded']),
    ...mapGetters('leaks', ['passwords', 'isOverLimits']),
    rawParsed () {
      const data = this.providerSidebarData
      if (!data) return false
      return parseRaw(data)
    },
    types () {
      if (!this.list) return []
      return [ ...new Set(this.list.map(el => el.genericType)) ].sort()
    },
    showProviderDetails () {
      return this.rawParsed && Object.keys(this.rawParsed).length
    },
    providerSidebarData () {
      return this.activeItem !== null && this.filteredList[this.activeItem]
    },
    filteredList () {
      if (!this.list) return []
      if (!this.filter) return this.list
      return this.list.filter(card => card.genericType === this.filter)
    },
    total () {
      return this.$store.state.fetcher.total
    },
    current () {
      return this.$store.state.fetcher.count
    },
    list () {
      let list = this.$store.state.fetcher.domain[this.domain] || []
      let set = []
      list = list.filter(card => {
        if (!card.ext_id) return true
        let i = set.findIndex(el => el.ext_id === card.ext_id)
        if (i === -1) {
          set.push({ ...card })
          return true
        } else {
          Object.assign({ ...set[i] }, { ...card })
        }
        return false
      })
      return list
    }
  },
  methods: {
    updateDetails (raw, id) {
      this.activeItem = id
      this.details = raw
    },
    update () {
      const action = 'fetcher/fetch'
      this.$store.dispatch(action, {
        search: this.domain,
        type: 'domain'
      })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
