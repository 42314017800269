<template>
  <div>
    <div v-if="!isLoaded"/>
    <div v-else>
      <div
        v-if="phone"
        class="uk-margin-small-left"
      >
        <router-link :to="{ name: 'phoneResults', params: { phone: phone } }">
          {{ phone }}
        </router-link>
        <div v-if="isTonVirtual">
          <table>
            <tr>
              <td>
                Owner:
              </td>
              <td>
                <Loader
                  v-if="!tonIsLoaded"
                  ratio="1.0"
                />
                <router-link
                  v-else
                  :to="{
                    name: 'crypto_ton_address',
                    params: {
                      address: tonAddress
                    }
                  }"
                >
                  {{ tonOwner }}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else-if="hasPhone && !isShown">
        <button
          class="uk-button uk-button-small uk-button-default"
          @click.stop.prevent="ask"
        >
          {{ $t('tgcp.user_details.resolve_phone') }}
        </button>
      </div>
      <div v-else-if="hasPhone && isShown">
        <Loader v-if="isLoading && !isLoadedPhone" />
        <div
          v-else
          class="uk-text-center"
        >
          {{ $t('tgcp.resolver.warning.cost_warning') }}
          <br />
          <button
            class="uk-button uk-button-link uk-button-small"
            @click.stop.prevent="isShown = false"
          >
            {{ $t('tgcp.resolver.cancel') }}
          </button>
          <button
            class="uk-button uk-button-link uk-button-primary uk-button-small uk-margin-small-left"
            @click.stop.prevent="resolvePhone"
          >
            {{ $t('tgcp.resolver.ok') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters, mapState } from 'vuex'
import { truncString } from '@/utils/ton-filters'

export default {
  components: {
    Loader
  },
  props: {
    id: {
      required: true,
      type: String
    },
    type: {
      type: String,
      validator: val => ['usercard', 'details'].includes(val)
    }
  },
  data () {
    return {
      isLoading: false,
      isShown: false
    }
  },
  watch: {
    id (from, to) {
      this.update()
    },
    phone (from, to) {
      if (this.isTonVirtual) {
        this.fetchTonAddressByPhone(this.phone)
      }
    }
  },
  computed: {
    ...mapState([
      'settings'
    ]),
    ...mapGetters('crypto', [
      'tonPhones'
    ]),
    phone () {
      if (!this.$store.state.tgcp.users[this.id]) return
      return this.$store.state.tgcp.users[this.id].phone
    },
    isTonVirtual () {
      if (!this.phone) return
      if (!this.phone.startsWith('+888')) return
      return true
    },
    hasPhone () {
      if (!this.$store.state.tgcp.users[this.id]) return
      return this.$store.state.tgcp.users[this.id].has_phone
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id + '_has_phone']
    },
    isLoadedPhone () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id + '_phone']
    },
    tonData () {
      return this.tonPhones[this.phone.replace('+','')] || []
    },
    tonIsLoaded () {
      return this.$store.state.crypto.isLoaded['tonphone_'+this.phone.replace('+','')]
    },
    tonOwner () {
      if (!this.tonData) {
        return ''
      }
      return this.truncString(this.tonData.address, 5)
    },
    tonAddress () {
      if (!this.tonData) {
        return ''
      }
      return this.tonData.address
    }
  },
  methods: {
    ...mapActions('tgcp', ['fetchUserById', 'checkPhoneExists', 'fetchUserPhoneById']),
    ...mapActions('crypto', [
      'fetchTonAddressByPhone'
    ]),
    truncString,
    ask () {
      if (this.settings.silent_token_write_off) {
        this.resolvePhone()
      } else {
        this.isShown = true
      }
    },
    async resolvePhone () {
      this.isLoading = true
      const action = 'tgcp/fetchUserPhoneById'
      try {
        await this.$store.dispatch(action, this.id)
      } catch (err) {
        const status = err.response.status
        const text = status === 404 ? this.$t('tgcp.resolver.errors.not_found') : this.$t('tgcp.resolver.errors.not_enough_money')
        this.$notify({
          group: 'general',
          title: this.$t('notifications.error'),
          text,
          type: 'error'
        })
      } finally {
        this.isLoading = false
        this.isShown = false
      }
    },
    update () {
      const action = 'tgcp/fetchUserPhoneAvailabilityById'
      this.$store.dispatch(action, this.id)
    }
  },
  mounted () {
    this.update()
  }
}
</script>
