import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class WhatsappGroupsUser extends Base {
  schema = Schemas.whatsapp.group
  OPTIONS_SCHEMA = ['id', 'query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { id, query, groups } = options
    if (!!groups && groups.length) {
      return groups
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      while (localQuery.offset < this.total) {
        const params = {
          phone: id,
          query: localQuery
        }
        const { data } = await dispatch('fetchUserOwnedGroups', params)
        const entry = data
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default WhatsappGroupsUser
