<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            v-if="type === 'all'"
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              type="submit"
              class="uk-button uk-button-primary uk-search-icon"
            >
              <span uk-search-icon />
            </button>
          </div>
          <div 
            v-if="type === 'all'"
            class="uk-display-inline-block uk-padding-remove"
          >
            <vueSelect
              v-model="currentLanguage"
              :options="languageOptions"
              label="label"
              class="uk-display-inline-block uk-width-auto uk-form-width-medium"
            />
          </div>
          <div 
            v-if="type === 'all'"
            class="uk-display-inline-block uk-padding-remove"
          >
            <vueSelect
              v-model="currentCountry"
              :options="countryOptions"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block">
            {{ $t('twitter.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-width-auto uk-form-width-medium"
            />
          </div>
          <div
            class="uk-margin"
          >
            <label
              v-for="field in searchList"
              :key="field"
              class="uk-margin-left"
            >
              <input
                v-model="query.search_fields"
                type="checkbox"
                class="uk-checkbox"
                :value="field"
              />
              {{ $t(`imo.sort.${field}`) }}
            </label>
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <template v-else>
          <GroupCard
            v-for="group in groups"
            :key="group.bgid"
            class="uk-margin-top"
            :group="group"
            @setLanguage="event => setLanguage(event)"
            @setCountry="event => setCountry(event)"
          />
        </template>
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import { countryCodeToName } from '@/filters/globalFilters'
import Paginate from '@/components/Paginate.vue'

export default {
  name: 'ImoGroupList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    GroupCard: defineAsyncComponent(() => import('@/components/Imo/GroupList/GroupCard')),
    Paginate,
    vueSelect,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'groups'].includes(val)
    },
    status: {
      type: String,
      required: false,
      validator: val => [undefined, 'former', 'owned'].includes(val)
    }
  },
  data () {
    return {
      groups: [],
      orderList: ['created_at'],
      searchList: ['name', 'description', 'tags'],
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'created_at',
        order_type: this.$route.query.order_type || 'desc',
        search_fields: [],
        search: this.$route.query.search || '',
        language: this.$route.query.language || null,
        cc: this.$route.query.cc || null
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.updateQuery()
    },
    query (to, from) {
      console.log(to)
    }
  },
  computed: {
    ...mapGetters('imo', ['isLoaded', 'languages', 'countries']),
    hasDataToView () {
      return this.groups.length > 0
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded['groups_' + this.id] : this.isLoaded['groups']
    },
    currentOrderBy: {
      get () {
        return this.order
      },
      set (obj) {
        this.order = obj && obj.value
        this.searchHandler()
      }
    },
    currentLanguage: {
      get () {
        return this.query.language || null
      },
      set (obj) {
        this.query.language = obj && obj.value
        this.searchHandler()
      }
    },
    currentCountry: {
      get () {
        return this.query.cc || null
      },
      set (obj) {
        this.query.cc = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return [
        { value: 'asc-created_at', label: this.$t('imo.sort.created_at_asc') },
        { value: 'desc-created_at', label: this.$t('imo.sort.created_at_desc') }
      ]
    },
    order: {
      get () {
        return this.query.order_type + '-' + this.query.order_by
      },
      set (value) {
        [this.query.order_type, this.query.order_by] = value.split('-')
      }
    },
    languageOptions () {
      return [
        { value: null, label: this.$t('imo.group_details.language')},
        ...this.languages.map(item => {
          return { value: item.value, label: item.value}
        })
      ]
    },
    countryOptions () {
      return [
        { value: null, label: this.$t('imo.group_details.cc')},
        ...this.countries.map(item => {
          return { value: item.value, label: this.$t(`countries.${item.value}`) }
        })
      ]
    }
  },
  methods: {
    ...mapActions('imo', ['fetchAllGroups', 'loadFilters', 'addParamToQuery', 'fetchUserGroups']),
    countryCodeToName,
    async init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      if (this.type === 'all') await this.loadFilters()
      this.query.offset = this.query.limit * (page - 1)

      const searchFields = this.$route.query.search_fields
      if (searchFields) this.query.search_fields = searchFields && Array.isArray(searchFields) ? searchFields : [searchFields]

      this.pagination.page = page
      await this.updateQuery()
    },
    async setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      await this.updateQuery()
    },
    setLanguage (lang) {
      this.query = {
        ...this.query,
        search: '',
        cc: null,
        language: lang
      }
      this.setPage(1)
    },
    setCountry (country) {
      this.query = {
        ...this.query,
        search: '',
        cc: country,
        language: null
      }
      this.setPage(1)
    },
    setData (groups) {
      this.groups = groups.data
      this.pagination.total = groups.meta.results_count
    },
    searchHandler () {
      this.setPage(1)
    },
    handleError () {
      this.groups = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    updateQuery (init = false) {
      if (this.type === 'all') {
        this.addParamToQuery(this.query)
      }
      this.loadGroups()
    },
    loadGroups () {
      let { type, status } = this
      const data = {
        id: this.id,
        query: { ...this.query },
        path: (type ? `/${type}` : '') + (status ? `/${status}` : '')
      }
      const opts = {
        all: ['fetchAllGroups', data.query],
        groups: ['fetchUserGroups', data]
      }
      const [method, payload] = opts[type]

      return this[method](payload)
        .then(groups => this.setData(groups))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>
