<template>
  <div
    :class="`uk-width-xxlarge uk-text-break ${(isSelected) ? 'uk-box-shadow-bottom': ''}`"
    style="height: 100%;min-height: 220px"
  >
    <div
      :class="`${cardType} uk-card uk-text-break uk-card-default uk-inline uk-width-xxlarge`"
      class="uk-flex uk-flex-column uk-flex-between"
      style="height: 100%;"
    >
      <div
        uk-grid
        class="uk-grid-collapse uk-grid"
      >
        <div
          class="uk-position-top-left uk-am"
          style="padding-bottom: 0;padding-top: 5px; padding-left: 7px"
        >
          <input
            v-if="selectMode === 'selected'"
            v-model="isSelected"
            class="uk-checkbox"
            type="checkbox"
          />
        </div>
        <div
          class="uk-position-top-right uk-am uk-overlay uk-overlay-default uk-padding-small"
          style="padding-bottom: 0;padding-top: 5px;"
        >
          <button
            type="button"
            disabled
            class="uk-icon type-icon"
          >
            <span v-if="generalUser.type === 'instagram'">
              <span
                uk-icon="icon: instagram"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'twitter'">
              <span
                uk-icon="icon: twitter"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'facebook'">
              <span
                uk-icon="icon: facebook"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'hangouts'">
              <span
                uk-icon="icon: google"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'linkedin'">
              <span
                uk-icon="icon: linkedin"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'whatsapp'">
              <span
                uk-icon="icon: whatsapp"
                title="WhatsApp"
              />
              WhatsApp
            </span>
            <span v-else-if="generalUser.type === 'github'">
              <span
                uk-icon="icon: github"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'wordpress'">
              <span
                uk-icon="icon: wordpress"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'zalo'">
              <zaloIcon
                style="width:1.5em;"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'threema'">
              <threemaIcon
                style="width:1.5em;"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'flickr'">
              <span
                uk-icon="icon: flickr"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'truecaller'">
              <span
                uk-icon="icon: receiver"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'numberlookup'">
              <span
                uk-icon="icon: receiver"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'behance'">
              <span
                uk-icon="icon: behance"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'dribbble'">
              <span
                uk-icon="icon: dribbble"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'soundcloud'">
              <span
                uk-icon="icon: soundcloud"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'vimeo'">
              <span
                uk-icon="icon: vimeo"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'pinterest'">
              <span
                uk-icon="icon: pinterest"
                :title="generalUser.type"
              />
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'discord'">
              <span
                uk-icon
                title="Discord"
              >
                <font-awesome-icon :icon="['fab', 'discord']" />
              </span>
              Discord
            </span>
            <span v-else-if="generalUser.type === 'tumblr'">
              <span
                uk-icon="icon: tumblr"
                :title="generalUser.type"
              />
              Tumblr
            </span>
            <span v-else-if="generalUser.type === 'etsy'">
              <span
                uk-icon="icon: etsy"
                :title="generalUser.type"
              />
              Etsy
            </span>
            <span v-else-if="generalUser.type === 'skype'">
              <font-awesome-icon :icon="['fab', 'skype']" />
              Skype
            </span>
            <span v-else-if="generalUser.type === 'reddit'">
              <span
                uk-icon="icon: reddit"
                :title="generalUser.type"
              />
              Reddit
            </span>
            <span v-else-if="generalUser.type === 'trello'">
              <span
                uk-icon
                title="Trello"
              >
                <font-awesome-icon :icon="['fab', 'trello']" />
              </span>
              Trello
            </span>
            <span v-else-if="generalUser.type === 'telegram' || generalUser.type === 'tgcp'">
              <span
                uk-icon
                title="Telegram"
              >
                <font-awesome-icon :icon="['fab', 'telegram-plane']" />
              </span>
              Telegram
            </span>
            <span v-else-if="generalUser.type === 'ok' ">
              <span
                uk-icon
                title="OK"
              >
                <font-awesome-icon :icon="['fab', 'odnoklassniki']" />
              </span>
              OK
            </span>
            <span v-else-if="generalUser.type === 'vk'">
              <span
                uk-icon
                title="VK"
              >
                <font-awesome-icon :icon="['fab', 'vk']" />
              </span>
              VK
            </span>
            <span v-else-if="generalUser.type === 'viber'">
              <span
                uk-icon
                title="Viber"
              >
                <font-awesome-icon :icon="['fab', 'viber']" />
                Viber
              </span>
            </span>
            <span v-else-if="generalUser.type === 'medium'">
              <span
                uk-icon
                title="Medium"
              >
                <font-awesome-icon :icon="['fab', 'medium']" />
              </span>
              Medium
            </span>
            <span v-else-if="generalUser.type === 'bitbucket'">
              <span
                uk-icon
                title="BitBucket"
              >
                <font-awesome-icon :icon="['fab', 'bitbucket']" />
              </span>
              BitBucket
            </span>
            <span v-else-if="generalUser.type === 'foursquare'">
              <span
                uk-icon="icon: foursquare"
                title="FourSquare"
              />
              FourSquare
            </span>
            <span v-else-if="generalUser.type === 'tripadvisor'">
              <span
                uk-icon="icon: tripadvisor"
                title="TripAdvisor"
              />
              TripAdvisor
            </span>
            <span v-else-if="generalUser.type === 'gitlab'">
              <span
                uk-icon
                title="GitLab"
              >
                <font-awesome-icon :icon="['fab', 'gitlab']" />
              </span>
              GitLab
            </span>
            <span v-else-if="generalUser.type === 'patreon'">
              <span
                uk-icon
                title="Patreon"
              >
                <font-awesome-icon :icon="['fab', 'patreon']" />
              </span>
              Patreon
            </span>
            <span v-else-if="generalUser.type === 'twitch'">
              <span
                uk-icon
                title="Twitch"
              >
                <font-awesome-icon :icon="['fab', 'twitch']" />
              </span>
              Twitch
            </span>
            <span v-else-if="generalUser.type === 'periscope'">
              <span
                uk-icon
                title="Periscope"
              >
                <font-awesome-icon :icon="['fab', 'periscope']" />
              </span>
              Periscope
            </span>
            <span v-else-if="generalUser.type === 'ebay'">
              <span
                uk-icon
                title="Ebay"
              >
                <font-awesome-icon :icon="['fab', 'ebay']" />
              </span>
              Ebay
            </span>
            <span v-else-if="generalUser.type === 'imo'">
              <span
                uk-icon
                title="Imo"
              >
                <img
                  src="/img/icons/imo_icon.svg"
                  style="width: 0.875em;"
                  uk-svg
                />
              </span>
              Imo
            </span>
            <span v-else-if="generalUser.type === 'habr'">
              <span
                uk-icon
                title="Habrahabr"
              >
                <img
                  src="/img/icons/harb.svg"
                  uk-svg
                />
              </span>
              Habrahabr
            </span>
            <span v-else-if="generalUser.type === 'kik'">
              <span
                uk-icon
                title="Kik messenger"
              >
                <img
                  src="/img/icons/kik_icon.svg"
                  style="width: 16px;"
                  uk-svg
                />
              </span>
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'gravatar'">
              <span
                uk-icon
                :title="generalUser.type"
              >
                <img
                  src="/img/icons/gravatar.svg"
                  style="width: 16px;"
                  uk-svg
                />
              </span>
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.type === 'yandexzen'">
              <span uk-icon="icon: database"/>
              Yandex.Zen
            </span>
            <span v-else-if="['avito', 'Авито'].includes(generalUser.type) || (typeof generalUser.externalLink === 'string' && generalUser.externalLink.match(/https:\/\/(www\.)?avito\.ru\//))">
              <span
                uk-icon
                :title="generalUser.type"
              >
                <img
                  src="/img/icons/avito.svg"
                  uk-svg
                />
              </span>
              {{ generalUser.type }}
            </span>
            <span v-else-if="generalUser.genericType === 'leak'">
              <span uk-icon="icon: paint-bucket"/>
              {{ generalUser.type }}
            </span>
            <span v-else>
              <span uk-icon="icon: database"/>
              {{ generalUser.type }}
            </span>
            <span>
              {{ generalUser.date }}
            </span>
          </button>
        </div>
        <div
          v-if="generalUser.genericType !== 'leak'"
          class="uk-width-1-3@m uk-first-column"
        >
          <div
            class="uk-padding-small uk-margin-top uk-text-left"
          >
            <a
              v-if="generalUser.avatar"
              :href="generalUser.externalLink"
              rel="noopener noreferrer"
              target="_blank"
              :class="`${((!generalUser.externalLink) ? 'link-important-muted' : '')}`"
            >
              <GeneralAvatar
                :src="generalUser.avatar"
                :waitRenderingFinished="false"
              />
            </a>
            <router-link
              v-else-if="generalUser.type === 'telegram'"
              :to="generalUser.externalLink"
            >
              <Avatar
                type="user"
                :avatar="generalUser.avatar"
              />
            </router-link>
          </div>
        </div>
        <div
          v-if="generalUser.noInfo"
          class="uk-margin-small-left"
        >
          {{ $t('generalUser.no-info') }}
        </div>
        <div
          :class="!generalUser.avatar && generalUser.genericType === 'leak' ? 'uk-width-1-1 uk-margin-medium-top' : 'uk-width-2-3@m'"
          class="uk-text-break"
        >
          <div class="uk-text-left uk-padding-small uk-margin-small-bottom">
            <a
              v-if="generalUser.fullName && generalUser.externalLink"
              :class="`el-title uk-margin-small-top uk-h3 uk-margin-medium-right uk-margin-remove-bottom uk-text-break ${((!generalUser.externalLink) ? 'link-important-muted' : '')}`"
              rel="noopener noreferrer"
              target="_blank"
              :href="generalUser.externalLink"
            >
              {{ generalUser.fullName }}
            </a>
            <div v-else-if="generalUser.fullName">
              <div
                v-for="fullName in fullNameParse"
                :key="fullName"
                class="el-title uk-margin-small-top uk-h3 uk-margin-medium-right uk-margin-remove-bottom uk-text-break"
              >
                {{ fullName }}
              </div>
            </div>
            <a
              v-else-if="generalUser.name"
              class="el-title uk-margin-small-top uk-h3 uk-margin-remove-bottom"
              :href="generalUser.externalLink"
              rel="noopener noreferrer"
            >
              {{ generalUser.name }}
            </a>
            <a
              v-else-if="generalUser.username || generalUser.nickname"
              :class="`el-title uk-margin-small-top uk-h3 uk-margin-remove-bottom ${((!generalUser.externalLink) ? 'link-important-muted' : '')}`"
              :href="generalUser.externalLink"
              rel="noopener noreferrer"
              :disabled="!generalUser.externalLink"
            >
              {{ generalUser.username || generalUser.nickname }}
            </a>
            <a
              v-else
              :class="`el-title uk-h3 uk-margin-remove-bottom uk-margin-small-top uk-text-small ${((!generalUser.externalLink) ? 'link-important-muted' : '')}`"
              :href="generalUser.externalLink"
              rel="noopener noreferrer"
            />
            <div v-if="Array.isArray(generalUser.names)">
              <span v-if="generalUser.names.length">
                <div
                  v-for="name in generalUser.names"
                  :key="name"
                  class="uk-margin-small-top"
                >
                  <span class>
                    {{ name }}
                  </span>
                </div>
              </span>
            </div>
            <div
              v-if="generalUser.raw && generalUser.raw.login"
              class="uk-text-break uk-text-nowrap uk-margin-small-top"
            >
              {{ $t('generalUser.login') }}: {{ generalUser.raw.login }}
              <router-link
                :to="{ name: 'nicknameResults', params: { nickname: generalUser.raw.login } }"
                class="uk-icon-link uk-text-primary uk-margin-small-left uk-icon"
                uk-icon="search"
              />
            </div>
            <div
              v-if="generalUser.user_id"
              class="uk-margin-small uk-text-small uk-text-truncate"
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                :href="externalLink"
                :class="`uk-text-primary ${((!externalLink) ? 'link-important-muted' : '')}`"
              >
                #{{ generalUser.user_id }}
              </a>
            </div>
            <div v-if="emailsParse.length">
              <div
                v-for="email in emailsParse"
                :key="email"
                class="uk-margin-small-top"
              >
                <span class>
                  {{ email }}
                </span>
                <router-link
                  :to="{ name: 'emailResults', params: { email }}"
                  class="uk-icon-link uk-text-primary uk-margin-small-left uk-icon"
                  uk-icon="search"
                />
              </div>
            </div>
            <div v-if="generalUser.password && passwordParse.length">
              {{ $t('generalUser.password') }}:
              <div
                v-for="(password, i) in passwordParse"
                :key="password"
                :class="{ 'uk-display-inline-block': !i }"
                class="uk-margin-small-top"
              >
                <span>
                  {{ password }}
                </span>
              </div>
            </div>
            <div
              v-if="generalUser.passwordHash && passwordHashParse.length"
              class="uk-text-break"
            >
              {{ $t('generalUser.password-hash') }}:
              <div
                v-for="(passwordHash) in passwordHashParse"
                :key="passwordHash"
                class="uk-margin-small-top"
              >
                <span>
                  {{ passwordHash }}
                </span>
              </div>
            </div>
            <div
              v-if="generalUser.sex"
              class="uk-text-break uk-margin-small-top"
            >
              {{ $t('generalUser.sex') }}: {{ generalUser.sex }}
            </div>
            <div
              v-if="generalUser.age"
              class="uk-text-break uk-margin-small-top"
            >
              {{ $t('generalUser.age') }}: {{ generalUser.age }}
            </div>
            <div
              v-if="generalUser.birthday"
              class="uk-text-break uk-margin-small-top"
            >
              {{ $t('generalUser.birthday') }}: {{ generalUser.birthday }}
            </div>
            <div v-if="generalUser.ip && ipParse.length">
              IP:
              <div
                v-for="(ip, i) in ipParse"
                :key="ip"
                :class="{ 'uk-display-inline-block': !i }"
                class="uk-margin-small-top"
              >
                <span>
                  {{ ip }}
                </span>
                <router-link
                  :to="{ name: 'ipResults', params: { ip } }"
                  class="uk-icon-link uk-text-primary uk-margin-small-left uk-icon"
                  uk-icon="search"
                />
              </div>
            </div>
            <div
              v-if="generalUser.name"
              class="uk-margin-small-top"
            >
              <span class>
                {{ $t('generalUser.nickname') }}: {{ generalUser.name }}
              </span>
              <router-link
                :to="{ name: 'nicknameResults', params: { nickname: generalUser.name } }"
                class="uk-icon-link uk-text-primary uk-margin-small-left uk-icon"
                uk-icon="search"
              />
            </div>
            <div v-if="generalUser.phone && phonesParse.length">
              {{ $t('generalUser.phone') }}:
              <div
                v-for="(phone, i) in phonesParse"
                :key="phone"
                :class="{ 'uk-display-inline-block': !i }"
                class="uk-margin-small-top"
              >
                <span>
                  {{ phone }}
                </span>
                <router-link
                  :to="{ name: 'phoneResults', params: { phone } }"
                  class="uk-icon-link uk-text-primary uk-margin-small-left uk-icon"
                  uk-icon="search"
                />
              </div>
            </div>

            <div
              v-if="generalUser.phones"
              class="uk-margin-small-top"
            />
            <div v-else-if="generalUser.type === 'telegram' && (!generalUser.subtype || generalUser.subtype !== 'channel') && !generalUser.phone">
              <UserPhone
                :id="String(generalUser.user_id)"
                type="usercard"
              />
            </div>

            <div class="uk-text-break uk-margin-small-top uk-margin-remove-bottom">
              <span
                v-if="safeBio"
                class="uk-text-small"
              >
                {{ $t('generalUser.bio') }}:
                <span v-html="shortedBio"/>
              </span>
            </div>
            <Translate
              v-if="safeBio"
              class="uk-margin-small-right"
              :text="safeBio"
              :translatedText="translatedBioText"
              :translatedShown="translatedBioShown"
              @translatedText="translatedBioText = $event"
              @translatedShown="translatedBioShown = $event"
            />
            <button
              v-if="safeBio.length > limit"
              class="uk-button uk-button-text"
              @click="showFullBio"
            >
              {{ short ? 'more' : 'less' }}
            </button>
          </div>
        </div>
      </div>
      <div class="uk-width uk-flex uk-flex-between control-panel">
        <div>
          <button
            type="button"
            class="uk-icon uk-text-primary"
          >
            <router-link
              v-if="generalUser.type === 'telegram'"
              :to="{ name: 'tgcp_user_detail_graph', params: { userId: String(generalUser.user_id) } }"
              uk-icon="icon: social"
            />
            <span
              v-else
              class="uk-disabled uk-text-muted"
              uk-icon="icon: social"
            />
          </button>
          <button
            type="button"
            class="uk-icon uk-margin-small-left uk-text-primary"
          >
            <span
              v-if="generalUser.hasLocation"
              uk-icon="icon: location"
            />
            <span
              v-else
              class="uk-disabled uk-text-muted"
              uk-icon="icon: location"
            />
          </button>
          <AddStoredButton
            v-if="showStoredButton"
            :provider="safeStoredProvider"
            :type="safeStoredType"
            :data="this.generalUser"
          />
          <CardToProfileButton
            v-if="showAddProfileButton"
            :card="generalUser"
            :profile-name-by-default="generalUser.getId()"
          />
          <router-link
            v-if="generalUser.user_id"
            :to="{
              name: 'profilerCardDetails',
              params: {
                provider: safeStoredProvider,
                id: generalUser.user_id
              }
            }"
            uk-icon="arrow-right"
            :uk-tooltip="$t('cases.details')"
          />
        </div>
        <div class="uk-flex uk-flex-between">
          <div
            v-if="generalUser.archived"
            uk-icon="icon: database"
            :uk-tooltip="$t('cases.archived-item')"
            class="uk-text-primary"
            style="align-self: center; margin-right: 0.3em"
          />
          <openSidebarBtn
            :raw="generalUser"
            @opendetails="$emit('opendetails')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import extractData from '@/utils/dataExtraction'
import { strToArray } from '@/utils/tryParse'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Usercard2',
  components: {
    openSidebarBtn: defineAsyncComponent(() => import(/* webpackChunkName: "openSidebarBtn" */ '@/components/openSidebarBtn')),
    Avatar: defineAsyncComponent(() => import('@/components/TGCP/Avatar')),
    threemaIcon: defineAsyncComponent(() => import('@/components/icons/threema-icon')),
    zaloIcon: defineAsyncComponent(() => import('@/components/icons/zalo-icon')),
    UserPhone: defineAsyncComponent(() => import('@/components/TGCP/UserDetails/UserPhone')),
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton')),
    Translate: defineAsyncComponent(() => import('@/components/Translate')),
    CardToProfileButton: defineAsyncComponent(() => import('@/components/CardToProfileButton')),
    GeneralAvatar: defineAsyncComponent(() => import('@/components/GeneralAvatar'))
  },
  props: {
    generalUser: {
      type: Object,
      required: true
    },
    fromStored: {
      type: Boolean,
      required: false,
      default: false
    },
    selectMode: {
      type: String
    },
    showAddProfileButton: {
      type: Boolean,
      default: false
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      short: true,
      limit: 240,
      translatedBioShown: false,
      translatedBioText: '',
      isSelected: false
    }
  },
  watch: {
    isSelected (checked) {
      this.$emit('selected', {
        user: this.generalUser,
        checked: checked
      })
    },
    selectMode (value) {
      if (value === 'normal' && this.isSelected) {
        this.isSelected = false
      }
    }
  },
  computed: {
    externalLink () {
      const isHoop = this?.generalUser?.type === 'hoop'
      const isDiscord = this?.generalUser?.type === 'discord'
      if (
        (isHoop || isDiscord) &&
        typeof this?.$router?.resolve === 'function' &&
        this?.generalUser?.['user_id']
      ) {
        const href = this.$router.resolve({
          name: isHoop ? 'hoop_user_details' : 'discord_user_details',
          params: {
            id: this.generalUser['user_id']
          }
        })?.href
        if (href) {
          return href
        }
      }

      return this?.generalUser?.externalLink
    },
    safeBio () {
      if (typeof this.generalUser.bio === 'string') {
        return this.generalUser.bio
      } else {
        return ''
      }
    },
    bio () {
      return this.translatedBioShown ? this.translatedBioText : this.safeBio || ''
    },
    emailsParse () {
      const { email, emails } = this.generalUser
      let data = [email]
      if (Array.isArray(emails)) {
        data = [ email, ...emails ]
      }
      return [ ...new Set(strToArray(data)) ]
    },
    phonesParse () {
      return strToArray(this.generalUser.phone)
    },
    fullNameParse () {
      return strToArray(this.generalUser.fullName)
    },
    passwordParse () {
      return strToArray(this.generalUser.password)
    },
    ipParse () {
      return strToArray(this.generalUser.ip)
    },
    passwordHashParse () {
      return strToArray(this.generalUser.passwordHash)
    },
    cardType () {
      return this.generalUser.genericType
    },
    extractedDataBio () {
      if (!this.generalUser) return
      let lt = /</g
      let gt = />/g
      let ap = /'/g
      let ic = /"/g
      let bio = this.bio
        .toString()
        .replace(lt, '&lt;')
        .replace(gt, '&gt;')
        .replace(ap, '&#39;')
        .replace(ic, '&#34;')
      return extractData(bio)
    },
    shortedBio () {
      const text = this.extractedDataBio
      if (text && text.length > this.limit && this.short) {
        return text.substring(0, this.limit) + '...'
      }
      return text
    },
    safeStoredData () {
      switch (this.generalUser.type) {
          case 'zalo':
            return this.generalUser
          default:
            return this.generalUser.raw
      }
    },
    safeStoredType () {
      switch (this.generalUser.type) {
          case 'zalo':
            return 'account'
          default:
            return this.generalUser.raw.broadcast ? 'chat' : 'account'
      }
    },
    safeStoredProvider () {
      switch (this.generalUser.genericType) {
          case 'forums':
            return 'forums'
          default:
            return this.generalUser.type
      }
    }
  },
  methods: {
    showFullBio () {
      this.short = !this.short
    }
  }
}
</script>

<style lang='scss' scoped>
.control-panel {
  padding: 0 15px 5px 15px;
}
.router-link-exact-active {
  color: gray !important;
  cursor: not-allowed;
}
.social {
  border: 1px solid blue;
}
.messenger {
  border: 1px solid green;
}
.ads {
  border: 1px solid yellow;
}
.msidn {
  border: 1px solid red;
}
.el-title {
  display: block;
}
.link-important-muted {
  color: #999!important;
  pointer-events: none;
}
button.type-icon {
  span.uk-icon {
    vertical-align: middle;
    svg {
      height: 1em;
    }
  }
}
.uk-box-shadow-bottom {
  z-index: 2;
}
.uk-box-shadow-bottom > .uk-card {
  z-index: 2;
}
.uk-box-shadow-bottom:before {
  z-index: 1;
  -webkit-transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  -webkit-animation-name: shadow-move;
  -webkit-animation-duration: 0.1s;
  animation-name: shadow-move;
  animation-duration: 0.1s;
}
@keyframes shadow-move {
  from {
    -webkit-transform: translate(0px, -30px);
    -moz-transform: translate(0px, -30px);
    -o-transform: translate(0px, -30px);
    -ms-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }
  to {
    -webkit-transform: translate(0px, 0);
    -moz-transform: translate(0px, 0);
    -o-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    transform: translate(0px, 0);
  }
}

@-webkit-keyframes shadow-move  {
  from {
    -webkit-transform: translate(0px, -30px);
    -moz-transform: translate(0px, -30px);
    -o-transform: translate(0px, -30px);
    -ms-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }
  to {
    -webkit-transform: translate(0px, 0);
    -moz-transform: translate(0px, 0);
    -o-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    transform: translate(0px, 0);
  }
}

</style>
