<template>
  <div class="home uk-container">
    <div v-if="!isLoaded">
      <Loader />
    </div>
    <div
      v-else-if="hasDataToView"
      class="uk-margin"
    >
      <ChannelCard
        v-for="channel in channels"
        :key="channel.chat_id"
        :channel="channel"
        class="uk-margin-top"
      />
    </div>
    <NotFound v-if="isLoaded && !hasDataToView" />
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center">
        <li :class="isPrevPage">
          <a
            class="uk-position-center"
            @click="prevPage"
          >
            <span uk-pagination-previous />
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <input
            v-model="goToPage"
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
            @change="setPage"
            @focus="$event.target.select()"
          />
        </li>
        <li :class="state.pagination.isNext ? 'uk-active' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="nextPage"
          >
            <span uk-pagination-next/>
          </a>
        </li>
      </ul>
    </div>
    <ExportDialog
      v-if="isLoaded && channels.length"
      :id="id"
      :from="`${participantStatus}_${participantType === 'participant' ? 'user' : participantType}_channels_${id}`"
      namespace="tgcp"
      type="channels"
      subtype="user"
      :participantType="participantType"
      :participantStatus="participantStatus"
      :query="state.query"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import ChannelCard from '@/components/TGCP/ChannelList/ChannelCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TgcpChannelListMini',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    ChannelCard
  },
  props: {
    id: {
      reqired: true,
      type: String
    },
    participantType: {
      type: String,
      default: 'participant'
    },
    participantStatus: {
      type: String,
      default: 'current'
    }
  },
  data () {
    return {
      channels: [],
      state: {
        query: {
          search: '',
          limit: 51,
          offset: 0,
          user_id: this.id
        },
        pagination: {
          page: 1,
          isNext: false
        }
      },
      goToPage: 1
    }
  },
  watch: {
    goToPage (newVal, oldVal) {
      if (!/^[0-9]*$/.test(newVal) || !+newVal) {
        this.goToPage = oldVal
      }
    },
    currentPage (newVal) {
      this.goToPage = newVal
    }
  },
  computed: {
    ...mapGetters('tgcp', [
      'isPrevPage',
      'allChannels',
      'allListChannels',
      'allListPastChannels'
    ]),
    currentPage () {
      return this.state.pagination.page
    },
    isLoaded () {
      const key = 'channelsbyuserid' + this.participantStatus

      return this.$store.state.tgcp.isLoaded[key]
    },
    hasDataToView () {
      return this.channels.length > 0
    },
    isPrevPage () {
      return this.state.pagination.page === 1 ? 'uk-disabled' : 'uk-active'
    },
    hidePagination () {
      if (this.state.pagination.isNext === false && this.state.pagination.page === 1) return true
      else return false
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'clearChannelsToView',
      'fetchChannelsByUserId',
      'clearChannels',
      'clearPastChannelsToView'
    ]),
    async update () {
      const chats = await this.fetchChannelsByUserId({
        id: this.id,
        query: { ...this.state.query, limit: this.state.query.limit},
        participantType: this.participantType,
        participantStatus: this.participantStatus
      })
      if (chats.length === this.state.query.limit) {
        this.state.pagination.isNext = true
        chats.pop()
      } else {
        this.state.pagination.isNext = false
      }
      this.channels = chats
    },
    onSubmit () {
      this.state.query['search'] = this.search
      this.clearChannelsToView() &&
        this.update()
    },
    async setPage ({ target }) {
      for (let i = 10; i > 0; i--) {
        this.state.query.offset = this.state.query.limit * (--target.value)
        await this.update()
        if (this.channels.length) {
          this.goToPage = +target.value + 1
          this.state.pagination.page = +target.value
          return
        }
      }
      this.state.query.offset = this.state.query.limit * (this.state.pagination.page - 1)
      await this.update()
      this.goToPage = this.state.pagination.page
      this.$notify({ group: 'general', title: `Page not found`, type: 'error' })
    },
    nextPage () {
      const page = this.state.pagination.page
      const offset = (this.state.query.limit - 1) * page
      if (this.allListChannels[page + 1] === undefined) {
        this.state.query['offset'] = offset
        if (this.state.query.offset < 0) this.state.query.offset = 0
        this.update()
        this.state.pagination.page = page + 1
      } else {
        this.state.pagination.page = page + 1
      }
    },
    prevPage () {
      const limit = (this.state.query.limit - 1)
      const page = this.state.pagination.page
      this.state.query['offset'] = this.state.query.offset - limit
      this.state.pagination.page = page - 1
      this.update()
    }
  },
  mounted () {
    this.clearPastChannelsToView() &&
      this.clearChannelsToView() &&
      this.clearChannels() &&
      this.update()
  },
  beforeUnmount () {
    this.clearPastChannelsToView() &&
      this.clearChannelsToView() &&
      this.clearChannels()
  }
}
</script>
