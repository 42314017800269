<template>
  <div>
    <div v-if="sticker">
      <img
        v-if="stickerCdnPath"
        class="sticker"
        :src="gateway(stickerCdnPath)"
        alt="sticker"
      />
      <div>
        <a
          :href="gateway(stickerCdnPath)"
          download="sticker"
        >
          <span uk-icon="cloud-download"/>  {{ downloadHandle(attachment) }}
        </a>
      </div>
    </div>
    <div
      v-if="attachment && attachmentCdnPath"
      class="uk-text-center"
    >
      <div>
        {{ title }}
      </div>
      <div class="uk-flex uk-flex-center">
        <Gallery
          v-if="isImage(attachment) && !imageError"
          :images="[gateway(attachmentCdnPath)]"
        />
      </div>
      <audio
        v-if="isAudio(attachment) && !audioError"
        v-audio-stop
        preload="none"
        controls="controls"
        @error="audioError = true"
      >
        <source
          :src="gateway(attachmentCdnPath)"
          :type="mime"
        />
      </audio>
      <video
        v-if="isVideo(attachment) && !videoError"
        v-video-stop
        preload="none"
        controls="controls"
        @error="videoError = true"
      >
        <source
          :src="gateway(attachmentCdnPath)"
          :type="mime"
        />
      </video>
      <div>
        <a
          :href="gateway(attachmentCdnPath)"
          :download="attachmentFilename"
        >
          <span uk-icon="cloud-download"/> {{ downloadHandle(attachment) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { size as humanFileSize } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Media',
  components: {
    Gallery: defineAsyncComponent(() => import('@/components/Gallery'))
  },
  props: {
    message: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      audioError: false,
      videoError: false,
      imageError: false
    }
  },
  computed: {
    sticker () {
      return this.message?.sticker
    },
    stickerCdnPath () {
      // eslint-disable-next-line camelcase
      let cdnPath = this.sticker?.cdn_path
      // eslint-disable-next-line no-unused-expressions
      cdnPath ??= ''
      return cdnPath
    },
    attachmentFilename () {
      // eslint-disable-next-line camelcase
      let attachmentFilename = this.message?.attachment_filename
      // eslint-disable-next-line no-unused-expressions
      attachmentFilename ??= ''
      return attachmentFilename
    },
    attachment () {
      const {
        message: {
          attachment
        }
      } = this
      return attachment
    },
    attachmentCdnPath () {
      // eslint-disable-next-line camelcase
      let cdnPath = this.attachment?.cdn_path
      // eslint-disable-next-line no-unused-expressions
      cdnPath ??= ''
      return cdnPath
    },
    mime () {
      let mime = this.attachment?.mime
      // eslint-disable-next-line no-unused-expressions
      mime ??= ''
      return mime
    },
    title () {
      let title = this.attachment?.title
      // eslint-disable-next-line no-unused-expressions
      title ??= ''
      return title
    },
    download () {
      let title = this.attachment?.title
      let seconds = this.attachment?.seconds
      // eslint-disable-next-line no-unused-expressions
      title ??= ''
      // eslint-disable-next-line no-unused-expressions
      seconds ??= ''
      // eslint-disable-next-line no-unused-expressions
      seconds &&= ` (${seconds})`
      return `${title}${seconds}`
    }
  },
  methods: {
    humanFileSize,
    gateway (value) {
      return this.$gateway(value)
    },
    downloadHandle (attachment, noName = false) {
      let str = ''
      if (attachment?.filename && !noName) str += attachment.filename
      else str += this.$i18n.t('general.download')
      if (attachment?.filesize) {
        str += ' (' + this.humanFileSize(attachment.filesize) + ')'
      }
      return str
    },
    isAudio (attachment) {
      const {
        mime
      } = attachment
      return mime && mime.includes('audio/')
    },
    isVideo (attachment) {
      const {
        mime
      } = attachment
      return mime && mime.includes('video/')
    },
    isImage (attachment) {
      const {
        mime
      } = attachment
      return mime && attachment.mime.includes('image/')
    }
  }
}
</script>
<style scoped>
.sticker {
  max-width: 256px;
  max-height: 256px
}
</style>
