<template>
  <div>
    <h3>
      {{ $t('twitter.tasks.tasks-list') }}
    </h3>
    <div>
      <form
        class="uk-form-horizontal uk-form"
        @submit.prevent="setPage(1)"
      >
        <input
          v-model="query.search"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
          :placeholder="$t('twitter.tasks.search')"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          type="submit"
        >
          <span uk-search-icon/>
        </button>
      </form>
    </div>
    <div v-if="loading && initialLoad">
      <Loader />
    </div>
    <table
      v-else-if="tasks.length"
      class="uk-table uk-table-striped uk-table-responsive uk-text-break"
    >
      <thead>
        <tr>
          <th class="uk-text-center uk-width-1-5">
            <button
              class="uk-button uk-button-text uk-text-nowrap"
              @click="setOrder('task')"
            >
              {{ $t('twitter.tasks.task') }}
              {{ query.order_by === 'task' ? selectedOrder : '' }}
            </button>
          </th>
          <th class="uk-text-center uk-width-1-5">
            {{ $t('twitter.tasks.description') }}
          </th>
          <th class="uk-text-center uk-width-1-5">
            <button
              class="uk-button uk-button-text uk-text-nowrap"
              @click="setOrder('sync_date')"
            >
              {{ $t('twitter.tasks.sync-date') }}
              {{ query.order_by === 'sync_date' ? selectedOrder : '' }}
            </button>
          </th>
          <th class="uk-text-center uk-width-1-5">
            <button
              class="uk-button uk-button-text uk-text-nowrap"
              @click="setOrder('created')"
            >
              {{ $t('twitter.tasks.created') }}
              {{ query.order_by === 'created' ? selectedOrder : '' }}
            </button>
          </th>
          <th class="uk-text-center uk-width-1-5">
            {{ $t('twitter.tasks.actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ListItem
          v-for="task in tasks"
          :key="task.id"
          :task="task"
          @delete="taskDelete"
        />
      </tbody>
    </table>
    <div
      v-else
      class="uk-margin"
    >
      <h4>
        {{ $t('twitter.tasks.no-tasks-added-yet') }}
      </h4>
    </div>
    <Paginate
      v-if="!hidePagination"
      v-model="pagination.page"
      :page-count="lastPage"
      :click-handler="setPage"
      prev-text="<"
      next-text=">"
      container-class="uk-pagination uk-flex-center"
    />
  </div>
</template>

<script>
import ListItem from '@/components/Tiktok/Tasks/ListItem.vue'
import Paginate from '@/components/Paginate'
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'

export default {
  name: 'TiktokTasksList',
  components: {
    ListItem,
    Paginate,
    Loader
  },
  data () {
    return {
      tasks: [],
      initialLoad: true,
      loading: false,
      timer: null,
      query: {
        offset: this.$route.query.offset || 0,
        limit: this.$route.query.limit || 20,
        order_by: this.$route.query.order_by || 'created',
        order_type: this.$route.query.order_type || 'DESC',
        search: this.$route.query.search
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  computed: {
    hidePagination () {
      return this.lastPage === 1
    },
    lastPage () {
      const lastPage = Math.floor((this.pagination.total) / this.query.limit)
      return this.pagination.total / lastPage - (this.query.limit) === 0 ? lastPage : lastPage + 1
    },
    selectedOrder () {
      return this.query.order_type === 'ASC' ? '↑' : '↓'
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'fetchAllTasks',
      'deleteTask'
    ]),
    setOrder (by) {
      if (this.query.order_by === by) {
        this.query.order_type = this.query.order_type === 'DESC' ? 'ASC' : 'DESC'
      } else {
        this.query.order_type = 'DESC'
      }
      this.query.order_by = by
      this.update()
    },
    taskDelete (id) {
      this.deleteTask(id)
        .then(() => {
          this.update()
        })
    },
    setPage (page = 1) {
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setData (res) {
      this.tasks = res.data
      this.pagination.total = res.meta.results_count
    },
    clearData () {
      this.tasks = []
      this.pagination.page = 1
      this.pagination.total = null
    },
    async update () {
      let caller = this
      caller.loading = true
      try {
        caller.$router.push({ query: caller.query })
        caller.setData(await caller.fetchAllTasks(caller.query))
        caller.initialLoad = false
      } catch (err) {
        caller.clearData()
        caller.initialLoad = true
      } finally {
        caller.loading = false
        caller.timer = setTimeout(caller.update, 20000)
      }
      if (caller.pagination.page > 1 && !caller.tasks.length) caller.setPage(caller.pagination.page - 1)
    }
  },
  mounted () {
    const page = this.$route.query.offset
      ? Math.floor(this.$route.query.offset / (this.query.limit - 1) + 1)
      : 1
    this.setPage(page)
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>
