<template>
  <ul 
    class="uk-pagination uk-flex-center uk-margin-small-bottom uk-margin-small-top" 
    :class="containerClass"
  >
    <li :class="firstPageSelected ? 'uk-disabled' : 'uk-enabled'">
      <a
        @click="prevPage()"
      >
        <span uk-pagination-previous/>
      </a>
    </li>
    <li
      v-for="page in pages"
      :key="page.index"
      :class="{ 'uk-active uk-text-bold': page.selected }"
    >
      <a
        v-if="!page.disabled"
        @click="handlePageSelected(page.content)"
      >
        {{ page.content }}
      </a>
      <span v-else>
        …
      </span>
    </li>
    <li
      style="padding-left: 20px;"
      :class="lastPageSelected ? 'uk-disabled' : 'uk-enabled'"
    >
      <a
        @click="nextPage()"
      >
        <span uk-pagination-next/>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  compatConfig: {
    MODE: 3
  },
  name: 'Paginate',
  props: {
    modelValue: {
      type: Number
    },
    pageCount: {
      type: Number
    },
    prevText: {
      type: String,
      default: '<'
    },
    nextText: {
      type: String,
      default: '>'
    },
    clickHandler: {
      type: Function,
      default: () => {}
    },
    containerClass: {
      type: String,
      required: false
    },
    pageRange: {
      type: Number,
      required: false,
      default: 3
    }
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.handlePageSelected(value)
      }
    },
    pages () {
      let items = {}
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          let page = {
            index,
            content: index + 1,
            selected: index === this.selected - 1
          }
          items[index] = page
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2)
        let setPageItem = (index) => {
          let page = {
            index,
            content: index + 1,
            selected: index === this.selected - 1
          }
          items[index] = page
        }
        let setBreakView = (index) => {
          let breakView = {
            disabled: true,
            breakView: true
          }
          items[index] = breakView
        }
        setPageItem(0)
        let selectedRangeLow = 0
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange
        }
        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1
        }
        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
          setPageItem(i)
        }
        if (selectedRangeLow > 1) {
          setBreakView(selectedRangeLow - 1)
        }
        if (selectedRangeHigh + 1 < this.pageCount - 1) {
          setBreakView(selectedRangeHigh + 1)
        }
        for (let i = this.pageCount - 1; i >= this.pageCount - 1; i--) {
          setPageItem(i)
        }
      }
      return items
    },
    firstPageSelected () {
      return this.selected === 1
    },
    lastPageSelected () {
      return this.selected === this.pageCount || this.pageCount === 0
    }
  },
  methods: {
    handlePageSelected (selected) {
      if (this.selected === selected)
        return
      this.$emit('update:modelValue', selected)
      this.clickHandler(selected)
    },
    prevPage () {
      if (this.selected <= 1)
        return
      this.handlePageSelected(this.selected - 1)
    },
    nextPage () {
      if (this.selected >= this.pageCount)
        return
      this.handlePageSelected(this.selected + 1)
    },
    selectFirstPage () {
      if (this.selected <= 1)
        return
      this.handlePageSelected(1)
    },
    selectLastPage () {
      if (this.selected >= this.pageCount)
        return
      this.handlePageSelected(this.pageCount)
    }
  }
}
</script>

