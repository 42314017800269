<template>
  <div>
    <div
      class="uk-padding-small uk-position-relative uk-light"
    >
      <GeneralAvatar
        :src="url"
        :wait-rendering-finished="!url"
      />
    </div>
  </div>
</template>

<script>
import GeneralAvatar from '@/components/GeneralAvatar'
import gateways from '@/config/gateways'

export default {
  components: { GeneralAvatar },
  props: {
    avatar: {
      required: true
    }
  },
  computed: {
    url () {
      if (!this.avatar) return null
      else if (!this.avatar?.avatar_cdn_path) return this.avatar
      return this.gateway() + this.avatar.avatar_cdn_path
    }
  },
  methods: {
    gateway () {
      return gateways.default.substring(0, gateways.default.length - 1)
    }
  }
}
</script>
