<template>
  <div class="" >
    <div v-if="isLoaded['group_' + id] === false">
      <Loader />
    </div>
    <div v-else-if="isLoaded['group_' + id] && !group">
      <NotFound />
    </div>
    <div
      v-else-if="group"
      class="uk-grid uk-grid-small"
      data-uk-grid-margin
    >
      <div
        v-if="localGroup"
        class="uk-card uk-margin-left uk-card-default uk-width-3-4@m general_info_wrapper"
      >
        <div
          class="uk-text-left uk-card-header border-bottom-0"
          style="margin-left: -15px;!important"
          uk-grid
        >
          <div
            class="uk-width-expand uk-card-title"
          >
            <h3
              class="uk-margin-top uk-margin-small-left"
            >
              {{ $t('whatsapp.group-details.group-info') }}
            </h3>
          </div>
          <div class="uk-float-right uk-padding-small">
            <button
              v-if="hasJoiningButton"
              class="uk-button uk-button-default uk-margin-right"
              @click="join"
            >
              <span
                v-if="!joining"
              >
                {{ $t('whatsapp.group-details.join') }}
              </span>
              <Loader
                v-else
                :ratio="0.7"
              />
            </button>
            <AddStoredButton
              provider="whatsapp"
              type="chat"
              :data="group"
            />
          </div>
        </div>
        <ul
          uk-tab
          class="uk-margin-remove-top uk-margin-small-left uk-margin-medium-right"
        >
          <li class="uk-active">
            <a href="#details">
              {{ $t('twitter.group.group-details') }}
            </a>
          </li>
          <li v-if="hasKnownLinks">
            <a href="#known-links">
              {{ $t('tgcp.channel_details.known-links') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher uk-margin-medium-right">
          <li v-if="group">
            <div
              class="uk-card-body uk-text-left"
              uk-grid
            >
              <div class="uk-width-1-4@m">
                <AvatarList
                  v-if="avatarCdnWithoutNull(group).avatars.length"
                  :data="avatarCdnWithoutNull(group)"
                />
                <Avatar
                  v-else
                  :avatar="group.avatar_cdn_path"
                />
              </div>
              <div class="uk-width-expand uk-margin-bottom">
                <h3
                  v-if="localGroup.subject"
                >
                  {{ localGroup.subject }}
                  <button
                    class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
                    @click="translateSubject"
                  >
                    <font-awesome-icon :icon="['fa', 'language']" />
                    <span v-if="translated['subject']">
                      {{ $t('whatsapp.group-details.show-original') }}
                    </span>
                    <span v-else>
                      Translate
                    </span>
                  </button>
                  <p class="uk-text-small uk-text-left  uk-margin-remove-top uk-margin-remove-bottom uk-text-muted">
                    {{ group.id }}
                  </p>
                </h3>
                <p
                  v-if="extractedDescription"
                  class="uk-margin"
                  style="white-space: pre-line;"
                >
                  <span v-html="extractedDescription"/>
                  <button
                    class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
                    @click="translateDescription"
                  >
                    <font-awesome-icon :icon="['fa', 'language']" />
                    <span v-if="translated['desc']"> {{ $t('whatsapp.group-details.show-original') }} </span>
                    <span v-else> {{ $t('whatsapp.group-details.translate') }} </span>
                  </button>
                </p>
                <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-top">
                  <li v-if="group.owner_phone">
                    {{ $t('whatsapp.group-details.owner-phone') }}
                    <router-link
                      :to="{
                        name: 'whatsapp_user_detail',
                        params: { phone: String(normalizePhone(group.owner_phone)) }}"
                    >
                      {{ normalizeWAPhone(group.owner_phone) }}
                    </router-link>
                  </li>
                  <li v-if="group.creation">
                    {{ $t('whatsapp.group-details.creation-date') }}
                    {{ dayjs(group.creation) }}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li v-if="hasKnownLinks">
            <div class="uk-margin-medium-left uk-margin-medium-bottom">
              <KnownLinks :links="knownLinks" />
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="localGroup"
        class="uk-width-expand"
      >
        <div class="uk-card uk-card-default uk-card-body uk-padding-small">
          <h5
            v-if="!group.joined && ( localGroup.members_count || localGroup.messages_count)"
            class="uk-text-left uk-text-muted"
          >
            {{ $t('whatsapp.group-details.outdated-data') }}
          </h5>
          <ul
            v-if="localGroup.members_count"
            class="uk-list uk-list-divider uk-text-left uk-width-expand"
          >
            <li>
              <a
                class="stat-link"
                style="cursor:pointer"
                @click.prevent="activateTab('members_tab')"
              >
                {{ $t('whatsapp.group-details.members-count') }}:
                <span class="uk-float-right">
                  {{ localGroup.members_count || '0' }}
                </span>
              </a>
            </li>
            <li>
              <a
                class="stat-link"
                style="cursor:pointer"
                @click.prevent="activateTab('messages_tab')"
              >
                {{ $t('whatsapp.group-details.messages-count') }}
                <span class="uk-float-right">
                  {{ localGroup.messages_count || '0' }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      v-if="group"
      class="uk-margin-medium-top uk-width-1-1 uk-padding-remove"
    >
      <ul
        id="tabs"
        uk-tab
      >
        <li
          class="uk-flex"
        >
          <a
            id="overview_tab"
            class="uk-padding-remove"
            href="#overview_tab"
            @click="activateTab('overview_tab')"
          >
            {{ $t('whatsapp.group-details.overview') }}
          </a>
        </li>
        <li
          class="uk-flex"
          :class="checkForTab('members')"
        >
          <a
            id="users_tab"
            class="uk-padding-remove"
            href="#users_tab"
            @click="activateTab('members_tab')"
          >
            {{ $t('whatsapp.group-details.users') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ group.members_count || 0 }}
            </span>
          </a>
        </li>
        <li
          v-if="formerMembersCount"
          class="uk-flex"
          :class="checkForTab('former_members')"
        >
          <a
            id="former_members_tab"
            class="uk-padding-remove"
            href="#former_members_tab"
            @click="activateTab('former_members_tab')"
          >
            {{ $t('whatsapp.group-details.former-members-count') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ formerMembersCount }}
            </span>
          </a>
        </li>
        <li
          class="uk-flex"
          :class="checkForTab('admins')"
        >
          <a
            id="admins_tab"
            class="uk-padding-remove"
            href="#admins_tab"
            @click="activateTab('admins_tab')"
          >
            {{ $t('whatsapp.group-details.admins') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ group.admins_count || 0 }}
            </span>
          </a>
        </li>
        <li
          v-if="formerAdminsCount"
          class="uk-flex"
          :class="checkForTab('former_admins')"
        >
          <a
            id="former_admins_tab"
            class="uk-padding-remove"
            href="#former_admins_tab"
            @click="activateTab('former_admins_tab')"
          >
            {{ $t('whatsapp.group-details.former-admins-count') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ formerAdminsCount }}
            </span>
          </a>
        </li>
        <li
          class="uk-flex"
          :class="checkForTab('superadmins')"
        >
          <a
            id="superadmins_tab"
            class="uk-padding-remove"
            href="#superadmins_tab"
            @click="activateTab('superadmins_tab')"
          >
            {{ $t('whatsapp.group-details.superadmins') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ group.superadmins_count || 0 }}
            </span>
          </a>
        </li>
        <li
          v-if="formerSuperadminsCount"
          class="uk-flex"
          :class="checkForTab('former_superadmins')"
        >
          <a
            id="former_superadmins_tab"
            class="uk-padding-remove"
            href="#former_superadmins_tab"
            @click="activateTab('former_superadmins_tab')"
          >
            {{ $t('whatsapp.group-details.former-superadmins-count') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ formerSuperadminsCount }}
            </span>
          </a>
        </li>
        <li
          class="uk-flex"
          :class="checkForTab('messages')"
        >
          <a
            id="messages_tab"
            class="uk-padding-remove"
            href="#messages_tab"
            @click="activateTab('messages')"
          >
            {{ $t('whatsapp.group-details.messages') }}
            <span class="uk-badge uk-margin-small-left ">
              {{ group.messages_count || 0 }}
            </span>
          </a>
        </li>
        <li class="uk-flex">
          <a
            id="entities_tab"
            href="#entities_tab"
            class="uk-padding-remove"
            @click="activateTab('entities_tab')"
          >
            {{ $t('whatsapp.group-details.entities') }}
            <span class="uk-badge uk-margin-small-left ">
              <Loader v-if="!isLoadedEntityStats && entity" />
              <span v-else>
                <span v-if="entity && (entity.task || entity.total.count)">
                  {{ entity.total.count }}
                </span><span v-else>
                  -
                </span>
              </span>
            </span>
          </a>
        </li>
        <li
          v-if="ownerPhone"
          class="uk-flex"
        >
          <a
            id="owner_tab"
            class="uk-padding-remove"
            href="#owner_tab"
            @click="activateTab('owner_tab')"
          >
            {{ $t('whatsapp.group-details.owner') }}
            <span class="uk-badge uk-margin-small-left ">
              1
            </span>
          </a>
        </li>
      </ul>
      <ul class="uk-switcher">
        <li>
          <a href="overview_tab"/>
          <BarGraph
            :id="id"
          />
          <LineGraph
            :id="id"
            type="day"
          />
          <LineGraph
            :id="id"
            type="week"
          />
          <LineGraph
            :id="id"
            type="year"
          />
          <AuditoryCountry :id="id" />
        </li>
        <li
          v-if="group.members_count"
        >
          <a href="members"/>
          <UserList
            v-if="activeTab === 'members_tab'"
            :groupId="id"
            type="members"
          />
        </li>
        <li v-if="formerMembersCount">
          <a href="#former_members_tab"/>
          <UserList
            :groupId="id"
            type="members/former"
          />
        </li>
        <li
          v-if="group.admins_count"
        >
          <a href="admins_tab"/>
          <UserList
            v-if="activeTab === 'admins_tab'"
            :groupId="id"
            type="admins"
          />
        </li>
        <li v-if="formerAdminsCount">
          <a href="#former_admins_tab"/>
          <UserList
            :groupId="id"
            type="admins/former"
          />
        </li>
        <li
          v-if="group.superadmins_count"
        >
          <a href="superadmins_tab"/>
          <UserList
            v-if="activeTab === 'superadmins_tab'"
            :groupId="id"
            type="superadmins"
          />
        </li>
        <li v-if="formerSuperadminsCount">
          <a href="#former_superadmins_tab"/>
          <UserList
            :groupId="id"
            type="superadmins/former"
          />
        </li>
        <li
          v-if="group.messages_count"
          :class="$route.query.messageId ? 'uk-active': ''"
        >
          <a href="messages_tab"/>
          <MessagesList
            :groupId="id"
            type="group"
          />
        </li>
        <li>
          <a name="entities_tab"/>
          <Entities
            v-if="activeTab === 'entities_tab'"
            :chatId="String(id)"
            moduleName="whatsapp"
          />
        </li>
        <li
          v-if="ownerPhone"
        >
          <a href="owner_tab"/>
          <div
            v-if="activeTab === 'owner_tab'"
            class="uk-flex"
          >
            <Owner
              :phone="ownerPhone"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import translate from '@/mixins/translate'
import Avatar from '@/components/WhatsApp/Avatar'
import Owner from '@/components/WhatsApp/UserList/Owner'
import AvatarList from '@/components/WhatsApp/AvatarList'
import extractData from '@/utils/dataExtraction'
import { normalizeWAPhone } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'WhatsappGroupDetail',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    MessagesList: defineAsyncComponent(() => import('@/views/WhatsApp/WhatsappMessagelist')),
    UserList: defineAsyncComponent(() => import('@/views/WhatsApp/WhatsappUserlist')),
    LineGraph: defineAsyncComponent(() => import('@/components/WhatsApp/Group/LineGraph')),
    BarGraph: defineAsyncComponent(() => import('@/components/WhatsApp/Group/BarGraph')),
    Entities: defineAsyncComponent(() => import('@/components/general/Entities')),
    AuditoryCountry: defineAsyncComponent(() => import('@/components/WhatsApp/Group/WaAuditoryCountry')),
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton')),
    KnownLinks: defineAsyncComponent(() => import('@/components/WhatsApp/KnownLinks')),
    AvatarList,
    Avatar,
    Owner
  },
  mixins: [translate],
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      joining: false,
      localGroup: null,
      translated: {}
    }
  },
  watch: {
    id () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('whatsapp', [
      'isLoaded',
      'allGroups',
      'knownLinks'
    ]),
    activeTab () {
      return this.$route.params.tab ?? 'overview_tab'
    },
    formerMembersCount () {
      return this?.group?.['former_members_count']
    },
    formerAdminsCount () {
      return this?.group?.['former_admins_count']
    },
    formerSuperadminsCount () {
      return this?.group?.['former_superadmins_count']
    },
    extractedDescription () {
      if (typeof this.localGroup?.desc === 'string') {
        return extractData(this.localGroup.desc)
      }
      return ''
    },
    hasKnownLinks () {
      try {
        return this?.knownLinks?.data?.length > 0
      } catch (error) {
        console.debug(error)
      }
      return false
    },
    group () {
      return this.allGroups[this.id] || undefined
    },
    ownerPhone () {
      try {
        // eslint-disable-next-line camelcase
        return this?.group?.owner_phone
      } catch (error) {
        console.debug(error)
      }
      return null
    },
    avatar () {
      if (!this.group.avatar_cdn_path) return false
      return this.$gateway(this.group.avatar_cdn_path)
    },
    stats () {
      return this.$store.state.whatsapp.groups[this.id]['stats']
    },
    entity () {
      return this.$store.state.whatsapp.groups[this.id]['entity_data']
    },
    isLoadedEntityStats () {
      return this.$store.state.whatsapp.isLoaded['channel_' + this.id + '_entity_data']
    },
    hasJoiningButton () {
      try {
        return (
          // eslint-disable-next-line camelcase
          this?.group?.invite_hash &&
          this?.group?.joinable &&
          !this?.group?.joined
        )
      } catch (error) {
        console.debug(error)
      }
      return false
    }
  },
  methods: {
    ...mapActions('whatsapp', [
      'fetchGroupByID',
      'fetchEntitiesCount',
      'joinChat',
      'recheckGroup',
      'fetchKnownLinks'
    ]),
    normalizeWAPhone,
    avatarCdnWithoutNull (user) {
      try {
        const avatars = user.avatars.filter(avatar => avatar['avatar_cdn_path'])
        return {
          ...user,
          avatars
        }
      } catch (error) {
        console.debug(error)
      }
    },
    join () {
      let link = 'https://chat.whatsapp.com/' + this.group.invite_hash
      this.joining = true
      let caller = this
      this.joinChat(link)
        .then((res) => {
          if (!res.group_joined) {
            caller.$notify({ group: 'general', title: caller.$t('notifications.error'), text: res.errors[0], type: 'error' })
          } else if (res.group_info.id) {
            caller.$notify({ group: 'general', title: caller.$t('notifications.success'), text: this.$t('whatsapp.group-details.joined'), type: 'success' })
            caller.recheckGroup(res.group_info.id)
            caller.$forceUpdate()
          } else throw new Error(this.$t('notifications.something-went-wrong'))
        })
        .catch(err => {
          let text = this.$t('whatsapp.group-details.group-is-full')
          if (err.response && err.response.message) {
            text = err.response.message
          }
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text, type: 'error' })
        })
        .finally(() => {
          this.joining = false
        })
    },
    activateTab (tab) {
      try {
        this.$router.push({
          ...this.$route,
          params: {
            ...this.$route.params,
            tab
          }
        })
      } catch (error) {
        console.debug(error)
      }
    },
    checkForTab (tab) {
      let paramTab = this.$route.params.tab
      if (paramTab && tab === paramTab.replace('_tab', '')) {
        return 'uk-active'
      }
      if (!this.group[tab + '_count']) return 'uk-disabled'
      return ''
    },
    update () {
      this.fetchGroupByID(this.id)
        .then(() => {
          this.fetchEntitiesCount(this.id)
          this.localGroup = { ...this.allGroups[this.id] }
        })
        .catch(err => console.debug(err))
      this.fetchKnownLinks({ groupId: this.id })
    },
    normalizePhone (value) {
      if (!value) return ''
      value = value.replace('@c.us', '')
      return value
    },
    async translateSubject () {
      const { subject } = await this.translateProps(['subject'], this.localGroup, this.group)
      this.localGroup.subject = subject || this.localGroup.subject
    },
    async translateDescription () {
      const { desc } = await this.translateProps(['desc'], this.localGroup, this.group)
      this.localGroup.desc = desc || this.localGroup.desc
    }
  },
  mounted () {
    this.update()
  }
}
</script>
<style scoped lang="scss">
  .border-bottom-0 {
    border-bottom: 0
  }
  .stat-link {
    cursor:pointer; 
    color: #666;
    &:hover {
      text-decoration: none;
    }
  }
</style>
