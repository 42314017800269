import safariDictionary from './safari-dictionary'

class Unicode {
  constructor () {
    // https://stackoverflow.com/a/42189492
    this.isDesktopSafari = window.safari !== undefined
  }

  normalize (string) {
    if (!this.isDesktopSafari) {
      return string
    }

    safariDictionary.forEach((pair) => {
      string = string.replaceAll(pair[0], pair[1])
    })

    return string
  }
}

const unicode = new Unicode()

export default unicode
