<template>
  <div class="uk-flex uk-flex-column">
    <template v-if="!isLoaded">
      <div class="uk-flex uk-flex-center">
        <Loader :ratio="2"/>
      </div>
    </template>
    <template v-else>
      <div class="uk-flex uk-flex-row table-header">
        <div 
          class="uk-width-1-3 uk-text-center"  
          style="align-self: center;"
        >
          {{ $t('crypto.ton.workchain') }}
        </div>
        <div 
          class="uk-width-1-3 uk-text-center"  
          style="align-self: center;"
        >
          {{ $t('crypto.ton.seq_no') }}
        </div>
        <div 
          class="uk-width-1-3 uk-text-center" 
          style="align-self: center;"
        > 
          {{ $t('crypto.ton.transactions-count') }}
        </div>
      </div>
      <div 
        v-for="block of blocks" 
        :key="block.seq_no"
      >
        <hr style="margin: 0.7rem 0"/>
        <div class="uk-flex">
          <div 
            class="uk-width-1-3 uk-text-center" 
            style="font-size: 1.1rem;"
          >
            {{ chainTitle(block.workchain) }}
          </div>
          <div 
            class="uk-width-1-3 uk-text-center" 
            style="align-self: center; font-size: 1.1rem;"
          >
            <router-link :to="{ name: 'crypto_ton_block_info', query: { workchain: block.workchain, shard: block.shard.toString(), seq_no: block.seq_no }, hash: '#overview'}">
              {{ block.seq_no }}
            </router-link>
          </div>
          <div 
            class="uk-width-1-3 uk-text-right"
            style="padding-right: 15%" 
          >
            {{ block.transactions_count }}
          </div>
        </div>
        <div 
          v-for="shard of shards[block.seq_no]"
          :key="shard.seq_no"
          class="uk-flex"
        >
          <div 
            class="uk-width-1-3 uk-text-center" 
            style="align-self: center;"
          >
            {{ prefix(true, lastId(blockKeyGen(shard.workchain, shard.shard, shard.seq_no), block.shard_keys)) + chainTitle(shard.workchain) }}
          </div>
          <div 
            class="uk-width-1-3 uk-text-center uk-margin-left" 
            style="align-self: center; margin-left: 0.7rem;"
          >
            <p class="uk-margin-remove uk-inline">
              {{ prefix(true, lastId(blockKeyGen(shard.workchain, shard.shard, shard.seq_no), block.shard_keys)) }}
            </p>
            <router-link :to="{ name: 'crypto_ton_block_info', query: { workchain: shard.workchain, shard: shard.shard.toString(), seq_no: shard.seq_no }, hash: '#overview'}">
              {{ shard.seq_no }}
            </router-link>
          </div>
          <div 
            class="uk-width-1-3 uk-text-right"
            style="padding-right: 15%" 
          >
            {{ shard.transactions_count }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { chainTitle, fullTON, shortTON, blockKeyGen } from '@/utils/ton-filters'
import { mapGetters } from 'vuex'

export default {
  name: '',
  components: {
    Loader
  },
  computed: {
    ...mapGetters('crypto', ['getTonBlockShards', 'getTonLatestBlocks']),
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_blocks_main'] ?? false
    },
    blocks () {
      return this.getTonLatestBlocks?.length ? this.getTonLatestBlocks : []
    },
    shards () {
      const map = {}
      this.blocks.forEach(block => map[block.seq_no] = this.getTonBlockShards(this.blockKeyGen(block.workchain, block.shard, block.seq_no)))
      return map
    }
  },
  methods: {
    chainTitle,
    fullTON,
    shortTON,
    blockKeyGen,
    prefix: (shard, last) => shard ? !last ? '╰┄' : '╰┄' : '',
    lastId: (shardKey, blockShards) => blockShards[blockShards.length - 1] === shardKey
  }
}
</script>

<style scoped>
.diamond {
    content: '💎';
    filter: grayscale(100%);
}
</style>