<template>
  <div
    id="right-sidebar"
    uk-offcanvas="flip: true; overlay: true"
  >
    <div class="uk-offcanvas-bar uk-padding-small">
      <button
        class="uk-offcanvas-close"
        type="button"
        uk-close
      />
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightSidebar'
}
</script>
