<template>
  <div>
    <div class="uk-card uk-card-default">
      <div class="uk-card-body uk-margin-top">
        <Paginate
          v-if="totalPageCount > 1"
          v-model="page"
          :page-count="totalPageCount"
          :click-handler="setPage"
          prev-text="&lt;"
          next-text="&gt;"
          container-class="vuejs-pagination-container"
        />
        <div class="uk-overflow-auto">
          <ScheduledSearchList
            :tasks="tasks"
            :loading="loading"
            @refresh="update"
            @sort="sort"
          />
        </div>
      </div>
    </div>
    <div class="uk-grid uk-padding uk-padding-remove-left uk-padding-remove-right uk-padding-remove-top">
      <div class="uk-width-1-2@m uk-margin-top uk-padding-left@m">
        <AddScheduledTask @refresh="update"/>
      </div>
      <div class="uk-width-1-2@m uk-margin-top uk-padding-left">
        <div
          class="uk-card uk-align-center uk-text-center uk-card-default uk-card-body uk-flex uk-flex-center uk-flex-middle"
          style="height: 100%"
        >
          <div>
            {{ $t('tgcp.tasks.scheduled-search-help') }}
          </div>
        </div>
      </div>
    </div>
    <ChannelListModal
      ref="channelList"
      :id="selectedChats"
      :total="selectedChats.length"
      :initialChats="selectedChats"
      :onlyModal="true"
      type="scheduled"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Paginate from '@/components/Paginate'

export default {
  name: 'TasksScheduledSearch',
  components: {
    ScheduledSearchList: defineAsyncComponent(() => import('@/components/TGCP/Tasks/ScheduledSearchList')),
    AddScheduledTask: defineAsyncComponent(() => import('@/components/TGCP/Tasks/AddScheduledSearchTask')),
    ChannelListModal: defineAsyncComponent(() => import('@/components/TGCP/ChannelList/ChannelListModal')),
    Paginate
  },
  data () {
    return {
      tasks: [],
      query: {
        limit: 20,
        offset: 0,
        order_by: null,
        order_type: null
      },
      page: 1,
      totalCount: 0,
      selectedChats: []
    }
  },
  computed: {
    ...mapGetters('cases', ['isLoaded']),
    loading () {
      return !this.isLoaded['fetchScheduledTasks']
    },
    totalPageCount () {
      return Math.ceil(this.totalCount / this.query.limit)
    },
    selectedTaskId () {
      return this.$route.query.selectedTaskId
    },
    selectedChatsId () {
      return this.$route.query.selectedChatsId
    }
  },
  methods: {
    ...mapActions('cases', [
      'fetchScheduledTasks',
      'fetchScheduledTaskById'
    ]),
    async update () {
      try {
        const result = await this.fetchScheduledTasks(this.query)

        this.totalCount = result.count
        this.tasks = result.tasks
      } catch (error) {
        console.error(error)
      }
    },
    setPage (page) {
      this.query.offset = (page - 1) * this.query.limit
      this.page = page
      this.update()
    },
    sort (data) {
      this.query.order_by = data.column
      this.query.order_type = data.direction
      this.update()
    }
  },
  async mounted () {
    await this.update()

    const { selectedTaskId, selectedChatsId } = this.$route.query

    if (!selectedTaskId || !selectedChatsId) {
      return
    }

    try {
      const response = await this.fetchScheduledTaskById(selectedTaskId)

      const selectedTask = response.data.task

      if (!selectedTask) {
        return
      }

      const selectedChatsResult = selectedTask.chats.find(chatsResult => chatsResult.id === selectedChatsId)

      if (!selectedChatsResult) {
        return
      }

      this.selectedChats = selectedChatsResult.chats

      if (this.selectedChats.length > 0) {
        this.$refs.channelList.showModal()
      }
    } catch (error) {
      this.$notify({
        group: 'general',
        title: this.$t('notifications.error'),
        text: `Error when get selected task with id ${selectedTaskId}`,
        type: 'error'
      })
    }
  }
}
</script>
