<template>
  <div class="uk-margin-top">
    <div
      v-if="!messengerData"
      class="uk-card uk-card-default uk-margin-top uk-card-body"
    >
      {{ $t('not_found.not_available') }}
    </div>
    <div v-else>
      <h2 class="uk-heading-divider">
        {{ $t('usage_stats.platform_stats_title') }}
      </h2>
      <div
        class="uk-grid"
        uk-grid="masonry: true"
      >
        <div class="uk-width-1-2@m">
          <div class="uk-card uk-card-default uk-margin-top ">
            <div class="uk-card-title uk-padding-small">
              {{ $t('usage_stats.usage_numbers_title') }}
            </div>
            <div class="uk-card-body">
              <table class="uk-table">
                <tr>
                  <th>
                    {{ $t('usage_stats.MAU') }}
                  </th><th>
                    {{ $t('usage_stats.WAU') }}
                  </th><th>
                    {{ $t('usage_stats.total_installs') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    {{ numberFormat(messengerData.mau) }}
                  </td><td>
                    {{ numberFormat(messengerData.wau) }}
                  </td><td>
                    {{ numberFormat(messengerData.installs) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div
          v-if="countryUsage"
          class="uk-width-1-2@m"
        >
          <div class="uk-card uk-card-default uk-margin-top  ">
            <div class="uk-card-title  uk-padding-small">
              {{ $t('usage_stats.app_installs') }}
            </div>
            <div class="uk-card-body uk-padding-remove-top ">
              <VueApexCharts
                ref="chart"
                :series="series"
                :options="chartOptions"
                @legendClick="legendClickHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChartDefault from '@/mixins/PieChartDefault.js'
import VueApexCharts from 'vue3-apexcharts'
import { numberFormat } from '@/filters/globalFilters'

export default {
  name: 'MessengerUsageStats',
  components: {
    VueApexCharts
  },
  mixins: [
    PieChartDefault
  ],
  props: {
    name: {
      required: true,
      type: String,
      default: 'whatsapp'
    }
  },
  computed: {
    totalArchive () {
      return {
        'whatsapp': {
          'mau': '1143041610',
          'wau': '400064564',
          'installs': '4950021500',
          'geo_usage': [
            { 'country_name': this.$t('countries.in'), 'downloads': '11501934' },
            { 'country_name': this.$t('countries.BR'), 'downloads': '9017028' },
            { 'country_name': this.$t('countries.ID'), 'downloads': '5506444' },
            { 'country_name': this.$t('countries.MX'), 'downloads': '5118841' },
            { 'country_name': this.$t('countries.TR'), 'downloads': '3040472' },
            { 'country_name': this.$t('countries.RU'), 'downloads': '2924645' },
            { 'country_name': this.$t('countries.PK'), 'downloads': '2050103' },
            { 'country_name': this.$t('countries.SA'), 'downloads': '1994912' },
            { 'country_name': this.$t('countries.US'), 'downloads': '1812229' },
            { 'country_name': this.$t('countries.EG'), 'downloads': '1713432' },
            { 'country_name': 'France', 'downloads': '1639125' },
            { 'country_name': 'Colombia', 'downloads': '1635603' },
            { 'country_name': 'Peru', 'downloads': '1451410' },
            { 'country_name': 'Malaysia', 'downloads': '1403004' },
            { 'country_name': 'Italy', 'downloads': '1392718' },
            { 'country_name': 'South Africa', 'downloads': '1274802' },
            { 'country_name': 'Germany', 'downloads': '1181714' },
            { 'country_name': 'Spain', 'downloads': '1094773' },
            { 'country_name': 'United Kingdom', 'downloads': '1059921' },
            { 'country_name': 'Chile', 'downloads': '841178' },
            { 'country_name': 'Romania', 'downloads': '715291' },
            { 'country_name': 'Nigeria', 'downloads': '592937' },
            { 'country_name': 'United Arab Emirates', 'downloads': '553292' },
            { 'country_name': 'Ecuador', 'downloads': '552529' },
            { 'country_name': 'Sri Lanka', 'downloads': '476539' },
            { 'country_name': 'Venezuela', 'downloads': '449846' },
            { 'country_name': 'Netherlands', 'downloads': '424803' },
            { 'country_name': 'Poland', 'downloads': '401189' },
            { 'country_name': 'Belgium', 'downloads': '352811' },
            { 'country_name': 'Canada', 'downloads': '32426' },
            { 'country_name': 'Kenya', 'downloads': '314526' },
            { 'country_name': 'Czech Republic', 'downloads': '280601' },
            { 'country_name': 'Australia', 'downloads': '251839' },
            { 'country_name': 'Portugal', 'downloads': '233697' },
            { 'country_name': 'Uruguay', 'downloads': '223774' },
            { 'country_name': 'Singapore', 'downloads': '201245' },
            { 'country_name': 'Austria', 'downloads': '195944' },
            { 'country_name': 'Israel', 'downloads': '188608' },
            { 'country_name': 'Philippines', 'downloads': '187016' },
            { 'country_name': 'Hong Kong', 'downloads': '183431' },
            { 'country_name': 'Ireland', 'downloads': '179552' },
            { 'country_name': 'Switzerland', 'downloads': '151985' },
            { 'country_name': 'Sweden', 'downloads': '123294' },
            { 'country_name': 'Finland', 'downloads': '99019' },
            { 'country_name': 'Thailand', 'downloads': '69631' },
            { 'country_name': 'New Zealand', 'downloads': '61783' },
            { 'country_name': 'Viet Nam', 'downloads': '58015' },
            { 'country_name': 'Korea', 'downloads': '48017' },
            { 'country_name': 'Hungary', 'downloads': '45735' },
            { 'country_name': 'Taiwan', 'downloads': '43373' },
            { 'country_name': 'Japan', 'downloads': '23146' },
            { 'country_name': 'Norway', 'downloads': '20113' },
            { 'country_name': 'Denmark', 'downloads': '17132' }]
        },
        'skype': {
          'mau': '216224672',
          'wau': '75678635',
          'installs': '1067838190'
        },
        'telegram': {
          'mau': '51385180',
          'wau': '17984813',
          'installs': '210220870',
          'geo_usage': [{ 'country_name': 'India', 'downloads': '1873127' },
                        { 'country_name': 'Russian Federation', 'downloads': '1420373' },
                        { 'country_name': 'Brazil', 'downloads': '1273046' },
                        { 'country_name': 'Italy', 'downloads': '594019' },
                        { 'country_name': 'Malaysia', 'downloads': '458056' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '391493' },
                        { 'country_name': 'South Africa', 'downloads': '322299' },
                        { 'country_name': 'Indonesia', 'downloads': '304909' },
                        { 'country_name': 'Egypt', 'downloads': '26089' },
                        { 'country_name': 'Spain', 'downloads': '215007' },
                        { 'country_name': 'Turkey', 'downloads': '209346' },
                        { 'country_name': 'United States', 'downloads': '168307' },
                        { 'country_name': 'Mexico', 'downloads': '160911' },
                        { 'country_name': 'Hong Kong', 'downloads': '1394' },
                        { 'country_name': 'Germany', 'downloads': '136322' },
                        { 'country_name': 'Korea', 'downloads': '115559' },
                        { 'country_name': 'Colombia', 'downloads': '104751' },
                        { 'country_name': 'Philippines', 'downloads': '102312' },
                        { 'country_name': 'Peru', 'downloads': '97912' },
                        { 'country_name': 'Nigeria', 'downloads': '93598' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '87913' },
                        { 'country_name': 'Ecuador', 'downloads': '69438' },
                        { 'country_name': 'Venezuela', 'downloads': '66896' },
                        { 'country_name': 'Kenya', 'downloads': '59176' },
                        { 'country_name': 'France', 'downloads': '57823' },
                        { 'country_name': 'Singapore', 'downloads': '55182' },
                        { 'country_name': 'Viet Nam', 'downloads': '52171' },
                        { 'country_name': 'Israel', 'downloads': '5074' },
                        { 'country_name': 'Portugal', 'downloads': '47206' },
                        { 'country_name': 'United Kingdom', 'downloads': '45134' },
                        { 'country_name': 'Poland', 'downloads': '3344' },
                        { 'country_name': 'Uruguay', 'downloads': '25444' },
                        { 'country_name': 'Chile', 'downloads': '23323' },
                        { 'country_name': 'Canada', 'downloads': '21848' },
                        { 'country_name': 'Romania', 'downloads': '20803' },
                        { 'country_name': 'Netherlands', 'downloads': '19175' },
                        { 'country_name': 'Pakistan', 'downloads': '19032' },
                        { 'country_name': 'Finland', 'downloads': '15075' },
                        { 'country_name': 'Sri Lanka', 'downloads': '14018' },
                        { 'country_name': 'Taiwan', 'downloads': '13559' },
                        { 'country_name': 'Australia', 'downloads': '12816' },
                        { 'country_name': 'Austria', 'downloads': '12167' },
                        { 'country_name': 'Switzerland', 'downloads': '1208' },
                        { 'country_name': 'Czech Republic', 'downloads': '11364' },
                        { 'country_name': 'Belgium', 'downloads': '10634' },
                        { 'country_name': 'Hungary', 'downloads': '9644' },
                        { 'country_name': 'Thailand', 'downloads': '9373' },
                        { 'country_name': 'Sweden', 'downloads': '8729' },
                        { 'country_name': 'Japan', 'downloads': '5657' },
                        { 'country_name': 'Ireland', 'downloads': '2863' },
                        { 'country_name': 'New Zealand', 'downloads': '2621' },
                        { 'country_name': 'Norway', 'downloads': '2341' },
                        { 'country_name': 'Denmark', 'downloads': '1684' }]
        },
        'line': {
          'mau': '160717640',
          'wau': '56251174',
          'installs': '779448735',
          'geo_usage': [{ 'country_name': 'Thailand', 'downloads': '1040425' },
                        { 'country_name': 'Japan', 'downloads': '649286' },
                        { 'country_name': 'Taiwan', 'downloads': '38275' },
                        { 'country_name': 'Indonesia', 'downloads': '335508' },
                        { 'country_name': 'Korea', 'downloads': '107852' },
                        { 'country_name': 'Turkey', 'downloads': '72571' },
                        { 'country_name': 'United States', 'downloads': '63081' },
                        { 'country_name': 'Viet Nam', 'downloads': '58622' },
                        { 'country_name': 'Malaysia', 'downloads': '4761' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '476' },
                        { 'country_name': 'Hong Kong', 'downloads': '34162' },
                        { 'country_name': 'Philippines', 'downloads': '28087' },
                        { 'country_name': 'Egypt', 'downloads': '27464' },
                        { 'country_name': 'Russian Federation', 'downloads': '24955' },
                        { 'country_name': 'Singapore', 'downloads': '22711' },
                        { 'country_name': 'India', 'downloads': '21131' },
                        { 'country_name': 'Mexico', 'downloads': '18434' },
                        { 'country_name': 'Germany', 'downloads': '17437' },
                        { 'country_name': 'Spain', 'downloads': '16601' },
                        { 'country_name': 'Brazil', 'downloads': '15856' },
                        { 'country_name': 'Pakistan', 'downloads': '15725' },
                        { 'country_name': 'Canada', 'downloads': '11337' },
                        { 'country_name': 'Australia', 'downloads': '9093' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '8388' },
                        { 'country_name': 'France', 'downloads': '8203' },
                        { 'country_name': 'United Kingdom', 'downloads': '6492' },
                        { 'country_name': 'Nigeria', 'downloads': '5743' },
                        { 'country_name': 'Italy', 'downloads': '4698' },
                        { 'country_name': 'Ecuador', 'downloads': '4238' },
                        { 'country_name': 'Sri Lanka', 'downloads': '3951' },
                        { 'country_name': 'Colombia', 'downloads': '3826' },
                        { 'country_name': 'Israel', 'downloads': '2461' },
                        { 'country_name': 'Sweden', 'downloads': '2354' },
                        { 'country_name': 'Poland', 'downloads': '2354' },
                        { 'country_name': 'Belgium', 'downloads': '2208' },
                        { 'country_name': 'Peru', 'downloads': '1993' },
                        { 'country_name': 'Netherlands', 'downloads': '199' },
                        { 'country_name': 'New Zealand', 'downloads': '1953' },
                        { 'country_name': 'Austria', 'downloads': '1877' },
                        { 'country_name': 'Chile', 'downloads': '1617' },
                        { 'country_name': 'Switzerland', 'downloads': '1543' },
                        { 'country_name': 'Romania', 'downloads': '1499' },
                        { 'country_name': 'Portugal', 'downloads': '1493' },
                        { 'country_name': 'South Africa', 'downloads': '1034' },
                        { 'country_name': 'Uruguay', 'downloads': '941' },
                        { 'country_name': 'Czech Republic', 'downloads': '695' },
                        { 'country_name': 'Norway', 'downloads': '672' },
                        { 'country_name': 'Hungary', 'downloads': '671' },
                        { 'country_name': 'Venezuela', 'downloads': '576' },
                        { 'country_name': 'Kenya', 'downloads': '498' },
                        { 'country_name': 'Ireland', 'downloads': '484' },
                        { 'country_name': 'Denmark', 'downloads': '441' },
                        { 'country_name': 'Finland', 'downloads': '431' }]
        },
        'viber': {
          'mau': '159506008',
          'wau': '55827103',
          'installs': '768960766',
          'geo_usage': [{ 'country_name': 'Russian Federation', 'downloads': '1813504' },
                        { 'country_name': 'Egypt', 'downloads': '243612' },
                        { 'country_name': 'Philippines', 'downloads': '162024' },
                        { 'country_name': 'Sri Lanka', 'downloads': '161137' },
                        { 'country_name': 'Viet Nam', 'downloads': '99928' },
                        { 'country_name': 'Turkey', 'downloads': '92379' },
                        { 'country_name': 'United States', 'downloads': '84209' },
                        { 'country_name': 'Poland', 'downloads': '82484' },
                        { 'country_name': 'Hungary', 'downloads': '77397' },
                        { 'country_name': 'France', 'downloads': '75869' },
                        { 'country_name': 'Germany', 'downloads': '6187' },
                        { 'country_name': 'Italy', 'downloads': '43091' },
                        { 'country_name': 'United Kingdom', 'downloads': '33594' },
                        { 'country_name': 'Pakistan', 'downloads': '30379' },
                        { 'country_name': 'Czech Republic', 'downloads': '29771' },
                        { 'country_name': 'India', 'downloads': '28288' },
                        { 'country_name': 'Malaysia', 'downloads': '25419' },
                        { 'country_name': 'Spain', 'downloads': '23233' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '20058' },
                        { 'country_name': 'Thailand', 'downloads': '19757' },
                        { 'country_name': 'Israel', 'downloads': '18326' },
                        { 'country_name': 'Australia', 'downloads': '16989' },
                        { 'country_name': 'Sweden', 'downloads': '16633' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '16611' },
                        { 'country_name': 'Canada', 'downloads': '16515' },
                        { 'country_name': 'Romania', 'downloads': '15731' },
                        { 'country_name': 'Brazil', 'downloads': '15617' },
                        { 'country_name': 'Singapore', 'downloads': '14953' },
                        { 'country_name': 'Belgium', 'downloads': '14569' },
                        { 'country_name': 'Indonesia', 'downloads': '1199' },
                        { 'country_name': 'Austria', 'downloads': '11648' },
                        { 'country_name': 'Portugal', 'downloads': '10222' },
                        { 'country_name': 'Ireland', 'downloads': '9643' },
                        { 'country_name': 'Switzerland', 'downloads': '8819' },
                        { 'country_name': 'Netherlands', 'downloads': '7845' },
                        { 'country_name': 'Japan', 'downloads': '7642' },
                        { 'country_name': 'Finland', 'downloads': '6956' },
                        { 'country_name': 'Nigeria', 'downloads': '6767' },
                        { 'country_name': 'Mexico', 'downloads': '6687' },
                        { 'country_name': 'Taiwan', 'downloads': '5754' },
                        { 'country_name': 'Korea', 'downloads': '5584' },
                        { 'country_name': 'New Zealand', 'downloads': '548' },
                        { 'country_name': 'Kenya', 'downloads': '4481' },
                        { 'country_name': 'South Africa', 'downloads': '4466' },
                        { 'country_name': 'Hong Kong', 'downloads': '4358' },
                        { 'country_name': 'Norway', 'downloads': '3996' },
                        { 'country_name': 'Denmark', 'downloads': '2655' },
                        { 'country_name': 'Colombia', 'downloads': '2046' },
                        { 'country_name': 'Ecuador', 'downloads': '1441' },
                        { 'country_name': 'Peru', 'downloads': '1212' },
                        { 'country_name': 'Chile', 'downloads': '750' },
                        { 'country_name': 'Uruguay', 'downloads': '668' },
                        { 'country_name': 'Venezuela', 'downloads': '584' }]
        },
        'wechat': {
          'mau': '67176575',
          'wau': '23511801',
          'installs': '326953547',
          'geo_usage': [{ 'country_name': 'Malaysia', 'downloads': '31809' },
                        { 'country_name': 'Taiwan', 'downloads': '234641' },
                        { 'country_name': 'Hong Kong', 'downloads': '163651' },
                        { 'country_name': 'Viet Nam', 'downloads': '144658' },
                        { 'country_name': 'Indonesia', 'downloads': '97727' },
                        { 'country_name': 'Korea', 'downloads': '82529' },
                        { 'country_name': 'Thailand', 'downloads': '78114' },
                        { 'country_name': 'Russian Federation', 'downloads': '72569' },
                        { 'country_name': 'United States', 'downloads': '59884' },
                        { 'country_name': 'Philippines', 'downloads': '52221' },
                        { 'country_name': 'Turkey', 'downloads': '3664' },
                        { 'country_name': 'India', 'downloads': '35678' },
                        { 'country_name': 'Japan', 'downloads': '33668' },
                        { 'country_name': 'Pakistan', 'downloads': '33019' },
                        { 'country_name': 'Singapore', 'downloads': '31935' },
                        { 'country_name': 'Italy', 'downloads': '29628' },
                        { 'country_name': 'Nigeria', 'downloads': '29277' },
                        { 'country_name': 'Spain', 'downloads': '25333' },
                        { 'country_name': 'United Kingdom', 'downloads': '23007' },
                        { 'country_name': 'Mexico', 'downloads': '20707' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '1818' },
                        { 'country_name': 'Brazil', 'downloads': '17515' },
                        { 'country_name': 'Canada', 'downloads': '15205' },
                        { 'country_name': 'Germany', 'downloads': '14378' },
                        { 'country_name': 'Australia', 'downloads': '13664' },
                        { 'country_name': 'South Africa', 'downloads': '13508' },
                        { 'country_name': 'France', 'downloads': '12897' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '11303' },
                        { 'country_name': 'Egypt', 'downloads': '9535' },
                        { 'country_name': 'Netherlands', 'downloads': '5878' },
                        { 'country_name': 'Colombia', 'downloads': '4417' },
                        { 'country_name': 'Israel', 'downloads': '4348' },
                        { 'country_name': 'Sri Lanka', 'downloads': '4107' },
                        { 'country_name': 'New Zealand', 'downloads': '3945' },
                        { 'country_name': 'Peru', 'downloads': '3829' },
                        { 'country_name': 'Portugal', 'downloads': '3105' },
                        { 'country_name': 'Poland', 'downloads': '2966' },
                        { 'country_name': 'Belgium', 'downloads': '2679' },
                        { 'country_name': 'Sweden', 'downloads': '2311' },
                        { 'country_name': 'Switzerland', 'downloads': '216' },
                        { 'country_name': 'Austria', 'downloads': '2116' },
                        { 'country_name': 'Kenya', 'downloads': '2058' },
                        { 'country_name': 'Ecuador', 'downloads': '2022' },
                        { 'country_name': 'Hungary', 'downloads': '1921' },
                        { 'country_name': 'Chile', 'downloads': '1496' },
                        { 'country_name': 'Romania', 'downloads': '146' },
                        { 'country_name': 'Venezuela', 'downloads': '1209' },
                        { 'country_name': 'Czech Republic', 'downloads': '1072' },
                        { 'country_name': 'Ireland', 'downloads': '929' },
                        { 'country_name': 'Uruguay', 'downloads': '726' },
                        { 'country_name': 'Norway', 'downloads': '696' },
                        { 'country_name': 'Denmark', 'downloads': '642' },
                        { 'country_name': 'Finland', 'downloads': '613' }]
        },
        'zalo': {
          'geo_usage': [{ 'country_name': 'Viet Nam', 'downloads': '1145074' },
                        { 'country_name': 'United States', 'downloads': '8277' },
                        { 'country_name': 'Taiwan', 'downloads': '8205' },
                        { 'country_name': 'Korea', 'downloads': '7172' },
                        { 'country_name': 'Nigeria', 'downloads': '2821' },
                        { 'country_name': 'Malaysia', 'downloads': '2074' },
                        { 'country_name': 'Singapore', 'downloads': '1452' },
                        { 'country_name': 'Thailand', 'downloads': '919' },
                        { 'country_name': 'Germany', 'downloads': '826' },
                        { 'country_name': 'Australia', 'downloads': '687' },
                        { 'country_name': 'Indonesia', 'downloads': '667' },
                        { 'country_name': 'Philippines', 'downloads': '644' },
                        { 'country_name': 'Canada', 'downloads': '592' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '517' },
                        { 'country_name': 'France', 'downloads': '501' },
                        { 'country_name': 'Hong Kong', 'downloads': '483' },
                        { 'country_name': 'Japan', 'downloads': '458' },
                        { 'country_name': 'Russian Federation', 'downloads': '392' },
                        { 'country_name': 'United Kingdom', 'downloads': '371' },
                        { 'country_name': 'Czech Republic', 'downloads': '258' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '249' },
                        { 'country_name': 'Pakistan', 'downloads': '238' },
                        { 'country_name': 'Turkey', 'downloads': '181' },
                        { 'country_name': 'Netherlands', 'downloads': '175' },
                        { 'country_name': 'Sweden', 'downloads': '130' },
                        { 'country_name': 'Romania', 'downloads': '130' },
                        { 'country_name': 'Sri Lanka', 'downloads': '84' },
                        { 'country_name': 'Norway', 'downloads': '56' },
                        { 'country_name': 'Belgium', 'downloads': '55' },
                        { 'country_name': 'New Zealand', 'downloads': '55' },
                        { 'country_name': 'Poland', 'downloads': '43' },
                        { 'country_name': 'Finland', 'downloads': '42' },
                        { 'country_name': 'Denmark', 'downloads': '29' },
                        { 'country_name': 'Hungary', 'downloads': '27' },
                        { 'country_name': 'Ireland', 'downloads': '25' },
                        { 'country_name': 'Egypt', 'downloads': '21' },
                        { 'country_name': 'Switzerland', 'downloads': '18' },
                        { 'country_name': 'Israel', 'downloads': '11' },
                        { 'country_name': 'Austria', 'downloads': '10' },
                        { 'country_name': 'South Africa', 'downloads': '5' }]
        },
        'signal': {
          'mau': '3044205',
          'wau': '1065472',
          'installs': '12942166',
          'geo_usage': [{ 'country_name': 'United States', 'downloads': '64368' },
                        { 'country_name': 'Germany', 'downloads': '4549' },
                        { 'country_name': 'Russian Federation', 'downloads': '41974' },
                        { 'country_name': 'Poland', 'downloads': '23302' },
                        { 'country_name': 'France', 'downloads': '22766' },
                        { 'country_name': 'Canada', 'downloads': '13824' },
                        { 'country_name': 'Brazil', 'downloads': '1302' },
                        { 'country_name': 'Australia', 'downloads': '10025' },
                        { 'country_name': 'Hungary', 'downloads': '8028' },
                        { 'country_name': 'Austria', 'downloads': '7745' },
                        { 'country_name': 'Spain', 'downloads': '662' },
                        { 'country_name': 'India', 'downloads': '6082' },
                        { 'country_name': 'Netherlands', 'downloads': '5328' },
                        { 'country_name': 'United Kingdom', 'downloads': '514' },
                        { 'country_name': 'Sweden', 'downloads': '353' },
                        { 'country_name': 'Italy', 'downloads': '3484' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '3327' },
                        { 'country_name': 'Mexico', 'downloads': '3226' },
                        { 'country_name': 'Colombia', 'downloads': '318' },
                        { 'country_name': 'Turkey', 'downloads': '3003' },
                        { 'country_name': 'Switzerland', 'downloads': '27' },
                        { 'country_name': 'Belgium', 'downloads': '2303' },
                        { 'country_name': 'Ecuador', 'downloads': '2111' },
                        { 'country_name': 'Portugal', 'downloads': '2012' },
                        { 'country_name': 'Hong Kong', 'downloads': '1964' },
                        { 'country_name': 'Finland', 'downloads': '1909' },
                        { 'country_name': 'Czech Republic', 'downloads': '1886' },
                        { 'country_name': 'New Zealand', 'downloads': '1771' },
                        { 'country_name': 'Romania', 'downloads': '1755' },
                        { 'country_name': 'Norway', 'downloads': '1716' },
                        { 'country_name': 'Pakistan', 'downloads': '1689' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '1652' },
                        { 'country_name': 'Japan', 'downloads': '1614' },
                        { 'country_name': 'Korea', 'downloads': '1571' },
                        { 'country_name': 'Philippines', 'downloads': '1513' },
                        { 'country_name': 'Israel', 'downloads': '122' },
                        { 'country_name': 'Egypt', 'downloads': '1127' },
                        { 'country_name': 'Indonesia', 'downloads': '1026' },
                        { 'country_name': 'Denmark', 'downloads': '816' },
                        { 'country_name': 'Venezuela', 'downloads': '729' },
                        { 'country_name': 'Nigeria', 'downloads': '696' },
                        { 'country_name': 'South Africa', 'downloads': '684' },
                        { 'country_name': 'Singapore', 'downloads': '632' },
                        { 'country_name': 'Malaysia', 'downloads': '626' },
                        { 'country_name': 'Ireland', 'downloads': '612' },
                        { 'country_name': 'Chile', 'downloads': '605' },
                        { 'country_name': 'Taiwan', 'downloads': '556' },
                        { 'country_name': 'Kenya', 'downloads': '546' },
                        { 'country_name': 'Thailand', 'downloads': '486' },
                        { 'country_name': 'Viet Nam', 'downloads': '462' },
                        { 'country_name': 'Peru', 'downloads': '436' },
                        { 'country_name': 'Uruguay', 'downloads': '364' },
                        { 'country_name': 'Sri Lanka', 'downloads': '305' }]
        },
        'kakaotalk': {
          'mau': '57756464',
          'wau': '20214762',
          'installs': '282288828',
          'geo_usage': [{ 'country_name': 'Korea', 'downloads': '488077' },
                        { 'country_name': 'Japan', 'downloads': '58365' },
                        { 'country_name': 'United States', 'downloads': '22857' },
                        { 'country_name': 'Indonesia', 'downloads': '20938' },
                        { 'country_name': 'Thailand', 'downloads': '18779' },
                        { 'country_name': 'Philippines', 'downloads': '11275' },
                        { 'country_name': 'Russian Federation', 'downloads': '9843' },
                        { 'country_name': 'Viet Nam', 'downloads': '7202' },
                        { 'country_name': 'Brazil', 'downloads': '6828' },
                        { 'country_name': 'Taiwan', 'downloads': '6162' },
                        { 'country_name': 'Turkey', 'downloads': '4589' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '3954' },
                        { 'country_name': 'Singapore', 'downloads': '3676' },
                        { 'country_name': 'India', 'downloads': '359' },
                        { 'country_name': 'Malaysia', 'downloads': '3585' },
                        { 'country_name': 'Nigeria', 'downloads': '3205' },
                        { 'country_name': 'Mexico', 'downloads': '29' },
                        { 'country_name': 'Hong Kong', 'downloads': '2474' },
                        { 'country_name': 'Germany', 'downloads': '2265' },
                        { 'country_name': 'Canada', 'downloads': '1785' },
                        { 'country_name': 'France', 'downloads': '1762' },
                        { 'country_name': 'Australia', 'downloads': '1557' },
                        { 'country_name': 'Pakistan', 'downloads': '1276' },
                        { 'country_name': 'Egypt', 'downloads': '1253' },
                        { 'country_name': 'Poland', 'downloads': '1237' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '1235' },
                        { 'country_name': 'United Kingdom', 'downloads': '1213' },
                        { 'country_name': 'Spain', 'downloads': '936' },
                        { 'country_name': 'Italy', 'downloads': '715' },
                        { 'country_name': 'Colombia', 'downloads': '686' },
                        { 'country_name': 'Sri Lanka', 'downloads': '552' },
                        { 'country_name': 'Chile', 'downloads': '528' },
                        { 'country_name': 'Sweden', 'downloads': '493' },
                        { 'country_name': 'Ecuador', 'downloads': '444' },
                        { 'country_name': 'Netherlands', 'downloads': '401' },
                        { 'country_name': 'Romania', 'downloads': '394' },
                        { 'country_name': 'Belgium', 'downloads': '394' },
                        { 'country_name': 'Portugal', 'downloads': '378' },
                        { 'country_name': 'Austria', 'downloads': '372' },
                        { 'country_name': 'New Zealand', 'downloads': '343' },
                        { 'country_name': 'Israel', 'downloads': '335' },
                        { 'country_name': 'Peru', 'downloads': '320' },
                        { 'country_name': 'Hungary', 'downloads': '299' },
                        { 'country_name': 'Czech Republic', 'downloads': '283' },
                        { 'country_name': 'Uruguay', 'downloads': '235' },
                        { 'country_name': 'South Africa', 'downloads': '181' },
                        { 'country_name': 'Venezuela', 'downloads': '147' },
                        { 'country_name': 'Finland', 'downloads': '136' },
                        { 'country_name': 'Switzerland', 'downloads': '121' },
                        { 'country_name': 'Norway', 'downloads': '120' },
                        { 'country_name': 'Ireland', 'downloads': '115' },
                        { 'country_name': 'Kenya', 'downloads': '92' },
                        { 'country_name': 'Denmark', 'downloads': '88' }]
        },
        'twitter': {
          'installs': '927801988',
          'mau': '330000000',
          'wau': ''
        },
        'qq': {
          'mau': '3067603',
          'wau': '1073661',
          'installs': '14112830',
          'geo_usage': [{ 'country_name': 'Taiwan', 'downloads': '71175' },
                        { 'country_name': 'Malaysia', 'downloads': '19523' },
                        { 'country_name': 'Hong Kong', 'downloads': '16176' },
                        { 'country_name': 'United States', 'downloads': '12284' },
                        { 'country_name': 'Viet Nam', 'downloads': '11331' },
                        { 'country_name': 'Thailand', 'downloads': '10387' },
                        { 'country_name': 'Korea', 'downloads': '9566' },
                        { 'country_name': 'Singapore', 'downloads': '6564' },
                        { 'country_name': 'Brazil', 'downloads': '5064' },
                        { 'country_name': 'Nigeria', 'downloads': '3945' },
                        { 'country_name': 'Philippines', 'downloads': '3149' },
                        { 'country_name': 'Indonesia', 'downloads': '3001' },
                        { 'country_name': 'India', 'downloads': '2825' },
                        { 'country_name': 'Saudi Arabia', 'downloads': '1969' },
                        { 'country_name': 'Russian Federation', 'downloads': '1752' },
                        { 'country_name': 'Pakistan', 'downloads': '1707' },
                        { 'country_name': 'Australia', 'downloads': '1575' },
                        { 'country_name': 'Canada', 'downloads': '1469' },
                        { 'country_name': 'Germany', 'downloads': '1174' },
                        { 'country_name': 'Egypt', 'downloads': '1115' },
                        { 'country_name': 'United Kingdom', 'downloads': '1111' },
                        { 'country_name': 'Mexico', 'downloads': '1089' },
                        { 'country_name': 'France', 'downloads': '924' },
                        { 'country_name': 'United Arab Emirates', 'downloads': '862' },
                        { 'country_name': 'Spain', 'downloads': '840' },
                        { 'country_name': 'Italy', 'downloads': '768' },
                        { 'country_name': 'Turkey', 'downloads': '656' },
                        { 'country_name': 'Israel', 'downloads': '503' },
                        { 'country_name': 'Netherlands', 'downloads': '361' },
                        { 'country_name': 'Colombia', 'downloads': '356' },
                        { 'country_name': 'New Zealand', 'downloads': '327' },
                        { 'country_name': 'Sweden', 'downloads': '313' },
                        { 'country_name': 'Portugal', 'downloads': '269' },
                        { 'country_name': 'Sri Lanka', 'downloads': '258' },
                        { 'country_name': 'Poland', 'downloads': '245' },
                        { 'country_name': 'Ecuador', 'downloads': '243' },
                        { 'country_name': 'Romania', 'downloads': '231' },
                        { 'country_name': 'Belgium', 'downloads': '195' },
                        { 'country_name': 'Chile', 'downloads': '186' },
                        { 'country_name': 'South Africa', 'downloads': '179' },
                        { 'country_name': 'Kenya', 'downloads': '163' },
                        { 'country_name': 'Peru', 'downloads': '151' },
                        { 'country_name': 'Austria', 'downloads': '146' },
                        { 'country_name': 'Hungary', 'downloads': '135' },
                        { 'country_name': 'Czech Republic', 'downloads': '116' },
                        { 'country_name': 'Ireland', 'downloads': '110' },
                        { 'country_name': 'Uruguay', 'downloads': '108' },
                        { 'country_name': 'Venezuela', 'downloads': '103' },
                        { 'country_name': 'Switzerland', 'downloads': '70' },
                        { 'country_name': 'Finland', 'downloads': '69' },
                        { 'country_name': 'Norway', 'downloads': '60' },
                        { 'country_name': 'Denmark', 'downloads': '54' }]
        }
      }
    },
    countryUsage () {
      const usageData = this.totalArchive[this.name]['geo_usage']
      if (!usageData) return false
      usageData.sort(function (a, b) {
        return Number(b.downloads) - Number(a.downloads)
      })
      var result = {
        datasets: [{
          data: [],
          backgroundColor: [
            '#4dc9f6',
            '#f67019',
            '#f53794',
            '#537bc4',
            '#acc236',
            '#166a8f',
            '#00a950',
            '#58595b',
            '#8549ba',
            '#D60000',
            '#F46300',
            '#0358B6',
            '#44DE28'
          ]
        }],
        labels: []
      }
      var others = { country_name: 'Others', downloads: 0 }
      usageData.forEach(function (data, index) {
        if (index > 8) {
          others.downloads += Number(data.downloads)
        } else {
          result.datasets[0].data.push(data.downloads)
          result.labels.push(data.country_name)
        }
      })
      if (others.downloads) {
        result.datasets[0].data.push(others.downloads)
        result.labels.push(others.country_name)
      }
      return result
    },
    messengerData () {
      return this.totalArchive[this.name]
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['message_' + this.messageId]
    }
  },
  methods: {
    numberFormat
  },
  mounted () {
    if (this.name === 'twitter') return
    const usageData = [ ...this.totalArchive[this.name]['geo_usage']]
    usageData.sort((a, b) => {
      return b.downloads - a.downloads
    })
    let others = { country_name: 'Others', downloads: 0 }
    usageData.forEach((data, index) => {
      if (index > 8) {
        others.downloads += +data.downloads
      } else {
        this.series.push(+data.downloads)
        this.chartOptions.labels.push(data.country_name)
      }
    })
    if (others.downloads) {
      this.series.push(others.downloads)
      this.chartOptions.labels.push(others.country_name)
    }
  }

}
</script>
