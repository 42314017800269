<template>
  <div class="home uk-container">
    <form class="uk-form-horizontal">
      <div 
        class="uk-flex uk-flex-wrap" 
        style="place-content: center;"
      >
        <EntityTip
          namespace="tgcp"
          type="Channel"
        >
          <template #input>
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              @keydown.enter.prevent="onSubmit"
            />
          </template>
        </EntityTip>
        <div class="uk-margin-medium-bottom uk-hidden@m uk-hidden@m uk-hidden@l uk-hidden@xl"/>
        <div class="uk-display-inline-block">
          <label
            v-if="0"
            class="uk-margin-small-left uk-margin-small-right"
          >
            <input
              v-model="query.bot_admin"
              type="checkbox"
              class="uk-checkbox"
              @change.prevent="onSubmit"
            />
            {{ $t('tgcp.channels.bot-admin') }}
          </label>
          <vueSelect
            v-model="currentBroadcast"
            :options="broadcastsComputed"
            label="label"
            class="uk-display-inline-block
          uk-form-width-small"
          />
          <vueSelect
            v-model="currentLanguage"
            :options="languagesComputed"
            label="label"
            class="uk-display-inline-block uk-form-width-small"
          />
          <vueSelect
            v-model="currentCountry"
            :options="countriesComputed"
            label="label"
            class="uk-display-inline-block uk-form-width-small"
          />
        </div>
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click.prevent="onSubmit"
        >
          <span uk-search-icon />
        </button>
      </div>
      <SearchInDbHint />
      <div
        class="uk-margin"
      >
        <label
          v-for="field in fields"
          :key="field"
        >
          <input
            v-model="query.search_fields"
            type="checkbox"
            class="uk-checkbox"
            :value="field"
          />
          {{ $t(`tgcp.filters.${field}`) }}
        </label>
      </div>
    </form>
    <div class="uk-margin">
      <div v-if="!isLoaded">
        <Loader />
      </div>
      <div v-else>
        <Items :channels="chats" />
        <div
          v-if="!hidePagination"
          class="uk-margin"
        >
          <ul class="uk-pagination uk-flex-center  uk-margin-bottom">
            <li
              :class="+pagination.page === 1 && 'uk-disabled'"
            >
              <a
                class="uk-position-center"
                @click="prevPage"
              >
                <span uk-pagination-previous/>
              </a>
            </li>
            <li
              class="uk-padding-remove"
            >
              <input
                v-model="goToPage"
                class="uk-input uk-text-center uk-form-blank uk-text-truncate"
                style="max-width: 250px; width: 40px"
                :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
                @change="setPage"
                @focus="$event.target.select()"
              />
            </li>
            <li :class="pagination.isNext ? 'uk-enabled' : 'uk-disabled'">
              <a
                class="uk-position-center"
                @click="nextPage"
              >
                <span uk-pagination-next/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <NotFound v-if="isLoaded && !chats.length" />
    <ExportDialog
      v-if="isLoaded && query.search"
      namespace="tgcp"
      type="channels"
      subtype="all"
      :search="query.search"
      :query="query"
      :totalCount="0"
    />
  </div>
</template>

<script>
import Items from '@/components/TGCP/ChannelList/Items.vue'
import Loader from '@/components/TGCP/Loader'
import countries from '@/utils/countryCodesNamesFull.json'
import langs from '@/utils/languages.json'
import vueSelect from '@/components/VueSelect'
import { mapGetters, mapActions } from 'vuex'
import { countryCodeToName } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ChannelList',
  components: {
    EntityTip: defineAsyncComponent(() => import('@/components/EntityTip/Tip.vue')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Items,
    Loader,
    vueSelect
  },
  data () {
    return {
      chats: [],
      countries: [],
      languageList: [],
      categories: [],
      fields: [
        'username',
        'name',
        'description',
        'joinlinks',
        'historical_names',
        'historical_usernames',
        'historical_descriptions'
      ],
      pagination: {
        page: 1,
        isNext: false
      },
      query: {
        search: this.$route.query.search || '',
        broadcast: this.$route.query.broadcast || '',
        limit: this.$route.query.limit || 21,
        offset: this.$route.query.offset || 0,
        full_match: this.$route.query.full_match,
        language_code: this.$route.query.language_code,
        country_code: this.$route.query.country_code,
        category_id: this.$route.query.category_id,
        search_fields: [],
        bot_admin: ''
      },
      goToPage: 1
    }
  },
  watch: {
    '$route.query' (to, from) {
      if (to.name === from.name) {
        if (to.language_code && to.language_code !== from.language_code) {
          this.query.language_code = to.language_code
          this.onSubmit()
        }
        if (to.broadcast && to.broadcast !== from.broadcast) {
          this.query.broadcast = to.broadcast
          this.onSubmit()
        }
        if (to.country_code && to.country_code !== from.country_code) {
          this.query.country_code = to.country_code
          this.onSubmit()
        }
        if (to.category_id && to.category_id !== from.category_id) {
          this.query.category_id = to.category_id
          this.onSubmit()
        }
      }
    },
    goToPage (newVal, oldVal) {
      if (!/^[0-9]*$/.test(newVal) || !+newVal) {
        this.goToPage = oldVal
      }
    },
    'pagination.page' (newVal) {
      this.goToPage = newVal
    },
    search: {
      immediate: false,
      handler (value) {
        globalThis.eventBus.$emit('EntityTip.id', value)
      }
    }
  },
  computed: {
    ...mapGetters('tgcp', ['allListChannels']),
    currentBroadcast: {
      get () {
        return this.query.broadcast
      },
      set (obj) {
        this.query.broadcast = obj && obj.value
        this.update()
      }
    },
    broadcastsComputed () {
      return [
        { value: '', label: this.$t('tgcp.filters.type') },
        { value: 'true', label: this.$t('tgcp.filters.channels') },
        { value: 'false', label: this.$t('tgcp.filters.chats') }
      ]
    },
    currentLanguage: {
      get () {
        return this.query.language_code
      },
      set (obj) {
        this.query.language_code = obj && obj.value
        this.update()
      }
    },
    languagesComputed () {
      const langs = this.languageList.map(lang => ({
        label: this.langName(lang),
        value: lang
      }))
      return [{ value: undefined, label: this.$t('tgcp.filters.language') }, ...langs].sort((a, b) => a?.label > b?.label)
    },
    currentCountry: {
      get () {
        return this.query.country_code
      },
      set (obj) {
        this.query.country_code = obj && obj.value
        this.update()
      }
    },
    countriesComputed () {
      const countries = this.countries.map(code => ({
        label: this.countryCodeToName(code),
        value: code
      })).sort((previous, next) => {
        const previousLabel = previous?.label
        const nextLabel = next?.label
        if (previousLabel < nextLabel) {
          return -1
        }
        if (previousLabel > nextLabel) {
          return 1
        }

        return 0
      })
      return [{ value: undefined, label: this.$t('tgcp.filters.select_country') }, ...countries]
    },
    isPrevPage () {
      return this.pagination.page === 1 ? 'uk-disabled' : 'uk-active'
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['channels']
    },
    hidePagination () {
      if (this.pagination.page === 1 && this.pagination.isNext === false) return true
      else return false
    },
    search () {
      return this.query?.search
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchAllChannels',
      'clearChannelsToView',
      'fetchChannelListLanguages',
      'fetchChannelListCountries',
      'fetchChannelListCategories'
    ]),
    countryCodeToName,
    async setPage ({ target }) {
      for (let i = 10; i > 0; i--) {
        this.query.offset = (this.query.limit - 1) * (--target.value)
        await this.update()
        if (this.chats.length) {
          this.goToPage = +target.value + 1
          this.pagination.page = +target.value + 1
          return
        }
      }
      this.query.offset = this.query.limit * (this.pagination.page - 1)
      await this.update()
      this.goToPage = this.pagination.page
      this.$notify({ group: 'general', title: `Page not found`, type: 'error' })
    },
    update () {
      if (this.query.offset < 0) {
        this.pagination.page = 1
        this.query.offset = 0
      }
      if (!this.query.bot_admin) this.query.bot_admin = undefined
      this.$router.push({ query: this.query })
      this.fetchAllChannels(this.query)
        .then(chats => {
          if (chats.length === Number(this.query.limit)) {
            this.pagination.isNext = true
            this.chats = chats.slice(0, -1)
          } else {
            this.pagination.isNext = false
            this.chats = chats
          }
        })
        .catch((err) => {
          this.chats = []
          try {
            if (err.response.status === 422) {
              Object.values(err.response.data.fields).forEach(texts => {
                texts.forEach(text => {
                  this.$notify({ group: 'general', title: this.$t('notifications.error'), text, type: 'error' })
                })
              })
            }
          } catch (error) {}
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        })
    },
    langName (code) {
      return langs[code].name
    },
    countryName (code) {
      return countries[code]
    },
    onSubmit () {
      this.query.offset = 0
      this.pagination.page = 1
      this.update()
    },
    nextPage () {
      this.query.offset = (this.query.limit - 1) * this.pagination.page
      this.pagination.page += 1
      this.update()
    },
    prevPage () {
      const offset = this.query.limit - 1
      this.query.offset = offset * this.pagination.page - offset * 2
      this.update()
      this.pagination.page -= 1
    },
    makingFile () {
      this.downloadcsv({ query: this.query, type: 'chats' })
    }
  },
  mounted () {
    this.fetchChannelListLanguages()
      .then(languages => {
        this.languageList = languages
      })
      .catch(() => {
        this.languageList = []
      })
    this.fetchChannelListCountries()
      .then(countries => {
        this.countries = countries
      })
      .catch(() => {
        this.countries = []
      })
    this.fetchChannelListCategories()
      .then(categories => {
        this.categories = categories
      })
      .catch(() => {
        this.categories = []
      })
    const offsetPassed = this.query.offset
    const page = Math.floor(offsetPassed / (this.query.limit - 1) + 1)
    this.goToPage = page || 1
    this.pagination.page = page || 1
    const searchFields = this.$route.query.search_fields
    if (searchFields) this.query.search_fields = searchFields && Array.isArray(searchFields) ? searchFields : [searchFields]
    this.update()
  },
  beforeUnmount () {
    this.clearChannelsToView()
  }
}
</script>
