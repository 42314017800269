<template>
  <div>
    <Loader v-if="isLoaded['topic_' + topicId] === false" />
    <NotFound v-else-if="err === 404" />
    <div v-else>
      <div
        class="uk-grid"
        data-uk-grid-margin
      >
        <div
          v-if="topic"
          class="uk-width-1-1@m uk-margin-small-top"
        >
          <Breadcrumbs
            v-if="topic.breadcrumbs && topic.breadcrumbs.length > 0"
            :topicId="topic.id"
            :entries="topic.breadcrumbs"
            :expanded="true"
          />
        </div>
        <div
          v-if="topic"
          class="uk-margin-small-top uk-width-expand"
        >
          <CustomForumsForumGeneralInfo
            v-if="!hasCustom"
            class="uk-margin-small-bottom"
            type="topic"
            :forum="topic"
          />
          <Item
            v-if="hasCustom"
            :topic="topic"
            :messagePage="messagePage"
            type="full"
            :isFromDetail="true"
            class="uk-margin-small-top"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import Item from '@/components/Forums/TopicList/Item.vue'
import { mapGetters, mapActions } from 'vuex'
import Breadcrumbs from '@/components/Forums/TopicDetails/Breadcrumbs'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ForumsTopicDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    Breadcrumbs,
    Item,
    CustomForumsForumGeneralInfo: defineAsyncComponent(() => import('@/components/Forums/ForumDetails/CustomGeneralInformation'))
  },
  props: {
    forumId: {
      required: true,
      type: [String, Number]
    },
    topicId: {
      required: true,
      type: [String, Number]
    },
    messagePage: {
      type: [Number, String]
    }
  },
  data () {
    return {
      activeTab: [this.$route.query.tab || 'messages_tab'],
      err: null
    }
  },
  watch: {
    topicId (from, to) {
      if (from !== to)
        this.update()
    }
  },
  computed: {
    ...mapGetters('forums', [
      'isLoaded',
      'allTopics'
    ]),
    topic () {
      return this.allTopics[this.topicId]
    },
    hasCustom () {
      return this.topic.users_count || this.topic.children_count || this.topic.messages_count
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchTopicById'
    ]),
    activateTab (tab) {
      tab = tab.target ? tab.target.id : tab
      if (!tab) return
      !this.activeTab.includes(tab) &&
        this.activeTab.push(tab)
    },
    update () {
      this.fetchTopicById(this.topicId)
        .then((topic) => {
          if (parseInt(topic.forum_id) !== parseInt(this.forumId)) {
            this.err = 404
            return
          }
          this.topic.messages_count !== 0
            ? this.activateTab('messages_tab')
            : this.activateTab('users_tab')
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.err = 404
          }
        })
    },
    checkForTab: function (tab) {
      if (tab === this.activeTab) {
        return 'uk-active'
      }
      if (tab === 'users' && this.topic.users_count === 0) return 'uk-disabled'
      if (tab === 'messages' && this.topic.messages_count === 0) return 'uk-disabled'
    }
  },
  mounted () {
    if (this.$route.params.tab) {
      this.activateTab(this.$route.params.tab)
    }
    this.$nextTick(() => this.update())
  }
}
</script>
