import {value as _} from './Utils'
// @ts-ignore
import gateway from '../../utils/gateway.js'
import {Message as TelegramMessage, Chat as TelegramChat, User as TelegramUser} from '../../types/telegram'
import {ITweetEntity as Tweet, IUserEntity as TwitterUser, IHashtagEntity as TwitterHashtag} from '../../types/twitter'
import {IPostEntity as Post, IUserEntity as TikTokUser, IHashtagEntity as TiktokHashtag} from '../../types/tiktok'
import {IUserEntity as HoopUser, IChannelEntity as HoopChannel, IMessageEntity as HoopMessage} from './../../types/hoop'
import {IFetcherEntity as FetcherUser} from './../../types/fetcher'
import {
  IMessageEntity as WhatsappMessage,
  IUserEntity as WhatsappUser,
  IGroupEntity as WhatsappGroup
} from './../../types/whatsapp'
import {
  IMessageEntity as ForumMessage,
  IGoodEntity as ForumGood,
  ITopicEntity as ForumTopic,
  IForumEntity as Forum,
  IUserEntity as ForumUser
} from './../../types/forums'
import {Value} from './Utils.d'
import { MessageEntity as DiscordMessage } from '@/types/discord'

namespace Schema {
  export type ArrayType<Interface, Union extends Object = Object> = (
    (
      Union extends Object ? keyof (
        Union & Interface
        ) : keyof Interface
      ) | Value.Schema.Transformer
    )[]
  export interface Interface {
    profile: String[],
    tgcp: {
      message: Schema.ArrayType<TelegramMessage, {
        fwd_channel_name: string,
        chatName: string,
        username: string,
        first_name: string,
        last_name: string,
        channelUsername: string
        link: string
      }>,
      user: Schema.ArrayType<TelegramUser, {
        admin_count: number,
        all_admin_count: number,
        all_chats_count: number,
        former_admin_count: number,
        former_chats_count: number,
        chats_count: number,
        media_messages_count: number,
        messages_count: number,
        join_date: string
      }>,
      channel: Schema.ArrayType<TelegramChat, {
        admins_count: number,
        all_admins_count: number,
        all_users_count: number,
        first_message_date: string,
        former_admins_count: number,
        former_users_count: number,
        last_message_date: string,
        last_sync: string,
        media_messages_count: number,
        messages_count: number,
        photos_count: number,
        users_count: number
      }>,
    },
    twitter: {
      tweet: Schema.ArrayType<Tweet, {
        source: unknown
      }>,
      user: Schema.ArrayType<TwitterUser, {
        url: unknown
      }>,
      hashtag: Schema.ArrayType<TwitterHashtag>
    },
    tiktok: {
      post: Schema.ArrayType<Post, {
        source: unknown
      }>,
      user: Schema.ArrayType<TikTokUser, {
        url: unknown
      }>,
      hashtag: Schema.ArrayType<TiktokHashtag>
    }
    whatsapp: {
      message: Schema.ArrayType<WhatsappMessage, {
        language: undefined
      }>,
      user: Schema.ArrayType<WhatsappUser, {
        activity: unknown,
        country: unknown
      }>,
      group: Schema.ArrayType<WhatsappGroup>
    },
    forums: {
      message: Schema.ArrayType<ForumMessage>,
      user: Schema.ArrayType<ForumUser>,
      forum: Schema.ArrayType<Forum>,
      topic: Schema.ArrayType<ForumTopic>,
      good: Schema.ArrayType<ForumGood>
    },
    discord: {
      message: Schema.ArrayType<DiscordMessage>
    },
    hoop: {
      message: Schema.ArrayType<HoopMessage, {
        avatar_url: string
      }>,
      user: Schema.ArrayType<HoopUser>,
      channel: Schema.ArrayType<HoopChannel, {
        avatar_url: string,
        avatars: {
          avatar_cdn_path: string
        }[]
      }>
    },
    case: String[],
    fetcher: Schema.ArrayType<FetcherUser>
  }
}
  
const schema: Schema.Interface = {
  profile: [
    'profile_id',
    'timestamp',
    'user_id',
    'name',
    'country',
    'seed_data',
    'createdAt',
    'updatedAt',
    'avatar',
    'lookup_history',
    'case_id',
    'in_progress',
    'deleted',
    'suggestions_count',
    'full_count',
    'tags'
  ],
  discord: {
    message: [
      'id',
      _<DiscordMessage, 'author'>('author', author => author ? author.global_name ? author.global_name : `${author.username}#${author.discriminator}` : ''),
      'author_id',
      'channel_id',
      'type',
      'content',
      'referenced_message_id',
      'activity',
      'mention_everyone',
      'pinned',
      'deleted',
      'deleted_timestamp',
      _<DiscordMessage, 'attachments'>('attachments', attachments => attachments ? attachments.map(v => v.url ? v.url + ',' : '').toString() : ''),
      _<DiscordMessage, 'embeds'>('embeds', embeds => embeds ? embeds.map(v => v.url ? v.url + ',' : '').toString() : ''),
      _<DiscordMessage, 'sticker_items'>('sticker_items', stickers => stickers ? stickers.map(v => v.url ? v.url + ',' : '').toString() : ''),
      'flags',
      'cdate',
      'timestamp',
      'sync_date'
    ]
  },
  tgcp: {
    message: [
      'chat_id',
      _<TelegramMessage, 'contacts'>('contacts', contacts => contacts ? contacts.map(v => v.phone_number ? gateway + v.phone_number + ',' : '').toString() : ''),
      'date',
      _<TelegramMessage, 'documents'>('documents', documents => documents ? documents.map(v => v.file_location).toString() : ''),
      'edit_date',
      'fwd_channel_name',
      'fwd_channel_id',
      'fwd_channel_post',
      'fwd_from_id',
      _<TelegramMessage, 'geolocation'>('geolocation', geolocation => geolocation ? geolocation.map(v => '(latitude: ' + (v.latitude ? v.latitude : '') + '; longitude: ' + (v.longitude ? v.longitude : '') + ')').toString() : ''),
      'message',
      'message_id',
      _<TelegramMessage, 'photos'>('photos', photos => photos ? photos.map(v => v.file_location).toString() : ''),
      'reply_msg_id',
      'user_id',
      'views',
      'chatName',
      'username',
      'first_name',
      'last_name',
      'channelUsername',
      'link'
    ],
    user: [
      'admin_count',
      'all_admin_count',
      'all_chats_count',
      'bio',
      'bot',
      'chats_count',
      'first_name',
      'former_admin_count',
      'former_chats_count',
      'last_name',
      'last_update',
      'media_messages_count',
      'messages_count',
      'status',
      'tg_id',
      'username',
      'was_online',
      _<TelegramUser, 'avatar'>('avatar', avatar => avatar && !avatar.file_location.startsWith(gateway) ? gateway + avatar.file_location : avatar ? avatar.file_location : ''),
      'join_date'
    ],
    channel: [
      _<TelegramChat, 'avatar'>('avatar', avatar => avatar ? avatar.file_location : ''),
      'admins_count',
      'all_admins_count',
      'all_users_count',
      'first_message_date',
      'former_admins_count',
      'former_users_count',
      'last_message_date',
      'last_sync',
      'media_messages_count',
      'messages_count',
      'photos_count',
      'users_count',
      'description',
      'name',
      'tg_id',
      'username',
      'creation_date',
      'scam',
      'verified'
    ]
  },
  tiktok: {
    user : [
      'apple_account',
      'avatar_url',
      'avatars',
      'bind_phone',
      'bio_url',
      'birthday',
      'cdate',
      'comments_count',
      'created_at',
      'follower_count',
      'follower_count_stats',
      'following_count',
      'following_count_stats',
      'friend_count',
      'gender',
      'google_account',
      'has_email',
      'has_facebook_token',
      'has_twitter_token',
      'id',
      'ins_id',
      'is_banned',
      'is_block',
      'is_blocked',
      'is_star',
      'likes_count_stats',
      'nickname',
      'posts_count',
      'posts_count_stats',
      'region',
      'signature',
      'signature_language',
      'sync_date',
      'twitter_id',
      'twitter_name',
      'unique_id',
      'youtube_channel_id',
      'youtube_channel_title'
    ],
    post: [
      'author_id',
      'aweme_type',
      'cdate',
      'comments_count',
      // 'cover : Object',
      'created_at',
      'description',
      'description_language',
      'duration',
      'follow_up_publish_from_id',
      'id',
      // 'interaction_stickers: Array',
      'is_ads',
      'is_delete',
      'is_description_translatable',
      'is_pgcshow',
      'is_private',
      'is_prohibited',
      'is_relieve',
      'is_story',
      'is_text_sticker_translatable',
      'is_top',
      'is_vr',
      // 'music_json : Object',
      'play_url_h264',
      'region',
      'sync_date'
    ],
    hashtag: [
      'id',
      'posts_count',
      'users_count',
      'value'
    ]
  },
  twitter: {
    tweet: [
      'cdate',
      'conversation_id',
      'created_at',
      'id',
      'lang',
      'place',
      'quoted_status_id',
      'reply_id',
      'retweet_id',
      'retweets_count',
      'source',
      'sync_date',
      'tweet_text',
      'user_id'
    ],
    user: [
      'avatar_url',
      _<TwitterUser, 'avatars'>('avatars', avatars => avatars.map(v => (!v.avatar_cdn_path?.startsWith(gateway) ? gateway + v.avatar_cdn_path : v.avatar_cdn_path) + ',').toString()),
      'background_url',
      'cdate',
      'created_at',
      'description',
      'id',
      'is_deleted',
      'is_private',
      'is_suspended',
      'is_verified',
      'location',
      'screen_name',
      'sync_date',
      'tweets_count',
      'url',
      'user_name'
    ],
    hashtag: [
      'id',
      'tweets_count',
      'users_count',
      'value'
    ]
  },
  whatsapp: {
    message: [
      'group_id',
      'id',
      'language',
      'media_key',
      'media_path',
      'media_thumb_path',
      'media_type',
      'media_url',
      'sender_phone',
      'text',
      'timestamp',
      'url',
      'url_description',
      'vcard'
    ],
    user: [
      'activity',
      _<WhatsappUser, 'avatars'>('avatars', avatars => avatars.map(v => v.url ? gateway + v.url + ',' : '').toString()),
      'country',
      'groups_count',
      'jid',
      'messages_count',
      'name',
      'owned_groups_count',
      'phone'
    ],
    group: [
      'admins_count',
      _<WhatsappGroup, 'avatars'>('avatars', avatars => avatars.map(v => v.url ? gateway + v.url + ',' : '').toString()),
      'creation',
      'desc',
      'desc_owner_phone',
      'desc_time',
      'id',
      'invite_hash',
      'joined',
      'members_count',
      'messages_count',
      'owner_phone',
      'subject',
      'subject_owner_phone',
      'subject_time',
      'superadmins_count',
      'updated'
    ]
  },
  forums: {
    message: [
      'id',
      'user_id',
      'forum_id',
      'topic_id',
      'timestamp',
      'url',
      'forum_message_id',
      'edit_comment',
      'message',
      'edit_date',
      _<{ attachments: { cdn_path: String }[] }, 'attachments'>('attachments', attachments => attachments.map(({ cdn_path }) => cdn_path ? (!cdn_path.startsWith(gateway) ? gateway + cdn_path : cdn_path) : '').toString()),
      'message_raw',
      'language',
      'username'
    ],
    user: [
      'age',
      'aim',
      _<ForumUser, 'avatar'>('avatar', avatar => avatar ? gateway + avatar : ''),
      'avatar_cdn_path',
      'avatar_original',
      _<ForumUser, 'avatars'>('avatars', avatars => avatars ? avatars.map(v => gateway + v.avatar_cdn_path).toString() : ''),
      'bio',
      'birthdate',
      'cdate',
      'discord',
      'display_name',
      'email',
      'first_name',
      _<ForumUser, 'forum'>('forum', forum => forum && forum.title ? forum.title : ''),
      'forum_user_id',
      'goods_count',
      'icq',
      'id',
      'ip',
      'jabber',
      'last_name',
      'last_update',
      'last_visit_date',
      'login',
      'messages_count',
      'other_json_user_information',
      'phone_number',
      'registration_date',
      'signature',
      'status',
      'telegram_user',
      'topics_count',
      'url',
      'user_group',
      'website'
    ],
    forum: [
      'id',
      'name',
      'title',
      'description',
      'image_url',
      'url',
      'is_darknet',
      _<Forum, 'languages'>('languages', languages => languages ? languages.toString() : ''),
      'users_count',
      'topics_count',
      'messages_count',
      'goods_count',
      'first_message_timestamp',
      'last_message_timestamp'
    ],
    topic: [
      'id',
      'forum_id',
      'parent_id',
      'name',
      'description',
      'creator_id',
      'forum_topic_id',
      'url',
      'is_forum',
      'first_message_timestamp',
      'last_message_timestamp',
      'username',
      'users_count',
      'messages_count',
      'children_count'
    ],
    good: [
      'id',
      'title',
      'user_id',
      'forum_id',
      'ext_id',
      'category',
      'description',
      'url',
      'cdate',
      'actual',
      'image_url',
      'image_original_url'
    ]
  },
  hoop: {
    message: [
      'id',
      'channel_id',
      'type',
      'author_id',
      'author_meteor_id',
      'author_display_name',
      'parent_id',
      'quoted_message_id',
      'text',
      'sticker_id',
      'timestamp',
      'likes',
      'cdate',
      'last_sync_date',
      'comment_count',
      _<HoopMessage, 'author'>('author', author => author?.display_name ? author.display_name.toString() : ''),
      _<HoopMessage, 'sticker'>('sticker', sticker => sticker?.cdn_path ? gateway + sticker.cdn_path : ''),
      _<HoopMessage, 'attachments'>('attachments', attachments => attachments ? attachments.map(v => v.cdn_path ? gateway + v.cdn_path + ',' : '').toString() : ''),
      _<HoopMessage, 'voice_note'>('voice_note', voice_note => voice_note?.cdn_path ? voice_note.cdn_path.toString() : ''),
      _<HoopMessage, 'quoted_message'>('quoted_message', quoted_message => quoted_message ? quoted_message.text : ''),
      'parent',
      _<HoopMessage, 'emojis'>('emojis', emojis => emojis ? emojis.map(v => v.cdn_path ? gateway + v.cdn_path + ',' : '').toString() : '')
    ],
    user: [
      'id',
      'meteor_id',
      'display_name',
      'jid',
      'avatar_url',
      'last_online',
      'cdate',
      'last_sync_date',
      'avatar_cdn_path',
      'messages_count',
      'channels_count',
      _<HoopUser, 'avatars'>('avatars', avatars => avatars ? avatars.map(v => v.avatar_cdn_path ? gateway + v.avatar_cdn_path + ',' : '').toString() : ''),
      _<HoopUser, 'names'>('names', names => names ? names.map(v => v.display_name ? v.display_name + ',' : '').toString() : ''),
      'is_virtual',
      'virtual_phone'
    ],
    channel: [
      'id',
      'parent_id',
      'type',
      'title',
      'description',
      'invite_link',
      'avatar_url',
      'cdate',
      'last_sync_date',
      'avatar_cdn_path',
      'messages_count',
      'subtopics_count',
      _<HoopChannel, 'tags'>('tags', tags => tags ? tags.map(v => v.value + ',').toString() : ''),
      _<{ avatars: { avatar_cdn_path: string }[] }, 'avatars'>('avatars', avatars => avatars.map(v => v.avatar_cdn_path ? gateway + v.avatar_cdn_path + ',' : '').toString())
    ]
  },
  case: [
    'case_id',
    'case_title',
    'case_description',
    'case_owner_id',
    'coordinates',
    'is_archived',
    'createdAt',
    'updatedAt',
    'case_settings',
    _<{ tags: { value: string }[] }, 'tags'>('tags', tags => tags.map(v => v.value + ',').toString()),
    'access_level'
  ],
  fetcher: [
    'avatar',
    'safeAvatar',
    'name',
    'title',
    'fullName',
    'externalLink',
    'noInfo',
    'bio',
    'sex',
    'age',
    'phone',
    'password',
    'passwordHash',
    'birthday',
    'email',
    'user_id',
    'ip',
    'status',
    'type',
    'subtype',
    'genericType',
    'location',
    'providerId',
    'module',
    'cached',
    'archived',
    'ext_id'
  ]
}

export default schema
