<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              type="submit"
              class="uk-button uk-button-primary uk-search-icon"
            >
              <span uk-search-icon />
            </button>
          </div>
          <div class="uk-display-inline-block">
            {{ $t('twitter.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block">
            {{ $t('imo.sort.from') }}
            <flatPickr
              v-if="!hideDate"
              v-model="query.from_date"
              :placeholder="$t('tgcp.messages.from_date')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              :config="{
                maxDate: query.to_date || new Date()
              }"
            />
          </div>
          <div 
            class="uk-display-inline-block"
            style="padding-left: 0.5em;"
          >
            {{ $t('imo.sort.to') }}
            <flatPickr
              v-if="!hideDate"
              v-model="query.to_date"
              :placeholder="$t('tgcp.messages.to_date')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              :config="{
                minDate: query.from_date || '2010-05-11',
                maxDate: new Date()
              }"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <template v-else>
          <MessageCard
            v-for="message in messages"
            :key="String(message.msg_seq) + message.bgid"
            :data-scroll-into-view="message.msg_seq"
            class="uk-margin-top"
            :message="message"
            :searchText="query.search"
          />
        </template>
      </div>
      <div 
        v-show="!hidePagination"
        style=""
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import { countryCodeToName } from '@/filters/globalFilters'
import flatPickr from 'vue-flatpickr-component'
import Paginate from '@/components/Paginate.vue'

export default {
  name: 'ImoMessageList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    MessageCard: defineAsyncComponent(() => import('@/components/Imo/MessageList/MessageCard')),
    Paginate,
    vueSelect,
    flatPickr,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'group', 'user'].includes(val)
    }
  },
  data () {
    return {
      messages: [],
      hideDate: false,
      orderList: ['created_at'],
      searchList: [null, 'text', 'image', 'video', 'file', 'universal_card', 'contact', 'group_invite', 'group_notification', 'deleted', 'unrecognized'],
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'created_at',
        order_type: this.$route.query.order_type || 'desc',
        types: this.$route.query.types || null,
        search: this.$route.query.search || '',
        from_date: this.$route.query.from_date || null,
        to_date: this.$route.query.to_date || null
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.update()
    },
    'query.from_date': {
      handler () {
        return this.setPage(1)
      }
    },
    'query.to_date': {
      handler () {
        return this.setPage(1)
      }
    }
  },
  computed: {
    ...mapGetters('imo', ['isLoaded']),
    hasDataToView () {
      return this.messages.length > 0
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded[`${this.type}_${this.id}_messages`] : this.isLoaded['messages']
    },
    currentOrderBy: {
      get () {
        return this.order
      },
      set (obj) {
        this.order = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return [
        { value: 'asc-created_at', label: this.$t('imo.sort.created_at_asc') },
        { value: 'desc-created_at', label: this.$t('imo.sort.created_at_desc') }
      ]
    },
    order: {
      get () {
        return this.query.order_type + '-' + this.query.order_by
      },
      set (value) {
        [this.query.order_type, this.query.order_by] = value.split('-')
      }
    }
  },
  methods: {
    ...mapActions('imo', ['fetchAllMessages', 'addParamToQuery']),
    countryCodeToName,
    async init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      await this.update(true)
    },
    async setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      await this.update()
    },
    setData (messages) {
      this.messages = messages.data
      this.pagination.total = messages.meta.results_count
    },
    searchHandler () {
      this.setPage(1)
    },
    handleError () {
      this.messages = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    async update () {
      if (this.type === 'all') {
        await this.addParamToQuery(this.query)
      }
      this.loadMessages()
    },
    loadMessages () {
      let { type } = this
      const data = {
        id: this.id,
        query: { ...this.query },
        type
      }

      return this.fetchAllMessages(data)
        .then(messages => this.setData(messages))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped lang="scss">

.target-message {
  transition: .5s;
  animation: pulse 1.2s infinite;
  animation-iteration-count: 5;
}
</style>
