<template>
  <div class="uk-card uk-card-default uk-card-small">
    <div class="uk-card-body">
      <div
        class="uk-grid-small uk-flex-middle"
        uk-grid
      >
        <div class="uk-width-1-1">
          <div class="uk-align-right my-margin-remove-bottom">
            <AddStoredButton
              v-if="showStoredButton"
              provider="whatsapp"
              type="message"
              :data="message"
            />
          </div>
          <span
            v-if="message.group"
            class="uk-align-left uk-text-small uk-text-muted my-margin-remove-bottom"
          >
            (
            <router-link
              :to="{
                name: 'whatsapp_group_details',
                params: { id: message.group_id }}"
            >
              {{ message.group.subject || message.group_id }}
            </router-link>) #{{ message.id || message.message_id }}
          </span>
          <span
            class="uk-align-right uk-text-right uk-margin-remove uk-text-muted"
          >
            {{ dayjs(message.timestamp) }}
          </span>
        </div>
        <div class="uk-width-1-6@m uk-text-center">
          <GeneralAvatar :src="avatar"/>
          <span
            class="uk-text-small uk-text-left uk-margin-remove-top uk-margin-remove-bottom uk-text-muted"
          >
            <router-link
              :to="{
                name: 'whatsapp_user_detail',
                params: { phone: String(message.sender || message.sender_phone) }}"
            >
              {{ normalizeWAPhone(message.sender || message.sender_phone) }}
            </router-link>
          </span>
        </div>
        <div class="uk-width-2-3@m">
          <p
            v-if="message.text"
            class="uk-margin uk-text-left uk-text-break"
            style="white-space: pre-line"
          >
            <span v-html="extractedText"/>
          </p>
          <Media :message="message" />
          <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-top">
            <li v-if="message.owner_phone">
              {{ $t('twitter.messages.owner-phone') }}
              {{ message.owner_phone }}
            </li>
            <li v-if="message.invite_hash">
              {{ $t('twitter.messages.invite-hash') }}
              {{ message.invite_hash }}
            </li>
            <li v-if="message.creation">
              {{ $t('twitter.messages.creation') }}
              {{ dayjs(message.creation) }}
            </li>
          </ul>
        </div>
        <div class="uk-width-1-6@m my-flex-top">
          <div>
            <ul class="uk-list uk-list-divider">
              <li>
                <GoToMessage
                  v-if="message"
                  class="uk-margin-small-right"
                  :message="message"
                />
              </li>
              <li>
                <button
                  class="uk-button-text uk-button"
                  @click="translate"
                >
                  <font-awesome-icon :icon="['fa', 'language']" />
                  <span v-if="translatedShown">
                    {{ $t('twitter.messages.show-original') }}
                  </span>
                  <span v-else>
                    {{ $t('twitter.messages.translate') }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '@/config'
import textHighlight from '@/utils/textHighlight'
import extractData from '@/utils/dataExtraction'
import { apiRequest } from '@/utils/api'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import GeneralAvatar from '@/components/GeneralAvatar'
import Media from '@/components/WhatsApp/Media'
import { normalizeWAPhone } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'WhatsappMessageItem',
  components: {
    AddStoredButton,
    GeneralAvatar,
    Media,
    GoToMessage: defineAsyncComponent(() => import('@/components/WhatsApp/MessagesList/GoToMessage'))
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      translatedText: false,
      translatedShown: false
    }
  },
  computed: {
    ...mapGetters('whatsapp', ['allGroups']),
    avatar () {
      // eslint-disable-next-line camelcase
      if (!this.message?.author || !this.message?.author?.avatar_cdn_path) return false
      // eslint-disable-next-line camelcase
      return this.$gateway(this.message?.author?.avatar_cdn_path)
    },
    extractedText () {
      if (this.translatedShown) {
        return extractData(this.translatedText)
      }
      var message = textHighlight(this.message.text, this.$route.query.search)
      return extractData(message)
        .trim()
    }
  },
  methods: {
    ...mapActions('whatsapp', ['fetchGroupByID', 'fetchUserByPhone']),
    normalizeWAPhone,
    translate () {
      if (!this.translatedShown) {
        if (!this.translatedText && this.message.text) {
          const translateTo = this.$store.state.settings.translate
          let translateText = this.message.text.replace(/<[^>]*>?/gm, '')
          apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, { lang: translateTo, text: translateText })
            .then(data => {
              this.translatedText = data.data.translation
              this.translatedShown = true
            })
            .catch(err => {
              console.debug(err)
              // reject(err)
            })
        } else {
          this.translatedShown = true
        }
      } else {
        this.translatedShown = false
      }
    },
    normalizePhone (value) {
      if (!value) return ''
      value = value.replace('@c.us', '')
      if (value.indexOf('+') !== 0) value = '+' + value
      return value
    }
  },
  mounted () {
    if (this.message.group_id.indexOf('@s.whatsapp.net') < 0) {
      this.fetchGroupByID(this.message.group_id)
    }
    this.fetchUserByPhone(this.message.sender_phone)
  }
}
</script>
<style scoped>
.my-flex-top {
  align-self: start
}
.my-margin-remove-bottom {
  margin-bottom: 0 !important
}
</style>
