<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div class="uk-margin">
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
        >
          <div class="uk-display-inline-block">
            <input
              v-model.trim="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              @keyup.enter="setPage(1)"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="setPage(1)"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block">
            <vueSelect
              v-if="ownerCountries.length"
              v-model="currentCountry"
              :options="sortedOwnerCountries"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!isLoaded['groups']">
        <Loader />
      </div>
      <div v-else>
        <template v-if="isLoaded['groups'] && groups.length === 0">
          <NotFound class="uk-align-center"/>
        </template>
        <template v-else>
          <Item
            v-for="group in groups"
            :key="group.id"
            class="uk-margin-top"
            :group="group"
          />
        </template>
      </div>
      <div
        v-if="!hidePagination"
        class="uk-margin"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <ExportDialog
      v-if="showExport"
      :id="userPhone"
      namespace="whatsapp"
      type="groups"
      :search="query.search"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedGroups"
      @deselect="selectedGroups = []"
    />
  </div>
</template>

<script>
import Item from '@/components/WhatsApp/GroupsList/Item'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import vueSelect from '@/components/VueSelect'
import { mapActions, mapGetters } from 'vuex'
import { countryCodeToName } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'GroupsList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item,
    Loader,
    Paginate,
    vueSelect
  },
  props: {
    userPhone: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'owner'].includes(val)
    }
  },
  data () {
    return {
      groups: [],
      orderList: ['id', 'owner_phone', 'subject', 'creation', 'members_count', 'messages_count'],
      ownerCountries: [],
      selectedGroups: [],
      query: {
        limit: 20,
        offset: 0,
        search: '',
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || 'desc',
        owner_country: this.$route.query.owner_country || undefined
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters('whatsapp', ['isLoaded']),
    sortedOwnerCountries () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this?.ownerCountries?.sort((previous, next) => {
        const previousLabel = previous?.label
        const nextLabel = next?.label
        if (previousLabel < nextLabel) {
          return -1
        }
        if (previousLabel > nextLabel) {
          return 1
        }

        return 0
      })
    },
    showExport () {
      if (this.type === 'all' && this.query.search && this.groups.length) {
        return true
      }
      if (this.type !== 'all' && this.groups.length) {
        return true
      }
      return false
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.setPage(1)
      }
    },
    currentCountry: {
      get () {
        return this.query.owner_country
      },
      set (obj) {
        this.query.owner_country = obj && obj.value
        this.setPage(1)
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.setPage(1)
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('whatsapp.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    hidePagination () {
      return this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('whatsapp', ['fetchAllGroups', 'addParamToQuery', 'fetchUserGroups', 'fetchUserOwnedGroups']),
    countryCodeToName,
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.type === 'all' &&
        this.addParamToQuery({
          page: this.page,
          [Symbol('hasPush')]: false
        })
      this.update()
    },
    setData (res) {
      this.groups = res.data
      this.pagination.total = res.meta.results_count
      if (res.meta.owner_countries) {
        this.ownerCountries = res.meta.owner_countries.map(el => {
          return {
            label: this.countryCodeToName(el.owner_country_name),
            value: el.owner_country_name,
            second: this.$t('twitter.group-list.count') + ': ' + el.count
          }
        }).sort((a, b) => a.label > b.label)
        this.ownerCountries.unshift({ label: this.$t('twitter.group-list.select-owner-country'), value: undefined })
      } else {
      }
    },
    clearData () {
      this.groups = []
      this.pagination.page = 1
      this.pagination.total = null
      this.ownerCountries = []
    },
    async update () {
      try {
        if (this.type === 'all') {
          this.addParamToQuery(this.query)
          return this.setData(await this.fetchAllGroups(this.query))
        } else if (this.type === 'user') {
          return this.setData(
            await this.fetchUserGroups({
              query: this.query,
              phone: this.userPhone
            })
          )
        } else if (this.type === 'owner') {
          return this.setData(
            await this.fetchUserOwnedGroups({
              query: this.query,
              phone: this.userPhone
            })
          )
        }
      } catch (err) {
        console.debug(err)
        this.clearData()
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      }
    }
  },
  created () {
    this.setPage(Number(this.$route.query.page) || 1)
  }
}
</script>
