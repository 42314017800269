<template>
  <div
    class="uk-card uk-card-default"
  >
    <div
      class="uk-margin-right uk-width-1-1 uk-card-header uk-padding-small uk-inline"
    >
      <span
        v-if="data.suggestions_count && data.suggestions_count > 0"
        class="uk-badge uk-position-top-right off-card"
        :uk-tooltip="$t('cases.profile.new-suggestions')"
      >
        <router-link
          style="color: white;"
          :to="{
            name: 'case_profile_suggestions',
            params: {
              caseId: caseId,
              profileId: data.profile_id
            }
          }"
        >
          {{ data.suggestions_count }}
        </router-link>
      </span>
      <div
        class="uk-align-left"
        style="margin-bottom: 0;"
      >
        {{ dayjs(data.updatedAt || data.createdAt) }}
      </div>
      <div
        v-if="allCases[caseId]"
        class="uk-margin-small-left uk-align-right"
      >
        <span
          v-if="['rw','f'].includes(allCases[caseId].access_level)"
          class="uk-margin-small-right"
        >
          <router-link
            uk-icon="pencil"
            :to="{name: 'case_profile_details',
                  params: {
                    profileId: data.profile_id,
                    caseId: caseId
                  }
            }"
          />
        </span>
        <button
          type="button"
          class="uk-icon uk-margin-small-right"
        >
          <span
            class="uk-disabled uk-text-muted"
            uk-icon="icon: social"
          />
        </button>
        <span
          v-if="['rw','f'].includes(allCases[caseId].access_level)"
          class=""
        >
          <button
            class="uk-button uk-button-text "
            type="button"
            title="Delete"
            @click="deleteProfile"
          >
            <span
              uk-icon="trash"
            />
          </button>
        </span>
      </div>
    </div>
    <div >
      <div class="uk-margin-small" >
        <div
          class="uk-grid-collapse uk-grid"
        >
          <div class="uk-width-1-3@m uk-first-column">
            <div class=" uk-margin-bottom uk-text-left">
              <router-link :to="{name: 'case_profile_details', params: {profileId: data.profile_id, caseId: caseId}}">
                <div
                  class="uk-padding-small  uk-margin-small-left uk-margin-small-top
                            uk-position-relative uk-visible-toggle"
                >
                  <GeneralAvatar :src="data.avatar"/>
                </div>
              </router-link>
            </div>
          </div>
          <div class="uk-width-2-3@m">
            <div class="uk-text-left uk-padding-small">
              <router-link
                v-if="data.name"
                :to="{name: 'case_profile_details', params: {profileId: data.profile_id, caseId: caseId}}"
                class="el-title uk-h3 uk-margin-remove-bottom  uk-text-break"
              >
                {{ data.name }}
              </router-link>
              <div class="uk-margin-small-top uk-margin-remove-bottom">
                <span class="uk-text-small uk-text-break">
                  {{ $t('cases.seed-data') }} {{ data.seed_data }}
                </span>
              </div>
              <router-link
                :to="{name: 'case_profile_details', params: {profileId: data.profile_id, caseId: caseId}}"
                class="uk-button uk-button-text"
              >
                {{ $t('cases.details') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="data.tags && data.tags.length"
      class="uk-card-footer uk-align-center  uk-margin-remove-top"
    >
      <span
        v-for="tag in data.tags"
        :key="tag.id"
        class="uk-margin-small-left tag small-left uk-badge"
        @click="$emit('tag', tag.tag_name)"
      >
        {{ tag.tag_name }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GeneralAvatar from '@/components/GeneralAvatar'

export default {
  name: 'ProfileCard',
  components: {
    GeneralAvatar
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    caseId: {
      type: String
    }
  },
  data () {
    return {
      comment: ''
    }
  },
  computed: {
    ...mapGetters('cases', [
      'allCases'
    ])
  },
  methods: {
    deleteProfile () {
      this.$store.dispatch('cases/deleteProfile',
                           {
                             profileId: this.data.profile_id,
                             caseId: this.caseId
                           })
        .then(() => this.$emit('deleteProfile'))
    }
  }
}
</script>

<style>
.tag {
  word-break: break-all;
  max-width: 100%;
  height: 100%;
  padding: 0px 10px 0px 10px;
  min-width: 40px;
  cursor: pointer;
}
.off-card {
  top: -10px;
  right: -10px;
}
</style>
