<template>
  <div class="uk-flex uk-flex-column">
    <template v-if="!isLoaded">
      <div class="uk-flex uk-flex-center">
        <Loader :ratio="2"/>    
      </div>
    </template>
    <template v-else>
      <div 
        v-for="(value, key) in getTonStats"
        :key="key"
        class="uk-margin-remove uk-padding-remove uk-width-1-1"
      >
        <hr style="margin: 0.7rem 0"/>
        <div 
          class="uk-text-medium uk-flex" 
          style="justify-content: space-between;"
        >
          <div>
            {{ $t(`crypto.stats.${key}`) }}
          </div>
          <div>
            {{ `${value}` }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters } from 'vuex'

export default {
  name: '',
  components: {
    Loader
  },
  computed: {
    ...mapGetters('crypto', ['getTonStats']),
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_stats']
    }
  }
}
</script>