<template>
  <div>
    <div
      v-if="stats.all_admin_count"
      class="uk-card uk-card-default uk-margin-bottom"
    >
      <div class="uk-card-header">
        <h3 class="uk-card-title">
          {{ $t('tgcp.user_details.admin') }}
        </h3>
      </div>
      <div class="uk-card-body uk-padding-small">
        <table class="uk-table  uk-table-hover uk-table-divider uk-table-justify uk-table-small">
          <tr
            v-if="stats.admin_count"
            class="links uk-padding-small"
            @click="changeRoute('tgcp_user_detail_admin')"
          >
            <td>
              {{ $t('tgcp.user_details.current') }}
            </td>
            <td>
              <span class="uk-badge">
                {{ stats.admin_count }}
              </span>
            </td>
          </tr>
          <tr
            v-if="stats.former_admin_count"
            class="links uk-padding-small"
            @click="changeRoute('tgcp_user_detail_past_admin')"
          >
            <td>
              {{ $t('tgcp.user_details.past') }}
            </td>
            <td>
              <span class="uk-badge">
                {{ stats.former_admin_count }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      v-if="stats.all_chats_count"
      class="uk-card uk-card-default uk-margin-bottom"
    >
      <div class="uk-card-header">
        <h3 class="uk-card-title">
          {{ $t('tgcp.user_details.participant') }}
        </h3>
      </div>
      <div class="uk-card-body uk-padding-small">
        <table class="uk-table uk-table-hover uk-table-divider uk-table-justify uk-table-small">
          <tr
            v-if="stats.chats_count"
            class="links uk-padding-small"
            @click="changeRoute('tgcp_user_detail_channels')"
          >
            <td>
              {{ $t('tgcp.user_details.current') }}
            </td>
            <td>
              <span class="uk-badge">
                {{ stats.chats_count }}
              </span>
            </td>
          </tr>
          <tr
            v-if="stats.former_chats_count"
            class="links uk-padding-small"
            @click="changeRoute('tgcp_user_detail_past_channels')"
          >
            <td>
              {{ $t('tgcp.user_details.past') }}
            </td>
            <td>
              <span class="uk-badge">
                {{ stats.former_chats_count }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String
    }
  },
  computed: {
    user () {
      return this.$store.state.tgcp.users[this.id]
    },
    stats () {
      return this.user.stats
    }
  },
  methods: {
    changeRoute (routeName) {
      this.$router.push({
        name: routeName
      })
    }
  }
}
</script>

<style scoped>
.links {
  cursor: pointer;
}

.uk-card-primary .uk-table-hover tr:hover {
  background: adjust-hue(#1e87f0, -2);
}
</style>
