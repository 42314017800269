<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import { MarkerClusterGroup } from 'leaflet.markercluster'
import { findRealParent, propsBinder } from 'vue2-leaflet'
import { DomEvent } from 'leaflet'

const props = {
  options: {
    type: Object,
    default: () => ({})
  },
  defaultVisible: {
    type: Boolean,
    required: false,
    default: true
  }
}

export default {
  props,
  data () {
    return {
      ready: false
    }
  },
  methods: {
    addLayer (layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject)
      }
    },
    removeLayer (layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject)
      }
    }
  },
  mounted () {
    this.mapObject = new MarkerClusterGroup(this.options)
    DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, props)
    this.ready = true
    this.parentContainer = findRealParent(this.$parent)
    // eslint-disable-next-line
    this.defaultVisible ? this.parentContainer.addLayer(this) : null
  },
  beforeUnmount () {
    this.parentContainer.removeLayer(this)
  }
}
</script>
