import config from './{ENV}.json'
import whiteLabel from './whitelabel.json'
import gateways from './gateways'
let whiteLabelDefaults = {
  'contactEmail': 'info@asiris.com',
  'disableModules': [],
  'logoUrl': '/img/logo.png',
  'showLogoOnLoginPage': false,
  'siteTitle': 'ASIRIS',
  'hideWhatIsAsiris': false,
  'manualLanguages': false,
  'manualTitle': 'ASIRIS_Platform_Operator_Manual_ENG.pdf'
}
let domain = window.location.hostname

for (let i in config['servers']) {
  if (config['servers'][i].indexOf('http') !== -1) {
    continue
  }
  config['servers'][i] = gateways[domain]
    ? gateways[domain] + config['servers'][i]
    : gateways['default'] + config['servers'][i]
}

if (whiteLabel[domain]) {
  for (let i in whiteLabelDefaults) {
    config[i] = whiteLabel[domain][i]
  }
} else {
  for (let i in whiteLabelDefaults) {
    config[i] = whiteLabelDefaults[i]
  }
}

export default config
