<template>
  <vSelect
    ref="vselect"
    v-model="active"
    :class="{ 'vue-select__disabled' : disabled, 'vue-select__taggable': taggable }"
    :options="options"
    :placeholder="placeholder"
    :searchable="searchableComputed"
    :clearable="clearable"
    :dir="dir"
    :taggable="taggable"
    :multiple="multiple"
    :closeOnSelect="closeOnSelect"
    :loading="loading"
    :filterable="filterable"
    @search:blur="blurHandler"
    @search:focus="focusHandler"
    @option:created="cratedHandler"
  >
    <template
      #no-options
    >
      {{ $t('select.no-matching-options') }}
    </template>

    <template
      #option="{ disabled, label, second }"
      :disabled="option.disabled"
    >
      <template
        v-if="!disabled"
      >
        <ScrollToComponent
          v-if="label === active"
          ref="element"
          class="vs__active"
        >
          <div
            class="option-label"
          >
            {{ label }}
          </div>
          <div
            class="text-muted"
          >
            {{ second }}
          </div>
        </ScrollToComponent>
        <div v-else>
          <div>
            {{ label }}
          </div>
          <div
            class="text-muted"
          >
            {{ second }}
          </div>
        </div>
      </template>
      <div
        v-else
        class="disabled-option"
        @click.stop.prevent
      >
        <div>
          {{ label }}
        </div>
        <div
          class="text-muted"
        >
          {{ second }}
        </div>
      </div>
    </template>

    <template
      #search="{attributes, events}"
    >
      <input
        v-bind="attributes"
        class="vs__search"
        :required="false"
        @input="inputHandler"
        v-on="events"
      />
    </template>
  </vSelect>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ScrollToComponent from '@/components/ScrollToComponent'

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  components: {
    vSelect,
    ScrollToComponent
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: null,
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    label: {
      required: false,
      default: null
    },
    scrollToActive: {
      required: false,
      type: Boolean,
      default: true
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    },
    searchable: {
      required: false,
      type: Boolean,
      default: null
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    showSelectedTag: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    searchMethod: {
      type: Function,
      default: Function
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      dir: 'auto' // Sets RTL support: ltr, rtl, auto
    }
  },
  computed: {
    active: {
      get () {
        if (this.taggable && !this.showSelectedTag) {
          return null
        }
        const obj = this.options.find(obj => obj.value === this.modelValue)
        return (obj && obj.label) || null
      },
      set (obj) {
        if (obj.disabled) return
        if (this.taggable) {
          return obj.value && this.$emit('update:modelValue', obj)
        }
        this.$emit('update:modelValue', obj)
        this.initTags()
      }
    },
    searchableComputed () {
      return this.searchable === null ? this.options.length > 5 : this.searchable
    }
  },
  methods: {
    async initTags () {
      await this.$nextTick()
      if (!this.taggable) return
      const vs = this.$refs.vselect
      const tagClickHandler = () => {
        vs.deselect(event.target.innerText)
        vs.toggleDropdown(vs.$el)
        return false
      }
      vs && vs.$el.querySelectorAll('.vs__selected').forEach(el => {
        el.removeEventListener('click', tagClickHandler, true)
        el.addEventListener('click', tagClickHandler, true)
      })
    },
    inputHandler ({ target, target: { value } }) {
      this.fixDirection(value, target)
      this.searchMethod(value)
    },
    fixDirection (value, target) {
      const setDirection = dir => {
        target.style.direction = dir
        this.dir = dir
      }
      const rtlChar = /[\u0590-\u083F]|[\u08A0-\u08FF]|[\uFB1D-\uFDFF]|[\uFE70-\uFEFF]/gm
      const isRTL = value.match(rtlChar)
      if (isRTL !== null) {
        return setDirection('rtl')
      }
      setDirection('ltr')
    },
    blurHandler () {
      if (this.taggable) return
      if (this.searchableComputed) {
        const el = this.$refs.vselect.$el
        try {
          el.querySelector('.vs__selected').style.display = ''
        } catch (error) {
          console.debug(error)
        }
      }
      this.dir = 'auto'
    },
    focusHandler () {
      if (this.taggable) return
      if (this.searchableComputed) {
        const el = this.$refs.vselect.$el
        try {
          el.querySelector('.vs__selected').style.display = 'none'
        } catch (error) {
          console.debug(error)
        }
      }
      if (this.scrollToActive && this.options.length > 5) this.$nextTick(this.scroll)
    },
    cratedHandler ({ label: value = '' } = {}) {
      value = value || arguments[0]
      if (value.trim()) this.$emit('update:modelValue', { value })
      else if (this.showSelectedTag) {
        this.$emit('update:modelValue', { value: '' })
      }
    },
    scroll () {
      if (this.$refs.element) {
        const { scrollX, scrollY } = window
        this.$refs.element.scrollTo()
        window.scrollTo(scrollX, scrollY)
      }
    }
  },
  mounted () {
    this.initTags()
  }
}
</script>

<style lang="scss">
.v-select {
  background-color: white;
  vertical-align: middle;
  // width: 100%;
  min-width: 150px;
  // width: fit-content;
  .disabled-option {
    //pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
  }
  .vs {
    &__dropdown-toggle {
      height: 40px;
      //min-width: 30px;
      text-align: left;
      //text-overflow: ellipsis;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__selected {

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__selected-tag {
      // overflow: hidden;
      // width: 80%;
      // text-overflow: ellipsis;
      //  white-space: nowrap;
    }

    &--open input[type='search'] {
      position: unset;
      width: auto !important;
    }
    &__active {
      color: #1e87f0;
    }
    &__dropdown-option.vs__dropdown-option--selected.vs__dropdown-option--highlight {
      background-color: #f0f0f0;
    }
    &__actions {
      justify-content: center;
      width: 24px; // 2em;
      position: absolute;
      right: 1px;
      top: 4px;
      height: 30px;
      text-align: center;
      background: white;
    }
    &__dropdown-menu {
      overflow-x: auto;
      // width: max-content;
      // min-width: 100%;
      // min-width: 150px;
      padding: 5px 0 !important;
      max-height: 300px;
    }
    &__dropdown-option {
      text-indent: 0px !important;
      overflow: hidden;
      padding: 5px !important;
      white-space: normal;
      text-overflow: ellipsis !important;
    }

    &__deselect {
      z-index: 999 !important;
      transition: .3s;
      :hover {
        transform: scale(1.1);
      }
    }
    &__selected-options {
      padding-right: 35px;
    }
  }

  input[type='search'] {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
  }

  .text-muted {
    color: #ccc;
  }
  ul {
    padding-left: 0px;
  }
}

.vue-select {
  &__disabled * {
    pointer-events: none;
    color: #ccc;
  }
  &__taggable .vs__search {
    font-weight: 300;
    // font-style: italic;
    color: #ccc;
  }
}

.vs__fade {
  &-enter-active,
  &-leave-active {
    transition: opacity .3s;
  }
  &-enter-from,
  &-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
}
</style>
