<template>
  <div>
    <div class="uk-card uk-card-default uk-card-body">
      <h3>
        {{ $t('whatsapp.tasks.add_task_title') }}
      </h3>
      <form
        class="uk-width-1-1 uk-float-right"
        @submit.prevent="onSubmit"
      >
        <Loader v-if="isLoading" />
        <div v-else>
          <div class="uk-flex uk-flex-middle uk-width-1-1 ">
            <input
              id="link-input"
              v-model.trim="task"
              type="text"
              :class="hasErrors"
              :uk-tooltip="`title:${$t('whatsapp.tasks.task_field_title') }; pos:bottom`"
              placeholder="https://chat.whatsapp.com/J2i..."
              class="uk-input "
            />
            <label
              v-if="isTaskExist"
              for="link-input"
              class="uk-margin-left"
              :uk-tooltip="`title: ${$t('whatsapp.tasks.task_exists')}`"
              uk-icon="icon: warning; ratio: 1"
            />
          </div>
          <textarea
            v-model="description"
            :placeholder="$t('whatsapp.tasks.description_field_placeholder')"
            class="uk-textarea uk-margin-top task-description"
          />
          <h6>
            {{ $t('whatsapp.tasks.options').toUpperCase() }}
          </h6>
          <div class="uk-margin uk-flex uk-flex-middle uk-flex-center">
            <div
              :uk-tooltip="`title: ${$t('tgcp.tasks.priority_field_title')}`"
              @click="priority = !priority"
            >
              <a
                uk-icon="icon: warning; ratio: 1"
                value="null"
                :class="priority ? 'uk-text-primary' : 'uk-text-muted'"
              />
            </div>
          </div>

          <div
            uk-grid
            class="uk-grid-small"
          >
            <div class="uk-width-1-2"/>
            <hr />
            <div class="uk-width-1-1 uk-float-right">
              <button
                :disabled="isLoading || !isValidLink || !task.length"
                class="uk-button uk-button-primary uk-width-1-1"
              >
                <span
                  v-if="isLoading"
                  uk-spinner
                />
                {{ $t('whatsapp.tasks.add_btn_title') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/WhatsApp/Loader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WhatsappAddTaskForm',
  components: {
    Loader
  },
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      priority: false,
      task: this.link,
      raw_task: '',
      description: '',
      error: '',
      isLoading: false
    }
  },
  watch: {
    task () {
      this.error = ''
    }
  },
  computed: {
    ...mapGetters('whatsapp', ['allTasks']),
    isTaskExist () {
      return !!Object.values(this.allTasks).find(({ joinlink }) => this.task.replace('/invite', '') === joinlink.replace('/invite', ''))
    },
    isValidLink () {
      // eslint-disable-next-line no-useless-escape
      const regexp = /^(http(s)?:\/\/)?chat\.whatsapp\.com(\/invite)?\/([A-z0-9_-]{22})$/gim
      const task = this.task.trim()
      return !task.length || (!!task.match(regexp) && !this.isTaskExist)
    },
    hasErrors () {
      return this.error || !this.isValidLink ? 'uk-form-danger' : ''
    }
  },
  methods: {
    ...mapActions('whatsapp', ['addTask']),
    async onSubmit () {
      if (!this.isValidLink) return
      let { task, priority, description } = this
      priority = priority ? 9 : 1
      this.isLoading = true
      try {
        await this.addTask({ joinlink: task, priority, description })
        this.error = ''
        this.task = ''
        this.description = ''
        this.$emit('update')
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task-description {
  min-height: 70px;
  max-height: 200px;
  min-width: 100%;
}
.priority__options {
  width: 47px;
  height: 20px;
}
</style>
