import axios from 'axios'
import config from '@/config'
import { apiRequest, apiCall } from '@/utils/api'

const state = {
  isLoaded: {
    settings: true
  }
}

const getters = {
  getCurrentLanguage: (state) => {
    return state.language
  }
}

const mutations = {
  startLoad: (state, payload) => {
    state.isLoaded[payload] = false
  },
  stopLoad: (state, payload) => {
    state.isLoaded[payload] = true
  }
}

const actions = {
  putTimezone ({ commit, rootState: { settings: { timezone } } }) {
    commit('startLoad', 'settings')
    apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { timezone })
      .then(({ data: { success, message, settings: { timezone } = {} } = {} }) => {
        if (!success) throw new Error(message)
        commit('setTimezone', timezone)
      })
      .catch(console.error)
      .finally(() => commit('stopLoad', 'settings'))
  },
  putLanguage ({ commit, rootState }, language) {
    commit('startLoad', 'settings')
    apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { translate: language })
      .then(({ data: { success, message, settings: { translate, defaultCase, timezone } = {} } = {} }) => {
        if (!success) throw new Error(message)
        commit('setLanguage', translate)
      })
      .catch(err => console.debug(err))
      .finally(() => commit('stopLoad', 'settings'))
  },
  putExcludeSource ({ commit, rootState }, excludeSources) {
    commit('startLoad', 'settings')
    return apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { excluded_sources: excludeSources })
      .then(({ data: { settings, message, success } = {} }) => {
        if (!success) throw new Error(message)
        commit('setSettings', settings)
      })
      .catch(err => console.debug(err))
      .finally(() => commit('stopLoad', 'settings'))
  },
  saveUserName ({ commit, rootState }, login) {
    sessionStorage.removeItem('user-name')
    localStorage.removeItem('user-name')
    sessionStorage.setItem('user-name', login)
    localStorage.setItem('user-name', login)
  },
  saveAuthToken ({ commit, rootState }, token) {
    sessionStorage.removeItem('user-token')
    sessionStorage.setItem('user-token', token)
    axios.defaults.headers.common['Authentication-Token'] = 'Bearer' + token
  },
  async setSettings ({ commit, dispatch, state, rootState }) {
    commit('startLoad', 'settings')
    try {
      let { data: { settings } } = await apiRequest('/api/user/settings', 'GET', {}, config.servers.asiris_api)
      let { data: { limits } }  = await apiRequest('/limit/user/'+rootState.current_user_id+'/complete_list', 'GET', {}, config.servers.auth)
      settings.limits = limits
      commit('setSettings', settings)
      return settings
    } catch (err) {
      throw err
    } finally {
      commit('stopLoad', 'settings')
    }
  },
  async getOtpSecret ({ commit, rootState }, password) {
    try {
      const { data } = await apiCall({ url: '/auth/login', email: rootState.user_name, password, method: 'POST' })
      commit('setToken', data.access_token)
      commit('setRefreshToken', data.refresh_token)
    } catch (err) {
      throw err
    }
    try {
      const { data } = await apiRequest('auth/otp', 'GET', {}, config.servers.auth)
      return data
    } catch (err) {
      throw err
    }

  },
  async changePassword ({ commit, rootState }, passwords) {
    try {
      const { data } = await apiCall({ url: '/auth/login', email: rootState.user_name, password: passwords.password, method: 'POST', otp: passwords.otp })
      commit('setToken', data.access_token)
      commit('setRefreshToken', data.refresh_token)
    } catch (err) {
      throw err
    }
    return apiRequest(`/auth/change_password`, 'POST', {}, config.servers.auth, passwords)
  },
  // eslint-disable-next-line
  async set2FA ({ commit, dispatch, state, rootState }, { twoFA, otp, otp_secret, signature, password }) {
    if (twoFA){
      const { data } = await apiCall({ url: '/auth/login', email: rootState.user_name, password, method: 'POST', otp: otp })
      commit('setToken', data.access_token)
      commit('setRefreshToken', data.refresh_token)
    }
    try {
      await apiRequest(!twoFA ? 'auth/otp' : 'auth/otp/delete', 'POST', {}, config.servers.auth, !twoFA ? { otp, otp_secret, signature } : { otp })
    } catch (err) {
      throw err
    }
  },
  getCurrentUser ({ commit }) {
    return apiRequest('current_user', 'GET', {}, config.servers.auth)
  },
  async getPasswordPolicy ({ commit }) {
    let { data } = await apiRequest('password_policy', 'GET', {}, config.servers.auth)
    return data
  },
  async setDefaultCase ({ commit, dispatch, state, rootState }, caseId) {
    if (rootState.settings.defaultCase === caseId.defaultCase) { caseId.defaultCase = null }
    await apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, caseId)
    commit('setDefaultCase', caseId.defaultCase)
  },
  async resetOTP ({ commit }, userId) {
    try {
      await apiRequest(`user/${userId}/otp_secret`, 'DELETE', {}, config.servers.auth)
    } catch (err) {
      throw err
    }
  },
  async setSilentWriteOff ({ commit }, writeoff) {
    const { data: { settings } } = await apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { silent_token_write_off: writeoff })
    commit('setSilentWriteOff', settings.silent_token_write_off)
  },
  async setSearchSave ({ commit }, searchSave) {
    const { data: { settings } } = await apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { search_save: searchSave })
    commit('setSearchSave', settings.search_save)
  },
  async setNsfwBlur ({ commit }, nsfwBlur) {
    const { data: { settings } } = await apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { blur_nsfw_forums: nsfwBlur })
    commit('setNsfwBlur', settings.blur_nsfw_forums)
  },
  async setChooseMessages ({ commit }, data) {
    const { data: { settings } } = await apiRequest('/api/user/settings', 'POST', {}, config.servers.asiris_api, { choose_messages_to_export: data })
    commit('setChooseMessages', settings.choose_messages_to_export)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
