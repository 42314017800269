<template>
  <div
    class="uk-align-center"
    uk-grid
  >
    <Loader v-if="!isLoaded"/>
    <div
      v-else
      uk-grid
    >
      <div class="uk-width-1-2">
        <div class="uk-card uk-card-default uk-margin-bottom">
          <div class="uk-card-header  " >
            <h4>
              {{ $t('crypto.btc-address-information') }}
            </h4>
          </div>
          <div
            class="uk-card-body"
            uk-grid
          >
            <table class="uk-table">
              <tr>
                <th>
                  {{ $t('crypto.address') }}
                </th><td>
                  {{ addressInfo.info.data.key }}
                </td>
              </tr>
              <tr>
                <th>
                  {{ $t('crypto.balance') }}
                </th><td>
                  <span :uk-tooltip="balanceText(addressInfo.info.data.balance.confirmed)">
                    {{ numberFormat(addressInfo.info.data.balance.confirmed.btc) }} BTC
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  {{ $t('crypto.identity') }}
                </th><td>
                  {{ addressInfo.info.data.identity.name || '-' }}
                </td>
              </tr>
              <tr>
                <th>
                  {{ $t('crypto.wallet-type') }}
                </th><td>
                  {{ addressInfo.info.data.identity.type || '-' }}
                </td>
              </tr>
              <tr>
                <th>
                  {{ $t('crypto.transactions-count') }}
                </th><td>
                  {{ numberFormat(addressInfo.info.data.count.transactions.total) }}
                </td>
              </tr>
              <tr>
                <th>
                  {{ $t('crypto.last-activity') }}
                </th><td>
                  {{ dayjs(Date(addressInfo.info.data.last_activity)) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="uk-width-1-2">
        <div class="uk-card uk-card-default uk-margin-bottom">
          <div class="uk-card-header">
            <h4>
              {{ $t('crypto.input-output-relation') }}
            </h4>
          </div>
          <div
            class="uk-card-body"
            uk-grid
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { numberFormat } from '@/filters/globalFilters'
import { mapActions } from 'vuex'

export default {
  name: 'BtcAddressInfo',
  components: {
    Loader
  },
  props: {
    address: {
      type: String,
      required: true
    }
  },
  watch: {
    address (to, from) {
      this.update()
    }
  },
  computed: {
    isLoaded () {
      return this.$store.state.crypto.isLoaded['address_' + this.address]
    },
    addressInfo () {
      return this.$store.state.crypto.addresses[this.address]
    }
  },
  methods: {
    ...mapActions('crypto', [
      'fetchAddress'
    ]),
    numberFormat,
    update () {
      this.fetchAddress(this.address)
    },
    balanceText (balanceObject) {
      let str = ''
      Object.entries(balanceObject).forEach(function (value, key) {
        if (key < 7) {
          if (value[0] === 'btc') str += value[1]
          else str += this.numberFormat(value[1])
          str += ' ' + value[0].toLocaleUpperCase() + '<br>'
        }
      })
      return str
    }
  },
  mounted () {
    this.update()
  }
}
</script>
