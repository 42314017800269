<template>
  <tr>
    <td class="uk-text-truncate uk-text-left"> 
      <AddressField 
        :addr="acc.address" 
        :break_word="false"
      />
    </td>
    <td class="uk-text-truncate uk-text-left">
      {{ acc.types && acc.types?.length > 0 ? acc.types.join(', ') : "" }}
    </td>
    <td>
      <BalanceCell 
        :balance="acc.balance"
        :place="'end'"
      />
    </td>
    <td class="uk-flex uk-flex-right">
      <StatusCell :status="acc.status"/>
    </td>
    <td>
      <TableDateCell :date-time="acc.updated_at"/>
    </td>
  </tr>
</template>

<script>
import TableDateCell from '@/components/Crypto/Ton/Atoms/TableDateCell'
import StatusCell from '@/components/Crypto/Ton/Atoms/StatusCell'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import BalanceCell from '@/components/Crypto/Ton/Atoms/BalanceCell'

export default {
  name: 'TonAccountLine',
  components: {
    TableDateCell,
    StatusCell,
    AddressField,
    BalanceCell
  },
  props: {
    acc: {
      type: Object,
      required: true
    }
  }
}
</script>