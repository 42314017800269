<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="user.answer_status==='NOT_FOUND'" />
      <div v-else>
        <h3>
          {{ $t('tgcp.user_details.user_info') }}
        </h3>
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <gi :user="user" />
          </div>
          <div class="uk-width-1-4@m">
            <UserStats :id="id" />
          </div>
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <ul uk-tab>
          <li :class="checkForTab('overview')">
            <a href="#">
              {{ $t('tgcp.channel_details.overview_title') }}
            </a>
          </li>
          <li :class="checkForTab('channels')">
            <a href="#">
              {{ $t('tgcp.channels.current-channels') }}
              <span class="uk-badge uk-margin-small-left">
                <Loader v-if="!isLoadedStats"/>
                <span v-else>
                  {{ stats.chats_count }}
                </span>
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              {{ $t('tgcp.channels.past-channels') }}
              <span class="uk-badge uk-margin-small-left">
                <Loader v-if="!isLoadedStats"/>
                <span v-else>
                  {{ stats.former_chats_count }}
                </span>
              </span>
            </a>
          </li>
          <li :class="checkForTab('messages')">
            <a href="#">
              {{ $t('tgcp.channel_details.messages_title') }}<span class="uk-badge uk-margin-small-left">
                <Loader v-if="!isLoadedStats"/>
                <span v-else>
                  {{ stats.messages_count }}
                </span>
              </span>
            </a>
          </li>
          <li :class="checkForTab('media_messages')">
            <a href="#">
              {{ $t('tgcp.channel_details.messages_with_media_title') }}<span class="uk-badge uk-margin-small-left">
                <Loader v-if="!isLoadedStats"/>
                <span v-else>
                  {{ stats.media_messages_count }}
                </span>
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              {{ $t('tgcp.user_details.replies_title') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <a href="#"/>
            <ucg :id="id" />
            <udg :id="id" />
            <uwg :id="id" />
            <umg :id="id" />
          </li>
          <li>
            <ChannelListMini :id="id" />
          </li>
          <li>
            <ChannelListMini
              :id="id"
              :participantStatus="String('former')"
            />
          </li>
          <li>
            <MessageListMini :userId="id"/>
          </li>
          <li>
            <MessageListMini
              :userId="id"
              :containMedia="true"
            />
          </li>
          <li>
            <ur :id="id"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import UserStats from '@/components/TGCP/UserDetails/UserStats'
import UserReplies from '@/components/TGCP/UserDetails/UserReplies'
import UserChannelsGraph from '@/components/TGCP/UserDetails/UserChannelsGraph'
import UserDayGraph from '@/components/TGCP/UserDetails/UserDayGraph'
import UserWeekGraph from '@/components/TGCP/UserDetails/UserWeekGraph'
import UserYearGraph from '@/components/TGCP/UserDetails/UserYearGraph'
import GeneralInformation from '@/components/TGCP/UserDetails/GeneralInformation'
import MessageListMini from '@/components/TGCP/MessageList/MessageListMini'
import ChannelListMini from '@/components/TGCP/ChannelList/ChannelListMini'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TgcpUserDetail',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    UserStats,
    ucg: UserChannelsGraph,
    udg: UserDayGraph,
    uwg: UserWeekGraph,
    umg: UserYearGraph,
    gi: GeneralInformation,
    ur: UserReplies,
    MessageListMini,
    ChannelListMini
  },
  props: {
    chatId: {
      required: true,
      type: String
    },
    messageId: {
      required: true,
      type: String
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    activeTab () {
      if (this.$route.query.tab) {
        return this.$route.query.tab
      }
      return 'overview'
    },
    user () {
      return this.$store.state.tgcp.users[this.id]
    },
    stats () {
      return this.$store.state.tgcp.users[this.id]['stats']
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id]
    },
    isLoadedStats () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id + '_stats']
    }
  },
  methods: {
    update () {
      const action = 'tgcp/fetchMessageReposts'
      this.$store.dispatch(action, { chatId: this.chatId, messageId: this.messageId })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
