<template>
  <div class="uk-margin-top">
    <div class="uk-margin">
      <form @submit.prevent="search">
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
          :placeholder="$t('threema.threema-id-or-phone-number-or-email')"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click="search"
        >
          <span uk-search-icon/>
        </button>
      </form>
    </div>
    <div
      class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
      uk-grid
    >
      <div >
        <div
          class="uk-card uk-card-small uk-card-default uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3 v-else-if="statsProblem">
            &mdash;
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.total_users_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.users_count') }}
          </h3>
        </div>
      </div>
      <div >
        <div
          class="uk-card uk-card-small  uk-card-primary uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3 v-else-if="statsProblem">
            &mdash;
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.mobile_no_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('threema.MSISDNs') }}
          </h3>
        </div>
      </div>
      <div >
        <div
          class="uk-card uk-card-small  uk-card-secondary uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3 v-else-if="statsProblem">
            &mdash;
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.email_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('threema.emails') }}
          </h3>
        </div>
      </div>
    </div>
    <div class="uk-card uk-card-default uk-margin-top uk-card-body uk-text-left">
      <p>{{ $t('threema.ASIRIS-allows-to-check') }}</p>
      <p>{{ $t('threema.as-well-as-look-up-in-already-preparsed') }} </p>
      <p>{{ $t('threema.please-note-presence') }}</p>
    </div>
  </div>
</template>
<script>
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
import { numberFormat } from '@/filters/globalFilters'
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'

export default {
  name: 'Stats',
  components: {
    Loader
  },
  data () {
    return {
      searchString: ''
    }
  },
  computed: {
    statsProblem () {
      return this.$store.state.threema.statsProblem
    },
    isLoaded () {
      return this.$store.state.threema.isLoaded['stats']
    },
    stats () {
      return this.$store.state.threema.stats
    }
  },
  methods: {
    numberFormat,
    validateEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    search () {
      let search = this.searchString
      if (parseMax(search, 'RU')) {
        try {
          if (parseMax(search, 'RU').isValid()) {
            search = parseMax(search, 'RU').format('INTERNATIONAL').split(' ').join('')
            this.$router.push({ name: 'threemaPhoneResults',
                                params: { phone: search, providers: 'threema' } })
          } else if (parseMax('+' + search).isValid()) {
            search = parseMax('+' + search).format('INTERNATIONAL').split(' ').join('')
            this.$router.push({ name: 'threemaPhoneResults',
                                params: { phone: search, providers: 'threema' } })
          } else if (this.validateEmail(search)) {
            this.$router.push({ name: 'threemaEmailResults',
                                params: { email: search, providers: 'threema' } })
          } else {
            this.$router.push({ name: 'threemaNicknameResults',
                                params: { nickname: search, providers: 'threema' } })
          }
        } catch (e) {
          if (this.validateEmail(search)) {
            this.$router.push({ name: 'threemaEmailResults',
                                params: { email: search, providers: 'threema' } })
          } else {
            this.$router.push({ name: 'threemaNicknameResults',
                                params: { nickname: search, providers: 'threema' } })
          }
        }
      } else if (parseMax('+' + search)) {
        if (parseMax('+' + search).isValid()) {
          search = parseMax('+ ' + search).format('INTERNATIONAL').split(' ').join('')
          this.$router.push({ name: 'threemaPhoneResults',
                              params: { phone: search, providers: 'threema' } })
        } else if (this.validateEmail(search)) {
          this.$router.push({ name: 'threemaEmailResults',
                              params: { email: search, providers: 'threema' } })
        } else {
          this.$router.push({ name: 'threemaNicknameResults',
                              params: {
                                nickname: search, providers: 'threema' } })
        }
      } else if (this.validateEmail(search)) {
        this.$router.push({ name: 'threemaEmailResults',
                            params: { email: search, providers: 'threema' } })
      } else {
        this.$router.push({ name: 'threemaNicknameResults',
                            params: {
                              nickname: search, providers: 'threema' } })
      }
    },
    ...mapActions({ update: 'threema/fetchStats' })
  },
  mounted () {
    this.update()
  }
}
</script>
