<template>
  <div class="home uk-margin">
    <h1>
      404
    </h1>
    <h2>
      {{ $t('generic-errors.page-not-found') }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
