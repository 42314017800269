<template>
  <div>
    <div
      v-if="hasCases"
      class="uk-card uk-card-default uk-margin-bottom uk-margin-top"
    >
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ archivedCases[id].case_title }}
        </h3>
        <div class="uk-position-right uk-margin-right uk-margin-top">
          <div>
            <span class="uk-margin-small-right uk-text-small">
              {{ dayjs(archivedCases[id].updatedAt) }}
            </span>

            <span>
              <button
                class="uk-button uk-button-text "
                type="button"
                :title="$t('cases.unarchive')"
                @click="archiveCase(archivedCases[id])&&
                  $router.push('/cases/' + id)"
              >
                <span uk-icon="push"/>
              </button>
            </span>
            <span>
              <button
                class="uk-button uk-button-text "
                type="button"
                :title="$t('cases.remove_case')"
                @click="confirmDelete(id)"
              >
                <span uk-icon="trash"/>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="uk-card-body">
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div class="uk-width-1-1@m  uk-text-left">
            <h3 class="uk-margin-remove-top uk-text-break">
              {{ archivedCases[id].case_description }}
            </h3>
            <h4 class="uk-margin-remove-top uk-margin-remove-bottom uk-text-muted uk-text-small">
              #{{ archivedCases[id].case_id }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="uk-grid uk-margin-top"
        uk-grid
      >
        <div class="uk-width-expand">
          <ul
            class="uk-child-width-expand"
            uk-tab
          >
            <li class="uk-active">
              <a href="#profiles_tab">
                {{ $t('cases.profiles.profiles') }}
              </a>
            </li>
            <li>
              <a href="#stored_tab">
                {{ $t('cases.stored-objects') }}
              </a>
            </li>
            <li class="uk-disabled">
              <a href="#tasks_tab">
                {{ $t('cases.tasks.tasks') }}
              </a>
            </li>
            <li>
              <a href="#notes_tab">
                {{ $t('cases.notes.notes_title') }}
              </a>
            </li>
          </ul>
          <ul class="uk-switcher">
            <li>
              <a name="profiles_tab"/>
              <ProfilesList :caseId="id"/>
            </li>
            <li>
              <a name="stored_tab"/>
              <StoredObjectsList :caseId="id"/>
            </li>
            <li>
              <a name="notes_tab"/>
              <NotesList :caseId="id"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotesList from '@/components/cases/NotesList'
import StoredObjectsList from '@/components/cases/StoredObjectsList'
import { mapActions, mapGetters } from 'vuex'
import ProfilesList from '@/components/cases/ProfilesList'
import UIkit from 'uikit'
export default {
  name: 'CaseDetails',
  components: {
    NotesList,
    StoredObjectsList,
    ProfilesList
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('cases', [
      'archivedCases'
    ]),
    hasCases () {
      if (!this.id) return false
      return Object.hasOwnProperty.call(this.archivedCases, this.id)
    }
  },
  methods: {
    ...mapActions('cases', [
      'archiveCase',
      'fetchArchivedCases',
      'deleteCase'
    ]),
    confirmDelete (id) {
      UIkit.modal.confirm(this.$t('cases.delete_case_confirm'))
        .then(() => {
          this.deleteCase(id) &&
            this.$router.push('/archived/cases/')
          this.fetchArchivedCases()
        })
        .catch((err) => {
          if (err) this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        })
    }
  },
  created () {
    this.fetchArchivedCases()
  }
}
</script>
