import VuexPersistence from 'vuex-persist'

const vuexPersistence = new VuexPersistence({
  key: 'asiris',
  storage: window.sessionStorage,
  reducer: (state) => {
    return {
    }
  }
})

export default vuexPersistence.plugin
