<template>
  <div 
    class="uk-flex uk-width-1-1"
    :style="`place-content: ${place}`"
  >
    <div 
      class="diamond"
      style="width: fit-content"
      :class="delta ? colorAmount(balance) : ''"
      :uk-tooltip="tooltip ? tooltip : (full ? undefined : fullTON(balance, delta))"
    >
      {{ full ? fullTON(balance, delta) : shortTON(balance, delta) }}
    </div>
  </div>
</template>

<script>
import { shortTON, fullTON, colorAmount } from '@/utils/ton-filters'

export default {
  name: 'TonBalanceCell',
  props: {
    balance: {
      type: [BigInt, Number, String],
      required: true
    },
    full: {
      type: Boolean,
      required: false,
      default: false
    },
    delta: {
      type: Boolean,
      required: false,
      default: false
    },
    place: {
      type: String,
      required: false,
      default: 'baseline'
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  methods: {
    shortTON,
    fullTON,
    colorAmount
  }
}
</script>

<style scoped>
.diamond::after {
    content: '💎';
    filter: grayscale(100%);
}
</style>