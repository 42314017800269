<template>
  <div>
    <div
      class="uk-card uk-card-default uk-card-body right-padding-remove"
    >
      <massEdit
        style="z-index: 1"
        :checkedTasks="checkedTasks"
        @update="refresh"
        @clearSelectedTasks="clearSelected"
      />
      <div class="tasks-wrapper__body">
        <h3>
          {{ $t('tgcp.tasks.tasks_title') }}
        </h3>

        <div class="uk-width-1-1">
          <div class="uk-flex">
            <div class="uk-flex uk-flex-wrap uk-flex-wrap-stretch  uk-flex-right filter-options">
              <div class="uk-flex-wrap uk-flex uk-flex-auto">
                <div>
                  {{ $t('tgcp.tasks.filter') }}
                  <vueSelect
                    v-model="currentTaskType"
                    :options="taskTypeTranslated"
                    label="label"
                    class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
                  />
                </div>
                <div>
                  {{ $t('tgcp.tasks.filter_sync') }}
                  <vueSelect
                    v-model="currentTaskSyncPeriod"
                    :options="taskSyncPeriodTranslated"
                    label="label"
                    class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
                  />
                </div>
                <div>
                  {{ $t('tgcp.tasks.filter_active') }}
                  <vueSelect
                    v-model="currentTaskIsActive"
                    :options="taskIsActiveTranslated"
                    label="label"
                    class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
                  />
                </div>
              </div>
              <div class="uk-flex">
                <div class="uk-flex-last uk-margin-left">
                  <button
                    class="uk-button uk-button-default filter-options__search-btn "
                    uk-toggle="target: #search_tg"
                    type="button"
                  >
                    <span uk-icon="search"/>
                    {{ $t('tgcp.tasks.search-in-TG') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-flex uk-flex-center options-row">
          <div
            v-if="!hidePagination"
            class="uk-margin-small-top"
          >
            <ul class="uk-pagination uk-flex-center">
              <li :class="currentPage === 1 && 'uk-disabled'">
                <a
                  class="uk-position-center"
                  @click="prevPage"
                >
                  <span uk-pagination-previous/>
                </a>
              </li>
              <li
                class="uk-padding-remove"
              >
                <input
                  v-model="goToPage"
                  class="uk-input uk-text-center uk-form-blank uk-text-truncate"
                  style="max-width: 250px; width: 40px"
                  :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
                  @change="jumpToPage"
                  @focus="$event.target.select()"
                />
              </li>
              <li :class="isNextPage ? 'uk-enabled' : 'uk-disabled'">
                <a
                  class="uk-position-center"
                  @click="nextPage"
                >
                  <span uk-pagination-next/>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="uk-margin-top"
          uk-overflow-auto
        >
          <table class="uk-table table-tasks uk-table-striped uk-table-justify uk-width-1-1">
            <thead >
              <tr
                class="uk-text-nowrap"
              >
                <th
                  class="uk-width-1-5 uk-text-center uk-text-middle"
                  style="padding-left:0.5rem;padding-right:0rem; width:1.5rem"
                >
                  <label
                    :uk-tooltip="!isAllChecked ? $t('tgcp.tasks.check-all') : $t('tgcp.tasks.uncheck-all')"
                  >
                    <input
                      v-model="isAllChecked"
                      :class="!isLoaded || !tasksOnPage.length ? 'uk-disabled' : ''"
                      :disabled="!tasksOnPage.length"
                      class="uk-checkbox"
                      type="checkbox"
                    />
                  </label>
                </th>
                <th
                  class="uk-width-1-5 uk-text-center uk-text-middle"
                  style="max-width: 25px;"
                >
                  <button
                    class="uk-button uk-button-text"
                    @click="setOrder('task')"
                  >
                    {{ $t('tgcp.tasks.task_th') }}
                    {{ query.order_by === 'task' ? selectedOrder : '' }}
                  </button>
                </th>
                <th class="uk-width-1-5 uk-text-center uk-text-middle uk-text-break">
                  {{ $t('tgcp.tasks.channels_th') }}
                </th>
                <th class="uk-width-1-5 uk-text-center uk-text-middle">
                  {{ $t('tgcp.tasks.description_th') }}
                </th>
                <th
                  class="uk-width-1-5 uk-text-center uk-text-middle"
                  width="100"
                >
                  <button
                    class="uk-button uk-button-text"
                    :uk-tooltip="$t('tgcp.tasks.last_sync_th')"
                    @click="setOrder('last_sync')"
                  >
                    {{ $t('tgcp.tasks.last_sync_dt_th') }}
                    {{ query.order_by === 'last_sync' ? selectedOrder : '' }}
                  </button>
                </th>
                <th
                  class="uk-width-1-5 uk-text-center uk-text-middle"
                  width="100"
                >
                  <button
                    class="uk-button uk-button-text"
                    @click="setOrder('created')"
                  >
                    {{ $t('tgcp.tasks.created_th') }}
                    {{ query.order_by === 'created' ? selectedOrder : '' }}
                  </button>
                </th>
                <th class="uk-width-1-5 uk-text-center uk-text-middle">
                  {{ $t('tgcp.tasks.status_th') }}
                </th>
                <th
                  class="uk-width-1-5 uk-text-center uk-text-middle"
                  width="100"
                >
                  {{ $t('tgcp.tasks.options_th') }}
                </th>
                <th
                  class="uk-width-1-5 uk-text-center uk-text-middle"
                  width="100"
                >
                  {{ $t('tgcp.tasks.actions_th') }}
                </th>
              </tr>
            </thead>

            <tbody v-if="!isLoaded">
              <td
                colspan="9"
                class="uk-text-center uk-width-1-1"
              >
                <Loader />
              </td>
            </tbody>
            <tbody v-else-if="isLoaded && allTasks">
              <TaskListItem
                v-for="(task, i) in tasksList"
                :key="getKey(task, i)"
                :task="task"
                :stored="stored.find(el => String(el.ext_id) === String(task.id))"
                :checked="checkedTasks.includes(task.id)"
                :checkedTasks="checkedTasks"
                @update="update"
                @map-show="activeTask = task.id"
                @check-changed="checkChanged($event, task.id)"
                @clicked-with-shift="clickedWithShift(task.id)"
                @openUserListModal="openUserListModal"
              />
            </tbody>
            <tbody v-else-if="isLoaded && !hasDataToView">
              <tr>
                <td
                  colspan="9"
                  class="uk-text-center uk-width-1-1"
                >
                  {{ $t('not_found.no_tasks') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="!hidePagination"
          class="uk-margin"
        >
          <ul class="uk-pagination uk-flex-center">
            <li :class="currentPage === 1 && 'uk-disabled'">
              <a
                class="uk-position-center"
                @click="prevPage"
              >
                <span uk-pagination-previous/>
              </a>
            </li>
            <li
              class="uk-padding-remove"
            >
              <input
                v-model="goToPage"
                class="uk-input uk-text-center uk-form-blank uk-text-truncate"
                style="max-width: 250px; width: 40px"
                :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
                @change="jumpToPage"
                @focus="$event.target.select()"
              />
            </li>
            <li :class="isNextPage ? 'uk-enabled' : 'uk-disabled'">
              <a
                class="uk-position-center"
                @click="nextPage"
              >
                <span uk-pagination-next/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="uk-grid">
      <div class=" uk-width-1-2@m uk-margin-top uk-padding-left@m">
        <AddTask
          :key="tgcpDefaultPriority"
          :default-priority="tgcpDefaultPriority"
          @added="refresh"
        />
      </div>
      <div class=" uk-width-1-2@m uk-margin-top uk-padding-left">
        <ImportExport
          @update="refresh"
        />
      </div>
    </div>
    <SearchTG
      @update="refresh"
    />
    <MapModal
      :task="mapActiveTask"
      @map-close="activeTask = false"
    />
    <ModalCenter
      v-if="activeTaskId"
      @hidden="activeTaskId = null"
    >
      <UserListModal :taskId="activeTaskId" />
    </ModalCenter>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'

export default {
  name: 'Tasks',
  components: {
    Loader,
    massEdit: defineAsyncComponent(() => import('@/components/TGCP/Tasks/MassEdit')),
    AddTask: defineAsyncComponent(() => import('@/components/TGCP/Tasks/AddTask')),
    ImportExport: defineAsyncComponent(() => import('@/components/TGCP/Tasks/ImportExport')),
    TaskListItem: defineAsyncComponent(() => import('@/components/TGCP/Tasks/listItem')),
    SearchTG: defineAsyncComponent(() => import('@/components/TGCP/Tasks/SearchTG')),
    MapModal: defineAsyncComponent(() => import('@/components/TGCP/Tasks/map-modal')),
    ModalCenter: defineAsyncComponent(() => import('@/components/ModalCenter')),
    UserListModal: defineAsyncComponent(() => import('@/views/TGCP/UserListModal')),
    vueSelect
  },
  data () {
    return {
      activeTask: false,
      timer: null,
      stored: [],
      tasks: {
        items: []
      },
      filters: ['username', 'joinchat', 'invite', 'geo'],
      sync_period_filters: ['auto','realtime','periodic'],
      query: {
        limit: 21,
        offset: this.$route.query.offset || 0,
        is_active: (this.$route.query.task_is_active === true || this.$route.query.task_is_active === false)?this.$route.query.task_is_active:null,
        sync_period: this.$route.query.task_sync_period || null,
        task_type: this.$route.query.task_type || null,
        order_by: this.$route.query.order_by || 'created',
        order_type: this.$route.query.order_type || 'desc'
      },
      refreshTimeout: 60000,
      checkedTasks: [],
      lastTaskChecked: null,
      isLoading: false,
      activeTaskId: null,
      goToPage: 1
    }
  },
  watch: {
    goToPage (newVal, oldVal) {
      if (!/^[0-9]*$/.test(newVal) || !+newVal) {
        this.goToPage = oldVal
      }
    },
    currentPage (newVal) {
      this.goToPage = newVal
    }
  },
  computed: {
    ...mapGetters(['tgcpDefaultPriority']),
    ...mapGetters('tgcp', ['currentPage', 'allListTasks', 'isNextPage', 'allTasks']),
    tasksList () {
      if (!this.tasks) return []
      return (this.tasks.items.length === this.query.limit) ? this.tasks.items.slice(0, -1) : this.tasks.items
    },
    tasksOnPage () {
      if (!this.tasks) return []
      return this.tasks.items.map(({ id }) => id)
    },
    isAllChecked: {
      get () {
        if (!this.allTasks || !this.tasksList || !this.checkedTasks.length) return
        return this.tasksList.every(task => this.checkedTasks.includes(task.id))
      },
      set () {
        if (this.isAllChecked) {
          this.checkedTasks = []
        } else {
          this.checkedTasks = this.tasksList.map(({ id }) => id)
        }
      }
    },
    mapActiveTask () {
      return this.getTask(this.activeTask)
    },
    currentTaskType: {
      get () {
        return this.query.task_type
      },
      set ({ value } = {}) {
        this.query.task_type = value || null
        this.query.offset = 0
        this.setPage(1)
        this.update()
      }
    },
    currentTaskSyncPeriod: {
      get () {
        return this.query.sync_period
      },
      set ({ value } = {}) {
        this.query.sync_period = value || null
        this.query.offset = 0
        this.setPage(1)
        this.update()
      }
    },
    currentTaskIsActive: {
      get () {
        return this.query.is_active
      },
      set ({ value } = {}) {
        this.query.is_active = value
        this.query.offset = 0
        this.setPage(1)
        this.update()
      }
    },
    taskTypeTranslated () {
      const taskTypes = this.filters.map(value => ({
        label: this.$t(`tgcp.filters.${value}`),
        value
      }))
      return [
        {
          label: this.$t('tgcp.filters.all'),
          value: null
        },
        ...taskTypes
      ]
    },
    taskIsActiveTranslated () {
      return [
        {
          label: this.$t('tgcp.filters.all'),
          value: null
        },
        {
          label: this.$t('tgcp.filters.active'),
          value: true
        },
        {
          label: this.$t('tgcp.filters.inactive'),
          value: false
        }
      ]
    },
    taskSyncPeriodTranslated () {
      const taskTypes = this.sync_period_filters.map(value => ({
        label: this.$t(`tgcp.filters.${value}`),
        value
      }))
      return [
        {
          label: this.$t('tgcp.filters.all'),
          value: null
        },
        ...taskTypes
      ]
    },
    taskById (id) {
      return this.$store.state.tgcp.tasks[id]
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['tasks']
    },
    hasDataToView () {
      if (!this.tasks) return 0
      return this.tasks.items.length
    },
    hidePagination () {
      return this.currentPage === 1 && this.isNextPage === false
    },
    selectedOrder () {
      return this.query.order_type === 'asc' ? '↑' : '↓'
    }
  },
  methods: {
    ...mapMutations('tgcp', [
      'clearQueryString',
      'clearTasksView'
    ]),
    ...mapActions('tgcp', [
      'fetchAllTasks',
      'setPage',
      'deleteTask',
      'deleteGeoTask'
    ]),
    ...mapActions('cases', [
      'fetchAllStoredObjects'
    ]),
    openUserListModal (taskId) {
      this.activeTaskId = taskId
    },
    getKey (task, i) {
      if (!task) {
        return i
      }
      return task.collect_media +
        task.sync_period +
        task.last_sync +
        task.priority +
        task.id +
        task.is_active
    },
    clickedWithShift (clickedId) {
      const tasksOnPage = this.tasksOnPage
      const index1 = tasksOnPage.findIndex(id => id === clickedId)
      const index2 = tasksOnPage.findIndex(id => id === this.lastTaskChecked) || 0
      const max = index1 >= index2 ? index1 + 1 : index2 - 1
      const min = index1 < index2 ? index1 : index2
      const shifted = this.tasksOnPage.slice(min, max)
      shifted.forEach(task => this.checkChanged(true, task))
    },
    checkChanged (value, task) {
      if (value) {
        this.lastTaskChecked = task
        return this.checkedTasks.push(task)
      }
      this.checkedTasks = this.checkedTasks.filter(taskId => taskId !== task)
    },
    getTask (id) {
      return id && this.allTasks[id]
    },
    nextPage () {
      this.query.offset = (this.query.limit - 1) * this.currentPage
      this.setPage(this.currentPage + 1)
      this.update()
    },
    prevPage () {
      const limit = this.query.limit - 1
      const { query } = this
      query.offset = query.offset - limit
      this.setPage(this.currentPage - 1)
      this.update()
    },
    async refresh (needCleanCheckedTasks) {
      clearTimeout(this.timer)
      this.timer = null
      if (!this.$route.name.includes('tgcp_tasks_all')) return

      if (needCleanCheckedTasks) this.checkedTasks = []
      try {
        await this.update('isRefreshing')
      } catch (err){
        console.dir(err)
      }
      this.timer = setTimeout(this.refresh, this.refreshTimeout)
      this.$forceUpdate()
    },
    async update (isRefreshing) {
      clearTimeout(this.timer)
      this.timer = null
      this.clearTasksView()
      if (!isRefreshing) {
        this.stored = []
        this.checkedTasks = []
      }
      this.fetchAllStoredObjects({ filter: ['task'] }).then(stored => (this.stored = stored))
      const { query } = this
      if (query.offset < 0) {
        query.offset = 0
        this.setPage(1)
      }
      await this.$router.replace({ query })
      try {
        this.tasks.items = await this.fetchAllTasks(isRefreshing)
        if (!this.hasDataToView && query.offset > 0 && this.currentPage > 0) {
          this.prevPage()
        }
      } catch (err) {
        console.dir(err)
      } finally {
        this.timer = setTimeout(this.refresh, this.refreshTimeout)
      }
    },
    async jumpToPage ({ target }) {
      for (let i = 10; i > 0; i--) {
        this.query.offset = (this.query.limit - 1) * (--target.value)
        await this.update()
        if (this.tasks.items.length) {
          this.goToPage = +target.value
          this.setPage(+target.value)
          return
        }
      }
      this.query.offset = (this.query.limit - 1) * (this.currentPage - 1)
      await this.update()
      this.goToPage = this.currentPage
      this.$notify({ group: 'general', title: `Page not found`, type: 'error' })
    },
    setOrder (by) {
      const { query } = this
      if (query.order_by === by) {
        query.order_type = query.order_type === 'desc' ? 'asc' : 'desc'
      } else {
        query.order_type = 'desc'
      }
      query.order_by = by
      this.update()
    },
    clearSelected () {
      this.checkedTasks = []
    }
  },
  mounted () {
    this.activeTaskId = false
    this.clearQueryString()
    const { offset } = this.query
    const page = offset && Number(offset) !== 0 ? Math.floor(offset / (this.query.limit - 1)) + 1 : 1
    this.goToPage = page || 1
    this.setPage(page)
    this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
    this.timer = null
  }
}
</script>

<style lang="scss" scoped>
.options-row {
  height: 20px;
}
.filter-options {
  flex-grow: 1;
  &__search-btn {
    // position: absolute;
    min-width: 12rem;
    right: 3em;
  }
}
@media (max-width: 700px) {
  .filter-options__search-btn {
    position: initial;
  }
}
</style>

<style lang="scss">
.table-tasks {
  td,
  th {
    padding-left: 0.3rem;
    padding-right: 0rem;
  }
}

.tasks-wrapper {
  &__options {
    width: 35px;
    min-width: 35px;
    max-width: 35px;
  }
}
.left-padding-remove {
  padding-left: 0 !important;
}
</style>
