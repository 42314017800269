<template>
  <div class="uk-box-shadow-small uk-box-shadow-hover-large uk-padding uk-margin-small-top my-uk-alert my-uk-padding">
    <div class="uk-margin uk-margin-small-bottom my-description">
      {{ entry.description.replaceAll('_', ' ') }}
    </div>
    <div v-if="!showInfiniteDoneBar && !showInfiniteBar">
      <progress
        v-if="progressValue < 100 && !isRejected(entry.progress)"
        class="uk-progress"
        :value="progressValue"
        max="100"
      />
    </div>
    <div v-if="showInfiniteBar">
      <div class="uk-progress infinite">
        <div class="infinite-value" />
      </div>
    </div>
    <div class="uk-flex uk-flex-center uk-flex-middle uk-grid-small">
      <span
        v-if="isRejected(entry.progress)"
        uk-icon="warning"
        class="my-icon"
        :uk-tooltip="$t('csv.stoped')"
      />
      <span>
        <span
          v-if="progress(entry.progress) === 100 && !isRejected(entry.progress)"
          class="my-success-icon"
          uk-icon="check"
          :uk-tooltip="$t('csv.success')"
        />
      </span>
      <span>
        <a
          v-if="progress(entry.progress) === 100 && !isRejected(entry.progress)"
          uk-toggle
          class="uk-border-pill my-refresh-button"
          :uk-tooltip="$t('csv.preview')"
          href="#export-preview"
          @click="setCurrent(entry)"
        >
          <span uk-icon="file-text"/>
        </a>
      </span>
      <div>
        <button
          :disabled="!(progressValue < 100 && !isRejected(entry.progress))"
          uk-icon="ban"
          class="uk-border-pill my-refresh-button"
          :uk-tooltip="$t('csv.stop')"
          @click="stop(entry)"
        />
      </div>
      <div>
        <button
          :disabled="!(progress(entry.progress) === 100 || isRejected(entry.progress))"
          uk-icon="refresh"
          class="uk-border-pill my-refresh-button"
          :uk-tooltip="$t('csv.continue')"
          @click="entry.continue(entry.id)"
        />
      </div>
      <div>
        <button
          :disabled="!(progress(entry.progress) === 100 || isRejected(entry.progress))"
          uk-icon="trash"
          class="uk-border-pill my-refresh-button"
          :uk-tooltip="$t('csv.clear')"
          @click="clear(entry.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Queue from '@/plugins/Export/Queue'

export default {
  name: 'NotificationCard',
  props: {
    entry: {
      type: Object
    }
  },
  computed: {
    showInfiniteBar () {
      return (this.entry?.Strategy?.options?.namespace === 'tgcp' || this.entry?.Strategy?.options?.namespace === 'profiles' || this.entry?.Strategy?.total === 0) && this.entry?.progress === 'pending'
    },
    showInfiniteDoneBar () {
      return (this.entry?.Strategy?.options?.namespace === 'tgcp' || this.entry?.Strategy?.options?.namespace === 'profiles' || this.entry?.Strategy?.total === 0) && this.entry?.progress === 'fulfilled'
    },
    progressValue () {
      if (!this.showInfiniteBar) return this.entry?.Strategy?.loaded ?? 50
      else return 50
    }
  },
  methods: {
    progress (progress) {
      try {
        switch (progress) {
            case 'fulfilled':
              return 100
            case 'rejected':
              return 0
            case 'pending':
              return 50
            default:
              return 0
        }
      } catch (error) {
        console.debug(error)
      }
      return 0
    },
    setCurrent (current) {
      try {
        this.$emit('setCurrent', current)
      } catch (error) {
        console.debug(error)
      }
    },
    isRejected (progress) {
      try {
        return progress === 'rejected'
      } catch (error) {
        console.debug(error)
      }
    },
    stop: process => Queue.stop(process),
    clear: process => Queue.delete(process)
  }
}
</script>

<style scoped>
.my-icon {
  color: #f0506e !important;
}

.my-description {
  word-break: break-all;
}

.my-uk-padding {
  padding-top: 15px;
  padding-bottom: 10px;
}

.my-success-icon {
  color: #1e87f0
}
.my-refresh-button, .my-refresh-button:hover {
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  color:  rgb(153, 153, 153);
  padding: 5px
}

.my-uk-alert {
  color: #999 !important;
}

.infinite {
  background-color: #f8f8f8;
  overflow: hidden;
}
.infinite-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>
