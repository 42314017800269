<template>
  <div>
    <div
      class="uk-card uk-card-default uk-margin-bottom"
    >
      <div
        class="uk-card-header"
      >
        <h3
          class="uk-card-title uk-text-left"
        >
          {{ $t('tgcp.user_details.year_activity_title') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <flatPickr
          v-model="dateStart"
          class="uk-input uk-form-width-small uk-form-width-medium"
          :placeholder="$t('tgcp.user_details.select-start-date')"
          :config="flatPickrConfig"
          @on-change="changed = true"
          @on-close="changeDate"
        />
        {{ $t('tgcp.user_details.to') }}
        <flatPickr
          v-model="dateEnd"
          class="uk-input uk-form-width-small uk-form-width-medium"
          :placeholder="$t('tgcp.user_details.select-end-date')"
          :config="flatPickrConfig"
          @on-change="changed = true"
          @on-close="changeDate"
        />
        <Loader
          v-if="!isLoaded"
        />
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import Loader from '@/components/TGCP/Loader'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import flatPickr from 'vue-flatpickr-component'
import IntegerAxisFormatter from '@/utils/IntegerAxisFormatter'

export default {
  name: 'TgcpUserYearGraph',
  components: {
    Loader,
    VueApexCharts,
    flatPickr
  },
  props: {
    id: {
      required: true,
      type: String
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      changed: false,
      dateStart: '',
      dateEnd: '',
      flatPickrConfig: {
        maxDate: new Date(),
        mode: 'range',
        plugins: [
          new MonthSelectPlugin({
            shorthand: true, // defaults to false
            dateFormat: 'Y-m', // defaults to "F Y"
            mode: 'range'
          })
        ]
      },
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          formatter: val => +val > 2 ? val : ''
        },
        xaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_months')
          }
        },
        yaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          },
          min: 0
        },
        tooltip: {
          marker: {
            show: true
          },
          y: {
            show: true,
            formatter: IntegerAxisFormatter.format
          }
        }
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    chatsActivity () {
      if (!this.$store.state.tgcp.users[this.id]) return
      const dayActivity = this.$store.state.tgcp.users[this.id]['activity']['year']['activity']
      const series = [{
        name: this.$i18n.t('tgcp.user_details.graph_messages'),
        data: []
      }]
      for (const i in dayActivity) {
        series[0].data.push({
          x: this.$i18n.t('tgcp.user_details.graph_months_' + i),
          y: dayActivity[i]
        })
      }
      return series
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id + '_activity_year']
    }
  },
  methods: {
    update (query) {
      const action = 'tgcp/fetchUserActivityById'
      this.$store.dispatch(action, [
        this.id,
        'year',
        query
      ])
      this.changed = false
    },
    changeDate (e) {
      if (!this.dateStart || !this.dateEnd) return
      this.changed &&
        this.update({
          from_date: dayjs(this.dateStart).startOf('month').format('YYYY-MM-DD'), // dayjs global filter exeption
          to_date: dayjs(this.dateEnd).endOf('month').format('YYYY-MM-DD')
        })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
<style>
</style>
