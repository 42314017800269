<script>
import Loader from '@/components/TGCP/Loader'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PhonesTable',
  components: {
    Loader,
    AddressField
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    owner: {
      type: String,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      itemCount: 10,
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    keys: {
      handler () {
        this.updateValues()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts', 'getTonAccountBases']),
    isLoaded () {
      return this.$store.state.crypto.isLoaded['tonaddressphones_'  + this.owner] ?? false
    },
    hidePagination () {
      return !this.isLoaded || (this.pagination.total ? this.pagination.page : this.keys.length ) < this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage + 1 : lastPage + 2
    },
    currentBatch () {
      const { keys, pagination, itemCount } = this
      return keys.slice((pagination.page - 1) *itemCount, (pagination.page)*itemCount).map(item => { return {
        phone: item.phone,
        address: item.address in this.getTonAccountBases ? this.getTonAccountBases[item.address] : item.address
      } })
    }
  },
  methods: {
    ...mapActions('crypto', ['fetchBareTonAccounts']),
    async updateValues () {
      this.fetchBareTonAccounts(this.keys.filter(item => !(item.address in this.getTonAccountBases)).map(item => item.address))
    },
    setPage (page) {
      this.pagination.page = page
    }
  },
  async mounted () {
    await this.updateValues()
  }
}
</script>

<template>
  <div>
    <table class="uk-table uk-table-middle uk-margin-remove-top uk-table-striped">
      <thead>
        <tr>
          <th class="uk-width-1-4">
            {{ $t('crypto.phone') }}
          </th>
          <th class="uk-width-3-4">
            {{ $t('crypto.ton.address') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template 
          v-if="!isLoaded" 
          class="uk-flex uk-flex-center"
        >
          <tr>
            <td colspan="2">
              <Loader :ratio="2"/>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr 
            v-for="entry of currentBatch"
            :key="entry.address + owner"
          >
            <td class="uk-text-left">
              <router-link :to="{ name: 'phoneResults', params: { phone: '+' + entry.phone } }">
                +{{ entry.phone }}
              </router-link>
            </td>
            <td class="uk-text-left uk-text-truncate">
              <AddressField 
                v-if="entry.address in getTonAccounts" 
                :break_word="true" 
                :addr="getTonAccounts[entry.address].address"
                :full="true"
              />
              <Loader
                v-else 
                :ratio="1"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next />
          </a>
        </li>
      </ul>
    </div>
  </div> 
</template>