<template>
  <MessageListMini
    :userId="id"
    :page="messagePage"
    :offset="messageOffset"
    :containMedia="true"
  />
</template>

<script>
import MessageListMini from '@/components/TGCP/MessageList/MessageListMini'

export default {
  name: 'TgcpUserDetailsMediaMessages',
  components: {
    MessageListMini
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  computed: {
    messagePage () {
      if (this.$route.params.message_pagination) return this.$route.params.message_pagination.page
      else return 1
    },
    messageOffset () {
      if (this.$route.params.message_pagination) return this.$route.params.message_pagination.offset
      else return 0
    }
  }
}
</script>

<style scoped>

</style>
