<template>
  <ul 
    v-if="parent"
    class="uk-margin-remove"
    :uk-accordion="`multiple: true;`"
  >
    <li>
      <a 
        class="uk-accordion-title uk-text-default"
        style="width: fit-content;" 
        href
      >
        {{ struct.name }}
      </a>
      <div class="uk-accordion-content uk-margin-remove-top uk-margin-left">
        <recursive-struct 
          v-for="item in struct.children"
          :key="`struct-${goodId}-${struct.name}-${depth}-${item.name}`"
          :struct="item"
          :goodId="goodId"
          :depth="depth + 1"
        />
      </div>
    </li>
  </ul>
  <div 
    v-else
    style="width: fit-content;" 
  >
    {{ struct.name }}
  </div>
</template>

<script>
export default {
  name: 'RecursiveStruct',
  props: {
    struct: {
      required: true,
      type: [Object, Array]
    },
    depth: {
      required: false,
      type: Number,
      default: 0
    },
    goodId: {
      required: true,
      type: [String, Number]
    }
  },
  computed: {
    parent () {
      return 'children' in this.struct
    }
  }
}
</script>