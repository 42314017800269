<template>
  <div>
    <Loader v-if="!isLoaded"/>
    <div v-else>
      <notfound v-if="user.answer_status==='NOT_FOUND'"/>
      <div v-else>
        <h3>
          {{ $t('tgcp.user_details.user_info') }}
        </h3>
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation :user="user" />
          </div>
          <div class="uk-width-1-4@m">
            <UserStats
              v-if="0"
              :id="id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ZaloUserDetails',
  components: {
    // Feed: defineAsyncComponent(() => import('@/components/Zalo/UserDetails/Feed')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/Zalo/UserDetails/GeneralInformation')),
    UserStats: defineAsyncComponent(() => import('@/components/Zalo/UserDetails/UserStats')),
    Loader
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      activeTab: []
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    user () {
      return this.$store.state.zalo.users[this.id]
    },
    isLoaded: function () {
      return this.$store.state.zalo.isLoaded['user_' + this.id]
    }
  },
  methods: {
    ...mapActions('zalo', [
      'fetchProfileByUID'
    ]),
    update () {
      this.fetchProfileByUID(this.id)
    },
    activateTab (tab) {
      tab.target.id &&
        !this.activeTab.includes(tab.target.id) &&
        this.activeTab.push(tab.target.id)
    },
    checkForTab (tab) {
      let paramTab = this.$route.params.tab
      if (paramTab && tab === paramTab.replace('_tab', '')) {
        return 'uk-active'
      }
    }
  },
  mounted () {
    if (this.$route.params.tab) {
      this.activeTab.push(this.$route.params.tab)
    } else {
      this.activeTab.push('feed_tab')
    }
    this.update()
  }
}
</script>
