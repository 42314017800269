<template>
  <div class="uk-card uk-card-default">
    <div class="uk-card-body">
      <div class="uk-align-right">
        <AddStoredButton
          v-if="showStoredButton"
          provider="boards"
          :ext_id="extId"
          type="board"
          :data="board"
        />
      </div>
      <div
        class="uk-grid-small uk-flex uk-text-left"
        uk-grid
      >
        <div class="uk-width-1-5@m">
          <h3 class="uk-width-2-5 uk-margin-remove uk-margin-remove-bottom uk-text-left">
            <router-link
              :to="{name: 'ads_list',
                    params: {
                      boardId: board.id,
                      country: board.geo.toLowerCase()
                    }
              }"
            >
              {{ board.title }}
            </router-link>
          </h3>
          <div class="uk-text-bottom">
            {{ board.url }}
          </div>
          <div class="uk-margin-top">
            <b>
              {{ $t('boards.country') }}:
            </b>
            {{ countries[board.geo] }}
          </div>
          <div>
            <b>
              {{ $t('boards.category') }}:
            </b>
            {{ board.category }}
          </div>
        </div>
        <div class="uk-width-3-5"/>
        <div
          v-if="stats"
          class="uk-width-1-5"
        >
          <ul class="uk-list uk-list-divider uk-text-left">
            <li>
              <span>
                {{ $t('boards.users') }}:
                {{ stats.users }}
              </span>
            </li>
            <li>
              <span>
                {{ $t('boards.phones') }}:
                {{ stats.msidn }}
              </span>
            </li>
            <li>
              <span>
                {{ $t('boards.ADS-count') }}:
                {{ stats.ads_count }}
              </span>
            </li>
            <li>
              <span>
                {{ $t('boards.check-date') }}:
                {{ dayjs(stats.check_date, true) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from '@/utils/countryCodesNamesFull.json'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'BoardListItem',
  components: {
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton'))
  },
  props: {
    board: {
      type: Object,
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      countries: countries,
      stats: null
    }
  },
  computed: {
    ...mapGetters('ads', ['isLoaded']),
    extId () {
      return `boards-board-${this.board.id}`
    }
  },
  methods: {
    ...mapActions('ads', ['getBoardStats']),
    update () {
      this.getBoardStats(this.board.id).then(stats => {
        this.stats = stats
      })
    }
  },
  mounted () {
    return this.update()
  }
}
</script>
