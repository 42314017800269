<template>
  <div class="uk-flex uk-flex-column uk-width-1-1">
    <slot/>
    <button 
      class="uk-button uk-margin-remove-bottom uk-margin-small-top uk-align-center uk-button-default"
      @click="$emit('closeField')"
    >
      {{ $t('cases.notes.ok') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SingleUseField',
  emits: ['closeField']
}
</script>