<template>
  <div class="hoop uk-margin-top">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Hoop'
}
</script>

