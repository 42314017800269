import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class HoopChannelsAll extends Base {
  schema = Schemas.hoop.channel
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { query, channels } = options
    if (channels && channels.length) {
      return channels
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      while (localQuery.offset < this.total) {
        const { data } = await dispatch('fetchAllChannels', localQuery)
        const entry = data
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default HoopChannelsAll
