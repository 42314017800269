<template>
  <div class="uk-width-1-1">
    <ul class="uk-tab">
      <li
        v-for="route in routes"
        :key="route.name"
        :class="{
          'uk-active': currentRouteName === route.name
        }"
      >
        <router-link :to="{ name: route.name }">
          {{ route.content }}
        </router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'NewTasks',
  data () {
    return {
      routes: [
        {
          name: 'tgcp_tasks_all',
          content: this.$t('tgcp.tasks.all')
        },
        {
          name: 'tgcp_tasks_bots',
          content: this.$t('tgcp.tasks.bots')
        },
        {
          name: 'tgcp_tasks_clusters',
          content: this.$t('tgcp.tasks.cluster')
        },
        {
          name: 'tgcp_tasks_scheduled_search',
          content: this.$t('tgcp.tasks.scheduled-search')
        }
      ]
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  }
}
</script>
