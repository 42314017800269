import { apiRequest, getQueryString } from '@/utils/api'
import config from '@/config'

const { servers: { monitoring } } = config

export default {
  namespaced: true,
  state: {
    phoneStates: {},
    lastStatus: {},
    update: {},
    isLoaded: {}
  },
  getters: {
    phoneStates: state => phone => state.phoneStates[phone] || [],
    lastStatus: state => state.lastStatus,
    isLoaded: state => state.isLoaded
  },
  mutations: {
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    lastStatus: (state, payload) => {
      state.lastStatus[payload.phone_number] = payload
    },
    getPhoneStates: (state, { phone, states }) => {
      state.phoneStates[phone] = state.phoneStateslength ? [ ...state.phoneStates, ...states ] : states
    },
    setUpdate: (state, { phone, enable }) => {
      state.update[phone] = enable
    }
  },
  actions: {
    async getPhoneStates ({ commit, state }, { phone, query }) {
      if (state.lastStatus[phone] && state.lastStatus[phone].exists === false) {
        return new Error('No data for phone')
      }
      commit('startLoad', 'phone_states_' + phone)
      try {
        let { data } = await apiRequest(`get_states?phone_number=${phone.replace(/[+\s]/gi, '')}&${getQueryString(query)}`, 'GET', {}, monitoring)
        commit('getPhoneStates', { phone, states: data })
        return data
      } catch (err) {
        if (err.response && err.response.status === 404) {
          const data = {
            phone_number: phone,
            exists: false
          }
          commit('getPhoneStates', data)
        } else {
          console.dir(err)
          throw err
        }
      } finally {
        commit('stopLoad', 'phone_states_' + phone)
      }
    },
    async getLastStatus ({ commit, state, dispatch }, phone) {
      commit('startLoad', 'last_status_' + phone)
      if (state.lastStatus[phone] && state.lastStatus[phone].exists === false) {
        return new Error('No data for phone')
      }
      try {
        const { data } = await apiRequest(`get_last_status?phone_number=${phone.replace(/[+\s]/gi, '')}`, 'GET', {}, monitoring)
        const alreadyIn = state.lastStatus[phone]
        if (alreadyIn && state.update[phone]) {
          (data.last_status.timestamp !== alreadyIn.last_status.timestamp) &&
          await dispatch('getPhoneStates', { phone, query: { start: Math.round(new Date(alreadyIn.last_status.timestamp).getTime() / 1000) - 5 } })
        }
        commit('lastStatus', data)
        return data
      } catch (err) {
        if (err.response && err.response.status === 404) {
          const data = {
            phone_number: phone,
            exists: false
          }
          commit('lastStatus', data)
        } else {
          console.dir(err)
          throw err
        }
      } finally {
        commit('stopLoad', 'last_status_' + phone)
      }
    },
    async addMonitorTarget ({ dispatch, commit }, phone) {
      phone = String(phone)
      try {
        const { data } = await apiRequest('add_target?phone_number=' + phone.replace(/[+\s]/gi, '') + '&format=json', 'GET', {}, monitoring)
        await dispatch('getLastStatus', phone)
        return data
      } catch (err) {
        if (err.response && err.response.status === 409) {
          const data = {
            phone_number: phone,
            exists: null
          }
          commit('lastStatus', data)
        } else {
          console.dir(err)
          throw err
        }
      }
    }
  }
}
