<template>
  <div class="home uk-container">
    <div>
      <template v-if="hasSearch">
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="update"
        >
          <div class="uk-display-inline-block">
            <input
              v-model.trim="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="search"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
        <SearchInDbHint />
      </template>
      <div
        v-if="loading"
        class="uk-margin"
      >
        <Loader />
      </div>
      <template v-else>
        <NotFound
          v-if="loaded && topics.length === 0 && !forumId && !parentId"
          class="uk-margin-top"
        />
        <div
          class="uk-grid uk-margin-top uk-margin-bottom"
          data-uk-grid-margin
        >
          <div
            class="uk-grid-small
            uk-child-width-1-1@s uk-flex-left
            uk-width-1-1
            uk-grid filter_area"
          >
            <span
              v-for="topic in topics"
              :key="topic.id"
            >
              <Item
                :has-breadcrumbs="hasBreadcrumbs"
                :topic="topic"
                :level="level"
                :type="itemType"
                class="uk-margin-small-top"
              />
            </span>
          </div>
        </div>
        <div v-if="!hidePagination && loaded">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="changePageHandler"
            prev-text="<"
            next-text=">"
            :container-class="'uk-pagination uk-flex-center uk-margin-small-top'"
          />
        </div>
      </template>
    </div>
    <ExportDialog
      v-if="hasExport && level === 0"
      :id="String(userId || forumId)"
      :search="query.search"
      namespace="forums"
      type="topics"
      :subtype="type"
      :query="query"
      :totalCount="Number(pagination?.total ?? 0)"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import forumsSearch from '@/mixins/forumsSearch'
import vueSelect from '@/components/VueSelect'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'ForumsTopicsList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Paginate,
    Item: defineAsyncComponent(() => import('@/components/Forums/TopicList/Item.vue')),
    Loader,
    vueSelect
  },
  mixins: [forumsSearch],
  props: {
    userId: {
      type: [Number, String],
      required: false
    },
    forumId: {
      type: [Number, String],
      required: false
    },
    parentId: {
      type: [Number, String],
      default: '0'
    },
    level: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'followers', 'topic'].includes(val)
    }
  },
  data () {
    return {
      selectedMessages: [],
      topics: [],
      loading: false,
      isFinded: false,
      prevSearch: '',
      query: {
        limit: 20,
        offset: 0,
        order_by: (!this.userId && !this.forumId && !this.parentId && this.$route.query.order_by) || 'id',
        order_type: (!this.userId && !this.forumId && !this.parentId && this.$route.query.desc) || 'asc',
        search: (!this.userId && !this.forumId && !this.parentId && this.$route.query.search) || '',
        parent_id: this.userId ? undefined : this.parentId || '0'
      },
      pagination: {
        page: 1,
        total: null
      },
      orderList: ['id', 'name', 'description', 'creator_id', 'last_message_timestamp'],
      paramsToRoute: ['search', 'order_by', 'order_type', 'page']
    }
  },
  watch: {
    parentId () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('forums', ['isLoaded']),
    hasExport () {
      return (this.type !== 'all' || this.query.search) && this.topics.length
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('forums.users.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    isParrent () {
      return this.type === 'all'
    },
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    loaded () {
      if (this.userId) return this.isLoaded['topics_user_' + this.userId]
      if (this.forumId) return this.isLoaded['topics_forum_' + this.forumId]
      return this.isLoaded['topics']
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    itemType () {
      const { $route: { name }, level } = this
      if (level === 0 && ['forums_topics_list', 'forums_forum_details'].includes(name)) {
        return 'full'
      }
      return 'nested'
    },
    hasSearch () {
      return !this.level || (!!this.level && this.$route.name === 'forums_topic_details')
    },
    hasBreadcrumbs () {
      return !this.forumId || (this.prevSearch.length && this.isFinded)
    }
  },
  methods: {
    ...mapActions('forums', ['fetchAllTopics', 'fetchForumTopics', 'fetchUserTopics', 'addParamToQuery']),
    update () {
      const { userId, query, forumId, setData, clearData } = this
      this.loading = true
      let p
      let localQuery = {
        ...query,
        recursive: this.query.search ? true: void 0,
        parent_id: this.query.parent_id
      }
      if (userId) {
        p = this.fetchUserTopics({ userId, query: localQuery })
      } else if (forumId) {
        p = this.fetchForumTopics({ forumId, query: localQuery })
      } else {
        p = this.fetchAllTopics(localQuery)
      }
      this.prevSearch = ''
      this.isFinded = false
      p.then(setData).then(() => {
        this.prevSearch = this.query.search
        this.isFinded = true
      })
        .catch(clearData)
        .finally(() => (this.loading = false))
    },
    search () {
      this.updateQuery()
      this.update()
    },
    clearData (err) {
      this.topics = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err.message, type: 'error' })
    },
    setData (res) {
      this.topics = res.data
      this.pagination.total = res.meta.results_count
    }
  }
}
</script>
