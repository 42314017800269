<template>
  <div class="twitter  uk-margin-top">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Tiktok'
}
</script>
