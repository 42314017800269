<template>
  <tr class="uk-text-center uk-text-break">
    <td
      v-if="localTask"
      class="uk-text-break"
    >
      <a @click="search">
        {{ localTask.task }}
        {{ localTask.geo && [ geoSquare._southWest.lat, geoSquare._southWest.lng, geoSquare._northEast.lat, geoSquare._northEast.lng ] }}
      </a>
      <div>
        <GeoButtonTask
          v-if="localTask.geo"
          type="get"
          :geo="[ geoSquare._southWest.lat, geoSquare._southWest.lng, geoSquare._northEast.lat, geoSquare._northEast.lng ]"
        />
      </div>
    </td>
    <td class="uk-text-break">
      {{ localTask.description }}
    </td>
    <td class="uk-text-break">
      {{ localTask.sync_date ? dayjs(localTask.sync_date) : $t('twitter.tasks.waiting-for-sync') }}
    </td>
    <td class="uk-text-break">
      {{ dayjs(localTask.created) }}
    </td>
    <td
      :class="{
        'uk-disabled': loading
      }"
    >
      <div
        class="uk-flex uk-width-1-1 uk-flex-between uk-flex-middle"
      >
        <AddStoredButton
          provider="twitter"
          :ext_id="extId"
          type="task"
          :data="task"
        />
        <a
          :disabled="localTask.geo"
          uk-icon="icon: bolt; ratio: 1"
          value="null"
          class="uk-margin-small-right"
          :uk-tooltip="'title: ' + $t('tgcp.tasks.real-time')"
          :class="{
            'uk-text-primary' : localTask.realtime || localTask.geo,
            'uk-disabled': localTask.geo
          }"
          @click="updateRealtime"
        />
        <a
          :uk-tooltip="`title: ${$t('tgcp.tasks.priority_field_title')}`"
          uk-icon="icon: warning; ratio: 1"
          :value="null"
          :class="localTask.priority > 4 ? 'uk-text-primary' : 'uk-text-muted'"
          @click="updatePriority"
        />
        <button
          class="uk-button uk-button-text uk-margin-left-small"
          type="button"
          style="height:40px;width:40px;"
          :uk-tooltip="$t('twitter.tasks.delete')"
          @click="$emit('delete', localTask.id)"
        >
          <span uk-icon="trash"/>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import L from 'leaflet'
import { defineAsyncComponent } from 'vue'

import { mapActions } from 'vuex'
export default {
  name: 'TwitterTasksListItem',
  components: {
    GeoButtonTask: defineAsyncComponent(() => import('@/components/Twitter/Tasks/GeoButtonTask.vue')),
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton'))
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localTask: null,
      loading: false
    }
  },
  computed: {
    geoSquare () {
      if (!this.task.geo) return
      const geo = this.task.geo
      return new L.latLngBounds(new L.latLng({ lat: geo[0].lat.toFixed(4), lng: geo[0].lon.toFixed(4) }), new L.latLng({ lat: geo[1].lat.toFixed(4), lng: geo[1].lon.toFixed(4) }))
    },
    extId () {
      return `twitter-task-${this.task.id}`
    }
  },
  methods: {
    ...mapActions('twitter', [
      'editTask'
    ]),
    search () {
      const task = this.task.task
      // eslint-disable-next-line camelcase
      if (this?.task?.user_id) {
        return this.$router.push({ name: 'twitter_user_details', params: { id: String(this.task.user_id) } })
      }
      if (this.task.geo && this.task.geo.length) {
        return this.$router.push({ name: 'twitter_messages_list', query: { bbox: `${this.geoSquare._southWest.lat},${this.geoSquare._southWest.lng},${this.geoSquare._northEast.lat},${this.geoSquare._northEast.lng}` } })
      }
      switch (task[0]) {
          case '#':
            return this.$router.push({ name: 'twitter_hashtag_list', query: { search: task.substring(1) } })
          case '@':
            return this.$router.push({ name: 'twitter_user_list', query: { search: task.substring(1) } })
          default:
            return this.$router.push({ name: 'twitter_messages_list', query: { search: task } })
      }
    },
    async updatePriority () {
      this.loading = true
      try {
        this.localTask = await this.editTask({ id: this.task.id, task: { priority: +this.localTask.priority > 4 ? 1 : 5 } })
      } catch {
        this.reset()
      } finally {
        this.loading = false
      }
    },
    async updateRealtime () {
      this.loading = true
      try {
        this.localTask = await this.editTask({ id: this.task.id, task: { realtime: !this.localTask.realtime } })
      } catch {
        this.reset()
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.localTask = { ...this.task }
    }
  },
  created () {
    this.reset()
  }
}
</script>
