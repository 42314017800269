<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div class="uk-margin">
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
        >
          <div class="uk-display-inline-block">
            <input
              v-model.trim="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              @keyup.enter="searchHandler"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="searchHandler"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block uk-position-relative">
            <flatPickr
              v-model="fromDate"
              :placeholder="$t('tgcp.messages.from_date')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              :config="{
                maxDate: toDate || new Date()
              }"
            />
            <button
              v-if="fromDate"
              class="uk-position-right uk-animation-fade uk-animation-fast"
              uk-icon="close"
              style="z-index:2;padding-right: 5px"
              @click="fromDate = null"
            />
          </div>
          <div class="uk-display-inline-block uk-position-relative">
            <flatPickr
              v-model="toDate"
              :placeholder="$t('tgcp.messages.to_date')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              :config="{
                minDate: fromDate || '2010-05-11',
                maxDate: new Date()
              }"
            />
            <button
              v-if="toDate"
              class="uk-position-right uk-animation-fade uk-animation-fast"
              uk-icon="close"
              style="z-index:2;padding-right: 5px"
              @click="toDate = null"
            />
          </div>
        </form>
        <SearchInDbHint />
      </div>
      <div v-if="!isLoaded['goods']">
        <Loader />
      </div>
      <div v-else>
        <template v-if="isLoaded['goods'] && goods.length === 0">
          <NotFound/>
        </template>
        <template v-else>
          <Item
            v-for="good in goods"
            :key="good.id"
            :good="good"
            class="uk-margin-top"
          />
        </template>
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="changePageHandler"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="userId || forumId"
      :search="query.search"
      namespace="forums"
      type="goods"
      :subtype="type"
      :query="query"
      :messages="selectedMessages"
      :totalCount="pagination?.total ?? 0"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Item from '@/components/Forums/GoodsList/Item'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import forumsSearch from '@/mixins/forumsSearch'
import vueSelect from '@/components/VueSelect'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'GoodList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item,
    Loader,
    Paginate,
    vueSelect,
    flatPickr
  },
  mixins: [forumsSearch],
  props: {
    forumId: {
      type: [String, Number]
    },
    userId: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'forum'].includes(val)
    }
  },
  data () {
    return {
      selectedMessages: [],
      goods: [],
      orderList: ['id', 'user_id', 'ext_id', 'title', 'category', 'description', 'url'],
      query: {
        search: '',
        limit: 40,
        offset: 0,
        order_by: 'id',
        order_type: 'desc',
        from_date: null,
        to_date: null
      },
      pagination: {
        page: 1,
        total: null
      },
      paramsToRoute: ['search', 'order_by', 'order_type', 'page', 'from_date', 'to_date']
    }
  },
  computed: {
    ...mapGetters('forums', ['isLoaded']),
    hasExport () {
      return (this.type !== 'all' || this.query.search) && this.goods.length
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('forums.users.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    toDate : {
      get () {
        return this.query.to_date ?? null
      },
      set (value) {
        if (this.query.to_date === value) return
        this.query.to_date = value
        this.updateQuery()
        this.searchHandler()
      }
    },
    fromDate : {
      get () {
        return this.query.from_date ?? null
      },
      set (value) {
        if (this.query.from_date === value) return
        this.query.from_date = value
        this.updateQuery()
        this.searchHandler()
      }
    },
    isParrent () {
      return this.type === 'all'
    },
    hidePagination () {
      return this.pagination.total < this.query.limit && this.isLoaded['goods']
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('forums', ['fetchAllGoods', 'fetchForumGoods', 'fetchUserGoods', 'addParamToQuery']),
    setData (res) {
      this.goods = res.data
      this.pagination.total = res.meta.results_count
    },
    clearData () {
      this.goods = []
      this.pagination.page = 1
      this.pagination.total = null
    },
    async update () {
      const { query, forumId, type, userId, setData, clearData } = this
      try {
        switch (type) {
            case 'forum':
              return setData(await this.fetchForumGoods({ query, forumId }))
            case 'user':
              return setData(await this.fetchUserGoods({ query, userId }))
            default:
              return setData(await this.fetchAllGoods(query))
        }
      } catch {
        clearData()
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      }
    }
  }
}
</script>
