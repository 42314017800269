<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <ListSettings
        :messages="selectedMessages"
        @update="update"
        @deselect="selectedMessages = []"
      />
      <SearchInDbHint />
      <div v-if="!isLoaded">
        <loading />
      </div>
      <div v-else>
        <div class="uk-margin-top">
          <div
            v-for="message in messages"
            :key="`${message.chat_id}_${message.message_id}`"
            class="uk-card uk-card-default uk-card-small uk-margin-top"
          >
            <MessageListMiniItem
              :user="user[message.user_id]"
              :chat="chat(message.chat_id)"
              :message="message"
              :checked="!!selectedMessages.find(el => el.message_id === message.message_id)"
              :showCheckbox="!!selectedMessages.length"
              @showNested="messageSelected = message"
              @message-select="selectedMessagesComputed = $event"
            />
          </div>
          <ModalCenter
            v-if="messageSelected"
            :message="messageSelected"
            @hidden="messageSelected = null"
          >
            <MessageListMiniItemModal :message="messageSelected" />
          </ModalCenter>
        </div>
        <div
          v-if="!hidePagination"
          class="uk-margin"
        >
          <ul class="uk-pagination uk-flex-center">
            <li
              class="uk-inline uk-margin-small-right"
              :class="+currentPage === 1 && 'uk-disabled'"
            >
              <a
                class="uk-position-center"
                @click="prevPage"
              >
                <span uk-pagination-previous/>
              </a>
            </li>
            <li
              class="uk-padding-remove"
            >
              <input
                v-model="goToPage"
                class="uk-input uk-text-center uk-form-blank uk-text-truncate"
                style="max-width: 250px; width: 40px"
                :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
                @change="jumpToPage"
                @focus="$event.target.select()"
              />
            </li>
            <li
              class="uk-inline uk-margin-small-left"
              :class="isNextPage ? 'uk-enabled' : 'uk-disabled'"
            >
              <a
                class="uk-position-center"
                @click="nextPage"
              >
                <span uk-pagination-next/>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <NotFound v-if="isLoaded && !hasDataToView" />
    </div>
    <ExportDialog
      v-if="hasExport"
      namespace="tgcp"
      :search="search"
      type="messages"
      subtype="all"
      :totalCount="0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import loading from '@/components/TGCP/Loader'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'MessageList',
  components: {
    ListSettings: defineAsyncComponent(() => import('@/components/TGCP/MessageList/ListSettings')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    MessageListMiniItem: defineAsyncComponent({
      loader: () => import('@/components/TGCP/MessageList/MessageListMiniItem'),
      loadingComponent: loading,
      delay: 200
    }),
    MessageListMiniItemModal: defineAsyncComponent(() => import('@/components/TGCP/MessageList/MessageListMiniItemModal')),
    ModalCenter: defineAsyncComponent(() => import('@/components/ModalCenter')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    loading
  },
  data () {
    return {
      messages: [],
      users: {},
      userChats: {},
      messageSelected: null,
      goToPage: this.currentPage,
      selectedMessages: []
    }
  },
  watch: {
    '$route.query' () {
      if (event instanceof PopStateEvent) { // юзер нажал вперед/назад
        this.init()
      }
    },
    goToPage (newVal, oldVal) {
      if (!/^[0-9]*$/.test(newVal) || !+newVal) {
        this.goToPage = oldVal
      }
    },
    currentPage (to, from) {
      this.goToPage = to
    }
  },
  computed: {
    ...mapGetters('tgcp', ['currentPage', 'allListMessages', 'isNextPage', 'limit']),
    search () {
      const search = this?.$route?.query?.search
      if (typeof search === 'string') {
        return search
      }
      return ''
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['message']
    },
    hasDataToView () {
      return this.messages.length > 0
    },
    hidePagination () {
      if (this.currentPage === 1 && this.isNextPage === false) return true
      else return false
    },
    selectedMessagesComputed: {
      get () {
        return this.selectedMessages
      },
      set (message) {
        const id = message.chat_id + message.message_id
        if (this.selectedMessages.find(el => el.chat_id + el.message_id === id)) {
          this.selectedMessages = this.selectedMessages.filter(el => el.chat_id + el.message_id !== id)
        } else {
          this.selectedMessages = [ message, ...this.selectedMessages ]
        }
      }
    },
    chat: {
      set (chats) {
        chats.forEach(chat => {
          this.userChats[chat.tg_id] = chat
        })
      },
      get () {
        return id => this.userChats[id]
      }
    },
    user: {
      set (users) {
        users.forEach(user => {
          this.users[user.tg_id] = user
        })
      },
      get () {
        return this.users
      }
    },
    hasExport () {
      return (this?.$route?.path === '/tgcp/messages') ? (this.search && this.messages.length > 0) : this.messages.length > 0
    }
  },
  methods: {
    ...mapMutations('tgcp', ['clearQueryString']),
    ...mapActions('tgcp', [
      'fetchAllMessages',
      'setPage',
      'addParamToQuery',
      'addHiddenParamToQuery',
      'clearMessagesToView',
      'getBatchChats',
      'getBatchUsers'
    ]),
    async init () {
      const { query } = this.$route
      let offset = query.offset || 0
      const page = Math.floor(offset / (this.limit - 1) + 1)
      this.goToPage = page || 1
      offset = (this.limit - 1) * page - (this.limit - 1)
      if (offset) await this.addParamToQuery({ offset })
      this.clearQueryString()
      this.addHiddenParamToQuery({
        order_type: query.order_type || 'desc',
        order_by: query.order_by || 'date'
      })
      this.update()
      this.setPage(page || 1)
    },
    async nextPage () {
      const offset = (this.limit - 1) * this.currentPage
      await this.addParamToQuery({ offset })
      this.setPage(this.currentPage + 1)
      this.update()
    },
    async prevPage () {
      const limit = this.limit - 1
      const offset = limit * this.currentPage - limit * 2
      await this.addParamToQuery({ offset })
      this.setPage(this.currentPage - 1)
      this.update()
    },
    async update () {
      let messages = []
      try {
        messages = await this.fetchAllMessages()
        if (messages === null) return
        messages = messages.length === this.limit ? messages = messages.slice(0, -1) : messages
        this.userChats = {}
        this.users = {}
        this.user = await this.getBatchUsers({ IDs: [ ...new Set(messages.map(el => el.user_id).filter(el => el)) ] })
        this.chat = await this.getBatchChats({ IDs: [ ...new Set(messages.map(el => el.chat_id).filter(el => el)) ] })
        this.messages = messages
      } catch (err) {
        console.dir(err)
        if (err) this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
      }
    },
    async jumpToPage ({ target }) {
      for (let i = 5; i > 0; i--) {
        await this.addParamToQuery({ offset: (this.limit - 1) * (--target.value) })
        await this.update()
        if (this.messages.length) {
          this.setPage(target.value)
          return
        }
      }
      await this.addParamToQuery({ offset: (this.limit - 1) * (this.currentPage - 1) })
      await this.update()
      this.setPage(this.currentPage)
      this.$notify({ group: 'general', title: `Page not found`, type: 'error' })
    }
  },
  async mounted () {
    this.init()
  },
  beforeUnmount () {
    this.messageSelected = null
    this.clearQueryString()
    this.clearMessagesToView()
  }
}
</script>
