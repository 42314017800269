<template>
  <div class="phone_monitoring">
    <div class="uk-card uk-card-default uk-margin-top uk-card-body uk-text-left">
      <div class="uk-card-title">
        {{ $t('osint.face-search') }}
      </div>
      <p>
        {{ $t('osint.image-search.face-search-description') }}
      </p>
      <ul uk-tab>
        <li class="uk-active">
          <a
            id="link_tab"
            href="#link"
          >
            By Link
          </a>
        </li>
        <li>
          <a
            id="upload_tab"
            href="#upload"
          >
            Upload
          </a>
        </li>
      </ul>
      <ul class="uk-switcher">
        <li>
          <a name="link_tab" />
          <div>
            <form
              class="uk-width-1-1 uk-float-right uk-form-horizontal"
              @submit.prevent="searchByUrl"
            >
              <input
                v-model.trim="url"
                class="uk-input uk-width-1-4"
                type="text"
                :class="errors.phone && 'uk-form-danger'"
                placeholder="Image url"
              /><br /><br />
              <button
                class="uk-button uk-button-primary"
                style="width:200px"
                type="submit"
              >
                <Loader
                  v-if="!isLoaded['face_search_services']"
                  :ratio="1"
                />
                <span
                  v-else
                >
                  {{ $t('utils.search') }}
                </span>
              </button>
            </form>
          </div>
        </li>
        <li>
          <a name="upload_tab" />
          <div>
            <form
              class="uk-width-1-1 uk-float-right uk-form-horizontal"
              @submit.prevent="searchByImage"
            >
              <DragDropTask @droppedFiles="prepareFileSelect" /> <img
                v-if="selectedFile && dataURL"
                :src="dataURL"
                style="max-width: 160px;max-height: 100px;padding-left: 10px;"
              /><br />
              <button
                class="uk-button uk-button-primary"
                style="width:200px"
                type="submit"
              >
                <Loader
                  v-if="!isLoaded['face_search_services']"
                  :ratio="1"
                />
                <span
                  v-else
                >
                  {{ $t('utils.search') }}
                </span>
              </button>
            </form>
          </div>
        </li>
      </ul>
      <div class="results">
        <h3 v-if="isLoaded['face_search_services'] && faceSearchResult.payload">
          {{ $t('osint.image-search.search-results-title') }}
        </h3>
      </div>
      <div
        class="uk-width-1-1 uk-margin-top uk-text-center"
      >
        <div
          v-if="isLoaded['face_search_services'] && faceSearchResult.payload && faceSearchResult.payload.error"
        >
          <span class="uk-text-danger">
            {{ $t('osint.image-search.' + faceSearchResult.payload.error ) }}
          </span>
        </div>
        <div v-else-if="isLoaded['face_search_services'] && faceSearchResult.payload && faceSearchResult.payload.result && faceSearchResult.payload.result.length===0">
          {{ $t('osint.image-search.nothing-found') }}
        </div>
        <div
          v-else-if="isLoaded['face_search_services'] && faceSearchResult.payload && faceSearchResult.payload.result"
        >
          <div
            v-for="(item, key) in faceSearchResult.payload.result"
            :key="key"
          >
            <hr />
            <div class="row">
              <h5>
                Face #{{ key+1 }}
              </h5><img
                :src="`data:image/gif;base64,${item.face_image}`"
                style="max-width:160px; max-height:160px"
              />
            </div>
            <hr />
            <div
              v-for="(result,index) in item.found_images"
              :key="`image_result_${key}_${index}`"
              class="row uk-grid-small uk-margin-small-bottom uk-flex"
            >
              <div class="uk-width-1-6@m uk-first-column">
                <a

                  :href="result.link"
                >
                  <img
                    style="max-width:80%"
                    :src="`https://gw.sma.services${result.image}`"
                  />
                </a>
              </div>
              <div class="uk-width-1-6@m uk-text-left">
                <div>
                  {{ $t('osint.image-search.' + result.module + '_' + result.type) }}
                </div>
                <div>
                  <a :href="result.link">
                    # {{ result.id }}
                  </a>
                </div>
              </div>
              <div class="uk-width-1-6@m uk-text-left">
                {{ result.score }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SearchingFacesDialog
      :title="$t('osint.image-search.select-face')"
      :entries="availableFaces"
      @select="select"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import InputedFilesReader from '@/utils/InputedFilesReader'
import SearchingFacesDialog from './SearchingFaces/Dialog.vue'
import base64Blob from '@/utils/base64Blob.js'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'FaceSearch',
  components: {
    Loader,
    DragDropTask: defineAsyncComponent(() => import('@/components/DragDropFile')),
    SearchingFacesDialog
  },
  data () {
    return {
      errors: {},
      selectedFile: null,
      initialLoad: true,
      url: this?.$route?.query?.face || '',
      description: '',
      showLoader: false,
      timeout: null,
      dataURL: false
    }
  },
  watch: {
    isLoaded: {
      deep: true,
      handler (value) {
        if (!value.face_search_services) {
          this.timeout = setTimeout(() => {
            this.showLoader = true
          }, 200)
        } else {
          this.showLoader = false
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }
    }
  },
  computed: {
    ...mapGetters('fetcher', [
      'isLoaded',
      'faceSearchResult'
    ]),
    filePreview () {
      return URL.createObjectURL(this.selectedFile)
    },
    availableFaces () {
      return this?.faceSearchResult?.payload?.['available_faces'] || []
    }
  },
  methods: {
    ...mapActions('fetcher', [
      'fetchSimilarImagesByFile',
      'fetchSimilarImagesByUrl'
    ]),
    showError (msg) {
      this.$notify({ group: 'general', title: this.$i18n.t(msg), text: '', type: 'error' })
    },
    async prepareFileSelect (files) {
      if (files.length) {
        let file = files.item(0)
        if (file.type.indexOf('image') < 0) {
          this.selectedFile = false
          this.dataURL = false
          return this.showError('osint.image-search.error-notimage')
        }
        try {
          const InputedFilesReaderInstance = new InputedFilesReader()
          InputedFilesReaderInstance.onload = event => {
            try {
              if (event?.target?.result) {
                this.dataURL = event.target.result
              }
            } catch (error) {
              console.debug(error)
            }
          }
          InputedFilesReaderInstance.read(file)
        } catch (error) {
          console.debug(error)
        }
        this.selectedFile = file
      }

    },
    update () {
    },
    searchByUrl () {
      this.fetchSimilarImagesByUrl({ imageUrl: this.url, type: 'face' })
    },
    searchByImage () {
      this.fetchSimilarImagesByFile({ file: this.selectedFile, type: 'face' })
    },
    select (entry) {
      try {
        if (typeof entry !== 'string') {
          console.debug('argument "entry" is not a string')
          return
        }
        if (!entry) {
          console.debug('argument "entry" is empty')
          return
        }
        const currentBlob = base64Blob(entry)
        if (!(currentBlob instanceof Blob)) {
          console.debug('"const currentBlob" is not a Blob')
          return
        }
        const file = new File([currentBlob], 'image')
        if (!(file instanceof File)) {
          console.debug('"const file" is not a File')
          return
        }
        this.dataURL = 'data:image/jpeg;base64,'.concat(entry)
        this.fetchSimilarFacesByFile(file)
      } catch (error) {
        console.debug(error)
      }
    }
  },
  created () {
    try {
      if (this?.$route?.query?.face) {
        this.searchByUrl(this.url)
      }
    } catch (error) {
      console.debug(error)
    }
  },
  mounted () {
    this.update()
  }
}
</script>