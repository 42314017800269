import router from '@/router'
import config from '@/config'
import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'
const { servers: { imo } } = config

const flatternObj = (obj) => {
  const flattened = {}
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flatternObj(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export default {
  namespaced: true,
  state: {
    groups: {},
    groupHistory: {},
    groupActivity: {},
    groupMessages: {},
    users: {},
    userHistory: {},
    userActivity: {},
    userMessages: {},
    messages: [],
    countries: [],
    languages: [],
    tasks: {},
    stats: {},
    searchResults: [],
    isLoaded: {},
    translatedCache: new Map(),
    cancelSources: {}
  },
  getters: {
    allGroups: state => state.groups,
    groupHistory: state => state.groupHistory,
    groupActivity: state => state.groupActivity,
    groupMessages: state => state.groupMessages,
    allUsers: state => state.users,
    userHistory: state => state.userHistory,
    userActivity: state => state.userActivity,
    userMessages: state => state.userMessages,
    allMessages: state => state.messages,
    countries: state => state.countries,
    languages: state => state.languages,
    isLoaded: (state) => state.isLoaded,
    stats: (state) => state.stats,
    allTasks: (state) => state.tasks
  },
  mutations: {
    setCancel (state, { prop, source }) {
      state.cancelSources[prop] = source
    },
    unsetCancel (state, { prop }) {
      delete state.cancelSources[prop]
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addGroupData: (state, payload) => {
      if (!payload.bgid) return
      state.groups[payload.bgid] = payload
    },
    addHistory: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state[`${payload.type}History`][payload.id] = payload.data
    },
    addActivity: (state, payload) => {
      if (!(payload.id in state[`${payload.from}Activity`])) {
        state[`${payload.from}Activity`][payload.id] = {}
      }
      // Check how empty response looks to override correctly
      if (payload.type === 'members' || payload.type === 'groups') {
        if (!payload.data || payload.data?.length === 0) return
      } else {
        if (!payload.data || Object.keys(payload.data).length === 0) return
      }
      state[`${payload.from}Activity`][payload.id][payload.type] = payload.data
    },
    addGroupMessages: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state.groupMessages[payload.id] = payload.data
    },
    addUserData: (state, payload) => {
      if (!payload.discover_anon_id) return
      state.users[payload.discover_anon_id] = payload
    },
    addUserMessages: (state, payload) => {
      if (!payload.id || payload.data?.length === 0) return
      state.userMessages[payload.id] = payload.data
    },
    addMessages: (state, payload) => {
      state.messages = payload.data
    },
    addLanguages: (state, payload) => {
      state.languages = payload.data
    },
    addCountries: (state, payload) => {
      state.countries = payload.data
    },
    addTask: (state, task) => {
      if (task.id) state.tasks[task.id] = task
    },
    addTasksBatch: (state, tasks) => {
      tasks.forEach(task => state.tasks[task.id] = task)
    },
    deleteTask: (state, taskId) => {
      delete state.tasks[taskId]
    },
    clearGroups: (state) => {
      delete state.groups
      state.groups = {}
    },
    updateStats: (state, stats) => {
      state.stats = stats
    }
  },
  actions: {
    checkError ({ commit, dispatch }, err) {
      if (err.response && err.response.status === 401) {
        if (err.response.data.code === 'TOKEN_EXPIRED') {
          dispatch('refresh', null, { root: true }).then(() => {
            if (err.response.callAgain) {
              // dispatch(err.response.callAgain.name, err.response.callAgain.argument, { root: true })
            }
          })
        }
      }
    },
    async fetchStats ({ commit, state, dispatch, rootState }) {
      if (!state.stats.hasOwnProperty('groups_count')) {
        commit('startLoad', `stats`)
      }
      try {
        const { data } = await apiRequest(`/stats`, 'GET', {}, imo)
        commit('updateStats', data)
      } 
      catch (err) {
        console.error(err)
      } 
      finally {
        commit('stopLoad', `stats`)
      }
    },
    async fetchHistory ({ commit, state, dispatch, rootState }, { id, type }) {
      commit('startLoad', `${type}_${id}_history`)

      if (id in state.groupHistory || id in state.userHistory) {
        commit('stopLoad', `${type}_${id}_history`)
        return
      }

      try {
        const { data: { data }} = await apiRequest(`/${type}s/${type === 'user'? 'by-discover-anon-id/' : ''}${encodeURIComponent(id)}/history`, 'GET', {}, imo)
        commit('addHistory', { id, data, type })
      }
      catch (err) {
        console.log(err)
      } finally {
        commit('stopLoad', `${type}_${id}_history`)
      }
    },
    async fetchActivity ({ commit, state, dispatch, rootState }, { id, type, from }) {
      commit('startLoad', `${from}_${id}_activity_${type}`)

      let req_path = ''
      if (from === 'group') req_path = `/groups/${id}`
      else if (from === 'user') req_path = `/users/by-discover-anon-id/${encodeURIComponent(id)}`

      if (id in state[`${from}Activity`] && state[`${from}Activity`][id][type]) {
        commit('stopLoad', `${from}_${id}_activity_${type}`)
        return
      }

      try {
        if (type !== 'members' && type !== 'groups') {
          const { data: { activity: data }} = await apiRequest(`${req_path}/activity/${type}`, 'GET', {}, imo)
          commit('addActivity', { id, data, type, from })
        } else {
          const { data } = await apiRequest(`${req_path}/activity/${type}`, 'GET', {}, imo)
          commit('addActivity', { id, data, type, from })
        }
      }
      catch (err) {
        console.debug(err)
      } finally {
        commit('stopLoad', `${from}_${id}_activity_${type}`)
      }
    },
    async fetchGroupById ({ state, commit }, id) {
      commit('startLoad', `group_${id}`)

      try {
        const { data } = await apiRequest(`/groups/${id}`, 'GET', {}, imo)
        commit('addGroupData', {
          ...data,
          answer_status: 'FOUND'
        })
      } catch {
        if (!(id in state.groups)) {
          commit('addGroupData', {
            bgid: id,
            answer_status: 'NOT_FOUND'
          })
        }
      } finally {
        commit('stopLoad', `group_${id}`)
      }
    },
    async fetchAllGroups ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'groups')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'groups_all'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/groups${queryString}`, 'GET', {}, imo, undefined, cancellation)
        data.forEach(group => {
          commit('addGroupData', group)
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', 'groups')
      }
    },
    async fetchUserGroups ({ dispatch, commit, state, rootState }, params) {
      commit('startLoad', `groups_${params.id}`)

      let { id, path, query, ignoreState = false } = params

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'groups_' + id
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/users/by-discover-anon-id/${encodeURIComponent(id)}${path}${queryString}`, 'GET', {}, imo, undefined, cancellation)
        if (!ignoreState) data.forEach(user => {
          commit('addGroupData', user)
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', `groups_${params.id}`)
      }
    },
    async loadFilters ({ state, commit }) {
      if (!Object.keys({ ...state.languages, ...state.countries }).length) {
        commit('startLoad', `filters`)
      }
      try {
        const { data: langs } = await apiRequest(`/groups/languages`, 'GET', {}, imo)
        commit('addLanguages', langs)
        const { data: counts } = await apiRequest(`/groups/countries`, 'GET', {}, imo)
        commit('addCountries', counts)
      } 
      catch (err) {
        console.error(err)
      } 
      finally {
        commit('stopLoad', `filters`)
      }
    },
    async fetchUserById ({ state, commit }, id) {
      commit('startLoad', `user_${id}`)

      try {
        const { data } = await apiRequest(`/users/by-discover-anon-id/${encodeURIComponent(id)}`, 'GET', {}, imo)
        commit('addUserData', {
          ...data,
          answer_status: 'FOUND'
        })
      } catch {
        if (!(id in state.users)) {
          commit('addUserData', {
            discover_anon_id: id,
            answer_status: 'NOT_FOUND'
          })
        }
      } finally {
        commit('stopLoad', `user_${id}`)
      }
    },
    async fetchAllUsers ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'users')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'users_all'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/users${queryString}`, 'GET', {}, imo, undefined, cancellation)
        data.forEach(user => {
          commit('addUserData', user)
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', 'users')
      }
    },
    async fetchGroupUsers ({ dispatch, commit, state, rootState }, params) {
      commit('startLoad', `users_${params.id}`)

      let { id, path, query } = params

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'users_' + id
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`/groups/${id}${path}${queryString}`, 'GET', {}, imo, undefined, cancellation)
        data.forEach(user => {
          commit('addUserData', user)
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', `users_${params.id}`)
      }
    },
    async fetchAllMessages ({ dispatch, commit, state, rootState }, params) {
      const { id, query, type } = params
      let req_path = ''
      if (type === 'group') req_path = `/groups/${id}`
      else if (type === 'user') req_path = `/users/by-discover-anon-id/${encodeURIComponent(id)}`

      commit('startLoad', id ? `${type}_${id}_messages` : 'messages')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false

      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = id ? `${type}_messages` : 'messages_all'
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data, meta } } = await apiRequest(`${req_path}/messages${queryString}`, 'GET', {}, imo, undefined, cancellation)
        data.forEach(message => {
          message.group = {
            bgid: id && type === 'group' ? id : message.group.bgid,
            name: id && type === 'group' ? state.groups[id]?.name : message.group.name
          }
          message.author = {
            discover_anon_id: message.author.discover_anon_id,
            nickname: message.author.nickname,
            avatar_cdn: message.author.avatar_cdn
          }
        })
        return { data, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled) && commit('stopLoad', id ? `${type}_${id}_messages` : 'messages')
      }
    },
    async getMessageOffset ({ commit }, { bgid, msg_seq }) {
      if (!bgid || !msg_seq ) return
      const { data: offset } = await apiRequest(`/groups/${bgid}/messages/${msg_seq}/offset?order_by=created_at&order_type=DESC`, 'GET', {}, imo)
      return offset
    },
    fetchTaskById (_, id) {
      return apiRequest(`/tasks/${id}`, 'GET', {}, imo)
    },
    async fetchAllTasks ({ commit }, query) {
      try {
        const queryString = getQueryString(query)
        const { data } = await apiRequest(`/tasks/?${queryString}`, 'GET', {}, imo)
        commit('addTasksBatch', data.data)
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'twitter_tasks', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'twitter_tasks', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async addTask ({ commit }, task) {
      commit('startLoad', 'add_task')
      try {
        const { status, data } = await apiRequest(`/tasks`, 'POST', {}, imo, task)
        
        commit('stopLoad', 'add_task')
        return { status, data }
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        commit('stopLoad', 'add_task')
        throw err
      }
    },
    async massTaskAdd ({ commit, dispatch, rootState }, { tasks }) {
      commit('startLoad', 'tasks_batch')

      try {
        const { status, data } = await apiRequest(`/tasks/batch`, 'POST', {}, imo, tasks)
        switch (status) {
          case 200:
            if (data.created && data.created.length !== 0) {
              notify({ group: 'general', text: `Created ${data.created.length} tasks`, type: 'success' })
            }
            if (data.exists && data.exists.length !== 0) {
              notify({ group: 'general', text: `${data.exists.length} tasks exist`, type: 'warn' })
            }
            if (data.errors && data.errors.length !== 0) {
              notify({ group: 'general', text: `${data.errors.length} errors while creating tasks`, type: 'error' })
            }
            break
        }
        commit('stopLoad', 'tasks_batch')
        return data
      } catch (err) {
        commit('stopLoad', 'tasks_batch')
        throw err
      }
    },
    async editTask ({ commit }, { id, task }) {
      try {
        const { status, data } = await apiRequest(`/tasks/${id}`, 'PUT', {}, imo, task)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Updated', type: 'success' })
            break
        }
        return data
      } catch (err) {
        throw err
      }
    },
    async deleteTask ({ commit, dispatch, rootState }, taskId) {
      await apiRequest(`/tasks/` + taskId, 'DELETE', {}, imo)
      commit('deleteTask', taskId)
    },
    translateText: async ({ state, rootState }, { text }) => {
      const translateTo = rootState.settings.translate
      try {
        const translatedCacheKey = JSON.stringify({
          translateTo,
          text
        })
        const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
        if (!hasTranslatedCache) {
          const {
            data: { translation }
          } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
            lang: translateTo,
            text: text
          })
          state.translatedCache.set(translatedCacheKey, { data: { translation } })
          return {
            data: {
              translation
            }
          }
        }
        return state.translatedCache.get(translatedCacheKey)
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    },
    clearGroups ({ commit }) {
      commit('clearGroups')
    },
    async addParamToQuery ({ commit }, param) {
      const oldQuery = router.currentRoute.value.query
      const newQuery = { ...param }
      Object.keys(newQuery).forEach((k) => !newQuery[k] && delete newQuery[k])
      if (!(Object.keys(oldQuery).every((key) =>  String(oldQuery[key]) === String(newQuery[key] ?? ''))
        && Object.keys(newQuery).every((key) =>  String(newQuery[key]) === String(oldQuery[key] ?? ''))))
        router.replace({ query: newQuery })
    }
  }
}
