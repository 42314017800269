import Base from '../../../Base.js'
import Schemas from '../../../../Schemas'

class WhatsappFormerUsersAdmins extends Base {
  schema = Schemas.whatsapp.user
  OPTIONS_SCHEMA = ['id', 'query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { id, query, users } = options
    if (!!users && users.length) {
      return users
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      while (localQuery.offset < this.total) {
        const params = {
          query: localQuery,
          type: 'admins/former',
          groupId: id
        }
        const { data } = await dispatch('fetchGroupUsers', params)
        let entry = data
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default WhatsappFormerUsersAdmins
