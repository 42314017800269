<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <form>
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
        />
        <vueSelect
          v-model="currentSearchType"
          :options="searchTypeTranslated"
          label="label"
          class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click.prevent="search"
        >
          <span uk-search-icon/>
        </button>
      </form>
      <SearchInDbHint />
    </div>

    <div
      class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
      uk-grid
    >
      <div>
        <router-link
          to="/tgcp/user"
          class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.users_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.users_count') }}
          </h3>
        </router-link>
      </div>
      <div>
        <router-link
          to="/tgcp/channel"
          class="uk-card uk-card-small  uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.chats_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.chats_count') }}
          </h3>
        </router-link>
      </div>
      <div>
        <router-link
          to="/tgcp/tasks"
          class="uk-card uk-card-small uk-card-secondary  uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.tasks_count) }} ({{ numberFormat(stats.tasks_success_perc) }}%)
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.tasks_count') }}
          </h3>
        </router-link>
      </div>
      <div>
        <div class="uk-card uk-card-small  uk-card-default uk-card-body uk-dark">
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.avatars_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.avatars_count') }}
          </h3>
        </div>
      </div>
      <div>
        <router-link
          to="/tgcp/channel"
          class="uk-card uk-card-small  uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.private_chats_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.private_chats_count') }}
          </h3>
        </router-link>
      </div>
      <div>
        <div class="uk-card uk-card-small uk-card-secondary uk-card-body uk-light">
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.documents_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.documents_count') }}
          </h3>
        </div>
      </div>
      <div>
        <router-link
          to="/tgcp/messages"
          class="uk-card uk-card-small  uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.messages_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.messages_count') }}
          </h3>
        </router-link>
      </div>
      <div>
        <router-link
          to="/tgcp/channel"
          class="uk-card uk-card-small uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.public_chats_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.public_chats_count') }}
          </h3>
        </router-link>
      </div>
      <div>
        <div class="uk-card uk-card-small  uk-card-secondary uk-card-body uk-light">
          <Loader v-if="!isLoaded" />
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.photos_count) }}
          </h3>
          <h3 class="uk-text-muted uk-margin-remove-top">
            {{ $t('tgcp.stats.photos_count') }}
          </h3>
        </div>
      </div>
    </div>
    <ParsedStats provider="tgcp" />
    <UsageStats name="telegram" />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import vueSelect from '@/components/VueSelect'
import { mapGetters, mapActions } from 'vuex'
import { numberFormat } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TGCPStats',
  components: {
    UsageStats: defineAsyncComponent(() => import('@/views/UsageStats')),
    ParsedStats: defineAsyncComponent(() => import('@/components/TGCP/Stats/ParsedStats')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Loader,
    vueSelect
  },
  data () {
    return {
      searchString: '',
      searchType: 'messages'
    }
  },
  computed: {
    ...mapGetters({ stats: 'tgcp/stats' }),
    currentSearchType: {
      get () {
        return this.searchType
      },
      set (obj) {
        this.searchType = obj && obj.value
        this.update()
      }
    },
    searchTypeTranslated () {
      return [
        { value: 'messages', label: this.$t('search.messages') },
        { value: 'tgid', label: this.$t('search.tg_id') },
        { value: 'channel', label: this.$t('search.channels') },
        { value: 'user', label: this.$t('search.users') }
      ]
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['stats']
    }
  },
  methods: {
    ...mapActions({ update: 'tgcp/fetchStats' }),
    ...mapActions('tgcp', ['fetchUserById', 'checkPhoneExists', 'fetchUserPhoneById', 'fetchChannelById']),
    numberFormat,
    search () {
      if (!this.searchString) return
      if (this.searchType === 'tgid') {
        this.resolveTGID()
      } else {
        this.$router.push(`/tgcp/${this.searchType}?search=` + this.searchString)
      }
    },
    resolveTGID () {
      this.fetchUserById({ id: this.searchString })
        .then(user => {
          if (user.answer_status === 'FOUND') {
            this.$router.push({
              name: 'tgcp_user_detail_overview',
              params: {
                id: user.tg_id
              }
            })
            return Promise.resolve()
          }
          return Promise.reject(new Error())
        })
        .catch(() => {
          this.checkPhoneExists(this.searchString)
            .then(res => {
              if (res.status === 200) {
                this.$router.push({
                  name: 'tgcp_user_detail_overview',
                  params: { id: this.searchString }
                })
                return Promise.resolve()
              }
              return Promise.reject(new Error())
            })
            .catch(() => {
              this.fetchChannelById(this.searchString)
                .then(res => {
                  if (res) {
                    this.$router.push({
                      name: 'tgcp_channel_detail',
                      params: { id: this.searchString }
                    })
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error())
                }).catch(() => {
                  this.$notify({
                    group: 'general',
                    title: this.$t('notifications.error'),
                    text: this.$t('tgcp.stats.not-found'),
                    type: 'error'
                  })
                })
            })
        })
    }
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
