<template>
  <div
    class="uk-align-center"
    uk-grid
  >
    <form
      class="uk-form-horizontal uk-form"
      @submit.prevent="search"
    >
      <div class="uk-inline">
        <input
          v-model.trim="query.q"
          class="uk-input uk-form-width-large"
          type="text"
          :placeholder="$t('databases.search-companies', { count: '182 312 333' })"
        />
        <div class="uk-width-1-6 uk-padding-remove uk-inline">
          <select
            v-if="jurisdictions.length > 0"
            v-model="query.jurisdiction_code"
            class="uk-select"
            autofocus
          >
            <option :value="undefined">
              {{ $t('crypto.all-jurisdictions') }}
            </option>
            <option
              v-for="jurisdiction in jurisdictions"
              :key="jurisdiction.code"
              :value="jurisdiction.code"
            >
              {{ jurisdiction.name }}
            </option>
          </select>
        </div>
        <div class="uk-width-1-6 uk-padding-remove uk-inline">
          <select
            v-model="query.inactive"
            class="uk-input uk-select"
          >
            <option value="">
              {{ $t('crypto.all') }}
            </option>
            <option value="false">
              {{ $t('crypto.active') }}
            </option>
            <option value="true">
              {{ $t('crypto.inactive') }}
            </option>
          </select>
        </div>
        <button
          class="uk-form-icon uk-form-icon-flip uk-button-primary"
          uk-icon="icon: search"
          style="margin-right: 30px;"
          @click="update"
        />
      </div>
    </form>
    <div v-if="isLoaded['search'] === false">
      <Loader />
    </div>
    <div v-else-if="isLoaded['search'] && companies.length === 0">
      <NotFound />
    </div>
    <div
      v-else
      class="uk-grid uk-margin-top uk-margin-bottom"
      data-uk-grid-margin
    >
      <div
        class="uk-grid-small
            uk-child-width-1-1@s uk-flex-left
            uk-width-1-1
            uk-grid filter_area"
      >
        <Item
          v-for="company in companies"
          :key="company.id"
          :company="company"
          class="uk-margin-top"
        />
        <Paginate
          v-if="isLoaded['search'] && Number(pagination.total_pages) > 1"
          v-model="pagination.page"
          :page-count="pagination.total_pages"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center uk-margin-small-top"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/DataBases/List/Item.vue'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'OpencorporateCompaniesList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Item,
    Loader,
    Paginate
  },
  data () {
    return {
      jurisdictions: [],
      companies: [],
      query: {
        q: this.$route.query.q || '',
        country_code: null,
        company_type: null,
        inactive: '',
        industry_codes: null,
        normalise_company_name: false,
        jurisdiction_code: undefined
        // inactive: this.$route.query.active || undefined
      },
      pagination: {
        page: 1,
        total_pages: 0
      }
    }
  },
  computed: {
    ...mapGetters('organizations', ['allJurisdictions', 'isLoaded'])
  },
  methods: {
    ...mapActions('organizations', ['fetchAllJurisdictions', 'fetchCompaniesByName', 'addParamToQuery']),
    setPage (page) {
      this.pagination.page = page
      this.addParamToQuery({ page: page })
      this.update()
    },
    async update () {
      try {
        let query = this.query
        const { page } = this.pagination
        if (page > 1) {
          query = { ...query, page }
        }
        this.setData(await this.fetchCompaniesByName(query))
      } catch (err) {
        this.handleError(err)
      }
    },
    setData (data) {
      this.companies = data.companies
      this.pagination.page = data.pagination.page
      this.pagination.total_pages = data.pagination.total_pages
    },
    handleError () {
      this.companies = []
      this.pagination.page = 1
      this.pagination.total_pages = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    search () {
      this.pagination.page = 1
      this.addParamToQuery(this.query)
      this.setPage(1)
    }
  },
  async mounted () {
    this.jurisdictions = (await this.fetchAllJurisdictions()) || []
    this.update()
  }
}
</script>
