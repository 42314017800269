<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TonSearchTable',
  components: {
    Loader
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    },
    search: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      itemCount: 20,
      emptyTable: false,
      pagination: {
        page: 1,
        total: 0
      },
      maxExploredPage: 0
    }
  },
  watch: {
    'pagination.page' : {
      async handler (to, from) {
        if (to === 1 || (to > from && to > this.maxExploredPage)) { 
          this.maxExploredPage = to
          await this.updateValues()
        }
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getQueryTotal']),
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_search'] ?? false
    },
    hidePagination () {
      return !this.isLoaded || this.pagination.total < this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('crypto', ['searchTon']),
    async updateValues (next = true) {
      const { keys, itemCount, pagination, searchTon, getQueryTotal } = this
      this.emptyTable = false
      if (keys.length === 0 || pagination.page === 1) {
        await searchTon({ req: null, limit: itemCount, useLastSearch: true })
      }
      else {
        await searchTon({ req: null, limit: itemCount, useLastSearch: true, offset: itemCount * (pagination.page - 1)})
      }
      this.pagination.total = getQueryTotal('src')
      if (keys.length === 0) this.emptyTable = true
    },
    setPage (page) {
      this.pagination.page = page
    }
  },
  mounted () {
    this.pagination.total = this.getQueryTotal('src')
  }
}
</script>

<template>
  <div>
    <table class="uk-table uk-background-default uk-margin-remove-top uk-table-divider">
      <thead>
        <th>
          {{ $t('crypto.route.results') }}
        </th>
      </thead>
      <tbody v-if="!isLoaded">
        <tr>
          <Loader :ratio="2"/>
        </tr>
      </tbody>
      <tbody v-if="keys.length > 0">
        <tr 
          v-for="res of keys.slice((pagination.page - 1)*itemCount, pagination.page*itemCount)"
          :key="res.show + search"
        >
          <td 
            class="uk-flex uk-text-left uk-flex-column" 
            style="max-width: 90vw;"
          >
            <router-link 
              v-if="res.type === 'account'" 
              :to="{name: 'crypto_ton_account_info', params: { id: res.value.hex }, hash: '#overview'}" 
            >
              {{ res.show ?? res.value.hex }}
            </router-link>
            <router-link 
              v-else-if="res.type === 'transaction'" 
              :to="{name: 'crypto_ton_transaction_info', params: { id: this.toBase64Web(res.value.hash)}, hash: '#overview'}" 
            >
              {{ toBase64Rfc(res.show ?? res.value.hash) }}
            </router-link>
            <router-link 
              v-else-if="res.type === 'label'" 
              :to="{name: 'crypto_ton_account_info', params: { id: res.value }, hash: '#overview'}"
            >
              {{ res.show ?? res.value }}
            </router-link>
            <router-link 
              v-else-if="res.type === 'block'" 
              :to="{name: 'crypto_ton_block_info', query: { workchain: res.value.workchain, shard: res.value.shard.toString(), seq_no: res.value.seq_no }, hash: '#overview'}" 
            >
              {{ res.show ?? store.blockKeyGen(res.value.workchain, res.value.shard, res.value.seq_no) }}
            </router-link>
            <p class="uk-margin-remove-vertical">
              {{ $t(`crypto.route.${res.type === 'label' ? 'account' :res.type}`) }}
            </p>
          </td>
        </tr>   
      </tbody>
    </table>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>