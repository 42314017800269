<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div>
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @sumbmit.prevent="setPag(1)"
        >
          <div class="uk-display-inline-block">
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="setPage(1)"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('twitter.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <vueSelect
              v-model="currentBoard"
              :options="boardsListComputed"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div
        v-if="!isLoaded['ads']"
        class="uk-margin-large-top"
      >
        <Loader />
      </div>
      <div v-else>
        <NotFound
          v-if="isLoaded['ads'] && ads.length === 0"
          class="uk-margin-top"
        />
        <template v-else>
          <Item
            v-for="ad in ads"
            :key="ad.id"
            :ad="ad"
            class="uk-margin-top"
          />
        </template>
        <div v-if="pagination.total > query.limit">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/Boards/ADItem'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import vueSelect from '@/components/VueSelect'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'AdsList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item,
    Loader,
    Paginate,
    vueSelect
  },
  props: {
    country: {
      type: String
    },
    boardId: {
      type: [Number, String],
      default: undefined
    }
  },
  data () {
    return {
      ads: [],
      boardsList: [],
      catList: [],
      lastRequestTimestamp: 0,
      orderList: ['id', 'title', 'phone', 'seller', 'address', 'time'],
      pagination: {
        page: 1,
        total: null,
        nextPage: false
      },
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || 'desc',
        search: this.$route.query.search || '',
        board_ids: this.boardId,
        geos: this.country
      }
    }
  },
  watch: {
    country (to, from) {
      this.query.geos = to
      this.currentBoard = { value: undefined }
      this.update()
    }
  },
  computed: {
    ...mapGetters('ads', ['isLoaded']),
    boardsListComputed () {
      const boards = this.boardsList.map(({ id, title } = {}) => ({ label: title, value: id }))
      return [
        {
          value: undefined,
          label: this.$t('boards.select-board')
        },
        ...boards
      ]
    },
    currentBoard: {
      get () {
        return this.query.board_ids
      },
      set (obj) {
        this.query.board_ids = obj && obj.value
        this.setPage(1)
      }
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.setPage(1)
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.setPage(1)
      }
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('ads.stats.order.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('twitter.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('twitter.sort.sort_type_desc') }
      ]
    }
  },
  methods: {
    ...mapActions('ads', ['fetchAllADS', 'getCategories', 'fetchAllBoards']),
    init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      this.setPage(page)
    },
    setData (ads) {
      this.ads = ads.data
      this.pagination.total = ads.meta.results_count
    },
    setError () {
      this.pagination.page = 1
      this.ads = []
      this.pagination.total = null
    },
    async setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page || 1
      await this.$router.replace({
        query: this.query
      })
      this.update()
    },
    update () {
      const now = Date.now()
      this.updateBoards()
      this.getCategories()
        .then(catList => (this.catList = catList))
        .catch(err => console.debug(err))
      this.fetchAllADS(this.query)
        .then(res => {
          if (this.lastRequestTimestamp < now) {
            this.lastRequestTimestamp = now
            this.setData(res)
          }
        })
        .catch(this.setError)
    },
    updateBoards () {
      this.fetchAllBoards({ geos: this.country }).then(boardsList => (this.boardsList = boardsList))
    }
  },
  mounted () {
    this.init()
  }
}
</script>
