import getIndexName from '@/utils/leaks/getIndexName'

var GeneralUser = class {
  constructor (vueData = {}) {
    let data = { ...vueData }
    switch (data.type) {
      case 'telegram' :
        if (data.subtype !== 'channel') data.externalLink = '/tgcp/user/' + data.user_id
        else data.externalLink = '/tgcp/channel/' + data.user_id
        break
      case 'twitter': data.externalLink = '/twitter/user/' + data.user_id
        break
      case 'whatsapp': data.externalLink = '/whatsapp/user/' + data.phone.replace('+', '')
        break
      case 'imo': data.externalLink = '/imo/users/' + data.raw.discover_anon_id
        break
      case 'ads':
        if (typeof data.externalLink === 'string' && data.externalLink.match(/https:\/\/(www\.)?avito\.ru\//)) {
          try {
            data.avatar = Object.entries(JSON.parse(data.raw.json).seller.images).reverse()[0][1]
          } catch {}
        }
        if (data.user_id) {
          let lang = 'en'
          if (data.raw && data.raw.board && data.raw.board.geo) {
            lang = data.raw.board.geo.toLowerCase()
          }
          data.externalLink = `/ads/${lang}/list/${data.user_id}`
        }
        break
    }
    if (data.general_type === 'forums') {
      data.externalLink = '/forums/user/' + data.raw.id
    }
    if (!data.fullName) data.fullName = `${data.first_name || ''} ${data.last_name || ''}`
    this.avatar = data.avatar
    if (this.avatar) {
      this.safeAvatar = this.avatar
    }
    this.name = data.name || data.user_name
    this.title = data.title
    this.fullName = data.fullName
    this.externalLink = data.externalLink
    this.noInfo = false
    if (data.noInfo) this.noInfo = true
    this.bio = data.bio
    this.sex = data.sex
    this.age = data.age
    this.orgs = data.orgs
    this.links = data.links || []
    this.phone = data.phone
    this.password = data.password
    this.passwordHash = data.password_hash
    this.phones = data.phones
    this.birthday = data.birthday
    this.email = data.email
    this.emails = (data.emails && data.emails.filter(el => el !== data.email))
    this.stats = data.stats
    this.user_id = data.user_id
    this.ip = data.ip
    this.status = data.status
    this.historical = data.historical
    this.type = data.general_type === 'leak'
      ? getIndexName([data.type])[0].name
      : data.type
    this.subtype = data.subtype
    this.genericType = data.general_type
    this.raw = data.raw || data
    this.location = data.location
    this.providerId = data.providerId
    this.module = data.module
    this.cached = data.cached
    this.archived = data.archived
    this.ext_id = [this.type, 'account', this.user_id].filter(el => el).join('-').replace(/[^a-zA-Z0-9\\-]/g, '').substring(0, 250)
    this.getId = function () {
      return [this.type, this.user_id, this.name, this.providerId, this.phone, this.externalLink, this.fullName, this.email, this.password].filter(el => el !== undefined && el !== false && el !== null).join('').replace(/[^a-zA-Z0-9]/g, '').substring(0, 250)
    }
  }
}
export default GeneralUser
