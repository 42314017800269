<script>
import TableDateCell from '@/components/Crypto/Ton/Atoms/TableDateCell'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import Selector from '@/components/Crypto/Ton/Atoms/Selector'
import BalanceCell from '@/components/Crypto/Ton/Atoms/BalanceCell'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TopNFTHolders',
  components: {
    TableDateCell,
    Selector,
    AddressField,
    BalanceCell
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    minter: {
      type: String,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      itemCount: 10
    }
  },
  watch : {
    async itemCount (to, from) {
      await this.loadTonTopHolders({ hex: this.minter, limit: this.itemCount })
      await this.fetchBareTonAccounts(this.keys.filter(item => item.owner_address !== null).map(item => item.owner_address.hex))
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts'])
  },
  methods: {
    ...mapActions('crypto', ['loadTonTopHolders', 'fetchBareTonAccounts'])
  },
  async mounted () {
    this.fetchBareTonAccounts(this.keys.filter(item => item.owner_address !== null).map(item => item.owner_address.hex))
  }
}
</script>
<template>
  <div>
    <div 
      v-if="keys.length >= 10"
      class="uk-flex uk-width-1-1 uk-align-left uk-flex-middle uk-margin-remove-bottom" 
      style="justify-content: flex-end;"
    >
      <div class="uk-flex uk-flex-middle">
        <Selector 
          :item-count="itemCount"
          :amount="null"
          :options="[10, 25, 50, 100]"
          @setValue="(e) => itemCount = e"
        />
      </div>
    </div>
    <table class="uk-table uk-table-middle uk-margin-remove-top uk-table-striped">
      <thead>
        <tr>
          <th class="uk-width-1-2">
            {{ $t('crypto.ton.id') }}
          </th>
          <th class="uk-table-shrink">
            {{ $t('crypto.ton.items') }}
          </th>
          <th class="uk-table-expand uk-text-right">
            {{ $t('crypto.ton.balance') }}
          </th>
          <th 
            class="uk-table-shrink uk-text-right" 
            style="margin-right: 0.3rem;"
          >
            {{ $t('crypto.general.updated') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="acc in keys"
          :key="(acc.owner_address?.hex ?? 'minter') + minter"
        >
          <td 
            v-if="acc.owner_address"
            class="uk-text-truncate uk-text-left"
          > 
            <AddressField 
              :addr="acc.owner_address"
              :break_word="true"
              :full="true"
            />
          </td>
          <td 
            v-else
            class="uk-text-left"
          >
            {{ $t('crypto.general.noone') }}
          </td>
          <td>
            {{ acc.items_count }}
          </td>
          <td class="uk-text-right uk-text-nowrap"> 
            <BalanceCell
              v-if="acc.owner_address && acc.owner_address.hex in getTonAccounts"
              :balance="getTonAccounts[acc.owner_address.hex]?.balance ? getTonAccounts[acc.owner_address.hex].balance : 0"
              :place="'end'"
            />
            <div v-else>
              -
            </div>
          </td>
          <td class="uk-padding-remove-left uk-text-right">
            <div v-if="acc.owner_address && acc.owner_address.hex in getTonAccounts">
              <TableDateCell :date-time="getTonAccounts[acc.owner_address.hex].updated_at"/>
            </div>
            <div v-else>
              -
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>