<script>
import { toBase64Web } from '@/utils/ton-filters'
import PropLine from '../Atoms/PropLine'
import CopyableText from '../Atoms/CopyableText'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import BalanceCell from '@/components/Crypto/Ton/Atoms/BalanceCell'
import ExitCodeField from '@/components/Crypto/Ton/Atoms/ExitCodeField'

export default {
  name: 'TxPropsTable',
  components: {
    PropLine,
    CopyableText,
    AddressField,
    ExitCodeField,
    BalanceCell
  },
  props: {
    trn : {
      type: Object,
      required: true
    }
  },
  computed: {
    dateForDton () {
      const d = new Date(this.trn.created_at)
      return d.getFullYear() + '-' + ('0'+(d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) +
        'T' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2)
    },
    externalLink () { 
      return {
        'Ton.cx': `https://ton.cx/tx/${this.trn.created_lt}:${this.trn.hash}:${this.trn.address.base64}`,
        'Toncoin': `https://explorer.toncoin.org/transaction?account=${this.trn.address.base64}&lt=${this.trn.created_lt}&hash=${this.trn.hex}`,
        'TonWhales': `https://tonwhales.com/explorer/address/${this.trn.address.base64}/${this.trn.created_lt}_${this.trn.hex}`, 
        'Tonviewer': `https://tonviewer.com/transaction/${this.trn.hex}`,
        'tonscan.org' : `https://tonscan.org/tx/${this.trn.hash}`,
        'Anton.tools' : `https://explorer.anton.tools/transactions/${this.trn.hash}#overview`,
        'dton': `https://dton.io/tx/${this.trn.hex.toUpperCase()}?time=${this.dateForDton}`
      }
    }
  },
  methods: {
    toBase64Web
  }
}
</script>

<template>
  <table class="uk-table uk-table-middle uk-margin-remove-vertical uk-table-striped">
    <tbody>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.hash`) }}
        </template>
        <template #value>
          <CopyableText :text="trn.hash">
            {{ trn.hash }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.hex`) }}
        </template>
        <template #value>
          <CopyableText :text="trn.hex">
            {{ trn.hex }}
          </CopyableText>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.address`) }}
        </template>
        <template #value>
          <CopyableText :text="trn.address.base64">
            <AddressField 
              :addr="trn.address"
              :break_word="false"
              :full="true"
            />
          </CopyableText> 
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.created_lt`) }}
        </template>
        <template #value>
          {{ trn.created_lt }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.block`) }}
        </template>
        <template #value>
          <CopyableText :text="trn.address.base64">
            <router-link 
              :to="`/crypto/ton/blocks/block?workchain=${trn.workchain}&shard=${trn.shard}&seq_no=${trn.block_seq_no}#overview`"
            >
              {{ `${trn.workchain}:${trn.shard}:${trn.block_seq_no}` }}
            </router-link>
          </CopyableText> 
        </template>
      </PropLine>
      <PropLine v-if="trn.prev_tx_hash">
        <template #name>
          {{ $t(`crypto.ton.prev_tx_hash`) }}
        </template>
        <template #value>
          <template v-if="trn.prev_tx_hash !== 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA='">
            <CopyableText :text="trn.prev_tx_hash">
              <router-link :to="`/crypto/ton/transaction/${toBase64Web(trn.prev_tx_hash)}#overview`">
                {{ trn.prev_tx_hash }}
              </router-link>
            </CopyableText> 
          </template>
          <template v-else>
            {{ $t('crypto.warning.first_tx') }}
          </template>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.total_fees`) }}
        </template>
        <template #value>
          <BalanceCell 
            :balance="trn.full_fees ? trn.full_fees : 0"
            :full="true"
          />
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.delta`) }}
        </template>
        <template #value>
          <BalanceCell 
            :balance="trn.delta ? trn.delta : 0"
            :delta="true"
            :full="true"
          />
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.orig_status`) }}
        </template>
        <template #value>
          {{ trn.orig_status }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.end_status`) }}
        </template>
        <template #value>
          {{ trn.end_status }}
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.exit_code`) }}
        </template>
        <template #value>
          <ExitCodeField :code="trn.compute_phase_exit_code"/>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.action_phase_result_code`) }}
        </template>
        <template #value>
          <ExitCodeField :code="trn.action_phase_result_code"/>
        </template>
      </PropLine>
      <PropLine :wrap="true">
        <template #name>
          {{ $t(`crypto.general.external`) }}
        </template>
        <template #value>
          <template v-for="key of Object.keys(externalLink)">
            <a
              v-if="externalLink[key]"
              :key="key + trn.hash"
              :href="externalLink[key]"
              class="uk-margin-right"
              uk-icon="icon:link"
              target="_blank"
              style="line-height: 1.5; color: #DA7D02;"
            >
              {{ key }}
            </a>
          </template>
        </template>
      </PropLine>
      <PropLine>
        <template #name>
          {{ $t(`crypto.ton.created_at`) }}
        </template>
        <template #value>
          {{ new Date(trn.created_at).toLocaleString() }}
        </template>
      </PropLine>
    </tbody>
  </table>   
</template>