<template>
  <div>
    <Loader v-if="!isLoaded['user_' + id]"/>
    <div v-else>
      <NotFound v-if="user.answer_status==='NOT_FOUND'" />
      <div v-else>
        <div class="uk-width-1-1">
          <gi :user="user" />
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <ul uk-tab>
          <li
            class="uk-flex"
            :class="checkForTab('overview_tab')"
          >
            <a
              id="overview_tab"
              class="uk-padding-remove"
              href="#overview_tab"
              @click="activateTab('overview_tab')"
            >
              {{ $t('twitter.channel_details.overview_title') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('accounts_tab')"
          >
            <a
              id="accounts_tab"
              class="uk-padding-remove"
              href="#accounts_tab"
              @click="activateTab('accounts_tab')"
            >
              {{ $t('twitter.channel_details.accounts') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('tweets_tab')"
          >
            <a
              id="tweets_tab"
              class="uk-padding-remove"
              href="#tweets_tab"
              @click="activateTab('tweets_tab')"
            >
              {{ $t('twitter.channel_details.tweets') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.tweets_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('follows_tab')"
          >
            <a
              id="follows_tab"
              ref="follows_tab"
              class="uk-padding-remove"
              href="#follows_tab"
              @click="activateTab('follows_tab')"
            >
              {{ $t('twitter.channel_details.following') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.follows_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('followers_tab')"
          >
            <a
              id="followers_tab"
              ref="followers_tab"
              class="uk-padding-remove"
              href="#followers_tab"
              @click="activateTab('followers_tab')"
            >
              {{ $t('twitter.channel_details.followers') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.followers_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('friends_tab')"
          >
            <a
              id="friends_tab"
              class="uk-padding-remove"
              href="#friends_tab"
              @click="activateTab('friends_tab')"
            >
              {{ $t('twitter.channel_details.friends') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.friends_count_max }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('retweets_tab')"
          >
            <a
              id="retweets_tab"
              class="uk-padding-remove"
              href="#retweets_tab"
              @click="activateTab('retweets_tab')"
            >
              {{ $t('twitter.channel_details.retweets') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.retweets_count || 0 }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('replies_tab')"
          >
            <a
              id="replies_tab"
              class="uk-padding-remove"
              href="#replies_tab"
              @click="activateTab('replies_tab')"
            >
              {{ $t('twitter.user_details.replies_title') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.replies_count || 0 }}
              </span>
            </span>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li :class="checkForTab('overview_tab')">
            <a name="overview_tab"/>
            <uthg :id="id" />
            <udg :id="id" />
            <uwg :id="id" />
            <umg :id="id" />
          </li>
          <li :class="checkForTab('accounts_tab')">
            <a name="accounts_tab"/>
            <Accounts
              :identity="user.user_name"
              type="twitter"
              @opendetails="showSidebar"
            />
          </li>
          <li
            v-if="user.tweets_count"
            :class="checkForTab('tweets_tab')"
          >
            <a name="tweets_tab"/>
            <MessageList
              v-if="activeTab === 'tweets_tab'"
              :id="id"
              type="user"
            />
          </li>
          <li
            v-if="user.follows_count"
            :class="checkForTab('follows_tab')"
          >
            <a name="follows_tab"/>
            <UserList
              v-if="activeTab === 'follows_tab'"
              :id="id"
              type="follows"
            />
          </li>
          <li
            v-if="user.followers_count"
            :class="checkForTab('followers_tab')"
          >
            <a name="followers_tab"/>
            <UserList
              v-if="activeTab === 'followers_tab'"
              :id="id"
              type="followers"
            />
          </li>
          <li
            v-if="user.friends_count"
            :class="checkForTab('friends_tab')"
          >
            <a name="friends_tab"/>
            <UserList
              v-if="activeTab === 'friends_tab'"
              :id="id"
              type="friends"
            />
          </li>
          <li>
            <a name="mediamessages_tab"/>
          </li>
          <li>
            <a name="replies_tab"/>
          </li>
        </ul>
      </div>
    </div>
    <RightSidebar>
      <ProviderDetails
        v-if="detail"
        :data="detail"
        :raw="detail.raw"
      />
    </RightSidebar>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import RightSidebar from '@/components/RightSidebar'
import UserTopHashesGraph from '@/components/Twitter/UserDetails/UserTopHashesGraph'
import UserDayGraph from '@/components/Twitter/UserDetails/UserDayGraph'
import UserWeekGraph from '@/components/Twitter/UserDetails/UserWeekGraph'
import UserYearGraph from '@/components/Twitter/UserDetails/UserYearGraph'
import GeneralInformation from '@/components/Twitter/UserDetails/GeneralInformation'
import UserList from '@/views/Twitter/UserList'
import MessageList from '@/views/Twitter/MessageList'
import Accounts from '@/views/GlobalSearch/Accounts/Accounts'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TwitterUserDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    Loader,
    uthg: UserTopHashesGraph,
    udg: UserDayGraph,
    uwg: UserWeekGraph,
    umg: UserYearGraph,
    gi: GeneralInformation,
    MessageList,
    UserList,
    Accounts,
    RightSidebar
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      detail: null
    }
  },
  watch: {
    id (to) {
      this.update()
    },
    activeTab (newTab) {
      const [tab] = newTab.split('_')
      if (!this.user[tab + '_count'] && tab !== 'accounts') {
        this.activateTab('overview_tab')
      }
      if (['follows_tab', 'followers_tab'].includes(newTab)) {
        this.$refs[newTab].click()
      }
    }
  },
  computed: {
    ...mapGetters('twitter', [
      'allUsers',
      'isLoaded',
      'userStats'
    ]),
    activeTab () {
      return this.$route.query.tab || 'overview_tab'
    },
    user () {
      return this.allUsers[this.id]
    },
    getStats () {
      return this.userStats[this.id]
    },
    isLoadedStats () {
      return this.isLoaded['user_' + this.id + '_stats']
    }
  },
  methods: {
    ...mapActions('twitter', [ 'fetchForceUserById', 'fetchForceUserStatsById' ]),
    checkForTab (tabName) {
      const activeTab = this.activeTab
      const [tab] = tabName.split('_')
      if (tabName === activeTab) {
        return 'uk-active'
      }
      if (!this.user[tab + '_count'] && tab !== 'accounts' && tab !== 'overview') return 'uk-disabled'
      else return ''
    },
    activateTab (tab) {
      tab && this.$router.push({ query: { tab } })
    },
    update () {
      this.fetchForceUserById(this.id) &&
        this.fetchForceUserStatsById(this.id)
    },
    showSidebar (value) {
      this.detail = value
    }
  },
  mounted () {
    this.update()
  }
}
</script>
