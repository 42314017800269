import Base from '../../Base.js'
import Schemas from '../../../Schemas'
import { addMainMessageField, filteredArr, messageMarkup } from '../../../../../utils/csvExport/fieldsParse.js'

class TgcpMessagesAll extends Base {
  schema = Schemas.tgcp.message
  OPTIONS_SCHEMA = [
    'query',
    'search',
    'allowedFields',
    'localizeFields',
    'messages',
    'translateMessages',
    'messageLimit',
    'includeRepliedMessage'
  ]
  fetch = async ({ dispatch, options }) => {
    try {
      const { query, search, messages, translateMessages, messageLimit, includeRepliedMessage } = options
      if (messages && messages.length) {
        return messages
      } else {
        let localQuery = {
          search,
          ...query,
          offset: 0,
          limit: 100
        }
        let result = []
        let entry = Array(1)
        let continueFlag = true
        while (entry.length && localQuery.offset < 5000 && continueFlag) {
          entry = await dispatch('fetchAllMessages', localQuery)
          if (entry.length < 100) continueFlag = false

          try {
            if (+messageLimit !== 0) {
              for (const current of entry) {
                current.main = ''
                let context = []
                try {
                  context = await dispatch('getMessageContext', {
                    messageId: current.message_id,
                    chatId: current.chat_id,
                    limit: messageLimit
                  })
                  context = await addMainMessageField(context, current.message_id)
                } catch {
                  context = [current.main]
                }
                entry = entry.concat(...context)
              }
            }
          } catch (error) {
            console.debug(error)
          }
          try {
            if (includeRepliedMessage) {
              entry = await dispatch('getMessagesReplies', entry)
            }
          } catch (error) {
            console.debug(error)
          }
          try {
            entry = filteredArr(entry)
          } catch (error) {
            console.debug(error)
          }
          result.push(...entry)
          localQuery.offset += localQuery.limit
        }
        try {
          result = await dispatch('getChannelName', result)
        } catch (error) {
          console.debug(error)
        }
        try {
          result = await dispatch('getUserName', result)
        } catch (error) {
          console.debug(error)
        }
        try {
          if (translateMessages) {
            result = await Promise.all(
              result.map(async current => {
                const {
                  data: { translation }
                } = await dispatch('translateText', { text: current.message })
                current.translated_text = translation
                return current
              })
            )
          }
        } catch (error) {
          console.debug(error)
        }
        try {
          result = result.map(current => {
            try {
              current.message = messageMarkup(current)
            } catch (error) {
              console.debug(error)
            }
            return current
          })
        } catch (error) {
          console.debug(error)
        }
        return result
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export default TgcpMessagesAll
