<template>
  <div>
    <ul
      class="uk-list"
    >
      <li
        v-for="user in users"
        :key="user.email"
        class="uk-flex uk-flex-center uk-flex-wrap uk-flex-middle uk-text-center"
        uk-form-controls
      >
        <div class="uk-flex-1">
          {{ user.email }}
          <br />
          <span class=" uk-text-muted">
            {{ !user.user_id ? $t('cases.pending') : '' }}
          </span>
        </div>
        <div>
          <vueSelect
            :disabled="!user.user_id"
            :options="acesseLevelsTranslated"
            :modelValue="user.access_level"
            label="label"
            class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            @update:modelValue="(event) => changePermission(user, $event && $event.value)"
          />
        </div>
        <div>
          <button
            class="uk-button uk-button-text uk-margin-left uk-margin-right onclick-transform"
            type="button"
            @click="deleteUser(user)"
          >
            <span
              :uk-tooltip="$t('cases.delete-user')"
              uk-icon="trash"
            />
          </button>
        </div>
      </li>
      <li
        v-if="!Object.keys(users).length"
      >
        {{ $t('cases.not-shared-with-anyone') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vueSelect from '@/components/VueSelect'

export default {
  name: 'CaseUserList',
  components: {
    vueSelect
  },
  props: {
    caseId: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      email: '',
      accessLevel: '',
      timer: null
    }
  },
  computed: {
    ...mapGetters('cases', [
      'users'
    ]),
    acesseLevelsTranslated () {
      return ['r', 'rw', 'f'].map(lvl => ({
        value: lvl,
        label: this.$t('access.' + lvl)
      }))
    }
  },
  methods: {
    ...mapActions('cases', [
      'getAllCaseUsers',
      'changeUserPermissions',
      'deleteUserFromCase'
    ]),
    changePermission (user, value) {
      this.changeUserPermissions({
        userId: user.user_id,
        caseId: this.caseId,
        accessLevel: value
      }).catch(err => {
        if (!err.response) return console.debug(err)
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t(err.response.data.message), type: 'error' })
      })
    },
    deleteUser (user) {
      this.deleteUserFromCase({
        userId: user.user_id,
        email: user.email,
        caseId: this.caseId
      })
        .then(() => {
          this.update()
        })
        .catch(err => {
          if (!err.response) return console.debug(err)
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t(err.response.data.message), type: 'error' })
        })
    },
    async update () {
      try {
        this.getAllCaseUsers(this.caseId)
      } catch (err) {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t(err.response.data.message), type: 'error' })
      } finally {
        this.timer = setTimeout(this.update, 10 * 1000)
      }
    }
  },
  mounted () {
    this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>
