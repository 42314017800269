<template>
  <div class="tgcp  uk-margin-top uk-width-1-1">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Tgcp'
}
</script>
