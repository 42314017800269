<template>
  <div>
    <div
      class="uk-grid-small uk-grid-match"
      uk-grid
    >
      <div
        class="uk-width-1-1"
      >
        <List/>
      </div>
      <div
        class="uk-width-1-2 uk-animation-slide-top-small"
      >
        <AddGeoCluster/>
      </div>
      <div
        class="uk-width-1-2 uk-animation-slide-top-small"
      >
        <div
          class="uk-card uk-card-default"
        >
          <div
            class="uk-card-body uk-text-middle"
            style="height: 100%"
          >
            <span
              class="uk-position-center uk-padding"
            >
              {{ $t('tgcp.tasks.cluster-help') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'TasksGeoClusters',
  components: {
    AddGeoCluster: defineAsyncComponent(() => import('@/components/TGCP/Tasks/AddGeoCluster.vue')),
    List: defineAsyncComponent(() => import('@/components/TGCP/Tasks/ClustersList.vue'))
  },
  data () {
    return {
      clusters: [],
      loading: false
    }
  }
}
</script>
