<template>
  <div>
    <div class="uk-card uk-card-default uk-padding my-uk-margin-small-right">
      <div uk-grid>
        <div class="uk-width-expand@m  uk-text-left">
          <h3 class="uk-margin-remove-top">
            {{ localGroup.title || localGroup.name }}
            <span
              class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted"
            >
              #{{ forum.id }}
            </span>
            <button
              class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
              @click="translateName"
            >
              <font-awesome-icon
                :uk-tooltip="translated.title || translated.name ? $t('tgcp.channels.show-original') : $t('tgcp.channels.translate')"
                :icon="['fa', 'language']"
                :class="translated.title || translated.name ? 'uk-text-primary' : 'uk-text-default'"
              />
              <span v-if="translated.title || translated.name">
                {{ $t('tgcp.channels.show-original') }}
              </span>
              <span v-else>
                {{ $t('tgcp.channels.translate') }}
              </span>
            </button>
          </h3>
          <div
            v-if="localGroup.description"
            class="uk-text-meta uk-margin-small-top uk-margin-right uk-text-left"
          >
            <span v-html="extractedDataDescription"/>
            <button
              class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
              @click="translateDescription"
            >
              <font-awesome-icon :icon="['fa', 'language']" />
              <span v-if="translated.description">
                {{ $t('tgcp.channels.show-original') }}
              </span>
              <span v-else>
                {{ $t('tgcp.channels.translate') }}
              </span>
            </button>
            <br />
          </div>
        </div>
        <div class="uk-margin-right">
          <span v-if="forum.url">
            {{ forum.url }} <a
              :href="forum.url"
              uk-icon="link"
              class="ui-link"
            />
          </span>
        </div>
      </div>
      <div class="">
        <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
          <AddStoredButton
            provider="forums"
            :type="type"
            :data="forum"
          />
        </div>
        <div
          class="uk-grid"
          data-uk-grid-margin
        />
      </div>
    </div>
  </div>
</template>
<script>
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import extractData from '@/utils/dataExtraction'
import translate from '@/mixins/translate'

export default {
  name: 'ForumsForumGeneralInfo',
  components: {
    AddStoredButton
  },
  mixins: [translate],
  props: {
    forum: {
      required: true,
      type: Object
    },
    type: {
      type: String,
      default: 'forum'
    }
  },
  data () {
    return {
      localGroup: { ...this.forum },
      translated: {
        title: false,
        name: false,
        description: false
      }
    }
  },
  computed: {
    extractedDataDescription () {
      if (!this.forum) return
      return extractData(this.localGroup.description)
    }
  },
  methods: {
    async translateName () {
      if (this.forum.title) {
        const { title } = await this.translateProps(['title'], this.localGroup, this.forum)
        this.localGroup.title = title || this.localGroup.title
      } else {
        const { name } = await this.translateProps(['name'], this.localGroup, this.forum)
        this.localGroup.name = name || this.localGroup.name
      }
    },
    async translateDescription () {
      const { description } = await this.translateProps(['description'], this.localGroup, this.forum)
      this.localGroup.description = description || this.localGroup.description
    }
  }
}
</script>
<style scoped>
.my-uk-margin-small-right {
  margin-right: 15px
}
</style>
