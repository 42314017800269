<template>
  <div class="uk-margin-top uk-card uk-card-body uk-card-default">
    <div
      class="uk-text-center"
      uk-grid
    >
      <div class="uk-width-2-3@m uk-flex-left uk-text-left">
        <div class="uk-card-title  uk-margin uk-heading-divider">
          {{ $t('help.operator.manuals') }}
        </div>
        <li>
          <a
            target="_blank"
            :class="{
              'uk-disabled uk-text-muted': !isLoaded['manual']
            }"
            style="display: inline-block;"
            @click="getManual({ locale })"
          >
            <span
              v-if="isLoaded['manual']"
              uk-icon="file-pdf"
            />
            <Loader
              v-else
              :ratio="0.7"
            />
            {{ $t('help.operator.ASIRIS-Platform-operator-Manual') }} (1.98.0 22/03/2024)
          </a>
          <span
            v-if="isLoaded['manual'] && manuals.length > 1 || !manuals.includes(locale)"
            class="uk-margin-small-left"
          >
            <a
              v-for="lang in manuals"
              :key="lang + '_manual'"
              target="_blank"
              :class="{
                'uk-disabled uk-text-muted': !isLoaded['manual']
              }"
              style="display: inline-block; margin-right: 0.3em"
              @click="getManual({ locale: lang })"
            >
              {{ `[${lang.toUpperCase()}]` }}
            </a>
          </span>
        </li>
        <li v-if="locale === 'en'">
          <a
            target="_blank"
            :class="{
              'uk-disabled uk-text-muted': !isLoaded['faq']
            }"
            @click="getManual({ locale, type: 'faq' })"
          >
            <span
              v-if="isLoaded['faq']"
              uk-icon="file-pdf"
            />
            <Loader
              v-else
              :ratio="0.7"
            />
            {{ $t('help.operator.faq') }} (1.96.1 16/08/2023)
          </a>
        </li>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import config from '@/config'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Help',
  components: {
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  computed: {
    ...mapGetters('fetcher', [
      'isLoaded'
    ]),
    manuals () {
      if (config.manualLanguages) return config.manualLanguages
      else return [this.$i18n.locale]
    },
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    ...mapActions('fetcher', [
      'getManual'
    ])
  }
}
</script>
