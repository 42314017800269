<template>
  <tr class="uk-text-center uk-text-break">
    <td
      v-if="localTask"
      class="uk-text-break"
    >
      <a 
        :class="{ 'uk-disabled uk-text-muted': !localTask.group }"
        @click="search"
      >
        {{ localTask.joinlink.split('/').pop() }}
      </a>
    </td>
    <td class="uk-text-break">
      {{ localTask.description }}
    </td>
    <td class="uk-text-break">
      {{ localTask.finished ? dayjs(localTask.finished) : $t('imo.tasks.waiting_for_sync') }}
    </td>
    <td class="uk-text-break">
      {{ dayjs(localTask.created) }}
    </td>
    <td
      :class="{
        'uk-disabled': loading
      }"
    >
      <div
        class="uk-flex uk-width-1-1 uk-flex-between uk-flex-middle"
      >
        <AddStoredButton
          provider="imo"
          :ext_id="extId"
          type="task"
          :data="task"
        />
        <a
          uk-icon="icon: bolt; ratio: 1"
          value="null"
          class="uk-margin-small-right"
          :uk-tooltip="'title: ' + $t('imo.tasks.realtime')"
          :class="{
            'uk-text-primary' : localTask.priority === 8
          }"
          @click="updateRealtime"
        />
        <a
          :uk-tooltip="`title: ${$t('imo.tasks.priority_field_title')}`"
          uk-icon="icon: warning; ratio: 1"
          :value="null"
          :class="localTask.priority > 4 ? 'uk-text-primary' : 'uk-text-muted'"
          @click="updatePriority"
        />
        <button
          class="uk-button uk-button-text uk-margin-left-small"
          type="button"
          style="height:40px;width:40px;"
          :uk-tooltip="$t('imo.tasks.delete')"
          @click="$emit('delete', localTask.id)"
        >
          <span uk-icon="trash"/>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'

export default {
  name: 'ImoTasksListItem',
  components: {
    AddStoredButton: defineAsyncComponent(() => import('@/components/cases/Stored/AddStoredButton'))
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localTask: null,
      loading: false
    }
  },
  computed: {
    extId () {
      return `imo-task-${this.task.id}`
    }
  },
  methods: {
    ...mapActions('imo', [
      'editTask'
    ]),
    search () {
      // eslint-disable-next-line camelcase
      if (this?.task?.group) {
        return this.$router.push({ name: 'imo_group_details', params: { id: String(this.task.group.bgid) } })
      }
    },
    async updatePriority () {
      this.loading = true
      try {
        this.localTask = await this.editTask({ id: this.task.id, task: { priority: +this.localTask.priority > 4 ? 1 : 5 } })
      } catch {
        this.reset()
      } finally {
        this.loading = false
      }
    },
    async updateRealtime () {
      this.loading = true
      try {
        this.localTask = await this.editTask({ id: this.task.id, task: { high_priority: +this.localTask.priority !== 8 ? true : false } })
      } catch {
        this.reset()
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.localTask = { ...this.task }
    }
  },
  created () {
    this.reset()
  }
}
</script>
