<template>
  <div
    id="map"
    style="margin-top: 0px"
  >
    <l-map
      ref="map"
      style="height: 100%; width: 100%"
      :zoom="zoom"
      :center="center"
      :options="{
        zoomControl: false,
        worldCopyJump: true,
        minZoom: 3,
        editable: true
      }"
      :maxBounds="maxBounds"
      @update:bounds="boundsUpdated"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
      />
      <l-geosearch :options="geosearchOptions" />
      <l-control-scale
        position="bottomleft"
        :imperial="true"
        :metric="true"
      />
      <l-control-zoom position="topleft" />
      <LRectangle
        v-if="rectangle"
        :bounds="geoComputed"
      />
      <l-marker
        v-if="marker && geoComputed && geoComputed.length === 2"
        :lat-lng="geoComputed"
      />
    </l-map>
  </div>
</template>

<script>
/* eslint-disable */

import L, { Icon } from 'leaflet'
import 'leaflet-editable'
import 'leaflet.path.drag'
import {
  LMap,
  LTileLayer,
  LControlZoom,
  LControlScale,
  LRectangle,
  LMarker
} from 'vue2-leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import Geosearch from 'vue2-leaflet-geosearch'

import '@/assets/css/leaflet-geosearch.css'
import '@/assets/css/ControlLoading.css'

import ControlLoading from '@/components/map/controls/Loading.js'
import EditControl from '@/components/map/controls/EditControl.js'

delete Icon.Default.prototype._getIconUrl

import config from '@/config'
const { servers: { map } } = config

export default {
  name: 'MapEditable',
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-control-zoom': LControlZoom,
    'l-control-scale': LControlScale,
    'l-geosearch': Geosearch,
    'l-marker': LMarker,
    LRectangle
  },
  props: {
    onSelect: {
      type: Function,
      default: null
    },
    onDeselect: {
      type: Function,
      default: null
    },
    geo: {
      type: Array
    },
    type: {
      type: String,
      default: 'set'
    }
  },
  data () {
    return {
      mapInstance: null,
      window: {
        width: 0,
        height: 0
      },
      url: map + '/{z}/{x}/{y}.png',
      zoom: 4,
      currentZoom: 4,
      currentBounds: null,
      lastZoom: 3,
      center: [31.750357558882385, 46.19476318359376],
      bounds: null,
      geosearchOptions: {
        position: 'topleft',
        style: 'bar',
        provider: new OpenStreetMapProvider()
      },
      rectangle: false,
      marker: false
    }
  },
  methods: {
    updateCoordinatesBox(bounds) {
      this.onSelect(bounds)
    },
    boundsUpdated (bounds) {
      this.currentBounds = bounds
    },
    zoomUpdate (zoom) {
      this.lastZoom = this.currentZoom
      this.currentZoom = zoom
    },
    getBBOX (bounds) {
      if (Math.abs(bounds._southWest.lng) > 180) bounds._southWest.lng %= 180
      if (Math.abs(bounds._northEast.lng) > 180) bounds._northEast.lng %= 180
      return bounds._southWest.lat + ',' + bounds._southWest.lng + ',' + bounds._northEast.lat + ',' + bounds._northEast.lng
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    loadingHandler (map) {
      return (e) => {
        map.fireEvent('dataloading', e)
      }
    },
    loadHandler (map) {
      return (e) => {
        map.fireEvent('dataload', e)
      }
    }
  },
  computed: {
    maxBounds () {
      var southWest =  new L.latLng(-89.9999, -180)
      var northEast =  new L.latLng(90, 180)
      var group =  new L.latLngBounds(southWest, northEast)
      return group
    },
    geoComputed () {
      if (!this.geo) return
      let geo = []
      if (this.geo.length === 4)
        geo = [[this.geo[0], this.geo[1]], [this.geo[2], this.geo[3]]]
      else 
        geo = this.geo
      return geo
    }
  },
  created () {
    // eslint-disable-next-line
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  async mounted () {
    await this.$nextTick()
    let caller = this
    this.mapInstance = this.$refs.map.mapObject
    const _map = this.$refs.map.mapObject
    this.bounds = this.mapInstance.getBounds()
    L.Control.Exit = L.Control.extend({
      options: {
        position: 'topleft'
      },

      initialize: function(options) {
          L.setOptions(this, options)
      },

      onAdd: function (map) {
        let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control'),
            link = L.DomUtil.create('a', '', container)

        link.href = '#'
        link.title = 'Exit editable mode'
        link.innerHTML = 'x'
        L.DomEvent.on(link, 'click', L.DomEvent.stop)
                  .on(link, 'click', function () {
                    map.fireEvent('editable:close')
                  })

        return container
      }
    })
    const rectangleControl = EditControl({
        position: 'topleft',
        callback: this.mapInstance.editTools.startRectangle,
        kind: 'rectangle',
        html: '⬛'
    })
    const circleControl = EditControl({
        position: 'topleft',
        callback: this.mapInstance.editTools.startCircle,
        kind: 'circle',
        html: '⬤'
    })
    const exitControl = new L.Control.Exit()
    if (this.type === "set"){
      rectangleControl.addTo(this.mapInstance)
    }
    ControlLoading({ position: 'bottomleft' }).addTo(this.mapInstance)

    this.mapInstance.on('editable:created', function (e) {
      rectangleControl.remove()
      circleControl.remove()
      caller.rectangle = false
      exitControl.addTo(e.editTools.map)
      window.currLayer = e.layer
    })

    this.mapInstance.on('editable:close', function (e) {
      caller.onDeselect()
      _map.removeLayer(window.currLayer)
      exitControl.remove()
      rectangleControl.addTo(_map)
    })

    this.mapInstance.on('editable:dragend', function (e) {
      this.geo = null
      if (e.layer._mRadius) {
        const R = 6371000
        const Y = e.layer._latlng.lat
        const X = e.layer._latlng.lng
        const pi = Math.PI
        let dY = 360 * e.layer._mRadius / R
        let dX = dY * Math.cos(Y * pi / 180)

        const bounds = {
          _southWest: {
            lat: Y - dY,
            lng: X - dX
          },
          _northEast: {
            lat: Y + dY,
            lng: X + dX
          }
        }

        caller.updateCoordinatesBox(bounds)

      } else {
        const bounds = e.layer._bounds
        caller.updateCoordinatesBox(bounds)
      }
    })

    this.mapInstance.on('editable:vertex:dragend', function (e) {
      if (e.layer._mRadius) {
        const R = 6371000
        const Y = e.layer._latlng.lat
        const X = e.layer._latlng.lng
        const pi = Math.PI
        let dY = 360 * e.layer._mRadius / R
        let dX = dY * Math.cos(Y * pi / 180)

        const bounds = {
          _southWest: {
            lat: Y - dY,
            lng: X - dX
          },
          _northEast: {
            lat: Y + dY,
            lng: X + dX
          }
        }
        caller.updateCoordinatesBox(bounds)
      } else {
        const bounds = e.layer._bounds
        caller.updateCoordinatesBox(bounds)
      }
    })
    if (this.geo) {
      if (this.geo.length === 4) {
        this.mapInstance.fitBounds(L.latLngBounds(new L.latLng(this.geo[0], this.geo[1]), new L.latLng(this.geo[2], this.geo[3])))
        this.rectangle = true
      } else if (this.geo.length === 2) {
        this.mapInstance.fitBounds(L.latLngBounds(new L.latLng(this.geo[0] - 0.01, this.geo[1] - 0.01), new L.latLng(this.geo[0] + 0.01, this.geo[1] + 0.01)))
        this.marker = true
      }
    }
    this.mapInstance._controlContainer.onclick = e => { e.stopPropagation() }
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
@import "~leaflet/dist/leaflet.css";


#map {
  position: relative;
  z-index: 0;
  width: 100%;
  margin-top: 60px;
}

.leaflet-control-layers {
  text-align: left;
}
.leaflet-control-layers-overlays {
  text-align: left;
}
.leaflet-control-layers-toggle {
  width: 30px!important;
  height: 30px!important;
}
</style>
