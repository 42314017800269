<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div v-if="!isLoaded">
        <Loader />
      </div>

      <div v-else>
        <div class="uk-card uk-card-default uk-card-small uk-margin-top uk-text-left">
          <div class="uk-padding">
            This page shows new Telegram Users registered at near real-time. It is PoC, currently working for Singapore and Palestinian Territories
          </div>
        </div>
        <Items
          v-for="user in usersOnPage"
          :key="user.tgid"
          :user="user"
        />
        <NotFound v-if="isLoaded && !usersOnPage.length" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'UserList',
  components: {
    Items: defineAsyncComponent(() => import('@/components/TGCP/UserList/LastUsersItems.vue')),
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  data () {
    return {
      timer: false,
      users: []
    }
  },
  computed: {
    usersOnPage () {
      return this.$store.state.fetcher.lastTgUsers
    },
    isLoaded () {
      return this.$store.state.fetcher.isLoaded['last_tg_users']
    }
  },
  methods: {
    ...mapActions('fetcher', ['fetchTgLastUsers']),
    scheduleUpdate () {
      this.timer = setTimeout(this.update, 90000)
    },
    update () {
      return this.fetchTgLastUsers().then(this.scheduleUpdate)
    }
  },
  async created () {
    this.countries = (await import(/* webpackChunkName: "countryCodesNames" */ '@/utils/countryCodesNames.json')).default
    this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>
