import L from 'leaflet'

/* eslint-disable */

L.Control.Edit = L.Control.extend({
  options: {
    position: 'topleft',
    callback: null,
    kind: '',
    html: ''
  },

  initialize: function(options) {
      L.setOptions(this, options)
  },

  onAdd: function (map) {
    let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control'),
        link = L.DomUtil.create('a', '', container)

    link.href = '#'
    link.title = 'Create a new ' + this.options.kind
    link.innerHTML = this.options.html
    L.DomEvent.on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', function () {
                window.LAYER = this.options.callback.call(map.editTools)
              }, this)

    return container
  }
})


const EditControl = function (options) {
  return new L.Control.Edit(options)
}

export default EditControl
