import L from 'leaflet'

import wifiIconUrl from '@/assets/mapIcons/wifi.png'
import config from '@/config'
import { apiRequest, getQueryString } from '@/utils/api'
const { servers: { wigle } } = config

export default {
  methods: {
    showWiFiSpotsPopup (feature, layer) {
      const popupContent =
        '<b>SSID</b>: ' + feature.properties.ssid +
        '<br/><b>MAC</b>: ' + feature.properties.netid +
        '<br/><b>Encryption</b>: ' + feature.properties.encryption +
        '</br></br><b>Latitude</b>: ' + feature.geometry.coordinates[1] +
        '</br><b>Longitude</b>: ' + feature.geometry.coordinates[0] +
        '</br><b>Country</b>: ' + feature.properties.country || '' +
        '</br><b>Region</b>: ' + feature.properties.region || '' +
        '</br><b>City</b>: ' + feature.properties.city || '' +
        '</br><b>Road</b>: ' + feature.properties.road || '' +
        '</br><b>House number</b>: ' + feature.properties.housenumber || '' +
        '</br></br><b>Created</b>: ' + new Date(feature.properties.firsttime).toString() +
        '</br><b>Updated</b>: ' + new Date(feature.properties.lastupdt).toString()

      layer.bindPopup(
        popupContent,
        { autoPan: false }
      )

      layer.options.icon = L.icon({
        iconUrl: wifiIconUrl,
        iconSize: [32, 32],
        popupAnchor: [0, -12]
      })
    },
    getWifiSpots (bounds) {
      // TODO: Magic numbers. Not so obvious.
      if (this.currentZoom > 7) {
        if (this.lastZoom >= this.currentZoom || this.currentZoom === 16) {
          // eslint-disable-next-line
          this.mapInstance ? this.mapInstance.fireEvent('dataloading') : null
          if (Math.abs(bounds._southWest.lng) > 180) bounds._southWest.lng %= 180
          if (Math.abs(bounds._northEast.lng) > 180) bounds._northEast.lng %= 180
          // TODO: Which params should I use?
          let params = {
            latrange1: bounds._northEast.lat,
            latrange2: bounds._southWest.lat,
            longrange1: bounds._northEast.lng,
            longrange2: bounds._southWest.lng,
            paynet: false
          }
          const queryString = getQueryString(params)
          apiRequest(`network/search?${queryString}`, 'GET', {}, wigle)
            .then(res => {
              // TODO: Server-side
              let results = res.data.results.map(item => (
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [
                      item.trilong,
                      item.trilat
                    ]
                  },
                  properties: {
                    ...item
                  }
                }
              ))
              let resultsWrapper = {
                type: 'FeatureCollection',
                features: [ ...results ]
              }
              window.wifiSpots.addLayer(
                L.geoJson(
                  resultsWrapper,
                  {
                    onEachFeature: this.showWiFiSpotsPopup
                  }
                )
              )
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              // eslint-disable-next-line
              this.mapInstance ? this.mapInstance.fireEvent('dataload') : null
            })
        }
      }
      if (this.currentZoom < 16) {
        window.wifiSpots.clearLayers()
      }
    }
  },
  created () {
    window.wifiSpots = new L.MarkerClusterGroup({
      animate: false,
      iconCreateFunction: function (cluster) {
        let count = cluster.getChildCount()

        let c = ' wifi-marker-cluster-'
        if (count < 10) c += 'small'
        else if (count < 50) c += 'medium'
        else c += 'large'

        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        })
      }
    })
  },
  beforeUnmount () {
    if (window.wifiSpots) {
      window.wifiSpots.clearLayers()
    }
  }
}
