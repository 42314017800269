<template>
  <div>
    <div class="uk-padding-small uk-position-relative uk-light">
      <GeneralAvatar :src="url"/>
    </div>
  </div>
</template>

<script>
import GeneralAvatar from '@/components/GeneralAvatar'

export default {
  components: { GeneralAvatar },
  props: {
    avatar: {
      required: true
    }
  },
  computed: {
    url () {
      if (!this.avatar) return false
      return this.avatar
    }
  }
}
</script>
