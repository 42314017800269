import { tokenize } from 'simple-html-tokenizer'
import { filterXSS } from 'xss'

export default text => {
  const tokens = tokenize(filterXSS(text))
  let markupText = ''
  for (const token of tokens) {
    if (token.type === 'StartTag') {
      markupText += `<${token.tagName}`
      if (token.tagName.toLowerCase() === 'a') {
        for (const attr of token.attributes) {
          if (attr[0] !== 'target' && attr[0] !== 'rel') {
            markupText += ` ${attr[0]}="${attr[1]}"`
          }
        }
        markupText += ' rel="noopener noreferrer" target="_blank"'
      } else {
        for (const attr of token.attributes) {
          markupText += ` ${attr[0]}="${attr[1]}"`
        }
      }
      markupText += '>'
    }
    if (token.type === 'EndTag') { markupText += `</${token.tagName}>` }
    if (token.type === 'Chars') {
      markupText += token.chars
    }
  }
  return markupText
}
