/**
 * @type {(value: string) => Blob}
 */
const base64Blob = value => {
  try {
    if (typeof value !== 'string') {
      throw Error('argument "value" is not a string')
    }
    if (!value) {
      throw Error('argument "value" is empty')
    }
    const currentAtob = atob(value)
    if (!currentAtob?.length) {
      throw Error('"const currentAtob" is empty')
    }
    const currentArrayBuffer = new ArrayBuffer(currentAtob.length)
    if (!currentArrayBuffer?.byteLength) {
      throw Error('"const currentArrayBuffer" is empty')
    }
    let currentUint8Array = new Uint8Array(currentArrayBuffer)
    if (!currentUint8Array?.length) {
      throw Error('"const currentUint8Array" is empty')
    }
    for (let index = 0; index < value?.length; index++) {
        currentUint8Array[index] = currentAtob.charCodeAt(index)
    }
    return new Blob([currentUint8Array])
  } catch (error) {
    console.debug(error)
  }
}

export default base64Blob