<template>
  <button
    type="button"
    :uk-tooltip="tooltip"
    :class="buttonBindClass"
    :style="`height:${size}px;width:${size}px;`"
    class="uk-icon"
    @click.stop="storedAdd"
  >
    <font-awesome-icon
      :icon="[stored || loading ? 'fas' : 'far', 'bookmark']"
    />
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GeneralUser from '@/utils/providers/generalUser'
import { normalizeWAPhone } from '@/filters/globalFilters'

export default {
  name: 'StoredAddButton',
  props: {
    provider: {
      type: String
    },
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    additionalData: {
      type: Object
    },
    global: {
      type: Boolean
    },
    isLoading: {
      default: false
    },
    size: {
      type: Number,
      default: 30
    }
  },
  data () {
    return {
      loading: false
    }
  },
  watch: {
    loading (newVal) {
      this.$emit('set-loading', newVal)
    }
  },
  computed: {
    ...mapGetters('cases', ['currentCase', 'allStoredObjects']),
    stored () {
      if (!this.allStoredObjects[this.currentCase]) return
      return this.allStoredObjects[this.currentCase][this.extId]
    },
    buttonBindClass () {
      return this.isLoading || !this.currentCase || this.accessLevel === 'r' ? 'uk-disabled uk-text-muted' : 'uk-text-primary'
    },
    accessLevel () {
      if (!this.activeCase) return
      return this.activeCase.access_level
    },
    activeCase () {
      return this.$store.state.cases.cases[this.currentCase]
    },
    tooltip () {
      const addToCase = this.$t('cases.tasks.add-to-case')
      const removeFromCase = this.$t('cases.tasks.remove-from-case')
      const actionTitle = this.stored || this.loading ? removeFromCase : addToCase
      const caseTitle = this.activeCase ? ` - ${this.activeCase.case_title}` : ''
      return actionTitle + caseTitle
    },
    resolvedProvider () {
      let provider = (this.provider === 'tgcp' ? 'telegram' : this.provider)
      return provider
    },
    extId () {
      let { type, data, resolvedProvider } = this
      const id = this.extractId(type, data, resolvedProvider)
      let provider = resolvedProvider
      if (resolvedProvider === 'forums') {
        provider = (data.forum && data.forum.name)? data.forum.name : null
        if (!provider) {
          provider = (data.raw && data.raw.forum && data.raw.forum.name)? data.raw.forum.name : resolvedProvider
        }
      }
      return `${provider}-${type}-${id}`
    }
  },
  methods: {
    ...mapActions('cases', [
      'storedObjectsAdd',
      'deleteStoredObject',
      'resolveAsGeneralUser'
    ]),
    normalizeWAPhone,
    async storedAdd () {
      if (this.loading) return
      this.loading = true
      const { resolvedProvider, type, currentCase, stored, deleteStoredObject, storedObjectsAdd } = this
      let formatedData
      try {
        formatedData = await this.formatedData()
        if (this.additionalData) {
          formatedData = {
            entity_type: 'composite',
            main_entity: formatedData,
            additional_data: this.additionalData
          }
        }
        if (stored) {
          await deleteStoredObject({
            caseId: currentCase,
            storedId: stored.stored_id
          })
        } else {
          await storedObjectsAdd({
            storedObject: {
              provider: resolvedProvider,
              ext_id: this.extId,
              type,
              data: formatedData
            }
          })
        }
      } catch (err) {
        console.debug(err)
        this.$notify({
          group: 'general',
          title: this.$t('notifications.error'),
          text: this.$t('cases.error'),
          type: 'error'
        })
      } finally {
        setTimeout(() => (this.loading = false), 500)
      }
    },
    async formatedData () {
      const { type, data, resolvedProvider } = this
      let out = { ...data }
      switch (type) {
          case 'account':
            if (!(data instanceof GeneralUser)) {
              out = await this.resolveAsGeneralUser({id: this.extractId(type, data, resolvedProvider), provider: resolvedProvider})
              if (!out) throw new Error('Empty general user object')
            }
            break
          case 'message':
            if (resolvedProvider === 'whatsapp') {
              out.chat_id = data.group_id
              if (data.group_id.indexOf('@s.whatsapp.net') < 0) {
                out.screen_name = this.normalizeWAPhone(data.sender_phone)
              }
              out.message = data.text
              out.user_id = data.sender_phone
            }
            break
          case 'chat':
            if (resolvedProvider === 'whatsapp') {
            }
      }
      return out
    },
    extractId (type, data, provider) {
      if (type === 'account' && provider === 'whatsapp') {
        return data.phone.replace('+','')
      }
      if (type === 'account' && provider === 'forums') {
        let id = (data.raw && data.raw.id) ? data.raw.id : null
        if (!id) {
          id = (data.id) ? data.id : data.user_id
        }
        return id
      }
      if (type === 'message' && provider === 'telegram') {
        return `${data.chat_id}-${data.message_id}`
      }
      if (type === 'message' && provider === 'imo') {
        return `${data.msg_seq}@${data.bgid}`
      }
      if (type === 'scheduled-search-task' && provider === 'telegram') {
        return `${data.task_id}`
      }
      if (type === 'account' && provider === 'imo') {
        return encodeURIComponent(data.discover_anon_id)
      }
      return  data.tg_id || data.tgid || data.id || data.user_id || data.message_id || data.phone || data.company_number || data.uid || data.task_id
    }
  }
}
</script>
