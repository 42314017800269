<template>
  <div class="home uk-container">
    <div class="uk-margin uk-margin-bottom">
      <div>
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="update"
        >
          <div class="uk-display-inline-block">
            <input
              v-model.trim="query.search"
              type="text"
              :placeholder="$t('forums.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="search"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <div
        v-if="!isLoaded['forums']"
        class="uk-margin"
      >
        <Loader/>
      </div>
      <div v-else>
        <template v-if="isLoaded['forums'] && !hasDataToView">
          <div class="uk-margin uk-margin-top">
            <NotFound />
          </div>
        </template>
        <template v-else>
          <Item
            v-for="forum in forums"
            :key="forum.id"
            :forum="forum"
            class="uk-margin-top"
          />
        </template>
        <div
          v-if="!hidePagination"
          class="uk-margin"
        >
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
    <ExportDialog
      namespace="forums"
      type="forums"
      :query="query"
      :messages="selectedMessages"
      :totalCount="pagination?.total ?? 0"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Item from '@/components/Forums/ForumsList/Item'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import vueSelect from '@/components/VueSelect'
import { mapActions, mapGetters } from 'vuex'
import forumsSearch from '@/mixins/forumsSearch'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ForumsList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Item,
    Loader,
    Paginate,
    vueSelect
  },
  mixins: [forumsSearch],
  data () {
    return {
      selectedMessages: [],
      forums: [],
      query: {
        limit: 20,
        offset: 0,
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || 'asc',
        search: this.$route.query.search || ''
      },
      pagination: {
        page: 1,
        total: null
      },
      orderList: ['id', 'name', 'url']
    }
  },
  computed: {
    ...mapGetters('forums', ['isLoaded']),
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('forums.users.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    hidePagination () {
      if (!this.lastPage || this.lastPage === 1) return true
      return false
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    hasDataToView () {
      return this.forums.length > 0
    }
  },
  methods: {
    ...mapActions('forums', ['fetchAllForums', 'addParamToQuery']),
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      !this.id &&
        this.addParamToQuery({
          page: this.pagination.page
        })
      this.update()
    },
    setData (res) {
      this.forums = res.data
      this.pagination.total = res.meta.results_count
    },
    clearData (err) {
      this.forums = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: err.message, type: 'error' })
    },
    update () {
      this.fetchAllForums(this.query)
        .then(res => this.setData(res))
        .catch(err => this.clearData(err))
    },
    search () {
      this.query.offset = 0
      this.pagination.page = 1
      this.update()
    }
  },
  created () {
    this.setPage(Number(this.$route.query.page) || 1)
  }
}
</script>
