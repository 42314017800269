import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class FetcherUsersName extends Base {
  schema = Schemas.fetcher
  OPTIONS_SCHEMA = ['messages', 'allowedFields', 'localizeFields']
  fetch = async ({ options }) => {
    this.loaded = 100
    return options.messages
  }
}

export default FetcherUsersName
