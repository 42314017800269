<template>
  <div
    id="map-user-geo"
    class="uk-margin-top"
    :style="{ height: '100%'}"
    @onresize="handleResize"
  >
    <l-map
      ref="map"
      style="height: 80%; width: 100%"
      :zoom="zoom"
      :center="center"
      :options="{
        zoomControl: false,
        maxZoom: 18,
        worldCopyJump: true,
        minZoom: 3,
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: 'Copy coordinates',
            callback: copyCoords
          },
          {
            text: 'Add geotask',
            callback: addGeotask
          }
        ]
      }"
      :maxBounds="maxBounds"
      :maxBoundsViscosity="1.0"
      @update:bounds="boundsUpdated"
      @update:zoom="zoomUpdate"
    >
      <button
        class="uk-modal-close-default disableclosemapfalse uk-alight-right close"
        type="button"
        uk-close
        @click.prevent="close"
      />

      <l-tile-layer
        :url="url"
      />
      <l-geosearch :options="options.geoSearch" />
      <l-control-scale
        position="bottomleft"
        :imperial="true"
        :metric="true"
      />
      <l-control-zoom position="topleft"/>
      <l-control-layers
        ref="control"
        position="topleft"
        :collapsed="true"
        :hideSingleBase="true"
      />
    </l-map>
    <div
      v-if="near.coords.length > 1"
      class="uk-card uk-card-default"
    >
      <div
        uk-grid
        class="uk-grid-small uk-grid"
      >
        <div class="uk-width-1-3 uk-margin">
          <div
            class="uk-margin-small-left uk-margin-top"
            uk-grid
          >
            <div class="uk-width-auto">
              Speed:
            </div>
            <div class="uk-width-expand">
              <VueSlideBar
                v-model="player.speed"
                class="priority__slider"
                :range="player.range"
                tooltip="none"
                :min="0.25"
                :max="4"
              />
            </div>
          </div>
        </div>
        <div class="uk-text-center uk-width-1-3 uk-margin-top">
          <span
            class="uk-button uk-button-primary"
            @click="jumpTo('first')"
          >
            <font-awesome-icon
              :icon="['fa', 'fast-backward']"
            />
          </span>
          <span
            class="uk-button uk-button-primary"
            @click="jumpTo('previous')"
          >
            <font-awesome-icon
              :icon="['fa', 'backward']"
            />
          </span>
          <span
            class="uk-button uk-button-primary "
            @click="play"
          >
            <font-awesome-icon
              v-if="player.stop"
              :icon="['fa', 'play']"
            />
            <font-awesome-icon
              v-else
              :icon="['fa', 'pause']"
            />
          </span>
          <span
            class="uk-button uk-button-primary"
            @click="jumpTo('next')"
          >
            <font-awesome-icon
              :icon="['fa', 'forward']"
            />
          </span>
          <span
            class="uk-button uk-button-primary"
            @click="jumpTo('last')"
          >
            <font-awesome-icon
              :icon="['fa', 'fast-forward']"
            />
          </span>
        </div>
        <div class="uk-margin-top uk-width-1-3 uk-text-center">
          <span
            :class="zoomTo ? 'uk-text-primary' : 'uk-text-muted'"
          >
            <input
              v-model="zoomTo"
              type="checkbox"
              class="uk-checkbox"
            />
            Zoom to point
          </span>
        </div>
      </div>
      <VueApexCharts
        v-if="timeLineGraphData"
        ref="geoChart"
        type="rangeBar"
        height="90"
        :options="options.lineGraph"
        :series="timeLineGraphData"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import L, {
  latLng,
  Icon,
  icon,
  divIcon
} from 'leaflet'
import {
  LMap,
  LMarker,
  LPopup,
  LTileLayer,
  LControlZoom,
  LControlScale,
  LControlLayers
} from 'vue2-leaflet'

import 'leaflet-rotatedmarker'
import 'leaflet-contextmenu'

import { OpenStreetMapProvider } from 'leaflet-geosearch'

import Geosearch from 'vue2-leaflet-geosearch'

import '@/assets/css/leaflet-geosearch.css'
import '@/assets/css/ControlLoading.css'
import '@/assets/css/leaflet.contextmenu.css'
import '@/assets/css/leaflet.css'
import 'leaflet/dist/leaflet.css'

import camIconUrl from '@/assets/mapIcons/cctv.png'
import disabledIconUrl from '@/assets/mapIcons/marker-icon.png'
import mobileIconUrl from '@/assets/mapIcons/mobile_icon.png'
import shadowIcon from 'leaflet/dist/images/marker-shadow.png'

import ControlLoading from '@/components/map/controls/Loading.js'
import MarkerCluster from '@/components/map/MarkerCluster.vue'
import TGCPChatsLayer from '@/components/map/layers/TGCPChats.js'
import GeoTasks from '@/components/map/layers/GeoTasks.js'
import VueApexCharts from 'vue3-apexcharts'
import VueSlideBar from '@/components/VueSlideBar'

import tgUsersLayer from '@/components/map/layers/tgUsers'

import config from '@/config'
const { servers: { map } } = config

import UIkit from 'uikit'
// this part resolve an issue where the markers would not appear

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

import { mapActions } from 'vuex'

const sleep = m => new Promise(resolve => setTimeout(resolve, m))

export default {
  name: 'map_user_geo',
  mixins: [
    TGCPChatsLayer,
    GeoTasks,
    tgUsersLayer
  ],
  props: {
    user: {
      type: Object,
      required: true
    },
    near: {
      type: Object,
      required: true
    },
    modal: {
      type: HTMLDivElement
    }
  },
  data () {
    return {
      mapInstance: null,
      chartInstance: null,
      window: {
        width: 0,
        heigth: 0
      },
      zoomTo: true,
      usersNear: false,
      layers: {
        tgcpChats: false,
        geoTasks: false,
        tgUsers: false
      },
      url: map + '/{z}/{x}/{y}.png',
      zoom: 4,
      currentZoom: 4,
      lastZoom: 3,
      center: [0, 0],
      bounds: null,
      player: {
        stop: true,
        current: 0,
        speed: 1,
        range: [0.25, 0.5, 1, 2, 4, 8]
      },
      markers: [],
      currentBounds: this.maxBounds,
      options: {
        geoSearch: {
          position: 'topleft',
          style: 'bar',
          provider: new OpenStreetMapProvider()
        },
        lineGraph: {
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 1
              }
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: 'darken',
                value: 0.05
              }
            }
          },
          chart: {
            type: 'rangeBar',
            toolbar: {
              show: true,
              tools: {
                download: false
              }
            },
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              offsetX: -57,
              showDuplicates: false,
              formatter: value => this.dayjs(value) // The formatter function overrides format property
            },
            min: () => dayjs(this.dayjs(this.near.first_date_unix * 1000)).unix(),
            max: () => dayjs(this.dayjs(this.near.last_date_unix * 1000)).unix()
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          tooltip: {
              custom: ({ series, seriesIndex, dataPointIndex, w }) => this.dayjs(this.timeLineGraphData[0].data[dataPointIndex].y[0])
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchGeoTasks',
      'addTask'
    ]),
    async play () {
      this.player.stop = !this.player.stop
      if (this.player.current === this.timeLineGraphData[0].data.length - 1) this.player.current = 0
      for (let i = this.player.current || 0; i < this.timeLineGraphData[0].data.length; i++) {
        if (this.player.stop) break
        this.activate(i)
        await sleep(500 / +this.player.speed)
      }
      this.player.stop = true
    },
    async activate (i) {
      if (i === undefined) i = this.player.current
      else this.player.current = i
      this.hideMarker()
      let bounds = this.showMarker(i)
      let start = this.timeLineGraphData[0].data[i].y[0]
      let end = this.timeLineGraphData[0].data[i].y[1]
      try {
        this.chartInstance.clearAnnotations()
          let annotation = {
            x: start,
            x2: end,
            fillColor: 'black',
            opacity: 1,
            label: {
              orientation: 'horizontal',
              offsetY: -10,
              offsetX: this.timecenter >  start ? 50 : -50,
              text: this.dayjs(start)
            }
        }
        this.chartInstance.addXaxisAnnotation(annotation, false)
      } catch {}
      await this.getTimelineUsers({
        bbox: this.getBBOX(bounds),
        from_date_unixtime: start,
        to_date_unixtime: end
        }, this.user.tg_id)
    },
    copyCoords (e) {
      const coords = [ e.latlng.lat, e.latlng.lng ].join(',')
      const el = document.createElement('textarea')
      el.value = coords
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    showMarker (index) {
      let coord = this.timeLineGraphData[0].data[index].coord.coord
      let type = this.timeLineGraphData[0].data[index].coord.type
      var layer = new L.FeatureGroup()
      for (let el of coord) {
        var latlng = { lat: Number.parseFloat(el.lat), lng: Number.parseFloat(el.lng) }
           new L.circle(latlng, {
            radius: type === 'area' ? el.distance : 1000,
            color: 'gray',
            stroke: true,
            opacity: type === 'area' ? 1 : 0,
            fillOpacity: 0
          }).addTo(layer)
          new L.marker(latlng, type === 'point'
          ? {
            icon: new L.icon({
              iconUrl: mobileIconUrl,
              iconSize: [26, 26],
              iconAnchor: [12, 38]
            }),
            radius: 5
          }
          : {}).addTo(layer).setZIndexOffset(1000)
      }
      layer.addTo(window.userGeo)
      layer.bringToFront()
      if (this.zoomTo) {
        this.mapInstance && this.mapInstance.fitBounds(layer.getBounds())
      }
      return layer.getBounds()
    },
    jumpTo (pos) {
      this.player.stop = true
      switch (pos) {
        case 'next':
          this.timeLineGraphData[0].data[this.player.current + 1]
            ? this.activate(this.player.current + 1)
            : this.activate(0)
          break
        case 'last':
          this.activate(this.timeLineGraphData[0].data.length - 1)
          break
        case 'previous':
          this.timeLineGraphData[0].data[this.player.current - 1]
            ? this.activate(this.player.current - 1)
            : this.activate(this.timeLineGraphData[0].data.length - 1)
          break
        case 'first':
          this.activate(0)
        break
      }
    },
    hideMarker () {
      window.userGeo.clearLayers()
    },
    handleClick (e, data, config) {
      if (config.dataPointIndex === -1) return
      if (e) this.player.stop = true
      this.activate(config.dataPointIndex)
    },
    boundsUpdated (bounds) {
      this.currentBounds = bounds
      if (this.currentZoom > 2 && this.layers.tgcpChats) {
        if (this.lastZoom >= this.currentZoom) {
          this.getTGCPChats(bounds)
        }
      }
    },
    zoomUpdate (zoom) {
      this.lastZoom = this.currentZoom
      this.currentZoom = zoom
    },
    handleResize () {
      this.mapInstance && this.mapInstance.invalidateSize()
    },
    overlayEventHandler (event) {
      if (!event) return
      let value = null
      if (event.type === 'overlayadd') value = true
      else if (event.type === 'overlayremove') value = false
      else return

      let bounds = this.currentBounds ? this.currentBounds : this.bounds
      switch (event.name) {
        case 'Telegram Chats':
          this.layers.tgcpChats = value
          if (value) this.getTGCPChats(bounds)
          break
        case 'Geo Tasks':
          this.layers.geoTasks = value
          const isNotLoadedGeoTask = this.getCountMarkers(window.geoTasks) === 0
          if (value && isNotLoadedGeoTask) this.getGeoTasks()
          break
        case 'Users near':
          this.layers.tgUsers = value
          this.usersNear = true
          const isNotLoadedTgUsers = this.getCountMarkers(window.tgUsers) === 0
          if (value && isNotLoadedTgUsers) this.getTgUsers()
          this.activate()
          break
      }
    },
    getBBOX (bounds) {
      if (Math.abs(bounds._southWest.lng) > 180) bounds._southWest.lng %= 180
      if (Math.abs(bounds._northEast.lng) > 180) bounds._northEast.lng %= 180
      return bounds._southWest.lat + ',' + bounds._southWest.lng + ',' + bounds._northEast.lat + ',' + bounds._northEast.lng
    },
    close () {
      UIkit.modal(this.modal).hide()
    },
    getCountMarkers (layer) {
      if (!layer) return 0
      if (layer.getLayers()) {
        return layer.getLayers().length
      }
    }
  },
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-control-zoom': LControlZoom,
    'l-control-scale': LControlScale,
    'l-geosearch': Geosearch,
    'l-marker-cluster': MarkerCluster,
    'l-marker': LMarker,
    'l-popup': LPopup,
    'l-control-layers': LControlLayers,
    VueApexCharts,
    VueSlideBar
  },
  computed: {
    timecenter () {
      return ((+this.options.lineGraph.xaxis.max() + +this.options.lineGraph.xaxis.min()) / 2) * 1000
    },
    maxBounds () {
      var southWest =  new L.latLng(-89.9999, -180)
      var northEast =  new L.latLng(90, 180)
      var group =  new L.latLngBounds(southWest, northEast)
      return group
    },
    timeLineGraphData () {
      var data = this.near.coords
      var series = [{ data: [] }]
      data.map(el => {
        var timestamp = dayjs(this.dayjs(el.timestamp_unix * 1000)).unix() * 1000
        var last = dayjs(this.dayjs(el.timestamp_unix * 1000)).add(179, 's').unix() * 1000
          series[0].data.push({
          x: 'Online',
          y: [timestamp, last],
          coord: { coord: el.coord, type: el.type }
        })
      })
      series[0].data
      return series
    }
  },
  created () {
    window.userGeo = new L.layerGroup()
    UIkit.util.on(this.modal, 'shown', () => {
      this.mapInstance && this.mapInstance.invalidateSize()
    })
    window.addEventListener('resize', this.handleResize)

  },
  async mounted () {
    await this.$nextTick()
    this.mapInstance = this.$refs.map.mapObject
    this.chartInstance = this.$refs.geoChart
    window.userGeo.addTo(this.mapInstance)
    this.currentBounds = this.mapInstance.getBounds()
    ControlLoading({ position: 'topleft' }).addTo(this.mapInstance)
    this.controlInstance = this.$refs.control.mapObject
    this.controlInstance.addOverlay(window.tgcpChats, 'Telegram Chats')
    this.controlInstance.addOverlay(window.geoTasks, 'Geo Tasks')
    this.controlInstance.addOverlay(window.tgUsers, 'Users near')
    this.mapInstance.on('overlayremove', this.overlayEventHandler)
    this.mapInstance.on('overlayadd', this.overlayEventHandler)
    this.handleResize()
    setTimeout(this.activate, 500)
  },
  beforeUnmount () {
    this.close()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.annotation-apex {
    z-index: 999;
    color: 'red';
    border: black;
}
</style>
