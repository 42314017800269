<template>
  <div
    v-show="ready"
    class="info legend leaflet-control-attribution legend-custom"
  >
    <span
      v-if="!offLegend"
      style="line-height: 10px;"
    >
      Clusters:
    </span>
    <div
      v-if="layers.tgcpChats"
      class="legend-gradient"
    >
      <span style="line-height: 15px;">
        <small>
          Telegram Chats
        </small>
      </span>
      <img src="/img/tt.png"/>
    </div>
    <div
      v-if="layers.airports"
      class="legend-gradient"
    >
      <span style="line-height: 15px;">
        <small>
          Airports
        </small>
      </span>
      <img src="/img/rt.png"/>
    </div>
    <div
      v-if="layers.singaporeTrafficCams"
      class="legend-gradient"
    >
      <span style="line-height: 15px;">
        <small>
          Singapore Traffic Cams
        </small>
      </span>
      <img src="/img/ct.png"/>
    </div>
    <div
      v-if="layers.geoTasks"
      class="legend-gradient"
    >
      <span style="line-height: 15px;">
        <small>
          Geo Tasks
        </small>
      </span>
      <img src="/img/gt.png"/>
    </div>
    <div
      v-if="layers.wifiSpots"
      class="legend-gradient"
    >
      <span style="line-height: 15px;">
        <small>
          Wifi
        </small>
      </span>
      <img src="/img/ct.png"/>
    </div>
  </div>
</template>

<script>
import L from 'leaflet'
import { findRealParent } from 'vue2-leaflet'

export default {
  props: {
    position: {
      type: String,
      required: false
    },
    layers: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    offLegend () {
      let i = 0
      let j = 0
      for (let prop in this.layers) {
        j++
        if (!this.layers[prop]) i++
      }

      return i === j
    }
  },
  mounted () {
    L.Control.Legend = L.Control.extend({
      element: undefined,
      onAdd () {
        return this.element
      },
      setElement (el) {
        this.element = el
      }
    })
    this.mapObject = new L.Control.Legend({ position: this.position })
    this.mapObject.setElement(this.$el)
    setTimeout(() => {
      this.ready = true
    }, 500)
    this.parentContainer = findRealParent(this.$parent)
    this.mapObject.addTo(this.parentContainer.mapObject)
  }
}
</script>

<style>
.legend-custom. #map .legend-custom {
  min-width: 0;
  position: relative;
  text-align: left;
  top: 250px;
  left: -10px;
}
.legend-custom span {
  display: block;
}
.span-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block!important;
}
.legend-gradient {
  padding: 3px 0;
}
</style>
