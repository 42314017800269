<template>
  <div 
    class="uk-margin-remove uk-text-left uk-text-truncate" 
    :style="`max-width: ${'calc(' + (customDeskWidth ? customDeskWidth : '75vw * 0.8') + ' - 20px)'}`"
  >
    <slot />
    <button 
      v-if="text"
      class="uk-display-inline uk-icon-link" 
      uk-icon="icon: copy"
      style="min-width: 20px;"
      @click="copyToClipboard(text)"
    />
  </div>
</template>

<script>
export default {
  name: 'TonCopyField',
  props: {
    text: {
      type: String,
      required: true
    },
    customDeskWidth: {
      type: String,
      required: false
    }
  },
  methods: {
    async copyToClipboard (val) {
      const { default: copyToClipboard } = await import(/* webpackChunkName: "copyToClipboard" */ '@/utils/copyToClipboard')
      copyToClipboard(val)
      this.$notify({ group: 'general', text: this.$t('utils.copied-to-clipboard'), type: 'general', duration: 700 })
    }
  }
}
</script>