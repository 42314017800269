<template>
  <div>
    <div class="uk-text-center">
      <Tab
        v-for="(entry, index) in entries"
        :key="key(index)"
        :entry="entry"
        @select="select"
      />
    </div>
  </div>
</template>
<script>
import Tab from './Tab.vue'
export default {
  name: 'SearchingFacesTabs',
  components: {
    Tab
  },
  props: {
    entries: {
      type: Array
    }
  },
  computed: {
    entriesLength () {
      return this.entries?.length || 0
    }
  },
  methods: {
    key (index) {
      return this.entriesLength.toString().concat(index.toString())
    },
    select (entry) {
      try {
        this.$emit('select', entry)
      } catch (error) {
        console.debug(error)
      }
    }
  }
}
</script>
