<template>
  <div
    id="find_location"
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h3 class="uk-modal-title">
        {{ $t('gp.find-location') }}
      </h3>
      <div class="uk-card-body uk-padding-remove-top">
        <p><i>
          {{ $t('gp.please-note-that-location-detection') }}
        </i></p>
        <Loader v-if="!isLoaded"/>
        <div v-else>
          <div
            v-if="location.error && location.error!='NOERROR' && location.Location_status!='AVAILABLE'"
            class="uk-margin-small-bottom"
          >
            {{ $t('gp.sorry-cant-detect-phone') }}
          </div>
          <div
            v-else
            class="uk-margin-small-bottom"
          >
            <table class="uk-table">
              <tr
                v-for="(item, key) in location"
                :key="key"
              >
                <td v-if="item!=='' && item!=null && key!='used_credits' && key!='credits' && key!='status' && key!='cachedResult' && key!='AgeOfLocationInformation' && key!='Location_status'">
                  {{ $t('gp.' + key) }}
                </td><td v-if="item!=='' && item!=null && key!='used_credits' && key!='AgeOfLocationInformation' && key!='status' && key!='cachedResult' && key!='Location_status' && key!='credits'">
                  {{ item }}
                </td>
              </tr>
              <tr v-if="location.lat || location.long">
                <td>
                  {{ $t('gp.coordinates') }}
                </td>
                <td>
                  <router-link
                    class="uk-modal-close"
                    :to="{name:'global_map_by_coords_with_mark', params: {coords:`${location.lat},${location.long}`, data: phone}}"
                    @click="closeMe"
                  >
                    <span uk-icon="icon: location"/><span>
                      {{ location.lat }}, {{ location.long }}
                    </span>
                  </router-link>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <button
          class="uk-modal-close uk-button uk-button-secondary"
          type="button"
        >
          {{ $t('gp.close') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/TGCP/Loader'
import UIkit from 'uikit'

export default {
  name: 'FindLocation',
  components: {
    Loader
  },
  props: {
    phone: {
      required: true,
      type: String
    }
  },
  watch: {
    phone (from, to) {
      this.update()
    }
  },
  computed: {
    location () {
      return this.$store.state.fetcher.location[this.phone]
    },
    isLoaded () {
      return this.$store.state.fetcher.isLoaded['location_' + this.phone]
    }
  },
  methods: {
    closeMe () {
      UIkit.modal('#find_location').hide()
    },
    update () {
      const action = 'fetcher/fetchLocation'
      this.$store.dispatch(action, this.phone)
    }
  },
  mounted () {
    this.update()
  }
}
</script>
