<template>
  <div>
    <div class="uk-margin-top-small">
      <div
        class="uk-card uk-card-default uk-card-body uk-margin-top"
      >
        <div
          uk-grid
        >
          <div
            class="uk-width-1-3"
          >
            <button
              class="uk-button uk-button-primary uk-align-left"
              type="button"
              @click="showModal(false)"
            >
              {{ $t('cases.add_case') }}
            </button>
          </div>
          <div
            class="uk-width-1-3 uk-text-center"
          >
            <div class="uk-inline uk-align-center uk-width-1-1">
              <form @submit.prevent="setPage(1)">
                <input
                  v-model.trim="query.search"
                  type="text"
                  class="uk-input uk-input-text uk-width-1-1"
                  :placeholder="$t('cases.search')"
                />
                <button
                  class="uk-form-icon uk-form-icon-flip uk-button-primary"
                  uk-icon="icon: search"
                  type="submit"
                />
              </form>
            </div>
          </div>
          <div
            class="uk-width-1-3"
          >
            <ConfirmInvintation
              @update="update"
            />
          </div>
        </div>

        <table class="uk-table   uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr>
              <th class="uk-text-center uk-width-1-4">
                {{ $t('cases.case_title') }}
              </th>
              <th class="uk-text-center uk-table-expand" >
                {{ $t('cases.case_description') }}
              </th>
              <th
                class="uk-text-center"
                width="10"
              >
                {{ $t('cases.access_level') }}
              </th>
              <th
                class="uk-text-center"
                width="200"
              >
                {{ $t('tgcp.tasks.actions_th') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="caseInfo in cases"
              :key="caseInfo.case_id"
            >
              <td class="uk-text-left" >
                 &nbsp;<router-link
                    :title="caseInfo.case_title"
                    class="uk-text-ellipsis"
                    :to="{name: 'case_details',
                    params: { caseId: caseInfo.case_id } } "
                       >
                        {{ caseInfo.case_title }}
                        </router-link>
              </td>
              <td class="uk-text-break uk-text-left">
                {{ caseInfo.case_description }}
              </td>
              <td class=" uk-text-center">
                <span nowrap>
                  {{ getAccessLevel(caseInfo.access_level) }}
                </span>
                <span/>
              </td>
              <td>
                <div class="uk-button-group ">
                  <CopyCase
                    :caseInfo="caseInfo"
                    @merged="update"
                  />
                  <button
                    class="uk-button uk-button-text "
                    type="button"
                    style="height:40px;width:40px;"
                    :class="defaultCase === caseInfo.case_id
                      && 'uk-text-primary'"
                    :title="$t('cases.pin_case')"
                    @click="pinCase(caseInfo.case_id)"
                  >
                    <span uk-icon="star"/>
                  </button>
                  <span v-if="['f'].includes(caseInfo.access_level)">
                    <button
                      class="uk-button uk-button-text "
                      type="button"
                      style="height:40px;width:40px;"
                      :title="$t('cases.edit_case')"
                      :class="{
                        'uk-disabled': loading
                      }"
                      :disabled="loading"
                      @click="$router.push({ name: 'case_settings', params: { caseId: caseInfo.case_id } })"
                    >
                      <span uk-icon="pencil"/>
                    </button>
                    <button
                      class="uk-button uk-button-text "
                      type="button"
                      style="height:40px;width:40px;"
                      :title="$t('cases.archive_case')"
                      :class="{
                        'uk-disabled': loading
                      }"
                      :disabled="loading"
                      @click="archive(caseInfo)"
                    >
                      <span uk-icon="pull"/>
                    </button>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      id="addCaseInfoList"
      uk-modal
    >
      <CaseModal
        :id="'addCaseInfoList'"
        class="uk-modal-dialog"
        @added="hideModal"
      />
    </div>
    <div v-if="!hidePagination">
      <Paginate
        v-model="pagination.page"
        :page-count="lastPage"
        :click-handler="setPage"
        prev-text="<"
        next-text=">"
        :container-class="'uk-pagination uk-flex-center uk-margin-small-top'"
      />
    </div>
    <ExportDialog
      namespace="cases"
      :query="query"
    />
  </div>
</template>

<script>
import Paginate from '@/components/Paginate'
import { mapGetters, mapActions } from 'vuex'
import CaseModal from '@/components/cases/CaseModal'
import ConfirmInvintation from '@/components/cases/CaseInvintation.vue'
import UIkit from 'uikit'
import CopyCase from '@/components/cases/CopyCase'

export default {
  name: 'CaseList',
  components: {
    CaseModal,
    ConfirmInvintation,
    Paginate,
    CopyCase
  },
  data () {
    return {
      info: {},
      cases: [],
      caseTitle: '',
      caseDescription: '',
      id: -1,
      query: {
        limit: 20,
        offset: 0,
        search: ''
      },
      pagination: {
        page: 1,
        total: null
      },
      loading: false,
      error: {
        title: false,
        description: false,
        send: false
      }
    }
  },
  computed: {
    ...mapGetters('cases', [
      'currentCase',
      'allCases'
    ]),
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    lastPage () {
      const lastPage = Math.floor((this.pagination.total) / (this.query.limit))
      return this.pagination.total / lastPage - (this.query.limit) === 0 ? lastPage : lastPage + 1
    },
    defaultCase () {
      return this.$store.state.settings.defaultCase
    }
  },
  methods: {
    ...mapActions('cases', [
      'archiveCase',
      'fetchCases',
      'getCaseById',
      'setCurrentCase'
    ]),
    async archive (caseData) {
      this.loading = true
      await this.archiveCase(caseData)
      await this.update()
      this.loading = false
    },
    clearData (err) {
      this.cases = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err.message, type: 'error' })
    },
    setData (res) {
      this.cases = res.cases
      this.pagination.total = res.total
    },
    setPage (page = 1) {
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = page
      this.update()
    },
    async update () {
      try {
        this.loading = true
        this.setData(await this.fetchCases(this.query))
      } catch (err) {
        this.clearData(err)
      } finally {
        this.loading = false
      }
      if (this.defaultCase && !this.query.search) {
        await this.getCaseById(this.defaultCase)
        this.cases.unshift(this.allCases[this.defaultCase])
      }
    },
    getAccessLevel (accessLevel) {
      switch (accessLevel) {
          case 'r': return this.$t('access.ro')
          case 'rw': return this.$t('access.rw')
          case 'f': return this.$t('access.f')
      }
    },
    pinCase (id) {
      this.$store.dispatch('setDefaultCase', { defaultCase: id })
      this.setCurrentCase(id)
    },
    showModal (info) {
      this.info = info
      UIkit.modal('#addCaseInfoList').show()
    },
    hideModal () {
      this.update()
      return UIkit.modal('#addCaseInfoList').hide()
    },
    send () {
      this.loading = true
      const caseInfo = {
        case_title: this.caseTitle,
        case_description: this.caseDescription
      }
      this.editCase({ id: this.id, caseData: caseInfo })
        .then(() => {
          UIkit.modal('#editCaseInfo').hide()
          this.update()
          this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('cases.successfully_updated'), type: 'success' })
        })
        .catch(() => {
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
.uk-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
}
</style>
