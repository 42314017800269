<template>
  <div>
    <div class="uk-card  uk-card-default uk-margin-bottom">
      <div class="uk-card-body ">
        <h3
          v-if="hasPhone"
          class="uk-card-title uk-text-left uk-margin-top"
        >
          {{ $t('tgcp.user_details.general_info_title') }}
        </h3>
        <div
          class="uk-grid"
          data-uk-grid-margin
        >
          <div
            v-if="hasPhone"
            class="uk-width-1-4@m"
          >
            <AvatarList :id="id"/>
          </div>
          <div class="uk-width-3-4@m  uk-text-left ">
            <h4
              v-if="hasPhone"
              class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted"
            >
              #{{ id }}
            </h4>
            <NotFound v-else />
            <div class="uk-margin-small-top">
              <UserPhone
                :id="String(id)"
                type="details"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserPhone from '@/components/TGCP/UserDetails/UserPhone'
import AvatarList from '@/components/TGCP/AvatarList'
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TgcpEmptyUserGeneralInfo',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    UserPhone,
    AvatarList
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      phone: false
    }
  },
  computed: {
    ...mapGetters('tgcp', [
      'allUsers',
      'userAvatars'
    ]),
    hasPhone () {
      return this.$store.state.tgcp.users[this.id].has_phone
    }
  }
}
</script>
