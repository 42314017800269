<template>
  <div>
    <ChannelCard
      v-for="channel in channels"
      :key="channel.tg_id"
      class="uk-margin-top"
      :channel="channel"
    />
  </div>
</template>

<script>
import ChannelCard from '@/components/TGCP/ChannelList/ChannelCard.vue'

export default {
  name: 'Items',
  components: {
    ChannelCard
  },
  props: ['channels']
}
</script>

<style>
    .channel {
        position: relative;
        overflow: hidden;
    }
    .channel_badge {
        background-color: #444;
        box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
        height: 100px;
        left: -50px;
        position: absolute;
        top: -50px;
        width: 100px;
        color: white;
        line-height: 15;
        font-size: 0.7rem;
        -webkit-transform: rotate(-45deg);
    }
</style>
