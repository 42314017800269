<template>
  <div
    class="uk-align-center"
    uk-grid
  >
    <form class="uk-form-horizontal uk-form">
      <div class="uk-inline uk-width-2-3">
        <input
          v-model.trim="query.q"
          class="uk-input uk-width-1-3"
          type="text"
          :placeholder="$t('databases.search-companies', { count: '182 312 333' })"
        />
        <vueSelect
          v-model="currentJurisdiction"
          :disabled="spinners.includes('jurisdictions')"
          :options="jurisdictionsComputed"
          label="label"
          class="uk-display-inline-block uk-form-width-small  uk-width-1-4 uk-padding-remove"
        />
        <vueSelect
          v-model="currentInactive"
          :options="inactiveElements"
          label="label"
          class="uk-display-inline-block uk-form-width-small  uk-width-1-4 uk-padding-remove"
        />
        <button
          class="uk-button uk-button-primary"
          uk-icon="icon: search"
          @click.prevent="search"
        />
      </div>
    </form>
    <div v-if="isLoaded['search'] === false">
      <Loader />
    </div>
    <div v-else-if="isLoaded['search'] && companies.length === 0">
      <NotFound />
    </div>
    <div
      v-else
      class="uk-grid uk-margin-top uk-margin-bottom"
      data-uk-grid-margin
    >
      <div
        class="uk-grid-small
            uk-child-width-1-1@s uk-flex-left
            uk-width-1-1
            uk-grid filter_area"
      >
        <Item
          v-for="company in companies"
          :key="company.id"
          :company="company"
          class="uk-margin-top"
        />
        <Paginate
          v-if="isLoaded['search'] && Number(pagination.total_pages) > 1"
          v-model="pagination.page"
          :page-count="pagination.total_pages"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center uk-margin-small-top"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/DataBases/List/Item.vue'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import vueSelect from '@/components/VueSelect'
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OpencorporateCompaniesList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Item,
    Loader,
    Paginate,
    vueSelect
  },
  data () {
    return {
      jurisdictions: [],
      companies: [],
      query: {
        q: this.$route.query.q || '',
        country_code: null,
        company_type: null,
        inactive: '',
        industry_codes: null,
        normalise_company_name: false,
        jurisdiction_code: undefined
        // inactive: this.$route.query.active || undefined
      },
      pagination: {
        page: 1,
        total_pages: 0
      },
      spinners: []
    }
  },
  computed: {
    ...mapGetters('organizations', ['allJurisdictions', 'isLoaded']),
    currentInactive: {
      get () {
        return this.query.inactive
      },
      set (obj) {
        this.query.inactive = obj && obj.value
        this.update()
      }
    },
    inactiveElements () {
      return [
        { value: '', label: 'All' },
        { value: 'false', label: 'Active' },
        { value: 'true', label: 'Inactive' }
      ]
    },
    currentOrderType: {
      get () {
        return this.order_type
      },
      set (obj) {
        this.order_type = obj && obj.value
        this.update()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('twitter.sort.' + word), value: word }))
    },
    currentJurisdiction: {
      get () {
        return this.query.jurisdiction_code
      },
      set (obj) {
        this.query.jurisdiction_code = obj && obj.value
        this.update()
      }
    },
    jurisdictionsComputed () {
      const jurisdictios = this.jurisdictions.map(({ code: value, name: label }) => ({
        value,
        label
      }))
      return [
        {
          value: undefined,
          label: this.$t('databases.all-jurisdictions')
        },
        ...jurisdictios
      ]
    }
  },
  methods: {
    ...mapActions('organizations', ['fetchAllJurisdictions', 'fetchCompaniesByName', 'addParamToQuery']),
    setPage (page) {
      this.pagination.page = page
      this.addParamToQuery({ page })
      this.update()
    },
    async update () {
      try {
        let query = this.query
        const { page } = this.pagination
        if (page > 1) {
          query = { ...query, page }
        }
        this.setData(await this.fetchCompaniesByName(query))
      } catch (err) {
        this.handleError(err)
      }
    },
    setData (data) {
      this.companies = data.companies
      this.pagination.page = data.pagination.page
      this.pagination.total_pages = data.pagination.total_pages
    },
    handleError () {
      this.companies = []
      this.pagination.page = 1
      this.pagination.total_pages = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    search () {
      this.pagination.page = 1
      this.addParamToQuery(this.query)
      this.setPage(1)
    }
  },
  async mounted () {
    this.spinners.push('jurisdictions')
    this.jurisdictions = (await this.fetchAllJurisdictions()) || []
    this.spinners = this.spinners.filter(el => el !== 'jurisdictions')
    this.update()
  }
}
</script>
