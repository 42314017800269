<template>
  <div
    v-if="ad"
    class="uk-card uk-card-default
     uk-margin-top"
  >
    <div class="uk-card-body">
      <div class="uk-align-right">
        <AddStoredButton
          provider="boards"
          type="ad"
          :data="ad"
        />
      </div>
      <span
        v-if="ad.views"
        class="uk-float-right"
        uk-icon
      >
        <font-awesome-icon
          icon="eye"
          class=" uk-margin-small-right"
        />{{ ad.views }}
      </span>
      <div
        class="uk-grid-small uk-flex uk-text-left"
        uk-grid
      >
        <div class="uk-width-3-4@m">
          <div class="uk-flex uk-flex-middle uk-flex-space-between">
            <h3
              v-html="title"
              class="uk-margin-remove-bottom uk-text-left uk-width-1-1"
            />
            <span class="uk-width-1-4 uk-text-right">
              <Translate
                v-if="title"
                class="uk-margin-small-right"
                :text="title"
                :translatedText="translatedTitleText"
                :translatedShown="translatedTitleShown"
                @translatedText="translatedTitleText = $event"
                @translatedShown="translatedTitleShown = $event"
              />
            </span>
          </div>
          <div class="uk-text-bottom uk-text-break">
            <a
              :href="ad.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ ad.url }}
            </a>
          </div>
          <div class="uk-margin-top">
            <b>
              {{ $t('boards.price') }}:
            </b>
            {{ ad.price }} {{ ad.currency }}
          </div>
          <div>
            <b>
              {{ $t('boards.address') }}:
            </b>
            {{ ad.address }}
          </div>
        </div>
        <div class="uk-width-1-4@m">
          <ul
            class="uk-list uk-list-divider uk-text-left uk-margin-small-right"
          >
            <li v-if="ad.phone" >
              <span>
                <b>
                  {{ $t('boards.phone') }}:
                </b>
                <router-link
                  v-if="ad.phone"
                  :to="{ name: 'phoneResults', params: { phone: ad.phone }}"
                  class="uk-icon-link uk-margin-small-left uk-icon"
                  uk-icon="search"
                >
                  {{ ad.phone }}
                </router-link>
              </span>
            </li>
            <li v-if="ad.seller" >
              <span>
                <b>
                  {{ $t('boards.seller') }}:
                </b>
                {{ ad.seller }}
              </span>
            </li>
            <li v-if="ad.time">
              <span>
                <b>
                  {{ $t('boards.created') }}:
                </b>
                <span>
                  {{ dayjs(ad.time ? ad.time * 1000 : ad.create) }}
                </span>
              </span>
            </li>
            <li>
              <span class="uk-float-right"/>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="uk-grid uk-margin-top "
        uk-grid
      >
        <div
          v-if="ad.img"
          class="uk-width-1-3"
        >
          <Thumbnail
            :src="ad.img"
            class="uk-inline"
          />
        </div>
        <div
          v-if="ad.description"
          class="uk-width-expand uk-text-justify"
        >
          <p>
            <span v-html="extractedData"/>
          </p>
          <Translate
            v-if="extractedData"
            class="uk-margin-small-right"
            :text="extractedData"
            :translatedText="translatedDetailText"
            :translatedShown="translatedDetailShown"
            @translatedText="translatedDetailText = $event"
            @translatedShown="translatedDetailShown = $event"
          />
        </div>
      </div>
    </div>
    <div/>
  </div>
</template>

<script>
import extractData from '@/utils/dataExtraction'
import { apiRequest } from '@/utils/api'
import config from '@/config'
import textHighlight from '@/utils/textHighlight'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import Translate from '@/components/Translate'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ADSDetail',
  components: {
    AddStoredButton,
    Translate,
    Thumbnail: defineAsyncComponent(() => import('@/components/Thumbnail.vue'))
  },
  props: {
    adId: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      ad: null,
      user: {},
      translatedDetailText: false,
      translatedDetailShown: false,
      translatedTitleText: false,
      translatedTitleShown: false
    }
  },
  watch: {
    adId: {
      async handler (newValue) {
        if (newValue) {
          await this.loadDetailInfo(newValue)
        }
      },
      immediate: true
    }
  },
  computed: {
    extractedData () {
      if (!this.ad.description) return
      if (this.translatedDetailShown) {
        return extractData(this.translatedDetailText)
      }
      var message = this.ad.description
      try {
        message = textHighlight(message, this.$route.query.search)
      } catch {}
      return extractData(message)
    },
    title () {
      return this.translatedTitleShown ? this.translatedTitleText : this.ad.title.replace(/<[^>]*>?/gm, '').trim()
    }
  },
  methods: {
    async loadDetailInfo (id) {
      try {
        const response = await apiRequest(`/ads/${id}`, 'GET', {}, config.servers.ads)
        if (response.data) {
          this.ad = response.data
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
