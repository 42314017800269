import config from '@/config'
import { apiRequest } from '@/utils/api'

class EmojiBroker {
  constructor () {
    this.LIMIT_PER_REQUEST = 200
  }

  async run (container) {
    const emojisElements = this.getEmojiElements(container)

    if (!emojisElements.length) {
      return
    }

    const emojisIds = new Set()

    emojisElements.forEach((element) => {
      emojisIds.add(element.dataset.emojiId)
    })

    const documents = await this.getDocuments([...emojisIds])

    if (!documents.length) {
      return
    }

    const documentsById = documents.reduce((acc, doc) => {
      acc[doc.tg_id_str] = doc

      return acc
    }, {})

    emojisElements.forEach((emojiElement) => {
      const doc = documentsById[emojiElement.dataset.emojiId]

      const customEmoji = this.createCustomEmoji(doc)

      if (!customEmoji) {
        return
      }

      emojiElement.replaceWith(customEmoji)
    })
  }

  async getDocuments (documentsIds) {
    try {
      const response = await apiRequest(
        '/documents/many',
        'POST',
        {},
        undefined,
        {
          document_ids: documentsIds
        }
      )

      return response.data.documents
    } catch (error) {
      return []
    }
  }

  getEmojiElements (container) {
    const emojisElementsAll = container.querySelectorAll('[data-emoji-id]')

    const emojisElements = []

    for (let i = 0; i < emojisElementsAll.length; i++) {
      if (emojisElements.length >= this.LIMIT_PER_REQUEST) {
        break
      }

      const element = emojisElementsAll[i]

      if (element.emojiBrokerSkip) {
        continue
      }
      element.emojiBrokerSkip = true

      emojisElements.push(element)
    }

    return emojisElements
  }

  createCustomEmoji (doc) {
    const source = `${config.servers.tgcp_static}${doc.file_location}`

    let element = null

    if (doc.mime_type === 'application/x-tgsticker') {
      element = document.createElement('tgs-player')
      element.className = 'custom-emoji'
      element.setAttribute('src', source)
      element.setAttribute('loop', '')
      element.setAttribute('mode', 'normal')
      element.setAttribute('autoplay', '')
    } else if (doc.mime_type.startsWith('image')) {
      element = document.createElement('img')
      element.className = 'custom-emoji'
      element.setAttribute('src', source)
    } else if (doc.mime_type.startsWith('video')) {
      element = document.createElement('video')
      element.className = 'custom-emoji'
      const filename = source.split('/')
      element.setAttribute('src', source + (filename[filename.length - 1].includes('.') ? '' : '.webm'))
      element.setAttribute('loop', '')
      element.setAttribute('muted', '')
      element.setAttribute('autoplay', '')
    } else {
      console.warn('Unhandled emoji mime_type:', doc)
    }

    return element
  }
}

const emojiBroker = new EmojiBroker()

export default emojiBroker
