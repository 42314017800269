<template>
  <Entities
    :userId="id"
    moduleName="tgcp"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'TgcpUserDetailsEntities',
  components: {
    Entities: defineAsyncComponent(() => import('@/components/general/Entities'))
  },
  props: {
    id: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
