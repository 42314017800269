import L from 'leaflet'
import config from '@/config'
import { apiRequest } from '@/utils/api'

export default {
  methods: {
    handleQuantity (quantity) {
      if (quantity < 100) {
        return `&nbsp;${quantity}`
      }

      if (quantity >= 1000) {
        return `${Math.floor(quantity / 1000)}k`
      }

      return quantity
    },
    async handleMarkerClick (event) {
      const popup = event.target.getPopup()

      const { id } = event.sourceTarget.options.info

      try {
        const response = await apiRequest(`/chats/${id}`, 'GET')

        const chat = response.data

        let popupContent = ''

        if (chat.avatar) {
          const avatarUrl = config.servers.tgcp_static + chat.avatar.file_location

          popupContent += `<img style="width: 100px" data-src="${avatarUrl}" class="uk-border-circle" src="${avatarUrl}"><br/>`
        }

        if (chat.username) {
          popupContent += `<b>Username</b>: <a href="/nickname/${chat.username}/" target="_blank">${chat.username}</a>`
        }

        if (chat.name) {
          popupContent += `<br/><b>Name</b>: ${chat.name}`
        }

        if (chat.description) {
          popupContent += `<br/><b>Description</b>: ${chat.description}`
        }

        popupContent += `<br/><a href="/tgcp/channel/${id}">Learn More</a>`

        popup.setContent(popupContent)
      } catch (error) {
        this.$notify({
          group: 'general',
          title: this.$t('notifications.error'),
          text: error.message,
          type: 'error'
        })
      }
    },
    getTGCPChats (bounds) {
      if (this.mapInstance) {
        this.mapInstance.fireEvent('dataloading')
      }

      apiRequest(`/cell/getTgcpInArea?bbox=${this.getBBOX(bounds)}`, 'GET', {}, config.servers.asiris_api).then(res => {
        let markers = []

        for (let chat of res.data.data) {
          if (window.addedChats.indexOf(chat.id) < 0) {
            window.addedChats.push(chat.id)
            let marker = L.marker(L.latLng(chat.lat, chat.lon), { info: chat })
            marker.bindPopup('Loading...')
            marker.on('click', this.handleMarkerClick)
            marker.setIcon(L.divIcon({
              html: '<div class="chat-icon"><img src="https://img.icons8.com/color/48/000000/filled-chat.png"><div class="">' + this.handleQuantity(chat.users_count) + '</div></div>',
              className: 'custom-icon',
              iconSize: L.point([30, 30]),
              popupAnchor: [10, -5]
            }))
            markers.push(marker)
          }
        }
        window.tgcpChats.addLayers(markers, { chunkedLoading: true })

        if (this.mapInstance) {
          this.mapInstance.fireEvent('dataload')
        }
      }).catch(error => {
        console.debug(error)
      })
    }
  },
  created () {
    window.addedChats = []
    window.tgcpChats = new L.MarkerClusterGroup({
      animate: false,
      iconCreateFunction: function (cluster) {
        let markers = cluster.getAllChildMarkers()
        let count = cluster.getChildCount()
        let n = 0
        for (let i = 0; i < count; i++) {
          n += markers[i].options.info.users_count
        }

        let c = ' tgcp-marker-cluster-'
        if (n < 500) c += 'small'
        else if (n < 5000) c += 'medium'
        else c += 'large'

        if (n >= 10000) {
          n = Math.floor(n / 1000) + 'k'
        }

        return L.divIcon({
          html: '<div><span>' + n + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        })
      }
    })
  },
  beforeUnmount () {
    if (window.tgcpChats) {
      window.tgcpChats.clearLayers()
    }
  }
}
