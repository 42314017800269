<template>
  <img
    v-if="src"
    :src="src"
    alt="img"
    class="uk-width-small uk-margin uk-margin-left uk-margin-right"
    @click="select(entry)"
  />
</template>
<script>
export default {
  name: 'SearchingFacesTab',
  props: {
    entry: {
      type: String
    }
  },
  computed: {
    src () {
      if (this.entry) {
        return 'data:image/jpeg;base64,'.concat(this.entry)
      }
      return ''
    }
  },
  methods: {
    select (entry) {
      try {
        this.$emit('select', entry)
      } catch (error) {
        console.debug(error)
      }
    }
  }
}
</script>
