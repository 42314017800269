<template>
  <div>
    <div class="uk-grid uk-grid-small">
      <div class="uk-width-3-4@m">
        <Loader v-if="isLoadingUser" />
        <UserNotFound
          v-else-if="userNotFound"
          :id="id"
        />
        <GeneralInformation
          v-else
          :user="user"
        />
      </div>
      <div class="uk-width-1-4@m">
        <Loader
          v-if="isLoadingStats"
          :ratio="2"
        />
        <UserStats
          v-else
          :id="id"
        />
      </div>
    </div>
    <div
      v-if="!isLoadingUser && !userNotFound"
      class="uk-width-1-1"
    >
      <ul class="uk-tab">
        <li
          v-for="route in $options.routes"
          :key="route.name"
          class="uk-padding-remove"
          :class="{
            'uk-active': currentRouteName === route.name
          }"
        >
          <router-link :to="{ name: route.name, query: { info: $route.query.info ? $route.query.info : 'current_tab'} }">
            {{ $t(route.contentKey) }}
            <template v-if="route.name === 'tgcp_user_detail_channels'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.chats_count }}
                </template>
              </span>
            </template>
            <template
              v-else-if="route.name === 'tgcp_user_detail_past_channels'"
            >
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.former_chats_count }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_admin'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.admin_count }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_past_admin'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.former_admin_count }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_owner'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.owner_count }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_messages'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.messages_count }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_media_messages'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingStats"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ stats.media_messages_count }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_replies'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingReplies"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else>
                  {{ repliesCount }}
                </template>
              </span>
            </template>
            <template v-else-if="route.name === 'tgcp_user_detail_entities'">
              <span class="uk-badge">
                <Loader
                  v-if="isLoadingEntities"
                  color="#fff"
                  :ratio="0.5"
                />
                <template v-else-if="!entitiesCount">
                  0
                </template>
                <template v-else>
                  {{ entitiesCount }}
                </template>
              </span>
            </template>
          </router-link>
        </li>
      </ul>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import UserStats from '@/components/TGCP/UserDetails/UserStats'
import UserNotFound from '@/components/TGCP/UserDetails/UserNotFound'
import GeneralInformation from '@/components/TGCP/UserDetails/GeneralInformation'

export default {
  name: 'TgcpUserDetailsNew',
  components: {
    Loader,
    UserStats,
    UserNotFound,
    GeneralInformation
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  routes: [
    {
      name: 'tgcp_user_detail_overview',
      contentKey: 'tgcp.channel_details.overview_title'
    },
    {
      name: 'tgcp_user_detail_accounts',
      contentKey: 'tgcp.user_details.accounts'
    },
    {
      name: 'tgcp_user_detail_channels',
      contentKey: 'tgcp.user_details.current-channels'
    },
    {
      name: 'tgcp_user_detail_past_channels',
      contentKey: 'tgcp.channels.past-channels'
    },
    {
      name: 'tgcp_user_detail_admin',
      contentKey: 'tgcp.user_details.admin-channels'
    },
    {
      name: 'tgcp_user_detail_past_admin',
      contentKey: 'tgcp.user_details.past-admin-channels'
    },
    {
      name: 'tgcp_user_detail_owner',
      contentKey: 'tgcp.user_details.owner'
    },
    {
      name: 'tgcp_user_detail_messages',
      contentKey: 'tgcp.channel_details.messages_title'
    },
    {
      name: 'tgcp_user_detail_media_messages',
      contentKey: 'tgcp.channel_details.messages_with_media_title'
    },
    {
      name: 'tgcp_user_detail_replies',
      contentKey: 'tgcp.user_details.replies_title'
    },
    {
      name: 'tgcp_user_detail_entities',
      contentKey: 'tgcp.channel_details.entities'
    }
  ],
  data () {
    return {
      isLoadingUser: true,
      isLoadingStats: true,
      isLoadingReplies: true,
      isLoadingEntities: true
    }
  },
  watch: {
    $route (to, from) {
      if (from.params.id === to.params.id) {
        return
      }

      this.getInfoAboutUser()
    }
  },
  computed: {
    user () {
      return this.$store.state.tgcp.users[this.id]
    },
    userNotFound () {
      return this.user.answer_status === 'NOT_FOUND'
    },
    stats () {
      return this.user.stats
    },
    repliesCount () {
      const { replies } = this.user

      if (!replies) {
        return 0
      }

      return replies.length
    },
    entitiesCount () {
      const entityData = this.user.entity_data

      if (!entityData) {
        return null
      }

      return entityData.total.count
    },
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchUserById',
      'fetchUserStatsById',
      'fetchUserRepliesById',
      'fetchUserEntitiesCount'
    ]),
    async getInfoAboutUser () {
      this.isLoadingUser = true
      this.isLoadingStats = true
      this.isLoadingReplies = true
      this.isLoadingEntities = true

      await this.fetchUserById({
        id: this.id,
        requestStats: false
      })
      this.isLoadingUser = false

      await this.fetchUserStatsById(this.id)
      this.isLoadingStats = false

      await this.fetchUserRepliesById(this.id)
      this.isLoadingReplies = false

      await this.fetchUserEntitiesCount(this.id)
      this.isLoadingEntities = false
    }
  },
  async created () {
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.info) updateQuery = { ...updateQuery, info : 'current_tab' }
    else if (updateQuery.info !== 'current_tab') updateQuery.info = 'current_tab'
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    await this.getInfoAboutUser()
  }
}
</script>

<style scoped>
.uk-tab {
  margin-left: 0;
}

.uk-tab a,
.uk-tab .uk-badge {
  font-size: 0.75rem;
}
</style>
