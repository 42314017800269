import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class ForumsUsersAll extends Base {
  schema = Schemas.forums.user
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { query, users } = options
    if (!!users && users.length) {
      return users
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let entries = []
      while (localQuery.offset < this.total) {
        const { data } = await dispatch('fetchAllUsers', localQuery)
        const entry = data
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default ForumsUsersAll
