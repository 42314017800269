<template>
  <div>
    <table 
      v-show="!hidden" 
      class="uk-table uk-table-striped uk-margin-remove-vertical"
    >
      <colgroup>
        <col width="13%" />
        <col width="13%" />
        <col width="25%" />
        <col width="13%" />
        <col width="13%" />
        <col width="13%" />
        <col width="10%" />
      </colgroup>
      <thead>
        <tr>
          <th style="width:13%; min-width: 20px">
            {{ $t('crypto.general.tx_names') }}
          </th>
          <th style="width:13%;">
            {{ $t('crypto.ton.type') }}
          </th>
          <th 
            class="uk-table-expand" 
            style="width:25%;"
          >
            {{ $t('crypto.ton.operation_name') }}
          </th>
          <th style="width:13%;">
            {{ $t('crypto.general.sender') }}
          </th>
          <th style="width:13%;">
            {{ $t('crypto.general.receiver') }}
          </th>
          <th 
            class="uk-text-right" 
            style="width:13%;"
          >
            {{ $t('crypto.general.amount') }}
          </th>
          <th 
            class="uk-text-right uk-text-nowrap" 
            style="width:10%;"
          >
            {{ $t('crypto.general.created') }}
          </th>
        </tr>
      </thead>
      <template v-if="emptyTable">
        <div class="uk-flex uk-margin-top">
          {{ $t('crypto.warning.nothing_found') }}
        </div>
      </template>
      <template v-else-if="!isLoaded && !hidden">
        <div class="uk-flex uk-flex-center uk-margin-top">
          <Loader />
        </div>
      </template>
      <template v-else>
        <tbody>
          <TableLine
            v-for="msg in keys.slice((pagination.page-1)*itemCount, pagination.page*itemCount)"
            :key="msg"
            :msg="getTonMessages[msg]" 
            :show-link="showLink"
          />
        </tbody>
      </template>
    </table>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import TableLine from '@/components/Crypto/Ton/Messages/TableLine'
import { mapGetters } from 'vuex'

export default {
  name: 'TonTransactionsTable',
  components: {
    Loader,
    TableLine
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    update: {
      type: Boolean,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    },
    hidden: {
      type: Boolean,
      required: true
    },
    showLink: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      itemCount: 10,
      emptyTable: false,
      pagination: {
        page: 1,
        total: 0
      },
      maxExploredPage: 0
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonMessages', 'getQueryTotal']),
    isLoaded () {
      if (!this.update) return true
      return this.$store.state.crypto.isLoaded['ton_messages'] ?? false
    },
    hidePagination () {
      return !this.isLoaded || (this.pagination.total ? this.pagination.page : this.keys.length )< this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage + 1 : lastPage + 2
    }
  },
  methods: {
    setPage (page) {
      this.pagination.page = page
    }
  }
}
</script>

<style lang="scss">
.error {
    border-color: red !important;
}
</style>