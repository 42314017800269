<template>
  <div>
    <div class="uk-margin-top-small">
      <div class="uk-card uk-card-default uk-card-body uk-margin-top">
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          :container-class="'uk-pagination uk-flex-center uk-margin-small-top'"
        />
        <table class="uk-table   uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr>
              <th class="uk-text-center uk-width-2-3">
                {{ $t('cases.case_title') }}
              </th>
              <th class="uk-text-center" >
                {{ $t('cases.case_description') }}
              </th>
              <th class="uk-text-center uk-width-auto">
                {{ $t('tgcp.tasks.actions_th') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="caseInfo in cases"
              :key="caseInfo.case_id"
            >
              <td class="uk-text-left " >
                 &nbsp;<router-link
                    :title="caseInfo.case_title"
                    class="uk-text-ellipsis"
                    :to="{name: 'case_archived_details',
                    params: { caseId: caseInfo.case_id } } "
                       >
                        {{ caseInfo.case_title }}
                        </router-link>
              </td>
              <td class="uk-text-break uk-text-left">
                {{ caseInfo.case_description }}
              </td>
              <td>
                <div class="uk-button-group">
                  <button
                    class="uk-button uk-button-text uk-margin-left"
                    type="button"
                    style="height:40px;width:40px;"
                    :title="$t('cases.unarchive')"
                    @click="archiveCase(caseInfo)"
                  >
                    <span uk-icon="push"/>
                  </button>

                  <button
                    class="uk-button uk-button-text uk-margin-left"
                    type="button"
                    style="height:40px;width:40px;"
                    :title="$t('cases.remove_case')"
                    @click="confirmDelete(caseInfo.case_id)"
                  >
                    <span uk-icon="close"/>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          :container-class="'uk-pagination uk-flex-center uk-margin-small-top'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UIkit from 'uikit'
import Paginate from '@/components/Paginate'

export default {
  name: 'Cases',
  components: {
    Paginate
  },
  data () {
    return {
      cases: [],
      query: {
        limit: 20,
        offset: 0,
        search: ''
      },
      pagination: {
        page: 1,
        total: null
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters('cases', [
      'archivedCases'
    ]),
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    lastPage () {
      const lastPage = Math.floor((this.pagination.total) / (this.query.limit))
      return this.pagination.total / lastPage - (this.query.limit) === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('cases', [
      'deleteCase',
      'archiveCase',
      'fetchArchivedCases'
    ]),
    clearData (err) {
      this.cases = []
      this.pagination.page = 1
      this.pagination.total = null
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err.message, type: 'error' })
    },
    setData (res) {
      this.cases = res.rows
      this.pagination.total = res.count
    },
    setPage (page = 1) {
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = page
      this.update()
    },
    confirmDelete (id) {
      UIkit.modal.confirm(this.$t('cases.delete_case_confirm'))
        .then(() => {
          this.deleteCase(id)
        })
        .catch((err) => {
          if (err) this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        })
    },
    async update () {
      try {
        this.loading = true
        this.setData(await this.fetchArchivedCases(this.query))
      } catch (err) {
        this.clearData(err)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.update()
  }
}
</script>

<style scoped>
.uk-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
}
</style>
