<template>
  <div class="home uk-container uk-margin-top">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
        >
          <div class="uk-display-inline-block">
            <input
              v-model.trim="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              @keyup.enter="searchHandler"
            />
            <vueSelect
              v-model="searchUnit"
              :options="translatedSearchUnits"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="searchHandler"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="my-fields-box">
            <div class="my-fields-flex">
              <div class="my-fields">
                <label
                  v-for="field in inFields"
                  :key="field"
                  class="my-field"
                >
                  {{ $t('forums.users.sort.' + field) }}
                  <input
                    v-model="currentSearchFields"
                    :value="field"
                    class="uk-checkbox"
                    type="checkbox"
                  />
                </label>
              </div>
              <div class="my-search-hint">
                <SearchInDbHint />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div v-if="!loaded">
        <loader/>
      </div>
      <NotFound v-if="loaded && users.length === 0" />
      <div
        v-if="loaded"
        class="uk-grid uk-margin-top"
        data-uk-grid-margin
      >
        <div
          class="uk-grid-small
                  uk-width-1-1
                  uk-child-width-1-1@s uk-flex-left
                  uk-grid filter_area"
        >
          <div
            v-for="user in users"
            :key="user.id"
            class="uk-grid-margin"
          >
            <Item
              :key="user.id"
              :user="user"
            />
          </div>
        </div>
      </div>
      <div v-if="!hidePagination && loaded">
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="changePageHandler"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="forumId || topicId"
      :search="query.search"
      namespace="forums"
      type="users"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Paginate from '@/components/Paginate'
import Loader from '@/components/TGCP/Loader'
import Item from '@/components/Forums/UserList/Item.vue'
import vueSelect from '@/components/VueSelect'
import forumsSearch from '@/mixins/forumsSearch'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ForumsUserList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item,
    loader: Loader,
    Paginate,
    vueSelect
  },
  mixins: [forumsSearch],
  props: {
    forumId: {
      type: [Number, String],
      required: false
    },
    topicId: {
      type: [Number, String],
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'members'].includes(val)
    }
  },
  data () {
    return {
      selectedMessages: [],
      users: [],
      forums: [],
      orderList: ['id', 'login', 'display_name', 'email', 'signature', 'bio', 'forum_id', 'forum_user_id'],
      query: {
        limit: 40,
        offset: 0,
        search: (!this.forumId && !this.topicId && this.$route.query.search) || '',
        order_by: (!this.forumId && !this.topicId && this.$route.query.order_by) || 'id',
        order_type: (!this.forumId && !this.topicId && this.$route.query.order_type) || 'desc',
        forum_id: null
      },
      pagination: {
        page: 1,
        total: null
      },
      paramsToRoute: ['search', 'order_by', 'order_type', 'page'], // 'forum_id'
      currentSearchUnit: 'all',
      inFields: [],
      currentSearchFields: []
    }
  },
  /* async created () {
    const { data } = await this.fetchAllForums()
    this.forums = data
  }, */
  watch: {
    currentSearchUnit (unit) {
      try {
        switch (unit) {
            case 'in_fields':
              this.inFields = [
                'display_name',
                'signature',
                'bio'
              ]
              break
            case 'by_id':
              this.inFields = []
              break
            case 'all':
              this.inFields = []
              break
        }
      } catch (err) {
        console.debug(err)
      }
    }
  },
  computed: {
    ...mapGetters('forums', ['isLoaded']),
    hasExport () {
      return (this.type !== 'all' || this.query.search) && this.users.length
    },
    searchUnit: {
      get () {
        return this.currentSearchUnit
      },
      set (unit) {
        this.currentSearchUnit = unit && unit.value
      }
    },
    searchUnits () {
      return [
        'all',
        'in_fields',
        'by_id'
      ]
    },
    translatedSearchUnits () {
      return this.searchUnits.map(unit => ({
        label: this.$t('forums.users.sort.' + unit),
        value: unit
      }))
    },
    isDetails () {
      return this.$route.name === 'forums_forum_details' || this.$route.name === 'forums_topic_details'
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.updateQuery()
        this.update()
      }
    },
    filteredOrderList () {
      if (this.isDetails) {
        return this.orderList.filter(order => order !== 'forum_id')
      }
      return this.orderList
    },
    orderListTranslated () {
      return this.filteredOrderList.map(word => ({ label: this.$t('forums.users.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    hidePagination () {
      if (!this.lastPage || Number(this.lastPage) === 1) return true
      return false
    },
    loaded () {
      if (this.topicId) return this.isLoaded['users_topic_' + this.topicId]
      if (this.forumId) return this.isLoaded['users_forum_' + this.forumId]
      return this.isLoaded['users']
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    hasSearchUnit () {
      return [
        'forums_forum_details',
        'forums_topic_details'
      ].includes(this.$route.name)
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchAllUsers',
      'fetchForumUsers',
      'fetchTopicUsers',
      'addParamToQuery'
      // 'fetchAllForums'
    ]),
    async update () {
      const { query, forumId, topicId, setPagination, setError } = this
      let currentQuery = query
      try {
        switch (this.currentSearchUnit) {
            case 'in_fields':
              {
                const {
                  forum_user_id,
                  ...current
                } = query
                currentQuery = {
                  ...current,
                  search_fields: this.currentSearchFields
                }
              }
              break
            case 'by_id':
              {
                const {
                  search,
                  search_fields,
                  ...current
                } = query
                currentQuery = {
                  ...current,
                  forum_user_id: Number(this.query.search)
                }
              }
              break
            case 'all':
              {
                const {
                  search_fields,
                  forum_user_id,
                  ...current
                } = query
                currentQuery = current
              }
              break
        }
      } catch (error) {
        console.debug(error)
      }
      try {
        const res = topicId ? await this.fetchTopicUsers({ forumId, topicId, query: currentQuery }) : forumId ? await this.fetchForumUsers({ forumId, query: currentQuery }) : await this.fetchAllUsers(currentQuery)
        setPagination(res)
      } catch (err) {
        setError(err)
      }
    },
    setPagination (res) {
      this.users = res.data
      this.pagination.total = res.meta.results_count
    },
    setError () {
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('notifications.something-went-wrong') || this.$t('notifications.error-getting-users'), type: 'error' })
      this.users = []
      this.pagination.page = 1
      this.pagination.total = null
    }
  }
}
</script>
<style scoped>
  .my-fields-flex {
    display:  flex;
    flex-direction: row;
    justify-content: center
  }
  .my-fields {
    margin-top: 10px
  }
  .my-field {
    margin-right:  10px
  }
  .my-fields-box {
    margin-top: 0
  }
  .my-search-hint {
    padding-left: 10px
  }
</style>
