import { apiCall, apiAuth } from '@/utils/api'
import router from '@/router'
import jwtDecode from 'jwt-decode'

export default {
  state: {
    in_process: false,
    errors: {
      deviceTime: false
    }
  },
  getters: {
    deviceTimeError: state => state.errors.deviceTime
  },
  mutations: {
    startAuthProcess (state) {
      state.authInProcess = true
    },
    stopAuthProcess (state) {
      state.authInProcess = false
    },
    addError (state, error) {
      state.errors[error] = true
    },
    setDeviceTimeError (state, error) {
      state.errors.deviceTime = error
    }
  },
  actions: {
    async login ({ commit, dispatch, rootState }, user) {
      commit('startAuthProcess')
      try {
        const { data } = await apiCall({
          url: '/auth/login',
          email: user.email,
          password: user.password,
          otp: user.otp,
          method: 'POST'
        })
        const token = data.access_token
        const refreshToken = data.refresh_token
        if (!token || !refreshToken) throw new Error('no token')
        const decoded = jwtDecode(token)
        commit('setDeviceTimeError', Math.abs(decoded.iat * 1000 - Date.now()) > 90000)
        commit('setToken', token)
        commit('setUserId', decoded.identity)
        commit('setUserName', user.email)
        commit('setRefreshToken', refreshToken)
        commit('setBalance', 0)
        commit('setOrganisationId', decoded.organisation_id)
        commit('setRole', decoded.role)
        commit('setForceChangePassword', decoded.password_expired)
          try {
            let { defaultCase } = await dispatch('setSettings')
            const id = localStorage.getItem('current-case') || defaultCase
            if (id) {
              await dispatch('cases/getCaseById', id)
              commit('cases/setCurrentCase', id)
            }
          } catch {
            localStorage.setItem('current-case', null)
            commit('cases/setCurrentCase', null)
          }
      } catch (err) {
        console.error(err)
        commit('setUserName', '')
        commit('setPassword', '')
        commit('setToken', '')
        commit('setRefreshToken', '')
        commit('setOrganisationId', '')
        commit('setBalance', 0)
        commit('setUserId', 0)
        commit('cases/setCurrentCase', '')
        commit('clearCasesAndProfiles')
        commit('clearTasks')
        commit('setForceChangePassword', false)
        throw err
      } finally {
        commit('stopAuthProcess')
      }
    },
    async refresh ({ state, commit, dispatch, rootState }) {
      try {
        let { data } = await apiAuth(`/auth/refresh`, 'GET')
        let token = data.access_token
        if (!token) {
          return await dispatch('logout')
        }
        commit('setToken', token)
        const decoded = jwtDecode(token)
        commit('setUserId', decoded.identity)
        commit('setForceChangePassword', decoded.password_expired)
        return data
      } catch (err) {
        console.debug(err)
        dispatch('logout')
        throw err
      }
    },
    async logout ({ commit, state, rootState }, redirect = router.currentRoute.value.fullPath) {
      if (rootState.auth_token) {
        try {
          await apiCall({
            url: `/auth/logout`,
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + rootState.auth_token
            }
          })
        } catch (err) {
          console.debug(err)
        }
      }
      commit('setUserName', '')
      commit('setPassword', '')
      commit('setToken', '')
      commit('setRefreshToken', '')
      commit('setBalance', 0)
      commit('setUserId', 0)
      commit('setOrganisationId', '')
      commit('clearCasesAndProfiles')
      commit('clearTasks')
      commit('setForceChangePassword', false)
      if (redirect !== '/login' && redirect !== '/logout') {
        sessionStorage.removeItem('redirect')
        sessionStorage.setItem('redirect', redirect)
      }
      if (router.currentRoute.value.name ?? 0 !== 'login') router.push({ name: 'login' })
    }
  }
}
