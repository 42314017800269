<template>
  <div>
    <div class="uk-card uk-card-default uk-card-body">
      <MassEdit
        class="z-index: 1"
        :checkedTasks="checkedTasks"
        :current-page="pagination.page"
        @update="refresh"
        @clearSelectedTasks="clearSelected"
        @delete="afterDelete"
      />
      <h3>
        {{ $t('whatsapp.tasks.tasks_title') }}
      </h3>
      <div class="tasks-table">
        <form
          class="uk-form-horizontal"
          @submit.prevent="searchHandler"
        >
          <input
            v-model="query.search"
            type="text"
            class="uk-input uk-form-width-small uk-form-width-medium"
            :placeholder="$t('twitter.tasks.search')"
          />
          <button
            class="uk-button uk-button-default uk-search-icon"
            @click="searchHandler"
          >
            <span uk-search-icon/>
          </button>
        </form>
        <Paginate
          v-if="!hidePagination"
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPageHandler"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
        <table class="uk-table uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr>
              <th
                class="uk-text-center uk-text-nowrap uk-width-1-10"
                style="padding-left:0.5rem;padding-right:0rem; width:1.5rem"
              >
                <input
                  v-model="isAllChecked"
                  class="uk-checkbox"
                  type="checkbox"
                />
              </th>
              <th class="uk-text-center uk-width-1-10">
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('joinlink')"
                >
                  {{ $t('whatsapp.tasks.task_th') }}
                  {{ query.order_by === 'joinlink' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center uk-text-nowrap uk-table-expand">
                {{ $t('whatsapp.tasks.channels_th') }}
              </th>
              <th class="uk-text-center uk-text-nowrap uk-width-1-10">
                {{ $t('whatsapp.tasks.members_th') }}
              </th>
              <th class="uk-text-center uk-text-nowrap uk-width-1-10">
                {{ $t('whatsapp.tasks.description_th') }}
              </th>
              <th class="uk-text-center uk-text-nowrap uk-width-1-10">
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('finished')"
                >
                  {{ $t('whatsapp.tasks.last_sync_th') }}
                  {{ query.order_by === 'finished' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center uk-width-1-10">
                <button
                  class="uk-button uk-button-text uk-text-nowrap"
                  @click="setOrder('created')"
                >
                  {{ $t('whatsapp.tasks.created_th') }}
                  {{ query.order_by === 'created' ? selectedOrder : '' }}
                </button>
              </th>
              <th class="uk-text-center uk-text-nowrap uk-width-1-10">
                {{ $t('whatsapp.tasks.status_th') }}
              </th>
              <th class="uk-text-center uk-text-nowrap uk-width-1-10">
                {{ $t('whatsapp.tasks.options_th') }}
              </th>
              <th class="uk-text-center uk-text-nowrap uk-width-1-10">
                {{ $t('whatsapp.tasks.actions_th') }}
              </th>
            </tr>
          </thead>

          <tbody v-if="!isLoaded">
            <tr>
              <td
                colspan="10"
                class="uk-text-center uk-width-1-1"
              >
                <Loader />
              </td>
            </tr>
          </tbody>

          <tbody v-if="isLoaded && Object.entries(allTasks).length !== 0">
            <WhatsAppTaskListItem
              v-for="task in tasksOnPage"
              :key="task"
              :task="allTasks[task]"
              :checked="checkedTasks.includes(task)"
              :checkedTasks="checkedTasks"
              :stored="stored.find(el => String(el.ext_id) === String(task))"
              @update="onDelete"
              @check-changed="checkChanged($event, task)"
              @delete="afterDelete"
            />
          </tbody>

          <tbody v-if="isLoaded && !hasDataToView">
            <tr>
              <td
                colspan="10"
                class="uk-text-center uk-width-1-1"
              >
                {{ $t('not_found.no_tasks') }}
              </td>
            </tr>
          </tbody>
        </table>

        <Paginate
          v-if="!hidePagination"
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPageHandler"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <div class="uk-grid">
      <div class=" uk-width-1-2@m uk-margin-top uk-padding-left@m">
        <AddTask @update="searchHandler" />
      </div>
      <div class="uk-width-1-2@m uk-margin-top uk-padding-left">
        <ImportExport @update="searchHandler" />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from '@/components/Paginate'
import Loader from '@/components/WhatsApp/Loader'
import WhatsAppTaskListItem from '@/components/WhatsApp/Tasks/ListItem'
import AddTask from '@/components/WhatsApp/Tasks/AddTask'
import ImportExport from '@/components/WhatsApp/Tasks/ImportExport'
import MassEdit from '@/components/WhatsApp/Tasks/MassEdit'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'WhatsAppTasks',
  components: {
    Paginate,
    Loader,
    AddTask,
    WhatsAppTaskListItem,
    ImportExport,
    MassEdit
  },
  data () {
    return {
      timer: false,
      stored: [],
      filters: ['username', 'joinchat', 'invite', 'geo'],
      query: {
        search: this.$route.query.search || '',
        limit: 20,
        offset: this.$route.query.offset || 0,
        task_type: this.$route.query.task_type || null,
        order_by: this.$route.query.order_by || 'created',
        order_type: this.$route.query.order_type || 'desc'
      },
      pagination: {
        page: 1,
        total: null
      },
      paramsToRoute: ['search', 'offset', 'task_type', 'order_by', 'order_type'],
      checkedTasks: [],
      lastTaskChecked: null
    }
  },
  computed: {
    ...mapState('whatsapp', ['queryParams']),
    ...mapGetters('whatsapp', ['allListTasks', 'allTasks']),
    isAllChecked: {
      get () {
        if (!this.allListTasks || !this.tasksOnPage || !this.checkedTasks.length) return
        return this.tasksOnPage.every(task => this.checkedTasks.includes(task))
      },
      set () {
        if (this.isAllChecked) {
          this.checkedTasks = this.checkedTasks.filter(task => !this.tasksOnPage.includes(task))
        } else {
          this.checkedTasks = this.checkedTasks.concat(this.tasksOnPage)
        }
      }
    },
    page () {
      return Math.floor((this.$route.query.offset / this.query.limit) + 1)
    },
    hidePagination () {
      return !this.isLoaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    taskById (id) {
      return this.$store.state.whatsapp.tasks[id]
    },
    tasks (id) {
      return this.allTasks[id]
    },
    tasksOnPage () {
      const { page } = this.pagination
      if (!this.allListTasks[page]) return false
      if (this.allListTasks[page].length === 0) return false
      return this.allListTasks[page]
    },
    isLoaded () {
      return this.$store.state.whatsapp.isLoaded['tasks']
    },
    hasDataToView () {
      return this.tasksOnPage
    },
    selectedOrder () {
      return this.query.order_type === 'asc' ? '↑' : '↓'
    }
  },
  methods: {
    ...mapMutations('whatsapp', ['clearTasksView']),
    ...mapActions('whatsapp', ['addParamToQuery', 'fetchAllTasks', 'refetchAllTasks']),
    ...mapActions('cases', ['fetchAllStoredObjects']),
    async afterDelete () {
      if (
        typeof this?.$route?.query?.offset === 'number' &&
        typeof this?.query?.limit === 'number' &&
        typeof this?.$router?.replace === 'function' &&
        typeof this?.tasksOnPage === 'number' &&
        this?.tasksOnPage?.length === 0
      ) {
        await this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            offset: this.$route.query.offset - this.query.limit
          }
        })
      } else {
        return
      }

      if (typeof this?.atCreating === 'function') {
        this.atCreating()
      }
    },
    refresh (needCleanCheckedTasks) {
      if (needCleanCheckedTasks) this.checkedTasks = []
      return this.refetchAllTasks({ ...this.query }).then(res => {
        this.setPagination(res)
        clearTimeout(this.timer)
        this.timer = setTimeout(this.refresh, 60000)
      })
    },
    clearSelected () {
      this.checkedTasks = []
    },
    onDelete () {
      try {
        if (
          Array.isArray(this.tasksOnPage) &&
          this.tasksOnPage.length === 0
        ) {
          this.searchHandler()
        } else {
          this.searchHandler({ page: this.page })
        }
      } catch (error) {
        console.debug(error)
      }
    },
    checkChanged (value, task) {
      if (value) {
        this.lastTaskChecked = task
        return this.checkedTasks.push(task)
      }
      this.checkedTasks = this.checkedTasks.filter(taskId => taskId !== task)
    },
    setOrder (by) {
      if (this.query.order_by === by) {
        this.query.order_type = this.query.order_type === 'desc' ? 'asc' : 'desc'
      } else {
        this.query.order_type = 'desc'
      }
      this.query.order_by = by
      this.searchHandler()
    },
    setPageHandler (page) {
      this.searchHandler({ page })
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
    },
    setPagination (res) {
      this.pagination.total = res.meta.results_count
    },
    searchHandler ({ page = 1 } = {}) {
      if (!page) page = 1
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = +page
      const fields = { offset: this.query.offset, ...this.query }
      const params = Object.fromEntries(Object.entries(fields).filter(([prop]) => this.paramsToRoute.includes(prop)))
      this.addParamToQuery(params)
      this.update()
    },
    update () {
      this.fetchAllStoredObjects({ filter: ['task'] }).then(stored => (this.stored = stored))
      this.clearTasksView()
      return this.fetchAllTasks().then(res => {
        this.setPagination(res)
        clearTimeout(this.timer)
        if (!this.hasDataToView && this.$route.query.offset > 0) {
          this.setPage(1)
        }
        this.timer = setTimeout(this.refresh, 60000)
      })
    },
    atCreating () {
      const page = Math.floor((this.$route.query.offset / this.query.limit) + 1)
      this.setPageHandler(page)
    }
  },
  created () {
    try {
      this.atCreating()
    } catch (error) {
      console.debug(error)
    }
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.tasks-table {
  overflow-x: auto;
}
.uk-width-1-10 {
  width: 10%;
}
</style>
