<template>
  <div
    id="hlr"
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h3
        class="uk-modal-title uk-text-center"
      >
        HLR
      </h3>
      <div class="uk-card-body uk-padding-remove-top">
        <Loader
          v-if="!isLoaded"
          class="uk-align-center uk-margin"
        />
        <div v-else>
          <NotFound
            v-if="hlr.success === false"
            class="uk-margin"
          />
          <table
            v-else
            class="uk-table"
          >
            <tr
              v-for="(item, key) in hlr"
              :key="item"
            >
              <td v-if="item!=='' && item!=null">
                {{ $t('hlr.' + key) }}
              </td><td v-if="item!=='' && item!=null">
                {{ item }}
              </td>
            </tr>
          </table>
        </div>
        <button
          class="uk-modal-close uk-button uk-button-secondary uk-align-center"
          type="button"
        >
          {{ $t('hlr.close') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/TGCP/Loader'
import NotFound from '@/components/TGCP/NotFound.vue'
export default {
  name: 'Hlr',
  components: {
    Loader,
    NotFound
  },
  props: {
    phone: {
      required: true,
      type: String
    }
  },
  watch: {
    phone (from, to) {
      this.update()
    }
  },
  computed: {
    hlr () {
      return this.$store.state.fetcher.hlrs[this.phone]
    },
    isLoaded () {
      return this.$store.state.fetcher.isLoaded['hlr_' + this.phone]
    }
  },
  methods: {
    update () {
      const action = 'fetcher/fetchHlrInsight'
      this.$store.dispatch(action, this.phone)
    }
  },
  mounted () {
    this.update()
  }
}
</script>
