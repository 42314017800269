<template>
  <tr>
    <td class="uk-text-left">
      {{ chainTitle(block.workchain) }}
    </td>
    <td class="uk-text-left">
      {{ block.shard.toString() }}
    </td>
    <td 
      v-if="linkBlock"
      class="uk-text-left" 
    >
      <router-link 
        :to="{ name: 'crypto_ton_block_info', query: { workchain: block.workchain, shard: block.shard.toString(), seq_no: block.seq_no }, hash: '#overview'}" 
      >
        {{ block.seq_no }}
      </router-link>
    </td>
    <td 
      v-else 
      class="uk-text-left"
    >
      {{ block.seq_no }}
    </td>
    <td class="uk-text-center">
      {{ block.transactions_count }}
    </td>
    <td>
      <TableDateCell :date-time="block.scanned_at"/>
    </td>
  </tr>
</template>

<script>
import { chainTitle } from '@/utils/ton-filters'
import TableDateCell from '@/components/Crypto/Ton/Atoms/TableDateCell'

export default {
  name: 'TonBlockLine',
  components: {
    TableDateCell
  },
  props: {
    block: {
      type: Object,
      required: true
    },
    linkBlock: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    chainTitle
  }
}
</script>