import config from '@/config'
import { apiRequest } from '@/utils/api'

export default {
  namespaced: true,
  state: {
    translatedCache: new Map()
  },
  actions: {
    translateText: async ({ state, rootState }, { text }) => {
      const translateTo = rootState.settings.translate
      try {
        const translatedCacheKey = JSON.stringify({
          translateTo,
          text
        })
        const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
        if (!hasTranslatedCache) {
          const {
            data: { translation }
          } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
            lang: translateTo,
            text: text
          })
          state.translatedCache.set(translatedCacheKey, { data: { translation } })
          return {
            data: {
              translation
            }
          }
        }
        return state.translatedCache.get(translatedCacheKey)
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    }
  }
}
