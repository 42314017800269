<template>
  <div>
    <ExternalLinkDialog ref="externalLinkDialogRef"/>
    <transition
      name="custom-classes-transition"
      enter-active-class="uk-animation-slide-top-medium"
      leave-active-class="uk-animation-slide-top-medium uk-animation-reverse"
    >
      <div>
        <div
          v-if="showDemo"
          class="demo-warning"
        >
          {{ $t('working-in-demo-mode') }}
        </div>
        <div
          v-if="offline"
          class="offline"
        >
          {{ $t('offline') }}
        </div>
        <div
          v-if="online"
          class="online"
        >
          {{ $t('online') }}
        </div>
        <div
          v-if="deviceTimeError"
          class="demo-warning"
        >
          {{ $t('wrong-time') }}
        </div>
      </div>
    </transition>
    <div
      v-if="dev13"
      class="uk-position-fixed uk-position-z-index uk-position-center-right uk-margin-medium-bottom"
    >
      <button
        class="uk-button uk-button-text"
        @click="changeLanguage"
      >
        EN/RU
      </button>
    </div>
    <NavBar
      v-if="isAuthenticated && !forceChangePassword"
      :offset="offset"
    />
    <div
      v-if="!forceChangePassword"
      :style="`padding-top: ${offset}px`"
      class="uk-container"
      :class="{
        'wide-layout': isWideLayout,
        'sidebar-container': isAuthenticated,
        'collapsed-container': isAuthenticated && isMainSideMenuCollapsed
      }"
    >
      <RouterView v-slot="{ Component }">
        <transition :duration="{ enter: 1000, leave: 0 }">
          <component :is="Component" />
        </transition>
      </RouterView>
    </div>
    <changePassword
      v-else-if="isAuthenticated"
      :shown="true"
    />
    <notifications
      group="general"
      position="bottom right"
    >
      <template
        v-slot:body="props"
      >
        <div :class="`vue-notification-template vue-notification ${props.item.type}`">
          <div
            v-if="props.item.title"
            v-html="props.item.title"
            class="notification-title"
          />
          <div
            v-if="props.item.text || (props.item.data && props.item.data.primaryText)"
            class="notification-content"
          >
            {{ props.item.text }}
            <div
              v-if="props.item.data && props.item.data.primaryText"
              class="uk-button uk-button-small"
              @click="props.item.data.primaryCallback().finally(props.close)"
            >
              {{ props.item.data.primaryText }}
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <ExportNotification v-if="isAuthenticated && !forceChangePassword"/>
    <ImageInfoOffcanvas/>
    <div id="lightboxPanel"/>
  </div>
</template>

<script>
import UIkit from 'uikit'
import NavBar from '@/components/NavBar'
import Icons from 'uikit/dist/js/uikit-icons'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import config from '@/config'
import { debounce } from 'lodash'
import ExternalLinkDialog from '@/components/ExternalLinkDialog'
import { defineAsyncComponent } from 'vue'

UIkit.use(Icons)

const logOutTime = 1800 * 1000

export default {
  name: 'App',
  components: {
    NavBar,
    ExternalLinkDialog,
    changePassword: defineAsyncComponent(() => import('@/components/general/ChangePassword.vue')),
    ImageInfoOffcanvas: defineAsyncComponent(() => import('@/components/ImageInfoOffcanvas'))
  },
  data () {
    return {
      offline: false,
      online: false,
      logoutTimer: null
    }
  },
  computed: {
    ...mapGetters('cases', [
      'allCases',
      'currentCase'
    ]),
    ...mapGetters([
      'isAuthenticated',
      'authInProcess',
      'isMainSideMenuCollapsed',
      'forceChangePassword',
      'deviceTimeError'
    ]),
    showDemo () {
      return config.showDemo
    },
    isWideLayout () {
      return this.$route.meta.isWideLayout || this.forceChangePassword
    },
    userdata () {
      return this.$store.getters.getProfile
    },
    dev13 () {
      let userID = this.$store.state.current_user_id
      return window.location.hostname === 'localhost' && (userID === 103 || userID === 166 || userID === 228)
    },
    offset () {
      return (this.deviceTimeError || this.showDemo || this.offline || this.online) ? 15 : 0
    }
  },
  methods: {
    ...mapActions('cases', [
      'setCurrentCase',
      'getCaseById'
    ]),
    ...mapActions([
      'logout'
    ]),
    ...mapMutations([
      'setToken',
      'setUserId',
      'setRefreshToken',
      'setUserId',
      'setUserName'
    ]),
    renewToken: debounce(function (token) {
      this.setToken(token)
    }, 1500),
    saveLastActivity: debounce(() => localStorage.setItem('last_activity', Date.now()), 4000, { 'maxWait': 30000 }),
    createLogoutTimer () {
      try {
        if (this.isAuthenticated && (+localStorage.getItem('last_activity') + logOutTime <= Date.now())) {
          this.logout()
        } else {
          this.saveLastActivity()
        }
        clearTimeout(this.logoutTimer)
      } catch (err) {
        console.debug(err)
      } finally {
        this.logoutTimer = this.isAuthenticated && setTimeout(() => {
          if (document.visibilityState === 'visible') this.logout()
        }, logOutTime)
      }
    },
    setLocale () {
      if (window.location.hostname === 'panel.sm-analytics.net') {
        this.$i18n.locale = 'ru'
      } else if (window.location.hostname === 'web.sma.services') {
        this.$i18n.locale = 'en'
      } else if (window.location.hostname === 'asui-892-web.web.local') {
        this.$i18n.locale = 'es'
      } else {
        this.$i18n.locale = this.$store.state.settings.language
      }
      dayjs.locale(this.$i18n.locale)
    },
    changeLanguage () {
      this.$i18n.locale === 'ru'
        ? (this.$i18n.locale = 'en')
        : (this.$i18n.locale = 'ru')
      dayjs.locale(this.$i18n.locale)
    }
  },
  async created () {
    this.setLocale()
    if (this.isAuthenticated) {
      try {
        let { defaultCase } = await this.$store.dispatch('setSettings')
        const id = JSON.parse(localStorage['current-case'] || defaultCase)
        if (id) {
          await this.getCaseById(id)
          this.setCurrentCase(id || localStorage['current-case'] || this.defaultCase)
        }
      } catch {
        this.setCurrentCase(null)
      }
    }
  },
  mounted () {
    try {
      globalThis.searchUsingFaceRecognition = source => {
        try {
          this.$router.push({
            name: 'face_search',
            query: {
              face: source
            }
          })
        } catch (error) {
          console.debug(error)
        }
      }
      globalThis.searchSimilarImages = source => {
        try {
          this.$router.push({
            name: 'similar_search',
            query: {
              similar: source
            }
          })
        } catch (error) {
          console.debug(error)
        }
      }
    } catch (error) {
      console.debug(error)
    }
    window.addEventListener('storage', e => {
      if (e.key !== 'asiris') return
      const nls = JSON.parse(e.newValue)
      if (
        this.isAuthenticated &&
        !nls.auth_token &&
        !nls.refresh_token &&
        !nls.current_user_id &&
        !nls.user_name
      ) {
        this.$router.go()
      }
      if (
        !this.isAuthenticated &&
        nls.auth_token && nls.auth_token !== this.$store.state.auth_token &&
        nls.refresh_token && nls.refresh_token !== this.$store.state.refresh_token &&
        nls.current_user_id && nls.current_user_id !== this.$store.state.current_user_id &&
        nls.user_name && nls.user_name !== this.$store.state.user_name
      ) {
        this.setToken(nls.auth_token)
        this.setRefreshToken(nls.refresh_token)
        this.setUserId(nls.current_user_id)
        this.setUserName(nls.user_name)
        this.$router.push(sessionStorage.getItem('redirect') || '/')
      }
    })
    window.addEventListener('keydown', this.createLogoutTimer)
    window.addEventListener('focus', this.createLogoutTimer)
    window.addEventListener('mousedown', this.createLogoutTimer)
    window.addEventListener('mouseup', this.createLogoutTimer)
    window.addEventListener('onclick', this.createLogoutTimer)
    window.addEventListener('scroll', this.createLogoutTimer)
    window.addEventListener('offline', () => {
      this.offline = true
    })
    window.addEventListener('online', () => {
      this.offline = false
      this.online = true
      setTimeout(() => {
        this.online = false
      }, 1500)
    })
    document.addEventListener('click', (event) => {
      if (!event) {
        return
      }

      let element = null
      const path = event.composedPath()

      // A click may not be on a link, but on a nested tag, so need to find the first element in the chain of events and get a href
      for (let pathElement of path) {
        if (pathElement.tagName === 'A' && pathElement.href) {
          element = pathElement
        }
      }
      const isSelf = element?.href && /^https?:?\/\/gw\.sma\.services/i?.test(element.href)

      if (isSelf) return
      if (!element) return
      if (
        element?.hasAttribute instanceof Function &&
        element.hasAttribute('download') &&
        typeof element?.href === 'string' &&
        /^data:((image\/png)|(text\/csv));/i.test(element.href)
      ) {
        return
      }
      if (element.skipGlobalLinksHandler) return

      const href = element.href.trim()
      const currentLocation = `${location.protocol}//${location.host}`

      if (!href || href === '#') return
      if (href.startsWith('blob:')) return
      if (href.startsWith(currentLocation)) return
      if (href.startsWith('/') && !href.startsWith('//')) return

      event.preventDefault()

      if (!this.$refs.externalLinkDialogRef) {
        return
      }

      if (event.ctrlKey || event.metaKey) {
        this.$refs.externalLinkDialogRef.directGoTo(href)
      } else {
        this.$refs.externalLinkDialogRef.showModal(href)
      }
    })
  }
}
</script>

<style src="./assets/fonts/bender.css"></style>

<style lang="scss">
$global-link-color: #DA7D02;
//#c41e23
// $global-primary-background: #c41e23; // defence group color
$global-font-family: 'Bender';
// 2. Import default variables and available mixins.
@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import "../node_modules/uikit/src/scss/uikit-theme.scss";

.uk-tooltip {
  overflow: hidden;
  word-wrap: break-word;
}

html {
  background: #ECEFF1;
  height: 100%;
}

.demo-warning {
  width: 100%;
  font-family: Bender;
  position: fixed;
  top: 0;
  font-size: 10px;
  z-index: 10000;
  display: block;
  background: #faa05a;
}

.offline {
  width: 100%;
  font-family: Bender;
  position: fixed;
  top: 0;
  font-size: 10px;
  z-index: 10000;
  background: #d31818;
  color: white;
}

.online {
  width: 100%;
  font-family: Bender;
  position: fixed;
  top: 0;
  font-size: 10px;
  z-index: 10000;
  background: #56e656;
}

.uk-input::placeholder {
  font-style: italic
}

.uk-pagination .active {
  font-weight: bold
}

#app {
  font-family: 'Bender', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.wide-layout {
  max-width: none !important;
}

.apexcharts-bar-area {
  cursor: pointer;
}

.apexcharts-yaxis-label:hover {
  font-weight: bold;
  cursor: pointer;
}

.vue-zoomer > .zoomer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 960px) {
  .uk-container {
    padding-left: 20px;
    padding-right: 10px;
    max-width:  1920px;
  }

  .sidebar-container {
    padding-left: 192px;
  }

  .collapsed-container {
    padding-left: 70px !important;
  }
}

.tm-sidebar-left {
  width: 180px;
  z-index: 1000;
  top: 0;
}

textarea {
  resize: none;
}

.profile-button {
  padding: 0 10px 10px 10px;
}

.vuejs-pagination-container {
  li {
    display: inline;
    list-style-type: none;
  }

  a {
    color: #999;
    padding: 5px;
  }
}

.lottie-sticker {
  width: 128px;
  height: 128px
}

.custom-emoji {
  display: inline-block;
  width: 24px;
  height: 24px
}

svg.leaflet-attribution-flag {
  display: none !important;
}
</style>
