// @ts-check
/**
 * @typedef {{ chatName: string, username: string, user_id: string, date: string, message: string, link: string, first_name: string, last_name: string, channelUsername: string}} Entry
 * @typedef {object[]} Entries
 */

/**
 * @class
 * @readonly
 */
class Txt {
  /**
   * @type {string}
   */
  data = ''
  /**
   * @readonly
   * @constructor
   * @param {Entries} entries
   */
  constructor (entries) {
    try {
      const values = entries
        .map(entry => {
          try {
            /**
             * @type {Partial<Entry>}
             */
            const nextEntry = entry
            const next = `${nextEntry.chatName} ${nextEntry.channelUsername ? '@' + nextEntry.channelUsername : ''}${
              nextEntry.user_id ? '\nАвтор: ' + (nextEntry.first_name || '') + (nextEntry.last_name ? ' ' + nextEntry.last_name : '') + (nextEntry.username ? ' @' + nextEntry.username : '') + (' #' + nextEntry.user_id) : ''
            }${nextEntry.date}${nextEntry.message}${nextEntry.link || ''}
———————————
`
            return next
          } catch (error) {
            console.debug(error)
          }
          return ''
        })
        .join('\n')
      this.data = values
    } catch (error) {
      console.debug(error)
    }
  }
}

export default Txt
