const safariDictionary = [
  // From plane 1
  ['̀', '̀'],
  ['́', '́'],
  ['̓', '̓'],
  ['̈́', '̈́'],
  ['ʹ', 'ʹ'],
  [';', ';'],
  ['·', '·'],
  ['क़', 'क़'],
  ['ख़', 'ख़'],
  ['ग़', 'ग़'],
  ['ज़', 'ज़'],
  ['ड़', 'ड़'],
  ['ढ़', 'ढ़'],
  ['फ़', 'फ़'],
  ['य़', 'य़'],
  ['ড়', 'ড়'],
  ['ঢ়', 'ঢ়'],
  ['য়', 'য়'],
  ['ਲ਼', 'ਲ਼'],
  ['ਸ਼', 'ਸ਼'],
  ['ਖ਼', 'ਖ਼'],
  ['ਗ਼', 'ਗ਼'],
  ['ਜ਼', 'ਜ਼'],
  ['ਫ਼', 'ਫ਼'],
  ['ଡ଼', 'ଡ଼'],
  ['ଢ଼', 'ଢ଼'],
  ['གྷ', 'གྷ'],
  ['ཌྷ', 'ཌྷ'],
  ['དྷ', 'དྷ'],
  ['བྷ', 'བྷ'],
  ['ཛྷ', 'ཛྷ'],
  ['ཀྵ', 'ཀྵ'],
  ['ཱི', 'ཱི'],
  ['ཱུ', 'ཱུ'],
  ['ྲྀ', 'ྲྀ'],
  ['ླྀ', 'ླྀ'],
  ['ཱྀ', 'ཱྀ'],
  ['ྒྷ', 'ྒྷ'],
  ['ྜྷ', 'ྜྷ'],
  ['ྡྷ', 'ྡྷ'],
  ['ྦྷ', 'ྦྷ'],
  ['ྫྷ', 'ྫྷ'],
  ['ྐྵ', 'ྐྵ'],
  ['ά', 'ά'],
  ['έ', 'έ'],
  ['ή', 'ή'],
  ['ί', 'ί'],
  ['ό', 'ό'],
  ['ύ', 'ύ'],
  ['ώ', 'ώ'],
  ['Ά', 'Ά'],
  ['ι', 'ι'],
  ['Έ', 'Έ'],
  ['Ή', 'Ή'],
  ['ΐ', 'ΐ'],
  ['Ί', 'Ί'],
  ['ΰ', 'ΰ'],
  ['Ύ', 'Ύ'],
  ['΅', '΅'],
  ['`', '`'],
  ['Ό', 'Ό'],
  ['Ώ', 'Ώ'],
  ['´', '´'],
  [' ', ' '],
  [' ', ' '],
  ['Ω', 'Ω'],
  ['K', 'K'],
  ['Å', 'Å'],
  ['〈', '〈'],
  ['〉', '〉'],
  ['⫝̸', '⫝̸'],
  ['豈', '豈'],
  ['更', '更'],
  ['車', '車'],
  ['賈', '賈'],
  ['滑', '滑'],
  ['串', '串'],
  ['句', '句'],
  ['龜', '龜'],
  ['契', '契'],
  ['金', '金'],
  ['喇', '喇'],
  ['奈', '奈'],
  ['懶', '懶'],
  ['癩', '癩'],
  ['羅', '羅'],
  ['蘿', '蘿'],
  ['螺', '螺'],
  ['裸', '裸'],
  ['邏', '邏'],
  ['樂', '樂'],
  ['洛', '洛'],
  ['烙', '烙'],
  ['珞', '珞'],
  ['落', '落'],
  ['酪', '酪'],
  ['駱', '駱'],
  ['亂', '亂'],
  ['卵', '卵'],
  ['欄', '欄'],
  ['爛', '爛'],
  ['蘭', '蘭'],
  ['鸞', '鸞'],
  ['嵐', '嵐'],
  ['濫', '濫'],
  ['藍', '藍'],
  ['襤', '襤'],
  ['拉', '拉'],
  ['臘', '臘'],
  ['蠟', '蠟'],
  ['廊', '廊'],
  ['朗', '朗'],
  ['浪', '浪'],
  ['狼', '狼'],
  ['郎', '郎'],
  ['來', '來'],
  ['冷', '冷'],
  ['勞', '勞'],
  ['擄', '擄'],
  ['櫓', '櫓'],
  ['爐', '爐'],
  ['盧', '盧'],
  ['老', '老'],
  ['蘆', '蘆'],
  ['虜', '虜'],
  ['路', '路'],
  ['露', '露'],
  ['魯', '魯'],
  ['鷺', '鷺'],
  ['碌', '碌'],
  ['祿', '祿'],
  ['綠', '綠'],
  ['菉', '菉'],
  ['錄', '錄'],
  ['鹿', '鹿'],
  ['論', '論'],
  ['壟', '壟'],
  ['弄', '弄'],
  ['籠', '籠'],
  ['聾', '聾'],
  ['牢', '牢'],
  ['磊', '磊'],
  ['賂', '賂'],
  ['雷', '雷'],
  ['壘', '壘'],
  ['屢', '屢'],
  ['樓', '樓'],
  ['淚', '淚'],
  ['漏', '漏'],
  ['累', '累'],
  ['縷', '縷'],
  ['陋', '陋'],
  ['勒', '勒'],
  ['肋', '肋'],
  ['凜', '凜'],
  ['凌', '凌'],
  ['稜', '稜'],
  ['綾', '綾'],
  ['菱', '菱'],
  ['陵', '陵'],
  ['讀', '讀'],
  ['拏', '拏'],
  ['諾', '諾'],
  ['丹', '丹'],
  ['寧', '寧'],
  ['怒', '怒'],
  ['率', '率'],
  ['異', '異'],
  ['北', '北'],
  ['磻', '磻'],
  ['便', '便'],
  ['復', '復'],
  ['不', '不'],
  ['泌', '泌'],
  ['數', '數'],
  ['索', '索'],
  ['參', '參'],
  ['塞', '塞'],
  ['省', '省'],
  ['葉', '葉'],
  ['說', '說'],
  ['殺', '殺'],
  ['辰', '辰'],
  ['沈', '沈'],
  ['拾', '拾'],
  ['若', '若'],
  ['掠', '掠'],
  ['略', '略'],
  ['亮', '亮'],
  ['兩', '兩'],
  ['凉', '凉'],
  ['梁', '梁'],
  ['糧', '糧'],
  ['良', '良'],
  ['諒', '諒'],
  ['量', '量'],
  ['勵', '勵'],
  ['呂', '呂'],
  ['女', '女'],
  ['廬', '廬'],
  ['旅', '旅'],
  ['濾', '濾'],
  ['礪', '礪'],
  ['閭', '閭'],
  ['驪', '驪'],
  ['麗', '麗'],
  ['黎', '黎'],
  ['力', '力'],
  ['曆', '曆'],
  ['歷', '歷'],
  ['轢', '轢'],
  ['年', '年'],
  ['憐', '憐'],
  ['戀', '戀'],
  ['撚', '撚'],
  ['漣', '漣'],
  ['煉', '煉'],
  ['璉', '璉'],
  ['秊', '秊'],
  ['練', '練'],
  ['聯', '聯'],
  ['輦', '輦'],
  ['蓮', '蓮'],
  ['連', '連'],
  ['鍊', '鍊'],
  ['列', '列'],
  ['劣', '劣'],
  ['咽', '咽'],
  ['烈', '烈'],
  ['裂', '裂'],
  ['廉', '廉'],
  ['念', '念'],
  ['捻', '捻'],
  ['殮', '殮'],
  ['簾', '簾'],
  ['獵', '獵'],
  ['令', '令'],
  ['囹', '囹'],
  ['嶺', '嶺'],
  ['怜', '怜'],
  ['玲', '玲'],
  ['瑩', '瑩'],
  ['羚', '羚'],
  ['聆', '聆'],
  ['鈴', '鈴'],
  ['零', '零'],
  ['靈', '靈'],
  ['領', '領'],
  ['例', '例'],
  ['禮', '禮'],
  ['醴', '醴'],
  ['隸', '隸'],
  ['惡', '惡'],
  ['了', '了'],
  ['僚', '僚'],
  ['寮', '寮'],
  ['尿', '尿'],
  ['料', '料'],
  ['燎', '燎'],
  ['療', '療'],
  ['蓼', '蓼'],
  ['遼', '遼'],
  ['龍', '龍'],
  ['暈', '暈'],
  ['阮', '阮'],
  ['劉', '劉'],
  ['杻', '杻'],
  ['柳', '柳'],
  ['流', '流'],
  ['溜', '溜'],
  ['琉', '琉'],
  ['留', '留'],
  ['硫', '硫'],
  ['紐', '紐'],
  ['類', '類'],
  ['六', '六'],
  ['戮', '戮'],
  ['陸', '陸'],
  ['倫', '倫'],
  ['崙', '崙'],
  ['淪', '淪'],
  ['輪', '輪'],
  ['律', '律'],
  ['慄', '慄'],
  ['栗', '栗'],
  ['隆', '隆'],
  ['利', '利'],
  ['吏', '吏'],
  ['履', '履'],
  ['易', '易'],
  ['李', '李'],
  ['梨', '梨'],
  ['泥', '泥'],
  ['理', '理'],
  ['痢', '痢'],
  ['罹', '罹'],
  ['裏', '裏'],
  ['裡', '裡'],
  ['里', '里'],
  ['離', '離'],
  ['匿', '匿'],
  ['溺', '溺'],
  ['吝', '吝'],
  ['燐', '燐'],
  ['璘', '璘'],
  ['藺', '藺'],
  ['隣', '隣'],
  ['鱗', '鱗'],
  ['麟', '麟'],
  ['林', '林'],
  ['淋', '淋'],
  ['臨', '臨'],
  ['立', '立'],
  ['笠', '笠'],
  ['粒', '粒'],
  ['狀', '狀'],
  ['炙', '炙'],
  ['識', '識'],
  ['什', '什'],
  ['茶', '茶'],
  ['刺', '刺'],
  ['切', '切'],
  ['度', '度'],
  ['拓', '拓'],
  ['糖', '糖'],
  ['宅', '宅'],
  ['洞', '洞'],
  ['暴', '暴'],
  ['輻', '輻'],
  ['行', '行'],
  ['降', '降'],
  ['見', '見'],
  ['廓', '廓'],
  ['兀', '兀'],
  ['嗀', '嗀'],
  ['塚', '塚'],
  ['晴', '晴'],
  ['凞', '凞'],
  ['猪', '猪'],
  ['益', '益'],
  ['礼', '礼'],
  ['神', '神'],
  ['祥', '祥'],
  ['福', '福'],
  ['靖', '靖'],
  ['精', '精'],
  ['羽', '羽'],
  ['蘒', '蘒'],
  ['諸', '諸'],
  ['逸', '逸'],
  ['都', '都'],
  ['飯', '飯'],
  ['飼', '飼'],
  ['館', '館'],
  ['鶴', '鶴'],
  ['郞', '郞'],
  ['隷', '隷'],
  ['侮', '侮'],
  ['僧', '僧'],
  ['免', '免'],
  ['勉', '勉'],
  ['勤', '勤'],
  ['卑', '卑'],
  ['喝', '喝'],
  ['嘆', '嘆'],
  ['器', '器'],
  ['塀', '塀'],
  ['墨', '墨'],
  ['層', '層'],
  ['屮', '屮'],
  ['悔', '悔'],
  ['慨', '慨'],
  ['憎', '憎'],
  ['懲', '懲'],
  ['敏', '敏'],
  ['既', '既'],
  ['暑', '暑'],
  ['梅', '梅'],
  ['海', '海'],
  ['渚', '渚'],
  ['漢', '漢'],
  ['煮', '煮'],
  ['爫', '爫'],
  ['琢', '琢'],
  ['碑', '碑'],
  ['社', '社'],
  ['祉', '祉'],
  ['祈', '祈'],
  ['祐', '祐'],
  ['祖', '祖'],
  ['祝', '祝'],
  ['禍', '禍'],
  ['禎', '禎'],
  ['穀', '穀'],
  ['突', '突'],
  ['節', '節'],
  ['縉', '縉'],
  ['繁', '繁'],
  ['署', '署'],
  ['者', '者'],
  ['臭', '臭'],
  ['艹', '艹'],
  ['著', '著'],
  ['褐', '褐'],
  ['視', '視'],
  ['謁', '謁'],
  ['謹', '謹'],
  ['賓', '賓'],
  ['贈', '贈'],
  ['辶', '辶'],
  ['難', '難'],
  ['響', '響'],
  ['頻', '頻'],
  ['恵', '恵'],
  ['𤋮', '𤋮'],
  ['舘', '舘'],
  ['並', '並'],
  ['况', '况'],
  ['全', '全'],
  ['侀', '侀'],
  ['充', '充'],
  ['冀', '冀'],
  ['勇', '勇'],
  ['勺', '勺'],
  ['啕', '啕'],
  ['喙', '喙'],
  ['嗢', '嗢'],
  ['墳', '墳'],
  ['奄', '奄'],
  ['奔', '奔'],
  ['婢', '婢'],
  ['嬨', '嬨'],
  ['廒', '廒'],
  ['廙', '廙'],
  ['彩', '彩'],
  ['徭', '徭'],
  ['惘', '惘'],
  ['慎', '慎'],
  ['愈', '愈'],
  ['慠', '慠'],
  ['戴', '戴'],
  ['揄', '揄'],
  ['搜', '搜'],
  ['摒', '摒'],
  ['敖', '敖'],
  ['望', '望'],
  ['杖', '杖'],
  ['歹', '歹'],
  ['滛', '滛'],
  ['滋', '滋'],
  ['瀞', '瀞'],
  ['瞧', '瞧'],
  ['爵', '爵'],
  ['犯', '犯'],
  ['瑱', '瑱'],
  ['甆', '甆'],
  ['画', '画'],
  ['瘝', '瘝'],
  ['瘟', '瘟'],
  ['盛', '盛'],
  ['直', '直'],
  ['睊', '睊'],
  ['着', '着'],
  ['磌', '磌'],
  ['窱', '窱'],
  ['类', '类'],
  ['絛', '絛'],
  ['缾', '缾'],
  ['荒', '荒'],
  ['華', '華'],
  ['蝹', '蝹'],
  ['襁', '襁'],
  ['覆', '覆'],
  ['調', '調'],
  ['請', '請'],
  ['諭', '諭'],
  ['變', '變'],
  ['輸', '輸'],
  ['遲', '遲'],
  ['醙', '醙'],
  ['鉶', '鉶'],
  ['陼', '陼'],
  ['韛', '韛'],
  ['頋', '頋'],
  ['鬒', '鬒'],
  ['𢡊', '𢡊'],
  ['𢡄', '𢡄'],
  ['𣏕', '𣏕'],
  ['㮝', '㮝'],
  ['䀘', '䀘'],
  ['䀹', '䀹'],
  ['𥉉', '𥉉'],
  ['𥳐', '𥳐'],
  ['𧻓', '𧻓'],
  ['齃', '齃'],
  ['龎', '龎'],
  ['יִ', 'יִ'],
  ['ײַ', 'ײַ'],
  ['שׁ', 'שׁ'],
  ['שׂ', 'שׂ'],
  ['שּׁ', 'שּׁ'],
  ['שּׂ', 'שּׂ'],
  ['אַ', 'אַ'],
  ['אָ', 'אָ'],
  ['אּ', 'אּ'],
  ['בּ', 'בּ'],
  ['גּ', 'גּ'],
  ['דּ', 'דּ'],
  ['הּ', 'הּ'],
  ['וּ', 'וּ'],
  ['זּ', 'זּ'],
  ['טּ', 'טּ'],
  ['יּ', 'יּ'],
  ['ךּ', 'ךּ'],
  ['כּ', 'כּ'],
  ['לּ', 'לּ'],
  ['מּ', 'מּ'],
  ['נּ', 'נּ'],
  ['סּ', 'סּ'],
  ['ףּ', 'ףּ'],
  ['פּ', 'פּ'],
  ['צּ', 'צּ'],
  ['קּ', 'קּ'],
  ['רּ', 'רּ'],
  ['שּ', 'שּ'],
  ['תּ', 'תּ'],
  ['וֹ', 'וֹ'],
  ['בֿ', 'בֿ'],
  ['כֿ', 'כֿ'],
  ['פֿ', 'פֿ'],

  // From plane 1
  ['𝅗𝅥', '𝅗𝅥'],
  ['𝅘𝅥', '𝅘𝅥'],
  ['𝅘𝅥𝅮', '𝅘𝅥𝅮'],
  ['𝅘𝅥𝅯', '𝅘𝅥𝅯'],
  ['𝅘𝅥𝅰', '𝅘𝅥𝅰'],
  ['𝅘𝅥𝅱', '𝅘𝅥𝅱'],
  ['𝅘𝅥𝅲', '𝅘𝅥𝅲'],
  ['𝆹𝅥', '𝆹𝅥'],
  ['𝆺𝅥', '𝆺𝅥'],
  ['𝆹𝅥𝅮', '𝆹𝅥𝅮'],
  ['𝆺𝅥𝅮', '𝆺𝅥𝅮'],
  ['𝆹𝅥𝅯', '𝆹𝅥𝅯'],
  ['𝆺𝅥𝅯', '𝆺𝅥𝅯'],

  // From plane 2 (duplicates from plane 0 have been removed)
  ['丽', '丽'],
  ['丸', '丸'],
  ['乁', '乁'],
  ['𠄢', '𠄢'],
  ['你', '你'],
  ['侻', '侻'],
  ['倂', '倂'],
  ['偺', '偺'],
  ['備', '備'],
  ['像', '像'],
  ['㒞', '㒞'],
  ['𠘺', '𠘺'],
  ['兔', '兔'],
  ['兤', '兤'],
  ['具', '具'],
  ['𠔜', '𠔜'],
  ['㒹', '㒹'],
  ['內', '內'],
  ['再', '再'],
  ['𠕋', '𠕋'],
  ['冗', '冗'],
  ['冤', '冤'],
  ['仌', '仌'],
  ['冬', '冬'],
  ['𩇟', '𩇟'],
  ['凵', '凵'],
  ['刃', '刃'],
  ['㓟', '㓟'],
  ['刻', '刻'],
  ['剆', '剆'],
  ['割', '割'],
  ['剷', '剷'],
  ['㔕', '㔕'],
  ['包', '包'],
  ['匆', '匆'],
  ['卉', '卉'],
  ['博', '博'],
  ['即', '即'],
  ['卽', '卽'],
  ['卿', '卿'],
  ['𠨬', '𠨬'],
  ['灰', '灰'],
  ['及', '及'],
  ['叟', '叟'],
  ['𠭣', '𠭣'],
  ['叫', '叫'],
  ['叱', '叱'],
  ['吆', '吆'],
  ['咞', '咞'],
  ['吸', '吸'],
  ['呈', '呈'],
  ['周', '周'],
  ['咢', '咢'],
  ['哶', '哶'],
  ['唐', '唐'],
  ['啓', '啓'],
  ['啣', '啣'],
  ['善', '善'],
  ['喫', '喫'],
  ['喳', '喳'],
  ['嗂', '嗂'],
  ['圖', '圖'],
  ['圗', '圗'],
  ['噑', '噑'],
  ['噴', '噴'],
  ['壮', '壮'],
  ['城', '城'],
  ['埴', '埴'],
  ['堍', '堍'],
  ['型', '型'],
  ['堲', '堲'],
  ['報', '報'],
  ['墬', '墬'],
  ['𡓤', '𡓤'],
  ['売', '売'],
  ['壷', '壷'],
  ['夆', '夆'],
  ['多', '多'],
  ['夢', '夢'],
  ['奢', '奢'],
  ['𡚨', '𡚨'],
  ['𡛪', '𡛪'],
  ['姬', '姬'],
  ['娛', '娛'],
  ['娧', '娧'],
  ['姘', '姘'],
  ['婦', '婦'],
  ['㛮', '㛮'],
  ['㛼', '㛼'],
  ['嬈', '嬈'],
  ['嬾', '嬾'],
  ['𡧈', '𡧈'],
  ['寃', '寃'],
  ['寘', '寘'],
  ['寳', '寳'],
  ['𡬘', '𡬘'],
  ['寿', '寿'],
  ['将', '将'],
  ['当', '当'],
  ['尢', '尢'],
  ['㞁', '㞁'],
  ['屠', '屠'],
  ['峀', '峀'],
  ['岍', '岍'],
  ['𡷤', '𡷤'],
  ['嵃', '嵃'],
  ['𡷦', '𡷦'],
  ['嵮', '嵮'],
  ['嵫', '嵫'],
  ['嵼', '嵼'],
  ['巡', '巡'],
  ['巢', '巢'],
  ['㠯', '㠯'],
  ['巽', '巽'],
  ['帨', '帨'],
  ['帽', '帽'],
  ['幩', '幩'],
  ['㡢', '㡢'],
  ['𢆃', '𢆃'],
  ['㡼', '㡼'],
  ['庰', '庰'],
  ['庳', '庳'],
  ['庶', '庶'],
  ['𪎒', '𪎒'],
  ['廾', '廾'],
  ['𢌱', '𢌱'],
  ['舁', '舁'],
  ['弢', '弢'],
  ['㣇', '㣇'],
  ['𣊸', '𣊸'],
  ['𦇚', '𦇚'],
  ['形', '形'],
  ['彫', '彫'],
  ['㣣', '㣣'],
  ['徚', '徚'],
  ['忍', '忍'],
  ['志', '志'],
  ['忹', '忹'],
  ['悁', '悁'],
  ['㤺', '㤺'],
  ['㤜', '㤜'],
  ['𢛔', '𢛔'],
  ['惇', '惇'],
  ['慈', '慈'],
  ['慌', '慌'],
  ['慺', '慺'],
  ['憲', '憲'],
  ['憤', '憤'],
  ['憯', '憯'],
  ['懞', '懞'],
  ['成', '成'],
  ['戛', '戛'],
  ['扝', '扝'],
  ['抱', '抱'],
  ['拔', '拔'],
  ['捐', '捐'],
  ['𢬌', '𢬌'],
  ['挽', '挽'],
  ['拼', '拼'],
  ['捨', '捨'],
  ['掃', '掃'],
  ['揤', '揤'],
  ['𢯱', '𢯱'],
  ['搢', '搢'],
  ['揅', '揅'],
  ['掩', '掩'],
  ['㨮', '㨮'],
  ['摩', '摩'],
  ['摾', '摾'],
  ['撝', '撝'],
  ['摷', '摷'],
  ['㩬', '㩬'],
  ['敬', '敬'],
  ['𣀊', '𣀊'],
  ['旣', '旣'],
  ['書', '書'],
  ['晉', '晉'],
  ['㬙', '㬙'],
  ['㬈', '㬈'],
  ['㫤', '㫤'],
  ['冒', '冒'],
  ['冕', '冕'],
  ['最', '最'],
  ['暜', '暜'],
  ['肭', '肭'],
  ['䏙', '䏙'],
  ['朡', '朡'],
  ['杞', '杞'],
  ['杓', '杓'],
  ['𣏃', '𣏃'],
  ['㭉', '㭉'],
  ['柺', '柺'],
  ['枅', '枅'],
  ['桒', '桒'],
  ['𣑭', '𣑭'],
  ['梎', '梎'],
  ['栟', '栟'],
  ['椔', '椔'],
  ['楂', '楂'],
  ['榣', '榣'],
  ['槪', '槪'],
  ['檨', '檨'],
  ['𣚣', '𣚣'],
  ['櫛', '櫛'],
  ['㰘', '㰘'],
  ['次', '次'],
  ['𣢧', '𣢧'],
  ['歔', '歔'],
  ['㱎', '㱎'],
  ['歲', '歲'],
  ['殟', '殟'],
  ['殻', '殻'],
  ['𣪍', '𣪍'],
  ['𡴋', '𡴋'],
  ['𣫺', '𣫺'],
  ['汎', '汎'],
  ['𣲼', '𣲼'],
  ['沿', '沿'],
  ['泍', '泍'],
  ['汧', '汧'],
  ['洖', '洖'],
  ['派', '派'],
  ['浩', '浩'],
  ['浸', '浸'],
  ['涅', '涅'],
  ['𣴞', '𣴞'],
  ['洴', '洴'],
  ['港', '港'],
  ['湮', '湮'],
  ['㴳', '㴳'],
  ['滇', '滇'],
  ['𣻑', '𣻑'],
  ['淹', '淹'],
  ['潮', '潮'],
  ['𣽞', '𣽞'],
  ['𣾎', '𣾎'],
  ['濆', '濆'],
  ['瀹', '瀹'],
  ['瀛', '瀛'],
  ['㶖', '㶖'],
  ['灊', '灊'],
  ['災', '災'],
  ['灷', '灷'],
  ['炭', '炭'],
  ['𠔥', '𠔥'],
  ['煅', '煅'],
  ['𤉣', '𤉣'],
  ['熜', '熜'],
  ['𤎫', '𤎫'],
  ['爨', '爨'],
  ['牐', '牐'],
  ['𤘈', '𤘈'],
  ['犀', '犀'],
  ['犕', '犕'],
  ['𤜵', '𤜵'],
  ['𤠔', '𤠔'],
  ['獺', '獺'],
  ['王', '王'],
  ['㺬', '㺬'],
  ['玥', '玥'],
  ['㺸', '㺸'],
  ['瑇', '瑇'],
  ['瑜', '瑜'],
  ['璅', '璅'],
  ['瓊', '瓊'],
  ['㼛', '㼛'],
  ['甤', '甤'],
  ['𤰶', '𤰶'],
  ['甾', '甾'],
  ['𤲒', '𤲒'],
  ['𢆟', '𢆟'],
  ['瘐', '瘐'],
  ['𤾡', '𤾡'],
  ['𤾸', '𤾸'],
  ['𥁄', '𥁄'],
  ['㿼', '㿼'],
  ['䀈', '䀈'],
  ['𥃳', '𥃳'],
  ['𥃲', '𥃲'],
  ['𥄙', '𥄙'],
  ['𥄳', '𥄳'],
  ['眞', '眞'],
  ['真', '真'],
  ['瞋', '瞋'],
  ['䁆', '䁆'],
  ['䂖', '䂖'],
  ['𥐝', '𥐝'],
  ['硎', '硎'],
  ['䃣', '䃣'],
  ['𥘦', '𥘦'],
  ['𥚚', '𥚚'],
  ['𥛅', '𥛅'],
  ['秫', '秫'],
  ['䄯', '䄯'],
  ['穊', '穊'],
  ['穏', '穏'],
  ['𥥼', '𥥼'],
  ['𥪧', '𥪧'],
  ['竮', '竮'],
  ['䈂', '䈂'],
  ['𥮫', '𥮫'],
  ['篆', '篆'],
  ['築', '築'],
  ['䈧', '䈧'],
  ['𥲀', '𥲀'],
  ['糒', '糒'],
  ['䊠', '䊠'],
  ['糨', '糨'],
  ['糣', '糣'],
  ['紀', '紀'],
  ['𥾆', '𥾆'],
  ['絣', '絣'],
  ['䌁', '䌁'],
  ['緇', '緇'],
  ['縂', '縂'],
  ['繅', '繅'],
  ['䌴', '䌴'],
  ['𦈨', '𦈨'],
  ['𦉇', '𦉇'],
  ['䍙', '䍙'],
  ['𦋙', '𦋙'],
  ['罺', '罺'],
  ['𦌾', '𦌾'],
  ['羕', '羕'],
  ['翺', '翺'],
  ['𦓚', '𦓚'],
  ['𦔣', '𦔣'],
  ['聠', '聠'],
  ['𦖨', '𦖨'],
  ['聰', '聰'],
  ['𣍟', '𣍟'],
  ['䏕', '䏕'],
  ['育', '育'],
  ['脃', '脃'],
  ['䐋', '䐋'],
  ['脾', '脾'],
  ['媵', '媵'],
  ['𦞧', '𦞧'],
  ['𦞵', '𦞵'],
  ['𣎓', '𣎓'],
  ['𣎜', '𣎜'],
  ['舄', '舄'],
  ['辞', '辞'],
  ['䑫', '䑫'],
  ['芑', '芑'],
  ['芋', '芋'],
  ['芝', '芝'],
  ['劳', '劳'],
  ['花', '花'],
  ['芳', '芳'],
  ['芽', '芽'],
  ['苦', '苦'],
  ['𦬼', '𦬼'],
  ['茝', '茝'],
  ['荣', '荣'],
  ['莭', '莭'],
  ['茣', '茣'],
  ['莽', '莽'],
  ['菧', '菧'],
  ['荓', '荓'],
  ['菊', '菊'],
  ['菌', '菌'],
  ['菜', '菜'],
  ['𦰶', '𦰶'],
  ['𦵫', '𦵫'],
  ['𦳕', '𦳕'],
  ['䔫', '䔫'],
  ['蓱', '蓱'],
  ['蓳', '蓳'],
  ['蔖', '蔖'],
  ['𧏊', '𧏊'],
  ['蕤', '蕤'],
  ['𦼬', '𦼬'],
  ['䕝', '䕝'],
  ['䕡', '䕡'],
  ['𦾱', '𦾱'],
  ['𧃒', '𧃒'],
  ['䕫', '䕫'],
  ['虐', '虐'],
  ['虧', '虧'],
  ['虩', '虩'],
  ['蚩', '蚩'],
  ['蚈', '蚈'],
  ['蜎', '蜎'],
  ['蛢', '蛢'],
  ['蜨', '蜨'],
  ['蝫', '蝫'],
  ['螆', '螆'],
  ['䗗', '䗗'],
  ['蟡', '蟡'],
  ['蠁', '蠁'],
  ['䗹', '䗹'],
  ['衠', '衠'],
  ['衣', '衣'],
  ['𧙧', '𧙧'],
  ['裗', '裗'],
  ['裞', '裞'],
  ['䘵', '䘵'],
  ['裺', '裺'],
  ['㒻', '㒻'],
  ['𧢮', '𧢮'],
  ['𧥦', '𧥦'],
  ['䚾', '䚾'],
  ['䛇', '䛇'],
  ['誠', '誠'],
  ['豕', '豕'],
  ['𧲨', '𧲨'],
  ['貫', '貫'],
  ['賁', '賁'],
  ['贛', '贛'],
  ['起', '起'],
  ['𧼯', '𧼯'],
  ['𠠄', '𠠄'],
  ['跋', '跋'],
  ['趼', '趼'],
  ['跰', '跰'],
  ['𠣞', '𠣞'],
  ['軔', '軔'],
  ['𨗒', '𨗒'],
  ['𨗭', '𨗭'],
  ['邔', '邔'],
  ['郱', '郱'],
  ['鄑', '鄑'],
  ['𨜮', '𨜮'],
  ['鄛', '鄛'],
  ['鈸', '鈸'],
  ['鋗', '鋗'],
  ['鋘', '鋘'],
  ['鉼', '鉼'],
  ['鏹', '鏹'],
  ['鐕', '鐕'],
  ['𨯺', '𨯺'],
  ['開', '開'],
  ['䦕', '䦕'],
  ['閷', '閷'],
  ['𨵷', '𨵷'],
  ['䧦', '䧦'],
  ['雃', '雃'],
  ['嶲', '嶲'],
  ['霣', '霣'],
  ['𩅅', '𩅅'],
  ['𩈚', '𩈚'],
  ['䩮', '䩮'],
  ['䩶', '䩶'],
  ['韠', '韠'],
  ['𩐊', '𩐊'],
  ['䪲', '䪲'],
  ['𩒖', '𩒖'],
  ['頩', '頩'],
  ['𩖶', '𩖶'],
  ['飢', '飢'],
  ['䬳', '䬳'],
  ['餩', '餩'],
  ['馧', '馧'],
  ['駂', '駂'],
  ['駾', '駾'],
  ['䯎', '䯎'],
  ['𩬰', '𩬰'],
  ['鱀', '鱀'],
  ['鳽', '鳽'],
  ['䳎', '䳎'],
  ['䳭', '䳭'],
  ['鵧', '鵧'],
  ['𪃎', '𪃎'],
  ['䳸', '䳸'],
  ['𪄅', '𪄅'],
  ['𪈎', '𪈎'],
  ['𪊑', '𪊑'],
  ['麻', '麻'],
  ['䵖', '䵖'],
  ['黹', '黹'],
  ['黾', '黾'],
  ['鼅', '鼅'],
  ['鼏', '鼏'],
  ['鼖', '鼖'],
  ['鼻', '鼻'],
  ['𪘀', '𪘀']
]

export default safariDictionary
