<template>
  <div
    v-if="channel"
    class="uk-width-expand"
  >
    <h3 class="uk-card-title uk-margin-remove-bottom uk-text-left uk-margin-left">
      {{ localGroup.name }}
      <button
        class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
        @click="translateName"
      >
        <font-awesome-icon :icon="['fa', 'language']" />
        <span v-if="translated['name']">
          {{ $t('tgcp.channels.show-original') }}
        </span>
        <span v-else>
          {{ $t('tgcp.channels.translate') }}
        </span>
      </button>
    </h3>
    <div class="uk-margin-remove-bottom uk-text-left uk-margin-left uk-text-meta">
      # <router-link :to="{ name: 'tgcp_channel_detail', params: { id: channel.tg_id }}">
        {{ channel.tg_id }}
      </router-link>
    </div>
    <span
      v-if="channel.username"
      class="uk-text-small uk-margin-remove-bottom uk-text-left uk-margin-left"
      style="display:block"
    >
      @<router-link
        :to="{
          name: 'nicknameResults',
          params: { nickname: channel.username }}"
      >{{ channel.username }}
      </router-link>
      <a
        :href="`https://t.me/${channel.username}`"
        target="_blank"
        rel="noopener noreferrer"
        :uk-tooltip="$t('tgcp.tasks.open-in-telegram')"
        class="uk-margin-small-left"
      >
        <font-awesome-icon :icon="['fab', 'telegram-plane']" />
      </a>
    </span>
    <p
      v-if="isRestricted"
      class="uk-text-danger uk-text-left uk-margin-left"
    >
      <span uk-icon="ban"/> {{ $t('tgcp.channel_details.marked_as_restricted') }}
    </p>
    <p
      v-if="channel.scam"
      class="uk-text-danger uk-text-left uk-margin-left"
    >
      <span uk-icon="warning"/> {{ $t('tgcp.channel_details.marked_as_scam') }}
    </p>
    <p
      v-if="channel.fake"
      class="uk-text-danger uk-text-left uk-margin-left"
    >
      <span uk-icon="warning"/> {{ $t('tgcp.channel_details.marked_as_fake') }}
    </p>
    <p
      v-if="channel.verified"
      class="uk-text-success uk-text-left uk-margin-left"
    >
      <span uk-icon="check"/> {{ $t('tgcp.channel_details.marked_as_verified') }}
    </p>
    <p
      v-if="localGroup.description"
      class="uk-text-meta uk-margin-top uk-text-left uk-margin-left uk-margin-right"
    >
      <span v-html="extractedDataDescription"/><button
        class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
        @click="translateDescription"
      >
        <font-awesome-icon :icon="['fa', 'language']" />
        <span v-if="translated.description"> {{ $t('tgcp.channels.show-original') }}</span>
        <span v-else> {{ $t('tgcp.channels.translate') }}</span></button>
      <br />
    </p>
    <div v-if="!isLoaded">
      <Loader />
    </div>
    <div
      v-if="channel.language_code"
      class="uk-list uk-list-divider uk-text-left uk-text-small uk-margin-left uk-margin-bottom"
    >
      {{ $t('tgcp.channels.language') }} <router-link :to="`/tgcp/channel?language_code=${channel.language_code}`">
        {{ langCode(channel.language_code) }}
      </router-link>
    </div>
    <div
      v-if="channel.country_codes.length"
      class="uk-list uk-list-divider uk-text-left uk-text-small uk-margin-left uk-margin-bottom"
    >
      {{ $t('tgcp.channels.countries') }} {{ channel.country_code }}
      <span
        v-for="(country_code, element_index) in channel.country_codes"
        :key="country_code"
      >
        <router-link :to="`/tgcp/channel?country_code=${country_code}`">
          {{ countryCodeToName(country_code) }}
        </router-link><span v-if="(channel.categories.length-1) > element_index">
          ,
        </span>
      </span>
    </div>
    <div
      v-if="channel.categories.length"
      class="uk-list uk-list-divider uk-text-left uk-text-small uk-margin-left uk-margin-bottom"
    >
      {{ $t('tgcp.channels.category') }}
      <span
        v-for="(category, element_index) in channel.categories"
        :key="element_index + category"
      >
        <router-link :to="`/tgcp/channel?category_id=${category}`">
          {{ categoryCodeToName(category) }}
        </router-link>
        <span v-if="(channel.categories.length-1) > element_index">
          ,
        </span>
      </span>
    </div>
    <div
      v-if="channelStat"
      class="uk-grid-small uk-margin-small-top uk-width-1-1"
      uk-grid
    >
      <div :class="`${hasMessagesDates ? 'uk-margin-left' : ''} uk-margin-bottom`">
        <ul class="uk-list uk-list-divider uk-text-left uk-text-small">
          <li v-if="channelStat.first_message_date">
            {{ $t('tgcp.channels.first_message_timestamp') }}: {{ dayjs(channelStat.first_message_date) }}
          </li>
          <li v-if="channelStat.last_message_date">
            {{ $t('tgcp.channels.last_message_timestamp') }}: {{ dayjs(channelStat.last_message_date) }}
          </li>
        </ul>
      </div>
      <div class="uk-margin-bottom uk-float-right">
        <ul class="uk-list uk-list-divider uk-text-left uk-text-small">
          <li >
            {{ $t('tgcp.channel_details.creation_date') }}: {{ channel.creation_date ? dayjs(channel.creation_date) : $t('tgcp.user_details.unknown') }}
          </li>
          <li>
            {{ $t('tgcp.channels.last_sync_timestamp') }}:
            <span v-if="channelStat.last_sync">
              {{ dayjs(channelStat.last_sync) }}
            </span>
            <span v-else>
              {{ dayjs(channelStat.last_message_date) }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import extractData from '@/utils/dataExtraction'
import Loader from '@/components/TGCP/Loader'
import translate from '@/mixins/translate'
import { langCode, countryCodeToName, categoryCodeToName } from '@/filters/globalFilters'

export default {
  name: 'ChannelInfo',
  components: {
    Loader
  },
  mixins: [ translate ],
  props: {
    id: {
      required: true,
      type: Number
    },
    channel: {
      required: true
    }
  },
  data () {
    return {
      localGroup: { ...this.channel },
      translated: {
        name: false,
        description: false
      }
    }
  },
  computed: {
    hasMessagesDates () {
      return this.channelStat.first_message_date || this.channelStat.last_message_date
    },
    channelStat () {
      if (!this.$store.state.tgcp.channels[this.id]) return
      return this.$store.state.tgcp.channels[this.id]['stats']
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['channel_' + this.id + '_stats']
    },
    extractedDataDescription () {
      if (!this.channel) return
      return extractData(this.localGroup.description)
    },
    isRestricted () {
      return this?.channel?.restricted
    }
  },
  methods: {
    langCode,
    countryCodeToName,
    categoryCodeToName,
    update () {
      const action = 'tgcp/fetchChannelStatsById'
      this.$store.dispatch(action, this.id)
    },
    async translateName () {
      const { name } = await this.translateProps(['name'], this.localGroup, this.channel)
      this.localGroup.name = name || this.localGroup.name
    },
    async translateDescription () {
      const { description } = await this.translateProps(['description'], this.localGroup, this.channel)
      this.localGroup.description = description || this.localGroup.description
    }
  },
  mounted () {
    this.update()
  }
}
</script>

