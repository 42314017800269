<template>
  <div class="ip_msisdn">
    <div class="uk-card uk-card-default uk-margin-top uk-card-body uk-text-left">
      <div class="uk-card-title">
        {{ $t('access_tools.title') }}
      </div>
      <ul class="uk-margin-top uk-list uk-text-left">
        <li class="">
          <router-link to="/access/ip_msisdn_detect">
            <span
              class="uk-margin-small-right uk-icon"
              uk-icon="icon: phone"
            />MSISDN detection phishing tool
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AccessToolsLanding',
  components: {
  },
  data () {
    return {
      timer: null
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
