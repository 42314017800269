<template>
  <div>
    <div
      class="uk-button-group uk-position-z-index uk-position-bottom-right uk-position-fixed"
    >
      <div
        style="padding-bottom: 30px"
        class="uk-position-z-index export_button"
      >
        <button
          v-if="hasSelect && messages.length"
          class="uk-button uk-button-default"
          style="padding-right: 20px"
          @click="$emit('deselect')"
        >
          {{ $t('messages.deselect-all') }}
        </button>
        <button
          :uk-toggle="`target: #toggle${type + (query.participant_type || participantStatus || '')}`"
          class="uk-button uk-button-default "
          style="padding-right: 20px"
          @click.prevent
        >
          <span
            uk-icon="icon: cloud-download"
          />
          {{ $t('tgcp.export.export_btn') }}
          <span
            v-if="messages.length"
            class="uk-text-small"
          >
            {{ messages.length }} {{ $t('csv.records') }}
          </span>
        </button>
        <div
          v-if="$i18n.locale === 'ru' && type === 'messages'"
          class="uk-inline"
        >
          <span
            class="uk-button uk-button-default"
            style="height: 100%; padding-left: 0;padding-right: 0;"
          >
            <span uk-icon="icon:  triangle-up"/>
          </span>
          <div
            uk-dropdown="mode: click;pos: top-right;flip: false;"
          >
            <ul class="uk-nav uk-dropdown-nav">
              <li class="uk-active uk-dropdown-close">
                <a
                  @click="makingFile({ format: 'txt' })"
                >
                  Экспорт в TXT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        :id="'toggle' + type + (query.participant_type || participantStatus || '')"
        ref="modal"
        uk-modal
      >
        <div
          class="uk-modal-dialog uk-modal-body uk-width-1-2"
        >
          <h2
            class="uk-modal-title"
          >
            {{ $t('tgcp.export.export_settings') }}
          </h2>
          <button
            class="uk-button uk-button-text uk-padding-remove uk-float-right"
            style="padding-left: 10px !important; padding-right: 10px !important; width: 120px;"
            @click.prevent="checkAll"
          >
            <span
              v-if="exportContext.strategy.getSchema().length === allowedFields.length"
            >
              {{ $t('tgcp.export.uncheck-all') }}
            </span>
            <span
              v-else
            >
              {{ $t('tgcp.export.check-all') }}
            </span>
          </button>
          <div
            class="uk-margin-small-top uk-width-1-1"
            style="columns: 2"
          >
            <div
              v-for="field in exportContext.strategy.getSchema()"
              v-show="field !== 'translated_message'"
              :key="field.toString()"
              class="uk-text-nowrap uk-margin-left"
            >
              <label>
                <input
                  v-model="allowedFields"
                  :value="field"
                  class="uk-checkbox"
                  type="checkbox"
                />
                {{ $t(`csv.${namespace}${typePath.$0}${typePath.$1}.${field}`) }}
              </label>
            </div>
          </div>
          <div class="uk-flex uk-flex-around uk-flex-wrap uk-flex-wrap-around uk-flex-middle uk-margin">
            <div
              v-if="exportContext.strategy.OPTIONS_SCHEMA.includes('messageLimit')"
              class="uk-flex-auto uk-margin-right uk-width-1-4"
            >
              <vueSelect
                v-model="messageContext"
                :options="contextComputed"
                label="label"
              />
            </div>
            <div class="uk-flex uk-flex-auto uk-flex-wrap uk-grid-small">
              <div
                v-if="exportContext.strategy.OPTIONS_SCHEMA.includes('includeRepliedMessage')"
                class="uk-flex-auto"
              >
                <label
                  for="include-replied"
                >
                  <input
                    id="include-replied"
                    v-model="includeRepliedMessage"
                    :value="includeRepliedMessage"
                    class="uk-checkbox"
                    type="checkbox"
                  />
                  {{ $t('tgcp.export.include_replied') }}
                </label>
              </div>
              <div
                v-if="exportContext.strategy.OPTIONS_SCHEMA.includes('translateMessages')"
                class="uk-flex-auto"
              >
                <label>
                  <input
                    v-model="translateMessages"
                    class="uk-checkbox"
                    type="checkbox"
                  />
                  {{ $t('tgcp.export.translate_messages') }}
                </label>
              </div>
              <div
                class="uk-flex-auto"
              >
                <label>
                  <input
                    v-model="localizeFields"
                    type="checkbox"
                    name="localizeFields"
                    class="uk-checkbox"
                  />
                  {{ $t('tgcp.export.localize-fields') }}
                </label>
              </div>
            </div>
            <div
              v-if="exportContext.strategy.OPTIONS_SCHEMA.includes('resolvePhoneNumbers')"
              class="uk-width-1-4"
            >
              <label>
                <input
                  v-model="resolvePhoneNumbers"
                  type="checkbox"
                  name="resolvePhoneNumbers"
                  class="uk-checkbox"
                />
                {{ $t('tgcp.export.resolve-phone-numbers') }}
              </label>
            </div>
          </div>
          <div
            v-if="type === 'messages'"
            class="search-hint"
          >
            <span
              uk-icon="icon: info;ratio: 0.5"
            /> {{ $t('tgcp.export.limit-alert') }}
          </div>
          <div class="uk-margin uk-flex uk-flex-center">
            <div>
              <div class="uk-inline">
                <span
                  class="uk-form-icon"
                  href="#"
                  uk-icon="icon: file-text"
                />
                <input
                  v-model="fileName"
                  class="uk-input"
                  type="text"
                  :placeholder="$t('file.name')"
                />
              </div>
            </div>
            <button
              class="uk-button uk-button-primary uk-form-width-small uk-form-width-medium"
              type="button"
              @click="makingFile"
            >
              {{ $t('tgcp.export.start') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import restrictions from '@/config/restrictions'
import UIkit from 'uikit'
import vueSelect from '@/components/VueSelect'
import ExportFactory from '../Factory'
import Utils from '../Utils'

export default {
  name: 'Dialog',
  components: {
    vueSelect
  },
  props: {
    namespace: {
      type: String
    },
    type: {
      type: String,
      default: ''
    },
    subtype: {
      type: String,
      default: ''
    },
    totalCount: {
      type: Number,
      default: 0
    },
    query: {
      type: Object,
      default: () => ({})
    },
    participantStatus: {
      required: false,
      type: String
    },
    participantType: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    search: {
      required: false,
      type: String
    },
    messages: {
      type: Array,
      default: () => []
    },
    hasSelect: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      messageLimit: 0,
      includeRepliedMessage: false,
      allowedFields: [],
      autoDownload: true,
      modalInstance: null,
      localizeFields: false,
      resolvePhoneNumbers: false,
      translateMessages: false,
      fileName: '',
      exportContext: null
    }
  },
  watch : {
    'search' : {
      async handler (to, from) {
        if (from !== '') {
          this.fileName = this.fileName.replace(new RegExp((to !== '' ? '' : '_search_') + from + '$'), (to !== '' ? '' : '_search_') + to)
        } else {
          if (to !== '') this.fileName = this.fileName + '_search_' + to
        }
      },
      deep: true
    }
  },
  computed: {
    parentProps () {
      return { ...this.$parent.$props }
    },
    contextComputed () {
      return [
        [0, this.$t('tgcp.export.without_context')],
        [3, 3],
        [6, 6],
        [10, 10],
        [15, 15]
      ].map(([value, label]) => ({ value, label }))
    },
    messageContext: {
      get () {
        return this.messageLimit
      },
      set (obj) {
        this.messageLimit = obj && obj.value
      }
    },
    hide () {
      const userId = this.$store.state.current_user_id
      return (
        restrictions[userId] &&
        restrictions[userId]['module'].indexOf('export') > -1
      )
    },
    typePath () {
      try {
        const type = this.type
        const typePath = type.split('.')
        return {
          $0: typePath[0] ? `.${typePath[0]}` : '',
          $1: typePath[1] ? `.${typePath[1]}` : ''
        }
      } catch (error) {
        console.debug(error)
      }
      return {
        $0: `.${this.type}`,
        $1: void 0
      }
    }
  },
  methods: {
    closeModal () {
      try {
        UIkit.modal(this.$refs.modal).hide()
      } catch (error) {
        console.debug(error)
      }
    },
    checkAll () {
      try {
        this.allowedFields.length === this.exportContext.strategy.getSchema().length
          ? this.allowedFields = []
          : this.allowedFields = this.exportContext.strategy.getSchema()
      } catch (error) {
        console.debug(error)
      }
    },
    async makingFile ({ format = 'csv' }) {
      try {
        if (
          format === 'csv' &&
          !this.allowedFields.length
        ) {
          this.$notify({
            group: 'general',
            title: this.$t('notifications.error'),
            text: this.$t('tgcp.export.all-unchecked-warning'),
            type: 'warn'
          })
          return
        }
        const Factory = new ExportFactory(this)
        this.exportContext = Factory.context
        this.exportContext.start()
        this.closeModal()
      } catch (error) {
        console.debug(error)
      }
    }
  },
  created () {
    const Factory = new ExportFactory(this)
    this.exportContext = Factory.context
  },
  mounted () {
    try {
      this.allowedFields = this.exportContext.strategy.getSchema()
      this.fileName = this.exportContext.strategy.description
      this.modalInstance = UIkit.modal(this.$refs.modal)
    } catch (error) {
      console.debug(error)
    }
  },
  beforeUnmount () {
    try {
      this.modalInstance.$destroy(true)
    } catch (error) {
      console.debug(error)
    }
  }
}
</script>

<style>
.export_button {
  background: white;
  opacity: 0.7;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  margin-right: 10px;
  bottom: 2rem;
}
.export_button:hover {
  opacity: 1;
}
.search-hint {
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
}
</style>
