<template>
  <div>
    <span
      class="uk-card-title"
    >
      {{ $t('cases.triggers.trigger-results') }}
    </span>
    <div
      v-if="notifies && notifies.length"
      class="uk-margin-top uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center"
      uk-grid
    >
      <div/>
      <div
        class="uk-inline table-view__toggle-wrapper uk-padding-remove uk-margin-remove"
      >
        <ul
          class="view-list"
          style="padding-left: 20px; margin-top: 5px"
          :value="tableViewModel ? true: null"
        >
          <li
            :class="{'uk-text-primary': !tableViewModel, 'onclick-transform': true }"
            @click="tableViewModel = false"
          >
            <i uk-icon="list"/>
            {{ $t('cases.triggers.list') }}
          </li>
          <li
            style="margin-left: 20px;"
            :class="{'uk-text-primary': tableViewModel, 'onclick-transform': true }"
            @click="tableViewModel = true"
          >
            <i uk-icon="table"/>
            {{ $t('cases.triggers.table') }}
          </li>
        </ul>
      </div>
      <div
        class="uk-text-right"
      >
        <GetReport
          class="uk-margin-small-right"
          :caseId="caseId"
          :triggerId="id"
        />
        <ClearResults
          v-if="accessLevel"
          :id="id"
          @update="update"
        />
      </div>
    </div>
    <div v-if="isLoading">
      <Loader />
    </div>
    <NotifyCard
      v-else-if="notifies && notifies.length"
      :notifies="notifies"
      :class="{ 'is-loading': isLoading }"
    />
    <div
      v-else
      class="uk-margin-top"
    >
      <RippleLoader/>
    </div>
    <div v-if="pages !== 1">
      <Paginate
        v-show="!isLoading"
        v-model="pagination.page"
        :page-count="pages"
        :click-handler="setPage"
        prev-text="<"
        next-text=">"
        container-class="uk-pagination uk-flex-center"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'CaseTriggerDetails',
  components: {
    NotifyCard: defineAsyncComponent(() => import('@/components/cases/NotifyCard')),
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader')),
    RippleLoader: defineAsyncComponent(() => import('@/components/LoaderRipple.vue')),
    Paginate,
    GetReport: defineAsyncComponent(() => import('@/components/cases/Reports/GetReportButton')),
    ClearResults: defineAsyncComponent(() => import('@/components/cases/Triggers/ClearResultsButton.vue'))
  },
  props: {
    id: {
      required: true,
      type: [Number, String]
    },
    caseId: [Number, String]
  },
  data () {
    return {
      isLoading: false,
      timer: null,
      notifies: [],
      meta: null,
      initialLoad: true,
      query: {
        limit: 3,
        offset: 0
      },
      pagination: {
        page: 1,
        lastPage: 1,
        isNext: false
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapState('cases', ['isTableView']),
    ...mapGetters('cases', [
      'accessLevel'
    ]),
    ...mapGetters('tgcp', [
      'allUsers',
      'allChannels'
    ]),
    tableViewModel: {
      get () {
        return this.isTableView || false
      },
      set (value) {
        this.setTableView(value)
      }
    },
    pages () {
      const meta = this.meta || { total: 1, limit: 1 }
      const pages = Math.ceil(meta.total / meta.limit) || 1
      return pages
    },
    isPrevPage () {
      return this.pagination.page === 1 ? 'uk-disabled' : 'uk-active'
    },
    hidePagination () {
      if (!this.pages || (this.pagination.isNext === false && this.pagination.page === 1)) return true
      else return false
    }
  },
  methods: {
    ...mapMutations('cases', [
      'setTableView'
    ]),
    ...mapActions('fetcher', [
      'fetchNotifies'
    ]),
    ...mapActions('tgcp', [
      'getBatchChats',
      'getBatchUsers'
    ]),
    ...mapActions('whatsapp', [
      'fetchGroupByID',
      'fetchUserByPhone'
    ]),
    async update () {
      if (this.initialLoad) this.isLoading = true
      try {
        clearTimeout(this.timer)
      } catch {
        this.timer = null
      }
      try {
        const { items, meta } = await this.fetchNotifies({
          id: this.id,
          page: this.pagination.page
        })
        this.notifies = items
        this.getBatchUsers({ IDs: [ ...new Set(items.map(el => el.module === 'tgcp' && el.stored && el.stored.data && el.stored.data.user_id).filter(el => el && !this.allUsers[el])) ], cache: true })
        this.getBatchChats({ IDs: [ ...new Set(items.map(el => el.module === 'tgcp' && el.stored && el.stored.data && el.stored.data.chat_id).filter(el => el && !this.allChannels[el])) ], cache: true })
        items.forEach(el => {
          if (el.module === 'wa') {
            this.fetchGroupByID(el.stored.data.group_id)
            this.fetchUserByPhone(el.stored.data.sender)
          }
        })
        this.meta = meta
      } finally {
        this.isLoading = false
        this.initialLoad = false
        this.timer = setTimeout(this.update, 10000)
      }
    },
    setPage (page) {
      this.pagination.page = page
      this.update()
    }
  },
  created () {
    this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
    this.messageSelected = null
  }
}
</script>

<style lang="scss" scoped>
.is-loading {
  filter: blur(2px);
}
.onclick-transform {
  cursor: pointer;
}
.table-view__toggle-wrapper {
  text-align: center;
  margin-top: 20px;
}

.view-list li {
  display: inline;
}
</style>
