<template>
  <div>
    <Loader v-if="isLoaded['user_' + id] === false"/>
    <div v-else>
      <div v-if="user">
        <div
          class="uk-grid"
          data-uk-grid-margin
          uk-height-match="target: > div > .uk-card"
        >
          <div
            id="generalBlock"
            class="uk-width-3-4@m"
          >
            <gu :user="user" />
          </div>
          <div
            class="uk-width-1-4@m uk-overflow-auto"
            style="max-height: 600px "
          >
            <div class="uk-card uk-card-default ">
              <h4 class="uk-card-header">
                {{ $t('forums.additional-information') }}
              </h4>
              <ul class="uk-list uk-list-divider uk-text-break uk-card-body uk-padding-remove-top uk-padding-small uk-text-left">
                <li
                  v-for="(val, key) in user.other_json_user_information"
                  :key="key"
                >
                  {{ key }}: {{ val.toString() }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="user"
        class="uk-margin-medium-top"
      >
        <ul
          uk-tab
        >
          <li
            class="uk-flex"
            :class="{
              'uk-active': activeTab === '#overview',
              'uk-disabled': !user.messages_count
            }"
            @click="activateTab('#overview')"
          >
            <a
              id="overview"
              class="uk-padding-remove"
              href="#overview"
            >
              {{ $t('forums.overview') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': activeTab === '#messages',
              'uk-disabled': !user.messages_count
            }"
            @click="activateTab('#messages')"
          >
            <a
              id="messages"
              class="uk-padding-remove"
              href="#messages"
            >
              {{ $t('forums.messages') }}
            </a>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ user.messages_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': activeTab === '#topics',
              'uk-disabled': !user.topics_count
            }"
            @click="activateTab('#topics')"
          >
            <a
              id="topics"
              class="uk-padding-remove"
              href="#topics"
            >
              {{ $t('forums.topics') }}
            </a>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ user.topics_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': activeTab === '#goods',
              'uk-disabled': !user.goods_count
            }"
            @click="activateTab('#goods')"
          >
            <a
              id="goods"
              class="uk-padding-remove"
              href="#goods"
            >
              {{ $t('forums.goods') }}
            </a>
            <span
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ user.goods_count }}
              </span>
            </span>
          </li>
        </ul>
        <ul
          v-if="user"
          class="uk-switcher"
        >
          <li
            v-if="user.messages_count"
          >
            <a
              name="overview"
            />
            <Overview
              v-if="activeTab === '#overview'"
              :id="id"
              :forumId="user.forum.id"
            />
          </li>
          <li
            v-if="user.messages_count"
          >
            <a
              name="messages"
            />
            <MessageList
              v-if="activeTab === '#messages'"
              :userId="id"
              type="user"
            />
          </li>
          <li
            v-if="user.topics_count"
          >
            <a name="topics"/>
            <TopicsList
              v-if="activeTab === '#topics'"
              :userId="id"
              type="followers"
            />
          </li>
          <li
            v-if="user.goods_count"
          >
            <a name="goods"/>
            <GoodsList
              v-if="activeTab === '#goods'"
              :userId="id"
              type="user"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import GeneralInformation from '@/components/Forums/UserDetails/GeneralInformation'
import MessageList from '@/views/Forums/MessageList'
import TopicsList from '@/views/Forums/TopicsList'
import Overview from '@/views/Forums/OverviewStats'
import GoodsList from '@/views/Forums/GoodsList'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ForumsUserDetails',
  components: {
    Loader,
    gu: GeneralInformation,
    TopicsList,
    MessageList,
    GoodsList,
    Overview
  },
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      activeTab: this.$route.hash || '#overview'
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapGetters('forums', [
      'allUsers',
      'isLoaded'
    ]),
    user () {
      let user = this.allUsers[this.id]
      return user
    }
  },
  methods: {
    ...mapActions('forums', [
      'fetchUserById'
    ]),
    activateTab (tab) {
      this.$router.push({ hash: tab })
      this.activeTab = tab
    },
    update () {
      this.fetchUserById(this.id)
    }
  },
  mounted () {
    if (this.$route.params.tab) {
      this.activeTab(this.$route.params.tab)
    }
    this.$nextTick(this.update)
  }
}
</script>
