<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      {{ $t('graph.loaded') }} {{ caseStats }}
    </div>
  </div>
</template>
<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CaseStats',
  components: {
    Loader
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      error: {
        title: false,
        description: false,
        send: false
      }
    }
  },
  computed: {
    ...mapGetters('cases', [
      'allCases', 'caseStats'
    ]),
    isLoaded: function () {
      return this.$store.state.cases.isLoaded['stats_' + this.id]
    }
  },
  methods: {
    ...mapActions('cases', [
      'getStats'
    ]),
    update () {
      let caller = this
      this.getStats({ caseId: this.id }).then(function () {
        caller.loaded = true
        // caller.timer = setTimeout(caller.update, 15000)
      })
    }
  },
  created () {
    this.update()
  }
}
</script>
