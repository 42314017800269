<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <form
        @submit.prevent="search"
      >
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
          placeholder=""
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click="search"
        >
          <span
            uk-search-icon
          />
        </button>
      </form>
      <SearchInDbHint />
    </div>
    <div
      class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
      uk-grid
    >
      <div >
        <router-link
          to="/twitter/user"
          class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.users_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.users_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.users_count') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/twitter/hashtags"
          class="uk-card uk-card-small uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.hashtags_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.hashtags_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('twitter.stats.hashtags') }}
          </h3>
        </router-link>
      </div>
      <div >
        <div
          to="/tgcp/channel"
          class="uk-card uk-card-small uk-card-secondary uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.cashtags_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.cashtags_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('twitter.stats.cashtags') }}
          </h3>
        </div>
      </div>
      <div >
        <router-link
          to="/twitter/tasks"
          class="uk-card uk-card-small uk-card-default  uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.tasks_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.tasks_count) }} ({{ numberFormat(stats.tasks_success_perc) }}%)
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.tasks_count') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/twitter/messages"
          class="uk-card uk-card-small uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.tweets_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.tweets_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.messages_count') }}
          </h3>
        </router-link>
      </div>
      <div >
        <div
          class="uk-card uk-card-small uk-card-secondary uk-card-body uk-light"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.photos_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.photos_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.photos_count') }}
          </h3>
        </div>
      </div>
    </div>
    <UsageStats
      name="twitter"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { numberFormat } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Stats',
  components: {
    UsageStats: defineAsyncComponent(() => import('@/views/UsageStats')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Loader
  },
  data () {
    return {
      searchString: ''
    }
  },
  computed: {
    ...mapGetters('twitter', [
      'stats'
    ]),
    isLoaded () {
      return this.$store.state.twitter.isLoaded['stats']
    }
  },
  methods: {
    ...mapActions({ update: 'twitter/fetchStats' }),
    numberFormat,
    search () {
      this.$router.push('/twitter/messages?search=' + this.searchString)
    }
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
