<template>
  <div :class="`uk-inline uk-position-relative ${dropdownTailClass}`">
    <button
      v-if="currentCase"
      class="uk-icon uk-text-primary add_to_profile_button"
      uk-icon="icon: plus"
      type="button"
    />
    <div
      v-if="currentCase"
      ref="dropdownControl"
      :uk-dropdown="'mode: click; offset: 0;pos:bottom-right;boundary: ' + boundary"
      @show="dropdownShownCallback"
      @hide="dropdownHiddenCallback"
    >
      <div class="dropdown_control">
        <div class="uk-flex uk-flex-between">
          <VueServerSelect
            v-if="dropdownShown"
            v-model="currentProfileId"
            class="uk-width-expand"
            :defaultOptions="[ createNewProfileItem ]"
            :loadDataPortion="loadProfiles"
            :refreshList="refreshProfileList"
          />
          <button
            v-if="dropdownShown"
            type="button"
            class="uk-icon uk-button-primary uk-width-1-4"
            :disabled="disabledDoneButton"
            @click="createProfile(card, profileNameByDefault)"
          >
            {{ $t('cases.done') }}
          </button>
        </div>
        <div
          v-if="phoneResolvable"
          class="uk-text-center uk-margin-small-top"
        >
          <label>
            <input
              v-model="resolvePhone"
              class="uk-checkbox"
              type="checkbox"
            />
            {{ $t('tgcp.user_details.resolve_phone') }}<br/>({{ $t('tgcp.resolver.warning.cost_warning') }})
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UIkit from 'uikit'
import VueServerSelect from '@/components/VueServerSelect'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'CardToProfileButton',
  components: {
    VueServerSelect
  },
  props: {
    card: {
      type: [Object, Function],
      required: true
    },
    profileNameByDefault: {
      type: String,
      required: true
    },
    phoneResolvable: {
      type: Boolean,
      default: false
    },
    userIdForPhoneResolving: {
      default: ''
    },
    userTypeForPhoneResolving: {
      type: String,
      default: ''
    },
    userProviderForPhoneResolving: {
      type: String,
      default: ''
    },
    boundary: {
      type: String,
      default: 'window'
    }
  },
  data () {
    return {
      dropdownShown: false,
      createNewProfileItem: null,
      currentProfileId: null,
      profilesInCurrentCase: [],
      searchProfilesInCase: '',
      refreshProfileList: false,
      dropdownTailClass: 'dropdown-tail-left',
      dropdownObserver: null,
      disabledDoneButton: false,
      resolvePhone: false
    }
  },
  computed: {
    ...mapGetters('cases', [
      'currentCase'
    ]),
    profilesInCase () {
      return [ this.createNewProfileItem, ...this.profilesInCurrentCase ]
    }
  },
  methods: {
    ...mapActions('cases', [
      'getProfiles',
      'attachUserCardsToProfile'
    ]),
    async createProfile (card, profileName) {
      this.refreshProfileList = false
      this.disabledDoneButton = true
      if (typeof card === 'function') {
        card = await card()
      }
      if (!card) {
        console.error('Empty card!')
        return
      }
      let root = this.$root
      const profileId = (this.currentProfileId) ? this.currentProfileId.value : -1
      try {
        let data = {
          cards: [card],
          profileName: profileName,
          caseId: this.currentCase,
          profileId: profileId,
          user: null
        }

        if (this.phoneResolvable && this.resolvePhone) {
          data.user = {
            provider: this.userProviderForPhoneResolving,
            id: this.userIdForPhoneResolving,
            type: this.userTypeForPhoneResolving
          }
        }
        const res = await this.attachUserCardsToProfile(data)
        root.$notify({
          group: 'general',
          title: res.status === 'created' ? root.$t('cases.profile.profile-was-created') : root.$t('cases.profile.profile-was-updated'),
          type: 'success',
          ignoreDuplicates: true,
          data: {
            primaryCallback: () => Promise.resolve().then(() => {
              root.$router.push(`/cases/${this.currentCase}/profile/${res.profileId}`)
            }),
            primaryText: root.$t('cases.profile.go-to-profile')
          }
        })
      } catch (e) {
        root.$notify({
          group: 'general',
          title:  root.$t('cases.profile.failed'),
          type: 'error',
          ignoreDuplicates: true,
          text: e.message
        })
      } finally {
        this.disabledDoneButton = false
        this.profilesInCurrentCase = []
        const dropdown = this.$refs['dropdownControl']
        if (dropdown) {
          UIkit.dropdown(dropdown).hide(false)
        }
      }
    },
    async loadProfiles (search, offset = 0, count = 20) {
      try {
        this.refreshProfileList = false
        let profilesResponse
        if (!search) {
          profilesResponse = await this.getProfiles({ query: { offset: offset, limit: count } })
        } else {
          profilesResponse = await this.getProfiles({ query: { search: search, offset: offset, limit: count } })
        }
        if (profilesResponse && profilesResponse.length > 0) {
          return profilesResponse[0].map(item => {
            return { value: parseInt(item.profile_id), label: item.seed_data }
          })
        } else {
          return []
        }
      } catch (e) {
        console.error(e)
      }
      return []
    },
    dropdownShownCallback () {
      this.dropdownShown = true
      const dropdown = this.$refs['dropdownControl']
      if (dropdown) {
        this.dropdownObserver = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
              const left = parseInt(dropdown.style.left.replace('px', ''))
              if (left === 0) {
                this.dropdownTailClass = 'dropdown-tail-left'
              } else {
                this.dropdownTailClass = 'dropdown-tail-right'
              }
              const top = parseInt(dropdown.style.top.replace('px', ''))
              if (top >= 0) {
                this.dropdownTailClass += ' dropdown-tail-bottom'
              } else {
                this.dropdownTailClass += ' dropdown-tail-top'
              }
            }
          })
        })

        this.dropdownObserver.observe(dropdown, {
          attributes: true,
          attributeFilter: ['style']
        })
      }
    },
    dropdownHiddenCallback () {
      this.dropdownShown = false
      if (this.dropdownObserver) {
        this.dropdownObserver.disconnect()
      }
    }
  },
  mounted () {
    this.currentProfileId = {
      value: -1,
      label: this.$t('cases.profile.create-profile')
    }
    this.createNewProfileItem = this.currentProfileId
  }
}
</script>

<style scoped>
.dropdown_control {
  width: 270px;
}
.add_to_profile_button {
  width: 30px;
  height: 30px;
}
.add_to_profile_button.uk-open {
  position: relative;
  z-index: 10000;
  background-color: white;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}
.dropdown-tail-bottom > .add_to_profile_button.uk-open {
  border-radius: 5px 5px 0 0;
}
.dropdown-tail-bottom > .add_to_profile_button.uk-open:after {
  background-color: #fff;
  height: 20px;
  content: "";
  position: absolute;
  bottom: -20px;
  width: 30px;
  left: 0;
}
.dropdown-tail-bottom.dropdown-tail-left > .add_to_profile_button.uk-open:after {
  width: 34px;
}
.dropdown-tail-bottom.dropdown-tail-right > .add_to_profile_button.uk-open:after {
  width: 45px;
  left: -15px;
}
.dropdown-tail-top > .add_to_profile_button.uk-open {
  border-radius: 0 0 5px 5px;
}
.dropdown-tail-top > .add_to_profile_button.uk-open:before {
  content: "";
  position: absolute;
  background-color: #fff;
  height: 20px;
  width: 30px;
  top: -20px;
  left: 0;
}
.add_to_profile_button:focus {
  outline: none!important;
}
</style>
