<template>
  <div class="uk-card uk-card-default uk-card-small">
    <div class="uk-card-body">
      <div class="uk-width-1-1 uk-text-right">
        <AddStoredButton
          v-if="showStoredButton"
          provider="whatsapp"
          type="chat"
          :data="group"
        />
      </div>
      <div
        class="uk-grid-small uk-flex-middle"
        uk-grid
      >
        <div class="uk-width-1-6@m uk-text-center" >
          <GeneralAvatar :src="avatar"/>
        </div>
        <div class="uk-width-2-3@m">
          <h3>
            {{ localGroup.subject }}
            <button
              class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
              @click="translateSubject"
            >
              <font-awesome-icon :icon="['fa', 'language']" />
              <span v-if="translated.subject">
                {{ $t('twitter.group.show-original') }}
              </span>
              <span v-else>
                {{ $t('twitter.group.translate') }}
              </span>
            </button>
            <p class="uk-text-small uk-text-center  uk-margin-remove-top uk-margin-remove-bottom uk-text-muted">
              {{ localGroup.id }}
            </p>
          </h3>
          <p
            v-if="extractedDescription"
            class="uk-margin"
          >
            <span v-html="extractedDescription"/><button
              class="uk-button-text uk-button uk-margin-left uk-text-small uk-text-muted"
              @click="translateDescription"
            >
              <font-awesome-icon :icon="['fa', 'language']" />
              <span v-if="translated.desc"> {{ $t('twitter.group.show-original') }}</span>
              <span v-else> {{ $t('twitter.group.translate') }}</span></button></p>

          <ul class="uk-list uk-list-divider uk-text-left uk-margin-remove-top">
            <li v-if="localGroup.owner_phone">
              {{ $t('twitter.group.owner-phone') }}
              <router-link
                :to="{
                  name: 'whatsapp_user_detail',
                  params: { phone: String(normalizePhone(localGroup.owner_phone)) }}"
              >
                {{ normalizeWAPhone(localGroup.owner_phone) }}
              </router-link>
            </li>
            <li v-if="localGroup.invite_hash">
              {{ $t('twitter.group.invite-hash') }}
              <a
                :href="`https://chat.whatsapp.com/invite/${localGroup.invite_hash}`"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ localGroup.invite_hash }}
              </a>
            </li>
            <li v-if="localGroup.creation">
              {{ $t('twitter.group.creation') }}
              {{ dayjs(localGroup.creation) }}
            </li>
          </ul>
        </div>
        <div class="uk-width-1-6@m">
          <div class="">
            <ul
              v-if="localGroup.members_count"
              class="uk-list uk-list-divider uk-text-left uk-width-expand "
            >
              <li>
                <router-link
                  class="stat-link"
                  :to="{
                    name: 'whatsapp_group_details',
                    params: {
                      id: localGroup.id,
                      tab: 'members_tab'
                    }
                  }"
                  style="cursor:pointer"
                >
                  {{ $t('twitter.group.members-count') }}
                  <span class="uk-float-right">
                    {{ localGroup.members_count || '0' }}
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="stat-link"
                  :to="{
                    name: 'whatsapp_group_details',
                    params: {
                      id: localGroup.id,
                      tab: 'messages_tab'
                    }
                  }"
                  style="cursor:pointer"
                >
                  {{ $t('twitter.group.messages-count') }} <span class="uk-float-right">
                    {{ localGroup.messages_count || '0' }}
                  </span>
                </router-link>
              </li>
            </ul>

            <div
              v-else
              class="uk-margin-small-bottom"
            >
              {{ $t('twitter.group.no-data-yet') }}
            </div>
            <button
              v-if="hasJoiningButton"
              class="uk-button uk-button-default uk-width-1-1 uk-margin-small-top"
              @click="join"
            >
              <span v-if="!joining">
                {{ $t('twitter.group.join') }}
              </span>
              <Loader
                v-else
                :ratio="0.7"
              />
            </button>
            <router-link
              :to="{
                name: 'whatsapp_group_details',
                params: {
                  id: localGroup.id
                }
              }"
              class="uk-button uk-button-primary uk-width-1-1"
            >
              {{ $t('twitter.group.group-details') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import GeneralAvatar from '@/components/GeneralAvatar'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import translate from '@/mixins/translate'
import extractData from '@/utils/dataExtraction'
import { normalizeWAPhone } from '@/filters/globalFilters'

export default {
  name: 'WhatsappGroupItem',
  components: {
    Loader,
    AddStoredButton,
    GeneralAvatar
  },
  mixins: [translate],
  props: {
    group: {
      type: Object,
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      joining: false,
      localGroup: { ...this.group },
      translated: {}
    }
  },
  computed: {
    avatar () {
      if (!this.group.avatar_cdn_path) return false
      return this.$gateway(this.group.avatar_cdn_path)
    },
    hasJoiningButton () {
      try {
        return (
          // eslint-disable-next-line camelcase
          this?.localGroup?.invite_hash &&
          this?.localGroup?.joinable &&
          !this?.localGroup?.joined
        )
      } catch (error) {
        console.debug(error)
      }
      return false
    },
    extractedDescription () {
      if (typeof this.localGroup?.desc === 'string') {
        return extractData(this.localGroup.desc)
      }
      return ''
    }
  },
  methods: {
    ...mapActions('whatsapp', ['joinChat', 'recheckGroup']),
    normalizeWAPhone,
    join () {
      let link = 'https://chat.whatsapp.com/' + this.group.invite_hash
      this.joining = true
      this.joinChat(link)
        .then((res) => {
          if (!res.group_joined) { this.$notify({ group: 'general', title: this.$t('notifications.error'), text: res.errors[0], type: 'error' }) } else if (res.group_info.id) {
            this.recheckGroup(res.group_info.id)
            this.$router.push({ name: 'whatsapp_group_details', params: { id: res.group_info.id } })
          } else throw new Error(this.$t('notifications.something-went-wrong'))
        })
        .catch(err => {
          let text = 'Group is full'
          if (err.response && err.response.message) {
            text = err.response.message
          }
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text, type: 'error' })
        })
        .finally(() => {
          this.joining = false
        })
    },
    normalizePhone (value) {
      if (!value) return ''
      value = value.replace('@c.us', '')
      return value
    },
    async translateSubject () {
      const { subject } = await this.translateProps(['subject'], this.localGroup, this.group)
      this.localGroup.subject = subject || this.localGroup.subject
    },
    async translateDescription () {
      const { desc } = await this.translateProps(['desc'], this.localGroup, this.group)
      this.localGroup.desc = desc || this.localGroup.desc
    }
  }
}
</script>

<style scoped lang="scss">
.stat-link {
  cursor:pointer; 
  color: #666;
  &:hover {
    text-decoration: none;
  }
}
</style>