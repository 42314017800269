<template>
  <div>
    <Loader v-if="!isLoaded['user_' + id]"/>
    <div v-else>
      <NotFound v-if="user.answer_status==='NOT_FOUND'" />
      <div v-else>
        <div class="uk-width-1-1">
          <gi :user="user" />
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <ul uk-tab>
          <li
            class="uk-flex"
            :class="checkForTab('overview_tab')"
          >
            <a
              id="overview_tab"
              class="uk-padding-remove"
              href="#overview_tab"
              @click="activateTab('overview_tab')"
            >
              {{ $t('twitter.channel_details.overview_title') }}
            </a>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('messages_tab')"
          >
            <a
              id="messages_tab"
              class="uk-padding-remove"
              href="#messages_tab"
              @click="activateTab('messages_tab')"
            >
              {{ $t('tgcp.messages.messages') }}
            </a>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ user.messages_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="checkForTab('channels_tab')"
          >
            <a
              id="channels_tab"
              class="uk-padding-remove"
              href="#channels_tab"
              @click="activateTab('channels_tab')"
            >
              {{ $t('hoop.stats.channels') }}
            </a>
            <span
              v-if="user.channels_count"
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ user.channels_count || 0 }}
              </span>
            </span>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li
            v-if="user"
            :class="checkForTab('overview_tab')"
          >
            <a name="overview_tab"/>
            <uthg :id="id" />
            <udg :id="id" />
            <uwg :id="id" />
            <umg :id="id" />
          </li>
          <li
            v-if="user.messages_count"
            :class="checkForTab('messages_tab')"
          >
            <a name="messages_tab"/>
            <MessageList
              v-if="activeTab === 'messages_tab'"
              :id="id"
              type="user"
            />
          </li>
          <li :class="checkForTab('channels_tab')">
            <a name="channels_tab"/>
            <ChannelList
              v-if="activeTab === 'channels_tab'"
              :user-id="user.id"
            />
          </li>
        </ul>
      </div>
    </div>
    <RightSidebar>
      <ProviderDetails
        v-if="detail"
        :data="detail"
        :raw="detail.raw"
      />
    </RightSidebar>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import RightSidebar from '@/components/RightSidebar'
import UserTopChannelsGraph from '@/components/Hoop/UserDetails/UserTopChannelsGraph'
import UserDayGraph from '@/components/Hoop/UserDetails/UserDayGraph'
import UserWeekGraph from '@/components/Hoop/UserDetails/UserWeekGraph'
import UserYearGraph from '@/components/Hoop/UserDetails/UserYearGraph'
import GeneralInformation from '@/components/Hoop/UserDetails/GeneralInformation'
import MessageList from '@/views/Hoop/MessageList'
import ChannelList from '@/views/Hoop/ChannelList'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'HoopUserDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    ProviderDetails: defineAsyncComponent(() => import('@/components/ProviderDetails')),
    Loader,
    uthg: UserTopChannelsGraph,
    udg: UserDayGraph,
    uwg: UserWeekGraph,
    umg: UserYearGraph,
    gi: GeneralInformation,
    MessageList,
    ChannelList,
    RightSidebar
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      detail: null
    }
  },
  watch: {
    id (to) {
      this.update()
    },
    activeTab (newTab) {
      if (['follows_tab', 'followers_tab'].includes(newTab)) {
        this.$refs[newTab].click()
      }
    }
  },
  computed: {
    ...mapGetters('hoop', [
      'allUsers',
      'isLoaded'
    ]),
    activeTab () {
      return this.$route.query.tab || 'overview_tab'
    },
    user () {
      return this.allUsers[this.id]
    },
    isLoadedStats () {
      return this.isLoaded['user_' + this.id + '_stats']
    }
  },
  methods: {
    ...mapActions('hoop', [ 'fetchForceUserById', 'fetchForceUserStatsById' ]),
    checkForTab (tabName) {
      const activeTab = this.activeTab
      if (tabName === activeTab) {
        return 'uk-active'
      }
      return ''
    },
    activateTab (tab) {
      if (tab) {
        this.$router.push({ query: { tab } })
      }
    },
    update () {
      this.fetchForceUserById(this.id)
    },
    showSidebar (value) {
      this.detail = value
    }
  },
  mounted () {
    this.update()
  }
}
</script>
