<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="searchHandler"
            >
              <span uk-search-icon />
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('twitter.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!hidePagination">
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center uk-margin-small-top"
        />
      </div>
      <template v-if="!loaded">
        <div class="uk-margin-medium-top">
          <Loader />
        </div>
      </template>
      <template v-else>
        <Item
          v-for="message in messages"
          :key="message.id"
          :id="`message_${message.id}`"
          data-item="message"
          :message="message"
          class="uk-margin-top"
        />
      </template>
      <NotFound v-if="loaded && messages.length === 0" />
      <div v-if="!hidePagination">
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="id"
      :search="query.search"
      namespace="hoop"
      type="messages"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'

export default {
  name: 'MessageList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Hoop/MessageList/Item')),
    Paginate,
    vueSelect,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'channel'].includes(val)
    },
    subtype: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      messages: [],
      query: {
        limit: 20,
        offset: 0,
        order_by: this.$route.query.order_by || 'timestamp',
        order_type: this.$route.query.order_type || 'desc',
        search: this.$route.query.search || '',
        bbox: this.$route.query.bbox || null
      },
      map: false,
      pagination: {
        page: 1,
        total: 0
      },
      selectedMessages: []
    }
  },
  watch: {
    id () {
      return this.update()
    },
    map (val) {
      if (!val) {
        this.query.bbox = null
        this.searchHandler()
      }
    },
    '$route.params.messageId': {
      immediate: false,
      async handler () {
        await this.update()
        await this.$nextTick()
        const item = document.querySelector(`[id=message_${this.$route.params.messageId}]`)
        if (item && this.$route.params.messageId) {
          let all = document.querySelectorAll('[data-item="message"]')
          all.forEach(el => {
            el.style.transition = 'none'
            el.style.outline = 0
          })
          item.style.transition = 'all .5s'
          item.scrollIntoView({ behavior: 'smooth' })
          item.style.outline = '2px solid lightblue'
        }
      }
    }
  },
  computed: {
    ...mapGetters('hoop', ['allMessages', 'isLoaded']),
    hasExport () {
      try {
        return (this.type !== 'all' || (this.query.search || this.query.bbox)) && this.messages.length
      } catch (error) {
        console.debug(error)
      }
      return false
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.searchHandler()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      const orderList = ['timestamp', 'cdate']
      return orderList.map(word => ({ label: this.$t('hoop.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('twitter.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('twitter.sort.sort_type_desc') }
      ]
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.type !== 'all' ? this.isLoaded[`messages_${this.type}_${this.id}`] : this.isLoaded['messages']
    },
    userOrChat () {
      const { id, type } = this
      return type + (id ? '_' + id : '')
    },
    typedId () {
      const { type, id } = this
      const typedId = String
      typedId.prototype.type = type
      if (id) return typedId(id)
      return ''
    }
  },
  methods: {
    ...mapActions('hoop', ['fetchAllMessages', 'fetchMessageById', 'addParamToQuery', 'fetchUserMessages', 'fetchHashtagMessages', 'fetchMessageLink']),
    scrollMethod (message) {
      const to = {
        name: 'hoop_channel_details',
        params: {
          id: message.channel_id,
          messageId: message.id
        },
        hash: '#messages_tab'
      }
      const item = document.querySelector(`[id=message_${message.id}]`)
      if (item) {
        let all = document.querySelectorAll('[data-item="message"]')
        all.forEach(el => {
          el.style.transition = 'none'
          el.style.outline = 0
        })
        item.style.transition = 'all .1s'
        item.scrollIntoView({ behavior: 'smooth' })
        item.style.outline = '3px solid lightblue'
      } else {
        this.$router.push(to)
      }
    },
    searchHandler () {
      this.submit({ page: 1 })
      this.pagination.page = 1
    },
    submit () {
      const params = {
        limit: 20,
        offset: 0,
        order_by: this.query.order_by,
        order_type: this.query.order_type,
        search: this.query.search,
        bbox: this.query.bbox
      }
      this.query = params
      this.update()
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setPagination (messages) {
      this.messages = messages.messages
      this.pagination.total = messages.meta.results_count
    },
    async update () {
      let data = {
        id: this.id,
        query: this.query
      }
      const messageId = this.$route.params.messageId
      if (messageId && !data.query.offset) {
        const obj = await this.fetchMessageLink({ messageId, chatId: this.id })
        data = { ...data, query: { ...data.query, offset: obj.message_pagination.offset }}
      }
      if (this.subtype === 'channel') data.query.channel_id = this.id
      const opts = {
        user: ['fetchUserMessages', data],
        hashtag: ['fetchAllMessages', data.query],
        all: ['fetchAllMessages', data.query]
      }
      const [method, payload] = opts[this.type] || []
      if (!method) return
      try {
        if (this.type === 'all' && this.subtype !== 'channel') {
          await this.$router.push({ query: { ...data.query } })
        }
      } catch (error) {
        console.debug(error)
      }
      return this[method](payload)
        .then(messages => this.setPagination(messages))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    },
    handleError () {
      this.messages = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    mapSelectHandler (val) {
      this.query.bbox = val._southWest.lat.toFixed(4) + ',' + val._southWest.lng.toFixed(4) + ',' + val._northEast.lat.toFixed(4) + ',' + val._northEast.lng.toFixed(4)
      this.searchHandler()
    },
    mapDeselectHandler () {
      this.query.bbox = null
      this.searchHandler()
    }
  },
  created () {
    globalThis.eventBus.$on('scroll', payload => {
      this.scrollMethod(payload)
    })
    if (this.$route.query.offset) {
      this.setPage(Math.floor(this.$route.query.offset / this.query.limit + 1))
    } else {
      this.update()
    }
    if (this.$route.query.bbox) {
      this.map = true
    }
  },
  async updated () {
    await this.$nextTick()
    const item = document.querySelector(`[id=message_${this.$route.params.messageId}]`)
    if (item && this.$route.params.messageId) {
      await this.$nextTick()
      let all = document.querySelectorAll('[data-item="message"]')
      all.forEach(el => {
        el.style.transition = 'none'
        el.style.outline = 0
      })
      item.style.transition = 'all .5s'
      item.scrollIntoView({ behavior: 'smooth' })
      item.style.outline = '2px solid lightblue'
    }
  }
}
</script>
