<template>
  <svg
    title="Threema"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    fill="currentColor"
  >
    <g id="surface77660">
      <path
        style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
        d="M 8 4 C 5.792969 4 4 5.792969 4 8 L 4 40 C 4 42.207031 5.792969 44 8 44 L 39.972656 44 C 41.050781 44 42.0625 43.578125 42.820312 42.808594 C 43.578125 42.039062 43.988281 41.019531 43.972656 39.972656 L 44 7.945312 C 43.96875 5.769531 42.175781 4 40 4 Z M 24 8 C 31.730469 8 38 12.925781 38 19 C 38 25.074219 31.730469 30 24 30 L 19.554688 30 C 16.222656 30 10 32 10 32 C 10 32 14 28.222656 14 27.113281 C 14 26.886719 13.808594 26.609375 13.542969 26.296875 C 12.710938 25.5625 12.007812 24.742188 11.449219 23.851562 C 10.664062 22.746094 10 21.359375 10 19.554688 L 10.035156 19.5625 C 10.023438 19.375 10 19.191406 10 19 C 10 12.925781 16.269531 8 24 8 Z M 23.757812 12.007812 C 21.664062 12.132812 20 13.875 20 16 L 20 18 C 18.894531 18 18 18.894531 18 20 L 18 24 C 18 25.105469 18.894531 26 20 26 L 28 26 C 29.105469 26 30 25.105469 30 24 L 30 20 C 30 18.894531 29.105469 18 28 18 L 28 16.226562 C 28 14.234375 26.644531 12.378906 24.679688 12.054688 C 24.367188 12.003906 24.058594 11.988281 23.757812 12.007812 Z M 24 14 C 25.105469 14 26 14.898438 26 16 L 26 18 L 22 18 L 22 16 C 22 14.898438 22.894531 14 24 14 Z M 16 36 C 17.105469 36 18 36.894531 18 38 C 18 39.105469 17.105469 40 16 40 C 14.894531 40 14 39.105469 14 38 C 14 36.894531 14.894531 36 16 36 Z M 24 36 C 25.105469 36 26 36.894531 26 38 C 26 39.105469 25.105469 40 24 40 C 22.894531 40 22 39.105469 22 38 C 22 36.894531 22.894531 36 24 36 Z M 32 36 C 33.105469 36 34 36.894531 34 38 C 34 39.105469 33.105469 40 32 40 C 30.894531 40 30 39.105469 30 38 C 30 36.894531 30.894531 36 32 36 Z M 32 36 "
      />
    </g>
  </svg>
</template>
