import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class TwitterFriendsUser extends Base {
  schema = Schemas.twitter.user
  OPTIONS_SCHEMA = ['id', 'query', 'allowedFields', 'localizeFields']
  fetch = async ({ dispatch, options }) => {
    const { id, query, users } = options
    if (!!users && users.length) {
      return users
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100,
        order_by: 'follower.id'
      }
      let entries = []
      while (localQuery.offset < this.total) {
        const params = {
          query: localQuery,
          type: 'friends',
          id
        }
        const { users } = await dispatch('fetchFollow', params)
        const entry = users
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default TwitterFriendsUser
