<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              type="submit"
              class="uk-button uk-button-primary uk-search-icon"
            >
              <span uk-search-icon />
            </button>
          </div>
          <div class="uk-display-inline-block">
            {{ $t('twitter.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <template v-else>
          <UserCard
            v-for="user in users"
            :key="user.discover_anon_id"
            class="uk-margin-top"
            :user="user"
          />
        </template>
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate.vue'

export default {
  name: 'ImoUserList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    UserCard: defineAsyncComponent(() => import('@/components/Imo/UserList/UserCard')),
    Paginate,
    vueSelect,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'group'].includes(val)
    },
    participantType: {
      type: String,
      required: false,
      validator: val => [undefined, 'members', 'admins'].includes(val)
    },
    participantStatus: {
      type: String,
      required: false,
      validator: val => [undefined, 'former'].includes(val)
    }
  },
  data () {
    return {
      users: [],
      orderList: ['sync_date'],
      searchList: [null, 'name', 'description', 'tags'],
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'sync_date',
        order_type: this.$route.query.order_type || 'desc',
        search: this.$route.query.search || ''
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  watch: {
    id () {
      return this.update()
    }
  },
  computed: {
    ...mapGetters('imo', ['isLoaded']),
    hasDataToView () {
      return this.users.length > 0
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded['users_' + this.id] : this.isLoaded['users']
    },
    currentOrderBy: {
      get () {
        return this.order
      },
      set (obj) {
        this.order = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return [
        { value: 'asc-sync_date', label: this.$t('imo.sort.sync_date_asc') },
        { value: 'desc-sync_date', label: this.$t('imo.sort.sync_date_desc') }
      ]
    },
    order: {
      get () {
        return this.query.order_type + '-' + this.query.order_by
      },
      set (value) {
        [this.query.order_type, this.query.order_by] = value.split('-')
      }
    }
  },
  methods: {
    ...mapActions('imo', ['fetchAllUsers', 'addParamToQuery', 'fetchGroupUsers']),
    async init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      await this.update(true)
    },
    async setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      await this.update()
    },
    setData (users) {
      this.users = users.data
      this.pagination.total = users.meta.results_count
    },
    searchHandler () {
      this.setPage(1)
    },
    handleError () {
      this.users = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    async update () {
      if (this.type === 'all')
        await this.addParamToQuery(this.query)
      this.loadUsers()
    },
    loadUsers () {
      let { type, participantType, participantStatus } = this
      const data = {
        id: this.id,
        query: { ...this.query },
        path: (participantType ? `/${participantType}` : '') + (participantStatus ? `/${participantStatus}` : '')
      }
      const opts = {
        all: ['fetchAllUsers', data.query],
        group: ['fetchGroupUsers', data]
      }
      const [method, payload] = opts[type]
      
      return this[method](payload)
        .then(users => this.setData(users))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>
