<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <form
        @submit.prevent="search"
      >
        <input
          v-model.trim="searchString"
          class="uk-input uk-form-width-medium uk-form-width-large"
          type="text"
          placeholder=""
        />
        <VueSelect
          v-model="currentSearchType"
          :options="searchTypeTranslated"
          label="label"
          class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
        />
        <button
          class="uk-button uk-button-primary uk-search-icon"
          @click="search"
        >
          <span
            uk-search-icon
          />
        </button>
      </form>
      <SearchInDbHint />
    </div>
    <div
      class="uk-child-width-1-3@s uk-child-width-1-3@m uk-grid-match "
      uk-grid
    >
      <div >
        <router-link
          to="/hoop/users"
          class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.users_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.users_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.users_count') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/hoop/channels"
          class="uk-card uk-card-small uk-card-secondary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.channels_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.channels_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('hoop.stats.channels') }}
          </h3>
        </router-link>
      </div>
      <div >
        <router-link
          to="/hoop/messages"
          class="uk-card uk-card-small uk-card-primary uk-card-hover uk-card-body uk-dark"
        >
          <Loader
            v-if="!isLoaded"
          />
          <h3
            v-else-if="!stats.messages_count"
            class="uk-card-title"
          >
            -
          </h3>
          <h3
            v-else
            class="uk-card-title"
          >
            {{ numberFormat(stats.messages_count) }}
          </h3>
          <h3
            class="uk-text-muted uk-margin-remove-top"
          >
            {{ $t('tgcp.stats.messages_count') }}
          </h3>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from '@/components/VueSelect'
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { numberFormat } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Stats',
  components: {
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Loader,
    VueSelect
  },
  data () {
    return {
      searchString: '',
      searchType: 'messages'
    }
  },
  computed: {
    ...mapGetters('hoop', [
      'stats'
    ]),
    currentSearchType: {
      get () {
        return this.searchType
      },
      set (obj) {
        this.searchType = obj && obj.value
        this.update()
      }
    },
    searchTypeTranslated () {
      return [
        { value: 'messages', label: this.$t('search.messages') },
        { value: 'channels', label: this.$t('search.channels') },
        { value: 'users', label: this.$t('search.users') }
      ]
    },
    isLoaded () {
      return this.$store.state.hoop.isLoaded['stats']
    }
  },
  methods: {
    numberFormat,
    search () {
      this.$router.push('/hoop/' + this.searchType + '?search=' + this.searchString)
    },
    ...mapActions({ update: 'hoop/fetchStats' })
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped>
a.uk-card:hover {
  text-decoration: none;
  color: #666;
}
</style>
