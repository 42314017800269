import L from 'leaflet'
import axios from 'axios'
import camIconUrl from '@/assets/mapIcons/cctv.png'

export default {
  methods: {
    getSingaporeTrafficCams () {
      // eslint-disable-next-line
      this.mapInstance ? this.mapInstance.fireEvent('dataloading') : null
      axios
        .get('https://api.data.gov.sg/v1/transport/traffic-images', {
          params: {
            date_time: new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Shanghai' })).toISOString().slice(0, -5)
          }
        })
        .then(({ data: { items } }) => {
          const locs = items[0].cameras.map(cam => {
            let marker = L.marker(L.latLng(cam.location.latitude, cam.location.longitude), { info: { ...cam } })
            marker.setIcon(L.icon({
              iconUrl: camIconUrl,
              iconSize: [32, 32],
              popupAnchor: [0, -10]
            }))
            return marker
          })
          window.singaporeTraffic.addLayers(locs, { chunkedLoading: true })
          // eslint-disable-next-line
          this.mapInstance ? this.mapInstance.fireEvent('dataload') : null
        })
        .catch(console.error)
    }
  },
  created () {
    window.singaporeTraffic = new L.MarkerClusterGroup({
      animate: false,
      iconCreateFunction: function (cluster) {
        let count = cluster.getChildCount()

        let c = ' singapore-traffic-cams-marker-cluster'
        if (count >= 10000) {
          count = Math.floor(count / 1000) + 'k'
        }

        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        })
      }
    })
  },
  beforeUnmount () {
    if (window.singaporeTraffic) {
      window.singaporeTraffic.clearLayers()
    }
  }
}
