const videoStop = {
  unmounted (video) {
    try {
      if (
        video.tagName === 'VIDEO' &&
        video.childElementCount === 1 &&
        video.firstElementChild.tagName === 'SOURCE'
      ) {
        video.pause()
        video.currentTime = 0
        video.firstElementChild.src = ''
        // лоад нужен для того чтобы плеер обновил сорс и понял что сорс пуст
        video.load()
      }
    } catch (error) {
      console.debug(error)
    }
  }
}
const audioStop = {
  unmounted (audio) {
    try {
      if (
        audio.tagName === 'AUDIO' &&
        audio.childElementCount === 1 &&
        audio.firstElementChild.tagName === 'SOURCE'
      ) {
        audio.pause()
        audio.currentTime = 0
        audio.firstElementChild.src = ''
        // лоад нужен для того чтобы плеер обновил сорс и понял что сорс пуст
        audio.load()
      }
    } catch (error) {
      console.debug(error)
    }
  }
}

export default {
  install: (app) => {
    app.directive('video-stop', videoStop)
    app.directive('audio-stop', audioStop)
  }
}
