import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class TiktokUsersAll extends Base {
  schema = Schemas.tiktok.user
  OPTIONS_SCHEMA = ['query', 'allowedFields', 'localizeFields', 'parentProps']
  fetch = async ({ dispatch, options }) => {
    const { parentProps: {
        id, type
      }, query, users } = options
    if (!!users && users.length) {
      return users
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      if (id && (type === 'follower' || type === 'friends')) {
        localQuery.order_by = `follower.${query.order_by}`
      }
      if (id && type === 'follows') {
        localQuery.order_by = `${type}.${query.order_by}`
      }
      let entries = []
      while (localQuery.offset < this.total) {
        let users = []
        if (type === 'all')
            users = (await dispatch('fetchAllUsers', {...localQuery, isExport: true})).users
        else if (type === 'follower' || type === 'friends' || type === 'follows')
            users = (await dispatch('fetchFollow', { id, type: type, query: localQuery, isExport: true })).users
        else if (type === 'hashtag')
            users = (await dispatch('fetchUsersByHashtag', { id, query: localQuery, isExport: true })).users
        const entry = users
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
      }
      return entries
    }
  }
}

export default TiktokUsersAll
