<template>
  <div class="web_sources uk-margin-top uk-width-1-1">
    <div
      class="uk-card uk-card-default uk-card-body uk-height-viewport"
      style="z-index: 0;"
    >
      <input
        type="button"
        class="uk-button uk-button-primary uk-align-right "
        :value="$t('web.add-new-source')"
        @click="$router.push({
          name: 'web_sources_add'
        })"
      />
      <div>
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
        >
          <div
            class="uk-display-inline-block"
          >
            <input
              v-model.trim="query.search"
              :disabled="loading"
              class="uk-input uk-width-auto"
              :placeholder="$t('instagram.feed.search-placeholder')"
              type="text"
            />
            <button
              :disabled="loading"
              class="uk-button uk-button-primary"
              uk-search-icon
              @click.prevent="searchHandler"
            />
          </div>
          <div
            class="uk-display-inline-block"
          >
            <div>
              {{ $t('instagram.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :disabled="loading"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
              <vueSelect
                v-model="currentOrderType"
                :disabled="loading"
                :options="orderTypeTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="pagination.total > query.limit"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="searchHandler"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center uk-width-1-1"
        />
      </div>
      <div
        v-if="loading"
        class="uk-margin-top"
      >
        <Loader/>
      </div>
      <WebSourcesTable
        v-else
        :sources="sources"
        :isLoading="isLoading"
      />
      <div
        v-if="pagination.total > query.limit && !loading"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="searchHandler"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center uk-width-1-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import vueSelect from '@/components/VueSelect'
import { defineAsyncComponent } from 'vue'
import Paginate from '@/components/Paginate'

export default {
  name: 'Websources',
  components: {
    Loader,
    vueSelect,
    WebSourcesTable: defineAsyncComponent(() => import('@/components/web/WebSourcesTable')),
    Paginate
  },
  data () {
    return {
      timer: null,
      language: 'all',
      loading: false,
      query: {
        search: this.$route.query.search || '',
        limit: 20,
        offset: 0,
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || 'desc',
        languages: '',
        includestats: true
      },
      pagination: {
        page: 1,
        total: 0
      },
      error: {},
      sourceType: '',
      sourceLink: '',
      sourceCountry: '',
      sourceKeywords: ''
    }
  },
  computed: {
    ...mapState([
      'is_admin',
      'current_user_id'
    ]),
    ...mapGetters('cases', [
      'webReaderSettings',
      'currentCase'
    ]),
    ...mapGetters('webreader', [
      'isLoaded'
    ]),
    currentLanguage: {
      get () {
        return this.language
      },
      set (obj) {
        this.language = obj && obj.value
      }
    },
    languagesComputed () {
      return [
        {
          value: 'all',
          label: this.$t('web.all')
        }
      ]
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return [ 'id', 'name' ].map(word => ({
        label: this.$t('web.sources.sort.' + word),
        value: word
      }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('instagram.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('instagram.users.sort.sort_type_desc') }
      ]
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.searchHandler()
      }
    },
    currentSourceType: {
      get () {
        return this.sourceType
      },
      set (obj) {
        this.sourceType = obj && obj.value
      }
    },
    sourceTypesComputed () {
      return [
        {
          value: '',
          label: this.$t('web.source-type')
        },
        {
          value: 'googlenews',
          label: this.$t('web.google-news')
        },
        {
          value: 'web',
          label: this.$t('web.web')
        },
        {
          value: 'custom',
          label: this.$t('web.custom')
        }
      ]
    },
    sources () {
      return Object.values(this.$store.state.webreader.webSources).sort((a, b) => {
        a = this.currentOrderBy === 'id' ? a.id : a.name
        b = this.currentOrderBy === 'id' ? b.id : b.name
        return this.currentOrderType === 'asc' ? a - b : b - a
      })
    },
    isLoading () {
      return !this.$store.state.webreader.sourcesLoaded
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0
        ? lastPage
        : lastPage + 1
    }
  },
  methods: {
    ...mapActions('webreader', [
      'fetchSources'
    ]),
    searchHandler (page) {
      if (isNaN(page)) page = 1
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = +page
      if (this.isParrent && !this.initial) {
        this.$router.push({ query: this.query })
      }
      if (this.initial) this.initial = false
      this.update()
    },
    async update () {
      this.loading = true
      try {
        if (JSON.stringify(this.query) !== JSON.stringify(this.$route.query)) this.$router.push({ query: this.query })
        const { meta, ids } = await this.fetchSources(this.query)
        this.initialLoad = false
        this.pagination.total = meta.results_count
        this.ids = ids
      } catch {
        this.$notify({
          group: 'general',
          title: this.$t('notifications.error'),
          text: this.$t('web.error-getting-sources'),
          type: 'error'
        })
        this.pagination.page = 1
        this.pagination.total = 0
      } finally {
        this.loading = false
      }

    }
  },
  created () {
    if (this.$route.query.offset) {
      this.searchHandler(Math.floor((this.$route.query.offset / this.query.limit) + 1))
    } else {
      this.update()
    }
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>

<style>
.card-with-scroll {
  overflow-y: scroll;
}
.card-with-scroll::-webkit-scrollbar {
  background-color: #fff;
  width: 5px;
}
.card-with-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
  width: 5px;
  border-radius: 5px;
}
.card-with-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgb(145, 145, 145);
}
.card-with-scroll {
  scrollbar-color: rgb(194, 194, 194) #fff;
  scrollbar-width: 5px;
}
.card-with-scroll {
  scrollbar-base-color: #fff;
  scrollbar-face-color: rgb(194, 194, 194);
  scrollbar-highlight-color: rgb(145, 145, 145);
}
.uk-card-hover {
  cursor: pointer;
}
</style>
