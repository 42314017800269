<script>
import TableDateCell from '@/components/Crypto/Ton/Atoms/TableDateCell'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'
import Selector from '@/components/Crypto/Ton/Atoms/Selector'
import { formatTons } from '@/utils/ton-filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TopJettonHolders',
  components: {
    TableDateCell,
    Selector,
    AddressField
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    minter: {
      type: String,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      itemCount: 10
    }
  },
  watch : {
    async itemCount (to, from) {
      await this.loadTonTopHolders({ hex: this.minter, limit: this.itemCount })
      await this.fetchBareTonAccounts(this.keys.filter(item => item.owner_address !== null).map(item => item.owner_address.hex))
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts', 'getTonMetadata']),
    minterMeta () {
      return this.minter in this.getTonMetadata ? 
        { decimals: this.getTonMetadata[this.minter].decimals, symbol: this.getTonMetadata[this.minter].symbol} : 
        { decimals: 9, symbol: ''}
    }
  },
  methods: {
    ...mapActions('crypto', ['loadTonTopHolders', 'fetchBareTonAccounts']),
    formatTons
  },
  async mounted () {
    this.fetchBareTonAccounts(this.keys.filter(item => item.owner_address !== null).map(item => item.owner_address.hex))
  }
}
</script>
<template>
  <div>
    <div 
      v-if="keys.length >= 10"
      class="uk-flex uk-width-1-1 uk-align-left uk-flex-middle uk-margin-remove-bottom" 
      style="justify-content: flex-end;"
    >
      <div class="uk-flex uk-flex-middle">
        <Selector 
          :item-count="itemCount"
          :amount="null"
          :options="[10, 25, 50, 100]"
          @setValue="(e) => itemCount = e"
        />
      </div>
    </div>
    <table class="uk-table uk-table-middle uk-margin-remove-top uk-table-striped">
      <thead>
        <tr>
          <th class="uk-width-1-3">
            {{ $t('crypto.ton.id') }}
          </th>
          <th class="uk-width-1-3">
            {{ $t('crypto.ton.wallet') }}
          </th>
          <th class="uk-table-expand uk-text-right">
            {{ $t('crypto.ton.balance') }}
          </th>
          <th 
            class="uk-table-shrink uk-text-right" 
            style="margin-right: 0.3rem;"
          >
            {{ $t('crypto.general.updated') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="acc in keys"
          :key="(acc.owner_address?.hex ?? 'minter') + minter"
        >
          <td class="uk-text-left uk-text-truncate"> 
            <AddressField 
              :addr="acc.owner_address" 
              :break_word="true"
              :full="true"
            />
          </td>
          <td class="uk-text-left uk-text-truncate">
            <AddressField 
              :addr="acc.wallet_address" 
              :break_word="true"
              :full="true"
            />
          </td>
          <td class="uk-text-right uk-text-nowrap"> 
            <div :class="{ 'diamond' : !minterMeta.symbol}">
              {{ `${formatTons(Number(acc.balance ?? 0), minterMeta.decimals)} ${minterMeta.symbol}` }}
            </div>
          </td>
          <td class="uk-padding-remove-left">
            <div v-if="acc.owner_address.hex in getTonAccounts">
              <TableDateCell :date-time="getTonAccounts[acc.owner_address.hex].updated_at"/>
            </div>
            <div v-else>
              -
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.diamond::after {
    content: '💎';
    filter: grayscale(100%);
}
</style>