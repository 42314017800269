import { apiRequest, getQueryString } from '@/utils/api'
import config from '@/config'
import router from '@/router'

const { servers: { opencorporate } } = config

export default {
  namespaced: true,
  state: {
    isLoaded: {},
    jurisdictions: [],
    companies: {}
  },
  getters: {
    allJurisdictions: state => state.jurisdictions,
    isLoaded: state => state.isLoaded,
    allCompanies: state => state.companies
  },
  mutations: {
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    fetchAllJurisdictions: (state, jurisdictions) => {
      state.jurisdictions = jurisdictions
    },
    fetchOrganizationById: (state, company) => {
      state.companies[company.company_number] = company
    }
  },
  actions: {
    fetchAllJurisdictions ({ commit, state }) {
      commit('startLoad', 'jurisdictions')
      return apiRequest(`jurisdictions`, 'GET', {}, opencorporate)
        .then(({ data: { results: { jurisdictions } = {} } = {} } = {}) => {
          const allJurisdictions = jurisdictions.map(({ jurisdiction }) => jurisdiction)
          commit('fetchAllJurisdictions', allJurisdictions)
          return allJurisdictions
        })
        .catch(console.error)
        .finally(() => commit('stopLoad', 'jurisdictions'))
    },
    fetchOrganizationById ({ commit, state }, { jurisdictionCode, companyNumber }) {
      return new Promise((resolve, reject) => {
        if (state.companies[companyNumber]) return resolve(state.companies[companyNumber])
        commit('startLoad', 'organization_' + companyNumber)
        apiRequest(`companies/${jurisdictionCode}/${companyNumber}`, 'GET', {}, opencorporate)
          .then(res => {
            var company = res.data.results.company
            commit('fetchOrganizationById', company)
            resolve(company)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'organization_' + companyNumber))
      })
    },
    fetchCompaniesByName ({ commit, state }, query) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'search')
        const queryString = getQueryString(query)
        apiRequest('companies/search?' + queryString, 'GET', {}, opencorporate)
          .then(res => {
            var companies = res.data.results.companies.map(el => el.company)
            delete res.data.results.companies
            var pagination = res.data.results
            resolve({ companies, pagination })
          })
          .catch(err => {
            console.debug(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', 'search'))
      })
    },
    async addParamToQuery ({ commit }, param) {
      const old = router.currentRoute.value.query
      if (typeof param === 'object') {
        Object.keys(param).forEach(key => !param[key] && delete param[key])
        param = Object.assign({}, old, param)
      }
      try {
        await router.replace({ query: param })
      } catch {

      }
    }
  }
}
