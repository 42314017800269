import App from './App.vue'
import { createApp } from 'vue'
import router from './router'
import { store } from './store/index.js'
import i18n from './i18n'

import Notifications from '@kyvg/vue3-notification'
import changeDate from './mixins/changeDate'
import 'flatpickr/dist/flatpickr.css'
import mixinDayjs from './mixins/dayjs'
import '@/assets/css/global.scss'
import FontAwesomeIcon from './icons'
import VueZoomer from 'vue-zoomer'
import VueScrollStop from '@/directives/scrollStop.js'
import VideoStop from '@/directives/videoStop.js'
import ExportData from '@/plugins/Export'
import scrollIntoView from '@/directives/scrollIntoView.js'
import MyLightbox from '@/plugins/MyLightbox.js'
import gateways from '@/config/gateways'
import eventBus from '@/plugins/eventBus.js'

import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

globalThis.eventBus = eventBus

dayjs.extend(utc)
dayjs.extend(tz)

window.dayjs = dayjs

let defaultGateway = gateways?.default

if (
  typeof defaultGateway === 'string' &&
  defaultGateway.endsWith('/')
) {
  defaultGateway = defaultGateway.substring(0, defaultGateway.length - 1)
}

const app = createApp(App)

// eslint-disable-next-line
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$gateway = value => {
  if (
    typeof value === 'string' &&
    value.match(/\s*https?:\/\//) !== null
    ) {
    return value
  }
  const hostname = location.hostname
  const gateway = gateways?.[hostname]
  if (typeof gateway === 'string') {
    let current = gateway
    if (current.endsWith('/')) {
      current = current.substring(0, current.length - 1)
    }
    return current.concat(value)
  }
  return ''.concat(defaultGateway, value)
}

app.mixin(changeDate)
app.mixin(mixinDayjs)

app.use(Notifications)
app.use(VueZoomer)
app.use(VueScrollStop)
app.use(VideoStop)
app.use(ExportData)
app.use(MyLightbox)
app.use(scrollIntoView)

app.use(router)
app.use(store)
app.use(i18n)

router.isReady().then(() => {
  app.mount('#app')
})
