<template>
  <div
    class="uk-text-left uk-container-small"
    :class="!dropdown && 'uk-align-center'"
  >
    <div
      v-if="!dropdown"
      class="uk-margin-remove-left uk-grid-small"
      uk-grid
    >
      <div
        class="uk-width-expand uk-padding-remove"
      >
        <input
          v-if="notes.length"
          v-model.trim="state.search"
          :placeholder="$t('cases.notes.search')"
          type="text"
          class="uk-input uk-width-expand"
        />
        <span
          v-else-if="dropdown"
          style="position: absolute;bottom: 22px;"
        >
          {{ $t('cases.notes.you-can-add-notes') }}
        </span>
      </div>
      <button
        v-if="accessLevel"
        class="uk-button uk-width-1-5 uk-padding-remove uk-button-primary"
        uk-icon="icon: plus"
        :uk-tooltip="$t('cases.notes.add_note_button')"
        @click="showModal"
      />
    </div>
    <Loader
      v-if="!isLoaded['notes_' + caseId]"
      class="uk-align-center uk-text-center"
    />
    <div
      v-if="isLoaded['notes_' + caseId] && filteredList.length"
      class="uk-margin-small-top uk-margin-bottom card-with-scroll"
      style="overflow-y: auto;overflow-x:auto"
      :style="dropdown && 'max-height: 50vh;'"
    >
      <div
        v-for="note in filteredList"
        :key="'note' + note.note_id"
      >
        <NoteCard
          :note="note"
          class="uk-description-list"
          :class="!dropdown && 'uk-margin-top'"
          :dropdown="dropdown"
          @update="update"
        />
      </div>
    </div>
    <div
      v-if="dropdown"
      class="uk-margin-remove-left uk-grid-small"
      uk-grid
    >
      <div
        class="uk-width-expand uk-padding-remove"
      >
        <input
          v-if="notes.length"
          v-model.trim="state.search"
          :placeholder="$t('cases.notes.search')"
          type="text"
          class="uk-input uk-width-expand"
        />
        <span
          v-else-if="dropdown"
          style="position: absolute;bottom: 22px;"
        >
          {{ $t('cases.notes.you-can-add-notes') }}
        </span>
      </div>
      <button
        v-if="accessLevel"
        class="uk-button uk-width-1-5 uk-padding-remove"
        uk-icon="icon: plus"
        :uk-tooltip="$t('cases.notes.add_note_button')"
        @click="showModal"
      />
    </div>
    <div>
      <div
        ref="addNoteModal"
        uk-modal
        @hidden="cancel"
      >
        <div class="uk-modal-dialog uk-modal-body">
          <h2 class="uk-modal-title">
            {{ $t('cases.notes.add_note_title') }}
          </h2>
          <div class="uk-modal-body">
            <textarea
              v-model.trim="state.text"
              class="uk-textarea"
              autofocus
              rows="5"
            />
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button
              class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
              @click="cancel"
            >
              {{ $t('cases.notes.cancel_button') }}
            </button>
            <button
              class="uk-button uk-button-primary"
              type="button"
              :class="{
                'uk-disabled': loading
              }"
              :disabled="loading"
              @click="confirm"
            >
              {{ $t('cases.notes.create_button') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoteCard from '@/components/cases/NoteCard'
import UIkit from 'uikit'
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotesList',
  components: {
    NoteCard,
    Loader
  },
  props: {
    caseId: {
      type: [ String, Number ]
    },
    dropdown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: {
        query: {
          limit: 28,
          offset: 0
        },
        text: '',
        search: ''
      },
      loading: false
    }
  },
  watch: {
    caseId () {
      this.search = ''
      this.update()
    }
  },
  computed: {
    ...mapGetters('cases', [
      'currentCase',
      'isLoaded',
      'caseNotes'
    ]),
    filteredList () {
      const notes = this.notes
      if (!this.state.search) return notes
      return Object.keys(notes).filter(key => {
        return notes[key].note_text.toLowerCase().includes(this.state.search.toLowerCase())
      }).map(key => notes[key])
    },
    accessLevel () {
      if (!this.$store.state.cases.cases[this.caseId]) return
      const accessLevel = this.$store.state.cases.cases[this.caseId].access_level
      return ['rw', 'f'].includes(accessLevel)
    },
    notes () {
      return this.caseNotes(this.caseId)
    }
  },
  methods: {
    ...mapActions('cases', [
      'fetchNotes',
      'noteAdd'
    ]),
    showModal () {
      UIkit.modal(this.$refs.addNoteModal).show()
    },
    cancel () {
      this.state.text = ''
      UIkit.modal(this.$refs.addNoteModal).hide()
    },
    async confirm () {
      this.loading = true
      try {
        await this.noteAdd({ text: this.state.text.trim(), caseId: this.caseId })
        await this.update()
        this.cancel()
      } catch {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: `Can't get notes`, type: 'error' })
      } finally {
        this.loading = false
      }
    },
    async update () {
      this.loading = true
      try {
        await this.fetchNotes({ query: this.state.query, caseId: this.caseId })
        this.state.search = ''
        this.state.text = ''
      } catch {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    UIkit.util.on(this.$refs.addNoteModal, 'show', e => {
      this.$emit('modal-shown', true)
    })
    UIkit.util.on(this.$refs.addNoteModal, 'beforehide', e => {
      this.$emit('modal-shown', false)
    })
    this.update()
  }
}
</script>

<style>
.card-with-scroll {
  overflow-y: scroll;
}
.card-with-scroll::-webkit-scrollbar {
  background-color: #fff;
  width: 5px;
}
.card-with-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
  width: 5px;
  border-radius: 5px;
}
.card-with-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgb(145, 145, 145);
}
.card-with-scroll {
  scrollbar-color: rgb(194, 194, 194) #fff;
  scrollbar-width: 5px;
}
.card-with-scroll {
  scrollbar-base-color: #fff;
  scrollbar-face-color: rgb(194, 194, 194);
  scrollbar-highlight-color: rgb(145, 145, 145);
}
</style>
