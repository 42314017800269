<template>
  <div class="mass-edit">
    <transition name="mass-edit__anim">
      <TaskOptions
        v-if="someChecked"
        :class="isLoading ? 'uk-disabled' : ''"
        class="mass-edit__options"
        :isLoading="isLoading"
        @update="massEdit"
      >
        <template v-slot:footer>
          <button
            :uk-tooltip="$t('tgcp.tasks.remove-checked')"
            :class="{ 'uk-disabled': isLoading }"
            class="uk-button uk-button-text uk-text-center remove-checked"
            type="button"
            @click.stop="deleteCheckedTasks"
          >
            <span uk-icon="trash"/>
          </button>
          <button
            :uk-tooltip="$t('tgcp.tasks.clear-selected')"
            :class="{ 'uk-disabled': isLoading }"
            class="uk-button uk-button-text uk-text-center clear-selected"
            type="button"
            @click.stop="clearSelectedTasks"
          >
            <span uk-icon="close"/>
          </button>
        </template>
      </TaskOptions>
      <div
        v-else-if="isLoading"
        class="uk-flex my-margin-top"
      >
        <Loader
          class="uk-margin-auto-vertical"
          :ratio=".7"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UIkit from 'uikit'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'MassEditWA',
  components: {
    TaskOptions: defineAsyncComponent(() => import('@/components/WhatsApp/Tasks/TaskOptions')),
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  props: {
    checkedTasks: Array,
    currentPage: Number
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('whatsapp', ['allListTasks', 'allTasks']),
    checkedTasksComputed () {
      return this.checkedTasks.map(id => this.allTasks[id])
    },
    isActiveAll () {
      const param = 'is_active'
      const [avg, len] = this.checkTheSameTasksOption(param)
      return len === 1 ? avg : false
    },
    someChecked () {
      if (!this.allTasks || !this.tasksOnPage || !this.checkedTasks.length) return
      return this.tasksOnPage.some(taskId => this.checkedTasks.includes(taskId))
    },
    isAllChecked: {
      get () {
        if (!this.allTasks || !this.tasksOnPage || !this.checkedTasks.length) return
        return this.tasksOnPage.every(taskId => this.checkedTasks.includes(taskId))
      },
      set () {
        if (this.isAllChecked) {
          this.checkedTasks = []
        } else {
          this.checkedTasks = this.tasksOnPage
        }
      }
    },
    tasksOnPage () {
      const task = this.allListTasks[this.currentPage]
      if (!task || !task.length) return false
      return task.length === this.$route.query.limit ? task.slice(0, -1) : task
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['tasks']
    }
  },
  methods: {
    ...mapActions('whatsapp', ['editTask', 'deleteTask']),
    checkTheSameTasksOption (param) {
      const checkedTasks = this.checkedTasks
      const len = checkedTasks.length
      const mapped = checkedTasks.map(id => this.getTask(id)[param])
      const uniq = new Set(mapped)
      for (const el of uniq) {
        if (el === null || (el >= 0 && el <= 9999999)) {
          return [el, uniq.size]
        }
      }
      return [-1, len]
    },
    getTask (id) {
      return id && this.allTasks[id]
    },
    async massEdit (options, ids = this.checkedTasks) {
      try {
        this.isLoading = true
        const promises = ids.map(id => this.editTask({ id, ...options }))
        const res = await Promise[Promise.allSettled ? 'allSettled' : 'all'](promises)
        const success = res.filter(({ status }) => status === 'fulfilled').length
        const failed = res.length - success
        let failReason = ''
        for (const { status, reason: { response: { data } = {} } = {} } of res) {
          if (status === 'rejected' && data && '' + JSON.stringify(data).match('Synchronization period limit reached')) {
            failReason = this.$t('tgcp.tasks.synchronization-period-limit-reached')
          }
        }
        let text = `${this.$i18n.t('tgcp.tasks.tasks-was-edited')}: ${success}.`
        if (failed) text += ` ${this.$i18n.t('tgcp.tasks.tasks-was-not-changed')}: ${failed} (${failReason})`
        const type = success && failed ? 'info' : failed ? 'error' : 'success'
        this.$notify({ group: 'general', title: this.$i18n.t('tgcp.tasks.result'), text, type })
        await this.$emit('update')
        return res
      } catch (err) {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err && err.message, type: 'error' })
      } finally {
        this.isLoading = false
      }
    },
    async deleteCheckedTasks () {
      await UIkit.modal.confirm(this.$t('tgcp.tasks.confirm_batch_delete_msg', { n: this.checkedTasks.length }))
      try {
        const promises = this.checkedTasks.map(async taskId => {
          const promisesInner = [this.deleteTask(taskId)]
          const resInner = await Promise[Promise.allSettled ? 'allSettled' : 'all'](promisesInner)
          if (resInner.every(({ status }) => status === 'fulfilled')) return
          throw new Error(taskId)
        })
        this.isLoading = true
        const res = await Promise[Promise.allSettled ? 'allSettled' : 'all'](promises)
        const success = res.filter(({ status }) => status === 'fulfilled').length
        const failed = res.length - success
        let text = `${this.$i18n.t('tgcp.tasks.tasks-was-removed')}: ${success}.`
        if (failed) text += ` ${this.$i18n.t('tgcp.tasks.tasks-was-not-removed')}: ${failed}`
        this.$notify({ group: 'general', title: this.$i18n.t('tgcp.tasks.result'), text, type: 'info' })
        this.$emit('update', true)
        this.$emit('delete')
      } catch (err) {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err && err.message, type: 'error' })
      } finally {
        this.isLoading = false
      }
    },
    checkChanged (value, task) {
      if (value) {
        return this.checkedTasks.push(task)
      }
      this.checkedTasks = this.checkedTasks.filter(taskId => taskId !== task)
    },
    clearSelectedTasks () {
      this.$emit('clearSelectedTasks')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-margin-top {
  margin-top: 55px
}
.clear-selected,
.remove-checked {
  height:30px;
  width:30px;
}
.mass-edit {
  z-index: 0;
  max-width: 30px;
  display: flex;
    position: sticky;
    padding: 0;
    top: 332px;
    margin-left: -35px;
    margin-bottom: 100px;
    float: left;
  &__options {
    flex-direction: column;
  }
  &__anim {
    transition: 3s;
    &-leave-from, &-leave-active {
      left: -1000px;
      position: absolute;
    }
    &-enter-from, &-enter-active{
      transform: scaleY(0) translateZ(0);
      opacity: 0;
    }
    &-enter-active {
      transition: all .2s cubic-bezier(.55, .085, .68, .53);
    }
  }
}
</style>
