export default function ({ value }) {
  try {
    if (!value) return
    const SELECTOR = `[data-scroll-into-view="${value}"]`
    const yOffset = -100
    const element = document.querySelector(SELECTOR)
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset
    if (!element) return
    window.scrollTo({top: y, behavior: 'smooth'})
    element.animate([
      { boxShadow: '0 0 0 0 rgba(30, 135, 240, .4)', offset: 0 },
      { boxShadow: '0 0 0 5px rgba(30, 135, 240, 0)', offset: .7 },
      { boxShadow: '0 0 0 0 rgba(30, 135, 240, 0)', offset: 1 }
    ], {
      duration: 1200,
      iterations: 5
    })
  } catch (error) {
    console.debug(error)
  }
}
