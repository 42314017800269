/**
 * @typedef {null|(dataURL: string) => void 0} Onload
 */
class InputedFilesReader {
  /**
   * @type {FileReader}
   */
  fileReader = null
  /**
   * @type {Onload}
   */
  onload = null
  constructor () {
    try {
      this.fileReader = new FileReader()
      if (!this.fileReader) {
        console.debug('file reader is null')
        return
      }
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @param {File} file - File.
   * @return {void}
   */
  read (file) {
    try {
      if (!file) {
        console.debug('file is null')
        return
      }
      if (!this?.fileReader) {
        console.debug('file reader is null')
        return
      }
      this.fileReader.onload = this.onload
      this.fileReader.readAsDataURL(file)
    } catch (error) {
      console.debug(error)
    }
  }
}

export default InputedFilesReader
