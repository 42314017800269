<template>
  <div>
    <h3>
      {{ $t('tgcp.tasks.import_task_title') }}
    </h3>
    <div v-if="total > 0">
      {{ $t('tgcp.tasks.upload_results') }}
      <table class="uk-table">
        <tr>
          <th>
            {{ $t('twitter.tasks.total-tasks') }}
          </th>
          <td>
            {{ total }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('twitter.tasks.added-tasks') }}
          </th>
          <td>
            {{ added }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('twitter.tasks.duplicated-tasks') }}
          </th>
          <td>
            {{ duplicates }}
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('twitter.tasks.failed-tasks') }}
          </th>
          <td>
            {{ error }}
          </td>
        </tr>
      </table>
    </div>
    <form
      enctype="multipart/form-data"
      class="uk-form-horizontal uk-form"
      @submit.prevent="add"
    >
      <div uk-form-custom="target: true">
        <input
          id="tiktok-upload-task"
          ref="tiktokBatchImportFile"
          type="file"
          name="files=[]"
          style="height: 2.5rem"
          @change="handleFileSelect"
        />
        <input
          class="uk-input uk-form-width-medium"
          type="text"
          :placeholder="$t('whatsapp.tasks.select-file')"
          :class="errors.file && 'uk-form-danger'"
          disabled
          style="height: 2.5rem;"
        />
      </div>
      <div class="uk-width-1-1 uk-float-right">
        <input
          type="submit"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
          :value="$t('tgcp.tasks.process_title')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TiktokAddBatchTasks',
  data () {
    return {
      total: 0,
      added: 0,
      duplicates: 0,
      error: 0,
      tasks: [],
      description: '',
      errors: {
        file: false
      }
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'massTaskAdd'
    ]),
    clear () {
      this.total = 0
      this.added = 0
      this.duplicates = 0
      this.error = 0
      this.tasks = []
    },
    add () {
      let caller = this
      if (caller.tasks.length) {
        return caller.massTaskAdd({ tasks: caller.tasks })
          .then(res => {
            caller.added = res.created.length
            caller.duplicates = res.exists.length
            caller.error = res.errors.length
            caller.errors.file = false
            caller.$emit('added')
          })
      }
    },
    handleFileError () {
      this.$notify({ group: 'general', title: this.$t('twitter.tasks.unable-to-read-file'), type: 'error' })
      this.errors.file = true
    },
    handleFileSelect () {
      let caller = this
      let file = caller.$refs.tiktokBatchImportFile.files[0]
      if (!file) return // make error handler
      let regexp = new RegExp(/[^.,;&\n ]{1,}/, 'g')
      let reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onloadend = evt => {
        caller.clear()
        var text = evt.target.result
        let tasks = text.match(regexp)
        caller.total = tasks.length
        tasks.forEach(task => {
          caller.tasks.push({
            task: task,
            description: caller.description
          })
        })
      }
    }
  }
}
</script>
