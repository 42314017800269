import config from '@/config'
import { store } from '@/store'
/* eslint-disable camelcase */

const { servers: { lookups } } = config

var AsyncLock = require('async-lock')
var jwtDecode = require('jwt-decode')
var lock = new AsyncLock()

const axios = require('axios')

const sleep = m => new Promise(resolve => setTimeout(resolve, m))

const filterProviders = (allowed, providers) => providers
  .filter(el => allowed
    .includes(Object.getPrototypeOf(el).constructor.name.toLowerCase()))

const lookupRequest = (url, headers = {}) => new Promise(async (resolve, reject) => {
  while (lock.isBusy('refreshToken')) await sleep(100)
  const jwtData = jwtDecode(store.state.auth_token)
  var expiryDate = jwtData.exp * 1000 - 120 * 1000
  if (expiryDate < Date.now()) {
    lock.acquire('refreshToken', () => {
      return store.dispatch('refresh')
        .then(() => {
          headers['Authorization'] = 'Bearer ' + store.state.auth_token
          axios({
            baseURL: lookups,
            headers: headers,
            method: 'GET',
            url: url
          })
            .then(data => resolve(data))
            .catch(err => reject(err))
        }).catch(err => console.debug(err))
    })
  } else {
    headers['Authorization'] = 'Bearer ' + store.state.auth_token
    axios({
      baseURL: lookups,
      headers: headers,
      method: 'GET',
      url: url,
      timeout: 60000
    })
      .then(data => resolve(data))
      .catch(err => reject(err))
  }
})

export {
  filterProviders,
  lookupRequest
}
