<template>
  <div class="ip_msisdn">
    <div class="uk-card uk-card-default uk-margin-top uk-card-body uk-text-left">
      <ul class="uk-list uk-text-left">
        <li class="">
          <router-link
            :to="{
              name: 'face_search'
            }"
          >
            <span
              class="uk-margin-small-right uk-icon"
              uk-icon="icon:  camera"
            />
            {{ $t('osint.face-search') }}
          </router-link>
        </li>
        <li class="">
          <router-link
            :to="{
              name: 'similar_search'
            }"
          >
            <span
              class="uk-margin-small-right uk-icon"
              uk-icon="icon: image"
            />
            {{ $t('osint.similar-search') }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AccessToolsLanding',
  data () {
    return {
      timer: null
    }
  }
}
</script>
