<template>
  <div class="uk-card uk-card-default uk-card-small uk-margin-top">
    <div
      v-if="user"
      class="uk-card-body"
    >
      <div class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small">
        <CardToProfileButton
          :card="userCard"
          :profile-name-by-default="profileName"
        />
        <AddStoredButton
          provider="forums"
          type="account"
          :data="user"
        />
      </div>
      <div
        class="uk-grid-small uk-flex-middle"
        uk-grid
      >
        <div class="uk-width-1-6@m ">
          <router-link :to="{ name: 'forums_user_details', params: { id: String( user.id ) }}">
            <Avatar
              :avatar="avatarCdnPath || user.avatar"
              type="user"
            />
          </router-link>
        </div>
        <div class="uk-width-2-3@m">
          <UserInfo
            :user="user"
          />
        </div>
        <div class="uk-width-1-6@m">
          <div class="">
            <ul
              class="uk-list uk-list-divider uk-text-left"
            >
              <li
                class="uk-width-expand"
              >
                {{ $t('tgcp.stats.messages_count') }} <span class="uk-float-right">
                  {{ user.messages_count }}
                </span>
              </li>
              <li
                class="uk-width-expand"
              >
                {{ $t('forums.topics') }} <span class="uk-float-right">
                  {{ user.topics_count }}
                </span>
              </li>
            </ul>
            <router-link
              :to="{
                name: 'forums_user_details',
                params: { id: String(user.id) }}"
              class="uk-button uk-button-primary uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            >
              {{ $t('tgcp.channels.full_info') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Forums/Avatar'
import UserInfo from '@/components/Forums/UserList/UserInfo'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import GeneralUser from '@/utils/providers/generalUser'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Item',
  components: {
    Avatar,
    UserInfo,
    AddStoredButton,
    CardToProfileButton: defineAsyncComponent(() => import('@/components/CardToProfileButton'))
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    avatarCdnPath () {
      if (this.user.avatar_cdn_path) {
        return this.$gateway(this.user.avatar_cdn_path)
      }
      return null
    },
    externalId () {
      return 'forums_user_' + this.user.id + this.user.display_name
    },
    userCard () {
      const card = new GeneralUser()
      if (this.user.avatar) {
        card.avatar = this.user.avatar
      }
      if (this.user.login || this.user.display_name) {
        card.name = this.user.login || this.user.display_name
      }
      if (this.user.first_name || this.user.last_name) {
        card.fullName = [this.user.first_name, this.user.last_name].filter(i => i).join(' ')
      }
      if (this.user.bio) {
        card.bio = this.user.bio
      }
      card.type = 'forums'
      card.genericType = 'forums'
      card.user_id = this.user.id
      if (this.user.phone_number) {
        card.phone = this.user.phone_number
      }
      if (this.user.birthday) {
        card.birthday = this.user.birthday
      }
      if (this.user.url) {
        card.links = [this.user.url]
      }
      if (this.user.age) {
        card.age = this.user.age
      }
      if (this.user.email) {
        card.email = this.user.email
      }
      return card
    },
    profileName () {
      return this.user.login || 'forum_user_' + this.user.id
    }
  }
}
</script>

