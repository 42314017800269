<template>
  <div class="uk-flex uk-flex-column uk-align-center uk-margin-remove-vertical">
    <p class="uk-margin-remove-bottom uk-text-right uk-text-medium"> 
      {{ dateTime? new Date(dateTime).toLocaleTimeString() : 'Error' }}
    </p>
    <p 
      v-if="dateTime" 
      class="uk-margin-remove uk-text-right" 
      style="font-size: small; white-space: nowrap;"
    >
      {{ new Date(dateTime).toLocaleDateString($i18n.locale === 'ru' ? 'ru-RU' : 'en-US', {year: 'numeric', month: 'short', day: 'numeric' }) }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TonDateCell',
  props: {
    dateTime: {
      type: [Number, String],
      required: false
    }
  }
}
</script>