import xss from 'xss'
import { findPhoneNumbersInText as parsePhones, default as parseMax } from 'libphonenumber-js/min'
import contextProcessor from '@/utils/context-processor'
import { store } from '@/store'
import router from '@/router'

const discordIdRegex = {
  regex: /(^|[^\w\/])(<@[a-z0-9_.-]+>)/gi,
  replaced: nickname => `<a href="/discord/users/${nickname.trim().replace(/[<@>]/gi, '')}/">${nickname}</a>`
}

export const regexes = {
  email: {
    regex: /([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*)/gi,
    replaced: email => `<a href="/email/${email.trim()}/"> ${email} </a>`
  },
  phone: {
    regex: [],
    replaced: (phone, old) => `<a href="/phone/${phone.replace(/[()]/gi, '')}/">${old}</a>`
  },
  ip: {
    regex: /(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/gi,
    replaced: ip => `<a href="/ip/${ip}/">${ip}</a>`
  },
  nickname: {
    regex: /(^|[^\w\/])(@[a-z0-9_.-]+)/gi,
    replaced: nickname => `<a href="/nickname/${nickname.trim()}/">${nickname}</a>`
  },
  link: {
    // eslint-disable-next-line no-useless-escape
    regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,10}\b([-a-zA-Z0-9@:%_\+.~#?!&//=]*)(?![">])/gi,
    replaced: link => `<a rel="noopener noreferrer" target="_blank" href="${link.trim()}">${link}</a>`
  }
}

const extractData = text => {
  if (text === null || !text) return

  let matched = xss(text, {
    onIgnoreTagAttr (tag, name, value) {
      if (name.startsWith('data-')) {
        return `${name}="${xss.escapeAttrValue(value)}"`
      }
    }
  })

  Object.keys(regexes).reverse().map(el => {
    if (el === 'phone') {
      const { decode, encode } = contextProcessor()
      matched = decode(matched)
      matched = getPhones(matched)
      matched = encode(matched)
    } else if (el === 'nickname') {
      if (router.currentRoute.value.name.includes('discord')) {
        let tester = matched.replace(/<\/?[b|i|^>]+(>|$)/g, '').replace('&lt;', '<').replace('&gt;', '>')
        let nick = tester.replace(discordIdRegex.regex, (find, p1, p2) => {
          if (p2) return find.replace(p2, discordIdRegex.replaced(p2))
        })
        if (tester !== nick) matched = nick
      } else {
        let tester = matched.replace(/<\/?[b|i|^>]+(>|$)/g, '')
        let nick = tester.replace(regexes[el].regex, (find, p1, p2) => {
          if (p2) return find.replace(p2, regexes[el].replaced(p2))
        })
        if (tester !== nick) matched = nick
      }
    } else {
      matched = matched.replace(regexes[el].regex, (find, p1, p2) => {
          return regexes[el].replaced(find)
      })
    }
  })
  return matched.replace(/\r?\n/g, '<br />')
}

const getPhones = text => {
  let locales = [] 
  if (router.currentRoute.value.name.includes('ads') && router.currentRoute.value?.params?.country) {
    locales = [router.currentRoute.value.params.country]
  }
  locales = [...new Set(locales, store.state.ads.geos.data)]
  let phones = []
  for (let i = 0; i < locales.length; i++) {
    phones = phones.concat(parsePhones(text, { defaultCountry: locales[i].toUpperCase() }))
    if (phones.length > 0) break
  }
  phones.map(phone => {
    let old = text.substring(phone.startsAt, phone.endsAt)
    if (!regexes.phone.regex.includes(old)) regexes.phone.regex.push(old)
  })
  for (let i = 0; i < phones.length; i++) {
    if (!phones[i].number) {
      phones[i].number = parseMax(phones[i].phone, phones[i].country)
    }
    let old = regexes.phone.regex[i]
    if (old) {
      const replaced = regexes.phone.replaced(phones[i].number.number, old)
      const regexpr = escapeRegExp(old)
      if (regexpr) {
        text = text.replace(new RegExp(regexpr, 'gi'), replaced)
      }
    }
  }
  regexes.phone.regex = []
  return text
}

function escapeRegExp (string) {
  if (!string) return null
  return string.replace(/^\[/g, '\\').replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export default extractData
