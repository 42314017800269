<template>
  <div
    class="uk-align-center "
    uk-grid
  >
    <Loader v-if="!isLoaded"/>
    <div
      v-else
      uk-grid
    >
      <div class="uk-width-expand uk-flex uk-flex-between">
        <div class="uk-card uk-card-default uk-margin-bottom uk-flex-1">
          <div class="uk-card-header" >
            <h4>
              {{ $t('crypto.ton-address-information') }}
            </h4>
          </div>
          <div
            class="uk-card-body uk-padding-remove"
          >
            <PropsTable
              :acc="acc"
              :short="true"
            />
          </div>
        </div>
        <UIExplorers
          :bounceable="bounceable"
          class="uk-flex-1 uk-margin-left ui-max-width"
        />
      </div>
      <div class="uk-width-1-1 uk-margin-remove-top">
        <UITabs 
          :tab-name-method="tabNameMethod" 
          :tab-counters="tabCounters"
        >
          <template #attached-phones>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-header">
                <h4>
                  {{ $t('crypto.attached-phones') }}
                </h4>
              </div>
              <div class="uk-card-body uk-padding-remove">
                <Loader v-if="!isLoadedAttached" />
                <PhoneTable
                  v-else
                  :keys="getTonAccountPhones[address] ?? []" 
                  :default-length="10"
                  :owner="address"
                />
              </div>
            </div>
          </template>
          <template #telegram-usernames>
            <TelegramUsernames :owner="bounceable"/>
          </template>
          <template #NFT-items>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-header">
                <h4>
                  {{ $t('crypto.NFT-items') }}
                </h4>
              </div>
              <div class="uk-card-body uk-padding-remove">
                <NFTGrid
                  :keys="ownerKeys" 
                  :default-length="18"
                  :account="address"
                  :minterFlag="false"
                />
              </div>
            </div>
          </template>
          <template #jettons>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-header">
                <h4>
                  {{ $t('crypto.jettons') }}
                </h4>
              </div>
              <div class="uk-card-body uk-padding-remove">
                <JettonsTable
                  :keys="jtKeys" 
                  :default-length="10"
                  :owner="address"
                />
              </div>
            </div>
          </template>
          <template #transactions>
            <div class="uk-card uk-card-default uk-margin-bottom">
              <div class="uk-card-header">
                <h4>
                  {{ $t('crypto.transactions') }}
                </h4>
              </div>
              <div class="uk-card-body uk-padding-remove">
                <TransactionsTable 
                  :keys="trKeys" 
                  :default-length="20" 
                  :hidden="trKeys.length === 0" 
                  :update="true" 
                  :account="address"
                  :excludeMC="false"
                />
              </div>
            </div>
          </template>
        </UITabs>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import PropsTable from '@/components/Crypto/Ton/Accounts/PropsTable'
import PhoneTable from '@/components/Crypto/Ton/Accounts/PhoneTable'
import NFTGrid from '@/components/Crypto/Ton/Accounts/NFTGrid'
import TransactionsTable from '@/components/Crypto/Ton/Transactions/Table'
import JettonsTable from '@/components/Crypto/Ton/Accounts/JettonsTable'
import UIExplorers from '@/components/Crypto/UIExplorers.vue'
import { numberFormat } from '@/filters/globalFilters'
import { mapActions, mapGetters } from 'vuex'
import UITabs from '@/components/UITabs.vue'
import TelegramUsernames from '@/components/Crypto/TelegramUsernames.vue'

export default {
  name: 'TonAddressInfo',
  components: {
    Loader,
    UITabs,
    UIExplorers,
    TelegramUsernames,
    PhoneTable,
    NFTGrid,
    JettonsTable,
    PropsTable,
    TransactionsTable
  },
  props: {
    address: {
      type: String,
      required: true
    }
  },
  watch: {
    address (to, from) {
      this.update()
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts', 'getTonAccountPhones', 'userNFTItemsByCollection']),
    acc () {
      return this.getTonAccounts[this.address] ?? null
    },
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_account_' + this.address]
    },
    isLoadedAttached () {
      return this.$store.state.crypto.isLoaded['tonaddressphones_' + this.address]
    },
    trKeys () {
      return this.acc?.transaction_keys?.length > 0 ? this.acc.transaction_keys : []
    },
    ownerKeys () {
      return this.acc?.owned_nfts?.length > 0 ? this.acc.owned_nfts : []
    },
    jtKeys () {
      return this.acc?.jetton_wallets?.length > 0 ? this.acc.jetton_wallets : []
    },
    bounceable () {
      return this.acc ? this.acc.address.base64 : null
    }
  },
  methods: {
    ...mapActions('crypto', [
      'fetchTonAccount'
    ]),
    numberFormat,
    async update () {
      const response = await this.fetchTonAccount({ hex: this.address, short: true })
      if (response != this.address) {
        this.$router.replace({ name: 'crypto_ton_address', params: { address: response }, hash: '#overview'})
      }
    },
    balanceText (balanceObject) {
      let str = ''
      Object.entries(balanceObject).forEach(function (value, key) {
        if (key < 7) {
          if (value[0] === 'btc') str += value[1]
          else str += this.numberFormat(value[1])
          str += ' ' + value[0].toLocaleUpperCase() + '<br>'
        }
      })
      return str
    },
    tabNameMethod (tabName) {
      return this?.$t('crypto.'.concat(tabName))
    },
    tabCounters (tabName) {
      switch (tabName) {
          case 'attached-phones' : return this.acc && this.getTonAccountPhones[this.address] ? this.getTonAccountPhones[this.address].length : 0
          case 'telegram-usernames': return this.userNFTItemsByCollection.length
          case 'NFT-items': return this.acc ? this.acc.nft_amount : 0
          case 'jettons': return this.acc ? this.acc.jetton_amount : 0
          case 'transactions': return this.acc ? this.acc.transaction_amount : 0
          default: return 0
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
<style scoped>
.ui-max-width {
  max-width: 250px
}
</style>