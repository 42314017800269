<template>
  <div class="uk-button-group uk-float-right  uk-text-small">
    <button
      class="uk-button
            uk-button-default
            uk-text-nowrap
            uk-margin-remove-bottom
            uk-padding-small
            uk-padding-remove-top
            uk-padding-remove-bottom
            uk-float-right"
      style="width: 90%; height: 100%"
      @click="resync(false)"
    >
      <div
        v-if="isLoaded === false"
        uk-spinner
      />
      <span v-else>
        {{ $t('tgcp.channels.resync_button') }}
      </span>
    </button>
    <div class="uk-inline">
      <button
        class="uk-button uk-button-default"
        type="button"
        style="height: 100%; padding-left: 0;padding-right: 0;"
      >
        <span uk-icon="icon:  triangle-down"/>
      </button>
      <div

        uk-dropdown="mode: click;pos: bottom-right"
      >
        <ul class="uk-nav uk-dropdown-nav">
          <li class="uk-active uk-dropdown-close">
            <a
              href="#"
              @click.prevent="resync(true)"
            >
              {{ $t('tgcp.channels.resync_button_with_media') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SyncChat',
  props: {
    channel: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoaded: null
    }
  },
  methods: {
    ...mapActions('tgcp', ['getChannelTasks', 'createChannelTask', 'editTask']),
    async resync (collectMedia) {
      this.isLoaded = false
      const tasksList = []
      const queue = []
      let flag = false
      try {
        const channelTasks = await this.getChannelTasks(this.channel.tg_id)
        if (channelTasks.length === 0) {
          const createdTasks = await this.createChannelTask(this.channel.tg_id)
          console.log(createdTasks)
          tasksList.push(...createdTasks)
          flag = true
        } else {
          tasksList.push(...channelTasks)
        }
        for (const task of tasksList) {
          if (((task.collect_media ?? false) !== collectMedia && collectMedia) || !task.priority || !task.is_active) {
            let taskData = {
              id: task.id,
              priority: true,
              is_active: true
            }
            if (collectMedia) taskData.collect_media = true
            const request = this.editTask(taskData)
            queue.push(request)
          }
        }
        console.log(queue)
        if (queue.length > 0 || flag) {
          await Promise.all(queue)
          this.$notify({ group: 'general', title: 'Synced', text: 'Chat resync scheduled successfully', type: 'success' })
        } else {
          this.$notify({ group: 'general', title: 'Synced', text: 'All tasks are already in priority queue', type: 'info' })
        }
      } catch (error) {
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: error, type: 'error' })
      } finally {
        this.isLoaded = true
      }
    }
  }
}
</script>
