<template>
  <tr>
    <template v-if="messageKeys.length > 0">
      <td class="uk-text-left">
        <router-link 
          class="uk-text-truncate" 
          aria-label="transaction_link" 
          :to="{ name: 'crypto_ton_transaction_info', params: { id: toBase64Web(trn.hash) }, hash: '#overview'}"
        >
          {{ truncString(trn.hash, 5) }}
        </router-link>
      </td>
      <td>
        <div 
          class="uk-flex uk-flex-column" 
          style="gap: 24px"
        >
          <StatusCell 
            v-for="(msg, index) in messageKeys"
            :key="trn.hash + '_status_' + index"
            :status="getTonMessages[msg].type"
          />
        </div>
      </td>
      <td class="uk-text-truncate">
        <div 
          class="uk-flex uk-flex-column" 
          style="gap: 24px"
        >
          <div 
            v-for="(msg, index) in messageKeys"
            :key="trn.hash + '_op_' + index"
            class="uk-text-left"
          >
            {{ getTonMessages[msg].operation_name? getTonMessages[msg].operation_name : 
              ( getTonMessages[msg].operation_id? `op=0x${opToHex(getTonMessages[msg].operation_id ?? 0)}` : "&#8203;") }}
          </div>
        </div>
      </td>
      <td>
        <div 
          class="uk-flex uk-flex-column" 
          style="gap: 24px"
        >
          <div 
            v-for="(msg, index) in messageKeys"
            :key="trn.hash + '_amount_' + index"
            class="diamond uk-text-right"
            :uk-tooltip="'title:' + fullTON(getTonMessages[msg].amount ?? 0n, false) + '; pos: top-right'"
          >
            {{ (msg === trn.in_msg_hash ? '' : '-') + shortTON(getTonMessages[msg].amount ?? 0n) }}
          </div>
        </div>
      </td>
      <td class="uk-text-truncate"> 
        <div 
          class="uk-flex uk-flex-column" 
          style="gap: 24px"
        >
          <AddressField 
            v-for="(msg, index) in messageKeys"
            :key="trn.hash + '_in_' + index"
            class="uk-text-right" 
            :addr="getTonMessages[msg].src_address ?? null" 
            :break_word="false"
            :disable="accDisabled(getTonMessages[msg].src_address?.hex)"
          />
        </div>
      </td>
      <td class="uk-text-truncate"> 
        <div 
          class="uk-flex uk-flex-column" 
          style="gap: 24px"
        >
          <AddressField 
            v-for="(msg, index) in messageKeys"
            :key="trn.hash + '_out_' + index"
            class="uk-text-right" 
            :addr="getTonMessages[msg].dst_address ?? null" 
            :break_word="false"
            :disable="accDisabled(getTonMessages[msg].dst_address?.hex)"
          />
        </div>
      </td>
      <td>
        <TableDateCell :date-time="trn.created_at"/>
      </td>   
    </template>
    <template v-else-if="messageKeys.length === 0">
      <td class="uk-text-left">
        <router-link
          aria-label="transaction_link" 
          :to="{ name: 'crypto_ton_transaction_info', params: { id: toBase64Web(trn.hash) }, hash: '#overview'}"
        >
          {{ truncString(trn.hash, 5) }}
        </router-link>
      </td>
      <td class="uk-flex">
        <StatusCell :status="'UPDATE'"/>
      </td>
      <td/>
      <td/>
      <td/>
      <td/>
      <td>
        <TableDateCell :date-time="trn.created_at"/>
      </td> 
    </template>
  </tr>
</template>

<script>
import { toBase64Web, truncString, fullTON, shortTON, opToHex } from '@/utils/ton-filters'
import { mapGetters } from 'vuex'
import TableDateCell from '@/components/Crypto/Ton/Atoms/TableDateCell'
import StatusCell from '@/components/Crypto/Ton/Atoms/StatusCell'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'

export default {
  name: 'TonTransactionLine',
  components: {
    TableDateCell,
    StatusCell,
    AddressField
  },
  props: {
    trn: {
      type: Object,
      required: true
    },
    account: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonMessageKeys', 'getTonMessages']),
    messageKeys () {
      return this.getTonMessageKeys([this.trn.hash], true, true).filter(key => key in this.getTonMessages)
    }
  },
  methods: {
    toBase64Web,
    truncString,
    shortTON,
    fullTON,
    opToHex,
    accDisabled (addr) {
      return (addr === this.account) ? true : null
    }
  }
}
</script>


<style scoped>
.diamond::after {
    content: '💎';
    filter: grayscale(100%);
}
</style>