export const REG_EXP = /(https?:)?\/\/chat\.whatsapp\.com\/(invite\/)?(?<id>[0-9a-z]{22})/i
export const WA_USER_REG_EXP = /(https?:\/\/)?wa.me\/\+?(?<id>[0-9\.]{7,15})/i

export const parse = value => {
  try {
    return value.match(REG_EXP)?.groups
  } catch (error) {
    console.debug(error)
  }
}
export const parseUser = value => {
  try {
    return value.match(WA_USER_REG_EXP)?.groups
  } catch (error) {
    console.debug(error)
  }
}

export const user = value => {
  try {
    return parseUser(value)?.id
  } catch (error) {
    console.debug(error)
  }
}

export const group = value => {
  try {
    return parse(value)?.id
  } catch (error) {
    console.debug(error)
  }
}
