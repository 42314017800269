import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'
import config from '@/config'

export default {
  namespaced: true,
  state: {
    countryStats: {},
    isLoaded: {},
    statsProblem: false,
    stats: {},
    users: {},
    geos: {}
  },
  getters: {
    isLoaded: state => state.isLoaded
  },
  mutations: {
    resetStatsProblem: (state) => {
      state.statsProblem = false
    },
    statsProblem: (state) => {
      state.statsProblem = true
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addAdsCountryStats: (state, payload) => {
      state.countryStats[payload.geo.toLowerCase()] = payload
    },
    getGeo: (state, geos) => {
      state.geos = geos
    },
    fetchAllUsers: (state, users) => {
      if (!users) return
      users.map(user => {
        state.users[user.user_id] = user
      })
    },
    fetchProfileByUID: (state, user) => {
      if (!user) return
      state.users[user.uid] = user
    }
  },
  actions: {
    searchByPhone ({ dispatch, commit, state, rootState }, phone) {
      return new Promise((resolve, reject) => {
        apiRequest(`/ads/?phones=` + encodeURIComponent(phone.replace('+', '')), 'GET', {}, config.servers.ads)
          .then(data => {
            if (data.data) {
              resolve(data.data.data)
            } else resolve(false)
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchAllBoards ({ dispatch, commit, state }, query) {
      commit('startLoad', 'boards')
      query = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/boards?${query}`, 'GET', {}, config.servers.ads)
          .then(res => {
            let boards = res.data.data
            resolve(boards)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'boards'))
      })
    },
    getCategories ({ dispatch, commit }) {
      commit('startLoad', 'categories')
      return new Promise((resolve, reject) => {
        apiRequest(`/boards/categories`, 'GET', {}, config.servers.ads)
          .then(res => {
            let categories = res.data
            resolve(categories)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'categories'))
      })
    },
    getGeo ({ state, commit }) {
      return new Promise((resolve, reject) => {
        const geos = state.geos
        if (geos.date && geos.date > new Date()) return resolve(state.geos.data)
        commit('startLoad', 'geo')
        apiRequest(`/boards/geos`, 'GET', {}, config.servers.ads)
          .then(res => {
            var date = new Date()
            date.setHours(date.getHours() + 24)
            let geo = res.data.map(el => el.toLowerCase())
            commit('getGeo', { data: geo, date: date })
            resolve(geo)
          })
          .catch(err => {
            notify({ group: 'general', title: 'Service unavailable', type: 'error' })
            reject(err)
          })
          .finally(() => commit('stopLoad', 'geo'))
      })
    },
    getBoardStats ({ dispatch, commit }, id) {
      commit('startLoad', 'stats_board_' + id)
      return new Promise((resolve, reject) => {
        apiRequest(`/boards/${id}/stats`, 'GET', {}, config.servers.ads)
          .then(res => {
            let stats = res.data
            resolve(stats)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'stats_board_' + id))
      })
    },
    getGeoStats ({ dispatch, commit, state }, country) {
      return new Promise((resolve, reject) => {
        const stats = state.countryStats[country]
        if (stats && stats.date > new Date()) return resolve(state.countryStats[country])
        commit('startLoad', 'country_stats_' + country)
        commit('resetStatsProblem')
        apiRequest(`/stats/geos/` + country.toUpperCase(), 'GET', {}, config.servers.ads).then((resp) => {
          if (resp.data.length === 0) return commit('statsProblem')
          var stats = resp.data
          var date = new Date()
          date.setHours(date.getHours() + 24)
          stats.date = date
          commit('addAdsCountryStats', stats)
          resolve()
        }).catch(err => {
          commit('statsProblem')
          console.debug(err)
        }).finally(function () {
          commit('stopLoad', 'country_stats_' + country)
        })
      })
    },
    fetchAllADS ({ dispatch, commit }, query) {
      commit('startLoad', 'ads')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      query = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/ads?${query}`, 'GET', {}, config.servers.ads)
          .then(res => resolve(res.data))
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'ads'))
      })
    }
  }
}
