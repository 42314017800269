<template>
  <span>
    <button
      v-if="initialValues && initialValues.length > 0"
      class="uk-button uk-button-default"
      uk-toggle="target: #offcanvas_password_block"
      type="button"
    >
      {{ $t(`leaks.leaked_passwords`) }}
    </button>
    <div
      id="offcanvas_password_block"
      flip="true"
      uk-offcanvas
    >
      <div class="uk-offcanvas-bar" >
        <h3>
          {{ $t(`leaks.leaked_passwords`) }}
        </h3>
        {{ $t('leaks.type.' + type) }}: {{ search }}
        <ul class="uk-list uk-list-divider">
          <li
            v-for="(leak, index) in initialValues"
            :key="leak.password + index"
            class="uk-disabled"
          >
            <div v-if="$route.name !== 'emailResults'">
              <router-link :to="`/leaks/public/${leak.login}/${leakType}/`">
                {{ leak.login }}
              </router-link>
            </div>
            <router-link
              :to="`/leaks/public/${leak.login}/${leakType}/`"
              class="uk-margin-small-top"
            >
              {{ leak.password }}
            </router-link>
            <div>
              <span class="uk-margin-small uk-text-small uk-text-muted">
                {{ leak.sourcesString }}
              </span>
            </div>
          </li>
        </ul>
        <div v-if="isOverLimit">
          {{ $t(`leaks.over_limit`) }}
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: 'Offcanvas',
  props: {
    type: {
      type: String,
      required: true
    },
    search: {
      type: String,
      required: true
    },
    initialValues: {
      type: Array
    },
    isOverLimit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    leakType () {
      if (this.type === 'email_user') {
        return 'login'
      } else {
        return 'email'
      }
    }
  }
}
</script>
