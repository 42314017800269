export default {
  created () {
    this.prepareRouteParams()
    this.searchHandler({ page: (this.isParrent && this.$route.query.page) || 1, isInit: true })
  },
  watch: {
    '$route.query' () {
      if (!Object.entries(this.$route.query).length) { // при клике по активной вкладке
        this.searchHandler()
      }
    },
    '$route.query.search' (search) {
      this.query.search = search
      this.update()
    }
  },
  computed: {
    isParrent () {
      return this.type === 'all'
    }
  },
  methods: {
    prepareRouteParams () {
      if (this.isParrent) {
        Object.entries(this.$route.query)
          .forEach(([prop, val]) => {
            if (!this.paramsToRoute.includes(prop)) return
            if (prop === 'page') this.pagination.page = +val || this.pagination.page
            else this.query[prop] = val || this.query[prop]
          })
      }
    },
    changePageHandler (page) {
      if (this.isParrent) this.query.search = this.$route.query.search // синхронизация стейта с роутом
      this.searchHandler({ page })
    },
    updateQuery ({ page = 1, isInit = false } = {}) {
      this.query.offset = (this.query.limit) * (page - 1)
      this.pagination.page = +page
      if (this.isParrent || this.type === 'topic') {
        const fields = { ...this.pagination, ...this.query }
        const params = Object.fromEntries(Object.entries(fields).filter(([prop]) => this.paramsToRoute.includes(prop)))
        this.addParamToQuery({ ...params, isInit })
      }
    },
    searchHandler ({ page = 1, isInit = false } = {}) {
      this.updateQuery({ page, isInit })
      this.update()
    }
  }
}
