// @ts-check
/**
 * @typedef {{ namespace: void|Capitalize<string>, type: void|Capitalize<string>, subtype: void|Capitalize<string> }} getStrategyNameReturns
 * @typedef {import('./Strategies/Base').StrategyOptions} StrategyOptions
 * @typedef {import('./Context').ContextStruct} ContextStruct
 * @typedef {import('./Strategies/Base').Strategy} Strategy
 */

import Context from './Context.js'
import Utils from './Utils'

/**
 * @class
 * @readonly
 */
class Factory {
  /**
   * @type {null|Strategy}
   */
  strategy = null
  /**
   * @type {null|ContextStruct}
   */
  context = null
  /**
   * @readonly
   * @constructor
   * @param {StrategyOptions} options
   */
  constructor (options) {
    try {
      /**
       * @type {void|Function}
       */
      const Strategy = Factory.getStrategy(options)
      if (Strategy) {
        this.strategy = Reflect.construct(Strategy, [options])
      }
      if (this.strategy) {
        this.context = new Context(this.strategy)
      }
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @private
   * @readonly
   * @param {StrategyOptions} options
   * @return {null|getStrategyNameReturns}
   */
  static getStrategyName (options) {
    try {
      /**
       * @type {getStrategyNameReturns}
       */
      const name = {
        namespace: Utils.nameNormalize(options.namespace),
        type: Utils.nameNormalize(options.type),
        subtype: Utils.nameNormalize(options.subtype)
      }
      if (name.subtype && name.subtype.includes('/'))
        // @ts-ignore
        name.subtype = name.subtype.split('/')[0] + '/' +  name.subtype.split('/')[1][0].toUpperCase() +  name.subtype.split('/')[1].slice(1)
      if (name.namespace !== void 0 && name.type !== void 0 && name.subtype !== void 0) {
        return name
      }
      return null
    } catch (error) {
      console.debug(error)
      return null
    }
  }
  /**
   * @private
   * @readonly
   * @param {StrategyOptions} options
   * @return {void|Function}
   */
  static getStrategy (options) {
    try {
      /**
       * @type {null|getStrategyNameReturns}
       */
      const name = Factory.getStrategyName(options)
      if (name) {
        return require('./Strategies/' + name.namespace + (name.type ? '/' + name.type : '') + '/' + (name.subtype ? name.subtype : 'All')).default
      }
    } catch (error) {
      console.debug(error)
    }
  }
}

export default Factory
