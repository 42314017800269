import L from 'leaflet'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      realtimeScanner: null
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchUsersGeoNearWithCoords',
      'getFromCacheOrRequestUserData',
      'fetchUserById'
    ]),
    showUserPopup (e) {
      let popup
      let id
      let created
      try {
        popup = e.target.getPopup()
        if (e.layer.feature) {
          id = e.layer.feature.id
          created = e.layer.feature.user['created_at']
        } else {
          id = e.layer.options.user.id
          created = e.layer.options.user['created_at']
        }
      } catch {
        throw new Error('missclick')
      }
      this.fetchUserById({ id })
        .then(data => {
          data = `
                    <h3 style="margin-bottom: 5px;">${data.first_name || ''} ${data.last_name || ''}</h3>
                    <span><a href="/tgcp/user/${id}">#${id}</a></span>
                    ${(data.username && '<p><a href="/nickname/' + data.username + '/">@' + data.username + '</a></p>') || ''}
                    ${(data.bio && '<p>' + data.bio + '</p>') || ''}
                    ${(created && '<p>Last seen on: ' + this.dayjs(created) + '</p>') || ''}
                    ${(data.avatar && data.avatar.file_location && '<div><img src="' + data.avatar.file_location + '" style="max-width:100px"/></div>') || ''}
                    `
          popup.setContent(data)
        })
        .catch(err => console.debug(err))
    },
    async rtUsers () {
      this.mapInstance && this.mapInstance.fireEvent('dataloading')
      return new Promise((resolve, reject) => {
        this
          .fetchUsersGeoNearWithCoords()
          .then(res => {
            const users = res.data.map(user => {
              return {
                type: 'Feature',
                properties: {
                  user: user,
                  id: user[0].user_id
                },
                user: user[0],
                id: user[0].user_id,
                geometry: {
                  type: 'Point',
                  coordinates: [ user.coordinates[1], user.coordinates[0] ]
                }
              }
            })
            resolve(users)
          })
          .catch(err => reject(err))
          .finally(() => this.mapInstance && this.mapInstance.fireEvent('dataload'))
      })
    },
    async getTimelineUsers (params, excludeId) {
      this.mapInstance && this.mapInstance.fireEvent('dataloading')
      let { data } = await this.fetchUsersGeoNearWithCoords(params)
      try {
        window.tgUsers.clearLayers()
      } catch {}
      return window.tgUsers.addLayers(
        data.filter(el => el[0].user_id !== excludeId)
          .map(user => L.marker(L.latLng(user.coordinates[0], user.coordinates[1]), { user: user[0] })
            .bindPopup('Loading...')
            .on('click', this.showUserPopup)
          ), { chunkedLoading: true }
      )
    },
    async getTgUsers () {
      const caller = this
      if (this.realtimeScanner) return
      this.refreshRealtimeData()
      this.realtimeScanner = setInterval(function () {
        caller.refreshRealtimeData()
      }, 120000)
    },
    refreshRealtimeData () {
      this.rtUsers()
        .then(users => {
          window.tgUsers.clearLayers()
          window.tgUsers.addLayer(
            L.geoJson(
              {
                type: 'FeatureCollection',
                features: users
              }
            )
          )
          .bindPopup('Loading...')
            .on('click', this.showUserPopup)
        })
        .catch(err => console.error(err))
    }
  },
  created () {
    window.tgUsers = new L.MarkerClusterGroup({
      disableClusteringAtZoom: 16,
      animate: false,
      spiderfyOnMaxZoom: false,
      chunkedLoading: true,
      iconCreateFunction: cluster => {
        let count = cluster.getChildCount()
        let c = ' geouser-marker-cluster-'
        if (count < 50) c += 'small'
        else if (count < 500) c += 'medium'
        else c += 'large'
        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        })
      }
    })
  },
  beforeUnmount () {
    if (this.realtimeScanner) {
      clearInterval(this.realtimeScanner)
    }
    if (window.tgUsers) {
      window.tgUsers.clearLayers()
    }
  }
}
