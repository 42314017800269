import VuexPersistence from 'vuex-persist'

const vuexPersistence = new VuexPersistence({
  key: 'asiris',
  storage: window.localStorage,
  reducer: (state) => {
    var data = {
      // modules
      language: state.language,
      permanent_save: state.permanent_save,
      auth_token: state.auth_token,
      refresh_token: state.refresh_token,
      user_name: state.user_name,
      current_user_id: state.current_user_id,
      current_case: state.cases.currentCase,
      is_admin: state.is_admin,
      organisation_id: state.organisation_id,
      main_side_menu_is_collapsed: state.main_side_menu_is_collapsed,
      password_expired: state.password_expired,
      search_history: state.search_history,
      cases: {
        feed: state.cases.feed,
        web: state.cases.web,
        profileCollapsedSections: state.cases.profileCollapsedSections
      }
    }
    return data
  }
})

export default vuexPersistence.plugin
