<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div class="uk-margin">
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
        >
          <div class="uk-display-inline-block">
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              @keyup.enter="setPage(1)"
            />
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="setPage(1)"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            <div class="uk-display-inline-block">
              {{ $t('forums.users.sort.sort_by') }}
              <vueSelect
                v-model="currentOrderBy"
                :options="orderListTranslated"
                label="label"
                class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
              />
            </div>
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <vueSelect
              v-if="avCountries"
              v-model="currentCountry"
              :options="countriesComputed"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!isLoaded['users']">
        <Loader />
      </div>
      <div
        v-else
        uk-grid
        class="uk-grid uk-grid-small uk-match-height"
      >
        <template v-if="isLoaded['users'] && users.length === 0">
          <NotFound class="uk-align-center"/>
        </template>
        <template v-else>
          <Item
            v-for="user in users"
            :key="user.jid"
            :user="user"
            class="uk-width-1-5"
          />
        </template>
      </div>
      <div
        v-if="!hidePagination"
        class="uk-margin"
      >
        <Paginate
          v-model="pagination.page"
          :page-count="lastPage"
          :click-handler="setPage"
          prev-text="<"
          next-text=">"
          container-class="uk-pagination uk-flex-center"
        />
      </div>
    </div>
    <ExportDialog
      v-if="showExport"
      :id="groupId"
      :search="query.search"
      namespace="whatsapp"
      type="users"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedUsers"
      @deselect="selectedUsers = []"
    />
  </div>
</template>

<script>
import Item from '@/components/WhatsApp/UserList/Item'
import Loader from '@/components/TGCP/Loader'
import Paginate from '@/components/Paginate'
import vueSelect from '@/components/VueSelect'
import countries from '@/utils/countryCodesNamesFull'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

const validation = query => {
  if (query.search && query.search[0] === '+') {
    query.search = query.search.replace('+', '')
  }
  return query
}
export default {
  name: 'WhatsappUserList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item,
    Loader,
    Paginate,
    vueSelect
  },
  props: {
    groupId: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'members', 'admins', 'superadmins', 'members/former', 'admins/former', 'superadmins/former'].includes(val)
    }
  },
  data () {
    return {
      users: [],
      selectedUsers: [],
      orderList: ['phone', ...(this.type === 'all' ? ['jid'] : [])],
      countries: countries,
      avCountries: null,
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        country: this?.$route?.query?.country || undefined,
        search: this.$route.query.search || '',
        order_by: this.$route.query.order_by || 'phone',
        order_type: this.$route.query.order_type || 'desc'
      },
      pagination: {
        page: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters('whatsapp', ['isLoaded']),
    showExport () {
      if (this.type === 'all' && this.query.search && this.users.length) {
        return true
      }
      if (this.type !== 'all' && this.users.length) {
        return true
      }
      return false
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.update()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.update()
      }
    },
    currentCountry: {
      get () {
        return this.query.country
      },
      set (obj) {
        this.query.country = obj && obj.value
        this.setCountry()
        this.setPage(1)
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('whatsapp.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('forums.users.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('forums.users.sort.sort_type_desc') }
      ]
    },
    countriesComputed () {
      if (!this.avCountries.length) return
      const countries = this.avCountries.map(({ country_name: name, count }) => ({
        label: `${this.countries[name]} (${count})`,
        value: name,
        second: `Count: ${count}`
      })).sort((previous, next) => {
        const previousLabel = previous?.label
        const nextLabel = next?.label
        if (previousLabel < nextLabel) {
          return -1
        }
        if (previousLabel > nextLabel) {
          return 1
        }

        return 0
      })
      return [{ value: undefined, label: 'Select country' }, ...countries]
    },
    hidePagination () {
      return this.lastPage < 2
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    }
  },
  methods: {
    ...mapActions('whatsapp', ['fetchAllUsers', 'addParamToQuery', 'fetchGroupUsers']),
    init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      this.setPage(page)
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.type === 'all' &&
        this.addParamToQuery({
          page: this.page
        })
      this.update()
    },
    setData (res) {
      this.avCountries = this.avCountries || res.meta.countries
      this.users = res.data
      this.pagination.total = res.meta.results_count
    },
    clearData () {
      this.users = []
      this.avCountries = null
      this.pagination.page = 1
      this.pagination.total = null
    },
    setCountry () {
      this?.$router?.push({
        ...this?.$route,
        query: {
          ...this?.$route?.query,
          country: this?.query?.country
        }
      })
    },
    async update () {
      const query = validation(this.query)
      if (this?.type === 'all') {
        this.addParamToQuery(query)
      }
      try {
        return this.type === 'all'
          ? this.setData(await this.fetchAllUsers(query))
          : this.setData(
            await this.fetchGroupUsers({
              query,
              type: this.type,
              groupId: this.groupId
            })
          )
      } catch {
        this.clearData()
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
