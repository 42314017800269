<template>
  <div>
    <Item
      v-if="company"
      :company="company"
      class="uk-margin-top"
    />
    <div v-if="company">
      <div
        v-if="company.officers.length > 0"
        class="uk-card uk-card-default uk-card-body uk-text-left"
      >
        <h3 class="uk-margin-remove-bottom">
          Officers:
        </h3>
        <table class="uk-table uk-margin-remove-top uk-table-striped uk-table-justify uk-table-responsive">
          <thead>
            <tr class="uk-text-center uk-text-nowrap">
              <th>
                {{ $t('databases.name') }}
              </th>
              <th>
                {{ $t('databases.position') }}
              </th>
              <th>
                {{ $t('databases.start-date') }}
              </th>
              <th>
                {{ $t('databases.end-date') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="{ officer } in company.officers"
              :key="officer.id"
            >
              <td>
                {{ officer.name || '-' }}
              </td>
              <td>
                {{ officer.position || '-' }}
              </td>
              <td>
                {{ officer.start_date || '-' }}
              </td>
              <td>
                {{ officer.end_date || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="company.data"
        class="uk-card uk-card-default uk-card-body uk-text-left"
      >
        <h3>
          {{ $t('databases.additional-data') }}
        </h3>
        <ul class="uk-list uk-list-striped">
          <li
            v-for="{ datum } in company.data.most_recent"
            :key="datum.id"
          >
            {{ datum.title }}: {{ datum.description }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/DataBases/List/Item.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CompanyDetails',
  components: {
    Item
  },
  props: {
    number: {
      type: [String, Number],
      required: true
    },
    code: {
      type: String,
      required: true
    }
  },
  data () {
    return {

    }
  },
  watch: {
    number () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('organizations', [
      'Loaded',
      'allCompanies'
    ]),
    company () {
      return this.allCompanies[this.number]
    }
  },
  methods: {
    ...mapActions('organizations', [
      'fetchOrganizationById'
    ]),
    update () {
      this.fetchOrganizationById({
        jurisdictionCode: this.code,
        companyNumber: this.number
      })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
