<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header my-card-header">
        <h3 class="uk-card-title uk-text-left">
          <Resync
            v-if="user.unique_id"
            class="uk-margin-right"
            :username="user.unique_id"
          />
          {{ $t('twitter.user_details.general_info_title') }}
        </h3>
      </div>
      <div class="uk-card-body my-card-body">
        <div
          class="uk-position-top-right uk-overlay uk-overlay-transparent uk-background-transparent uk-padding-small"
        >
          <AddStoredButton
            provider="tiktok"
            type="account"
            :data="user"
          />
        </div>
        <ul uk-tab>
          <li class="uk-active">
            <a
              id="current_tab"
              href="#current_tab"
            >
              {{ $t('tgcp.tasks.current-data') }}
            </a>
          </li>
          <li
            v-if="linkedFrom.length > 0"
          >
            <a
              href="#linked-from_tab"
            >
              {{ $t('tgcp.channel_details.linked_from') }}
            </a>
          </li>
          <li
            v-if="linksTo.length > 0"
          >
            <a
              href="#links-to_tab"
            >
              {{ $t('tgcp.channel_details.links_to') }}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li class="uk-active">
            <a name="current_tab" />
            <div
              class="uk-grid"
              data-uk-grid-margin
            >
              <div class="uk-width-1-4@m">
                <AvatarList
                  v-if="avatarCdnWithoutNull(user).avatars.length"
                  :data="avatarCdnWithoutNull(user)"
                />
                <Avatar
                  v-else
                  :avatar="user.avatar_url"
                />
              </div>
              <div class="uk-width-expand@m uk-text-left">
                <h3 class="uk-margin-remove-bottom">
                  {{ user.nickname }}
                </h3>
                <h4
                  class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted"
                >
                  #{{ user.id }}
                </h4>
                <h4
                  v-if="user.unique_id"
                  class="uk-margin-remove-top uk-margin-remove-botto uk-text-muted uk-text-small"
                >
                  <router-link
                    :to="{ name: 'nicknameResults', params: { nickname: user.unique_id } }"
                  >@{{ user.unique_id }}</router-link>
                  <a
                    :href="`https://tiktok.com/@${user.unique_id}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    :uk-tooltip="$t('tiktok.open-in-tiktok')"
                    class="uk-margin-small-left"
                  >
                    <tiktokIcon/>
                  </a>
                </h4>
                <p
                  v-if="user.is_verified"
                  class="uk-text-success"
                >
                  <span uk-icon="icon: check"/> {{ $t('twitter.user_details.verified') }}
                </p>
                <p v-if="user.is_private">
                  <span uk-icon="icon: lock"/> {{ $t('twitter.user_details.private') }}
                </p>
                <p
                  v-if="user.is_deleted"
                  class="uk-text-danger"
                >
                  <span uk-icon="icon: ban"/> {{ $t('twitter.user_details.deleted') }}
                </p>

                <div uk-grid>
                  <div class="uk-width-1-3 uk-text-break">
                    <p
                      v-html="extractedDataBio"
                      class="uk-margin-remove-bottom"
                    />
                    <button
                      class="uk-button-text uk-button uk-text-small uk-text-muted"
                      @click="translateDescription"
                    >
                      <font-awesome-icon :icon="['fa', 'language']" />
                      <span
                        v-if="translated['signature']"
                      >
                        {{ $t('twitter.user_details.show-original') }}
                      </span>
                      <span v-else>
                        {{ $t('twitter.user_details.translate') }}
                      </span>
                    </button>
                    <ul class="uk-list uk-text-small">
                      <li v-if="user.bio_url">
                        {{ $t('twitter.user_details.url') }}:
                        <a
                          :href="user.bio_url"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ user.bio_url }}
                        </a>
                      </li>
                      <li v-if="user.birthday">
                        Birthday:
                        <span>
                          {{ user.birthday }}
                        </span>
                      </li>
                      <li v-if="user.twitter_id">
                        Twitter:
                        <router-link
                          :to="{
                            name: 'twitter_user_details',
                            params: { id: String(user.twitter_id) }}"
                        >
                          {{ '@' + user.twitter_name ? user.twitter_name : String(user.twitter_id) }}
                        </router-link>
                      </li>
                      <li v-if="user.youtube_channel_id">
                        Youtube:
                        <a :href="`https://www.youtube.com/channel/${user.youtube_channel_id}`">
                          {{ user.youtube_channel_title ? user.youtube_channel_title : String(user.youtube_channel_id) }}
                        </a>
                      </li>
                      <li
                        v-if="user.region"
                      >
                        {{ $t('twitter.user_details.location') }}: {{ countries[user.region] || user.region }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.account-created') }}: {{ user.cdate ? dayjs(user.cdate) : $t('tgcp.user_details.never') }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.account-synced') }}: {{ user.sync_date ? dayjs(user.sync_date) : $t('tgcp.user_details.never') }}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <ul
                      class="uk-list uk-text-small"
                    >
                      <li>
                        {{ $t('twitter.user_details.followers') }}: {{ user.follower_count_stats }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.following') }}: {{ user.following_count_stats }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.post_count') }}: {{ user.posts_count_stats }}
                      </li>
                      <li>
                        {{ $t('twitter.user_details.likes_count') }}: {{ user.likes_count_stats }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li v-if="linkedFrom.length > 0">
            <a
              name="linked-from_tab"
            />
            <Relations
              :data="linkedFrom"
            />
          </li>
          <li v-if="linksTo.length > 0">
            <a
              name="links-to_tab"
            />
            <Relations
              :data="linksTo"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '@/components/Tiktok/Avatar'
import AvatarList from '@/components/Tiktok/AvatarList'
import extractData from '@/utils/dataExtraction'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import translate from '@/mixins/translate'
import {mapActions, mapGetters} from 'vuex'
import tiktokIcon from '@/components/icons/tiktok-icon.vue'
import countries from '@/utils/countryCodesNamesFull.json'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TiktokUserGeneralInfo',
  components: {
    Avatar,
    AddStoredButton,
    AvatarList,
    tiktokIcon,
    Resync: defineAsyncComponent(() => import('@/components/Tiktok/UserDetails/Resync.vue')),
    Relations: defineAsyncComponent(() => import('@/components/Relations.vue'))
  },
  mixins: [translate],
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      countries,
      localUser: {...this.user},
      translated: {}
    }
  },
  computed: {
    ...mapGetters('tiktok', ['linkedFrom', 'linksTo']),
    extractedDataBio () {
      if (!this.user) return
      return extractData(this.localUser.signature)
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'fetchUserRelations'
    ]),
    avatarCdnWithoutNull (user) {
      try {
        const avatars = user.avatars.filter(avatar => avatar['avatar_cdn_path'])
        return {
          ...user,
          avatars
        }
      } catch (error) {
        console.debug(error)
      }
    },
    async translateDescription () {
      const { signature } = await this.translateProps(['signature'], this.localUser, this.user)
      this.localUser.signature = signature || this.localUser.signature
    }
  },
  created () {
    try {
      if (typeof this?.user?.id !== 'undefined') {
        this.fetchUserRelations(this.user.id)
      }
    } catch (error) {
      console.debug(error)
    }
  }
}
</script>
<style scoped>
  .my-card-header {
    border-bottom: 0;
    padding-bottom: 0
  }
  .my-card-body {
    padding-top: 0
  }
</style>
