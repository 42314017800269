<script>
import Loader from '@/components/TGCP/Loader'
import { formatTons } from '@/utils/ton-filters'
import { mapGetters, mapActions } from 'vuex'
import AddressField from '@/components/Crypto/Ton/Atoms/AddressField'

export default {
  name: 'TonJettonsTable',
  components: {
    Loader,
    AddressField
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    owner: {
      type: String,
      required: true
    },
    defaultLength: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      firstLT: 0,
      lastLT: 0,
      itemCount: 10,
      pagination: {
        page: 1,
        total: 0
      },
      maxExploredPage: 0
    }
  },
  watch: {
    defaultLength: {
      handler (to, from) {
        this.itemCount = to
        if (this.pagination.page === 1) this.updateValues()
        else this.pagination.page = 1
      },
      deep: true
    },
    'pagination.page' : {
      async handler (to, from) {
        if (to === 1 || (to > from && to > this.maxExploredPage)) { 
          this.maxExploredPage = to
          await this.updateValues()
        }
      }
    }
  },
  computed: {
    ...mapGetters('crypto', ['getTonAccounts', 'getTonMetaItems', 'getTonMetaRelations', 'getTonMetadata']),
    isLoaded () {
      return this.$store.state.crypto.isLoaded['ton_account_jettons_'  + this.owner] ?? false
    },
    hidePagination () {
      return !this.isLoaded || (this.pagination.total ? this.pagination.total : this.keys.length ) < this.itemCount
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.itemCount)
      return this.pagination.total / lastPage - this.itemCount === 0 ? lastPage : lastPage + 1
    },
    currentBatch () {
      const { keys, pagination, itemCount } = this
      return keys.slice((pagination.page - 1) *itemCount, (pagination.page)*itemCount)
    },
    jtList () {
      return this.getTonMetaItems(this.currentBatch)
    },
    jtRelations () {
      return this.getTonMetaRelations(this.currentBatch)
    }
  },
  methods: {
    ...mapActions('crypto', ['loadTonAccountJettons']),
    formatTons,
    setExtraFields () {
      const { keys, getTonAccounts, owner } = this
      if (keys.length > 0) {
        this.pagination.total = getTonAccounts[owner].jetton_amount
        if (keys[0] in getTonAccounts) {
          this.firstLT = BigInt(getTonAccounts[keys[0]].last_tx_lt)
        }
        if (keys[keys.length - 1] in getTonAccounts) {
          this.lastLT = BigInt(getTonAccounts[keys[keys.length - 1]].last_tx_lt)
        }
      }
    },
    async updateValues (next = true) {
      const { keys, itemCount, pagination, lastLT, firstLT, owner } = this
      if (keys.length === 0 || pagination.page === 1)
        await this.loadTonAccountJettons({ limit: itemCount, preload: true, seqOffset: null, account: owner })
      else {
        await this.loadTonAccountJettons({ limit: itemCount, preload: true, seqOffset: next ? lastLT : firstLT, account: owner })
      }
      this.setExtraFields()
    },
    setPage (page) {
      this.pagination.page = page
    }
  },
  async mounted () {
    if (this.keys.length === 0 || this.keys.length === Object.keys(this.jtList).length) {
      await this.loadTonAccountJettons({ account: this.owner, preload: true, limit: 10, seqOffset: null })

    } else if (this.keys.length > 0 && Object.keys(this.jtList).length !== this.keys.length) {
      await this.loadTonAccountJettons({ account: this.owner, preload: true, limit: 10, seqOffset: null })
    }
    this.setExtraFields()
  }
}
</script>

<template>
  <div>
    <table class="uk-table uk-table-middle uk-margin-remove-top uk-table-striped">
      <thead>
        <tr>
          <th class="uk-width-1-4">
            {{ $t('crypto.ton.name') }}
          </th>
          <th class="uk-width-1-2">
            {{ $t('crypto.ton.wallet') }}
          </th>
          <th class="uk-table-expand uk-text-right">
            {{ $t('crypto.ton.balance') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template 
          v-if="!isLoaded" 
          class="uk-flex uk-flex-center"
        >
          <tr>
            <td colspan="3">
              <Loader :ratio="2"/>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr 
            v-for="key of currentBatch.filter(item => item in jtList)"
            :key="key"
          >
            <td 
              class="uk-text-truncate uk-text-left" 
              style="gap: 0.5rem"
            >
              <router-link 
                :to="{ name: 'crypto_ton_account_info', params: { id: jtRelations[key].minter.hex }, hash: '#overview'}"
                class="uk-text-nowrap"
              >
                {{ getTonMetadata[jtRelations[key].minter.hex]?.name }}
              </router-link>
            </td>
            <td class="uk-text-left uk-text-truncate">
              <AddressField 
                v-if="key in getTonAccounts" 
                :break_word="true" 
                :addr="getTonAccounts[key].address"
                :full="true"
              />
              <Loader
                v-else 
                :ratio="1"
              />
            </td>
            <td 
              class="uk-text-right uk-flex uk-flex-right" 
              style="gap: 0.5rem"
            >
              {{ `${formatTons(Number(getTonAccounts[key]?.jetton_balance ?? 0), jtList[key].decimals)} ${getTonMetadata[jtRelations[key].minter.hex]?.symbol ?? ''}` }}

              <p 
                v-if="getTonAccounts[key]?.fake" 
                class="uk-margin-remove uk-text-danger"
              >
                {{ $t('crypto.ton.fake') }}
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="!hidePagination">
      <ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
        <li :class="+pagination.page > 1 ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page -= 1"
          >
            <span uk-pagination-previous/>
          </a>
        </li>
        <li
          class="uk-padding-remove"
        >
          <div
            class="uk-input uk-text-center uk-form-blank uk-text-truncate"
            style="max-width: 250px; width: 40px"
            :style="`width: ${(('' + pagination.page).length + 2) * 14 }px`"
          >
            {{ pagination.page }}
          </div>
        </li>
        <li :class="pagination.page !== lastPage ? 'uk-enabled' : 'uk-disabled'">
          <a
            class="uk-position-center"
            @click="pagination.page += 1"
          >
            <span uk-pagination-next />
          </a>
        </li>
      </ul>
    </div>
  </div> 
</template>