<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom tgcp-item">
      <div
        v-if="user.bot"
        class="tgcp-item__badge"
      >
        <span>
          {{ $t('tgcp.channels.bot') }}
        </span>
      </div>
      <div class="uk-card-body uk-padding-remove-top">
        <h3
          class="uk-card-title uk-text-left uk-margin-top"
          style="margin-top: 25px!important;margin-left: 10px;"
        >
          {{ $t('tgcp.user_details.general_user_info_title') }}
          <div class="uk-float-right uk-text-small uk-flex">
            <button
              v-if="singleLocation && enabledGeoButton"
              class="uk-button uk-margin-small-right uk-button-default"
              @click="showNear"
            >
              <Loader
                v-if="!geolocations"
                :ratio="1"
              />
              <span v-else>
                {{ $t('tgcp.tasks.show-geolocation') }}
              </span>
            </button>
            <div>
              <SyncUserChats
                v-if="showSync"
                :user="user"
              />
            </div>
            <CardToProfileButton
              :card="userCard"
              :profile-name-by-default="profileName"
              :phoneResolvable="isPhoneUnresolved"
              :userIdForPhoneResolving="userCard.user_id"
              userTypeForPhoneResolving="messenger"
              userProviderForPhoneResolving="tgcp"
              boundary=".tgcp-item"
            />
            <AddStoredButton
              provider="tgcp"
              type="account"
              :data="user"
              class="uk-text-default"
              style="align-self: center;"
            />
          </div>
        </h3>
        <ul uk-tab>
          <li class="uk-active">
            <router-link
              :to="{
                query: {
                  info: 'current_tab'
                }
              }"
            >
              {{ $t('tgcp.channel_details.current-data') }}
            </router-link>
          </li>
          <li 
            v-if="withoutWasOnline(this.historical).length > 0"
          >
            <router-link
              :to="{
                query: {
                  info: 'history_tab'
                }
              }"
            >
              {{ $t('tgcp.channel_details.historical-data') }}
            </router-link>
          </li>
          <li
            v-if="linkedFrom.length > 0"
          >
            <router-link
              :to="{
                query: {
                  info: 'linked-from_tab'
                }
              }"
            >
              {{ $t('tgcp.channel_details.linked_from') }}
            </router-link>
          </li>
          <li
            v-if="linksTo.length > 0"
          >
            <router-link
              :to="{
                query: {
                  info: 'links-to_tab'
                }
              }"
            >
              {{ $t('tgcp.channel_details.links_to') }}
            </router-link>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li
            :class="{ 'uk-active': $route.query.info === 'current_tab' }"
          >
            <div
              class="uk-grid"
              data-uk-grid-margin
            >
              <div class="uk-width-1-4@m">
                <AvatarList :id="user.tg_id" />
                <Avatar
                  v-if="userAvatars.length === 0"
                  :avatar="user.avatar"
                  type="user"
                />
              </div>
              <div class="uk-width-expand uk-text-left">
                <h3 class="uk-margin-remove-top uk-margin-remove-bottom uk-flex uk-flex-middle" 
                    style="gap: 0.5rem"
                >
                  {{ fullName }}
                  <span v-if="user.emoji_document_id_str"
                        :data-emoji-id="user.emoji_document_id_str"
                        style="display: none;"
                  >
                    {{ user.emoji_document_id_str }}
                  </span>
                </h3>
                <div
                  v-if="hasFullNameAndNotDeleted"
                  class="uk-text-meta uk-text-left uk-margin-right"
                >
                  <button
                    class="uk-button-text uk-button uk-margin-small-top uk-margin-small-bottom uk-text-small uk-text-muted"
                    @click="throttledTranslateFullName"
                  >
                    <font-awesome-icon :icon="['fa', 'language']" />
                    <span v-if="translated['first_name'] || translated['last_name']">
                      {{ $t('tgcp.user_details.show-original') }}
                    </span>
                    <span v-else>
                      {{ $t('tgcp.user_details.translate') }}
                    </span>
                  </button>
                </div>
                <h4 class="uk-text-small uk-margin-remove-top uk-margin-remove-bottom uk-text-muted">
                  #{{ user.tg_id }}
                </h4>
                <h4
                  v-if="user.username"
                  class="uk-margin-remove-top uk-text-muted uk-text-small"
                >
                  <router-link :to="{ name: 'nicknameResults', params: { nickname: user.username } }">
                    @{{ user.username }}
                  </router-link>
                  <a
                    :href="`tg://resolve?domain=${user.username}`"
                    :uk-tooltip="$t('tgcp.tasks.open-in-telegram')"
                    class="uk-margin-small-left"
                  >
                    <font-awesome-icon :icon="['fab', 'telegram-plane']" />
                  </a>
                </h4>
                <div class="uk-margin">
                  <UserPhone
                    :id="String(user.tg_id)"
                    type="details"
                  />
                </div>

                <p
                  v-if="user.verified"
                  class="uk-text-success uk-margin-remove-top uk-inline uk-margin-small-right"
                >
                  <span uk-icon="icon: check"/> {{ $t('tgcp.user_details.verified') }}
                </p>
                <p v-if="user.support"
                   class="uk-text-success uk-margin-remove-top uk-inline uk-margin-small-right"
                >
                  <span uk-icon="icon: lifesaver"/> {{ $t('tgcp.user_details.support') }}
                </p>
                <p v-if="user.premium"
                   class="uk-inline uk-margin-remove-top uk-margin-small-right"
                >
                  <span uk-icon="icon: star"/> {{ $t('tgcp.user_details.premium') }}
                </p>
                <p
                  v-if="user.scam"
                  class="uk-text-danger uk-margin-remove-top  uk-inline"
                >
                  <span uk-icon="icon: ban"/> {{ $t('tgcp.user_details.scam') }}
                </p>
                <p
                  v-if="user.fake"
                  class="uk-text-danger uk-margin-remove-top uk-inline"
                >
                  <span uk-icon="icon: warning"/> {{ $t('tgcp.user_details.fake') }}
                </p>

                <p
                  v-if="user.bio"
                  class="uk-margin-remove-bottom"
                >
                  <span v-html="extractedDataBio" /></p>
                <button
                  v-if="user.bio"
                  class="uk-button-text uk-button uk-margin-small-top uk-text-small uk-text-muted"
                  @click="translateBio"
                >
                  <font-awesome-icon :icon="['fa', 'language']" />
                  <span v-if="translated['bio']">
                    {{ $t('tgcp.tasks.show-original') }}
                  </span>
                  <span v-else>
                    {{ $t('tgcp.tasks.translate') }}
                  </span>
                </button>
                <div
                  class="uk-width-1-1"
                  uk-grid
                >
                  <ul class="uk-list uk-text-small uk-width-1-2">
                    <li v-if="user.country_code">
                      {{ $t('tgcp.tasks.country') }}
                      <span :uk-tooltip="$t('tgcp.tasks.received-from-users-phone')">
                        {{ countries[user.country_code] }} ({{ user.country_code }})
                      </span>
                    </li>
                    <li v-if="user.last_update">
                      {{ $t('tgcp.user_details.last_update') }} : {{ dayjs(user.last_update) }}
                    </li>
                    <li class="">
                      {{ $t('tgcp.user_details.was_online') }}:
                      {{ user.was_online ? dayjs(user.was_online) : $t('tgcp.user_details.never') }}
                      <div uk-dropdown>
                        <ul class="uk-list uk-list-divider uk-text-nowrap uk-padding-remove uk-text-center">
                          <li
                            v-for="(history, i) in historical"
                            :key="i"
                          >
                            {{ dayjs(history.was_online) }}
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      {{ $t('tgcp.user_details.last_status') }}: {{ user.status }}
                    </li>
                  </ul>
                  <ul
                    v-if="singleLocation && geolocations"
                    class="uk-list uk-text-small uk-width-expand"
                  >
                    <li v-if="geolocations.first_date">
                      {{ $t('tgcp.user_details.first-geo-seen') }} {{ dayjs(geolocations.first_date) }}
                    </li>
                    <li v-if="geolocations.last_date">
                      {{ $t('tgcp.user_details.last-geo-seen') }} {{ dayjs(geolocations.last_date) }}
                    </li>
                  </ul>
                </div>

                <p v-if="user.geo && user.geo.length">
                  {{ $t('tgcp.user_details.user-seen-around-these-geo-positions') }}
                  <span
                    v-for="(geoPoint, index) in user.geo"
                    :key="geoPoint.id"
                  >
                    <span v-if="index">,</span>
                    <router-link
                      :to="{ name: 'global_map_by_coords', params: { coords: `${geoPoint.latitude},${geoPoint.longitude}` } }"
                    >
                      <span
                        v-if="!index"
                        uk-icon="icon: location"
                      />
                      <span>{{ geoPoint.latitude }}, {{ geoPoint.longitude }}</span>
                    </router-link>
                  </span>
                </p>
              </div>
            </div>
          </li>
          <li
            v-if="withoutWasOnline(historical).length > 0"
            :class="{ 'uk-active': $route.query.info === 'history_tab' }"
          >
            <HistoricalData :historical="withoutWasOnline(historical)" />
          </li>
          <li 
            v-if="linksTo.length > 0" 
            :class="{ 'uk-active': $route.query.info === 'links-to_tab' }"
          >
            <Relations
              :data="linksTo"
            />
          </li>
          <li
            v-if="linkedFrom.length > 0"
            :class="{ 'uk-active': $route.query.info === 'linked-from_tab' }"
          >
            <a
              name="linked-from_tab"
            />
            <Relations
              :data="linkedFrom"
            />
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="geolocations"
      ref="showNear"
      bg-close="false"
      class="uk-flex-top"
      uk-modal
    >
      <div
        v-if="map && user"
        class="uk-margin-remove uk-margin-auto-vertical userGeoMap"
      >
        <Map
          layer="tgcpChats"
          :near="geolocations"
          :user="user"
          :modal="$refs.showNear"
        />
      </div>
    </div>
  </div>
</template>
<script>
import UIkit from 'uikit'
import { mapGetters, mapActions } from 'vuex'

import AvatarList from '@/components/TGCP/AvatarList'
import UserPhone from '@/components/TGCP/UserDetails/UserPhone'
import extractData from '@/utils/dataExtraction'
import Map from '@/components/map/MapUserGeo'
import countries from '@/utils/countryCodesNamesFull.json'
import HistoricalData from '@/components/TGCP/UserDetails/HistoricalData.vue'
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'
import emojiBroker from '@/utils/emoji-broker'
import translate from '@/mixins/translate'

import Loader from '@/components/TGCP/Loader'
import GeneralUser from '@/utils/providers/generalUser'
import throttle from 'lodash/throttle'
import '@lottiefiles/lottie-player/dist/tgs-player'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TgcpUserGeneralInfo',
  components: {
    Loader,
    AvatarList,
    UserPhone,
    HistoricalData,
    Map,
    AddStoredButton,
    Avatar: defineAsyncComponent(() => import('@/components/TGCP/Avatar')),
    CardToProfileButton: defineAsyncComponent(() => import('@/components/CardToProfileButton')),
    Relations: defineAsyncComponent(() => import('@/components/Relations.vue')),
    SyncUserChats: defineAsyncComponent(() => import('@/components/TGCP/UserDetails/SyncUserChats.vue'))
  },
  mixins: [translate],
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      countries,
      historical: [],
      geolocations: null,
      singleLocation: null,
      map: false,
      modalInstance: null,
      localUser: { ...this.user },
      enabledGeoButton: false,
      translated: {}
    }
  },
  computed: {
    ...mapGetters('tgcp', ['allUsers', 'userAvatars', 'linkedFrom', 'linksTo']),
    ...mapGetters('fetcher', ['allAccounts']),
    geo () {
      return this.$store.state.tgcp.users[this.user.tg_id].geo
    },
    showSync () {
      // eslint-disable-next-line
      return this.user?.stats?.all_chats_count || this.user?.username
    },
    isPhoneUnresolved () {
      const hasPhone = this.user.has_phone
      const phone = this.user.phone
      return hasPhone && !phone
    },
    extractedDataBio () {
      if (!this.user) return
      return extractData(this.localUser.bio)
    },
    fullName () {
      if (this.user.first_name || this.user.last_name) return `${this.localUser.first_name || ''} ${this.localUser.last_name || ''}`
      if (!this.user.first_name && !this.user.last_name && !this.user.username) return 'DELETED USER'
      return ''
    },
    profileName () {
      return this.user.phone || ((this.user.first_name && this.user.last_name) ? this.user.first_name + this.user.last_name : 'telegram_user_' + this.user.tg_id)
    },
    userCard () {
      const card = new GeneralUser()
      if (this.user.avatar) {
        card.avatar = this.user.avatar.file_location
      }
      if (this.user.username) {
        card.name = this.user.username
      }
      if (this.user.first_name || this.user.last_name) {
        card.fullName = [this.user.first_name, this.user.last_name].filter(i => i).join(' ')
      }
      if (this.user.bio) {
        card.bio = this.user.bio
      }
      card.type = 'telegram'
      card.genericType = 'messenger'
      card.user_id = this.user.tg_id
      card.phone = this.user.phone

      return card
    },
    hasFullNameAndNotDeleted () {
      return this.fullName && this.fullName !== 'DELETED USER'
    }
  },
  methods: {
    ...mapActions('tgcp', ['getUserHistorical', 'fetchUserGeo', 'fetchUserGeoNear', 'fetchUserGeoNearExists', 'fetchUserRelations']),
    showNear () {
      this.map = true
      UIkit.modal(this.$refs.showNear).show()
    },
    hideNear () {
      UIkit.modal(this.$refs.showNear).hide()
    },
    withoutWasOnline (val) {
      return val.filter(el => {
        const filtered = Object.keys(el).filter(key => el[key])
        if (filtered.length < 4 && el.was_online && el.status) {
        } else if (filtered.length < 3 && (el.status || el.was_online)) {
        } else {
          return el
        }
      })
    },
    async update () {
      this.getUserHistorical(this.user.tg_id)
        .then(data => {
          this.historical = data
        })
        .catch(console.error)
      this.fetchUserGeo(this.user.tg_id)
      await this.fetchUserGeoNearExists(this.user.tg_id).then(data => {
        if (data?.geolocation.length) {
          this.singleLocation = data.geolocation[0]
          this.enabledGeoButton = true
        }
      })
      await this.fetchUserGeoNear(this.user.tg_id)
        .then(geolocations => {
          if (geolocations.coords.length) { 
            this.geolocations = geolocations
            this.enabledGeoButton = true
          } else if (this.singleLocation) {
            this.geolocations = {
              coords: [{
                coord: [{
                  lat: this.singleLocation.geolocation.latitude,
                  lng: this.singleLocation.geolocation.longitude,
                  distance: this.singleLocation.distance
                }],
                timestamp_unix: this.singleLocation.created_at,
                type: 'area'
              }],
              first_date: this.singleLocation.created_at,
              first_date_unix: new Date(this.singleLocation.created_at).getTime(),
              last_date: this.singleLocation.created_at,
              last_date_unix: new Date(this.singleLocation.created_at).getTime()
            }
          }
        })
      if (typeof this.user?.['tg_id'] !== 'undefined') {
        this.fetchUserRelations(this.user['tg_id'])
      }
    },
    async translateFullName () {
      const translate = await this.translateProps(['first_name', 'last_name'], this.localUser, this.user)
      this.localUser.first_name = translate.first_name || this.localUser.first_name
      this.localUser.last_name = translate.last_name || this.localUser.last_name
    },
    async translateBio () {
      const { bio } = await this.translateProps(['bio'], this.localUser, this.user)
      this.localUser.bio = bio || this.localUser.bio
    }
  },
  created () {
    this.throttledTranslateFullName = throttle(this.translateFullName, 500)
  },
  async mounted () {
    this.update()

    emojiBroker.run(this.$el)
  }
}
</script>
<style scoped>
.userGeoMap {
  height: 90vh;
}
</style>

<style lang="scss" scoped>
.tgcp-item {
  position: relative;
  overflow: hidden;

  &__badge {
    background-color: #444;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.8);
    height: 100px;
    left: -50px;
    position: absolute;
    top: -50px;
    width: 100px;
    color: white;
    line-height: 15;
    font-size: 0.7rem;
    -webkit-transform: rotate(-45deg);
  }
}
</style>
