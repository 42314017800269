<template>
  <div class="uk-card uk-card-default uk-card-body">
    <h3>
      {{ $t('whatsapp.tasks.add_task_title') }}
    </h3>
    <form @submit.prevent="submit">
      <div>
        <Loader v-if="processing"/>
        <div v-else>
          <div class="uk-inline uk-width-1-1">
            <input
              v-model.trim="task"
              type="text"
              :placeholder="$t('hoop.tasks.link') + ' (https://hoop.page.link/page)' + ' ' + $t('or') + ' ' + $t('hoop.tasks.name') + ' (@name)'"
              :class="{'uk-input': true, 'uk-form-danger': invalidTask}"
            />
          </div>
          <textarea
            v-model="description"
            class="uk-textarea uk-margin-top"
            :placeholder="$t('twitter.tasks.task-description')"
          />
        </div>
      </div>
      <div
        class="uk-margin-top uk-text-center"
      >
        <div>
          <a
            uk-icon="icon: warning; ratio: 1"
            :value="null"
            :class="priority ? 'uk-text-primary' : 'uk-text-muted'"
            :uk-tooltip="`title: ${$t('tgcp.tasks.priority_field_title')}`"
            @click="priority = !priority"
          />
        </div>
      </div>
      <div class="uk-margin">
        <button
          class="uk-button uk-button-primary"
          type="submit"
        >
          {{ $t('twitter.tasks.submit') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'

import { mapActions } from 'vuex'
export default {
  name: 'HoopAddTasks',
  components: {
    Loader
  },
  data () {
    return {
      task: '',
      description: '',
      priority: false,
      processing: false
    }
  },
  computed: {
    link () {
      try {
        const task = this.task
        const EXP = /(https?:?\/\/hoop\.page\.link\/(?<URN>[0-9a-z]+))|(?<NAME>@[^\s]+)/i
        const match = task.match(EXP)
        const groups = match?.groups
        const URN = groups?.URN
        const NAME = groups?.NAME
        return URN || NAME
      } catch (error) {
        console.debug(error)
      }
      return null
    },
    invalidTask () {
      try {
        return this.task && !this.link
      } catch (error) {
        console.debug(error)
      }
      return null
    }
  },
  methods: {
    ...mapActions('hoop', [
      'addTask'
    ]),
    validate (task = this.task) {
      return { task }
    },
    submit () {
      if (!this.task || !this.link) {
        return
      }
      this.processing = true
      let task = this.link
      if (!task.startsWith('@')) {
        task = '/' + this.link
      }
      return this.addTask({ task, description: this.description, priority: this.priority ? 5 : 1 })
        .then(({ status }) => {
          switch (status) {
              case 200:
                this.$notify({ group: 'general', text: this.$t('twitter.tasks.already-exists'), type: 'warn' })
                break
              case 201:
                this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('whatsapp.tasks.task_added_msg'), type: 'success' })
                break
          }
          this.$emit('added')
          this.task = ''
          this.description = ''
          this.priority = false
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>
