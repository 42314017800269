<template>
  <div class="uk-flex uk-flex-middle uk-padding-small">
    <p 
      v-if="startLine !== null" 
      class="uk-margin-remove"
    >
      {{ $t(startLine ?? 'crypto.general.show') }}
    </p>
    <select 
      :value="itemCount" 
      class="uk-select uk-margin-small-left" 
      style="min-width: 70px" 
      @change="$event => emitValue($event)"
    >
      <option 
        v-for="option in options"
        :key="option"
      >
        {{ option }}
      </option>
    </select>
    <template v-if="amount && isNumeric(itemCount)">
      <p 
        class="uk-margin-remove-vertical uk-margin-small-left" 
        style="white-space: nowrap;"
      >
        {{ $t('crypto.general.items') + ' ' + amount + (amount > Number(itemCount) ? ' ·' : '') }}
      </p>
    </template>
  </div>
</template>

<script>
import { isNumeric } from '@/utils/ton-filters'

export default {
  name: 'TonSelector',
  props: {
    startLine: {
      type: String,
      required: false
    },
    amount: {
      type: String,
      required: false
    },
    itemCount: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    isNumeric,
    emitValue (e) {
      this.$emit('setValue', e.target.value)
    }
  }
}
</script>