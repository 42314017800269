<template>
  <div class="uk-card uk-card-default uk-card-small channel">
    <div class="uk-card-body">
      <div class="uk-align-right">
        <AddStoredButton
          v-if="showStoredButton"
          provider="databases"
          type="organization"
          :data="company"
        />
      </div>
      <div
        v-if="company.inactive"
        class="channel_badge"
      >
        <span>
          {{ $t('databases.closed') }}
        </span>
      </div>
      <div class="uk-width-expand uk-text-center uk-margin-bottom">
        <span class="uk-align-right uk-text-small uk-text-muted">
          #{{ company.company_number }}
        </span>
        <h3 class="uk-margin-remove uk-margin-small-left uk-text-break">
          <router-link
            :to="{
              name: 'organization_details',
              params: {
                number: company.company_number,
                code: company.jurisdiction_code
              }
            }"
          >
            {{ company.name }}
          </router-link>
        </h3>
        <span class="uk-text-small uk-text-right uk-margin-remove-bottom uk-text-muted">
          {{ company.company_type }}
        </span>
      </div>
      <div
        class="uk-grid-small uk-flex-middle uk-text-break"
        uk-grid
      >
        <div
          v-if="company.current_status || company.dissolution_date || company.registered_address_in_full"
          class="uk-width-expand"
        >
          <ul class="uk-list uk-text-left">
            <li v-if="company.current_status">
              {{ $t('databases.status') }} {{ company.current_status }}
            </li>
            <li v-if="company.incorporation_date">
              {{ $t('databases.incorporation-date') }} {{ company.incorporation_date }}
            </li>
            <li v-if="company.dissolution_date">
              {{ $t('databases.dissolution-date') }} {{ company.dissolution_date }}
            </li>
            <li v-if="company.registered_address_in_full">
              {{ $t('databases.registration-address') }} {{ company.registered_address_in_full }}
            </li>
          </ul>
        </div>
        <div v-if="company.current_status || company.dissolution_date || company.registered_address_in_full">
          <hr class="uk-divider-vertical uk-width-auto"/>
        </div>
        <div class="uk-width-expand">
          <ul class="uk-list uk-text-left">
            <li v-if="company.source.publisher">
              {{ $t('databases.publisher') }} <a
                :href="company.source.url"
                target="blank"
                rel="noopener noreferrer"
              >
                {{ company.source.publisher }}
              </a>
            </li>
            <li v-if="company.source.terms_url">
              {{ $t('databases.terms') }}
              <a
                :href="company.source.terms_url"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ company.source.terms }}
              </a>
            </li>
            <li v-if="company.source.registered_address_in_full">
              {{ $t('databases.registration-date') }} {{ company.registered_address_in_full }}
            </li>
            <li v-if="company.agent_name">
              {{ $t('databases.agent') }}
              <span>
                {{ company.agent_name }}
              </span>
              <div
                v-if="company.agent_address"
                class="uk-text-muted uk-text-small"
              >
                {{
                  [company.agent_address.postal_code, company.agent_address.region, company.agent_address.locality, company.agent_address.street_address].filter(el => el).join(' ') || company.agent_address }}
              </div>
            </li>
            <li v-if="company.registered_address_in_full">
              {{ $t('databases.registered-address') }} {{ company.registered_address_in_full }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddStoredButton from '@/components/cases/Stored/AddStoredButton'

export default {
  name: 'CompanyListItem',
  components: {
    AddStoredButton
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    showStoredButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
    .channel {
        position: relative;
        overflow: hidden;
    }
    .channel_badge {
        background-color: rgb(99, 0, 0);
        box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
        height: 100px;
        left: -50px;
        position: absolute;
        top: -50px;
        width: 100px;
        color: white;
        line-height: 15;
        font-size: 0.7rem;
        -webkit-transform: rotate(-45deg);
    }
</style>
