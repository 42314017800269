import i18n from '@/i18n'
import config from '@/config'
import { apiRequest, getQueryString } from '@/utils/api'
import unicode from '@/utils/unicode'
import getSymbol from '@/utils/getSymbol'
import router from '@/router'
import countryNames from '@/utils/countryCodesNamesFull'
import { notify } from '@kyvg/vue3-notification'
const { servers: { whatsapp } } = config
const $t = str => i18n.global.t(str)

export default {
  namespaced: true,
  state: {
    groups: {},
    isLoaded: {
    },
    messages: {},
    pagination: {
      currentPage: 1,
      nextPage: false
    },
    queryParams: {
      limit: 20
    },
    defaultParams: {
      channellist_limit: 21,
      channellist_mini_limit: 7
    },
    stats: {},
    parsedStats: [],
    tasks: {},
    users: {},
    knownLinks: []
  },
  getters: {
    allGroups: state => state.groups,
    allListTasks: state => state.listTasks,
    allTasks: state => state.tasks,
    allUsers: state => state.users,
    currentPage: state => {
      return state.pagination.currentPage
    },
    isLoaded: state => state.isLoaded,
    isNextPage: state => state.pagination.nextPage,
    isPrevPage: state => (state.pagination.currentPage === 1 ? 'uk-disabled' : 'uk-active'),
    limit: state => state.queryParams.limit,
    stats: state => state.stats,
    knownLinks: state => state.knownLinks,
    parsedStats: state => state.parsedStats
  },
  mutations: {
    addParamToQuery: (state, param) => {
      const path = router.currentRoute.value.fullPath
      const old = router.currentRoute.value.query
      if (typeof param === 'object') {
        Object.keys(param).forEach(key => !param[key] && delete param[key])
        param = Object.assign({}, old, param)
      }
      router.push({ path: path, query: param })
    },
    clearTasksView: state => {
      delete state.listTasks
      state.listTasks = {}
    },
    deleteTask: (state, taskId) => {
      delete state.tasks[taskId]
      for (var i in state.listTasks) {
        for (var j in state.listTasks[i]) {
          if (state.listTasks[i][j] === taskId) {
            delete state.listTasks[i][j]
          }
        }
      }
    },
    editTask (state, task) {
      state.tasks[task.id] = task
    },
    fetchAllGroups (state, groups) {
      if (!groups) return
      groups.forEach(group => state.groups[group.id] = group)
    },
    unsetGroup (state, groupId) {
      delete state.groups[groupId]
    },
    fetchAllMessages (state, messages) {
      if (!messages) return
      messages.map(message => state.messages[message.id] = message)
    },
    fetchAllTasks (state, tasks) {
      tasks.map(task => {
        if (!state.tasks[task.id]) state.tasks[task.id] = task
      })
    },
    fetchAllUsers (state, users) {
      if (!users) return
      users.map(user => state.users[user.phone] = user)
    },
    addEntityDataStats (state, payload) {
      if (!payload.tg_id) return
      state.groups[payload.tg_id].entity_data = payload
    },
    fetchGroupActivityById (state, data) {
      if (!data.id) return
      if (!state.groups[data.id].activity) state.groups[data.id].activity = {}
      state.groups[data.id].activity[data.key] = data
    },
    fetchStats: (state, stats) => {
      state.stats = stats
    },
    fetchTask (state, task) {
      state.tasks[task.id] = task
    },
    fetchTasksToView: (state, { taskId, page }) => {
      if (taskId.length === state.queryParams.limit) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listTasks[page] = taskId
    },
    fetchUserActivityByPhone (state, data) {
      if (!data.phone) return
      if (!state.users[data.phone].activity) state.users[data.phone].activity = {}
      state.users[data.phone].activity[data.key] = data
    },
    setPage: (state, page) => {
      state.pagination.currentPage = page
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    clearKnownLinks: state => {
      delete state.knownLinks
      state.knownLinks = []
    },
    setKnownLinks: (state, links) => {
      state.knownLinks = links
    },
    updateParsedStats: (state, stats) => {
      state.parsedStats = stats
    }
  },
  actions: {
    fetchParsedStats ({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiRequest('/users/countries', 'GET', {}, config.servers.whatsapp)
          .then(res => {
            const data = res.data
              .map(entry => ({
                'users_count': entry?.count,
                'country_code': entry?.['country_name'],
                'country_name': countryNames?.[entry?.['country_name']]
              }))
              .sort((a, b) => a.users_count < b.users_count)
            commit('updateParsedStats', data)
            resolve(data)
          })
          .catch(err => console.debug(err))
      })
    },
    fetchMessageLink ({ commit, state, rootState }, { messageId, groupId, userId }) {
      return new Promise((resolve, reject) => {
        let path = 'groups'
        if (userId) {
          path = 'users'
        }
        apiRequest(`/${path}/${groupId || userId}/messages/${messageId}/offset?order_by=timestamp&order_type=desc`, 'GET', {}, whatsapp)
          .then(data => {
            let offset = data.data
            const limit = state.defaultParams.channellist_limit
            const page = Math.floor(offset / (limit - 1) + 1)
            offset = (limit - 1) * page - (limit - 1)
            resolve({
              id: groupId,
              message_id: messageId,
              message_pagination: { page, offset },
              tab: 'messages_tab'
            })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async addTask ({ commit, dispatch, rootState }, newTask) {
      try {
        const { status, data } = await apiRequest(`tasks/`, 'POST', {}, whatsapp, newTask)
        switch (status) {
          case 200:
            notify({ group: 'general', text: $t('whatsapp.tasks.task_exist_msg'), type: 'warn' })
            break
          case 201:
            notify({ group: 'general', title: $t('notifications.success'), text: $t('whatsapp.tasks.task_added_msg'), type: 'success' })
            break
          case 500:
            notify({ group: 'general', title: $t('notifications.error'), text: 'Unknown error', type: 'error' })
            break
        }
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        } else {
          notify({ group: 'general', title: 'Service unavailable', type: 'error' })
        }
        throw new Error(err)
      }
    },
    async massTaskAdd ({ commit, dispatch, rootState }, { tasks }) {
      try {
        /**
        * @type {[]}
        */
        let created = []
        /**
        * @type {[]}
        */
        let exists = []
        /**
        * @type {[]}
        */
        let errors = []
        for (let i = 0; i < tasks.length; i += 400) {
          try {
            var { status, data } = await apiRequest(`/tasks/batch`, 'POST', {}, whatsapp, tasks.slice(i, i + 400).map(task => {
              if (task.priority) {
                task.priority = 8
              } else {
                task.priority = 1
              }
              return task
            }))
            created = [...new Set(data.created)]
            exists = [...new Set(data.exists)]
            errors = [...new Set(data.errors)]
          } catch { continue }
        }
        switch (status) {
          case 200:
            const text = `Created: ${created.length}. Exists: ${exists.length}. Errors: ${errors.length}.`
            let type = 'success'
            if (!created.length) type = 'info'
            notify({ group: 'general', text, type })
            break
        }
        return { created, exists, errors }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        } else {
          notify({ group: 'general', title: 'Service unavailable', type: 'error' })
        }
        throw err
      }
    },
    addParamToQuery ({ commit }, param) {
      const path = router.currentRoute.value.fullPath
      const old = router.currentRoute.value.query
      if (typeof param === 'object') {
        param = Object.assign({}, old, param)
      }
      const hasPush = getSymbol(param, 'hasPush')
      if (!hasPush) {
        router.replace({ path: path, query: param })
      }
    },
    async deleteTask ({ commit, dispatch, rootState }, taskId) {
      try {
        await apiRequest(`tasks/` + taskId, 'DELETE', {}, whatsapp)
      } catch (err) {
        notify({ group: 'general', title: this.$t('whatsapp.tasks.error_deleting'), type: 'error' })
        throw new Error(err)
      }
    },
    async editTask ({ commit, dispatch }, { id, ...task }) {
      if (!id) return notify({ group: 'general', title: 'id required', type: 'error' })
      try {
        const { status, data: taskEdited } = await apiRequest(`tasks/` + id, 'PUT', {}, whatsapp,
          {
            ...task
          })
        commit('editTask', taskEdited)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Priority changed successfully', type: 'success' })
            break
        }
        return taskEdited
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        } else {
          notify({ group: 'general', title: 'Service unavailable', type: 'error' })
        }
        throw err
      }
    },
    addEntitiesTask ({ commit, state, rootState, dispatch }, chatId) {
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/wa/entities/` + chatId, 'POST', {}, config.servers.fetcher_proxy)
          .then(data => {
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    fetchEntitiesForTable ({ commit, state, rootState, dispatch }, { chatId, type, filter }) {
      let params = '?for_vue_server=1'
      if (filter['type']) params += '&type=' + filter['type']
      if (type) params += '&type=' + type
      if (filter['offset']) params += '&offset=' + filter['offset']
      if (filter['page']) params += '&page=' + filter['page']
      if (filter['limit']) params += '&limit=' + filter['limit']
      if (filter['ascending']) params += '&ascending=' + filter['ascending']
      if (filter['byColumn']) params += '&byColumn=' + filter['byColumn']
      if (filter['orderBy']) params += '&orderBy=' + filter['orderBy']
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/wa/entities/` + chatId + params, 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            let obj = data.data
            for (let i in obj['data']) {
              obj['data'][i]['joinlink'] = obj['data'][i]['value']
              if (obj['data'][i]['type'] === 'email') {
                obj['data'][i]['email'] = obj['data'][i]['value']
              }
              if (obj['data'][i]['type'] === 'phone') {
                obj['data'][i]['phone'] = obj['data'][i]['value']
              }
            }
            resolve(obj)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    fetchEntities ({ commit, state, rootState, dispatch }, { chatId, filter }) {
      let params = '?'
      if (filter['type']) params += '&type=' + filter['type']
      if (filter['offset']) params += '&offset=' + filter['offset']
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/wa/entities/` + chatId + params, 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            if (!filter && !filter['type']) {
              data.data.tg_id = chatId
              commit('addEntityDataStats', data.data)
            }
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    exportEntities ({ commit, state, rootState, dispatch }, { chatId, filter }) {
      let params = '?'
      if (filter['type']) params += '&type=' + filter['type']
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/wa/entities/` + chatId + '/export' + params, 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    fetchEntitiesCount ({ commit, state, rootState, dispatch }, chatId) {
      commit('startLoad', `channel_${chatId}_entity_data`)
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/wa/entities/` + chatId + '/count', 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            data.data.tg_id = chatId
            commit('addEntityDataStats', data.data)
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('stopLoad', `channel_${chatId}_entity_data`)
          })
      )
    },
    fetchAllGroups ({ commit }, params) {
      let query = params
      const withoutFetchingToView = query?.withoutFetchingToView
      const caseSensitive = query?.caseSensitive
      if (withoutFetchingToView) {
        const { withoutFetchingToView, ...currentParams } = query
        query = currentParams
      }
      if (caseSensitive) {
        const { caseSensitive, ...currentParams } = query
        query = currentParams
      }
      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      return new Promise((resolve, reject) => {
        if (!withoutFetchingToView) {
          commit('startLoad', 'groups')
        }
        const queryString = getQueryString(query, caseSensitive)
        apiRequest(`groups?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let groups = res.data.data
            if (!withoutFetchingToView) {
              commit('fetchAllGroups', groups)
            }
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            if (!withoutFetchingToView) {
              commit('stopLoad', 'groups')
            }
          })
      })
    },
    fetchAllTasks ({ dispatch, commit, state, rootState }, { query } = {}) {
      commit('startLoad', 'tasks')
      if (!query) query = { ...state.queryParams, ...router.currentRoute.value.query }
      if (!query.order_type || !query.order_by) {
        query = { ...query, order_type: 'desc', order_by: 'created' }
      }
      const queryString = getQueryString(query)
      return apiRequest(`tasks/?${queryString}`, 'GET', {}, whatsapp).then(({ data: tasks }) => {
        return dispatch('fetchTasksToView', tasks)
          .then(() => {
            tasks.data.forEach(task => commit('fetchTask', task))
            return tasks
          })
          .finally(() => commit('stopLoad', `tasks`))
      })
    },
    fetchAllUsers ({ commit }, query) {
      commit('startLoad', 'users')

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = getQueryString(query, true)
      return apiRequest(`users?${queryString}`, 'GET', {}, whatsapp)
        .then(async ({ data, data: { data: users, meta } }) => {
          try {
            const { data: countries } = await apiRequest(`users/countries`, 'GET', {}, whatsapp)
            meta.countries = countries
          } catch {}
          commit('fetchAllUsers', users)
          return data
        })
        .finally(() => commit('stopLoad', 'users'))
    },
    async recheckGroup ({ commit, state, dispatch }, groupId) {
      commit('unsetGroup', groupId)
      try {
        await dispatch('fetchGroupByID', groupId)
        await dispatch('fetchEntitiesCount', groupId)
      } catch (err) {
        console.debug(err)
      }
    },
    async fetchGroupByID ({ commit, state }, groupId) {
      if (state.groups[groupId]) return state.groups[groupId]
      commit('startLoad', 'group_' + groupId)
      try {
        const { data } = await apiRequest(`groups/${groupId}`, 'GET', {}, whatsapp)
        commit('fetchAllGroups', [data])
        return data
      } finally {
        commit('stopLoad', 'group_' + groupId)
      }
    },
    fetchGroupUsers ({ commit }, { query, type, groupId }) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'users')
        const queryString = getQueryString(query, true)
        apiRequest(`groups/${groupId}/${type}?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let users = res.data.data
            commit('fetchAllUsers', users)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'users'))
      })
    },
    fetchTasksToView ({ dispatch, commit, state }, tasks) {
      const offsetPassed = router.currentRoute.value.query?.offset ?? 0
      let page = 0
      if (offsetPassed) {
        page = Math.floor((offsetPassed / state.queryParams.limit) + 1)
      } else page = ++Object.keys(state.listTasks).length
      if (Number(offsetPassed) === 0) page = 1
      if (tasks) {
        const taskId = tasks.data.map(({ id }) => id)
        commit('fetchTasksToView', { taskId, page })
      }
      return Promise.resolve()
    },
    fetchTaskById (_, id) {
      return apiRequest(`/tasks/${id}`, 'GET', {}, whatsapp)
    },
    async fetchUserActivityByPhone ({ commit, state, dispatch }, { phone, type }) {
      commit('startLoad', `user_${phone}_activity_${type}`)
      if (!state.users[phone]) {
        try {
          await dispatch('fetchUserByPhone', phone)
        } catch (err) {
          throw err
        }
      }
      try {
        var res = await apiRequest(`users/${phone}/activity/${type}`, 'GET', {}, whatsapp)
        var data = res.data
        data.phone = phone
        data.key = type
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `user_${phone}_activity_${type}`)
      }
      commit('fetchUserActivityByPhone', data)
      return res.data.activity
    },
    fetchUserByPhone ({ commit, state }, phone) {
      return new Promise((resolve, reject) => {
        if (state.users[phone]) return resolve(state.users[phone])
        commit('startLoad', 'user_' + phone)
        apiRequest(`users/${phone}`, 'GET', {}, whatsapp)
          .then(res => {
            let users = res.data
            commit('fetchAllUsers', [users])
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'user_' + phone))
      })
    },
    fetchUserGroups ({ commit }, { query, phone }) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'groups')
        const queryString = getQueryString(query, true)
        apiRequest(`users/${phone}/groups?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let groups = res.data.data
            commit('fetchAllGroups', groups)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'groups'))
      })
    },
    fetchUserOwnedGroups ({ commit }, { query, phone }) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'groups')
        const queryString = getQueryString(query, true)
        apiRequest(`users/${phone}/ownedgroups?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let groups = res.data.data
            commit('fetchAllGroups', groups)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'groups'))
      })
    },
    fetchAllMessages ({ commit }, query) {
      if (query.message) {
        query.message = unicode.normalize(query.message)
      }

      return new Promise((resolve, reject) => {
        commit('startLoad', 'messages')
        const queryString = getQueryString(query)
        apiRequest(`messages?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages'))
      })
    },
    async fetchGroupActivityById ({ commit, state, dispatch }, { id, type }) {
      commit('startLoad', `group_${id}_activity_${type}`)
      if (!state.groups[id]) {
        try {
          await dispatch('fetchGroupByID', id)
        } catch (err) {
          throw err
        }
      }
      try {
        var res = await apiRequest(`groups/${id}/activity/${type}`, 'GET', {}, whatsapp)
        var data = res.data
        data.id = id
        data.key = type
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `group_${id}_activity_${type}`)
      }
      commit('fetchGroupActivityById', data)
      return res.data.activity
    },
    async fetchChannelAudienceByGroup ({ commit, state, dispatch }, id) {
      commit('startLoad', `group_${id}_activity_country`)
      if (!state.groups[id]) {
        try {
          await dispatch('fetchGroupByID', id)
        } catch (err) {
          throw err
        }
      }
      try {
        var res = await apiRequest(`groups/${id}/members/countries`, 'GET', {}, whatsapp)
        var data = res.data
        data.id = id
        data.key = 'country'
        commit('fetchGroupActivityById', data)
        return data
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `group_${id}_activity_country`)
      }
    },
    fetchGroupMessages ({ commit, dispatch }, { query, groupId }) {
      return new Promise(async (resolve, reject) => {
        let localQuery = query
        let offset
        if (query.messageId && groupId) {
          offset = (await dispatch('fetchMessageLink', {
            messageId: query.messageId,
            groupId: groupId
          })).message_pagination.offset
          const { messageId, ...withoutMessageId } = localQuery
          localQuery = withoutMessageId
        }
        localQuery = offset ? { ...localQuery, offset } : localQuery
        commit('startLoad', 'messages')
        const queryString = getQueryString(localQuery)
        apiRequest(`groups/${groupId}/messages?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages'))
      })
    },
    fetchStats ({ commit }) {
      commit('startLoad', 'stats')
      apiRequest(`stats`, 'GET', {}, whatsapp)
        .then(res => {
          commit('fetchStats', res.data)
        })
        .finally(() => commit('stopLoad', 'stats'))
    },
    fetchUsersMessages ({ commit, dispatch }, { query, phone }) {
      return new Promise(async (resolve, reject) => {
        commit('startLoad', 'messages')
        let localQuery = query
        let offset
        if (query.messageId && phone) {
          offset = (await dispatch('fetchMessageLink', {
            messageId: query.messageId,
            userId: phone
          })).message_pagination.offset
          const { messageId, ...withoutMessageId } = localQuery
          localQuery = withoutMessageId
        }
        localQuery = offset ? { ...localQuery, offset } : localQuery
        const queryString = getQueryString(localQuery)
        apiRequest(`users/${phone}/messages?${queryString}`, 'GET', {}, whatsapp)
          .then(res => {
            let messages = res.data.data
            commit('fetchAllMessages', messages)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'messages'))
      })
    },
    joinChat ({ dispatch, commit, state, rootState }, joinLink) {
      return new Promise((resolve, reject) => {
        let url = `/tools/wa/add?link=${joinLink}`
        apiRequest(url, 'GET', {}, config.servers.fetcher_proxy)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.debug(err)
            if (err.response.status === 404) console.error(err)
            reject(err)
          })
      })
    },
    refetchAllTasks ({ dispatch, commit, state, rootState }, query) {
      if (!query) query = { ...state.queryParams, ...router.currentRoute.value.query }
      if (!query.order_type || !query.order_by) {
        query = { ...query, order_type: 'desc', order_by: 'created' }
      }
      const queryString = getQueryString(query)
      return apiRequest(`/tasks/?${queryString}`, 'GET', {}, whatsapp).then(({ data: tasks }) => {
        return dispatch('fetchTasksToView', tasks).then(() => {
          tasks.data.forEach(task => commit('fetchTask', task))
          return Promise.resolve(tasks)
        })
      })
    },
    setPage ({ commit }, page) {
      return commit('setPage', page)
    },
    fetchKnownLinks: async ({ dispatch, commit }, { groupId }) => {
      try {
        commit('clearKnownLinks')
        const { data } = await apiRequest(`/groups/${groupId}/invites`, 'GET', {}, whatsapp)
        commit('setKnownLinks', data)
      } catch (error) {
        console.debug(error)
      }
    },
    async resolveUserPhone ({ commit, state, dispatch, rootState }, phone) {
      commit('startLoad', `user_resolve_${phone}`)
      try {
        const { data } = await apiRequest(`users/${phone}`, 'GET', {}, whatsapp)
        return data
      } catch (error) {
        console.debug(error)
      } finally {
        commit('stopLoad', `user_resolve_${phone}`)
      }
    }
  }
}
