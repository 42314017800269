<template>
  <div>
    <div
      v-if="showDemo"
      class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-top"
    >
      <div class="uk-card-title">
        {{ $t('news.ASIRIS-monitoring-platform') }}<br/>{{ $t('news.welcome-to-DEMO-environment') }}
      </div>
      <div class="uk-text-left uk-margin-top">
        {{ $t('news.ASIRIS-is-a-Deep-Web') }}
      </div>
      <div class="uk-text-left">
        {{ $t('news.demo-environment-limitations') }}
      </div>
      <ul class="uk-list uk-text-left uk-list-bullet">
        <li>
          {{ $t('news.no-data-export-features') }}
        </li>
        <li>
          {{ $t('news.Limited-TGID-to-MSISDNs') }}
        </li>
        <li>
          {{ $t('news.Limited-ASIRIS-data-modules') }}
        </li>
        <li>
          {{ $t('news.Limited-analytical-capabilities') }}
        </li>
      </ul>
      <div class="uk-text-left">
        {{ $t('news.Please-share-your-feedback') }}
      </div>
    </div>
    <div
      v-else-if="!hideWhatIsAsiris"
      class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-top"
    >
      <div class="uk-card-title">
        {{ $t('news.what-is-asiris') }}
      </div>
      <div
        class="uk-text-left uk-margin-top"
      >
        {{ $t('news.asiris-is-a-deep-web') }}
      </div>
      <div class="uk-text-left uk-margin-top">
        {{ $t('news.asiris-detects-and-controls') }}
      </div>
      <h4 class="uk-text-center">
        {{ $t('news.what-are-the-current') }}
      </h4>
      <div
        class="uk-text-left"
      >
        {{ $t('news.the-platform') }}
      </div>
      <div
        class="uk-margin-top uk-text-left"
      >
        {{ $t('news.data-sources') }}:
      </div>
      <ul class="uk-list uk-text-left uk-list-bullet uk-list-collapse">
        <li>
          {{ $t('news.social-networks') }}
        </li>
        <li>
          {{ $t('news.messengers') }}
        </li>
        <li>
          {{ $t('news.corporate-registers') }}
        </li>
        <li>
          {{ $t('news.forums-and-ads') }}
        </li>
        <li>
          {{ $t('news.public-leaks') }}
        </li>
        <li>
          {{ $t('news.private-db') }}
        </li>
        <li>
          {{ $t('news.other') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'NewsList',
  computed: {
    news () {
      return this.$store.state.news.news
    },
    showDemo () {
      return config.showDemo
    },
    hideWhatIsAsiris () {
      return config.hideWhatIsAsiris
    }
  }
}
</script>

<style>
</style>
