import config from '@/config'
import { apiRequest, getQueryString } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'

const { servers: { instagram } } = config

export default {
  namespaced: true,
  state: {
    users: {},
    feed: {},
    isLoaded: {},
    stats: {},
    tasks: {}
  },
  getters: {
    isLoaded: state => state.isLoaded,
    stats: state => state.stats,
    users: state => state.users,
    tasks: state => state.tasks
  },
  mutations: {
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addUser: (state, user) => {
      state.users[user.id] = user
    },
    addFeed: (state, feed) => {
      state.feed[feed.id] = feed
    },
    fetchStats: (state, stats) => {
      state.stats = stats
    },
    addTask: (state, task) => {
      state.tasks[task.id] = task
    }
  },
  actions: {
    async fetchUserById ({ commit, state }, id) {
      commit('startLoad', 'user_' + id)
      try {
        if (state.users[id]) return state.users[id]
        const { data } = await apiRequest('users/' + id, 'GET', {}, instagram)
        commit('addUser', data)
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad', 'user_' + id)
      }
    },
    async fetchUsers ({ commit }, { userId = '', type = '', query }) {
      commit('startLoad', 'users' + type + userId)
      try {
        let url = (userId && type)
          ? `users/${userId}/${type}?`
          : 'users/?'
        url += getQueryString(query)
        const { data } = await apiRequest(url, 'GET', {}, instagram)
        data.data.forEach(user => commit('addUser', user))
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad', 'users' + type + userId)
      }
    },
    async fetchFeed ({ commit }, { userId = '', query }) {
      commit('startLoad', userId ? 'feed_' + userId : 'feed')
      try {
        let queryString = getQueryString(query)
        const { data } = await apiRequest(`feed/${userId || ''}?${queryString}`, 'GET', {}, instagram)
        data.data.forEach(feed => commit('addFeed', feed))
        return data
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad', userId ? 'feed_' + userId : 'feed')
      }
    },
    async fetchStats ({ commit }) {
      commit('startLoad', 'stats')
      try {
        const { data } = await apiRequest('stats', 'GET', {}, instagram)
        commit('fetchStats', data)
      } catch (err) {
        console.dir(err)
        throw err
      } finally {
        commit('stopLoad', 'stats')
      }
    },
    async fetchTasks ({ commit }, query) {
      commit('startLoad', 'tasks')
      let queryString = getQueryString(query)
      try {
        const { data } = await apiRequest('tasks?' + queryString, 'GET', {}, instagram)
        data.data.forEach(task => commit('addTask', task))
        return data
      } catch (err) {
        console.dir(err)
      } finally {
        commit('stopLoad', 'tasks')
      }
    },
    async addTask ({ commit }, task) {
      try {
        const { status } = await apiRequest(`/tasks`, 'POST', {}, instagram, task)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Already exists', type: 'warn' })
            break
          case 201:
            notify({ group: 'general', text: 'Created', type: 'success' })
            break
        }
        return status
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async massTaskAdd ({ commit }, tasks) {
      try {
        const { status, data } = await await apiRequest(`/tasks/batch`, 'POST', {}, instagram, tasks)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Created', type: 'success' })
            break
        }
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    }
  }
}
