const exeption = index => {
  switch (index) {
    case 'test':
      return null
    case 'presentation':
      return 'InnerBase'
    case 'vk_parse':
      return 'vk_parse'
    case '.tasks':
      return 'tasks'
    case 'xsplit_plain_sha1_parsed-1.txt-notitle-24.05.2016':
      return 'XSplit'
    case 'test1':
      return null
    case 'experian.csv-notitle-10.03.2016':
      return 'Exprerian'
    case 'mate1.com-plain-november-2015.txt-notitle-05.11.2015':
      return 'mate1.com'
    case 'usersmya.csv-notitle-2912.2016':
      return 'usermya'
    case 'antipublic_1.tar.gz-myrq-18.01.2019':
      return 'antipublic_1_myrq'
    case 'ap_myr_zabugor_2_eu_russian_antipublic-myrq-18.01.2019':
      return 'antipublic_russian_eu'
    case 'ap_myr_zabugor_2_eu_russian_antipu':
      return 'zabugor_2_eu_russian'
    case 'lsbg.net-notitle-29.12.2016':
      return 'lsbg.net'
    case '17.media_private_database.sql-pastebin.com-24-05-2017':
      return 'pastebin.com'
    case 'blowfish.txt-dropbox.com-02.02-2016':
      return 'dropbox.com'
    case 'first-passengers.csv-site.com-10.09.2019':
      return 'part-1-site.com'
    case 'file2-passengers.csv-site.com-10.09.2019':
      return 'part-2-site.com'
    case 'wildstart_database_part_(1)-wildstar-online.com-20.09-2016':
      return 'part-1-wildstar-online.com'
    case 'wildstart_database_part_(7)-wildstar-online.com-20.09-2016':
      return 'part-7-wildstar-online.com'
    case 'wildstart_database_part_(3)-wildstar-online.com-20.09-2016':
      return 'part-3-wildstart-online.com'
    case 'wildstart_database_part_(2)-wildstar-online.com-20.09-2016':
      return 'part-2-wildstart-online.com'
    case 'wildstart_database_part_(4)-wildstar-online.com-20.09-2016':
      return 'part-4-wildstart-online.com'
    case 'wildstart_database_part_(5)-wildstar-online.com-20.09-2016':
      return 'part-5-wildstart-online.com'
    case 'wildstart_database_part_(6)-wildstar-online.com-20.09-2016':
      return 'part-6-wildstart-online.com'
    case 'wildstart_database_part_(8)-wildstar-online.com-20.09-2016':
      return 'part-8-wildstart-online.com'
    case 'forumptd.com.txt-forumptd.com-20.09-2016':
      return 'forumptd.com'
    case 'blockmadness.com_buycraft_db.csv-blockmadness.com-09.03-2018':
      return 'blockmadness.com'
    default:
      return null
  }
}

const exeptionDate = index => {
  switch (index) {
    case 'wildstart_database_part_(1)-wildstar-online.com-20.09-2016':
      return '20.09.2016'
    default:
      return null
  }
}

const getIndexName = indexes => indexes.map((el, i, arr) => {
  let splitted = el._index
    ? el._index.split('-')
    : el.split('-')
  let index = el._index
    ? el._index
    : el
  let name = exeption(index) || splitted[splitted.length - 2]
  if (!name) return
  let date = exeptionDate(index) || splitted[splitted.length - 1].replace('.txt', '')
  // if (name == 'notitle') name = name + ' ' + date
  // else if (arr.find(el => el.name === name)) name = name + ' ' + date
  let count = el.docs && el.docs.count
  return { name, date, count, index }
}).filter(el => el)

export default getIndexName
