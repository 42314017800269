<template>
  <div
    id="imsi"
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h3 class="uk-modal-title">
        {{ $t('imsi.IMSI') }}
      </h3>
      <div class="uk-card-body uk-padding-remove-top">
        <Loader v-if="!isLoaded"/>
        <div v-else>
          <table class="uk-table">
            <tr v-if="imsi.error">
              <td colspan="2">
                {{ $t('imsi.IMSI-number-cant-be-detected') }}
              </td>
            </tr>
            <tr v-else>
              <td>
                {{ $t('imsi.IMSI-number') }}
              </td>
              <td>
                {{ imsi.imsi }}
              </td>
            </tr>
          </table>
        </div>
        <button
          class="uk-modal-close uk-button uk-button-secondary"
          type="button"
        >
          {{ $t('imsi.close') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'Imsi',
  components: {
    Loader
  },
  props: {
    phone: {
      required: true,
      type: String
    }
  },
  watch: {
    phone (from, to) {
      this.update()
    }
  },
  computed: {
    imsi () {
      return this.$store.state.fetcher.imsi[this.phone]
    },
    isLoaded () {
      return this.$store.state.fetcher.isLoaded['imsi_' + this.phone]
    }
  },
  methods: {
    update () {
      const action = 'fetcher/fetchIMSI'
      this.$store.dispatch(action, this.phone)
    }
  },
  mounted () {
    this.update()
  }
}
</script>
