<template>
  <div>
    <div
      v-if="userdata.name"
      ref="sidemenuOffcanvas"
      uk-height-viewport="expand: true"
      :class="'tm-sidebar-left uk-position-fixed uk-box-shadow-small uk-background-default uk-height-1-1 uk-visible@m uk-offcanvas-content ' + (isCollapsed ? 'collapsed-menu-md ' : '')"
    >
      <a
        v-if="!isCollapsed"
        class="collapse-button-md uk-icon-button"
        uk-icon="chevron-left"
        @click="isCollapsed = true"
      />
      <a
        v-else-if="isCollapsed"
        class="expand-button-md uk-icon-button"
        uk-icon="chevron-right"
        @click="isCollapsed = false"
      />
      <nav
        class="uk-navbar-container uk-dark uk-navbar-transparent"
        uk-navbar="mode: hover"
      >
        <div
          class="uk-navbar-left uk-width-1-1"
          style="position: relative;height: 100vh;"
        >
          <div
            class="uk-width-1-1 uk-text-center uk-margin-medium-top"
            style="margin-bottom:auto"
          >
            <router-link
              to="/"
              :class="'uk-logo uk-visible@s ' + (isCollapsed ? 'collapsed-logo' : 'uk-navbar-item')"
              href="/"
            >
              <img
                :src="logourl"
                height="64"
                width="64"
              />
            </router-link>
          </div>
          <div
            class="uk-width-1-1 uk-text-left menu-with-scroll uk-padding-remove-top"
            style="padding-left: 15px;"
          >
            <SourcesMenu :availableAds="availableAds"/>
          </div>
        </div>
      </nav>
    </div>
    <div
      v-if="userdata.name"
      class="uk-offcanvas-content navbar uk-width-expand uk-position-z-index uk-position-fixed"
      style="height:10px;z-index: 1112;max-height:20px;top:50px"
    >
      <a
        class="uk-navbar-toggle uk-position-top-left mobile-menu"
        uk-navbar-toggle-icon
        uk-toggle="target: #mobile-navbar"
        @click="isCollapsed = false"
      />
      <div
        id="mobile-navbar"
        uk-offcanvas="mode: slide; flip: false"
      >
        <div class="uk-offcanvas-bar">
          <button
            class="uk-offcanvas-close"
            type="button"
            uk-close
          />
          <router-link to="/">
            <img
              src="/img/logo.png"
              height="64"
              width="64"
            />
          </router-link>
          <div>
            <ul
              class="uk-navbar-nav uk-margin-small-top uk-overflow-auto"
              style="padding-top: 20px; padding-bottom: 20px;"
            >
              <li
                v-for="item in menu"
                :key="item.to"
                class="uk-padding-remove"
                :class="{
                  'uk-active': item.to === $route.name
                }"
              >
                <router-link
                  :to="{
                    name: item.to,
                    params: item.to === 'case_trigger_link' ? {
                      id: routerCaseId,
                      caseId: routerCaseId
                    } : null,
                    hash: item.hash,
                    query: item.query
                  }"
                  style="min-height: 1rem!important;padding-bottom: 0.5rem;padding-top:0.5rem"
                >
                  {{ $t(item.translate) }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="uk-width-1-1 uk-text-left uk-margin-top">
            <SourcesMenu :availableAds="availableAds" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="title"
      class="uk-offcanvas-content  uk-width-expand uk-grid-collapse desktop-menu "
      :style="`z-index: 11; position: fixed; top:${offset}px;`"
      uk-grid
    >
      <div
        class="uk-width-1-1 "
        style="height: 38px;max-width:100%"
      >
        <ul
          ref="topmenu"
          :style="isCollapsed ? `padding-left: 60px!important` : `padding-left:190px`"
          style="height: 38px"
          class="uk-background-default uk-margin-remove uk-tab top-menu"
        >
          <li
            v-for="item in menu"
            :key="item.to"
            class="uk-padding-remove uk-text-truncate"
            :class="{
              'uk-active': item.to === $route.name
            }"
          >
            <router-link
              :to="{
                name: item.to,
                params: item.to === 'case_trigger_link' ? {
                  id: routerCaseId,
                  caseId: routerCaseId
                } : null,
                hash: item.hash,
                query: item.query
              }"

              style="min-height: 1rem!important;padding-bottom: 0.5rem;padding-top:0.5rem"
            >
              {{ $t(item.translate) }}
            </router-link>
          </li>
          <li
            v-if="menu"
            class="case-title-case"
          >
            <hr
              style="height: 38px"
              class="uk-divider-vertical"
            />
          </li>
          <li
            v-if="routerModule === 'case'"
            class="case-title-cases"
          >
            <router-link
              style="min-height: 1rem!important;padding-bottom: 0.5rem;padding-top:0.5rem;display:inline-block"
              :to="{
                name: 'cases_list'
              }"
              exact
            >
              {{ $t('navbar.cases') }}
            </router-link>
          </li>
          <li
            v-if="routerModule === 'case'"
            class="uk-text-muted uk-padding-remove-left uk-padding-remove-right case-title-cases"
          >
            <a
              class="uk-padding-remove-left uk-padding-remove-right"
              style="min-height: 1rem!important;padding-bottom: 0.5rem;padding-top:0.5rem;display:inline-block;uk-disabled"
            >
              /
            </a>
          </li>
          <li
            v-if="routerModule === 'case'"
            style=" max-width: max-content!important;flex: 10;display:inline-block "
            class="uk-padding-remove-left uk-text-left  uk-width-expand case-title"
          >
            <router-link
              class="uk-text-truncate"
              style="min-height: 1rem!important;padding-bottom: 0.5rem;padding-top:0.5rem;display:inline-block;"
              :to="{
                name: 'case_details',
                params: {
                  caseId: $route.params.caseId
                }
              }"
              exact-active-class="uk-text-muted"
              exact
              active-class="uk-text-muted"
            >
              {{ caseTitle }}
            </router-link>
          </li>
          <li
            v-if="routerModule !== 'case'"
            :class="{
              'uk-disabled': !firstMenuElement.to
            }
            "
            class="menu-title"
            @click.stop="showTab"
          >
            <router-link
              :to="{
                name: firstMenuElement.to,
                params: firstMenuElement.to === 'case_trigger_link' ? {
                  id: routerCaseId,
                  caseId: routerCaseId
                } : null,
              }"
              exact-active-class="uk-text-muted"
              exact
              active-class="uk-text-muted"
              style="min-height: 1rem!important;padding-bottom: 0.5rem;padding-top:0.5rem;margin-right:20px;"
            >
              <span
                v-html="title"
              />
            </router-link>
          </li>
          <li
            style="padding-right:10px;flex: 1 1 auto;cursor: pointer;"
            class="uk-padding-remove searcharea"
            @click.stop
          >
            <button
              id="searchtoggle"
              style="min-height:35px!important;height:37px;margin-right:10px"
              class="uk-navbar-toggle uk-padding-remove uk-float-right"
              uk-search-icon
            />
            <div
              ref="searchdropdown"
              style="border-left: 3px solid #1e87f0;position:fixed;"
              class="uk-drop uk-background-default uk-padding-remove searchdropdown "
              uk-dropdown="toggle: .searcharea;mode: click; pos: left-center;boundary-align:#searchtoggle;boundary:#searchtoggle;offset: -30px;animation: uk-animation-slide-right-small"
            >
              <form
                class="uk-search uk-width-1-1"
                style="padding-top:4px;padding-left:10px; height: 37px;"
                @submit.prevent="search"
                @click.stop
              >
                <input
                  v-model="searchString"
                  class="uk-search-input"
                  type="search"
                  :placeholder="$t('navbar.search_placeholder')"
                  autofocus
                  @focus="$event.target.select()"
                />
              </form>
            </div>
          </li>
        </ul>
      </div>

      <div
        class="uk-position-right uk-width-auto"
        style="height: 37px;margin-top:36px;"
      >
        <div
          v-if="routerModule === 'ads'"
          class="uk-visible@m uk-float-right  uk-background-default"
          style="width:150px; height:38px; padding-left: 20px;border-radius: 0 0 0 15px;margin-top:2px;border-top: 1px solid #39f"
        >
          <div class="uk-form">
            <select
              v-model="adsCountry"
              class="uk-select"
              style="border: none;height: 38px;"
            >
              <option
                v-for="({ country, code }, i) in availableAds"
                :key="code + i"
                :value="code"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <CasesBar v-if="userdata.name" />
  </div>
</template>

<script>
import SourcesMenu from '@/components/SourcesMenu'
import CasesBar from '@/components/CasesBar'
import mainSearch from '@/utils/search'
import config from '@/config'
import UIkit from 'uikit'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import menuItems from '@/utils/navbar.json'
const OFFCANVAS_MODE_WIDTH = 960
import { countryCodeToName } from '@/filters/globalFilters'

export default {
  name: 'Navbar',
  components: {
    SourcesMenu,
    CasesBar
  },
  props: {
    offset: {
      type: [Number],
      default: 0
    }
  },
  data () {
    return {
      searchString: '',
      selectedAdsCountry: '',
      collapsed: false,
      windowWidth: window.innerWidth,
      dropdown: false
    }
  },
  watch: {
    $route (to, from) {
      this.selectedAdsCountry = to.params.country
    }
  },
  computed: {
    ...mapGetters('cases', ['archivedCases', 'allCases']),
    ...mapGetters(['isMainSideMenuCollapsed', 'isAuthenticated']),
    caseTitle () {
      let id = this?.$route?.params?.caseId
      let cases = this?.allCases
      if (this?.$route?.name === 'case_archived_details') {
        id = this?.$route?.params?.caseId
        cases = this?.archivedCases
      }
      return cases?.[id]?.['case_title'] || ''
    },
    isCollapsed: {
      get () {
        return this.windowWidth >= OFFCANVAS_MODE_WIDTH ? this.isMainSideMenuCollapsed : false
      },
      set (value) {
        this.setMainSideMenuCollapsed(value)
      }
    },
    menu () {
      for (const item in menuItems[this.routerModule]) {
        switch (menuItems[this.routerModule][item].props) {
            case 'id':
              menuItems[this.routerModule][item].props = { id: this.routerCaseId }
              break
            case 'caseId':
              menuItems[this.routerModule][item].props = { caseId: this.routerCaseId }
              break
            case 'country':
              menuItems[this.routerModule][item].props = { country: this.selectedAdsCountry }
              break
        }
      }
      return menuItems[this.routerModule]
    },
    firstMenuElement () {
      return this.menu ? this.menu[Object.keys(this.menu)[0]] : {}
    },
    availableAds () {
      let geos = this.$store.state.ads.geos.data
      if (!geos) return false
      return geos.map(el => { return { country: this.countryCodeToName(el), code: el } }).sort((a, b) => a.country > b.country)
    },
    adsCountry: {
      get () {
        return this.selectedAdsCountry
      },
      set (val) {
        this.$router.push({ params: { country: val } })
      }
    },
    userdata () {
      return this.$store.getters.getProfile
    },
    routes () {
      return this.$router.options.routes.reduce((acc, route, i, routes) => {
        const own = routes.hasOwnProperty(i)
        const inMenu = route.hasOwnProperty('inMenu')
        const routeInMenu = route.inMenu
        return own && inMenu && routeInMenu ? [...acc, route] : acc
      }, [])
    },
    routerModule () {
      try {
        return this.$route.name.split('_')[0].replace('tgcp', 'telegram')
      } catch (e) {}
      return false
    },
    title () {
      let lt = /</g
      let gt = />/g
      let ap = /'/g
      let ic = /"/g
      switch (this.routerModule) {
          case 'phoneResults':
            return `${this.$t('search.by_phone_title')} <b>${this.$route.params.phone.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'nicknameResults':
            return `${this.$t('search.by_nickname_title')} <b>${this.$route.params.nickname.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'emailResults':
            return `${this.$t('search.by_email_title')} <b>${this.$route.params.email.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'domainResults':
            return `${this.$t('search.by_domain_title')} <b>${this.$route.params.domain.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'nameResults':
            return `${this.$t('search.by_name_title')} <b>${this.$route.params.name.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'threemaPhoneResults':
            return `${this.$t('search.threema_by_phone_title')} <b>${this.$route.params.phone.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'facebookSearchResults':
            return `${this.$t('search.facebook_by_phone_title')} <b>${this.$route.params.search.toString()
              .replace(lt, '&lt;')
              .replace(gt, '&gt;')
              .replace(ap, '&#39;')
              .replace(ic, '&#34;')}</b>`
          case 'global':
            return this.$t('navbar.map_title')
          case 'osint':
            return  this.$t('navbar.osint-tools' )
          case 'messenger':
            return this.$route.params.name
          case 'social':
            return this.$route.params.name
          case 'home':
            return this.$t('navbar.home_title')
          case 'profilerCardDetails':
            return this.$t('navbar.profiler-card-details')
          default:
            return this.routerModule
      }
    },
    routerCaseId () {
      return this.$route.params.caseId || this.$route.params.id
    },
    profileRoutes () {
      const routes = []
      for (const i in this.$router.options.routes) {
        if (!this.$router.options.routes.hasOwnProperty(i)) {
          continue
        }
        const route = this.$router.options.routes[i]
        if (route.hasOwnProperty('inRightMenu')) {
          if (route.inRightMenu) {
            routes.push(route)
          }
        }
      }
      return routes
    },
    logourl () {
      return config.logoUrl
    }
  },
  methods: {
    ...mapActions('ads', ['getGeo']),
    ...mapMutations([
      'setMainSideMenuCollapsed'
    ]),
    countryCodeToName,
    classForRoute (route) {
      const { activate, name } = this.$router.currentRoute.value
      return [activate, name].includes(route.name) ? 'uk-active' : ''
    },
    showTab (index = 0) {
      UIkit.tab(this.$refs.topmenu).show(0)
    },
    search () {
      if (!this.searchString.replace(/ /gi, '').trim()) return
      return mainSearch(this.$router, this.searchString)
    },
    resize () {
      // This code is needed in order to remove the duplication of the open menu when resizing
      if ((this.windowWidth < OFFCANVAS_MODE_WIDTH) && (window.innerWidth >= OFFCANVAS_MODE_WIDTH)) {
        if (this.$refs.sidemenuOffcanvas) {
          const menu = UIkit.offcanvas(this.$refs.sidemenuOffcanvas)
          menu.hide()
          menu.show()
        }
      }
      this.windowWidth = window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.resize)
  },
  mounted () {
    this.selectedAdsCountry = this.$router.currentRoute.value.params.country
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.resize)
  }
}
</script>

<style type="text/css">
.collapse-button-md {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 500;
}
.collapsed-menu-md {
  width: 50px!important;
}
.expand-button-md {
  z-index: 1500;
  top: 5px;
  right: 5px;
  position: absolute;
}
.collapsed-top-menu {
  padding-left: 200px!important;
}
.collapsed-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-top: 25px;
}
.menu-with-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
  height: 500px;
  height: calc(100% - 130px);
  position: absolute;
  top: 130px;
}
.menu-with-scroll::-webkit-scrollbar {
  width: 5px;
}
.menu-with-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
  width: 5px;
  border-radius: 5px;
}
.menu-with-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgb(145, 145, 145);
}
.menu-with-scroll {
  scrollbar-base-color: #fff;
  scrollbar-face-color: rgb(194, 194, 194);
  scrollbar-highlight-color: rgb(145, 145, 145);
}
.navbar {
  margin-top: -60px;
}
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  min-height: 60px !important;
}
#app {
  padding-bottom: 2rem;
}
.process-navigation {
  line-height: 1.8rem;
  color: #bbbbbb;
}
.process-navigation .uk-active {
  color: #666;
}

.uk-nav-sub .router-link-active {
  color: #666 !important;
}
.uk-navbar-nav .router-link-active {
  color: #666 !important;
}
.process-navigation .divider {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-bottom: 1px solid #bbb;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  padding-top: -1rem;
  margin-bottom: 0.2rem;
}
.process-navigation span.uk-icon {
  vertical-align: middle;
  border: 1px solid;
  border-radius: 50%;
  padding: 3px;
  margin-right: 0.5rem;
}
.on-top {
  z-index: 44;
}
#searcharea {
  z-index: 45;
}
.searchdropdown{
  -webkit-box-shadow:  -8px 0px 7px 0px rgba(0,0,0,0.2)!important;
  -moz-box-shadow:  -8px 0px 7px 0px rgba(0,0,0,0.2)!important;
  box-shadow: -8px 0px 7px 0px rgba(0,0,0,0.2)!important;
;
}
.top-menu > li {
    height: 38px;
  }
@media (max-width: 1024px) {
  .top-menu {
    padding-left: 40px!important;
  }
  #searchtoggle {
    position: fixed!important;
    top:0px!important;
    right:5px!important;
  }
  .case-title {
    margin-right: 40px;
  }
}
@media (max-width: 640px) {
    .mobile-menu {
    display: block!important;
    z-index: 1111;
  }
  .uk-container {
    padding-top: 14px;
  }
  .desktop-menu {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .case-title-cases {
    display: none;
  }

}
@media (min-width: 960px) {
  .mobile-menu {
    display: none!important;
  }
}
@media (min-width: 640px) {
  .uk-navbar-nav {
    display: none !important;
  }
}
</style>
<style scoped>
.uk-tab::before {
  width: 100%!important;
  display: none;
}
@media (max-width: 780px) {
    .mobile-menu {
    display:flex!important;
    z-index: 1111;
  }
  .menu-title {
    display: none;
  }
}
</style>
