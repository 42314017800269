<template>
  <div class="ip_msisdn">
    <div class="uk-card uk-card-default uk-margin-top uk-card-body uk-text-left">
      <div class="uk-card-title">
        {{ $t('access.msisdn-detection-phishing-tool') }}
        <span
          :uk-tooltip="$t('access.currently-msisdn-fetching-is-working')"
          uk-icon="icon: info"
        />
      </div>
      <p>{{ $t('access.to detect targets ip address') }}</p>
      <ul>
        <li>
          {{ $t('access.generate-phishing-link') }}
        </li>
        <li>
          {{ $t('access.send-link-to-target') }}
        </li>
        <li>
          {{ $t('access.when target will click on it') }}
        </li>
      </ul>
      <div class="uk-card-title uk-button-small uk-margin-small-top uk-margin-small-bottom">
        {{ $t('access.my-links') }}
      </div>
      <button
        class="uk-button uk-button-primary"
        style="width:200px"
        @click="createNewFishingLink"
      >
        <Loader
          v-if="isCreating"
          :ratio="'1'"
        />
        <span v-else>
          {{ $t('access.generate-new-link') }}
        </span>
      </button>
      <div>
        <Loader v-if="isLoading"/>
        <div v-else>
          <span v-if="links.length===0">
            {{ $t('access.no-links-created-yet') }}
          </span>
          <table
            v-else
            class="uk-table"
            width="100%"
          >
            <tr>
              <th width="37%">
                Link
              </th><th width="150">
                {{ $t('access.created-at') }}
              </th><th width="100">
                {{ $t('access.clicks') }}
              </th>
              <th>
                {{ $t('access.retrieved-data') }}
              </th>
            </tr>
            <tr
              v-for="link in links"
              :key="link.link"
            >
              <td>
                <a
                  uk-icon="icon: copy"
                  @click="copyToClipboard(link.link)"
                /> {{ link.link }}
              </td>
              <td>
                {{ dayjs(link.created_at) }}
              </td>
              <td>
                {{ link.cnt }}
              </td>
              <td>
                <span v-if="link.resolves.length===0">
                  {{ $t('access.no-data-received-yet') }}
                </span>
                <div v-else>
                  <table
                    style="margin-top: -30px;"
                    class="uk-table uk-table-small"
                  >
                    <tr>
                      <th style="padding-top: 0;padding-bottom: 0;">
                        MSISDN
                      </th><th style="padding-top: 0;padding-bottom: 0;">
                        IP
                      </th>
                      <th
                        width="40"
                        style="padding-top: 0;padding-bottom: 0;"
                      >
&nbsp;
                      </th>
                    </tr>
                    <tr
                      v-for="(resolve,i) in link.resolves"
                      :key="i"
                      style="margin-top: -30px;"
                    >
                      <td>
                        <router-link
                          v-if="resolve.object.msisdn"
                          :to="{ name: 'phoneResults', params: { phone: '+' + resolve.object.msisdn }}"
                        >
                          +{{ resolve.object.msisdn }}
                        </router-link>
                        <span v-else>
                          {{ $t('access.not-supported') }}
                        </span>
                      </td>
                      <td>
                        <router-link :to="{ name: 'ipResults', params: { ip: resolve.object.ip }}">
                          {{ resolve.object.ip }}
                        </router-link>
                      </td>
                      <td >
                        <div style="width: 20px">
                          <button
                            style="font-size: 12px"
                          />
                          <div
                            uk-drop="pos: left"
                            class="uk-card uk-card-body uk-card-default"
                          >
                            <div>
                              <span
                                class="uk-text-bold"
                              >
                                {{ $t('access.click-on-link-was-detected-at') }}
                              </span>
                              {{ dayjs(resolve.created_at) }}
                            </div>
                            <div>
                              <span
                                class="uk-text-bold"
                              >
                                {{ $t('access.user-agent-is') }}
                              </span>

                              {{ resolve.object.ua.replace(/[()]/, '') }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'IpMsisdnDetect',
  components: {
    Loader
  },
  data () {
    return {
      timer: null
    }
  },
  computed: {
    links () {
      return this.$store.state.fetcher.phishing
    },
    isCreating () {
      return !this.$store.state.fetcher.isLoaded['phishing_create']
    },
    isLoading () {
      return !this.$store.state.fetcher.isLoaded['phishing']
    }
  },
  methods: {
    tooltip (resolve) {
      let text = ''
      text += +'\n'
      // text += 'Target`s IP Address is ' + resolve.object.ip + '\n, '
      // text += 'Detected MSISDN is +' + resolve.object.msisdn + '\n, '

      return text
    },
    async copyToClipboard (val) {
      const { default: copyToClipboard } = await import(/* webpackChunkName: "copyToClipboard" */ '@/utils/copyToClipboard')
      copyToClipboard(val)
      this.$notify({ group: 'general', text: this.$t('utils.copied-to-clipboard'), type: 'general', duration: 700 })
    },
    ...mapActions('fetcher', [
      'createNewFishingLink',
      'fetchAvailableFishingLinks'
    ]),
    async update () {
      await this.fetchAvailableFishingLinks()
      this.initialLoad = false
      this.timer = setTimeout(this.update, 20000)
    }
  },
  created () {
    this.update()
  },
  beforeUnmount () {
    clearTimeout(this.timer)
  }
}
</script>
