<template>
  <div>
    <div
      class="uk-grid-small"
      uk-grid
    >
      <div class="uk-width-expand@m">
        <ul
          id="component-tab-left"
          class="uk-switcher"
        >
          <li
            v-for="filter in filters"
            :key="filter.value"
            class="uk-padding-small"
          >
            <StoredContent
              v-if="currentTab === filter.value"
              :id="caseId"
              :storedFilterType="filter.value"
              @storedObjectWasDeleted="storedObjectWasDeleted"
            />
          </li>
        </ul>
      </div>
      <div class="uk-width-1-6@m">
        <ul
          class="uk-tab-right"
          uk-tab="connect: #component-tab-left; animation: uk-animation-fade"
        >
          <li
            v-for="filter in filters"
            :key="filter.value"
          >
            <a
              href="#"
              @click="currentTab = filter.value"
            >
              {{ filter.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StoredObjectsList',
  components: {
    StoredContent: defineAsyncComponent(() => import('@/components/cases/Stored/StoredContent'))
  },
  props: {
    caseId: {
      type: String
    }
  },
  data () {
    return {
      filters: [],
      tab: ''
    }
  },
  watch: {
    currentCase: {
      immediate: true,
      async handler (newCase) {
        await this.loadFilters(newCase)
      }
    }
  },
  computed: {
    ...mapGetters('cases', [
      'currentCase'
    ]),
    currentTab: {
      get () {
        if (this.tab !== '') return this.tab
        if (!this.filters || this.filters.length === 0) return ''
        return this.filters[0].value
      },
      set (value) {
        this.tab = value
      }
    }
  },
  methods: {
    ...mapActions('cases', [
      'getStoredObjectsFilters'
    ]),
    storedObjectWasDeleted () {
      this.loadFilters(this.currentCase)
    },
    async loadFilters (currentCase) {
      try {
        const filters = await this.getStoredObjectsFilters(this.caseId)
        if (filters) {
          this.filters = filters
            .filter(item => (item !== 'task') && (item !== 'bot') && (item !== 'geo-cluster'))
            .map(item => {
              if (this.$te(`cases.filter_stored_objects.${item}`)) {
                return { label: this.$t(`cases.filter_stored_objects.${item}`), value: item }
              }
              return { label: item, value: item }
            })
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
