<template>
  <span>
    <button
      class="uk-button uk-button-text"
      uk-icon="move"
      style="height:40px;width:40px;"
      :uk-tooltip="$t('cases.move.copy')"
      @click="showModal"
    />
    <div
      :ref="'copyCase' + caseInfo.case_id"
      uk-modal
      class="uk-flex-top"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
      >
        <button
          class="uk-modal-close-default"
          type="button"
          uk-close
        />
        <div/>
        <div
          class="uk-margin-top"
          uk-grid
        >
          <div
            class="uk-margin-auto-vertical uk-width-expand"
          >
            {{ $t('cases.move.copy-to') }}
          </div>
          <div
            class="uk-width-3-4"
          >
            <vueSelect
              v-model="currentCase"
              :options="casesCumputed"
              label="label"
            />
          </div>
          <div
            class="uk-width-1-1 uk-text-center uk-margin-top"
          >
            <label
              v-if="currentCase"
              :uk-tooltip="$t('cases.move.case-will-be-archived')"
            >
              <input
                v-model="merge"
                type="checkbox"
                class="uk-checkbox"
              />
              {{ $t('cases.move.archive') }}
            </label>
          </div>
        </div>
        <div
          v-if="!to"
          class="uk-margin-top"
          uk-grid
        >
          <div
            class="uk-margin-auto-vertical uk-width-expand"
          >
            {{ $t('cases.move.new-name') }}:
          </div>
          <div class="uk-width-3-4">
            <input
              v-model="case_title"
              class="uk-input "
              label="label"
            />
          </div>
        </div>
        <div
          v-if="!to"
          class="uk-margin-top"
          uk-grid
        >
          <div>
            {{ $t('cases.move.new-description') }}:
          </div>
          <div class="uk-width-1-1 uk-margin-remove">
            <textarea
              v-model="case_description"
              class="uk-width-1-1 uk-textarea"
              rows="5"
              label="label"
            />
          </div>
        </div>
        <div
          class="uk-text-center uk-margin-top"
        >
          <button
            class="uk-button uk-button-default uk-margin-right"
            @click="hideModal"
          >
            {{ $t('cases.move.cancel') }}
          </button>
          <button
            class="uk-button uk-button-primary "
            :disabled="loading"
            @click="confirm"
          >
            <Loader
              v-if="loading"
              :ratio="0.7"
            />
            <span
              v-else
            >
              {{ $t('cases.move.copy') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import UIkit from 'uikit'
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'

export default {
  name: 'MoveCaseButton',
  components: {
    vueSelect,
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  props: {
    caseInfo: {
      type: Object,
      reuired: true
    }
  },
  data () {
    return {
      merge: false,
      to: undefined,
      case_title: this.caseInfo.case_title ? this.caseInfo.case_title + ` (${this.$t('cases.copy')})` : '',
      case_description: this.caseInfo.case_description || '',
      loading: false,
      cases: [],
      query: {
        limit: 500,
        offset: 0,
        search: ''
      }
    }
  },
  computed: {
    currentCase: {
      get () {
        return this.to
      },
      set (val) {
        this.to = val && val.value
      }
    },
    casesCumputed () {
      return [
        { label: this.$t('cases.move.create-new-one'), value: undefined },
        ...(this.cases.filter(el => el.case_id !== this.caseInfo.case_id && ['rw', 'f'].includes(el.access_level)).map(el => ({
          label: el.case_title,
          value: el.case_id,
          second: el.case_description
        }
        )))
      ]
    }
  },
  methods: {
    ...mapActions('cases', [
      'fetchCases',
      'mergeCases'
    ]),
    showModal () {
      this.update()
      UIkit.util.on(this.$refs['copyCase' + this.caseInfo.case_id], 'hide', () => {
        this.to = undefined
        this.merge = false
        this.case_title = this.caseInfo.case_title ? this.caseInfo.case_title + ` (${this.$t('cases.copy')})` : ''
        this.case_description = this.caseInfo.case_description || ''
      })
      UIkit.modal(this.$refs['copyCase' + this.caseInfo.case_id]).show()
    },
    hideModal () {
      UIkit.modal(this.$refs['copyCase' + this.caseInfo.case_id]).hide()
    },
    async confirm () {
      this.loading = true
      try {
        const newCase = await this.mergeCases({
          caseId: this.caseInfo.case_id,
          params: {
            merge: this.merge,
            case_id: this.to,
            case_title: this.case_title,
            case_description: this.case_description
          }
        })
        this.$emit('merged')
        this.$notify({
          group: 'general',
          title: this.$t('notifications.success'),
          text: this.$t('cases.move.case-was-merged'),
          duration: 20000,
          type: 'success',
          data: {
            primaryCallback: () => Promise.resolve()
              .then(() => {
                this.$router.push('/cases/' + newCase.case_id)
              }),
            primaryText: this.$t('cases.move.go-to-case')
          }
        })
        this.hideModal()
      } catch {
        this.$notify({
          group: 'general',
          title: this.$t('notifications.error'),
          text: this.$t('cases.move.failed'),
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async update (id) {
      try {
        const { cases } = await await this.fetchCases(this.query)
        this.cases = cases
      } catch (err) {
        this.cases = []
      }
    }
  }
}
</script>

<style>

</style>
