<template>
  <span>
    <button
      uk-toggle="target: #confirmInvintation"
      type="button"
      class="uk-button uk-button-default uk-margin-left uk-align-right"
    >
      {{ $t('cases.sharing.add_by_invite_code_button') }}
    </button>
    <div
      id="confirmInvintation"
      class="uk-flex-top"
      uk-modal
    >
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">
            {{ $t('cases.sharing.enter_code_title') }}
          </h2>
        </div>
        <button
          class="uk-modal-close-default"
          type="button"
          uk-close
        />
        <span>
          <div class="uk-modal-body">
            <input
              v-model.trim="code"
              :class="error && 'uk-form-danger'"
              class="uk-input uk-form-width-large"
              type="text"
              placeholder="12-FD12KL33KJ43"
            />
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button
              class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
              @click="cancel"
            >
              {{ $t('cases.cancel') }}
            </button>
            <div
              v-if="loaded === false"
              uk-spinner
            />
            <button
              v-else
              class="uk-button uk-button-primary"
              type="button"
              @click="confirm"
            >
              {{ $t('cases.confirm') }}
            </button>
          </div>
        </span>
      </div>
    </div>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import UIkit from 'uikit'
export default {
  name: 'CaseInvintation',
  data () {
    return {
      code: this.$route.query.code,
      error: false,
      loaded: null
    }
  },
  methods: {
    ...mapActions('cases', [
      'confirmInvintation'
    ]),
    confirm () {
      if (!this.code) {
        this.error = true
        return
      }
      this.loaded = false
      this.confirmInvintation(this.code)
        .then(() => {
          UIkit.modal('#confirmInvintation').hide()
          this.$emit('update')
          this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('cases.sharing.access_granted_msg'), type: 'success' })
          if (this.$route.query.code) {
            let query = Object.assign({}, this.$route.query)
            delete query.code
            this.$router.replace({ query })
          }
        })
        .catch((err) => {
          this.error = true
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t(err.response.data.message), type: 'error' })
          if (this.$route.query.code) {
            UIkit.modal('#confirmInvintation').show()
          }
        })
        .finally(() => {
          this.loaded = true
        })
    },
    cancel () {
      this.code = ''
    }
  },
  mounted () {
    if (this.code) {
      this.confirm()
    }
  }
}
</script>
