/**
* @param {object} object$
* @param {string} description
* @return {undefined|Symbol}
*/
export default function (object$, description) {
  try {
    const symbols = Object.getOwnPropertySymbols(object$)
    return symbols.find(element => {
      try {
        return element.description === description
      } catch (error) {
        console.debug(error)
      }
    })
  } catch (error) {
    console.debug(error)
  }
  return undefined
}
