<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="channel.answer_status === 'NOT_FOUND'" />
      <div v-else>
        <div
          class="uk-grid uk-grid-small"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation
              :channel="channel"
              @resolved="update"
            />
          </div>
          <div class="uk-width-1-4@m">
            <ChannelStats :data="channel" />
          </div>
        </div>
      </div>
      <div
        class="uk-margin-medium-top"
      >
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#overview_tab',
              'uk-disabled': isLoadedStats
            }"
          >
            <router-link
              :to="{
                hash: '#overview_tab'
              }"
              class="uk-padding-remove"
            >
              {{ $t('twitter.channel_details.overview_title') }}
            </router-link>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#messages_tab',
              'uk-disabled': isLoadedStats && (!stats || !stats.messages_count)
            }"
          >
            <router-link
              :to="{
                hash: '#messages_tab'
              }"
              class="uk-padding-remove"
            >
              {{ $t('tgcp.channel_details.messages_title') }}
            </router-link>
            <span class="uk-badge uk-margin-small-left">
              <span>
                {{ channel.messages_count }}
              </span>
            </span>
          </li>
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#users_tab',
            }"
          >
            <router-link
              :to="{
                hash: '#users_tab'
              }"
              class="uk-padding-remove"
            >
              {{ $t('hoop.sort.users') }}
            </router-link>
            <span
              v-if="channel.users_count"
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ channel.users_count }}
              </span>
            </span>
          </li>
          <li
            v-if="channel.subtopics_count"
            class="uk-flex"
            :class="{
              'uk-active': $route.hash === '#channels_tab',
            }"
          >
            <router-link
              :to="{
                hash: '#channels_tab'
              }"
              class="uk-padding-remove"
            >
              {{ $t('hoop.sort.child_channels') }}
            </router-link>
            <span
              v-if="channel.subtopics_count"
              class="uk-badge uk-margin-small-left"
            >
              <span>
                {{ channel.subtopics_count }}
              </span>
            </span>
          </li>
        </ul>
        <ul
          style="min-height: 500px"
          class="uk-switcher"
        >
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.hash === '#overview_tab'
            }"
          >
            <udg :id="channel.id" />
            <uwg :id="channel.id" />
            <umg :id="channel.id" />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.hash === '#users_tab'
            }"
          >
            <UserList
              :id="channel.id"
              if="$route.hash === '#users_tab'"
              type="hashtag"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.hash === '#messages_tab'
            }"
          >
            <MessageListMini
              :id="String(id)"
              if="$route.hash === '#messages_tab'"
              subtype="channel"
              :page="messagePage"
              :offset="messageOffset"
            />
          </li>
          <li
            :class="{
              'uk-animation-slide-bottom-medium uk-active': $route.hash === '#channels_tab'
            }"
          >
            <ChannelList
              if="$route.hash === '#channels_tab'"
              :parentId="String(id)"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'
import UserDayGraph from '@/components/Hoop/ChannelDetails/ChannelDayGraph'
import UserWeekGraph from '@/components/Hoop/ChannelDetails/ChannelWeekGraph'
import UserYearGraph from '@/components/Hoop/ChannelDetails/ChannelYearGraph'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TgcpChannelDetails',
  components: {
    udg: UserDayGraph,
    uwg: UserWeekGraph,
    umg: UserYearGraph,
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    ChannelStats: defineAsyncComponent(() => import('@/components/Hoop/ChannelDetails/ChannelStats')),
    ChannelList: defineAsyncComponent(() => import('@/views/Hoop/ChannelList')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/Hoop/ChannelDetails/GeneralInformation')),
    MessageListMini: defineAsyncComponent(() => import('@/views/Hoop/MessageList')),
    UserList: defineAsyncComponent(() => import('@/views/Hoop/UserList'))
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  watch: {
    '$route.params.id': {
      immediate: false,
      handler () {
        this.update()
        if (!this.$route.hash) {
          this.$router.replace({ hash: '#overview_tab' })
        }
      }
    }
  },
  computed: {
    stats () {
      if (!this.$store.state.hoop.channels[this.id]) return
      return this.$store.state.hoop.channels[this.id]['stats']
    },
    entity () {
      let entity = this.$store.state.hoop.channels[this.id]['entity_data']
      if (!entity) {
        entity = {
          task: false,
          total: {
            count: 0
          }
        }
      }
      return entity
    },
    channel () {
      return this.$store.state.hoop.channels[this.id]
    },
    isLoaded () {
      return this.$store.state.hoop.isLoaded['channel_' + this.id]
    },
    isLoadedStats () {
      return this.$store.state.hoop.isLoaded['channel_' + this.id + '_stats']
    },
    isLoadedEntityStats () {
      return this.$store.state.hoop.isLoaded['channel_' + this.id + '_entity_data']
    },
    messagePage () {
      if (this.$route.params.message_pagination) return this.$route.params.message_pagination.page
      else return 1
    },
    messageOffset () {
      if (this.$route.params.message_pagination) return this.$route.params.message_pagination.offset
      else return 0
    },
    messageId () {
      return this.$route.params.message_id
    }
  },
  methods: {
    ...mapActions('hoop', ['fetchChannelById']),
    init () {
      this.update()
    },
    async update () {
      await this.fetchChannelById(this.id)
    }
  },
  created () {
    this.update()
  },
  mounted () {
    if (!this.$route.hash) {
      this.$router.replace({ hash: '#overview_tab' })
    }
  }
}
</script>

<style>
.uk-badge.uk-margin-small-left svg {
  width: 100%;
  height: 100%;
  max-width: 1rem;
}
</style>

<style scoped>
[class*='uk-animation-'] {
  animation-fill-mode: none !important;
}
</style>
