<template>
  <div :uk-spinner="`ratio: ${ratio}`"/>
</template>
<script>
export default {
  props: {
    ratio: {
      required: false,
      type: String,
      default: '3'
    }
  }
}
</script>
