import {
  mapState
} from 'vuex'

import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// import { timezonesWithParams } from '@/timezones'
// moment().tz.add(timezonesWithParams.zones)

export default {
  computed: {
    ...mapState(['settings'])
  },
  methods: {
    dayjs (date, h, withOffset = true) {

      dayjs.extend(utc)
      dayjs.extend(tz)

      const dateStr = String(date)
      if (typeof date === 'string' && !dateStr.length) return this.$t('options.timezone.invalid_date') // date = "2019-11-19T04:00:57"
      if (Number.isNaN(+date) && dateStr[dateStr.length - 1].toUpperCase() !== 'Z' && !/\+/.test(dateStr)) date += 'Z'
      const dateTimeUtc = dayjs(date).utc()
      let dateTimeLocal = dateTimeUtc
      if (withOffset) {
        const timestamp = dateTimeUtc.unix()
        const timezone = this.settings.timezone || 'Etc/UTC'
        const offset = dayjs().tz(timezone).utcOffset() * 60
        dateTimeLocal = dayjs.unix(timestamp + offset).utc()
      }
      const format = h ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
      // const formatted = dayjs().tz(date, timezone).format(format) // парсим дату с указанной таймзоной
      // const formatted = dayjs(date).tz(timezone).format(format) // парсим дату и конвертим в таймзону
      const formatted = dateTimeLocal.format(format)
      return formatted //  2019-11-19 01:00    }
    }
  }
}
