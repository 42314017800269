// @ts-check
/**
 * @typedef {object} Entry
 * @typedef {Entry[]} Entries
 * @typedef {import("../Strategies/Base").StrategyOptions} Options
 */

import i18n from '../../../i18n.js'

/**
 * @class
 * @readonly
 */
class Csv {
  /**
   * @type {string}
   */
  data = ''
  /**
   * @readonly
   * @constructor
   * @param {{entries: Entries, Options: Options}} params
   */
  constructor (params) {
    try {
      let properties = []
      const { entries } = params
      for (const entry of entries) {
        const keys = Object.keys(entry)
        properties.push(...keys)
      }
      properties = [...new Set(properties)]
      if (params.Options.localizeFields) {
        properties = properties.map(property => {
          try {
            return i18n.global.t('csv.' + params.Options.namespace + (params.Options.type ? '.' + params.Options.type : '') + '.' + property)
          } catch (error) {
            console.debug(error)
          }
          return property
        })
      }
      properties = properties.map(property => '"' + property + '"')
      const values = params.entries
        .map(entry => {
          try {
            let values = Object.values(entry)
            values = values.map(value => {
              try {
                if (typeof value === 'number') {
                  value = value.toString()
                }
                return JSON.stringify(value).replaceAll(';', '-')
              } catch (error) {
                console.debug(error)
              }
              return ''
            })
            return values.join(';')
          } catch (error) {
            console.debug(error)
          }
          return ''
        })
        .join('\n')
      this.data = properties.join(';') + '\n' + values
    } catch (error) {
      console.debug(error)
    }
  }
}

export default Csv
