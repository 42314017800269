<template>
  <div class="uk-card uk-card-default uk-margin-bottom">
    <div class="uk-card-header">
      <h4>
        {{ $t('forums.explorers') }}
      </h4>
    </div>
    <div class="uk-card-body uk-padding-small">
      <ul
        class="uk-list uk-list-divider uk-text-break uk-card-body uk-padding-remove-top uk-padding-small uk-text-left"
      >
        <li
          v-for="(explorer, key) in explorers"
          :key="key"
        >
          <a
            :href="explorer"
            target="_blank"
          >
            {{ key }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UIExplorers',
  props: {
    bounceable: {
      type: String
    }
  },
  computed: {
    explorers () {
      return {
        'Ton.cx': `https://ton.cx/address/${this.bounceable}`,
        'Toncoin': `https://explorer.toncoin.org/account?workchain=&shard=&seqno=&roothash=&filehash=&account=${this.bounceable}`,
        'TonWhales': `https://tonwhales.com/explorer/address/${this.bounceable}`,
        'Ton.sh': `https://ton.sh/address/${this.bounceable}`,
        'Tonviewer': `https://tonviewer.com/${this.bounceable}`,
        'tonscan.org' : `https://tonscan.org/address/${this.bounceable}`,
        'Ton NFT': `https://explorer.tonnft.tools/address/${this.bounceable}`,
        'TonObserver': `https://tonobserver.com/explorer/info?address=${this.bounceable}`,
        'Anton.tools' : `https://explorer.anton.tools/accounts/${this.bounceable}#overview`,
        'dton': `https://dton.io/a/${this.bounceable}`
      }
    }
  }
}
</script>
