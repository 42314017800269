<template>
  <div
    class="uk-margin-top uk-card uk-card-body uk-card-default settings"
  >
    <div
      uk-grid
      class="uk-width-1-1"
    >
      <div
        class="uk-text-center uk-width-1-1"
      />
      <div
        class="uk-width-expand uk-margin-remove"
      >
        <div
          v-if="$route.name === 'settings_general'"
        >
          <form class="uk-form-horizontal uk-width-1-2@s uk-align-center">
            <div
              v-if="showLanguages"
              class="uk-text-center"
            >
              <div>
                <label>
                  {{ $t('options.language') }}
                </label>
              </div>
              <vue-select
                v-model="currentLocale"
                :options="languages"
                label="label"
              />
            </div>

            <div class="uk-margin-top uk-text-center">
              <label>
                {{ $t('options.timezone.title') }}
              </label>
              <vue-select
                v-model="currentTimezone"
                :options="timezones"
                label="label"
              />
            </div>
            <div
              class="uk-text-left uk-margin-top"
            >
              <label for="silentWriteOff">
                <input
                  v-model="silentWriteOff"
                  name="silentWriteOff"
                  class="uk-checkbox"
                  type="checkbox"
                  :disabled="loading.token"
                />
                {{ $t('options.silent-token-write-off') }}
              </label>
            </div>
            <div
              class="uk-text-left uk-margin-top"
            >
              <label
                for="searchSave"
                :uk-tooltip="$t('options.search-save-tooltip')"
              >
                <input
                  v-model="searchSave"
                  name="searchSave"
                  class="uk-checkbox"
                  type="checkbox"
                  :disabled="loading.search"
                />
                {{ $t('options.search-save') }}
              </label>
            </div>
            <div
              class="uk-text-left uk-margin-top"
            >
              <label
                for="searchSave"
                :uk-tooltip="$t('options.select-messages-tooltip')"
              >
                <input
                  :checked="selectMessages"
                  name="searchSave"
                  class="uk-checkbox"
                  type="checkbox"
                  :disabled="loading.messages"
                  @click.prevent="selectMessages = !selectMessages"
                />
                {{ $t('options.allow-select-save') }}
              </label>
            </div>
            <div
              class="uk-text-left uk-margin-top"
            >
              <label
                for="nsfwBlur"
                :uk-tooltip="$t('options.nsfw-blur-tooltip')"
              >
                <input
                  v-model="nsfwBlur"
                  name="nsfwBlur"
                  class="uk-checkbox"
                  type="checkbox"
                  :disabled="loading.search"
                />
                {{ $t('options.nsfw-blur') }}
              </label>
            </div>
          </form>
          <div
            class="uk-width-1-2 uk-align-center"
          >
            <Create2FA
              :enabled="twoFA"
            />
            <changePassword
              :twoFA="twoFA"
            />
          </div>
        </div>
        <div
          v-if="$route.name === 'settings_search'"
        >
          <Sources
            class="uk-margin-top"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import UIkit from 'uikit'
import Sources from '@/components/Settings/Sources.vue'
import changePassword from '@/components/general/ChangePassword.vue'
import { timezones, timezonesI18n } from '@/timezones'
import vueSelect from '@/components/VueSelect'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Settings',
  components: {
    vueSelect,
    changePassword,
    Sources,
    Create2FA: defineAsyncComponent(() => import('@/components/Settings/Create2FA.vue'))
  },
  data () {
    return {
      twoFA: false,
      otp: '',
      qr: '',
      signature: '',
      map: false,
      sessions: [],
      modalInstance: null,
      currentTab: '',
      loading: {
        token: false,
        search: false,
        messages: false
      }
    }
  },
  computed: {
    ...mapState([
      'settings',
      'user_name',
      'is_admin'
    ]),
    isLoadingClass () {
      return !this.$store.state.options.isLoaded.settings ? 'uk-disabled' : ''
    },
    showLanguages () {
      const hosts = [
        'asirisweb.defence.tools',
        'asiris.defence.tools',
        'asiris.defencegroup.net'
      ]
      const isHost = hosts.includes(window.location.hostname)
      return !isHost
    },
    coordinates () {
      if (!this.sessions[0]) return false
      return this.sessions.filter(el => el.geo && el.geo.ll)
    },
    languages () {
      return[
        { label: 'English', value: 'en' },
        { label: 'Русский', value: 'ru' },
        { label: 'Español', value: 'es' }
      ]
    },
    silentWriteOff: {
      get () {
        return this.settings.silent_token_write_off
      },
      async set () {
        this.loading.token = true
        try {
          await this.setSilentWriteOff(!this.settings.silent_token_write_off)
        } catch (err){
          console.debug(err)
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        } finally {
          this.loading.token = false
        }
      }
    },
    searchSave: {
      get () {
        return this.settings.search_save
      },
      async set () {
        this.loading.search = true
        try {
          await this.setSearchSave(!this.settings.search_save)
        } catch (err){
          console.debug(err)
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        } finally {
          this.loading.search = false
        }
      }
    },
    nsfwBlur: {
      get () {
        return this.settings.blur_nsfw_forums
      },
      async set () {
        this.loading.search = true
        try {
          await this.setNsfwBlur(!this.settings.blur_nsfw_forums)
        } catch (err){
          console.debug(err)
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        } finally {
          this.loading.search = false
        }
      }
    },
    currentLocale: {
      get () {
        return this.settings.translate
      },
      set (obj) {
        this.putLanguage((obj && obj.value) || null)
      }
    },
    selectMessages: {
      get () {
        return this.settings.choose_messages_to_export
      },
      async set (val) {
        this.loading.messages = true
        try {
          await this.setChooseMessages(val)
        } catch (err){
          console.debug(err)
          this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
        } finally {
          this.loading.messages = false
        }
      }
    },
    timezones () {
      return Intl.supportedValuesOf('timeZone')
        .filter(tz => timezones.includes(tz))
        .reduce((acc, name) => {
          const offset = dayjs().tz(name).utcOffset()
          const timezone = offset ? dayjs().tz(name).format('Z') : ''
          const translation = timezonesI18n[name] || ''
          const label = `(GMT${timezone}) ${translation}`
          return [...acc, { value: name, timezone, offset, label }]
        }, [])
        .sort(({ offset: offsetA }, { offset: offsetB }) => offsetA - offsetB)
    },
    currentTimezone: {
      get () {
        if (!this.settings.timezone) return 'GMT' // if null
        return this.settings.timezone
      },
      set (obj) {
        this.setTimezone((obj && obj.value) || null)
        this.putTimezone()
      }
    }
  },
  methods: {
    ...mapActions([
      'putLanguage',
      'putTimezone',
      'getCurrentUser',
      'setSilentWriteOff',
      'setSearchSave',
      'setNsfwBlur',
      'setChooseMessages'
    ]),
    ...mapMutations([
      'setTimezone'
    ]),
    showMap () {
      UIkit.modal('#map')
        .show()
        .then(() => {
          this.map = true
        })
    }
  },
  async created () {
    const { data } = await this.getCurrentUser()
    this.twoFA = data.has_otp
  }
}
</script>

<style scoped>
.settings{
  min-height: 87vh;
}
</style>
