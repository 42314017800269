<template>
  <div
    id="map"
    class="uk-margin-top"
    :style="{ height: window.height - 90 + 'px' }"
  >
    <l-map
      ref="map"
      style="height: 100%; width: 100%"
      :zoom="zoom"
      :center="center"
      :options="{
        zoomControl: false,
        worldCopyJump: true,
        minZoom: 3,
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [{
          text: 'Copy coordinates',
          callback: copyCoords
        }, {
          text: 'Add geotask',
          callback: addGeotask
        }]
      }"
      :maxBounds="mapMaxBounds"
      @update:bounds="boundsUpdated"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
      />
      <l-geosearch :options="geosearchOptions" />
      <l-control-scale
        position="bottomleft"
        :imperial="true"
        :metric="true"
      />
      <l-control-zoom position="topleft" />
      <l-legend
        ref="legend"
        position="topright"
        :layers="layers"
      />
    </l-map>
  </div>
</template>

<script>
/* eslint-disable */

import L, {
  latLng,
  Icon,
  icon,
  divIcon
} from 'leaflet'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlZoom,
  LControlScale
} from 'vue2-leaflet'
import 'leaflet-rotatedmarker'
import 'leaflet-contextmenu'
import 'leaflet-realtime'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import Geosearch from 'vue2-leaflet-geosearch'

import '@/assets/css/leaflet-geosearch.css'
import '@/assets/css/ControlLoading.css'
import '@/assets/css/leaflet.contextmenu.css'

import camIconUrl from '@/assets/mapIcons/cctv.png'
import disabledIconUrl from '@/assets/mapIcons/marker-icon.png'
import mobileIconUrl from '@/assets/mapIcons/mobile_icon.png'

import ControlLoading from '@/components/map/controls/Loading.js'
import mapLegend from '@/components/map/controls/Legend.vue'
import MarkerCluster from '@/components/map/MarkerCluster.vue'

import tgUsersLayer from '@/components/map/layers/tgUsers'
import GeoTasks from '@/components/map/layers/GeoTasks'

import { mapActions } from 'vuex'

import axios from 'axios'

delete Icon.Default.prototype._getIconUrl

import config from '@/config'
const { servers: { map } } = config

  export default {
    mixins: [
      tgUsersLayer,
      GeoTasks
    ],
    components: {
      'l-map': LMap,
      'l-tile-layer': LTileLayer,
      'l-control-zoom': LControlZoom,
      'l-control-scale': LControlScale,
      'l-geosearch': Geosearch,
      'l-marker-cluster': MarkerCluster,
      'l-marker': LMarker,
      'l-popup': LPopup,
      'l-legend': mapLegend
    },
    name: 'globalMap',
    data () {
      return {
        mapInstance: null,
        window: {
          width: 0,
          height: 0
        },
        layers: {
          geoTasks: false,
          tgUsers: false
        },
        url: map + '/{z}/{x}/{y}.png',
        coverageUrl: 'https://opencellid.org/images/maps/opencellid/{z}/{x}/{y}.png?v=',
        zoom: 4,
        currentZoom: 4,
        currentBounds: null,
        lastZoom: 3,
        center: [31.750357558882385, 46.19476318359376],
        bounds: null,
        geosearchOptions: {
          position: 'topleft',
          style: 'bar',
          provider: new OpenStreetMapProvider()
        },
        STCClusterOptions: {}
      }
    },
    methods: {
      ...mapActions('tgcp', [
        'fetchUsersGeoNearWithCoords',
        'addTask',
        'fetchUserById',
        'getFromCacheOrRequestUserData'
      ]),
      layerFromUser(user) {
        return {
          "type": "Feature",
          "properties": {
            "user": user,
            "id": user[0].user_id,
          },
          "id": user[0].user_id,
          "geometry": {
            "type": "Point",
            "coordinates": [user.coordinates[1], user.coordinates[0]]
          }
        }
        return L.marker(user.coordinates, {
          icon: L.icon({
            iconUrl: mobileIconUrl,
            iconSize:     [45, 41],
            iconAnchor:   [12, 38]
          }),
          radius: 5
        })
      },
      copyCoords (e) {
        const coords = [ e.latlng.lat, e.latlng.lng ].join(',')
        const el = document.createElement('textarea')
        el.value = coords
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      },
      boundsUpdated (bounds) {
        this.currentBounds = bounds
      },
      zoomUpdate (zoom) {
        this.lastZoom = this.currentZoom
        this.currentZoom = zoom
      },
      getBBOX (bounds) {
        if (Math.abs(bounds._southWest.lng) > 180) bounds._southWest.lng %= 180
        if (Math.abs(bounds._northEast.lng) > 180) bounds._northEast.lng %= 180
        return bounds._southWest.lat + ',' + bounds._southWest.lng + ',' + bounds._northEast.lat + ',' + bounds._northEast.lng
      },
      handleResize () {
        this.window.width = window.innerWidth
        this.window.height = window.innerHeight
      },
      loadingHandler (map) {
        return (e) => {
          map.fireEvent('dataloading', e)
        }
      },
      loadHandler (map) {
        return (e) => {
          map.fireEvent('dataload', e)
        }
      },
      overlayEventHandler (event) {
        if (!event) return
        let value = null
        if (event.type === 'overlayadd') value = true
        else if (event.type === 'overlayremove') value = false
        else return
        switch (event.name) {
          case 'Geo Tasks':
            this.layers.geoTasks = value
            const isNotLoadedGeoTask = this.getCountMarkers(window.geoTasks) === 0
            if (value && isNotLoadedGeoTask) this.getGeoTasks()
            break
          case 'TG Users':
            this.layers.tgUsers = value
            const isNotLoadedTgUsers = this.getCountMarkers(window.tgUsers) === 0
            if (value && isNotLoadedTgUsers) this.getTgUsers()
            break
        }
      },
      getCountMarkers (layer) {
        if (!layer) return 0
        if (layer.getLayers()) {
          return layer.getLayers().length
        }
      }
    },
    computed: {
      mapMaxBounds () {
        // eslint-disable-next-line
        return new L.latLngBounds(new L.latLng(-90, -Infinity), new L.latLng(90, Infinity))
      }
    },
    created () {
      // eslint-disable-next-line
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    async mounted () {
      await this.$nextTick()
      this.mapInstance = this.$refs.map.mapObject
      this.bounds = this.mapInstance.getBounds()
      L.control.layers(
        {},
        {
          'Geo Tasks': window.geoTasks,
          'TG Users': window.tgUsers.addTo(this.mapInstance)
        },
        {
          hideSingleBase: true,
          position: 'topleft'
        }
      ).addTo(this.mapInstance)
      ControlLoading({ position: 'topleft' }).addTo(this.mapInstance)
      this.mapInstance.on('overlayremove', this.overlayEventHandler)
      this.mapInstance.on('overlayadd', this.overlayEventHandler)
      this.overlayEventHandler({ type: 'overlayadd', name: 'TG Users' })
      if (this.$route.params.coords) {
        let coords = this.$route.params.coords.split(',')
        if (coords.length === 2) {
          let lat = Number.parseFloat(coords[0])
          let lon = Number.parseFloat(coords[1])
          if (Math.abs(lat) <= 90 && Math.abs(lon) <= 180) {
            this.mapInstance.setView(coords, 14)
          }
          let data = this.$route.params.data
          if (data) {
            this.mapInstance.setView(coords, 18)
            let marker = L.marker(coords, {
              icon: L.icon({
                iconUrl: mobileIconUrl,
                iconSize:     [26, 26],
                iconAnchor:   [12, 38]
              }),
              radius: 5
            }).addTo(this.mapInstance)
          }
        }
      }
    },
    unmounted () {
      window.removeEventListener('resize', this.handleResize)
    }
  }
</script>

<style>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  #map {
    position: relative;
    z-index: 0;
    width: 100%;
    margin-top: 60px;
  }

  .custom-icon {
    border: none!important;
    background-color: none;
  }

  div.chat-icon {
    text-align: center;
  }

  div.chat-icon div {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 40%;
    color: #ffffff;
  }

  .leaflet-control-layers {
    text-align: left;
  }
  .leaflet-control-layers-overlays {
    text-align: left;
  }
  .leaflet-control-layers-toggle {
    width: 30px!important;
    height: 30px!important;
  }

  .airport-marker-cluster-small {
    background-color: rgba(186, 85, 211, 0.6);
  }
  .airport-marker-cluster-small div {
    background-color: rgba(186, 85, 211, 0.6);
  }
  .airport-marker-cluster-medium {
    background-color: rgba(148, 0, 211, 0.6);
  }
  .airport-marker-cluster-medium div {
    background-color: rgba(148, 0, 211, 0.6);
  }
  .airport-marker-cluster-large {
    background-color: rgba(128, 0, 128, 0.6);
    color: #ffffff;
  }
  .airport-marker-cluster-large div {
    background-color: rgba(128, 0, 128, 0.6);
  }

  .singapore-traffic-cams-marker-cluster {
    background-color: rgba(0, 100, 0, 0.6);
    color: #ffffff;
  }
  .singapore-traffic-cams-marker-cluster div {
    background-color: rgba(0, 100, 0, 0.6);
  }

  .tgcp-marker-cluster-small {
    background-color: rgba(135, 206, 235, 0.6);
  }
  .tgcp-marker-cluster-small div {
    background-color: rgba(135, 206, 235, 0.6);
  }
  .tgcp-marker-cluster-medium {
    background-color: rgba(30, 144, 255, 0.6);
  }
  .tgcp-marker-cluster-medium div {
    background-color: rgba(30, 144, 255, 0.6);
  }
  .tgcp-marker-cluster-large {
    background-color: rgba(0, 0, 139, 0.6);
    color: #ffffff;
  }
  .tgcp-marker-cluster-large div {
    background-color: rgba(0, 0, 139, 0.6);
  }

  .cell-marker-cluster-small {
    background-color: rgba(205, 92, 92, 0.6);
  }
  .cell-marker-cluster-small div {
    background-color: rgba(205, 92, 92, 0.6);
  }
  .cell-marker-cluster-medium {
    background-color: rgba(178, 34, 34, 0.6);
  }
  .cell-marker-cluster-medium div {
    background-color: rgba(178, 34, 34, 0.6);
  }
  .cell-marker-cluster-large {
    background-color: rgba(139, 0, 0, 0.6);
  }
  .cell-marker-cluster-large div {
    background-color: rgba(139, 0, 0, 0.6);
  }

  .geotask-marker-cluster-small {
    background-color: rgba(163, 228, 215, 0.6);
  }
  .geotask-marker-cluster-small div {
    background-color: rgba(163, 228, 215, 0.6);
  }
  .geotask-marker-cluster-medium {
    background-color: rgba(72, 201, 176, 0.6);
  }
  .geotask-marker-cluster-medium div {
    background-color: rgba(72, 201, 176, 0.6);
  }
  .geotask-marker-cluster-large {
    background-color: rgba(23, 165, 137, 0.6);
  }
  .geotask-marker-cluster-large div {
    background-color: rgba(23, 165, 137, 0.6);
  }
  .geouser-marker-cluster-small {
    background-color: rgba(163, 228, 215, 0.6);
  }
  .geouser-marker-cluster-small div {
    background-color: rgba(163, 228, 215, 0.6);
  }
  .geouser-marker-cluster-medium {
    background-color: rgba(72, 201, 176, 0.6);
  }
  .geouser-marker-cluster-medium div {
    background-color: rgba(72, 201, 176, 0.6);
  }
  .geouser-marker-cluster-large {
    background-color: rgba(23, 165, 137, 0.6);
  }
  .geouser-marker-cluster-large div {
    background-color: rgba(23, 165, 137, 0.6);
  }
</style>
