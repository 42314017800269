<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            class="uk-display-inline-block"
          >
            <div class="uk-flex">
              <input
                v-model="query.search"
                type="text"
                :placeholder="$t('tgcp.channels.search_placeholder')"
                class="uk-input uk-form-width-small uk-form-width-medium"
              />
              <button
                type="submit"
                class="uk-button uk-button-primary uk-search-icon"
              >
                <span uk-search-icon />
              </button>
            </div>
          </div>
          <div class="uk-display-inline-block">
            {{ $t('twitter.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block">
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div><div class="uk-display-inline-block uk-position-relative">
            <flatPickr
              v-model="fromDate"
              :placeholder="$t('tgcp.messages.from_date')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              :config="{
                maxDate: toDate || new Date()
              }"
            />
            <button
              v-if="fromDate"
              class="uk-position-right uk-animation-fade uk-animation-fast"
              uk-icon="close"
              style="z-index:2;padding-right: 5px"
              @click="fromDate = null"
            />
          </div>
          <div class="uk-display-inline-block uk-position-relative">
            <flatPickr
              v-model="toDate"
              :placeholder="$t('tgcp.messages.to_date')"
              class="uk-input uk-form-width-small uk-form-width-medium"
              :config="{
                minDate: fromDate || '2010-05-11',
                maxDate: new Date()
              }"
            />
            <button
              v-if="toDate"
              class="uk-position-right uk-animation-fade uk-animation-fast"
              uk-icon="close"
              style="z-index:2;padding-right: 5px"
              @click="toDate = null"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <template v-else>
          <Item
            :messages="messages"
            class="uk-margin-top"
          />
        </template>
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="id"
      :search="query.search"
      namespace="discord"
      type="messages"
      :subtype="type"
      :query="query"
      :totalCount="pagination.total ?? 0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'DiscordMessageList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Discord/MessageList/Item')),
    Paginate,
    vueSelect,
    Loader,
    flatPickr
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'user', 'channel', 'guild'].includes(val)
    }
  },
  data () {
    return {
      messages: [],
      orderList: ['id'],
      query: {
        limit: 20,
        offset: +this.$route.query.offset || 0,
        order_by: this.$route.query.order_by || 'id',
        order_type: this.$route.query.order_type || (['channel', 'user'].includes(this.type) ? 'asc' : 'desc'),
        search: this.$route.query.search || '',
        from_date: null,
        to_date: null
      },
      pagination: {
        page: 1,
        total: 0
      },
      selectedMessages: []
    }
  },
  watch: {
    id () {
      return this.update()
    }
  },
  computed: {
    ...mapGetters('discord', ['isLoaded']),
    hasExport () {
      try {
        return (this.type !== 'all' || (this.query.search)) && this.messages.length
      } catch (error) {
        console.debug(error)
      }
      return false
    },
    hasDataToView () {
      return this.messages.length > 0
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded[`${this.type}_${this.id}_messages`] : this.isLoaded['messages']
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.searchHandler()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.searchHandler()
      }
    },
    toDate : {
      get () {
        return this.query.to_date ?? null
      },
      set (value) {
        if (this.query.to_date === value) return
        this.query.to_date = value
        this.searchHandler()
      }
    },
    fromDate : {
      get () {
        return this.query.from_date ?? null
      },
      set (value) {
        if (this.query.from_date === value) return
        this.query.from_date = value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('discord.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('discord.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('discord.sort.sort_type_desc') }
      ]
    }
  },
  methods: {
    ...mapActions('discord', ['fetchAllMessages', 'addParamToQuery', 'fetchMessageById']),
    init () {
      const page = Math.floor(this.query.offset / this.query.limit + 1)
      this.setPage(page)

      const searchFields = this.$route.query.include
      if (searchFields) this.query.include = searchFields && Array.isArray(searchFields) ? searchFields : [searchFields]
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setData (messages) {
      this.messages = messages.data
      this.pagination.total = messages.meta.results_count
    },
    searchHandler () {
      this.setPage(1)
    },
    handleError (err) {
      this.messages = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    update () {
      let { type } = this
      const data = {
        id: this.id,
        query: { ...this.query, include: ['embeds', 'attachments', 'sticker_items'] }
      }
      if (type === 'all') {
        this.addParamToQuery(this.query)
      }
      const opts = {
        all: ['fetchAllMessages', { query: data.query }],
        user: ['fetchAllMessages', { query: { ...data.query, author_id: data.id}, type: 'user', id: data.id }],
        channel: ['fetchAllMessages', { query: { ...data.query, channel_id: data.id}, type: 'channel', id: data.id }],
        guild: ['fetchAllMessages', { query: { ...data.query, guild_id: data.id}, type: 'guild', id: data.id }]
      }
      const [method, payload] = opts[type]

      return this[method](payload)
        .then(messages => this.setData(messages))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>
