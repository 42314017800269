<template>
  <div class="uk-margin-top">
    {{ $t('search.by_email_title') }} <b>
      {{ $route.params.email }}
    </b>
    <li
      v-if="currentCase"
      class="uk-margin-left uk-button-primary uk-button"
      @click="profileAdd({
        data: {
          seed_data: $route.params.email
        },
        caseId: currentCase
      })"
    >
      {{ $t('threema.search.add-profile') }}
    </li>
    <div v-if="possible">
      {{ $t('threema.possible-' + possible.type) }}:
      <i>
        {{ possible.data }}
      </i>
      <router-link
        :to="{ name: possible.type + 'Results', params: { [possible.type]: possible.data }}"
        class="uk-icon-link uk-margin-small-left uk-icon"
        uk-icon="search"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          data-svg="search"
        >
          <circle
            fill="none"
            stroke="#000"
            stroke-width="1.1"
            cx="9"
            cy="9"
            r="7"
          />
          <path
            fill="none"
            stroke="#000"
            stroke-width="1.1"
            d="M14,14 L18,18 L14,14 Z"
          />
        </svg>
      </router-link>
    </div>
    <div
      class="uk-grid uk-margin-top"
      data-uk-grid-margin
    >
      <div class="uk-width-expand">
        <loader v-if="loading" />
        <div
          v-else
          class="uk-grid-small uk-child-width-1-3@s uk-flex-left uk-grid"
        >
          <div
            v-for="item in cards"
            :key="item.getId()"
            class=" uk-grid-margin"
          >
            <usercard
              :key="item.getId()"
              :generalUser="item"
              :showAddProfileButton="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Threema from '@/utils/providers/threema'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Emails',
  components: {
    usercard: defineAsyncComponent(() => import('@/components/UserCard2')),
    'loader': defineAsyncComponent(() => import('@/components/TGCP/Loader'))
  },
  props: {
    email: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      loading: false,
      cards: [],
      details: {}
    }
  },
  watch: {
    email (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapGetters('cases', [
      'currentCase'
    ]),
    ...mapGetters('fetcher', [
      'profilesCount'
    ]),
    possible () {
      if (!this.email) return
      let splitted = this.email.split('@')
      let phone = parseMax('+' + splitted[0])
      if (phone && phone.isValid()) return { type: 'phone', data: phone.number }
      return { type: 'nickname', data: splitted[0] }
    }
  },
  methods: {
    ...mapActions('threema', [
      'fetchProfile'
    ]),
    ...mapActions('cases', [
      'profileAdd'
    ]),
    updateDetails (raw) {
      this.details = raw
    },
    async update () {
      try {
        this.loading = true
        const cards = await this.fetchProfile({ searchData: this.email, searchType: 'email' })
        this.cards = cards.map(el => Threema.formatData(el))
      } catch {
        this.$notify({ group: 'general', title: this.$t('notifications.something-went-wrong'), type: 'error' })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
