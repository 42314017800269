<template>
  <div
    id="external-link-dialog"
    ref="externalLinkDialog"
    class="uk-modal-container"
    uk-modal
  >
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <p>
          {{ $t('links.notification') }}
          <br/>
          {{ $t('links.link') }}: <span class="uk-text-break">{{ link }}</span>
        </p>
        <div v-if="showAddTask">
          <hr/>
          <template v-if="linkType === TGCP_JOINLINK_LINK_TYPE">
            <AddTgcpTask
              :link="link"
              :default-priority="tgcpDefaultPriority"
              @added="addedTask"
            />
          </template>
          <template v-if="linkType === WA_INVITELINK_LINK_TYPE">
            <AddWaTask
              :link="link"
              @update="addedTask"
            />
          </template>
          <template v-if="twitterTask">
            <AddTwitterTask
              :link="twitterTask"
              @update="addedTask"
            />
          </template>
        </div>
      </div>
      <div class="uk-modal-footer uk-text-center">
        <button
          class="uk-button uk-button-default uk-modal-close"
          type="button"
        >
          {{ $t('links.cancel') }}
        </button>
        
        <button
          v-if="hasConfirm"
          class="uk-margin-small-left uk-button uk-button-primary"
          type="button"
          @click="directGoTo(link)"
        >
          {{ $t('links.confirm') }}
        </button>
        <div
          v-if="linkType === WA_INVITELINK_LINK_TYPE"
          class="uk-inline"
        >
          <button
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="showWaAddTaskModal"
          >
            {{ $t('links.add_to_task') }}
          </button>
          <button
            v-if="whatsappGroupLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(whatsappGroupLink)"
          >
            {{ $t('links.view_group') }}
          </button>
        </div>
        <div
          v-if="linkType === WA_USERLINK_LINK_TYPE"
          class="uk-inline"
        >
          <button
            v-if="whatsappUserLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(whatsappUserLink)"
          >
            {{ $t('links.view_user') }}
          </button>
          <button
            v-if="whatsappUser"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(`/phone/+${whatsappUser}/`)"
          >
            {{ $t('links.search_phone') }}
          </button>
        </div>
        <div
          v-if="linkType === DISCORD_INVITELINK_LINK_TYPE"
          class="uk-inline"
        >
          <button
            v-if="discordGuildLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(discordGuildLink)"
          >
            {{ $t('links.view_guild') }}
          </button>
          <button
            v-if="discordUserLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(discordUserLink)"
          >
            {{ $t('links.view_user') }}
          </button>
        </div>
        <div
          class="uk-inline"
        >
          <button
            v-if="twitterTask"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="showTwitterAddTaskModal"
          >
            {{ $t('links.add_to_task') }}
          </button>
          <div
            v-if="twitterUserFetching"
            uk-spinner
            class="uk-margin-small-left"
          />
          <button
            v-else-if="!twitterUserFetching && twitterUserHref"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(twitterUserHref)"
          >
            {{ $t('links.view_user') }}
          </button>
        </div>
        <div
          v-if="linkType === TGCP_JOINLINK_LINK_TYPE"
          class="uk-inline"
        >
          <button
            v-if="hasTaskButton"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="showTGCPAddTaskModal"
          >
            {{ $t('links.add_to_task') }}
          </button>

          <button
            v-if="tgcp.authorLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(tgcp.authorLink)"
          >
            {{ $t('links.go_to_link_author') }}
          </button>

          <button
            v-if="tgcp.chatLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(tgcp.chatLink)"
          >
            {{ isChannel ? $t('links.view_channel') : $t('links.view_chat') }}
          </button>

          <button
            v-if="tgcp.userLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(tgcp.userLink)"
          >
            {{ $t('links.view_user') }}
          </button>
          <button
            v-if="tgcp.messageLink"
            class="uk-button uk-button-default uk-margin-small-left"
            type="button"
            @click="directGoTo(tgcp.messageLink)"
          >
            {{ $t('tgcp.messages.go_to') }}
          </button>
        </div>
        <button
          v-if="!hasConfirm"
          class="uk-button uk-button-default uk-margin-small-left"
          type="button"
          @click="directGoTo(link)"
        >
          {{ $t('links.go_to_external_link') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import TgcpJoinLink, {telegramExp, isTelegramLink, notTelegramExp, telegramChatExp} from '@/utils/tgcpJoinLink'
import { mapActions, mapGetters } from 'vuex'
import { group as whatsappGroup, user as whatsappUser } from '@/utils/whatsapp'
import { group as discordGroupTest, user as discordUserTest } from '@/utils/discord'
import { userName as getTwitterUserName } from '@/utils/twitter'
import { defineAsyncComponent } from 'vue'

const OTHER_LINK_TYPE = 'other'
const TGCP_JOINLINK_LINK_TYPE = 'tgcpJoinLink'
const WA_INVITELINK_LINK_TYPE = 'waInvitelink'
const WA_USERLINK_LINK_TYPE = 'waInvitelink'
const DISCORD_INVITELINK_LINK_TYPE = 'discordLink'

export default {
  name: 'ExternalLinkDialog',
  components: {
    AddTgcpTask: defineAsyncComponent(() => import('@/components/TGCP/Tasks/AddTask')),
    AddWaTask: defineAsyncComponent(() => import('@/components/WhatsApp/Tasks/AddTask')),
    AddTwitterTask: defineAsyncComponent(() => import('@/components/Twitter/Tasks/AddTask'))
  },
  data () {
    return {
      link: '',
      linkType: OTHER_LINK_TYPE,
      tgcp: {
        authorLink: '',
        userLink: '',
        chatLink: '',
        showAddTask: false
      },
      twitter: {
        user: {
          fetching: false,
          id: null
        }
      },
      isChannel: false,
      whatsappGroupLink: '',
      whatsappUserLink: '',
      discordGuildLink: '',
      discordUserLink: '',
      showAddTask: false
    }
  },
  watch: {
    async link (link) {
      try {
        this.discordUserLink = ''
        this.discordGuildLink = ''
        if (this.whatsappGroup) {
          this.linkType = WA_INVITELINK_LINK_TYPE
          this.whatsappGroupLink = ''
          const response = await this.fetchAllGroups({
            search: this.whatsappGroup,
            search_fields: ['invite_hash'],
            caseSensitive: true,
            withoutFetchingToView: true
          })
          if (
            response?.data &&
            response.data.length > 0 &&
            response.data[0]?.id
          ) {
            this.whatsappGroupLink = `/whatsapp/group/${response.data[0].id}`
          }
        } else if (this.whatsappUser) {
          this.linkType = WA_USERLINK_LINK_TYPE
          this.whatsappUserLink = ''
          await this.resolveUserPhone(this.whatsappUser).then(response => {
            if (
              response &&
              response.phone
            ) {
              this.whatsappUserLink = `/whatsapp/user/${response.phone}`
            }
          })
        } else if (this.discordUser) {
          this.linkType = DISCORD_INVITELINK_LINK_TYPE
          await this.fetchUserByIdWithoutCommitting(this.discordUser).then(response => {
            if (
              response &&
              response.id
            ) {
              this.discordUserLink = `/discord/users/${response.id}`
            }
          })
        } else if (this.discordGroup) {
          this.linkType = DISCORD_INVITELINK_LINK_TYPE
          await this.resolveGuildInvite(this.discordGroup).then(response => {
            if (
              response &&
              response.guild_id
            ) {
              this.discordGuildLink = `/discord/guilds/${response.guild_id}`
            }
          })
        } else if (isTelegramLink(link)) {
          this.linkType = TGCP_JOINLINK_LINK_TYPE
        } else {
          this.linkType = OTHER_LINK_TYPE
        }
        if (this.linkType === TGCP_JOINLINK_LINK_TYPE) {
          await this.parseTGCPJoinLink(link)
        }
        if (this?.fetchTwitterUserId) {
          this.fetchTwitterUserId()
        }
      } catch (error) {
        console.debug(error)
      }
    }
  },
  computed: {
    ...mapGetters(['tgcpDefaultPriority']),
    twitterUserName () {
      if (this?.link) {
        return getTwitterUserName(this.link)
      }
      return ''
    },
    twitterUserId () {
      return this?.twitter?.user?.id
    },
    twitterUserHref () {
      if (
        (
          typeof this?.twitterUserId === 'number' ||
          typeof this?.twitterUserId === 'string'
        ) &&
        this?.$router?.resolve instanceof Function
      ) {
        const HREF = this.$router.resolve({
          name: 'twitter_user_details',
          params: {
            id: this.twitterUserId.toString()
          }
        })?.href
        if (typeof HREF === 'string') {
          return HREF
        }
      }
      return ''
    },
    twitterUserFetching () {
      return this?.twitter?.user?.fetching
    },
    twitterTask () {
      if (typeof this?.twitterUserName === 'string') {
        return '@'.concat(this.twitterUserName)
      }
      return ''
    },
    hasConfirm () {
      try {
        return (
          !this?.tgcp.messageLink &&
          !this?.tgcp.chatLink &&
          !this?.tgcp.userLink &&
          !this?.tgcp.authorLink &&
          !this?.whatsappGroupLink &&
          !this?.twitterUserId &&
          !this?.hasTaskButton &&
          this.linkType === OTHER_LINK_TYPE
        )
      } catch (error) {
        console.debug(error)
      }
      return false
    },
    discordGroup () {
      try {
        return discordGroupTest(this.link)
      } catch {
        console.debug(error)
      }
      return null
    },
    discordUser () {
      try {
        return discordUserTest(this.link)
      } catch {
        console.debug(error)
      }
      return null
    },
    whatsappGroup () {
      try {
        return whatsappGroup(this.link)
      } catch (error) {
        console.debug(error)
      }
      return null
    },
    whatsappUser () {
      try {
        return whatsappUser(this.link)
      } catch (error) {
        console.debug(error)
      }
      return null
    },
    hasTaskButton () {
      return !telegramChatExp.test(this.link)
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchUserById',
      'searchChannelsByUsername',
      'fetchAllUsers',
      'fetchChannelById'
    ]),
    ...mapActions('whatsapp', [
      'fetchAllGroups',
      'resolveUserPhone'
    ]),
    ...mapActions('discord', [
      'resolveGuildInvite',
      'fetchUserByIdWithoutCommitting'
    ]),
    fetchTwitterUserId () {
      if (this?.twitterUserName) {
        this.twitter.user.fetching = true
        this.$store.dispatch('twitter/fetchUserByName', this.twitterUserName)
          .then(data => {
            if (data?.id) {
              this.twitter.user.id = data.id
            } else {
              this.twitter.user.id = null
            }
          })
          .catch(() => {
            this.twitter.user.id = null
          })
          .finally(() => {
            this.twitter.user.fetching = false
          })
      }
    },
    messageLink (chatId, messageId) {
      try {
        return this.$router.resolve({
          name: 'tgcp_go_to_message',
          params: {
            chatId,
            messageId
          }
        }).href
      } catch (error) {
        console.debug(error)
      }
      return ''
    },
    showModal (link) {
      this.showAddTask = false
      this.link = link
      const dialog = this.$refs.externalLinkDialog
      if (dialog) {
        UIkit.modal(dialog).show()
      } else {
        console.error('Can\'t find dialog')
      }
    },
    directGoTo (link) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      if (isSafari) {
        const linkElement = document.createElement('a')
        linkElement.target = '_blank'
        linkElement.href = link
        linkElement.rel = 'noopener noreferrer'
        linkElement.skipGlobalLinksHandler = true
        document.body.appendChild(linkElement)
        linkElement.click()
        linkElement.parentNode.removeChild(linkElement)
      } else {
        window.open(link, '_blank', 'noopener=yes, noreferrer=yes')
      }
      const dialog = this.$refs.externalLinkDialog
      if (dialog) {
        UIkit.modal(dialog).hide()
      }
    },
    showTGCPAddTaskModal () {
      this.showAddTask = true
    },
    showWaAddTaskModal () {
      this.showAddTask = true
    },
    showTwitterAddTaskModal () {
      this.showAddTask = true
    },
    addedTask () {
      this.showAddTask = false
      const externalLinkDialog = this.$refs.externalLinkDialog
      if (externalLinkDialog) {
        UIkit.modal(externalLinkDialog).hide()
      }
    },
    async parseTGCPJoinLink (link) {
      this.tgcp.authorLink = ''
      this.tgcp.chatLink = ''
      this.tgcp.messageLink = ''
      let linkId = ''
      const groups = link.match(telegramExp)?.groups
      const joinlink = groups?.URL_REF
      let userName = groups?.URL_NAME || groups?.URL_NAME_A || groups?.NAME
      if (notTelegramExp.test(link)) {
        userName = undefined
      }
      const messageId = groups?.URL_ID2 ?? groups?.URL_ID
      linkId = joinlink || userName
      const isUserId = joinlink && TgcpJoinLink.isUserId(linkId)
      if (isUserId) {
        const id = TgcpJoinLink.extractUserId(linkId)
        if (id) {
          try {
            const userdata = await this.fetchUserById({ id })
            if (userdata.answer_status === 'FOUND') {
              this.tgcp.authorLink = '/tgcp/user/' + id
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      try {
        const chatdata = await this.searchChannelsByUsername({
          search_fields: userName ? 'username' : 'joinlinks',
          full_match: !joinlink,
          search: joinlink ? '"' + linkId + '"' : linkId
        })
        if (chatdata && chatdata.length > 0) {
          if (
            chatdata?.[0]?.broadcast
          ) {
            this.isChannel = true
          } else {
            this.isChannel = false
          }
          const chatId = chatdata[0].tg_id
          if (chatId) {
            this.tgcp.chatLink = '/tgcp/channel/' + chatId
            if (messageId) {
              this.tgcp.messageLink = this.messageLink(chatId, messageId)
            }
          }
        } else {
          const entries = await this.fetchAllUsers({
            search: linkId,
            'search_fields': ['username'],
            withoutFetchingToView: true
          })
          if (entries.length === 1) {
            this.tgcp.userLink = '/tgcp/user/' + entries[0].tg_id
          }
        }
      } catch (e) {
        console.error(e)
      }
      try {
        const groups = link.match(telegramChatExp)?.groups
        const chatId = groups?.URL_NAME
        const messageId = groups?.URL_ID
        if (chatId) {
          this.tgcp.showAddTask = false
          this.tgcp.chatLink = '/tgcp/channel/' + chatId
          if (messageId) {
            this.tgcp.messageLink = this.messageLink(chatId, messageId)
          }
          try {
            this.fetchChannelById(chatId)
              .then(data => {
                if (data?.broadcast) {
                  this.isChannel = true
                } else {
                  this.isChannel = false
                }
              })
          } catch (error) {
            console.debug(error)
          }
        }
      } catch (error) {
        console.debug(error)
      }
    }
  },
  created () {
    this.OTHER_LINK_TYPE = OTHER_LINK_TYPE
    this.TGCP_JOINLINK_LINK_TYPE = TGCP_JOINLINK_LINK_TYPE
    this.WA_INVITELINK_LINK_TYPE = WA_INVITELINK_LINK_TYPE
    this.DISCORD_INVITELINK_LINK_TYPE = DISCORD_INVITELINK_LINK_TYPE
    this.WA_USERLINK_LINK_TYPE = WA_USERLINK_LINK_TYPE
  }
}
</script>

<style scoped>

</style>
