<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('tgcp.user_details.day_activity_title') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <flatPickr
          v-model="date"
          class="uk-input uk-form-width-small uk-form-width-medium"
          :placeholder="$t('tgcp.user_details.select-date-range')"
          :config="flatPickrConfig"
          @on-close="changeDate"
        />
        <div
          v-if="!isLoaded"
        >
          <Loader/>
        </div>
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import Loader from '@/components/TGCP/Loader'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapState } from 'vuex'
import IntegerAxisFormatter from '@/utils/IntegerAxisFormatter'
import rotate from '@/utils/rotateArray.js'
export default {
  name: 'TgcpUserDayGraph',
  components: {
    Loader,
    VueApexCharts,
    flatPickr
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      dateRange: [],
      date: '',
      flatPickrConfig: {
        maxDate: new Date(),
        mode: 'range'
      },
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          formatter: val => +val > 2 ? val : ''
        },
        xaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_hours')
          },
          labels: {
            formatter: IntegerAxisFormatter.format
          }
        },
        yaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          },
          min: 0
        },
        tooltip: {
          marker: {
            show: true
          },
          y: {
            show: true,
            formatter: IntegerAxisFormatter.format
          }
        }
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    ...mapState([
      'settings'
    ]),
    chatsActivity () {
      if (!this.id) return
      let dayActivity = this.$store.state.tgcp.users[this.id]['activity']['day']['activity']
      dayActivity = rotate(Object.values(dayActivity), -1 * +dayjs().tz(this.settings.timezone).format('Z').split(':')[0])
      const series = [{
        name: this.$i18n.t('tgcp.user_details.graph_messages'),
        data: dayActivity.map((el, i) => ({
          y: el,
          x: i
        }))
      }]
      return series
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['user_' + this.id + '_activity_day']
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchUserActivityById'
    ]),
    update (query) {
      this.fetchUserActivityById([
        this.id,
        'day',
        query
      ])
    }
  },
  mounted () {
    this.update()
  }
}
</script>
