<template>
  <ul
    class="uk-nav-default"
    uk-nav
  >
    <li class="uk-parent uk-margin-small-bottom">
      <a
        href="#"
        :style="`${langFix}`"
      >
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          uk-icon="icon: comments"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.messengers_title') + '; pos: top-left' : null)"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.messengers_title') }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li>
          <router-link
            :class="{
              'active-menu-item': routerModule === 'tgcp'
            }"
            to="/tgcp"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Telegram; pos: top-left' : null)"
            >
              <font-awesome-icon
                :icon="['fab', 'telegram-plane']"
              />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Telegram
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/zalo"
            :class="{
              'active-menu-item': routerModule === 'zalo'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Zalo; pos: top-left' : null)"
            >
              <zaloIcon :style="isMainSideMenuCollapsed ? '' : 'width:0.875em;'" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Zalo
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/discord"
            :class="{
              'active-menu-item': routerModule === 'discord'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Discord; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'discord']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Discord
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/imo"
            :class="{
              'active-menu-item': routerModule === 'imo'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Imo; pos: top-left' : null)"
            >
              <img
                src="/img/icons/imo_icon.svg"
                style="width: 0.875em;"
                uk-svg
              />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Imo
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/threema"
            :class="{
              'active-menu-item': routerModule === 'threema'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Threema; pos: top-left' : null)"
            >
              <threemaIcon :style="isMainSideMenuCollapsed ? '' : 'width:0.875em;'" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Threema
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            :class="{
              'active-menu-item': routerModule === 'whatsapp'
            }"
            :to="{
              name: 'whatsapp_stats'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: WhatsApp; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'whatsapp']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              WhatsApp
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            :class="{
              'active-menu-item': routerModule === 'hoop'
            }"
            to="/hoop"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Hoop; pos: top-left' : null)"
            >
              <font-awesome-icon
                :icon="['fa', 'comments']"
              />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Hoop
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/messengers/line"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Line; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'line']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Line
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/messengers/viber"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Viber; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'viber']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Viber
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/messengers/weixin"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: WeChat; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'weixin']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              WeChat
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/messengers/qq"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: QQ; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'qq']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              QQ
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/messengers/kakao"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: KakaoTalk; pos: top-left' : null)"
            >
              <img
                src="/img/icons/kakaotalk_icon.svg"
                style="width: 0.875em;"
                uk-svg
              />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              KakaoTalk
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/messengers/slack"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon ' : 'uk-margin-small-right ')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Slack; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'slack']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Slack
            </span>
          </router-link>
        </li>
      </ul>
    </li>
    <li class="uk-parent uk-margin-small-bottom">
      <a href="#">
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.socials_title') + '; pos: top-left' : null)"
          uk-icon="icon: social"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.socials_title') }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li>
          <router-link
            :class="{
              'active-menu-item': routerModule === 'twitter'
            }"
            to="/twitter"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Twitter; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Twitter
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/facebook"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Facebook; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'facebook-f']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Facebook
            </span>
          </router-link>
        </li>
        <li v-if="!(disabledModules.includes('tiktok'))">
          <router-link
            to="/tiktok"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Tiktok; pos: top-left' : null)"
            >
              <tiktokIcon :style="isMainSideMenuCollapsed ? '' : 'width:0.875em;'" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Tiktok
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            class="not_available"
            to="/social/instagram"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Instagram; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Instagram
            </span>
          </router-link>
        </li>

        <li>
          <router-link
            to="/social/vk"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: VK; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'vk']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              VK
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/social/reddit"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Reddit; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'reddit']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Reddit
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/social/flickr"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Flickr; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'flickr']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Flickr
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/social/foursquare"
            class="not_available"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: Foursquare; pos: top-left' : null)"
            >
              <font-awesome-icon :icon="['fab', 'foursquare']" />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              Foursquare
            </span>
          </router-link>
        </li>
      </ul>
    </li>
    <li
      v-if="allowLeak"
      class="uk-margin-small-bottom"
    >
      <router-link
        :to="{
          name: 'leaks_table'
        }"
      >
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.leaked_title') + '; pos: top-left' : null)"
          uk-icon="icon: paint-bucket"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.leaked_title') }}
        </span>
      </router-link>
    </li>
    <li class="uk-parent uk-margin-small-bottom">
      <a href="#">
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.forums_title') + '; pos: top-left' : null)"
          uk-icon="icon: comments"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.forums_title') }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li>
          <router-link
            :class="{
              'active-menu-item': routerModule === 'forum'
            }"
            :to="{
              name: 'forums_stats'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.all') + '; pos: top-left' : null)"
              uk-icon="icon: users"
            />
            <span v-show="!isMainSideMenuCollapsed">
              {{ $t('navbar.all') }}
            </span>
          </router-link>
        </li>
      </ul>
    </li>
    <li class="uk-parent uk-margin-small-bottom">
      <a href="#">
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.crypto_title') + '; pos: top-left' : null)"
        >
          <font-awesome-icon :icon="['far', 'money-bill-alt']" />
        </span>
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.crypto_title') }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li>
          <router-link
            :class="{
              'active-menu-item': routerModule === 'crypto'
            }"
            :to="{
              name: 'crypto_ton_main_page'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.ton') + '; pos: top-left' : null)"
            >
              <font-awesome-icon
                :icon="['fab', 'telegram-plane']"
              />
            </span>
            <span v-show="!isMainSideMenuCollapsed">
              {{ $t('navbar.ton') }}
            </span>
          </router-link>
        </li>
      </ul>
    </li>
    <li class="uk-parent uk-margin-small-bottom">
      <a href="#">
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.ads_title') + '; pos: top-left' : null)"
          uk-icon="icon: cart"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.ads_title') }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li
          v-for="countryCode in ['ar','bd', 'br', 'co', 'cu', 'fr', 'id', 'in', 'it', 'kz', 'lt', 'my', 'pa', 'pe', 'pk', 'ru', 'sg', 'za', 'ae']"
          :key="countryCode"
        >
          <router-link
            :to="`/ads/${countryCode}`"
            :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('countries.' + countryCode) + '; pos: top-left' : null)"
          >
            {{ isMainSideMenuCollapsed ? countryCode : $t('countries.' + countryCode) }}
          </router-link>
        </li>
      </ul>
    </li>
    <li class="uk-parent uk-margin-bottom">
      <a href="#">
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.news_title') + '; pos: top-left' : null)"
          uk-icon="icon: rss"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.news_title') }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li>
          <router-link
            to="/web/sources"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.sources') + '; pos: top-left': null)"
              uk-icon="icon: rss"
            />
            <span v-show="!isMainSideMenuCollapsed">
              {{ $t('navbar.sources') }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'web_monitor'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.monitor') + '; pos: top-left': null)"
              uk-icon="icon: album"
            />
            <span v-show="!isMainSideMenuCollapsed">
              {{ $t('navbar.monitor') }}
            </span>
          </router-link>
        </li>
      </ul>
    </li>
    <li>
      <hr
        style="min-width: 20px;"
      />
    </li>
    <li class="uk-margin-small-bottom">
      <router-link :to="{ name: 'cases_list' }">
        <span
          uk-icon="icon: folder"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.cases_title') + '; pos: top-left': null)"
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.cases_title') }}
        </span>
      </router-link>
    </li>
    <li
      class="uk-margin-small-bottom"
    >
      <router-link
        to="/osint/"
      >
        <span
          uk-icon="icon: world"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.osint-tools') + '; pos: top-left': null)"
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
        />

        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.osint-tools') }}
        </span>
      </router-link>
    </li>
    <li
      class="uk-margin-small-bottom"
    >
      <router-link to="/map/">
        <span
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.map_title') + '; pos: top-left': null)"
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          uk-icon="icon: location"
        />

        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.map_title') }}
        </span>
      </router-link>
    </li>
    <li
      class="uk-margin-bottom"
    >
      <router-link to="/help/">
        <span
          uk-icon="icon: question"
          :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.help_title') + '; pos: top-left': null)"
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ $t('navbar.help_title') }}
        </span>
      </router-link>
    </li>
    <li>
      <hr
        style="min-width: 20px;"
      />
    </li>
    <li class="uk-parent uk-margin-small-bottom">
      <a
        :uk-tooltip="`title: ${userdata.name}; pos: top-left`"
      >
        <span
          :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-icon' : 'uk-margin-small-right')"
          uk-icon="icon: user"
        />
        <span v-show="!isMainSideMenuCollapsed">
          {{ userdata.name.split('@')[0] }}
        </span>
      </a>
      <ul :class="'uk-nav-sub ' + (isMainSideMenuCollapsed ? 'collapsed-menu' : '')">
        <li>
          <router-link
            to="/settings/general"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.settings_title') + '; pos: top-left': null)"
              uk-icon="icon: cog"
            />
            <span v-show="!isMainSideMenuCollapsed">
              {{ $t('navbar.settings_title') }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'logout'
            }"
          >
            <span
              :class="'uk-icon ' + (isMainSideMenuCollapsed ? 'collapsed-sub-icon' : 'uk-margin-small-right')"
              :uk-tooltip="(isMainSideMenuCollapsed ? 'title: ' + $t('navbar.logout_title') + '; pos: top-left': null)"
              uk-icon="icon: sign-out"
            />
            <span v-show="!isMainSideMenuCollapsed">
              {{ $t('navbar.logout_title') }}
            </span>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import threemaIcon from '@/components/icons/threema-icon'
import zaloIcon from '@/components/icons/zalo-icon'
import tiktokIcon from '@/components/icons/tiktok-icon'
import restrictions from '../config/restrictions'
import config from '@/config'
import { mapGetters } from 'vuex'

export default {
  components: {
    threemaIcon,
    zaloIcon,
    tiktokIcon
  },
  props: {
    availableAds: {
      required: false
    }
  },
  computed: {
    ...mapGetters([
      'isMainSideMenuCollapsed'
    ]),
    langFix () {
      if (this.$i18n.locale === 'ru') return 'font-size: 13px;'
      return ''
    },
    disabledModules () {
      return config.disableModules
    },
    allowLeak () {
      const userId = this.$store.state.current_user_id
      if (restrictions[userId]) {
        if (restrictions[userId].module.indexOf('leak') > -1) {
          return false
        }
      }
      return true
    },
    routerModule () {
      try {
        return this.$route.name.split('_')[0]
      } catch (e) {}
      return false
    },
    userdata () {
      return this.$store.getters.getProfile
    }
  }
}
</script>

<style scoped>
    .collapsed-icon {
      width: 20px;
      height: 20px;
    }
    .collapsed-sub-icon {
      width: 15px;
      height: 15px;
    }
    .collapsed-menu {
      padding: 0!important;
    }
    .collapsed-menu li{
      margin-left: 5px;
    }
    .not_available {
        opacity: 0.5;
        pointer-events: none;
    }
    .svg_object svg {
        fill: red;
    }
    .active-menu-item {
        color: #666 !important;
    }
    .uk-nav-default > li > a > span:nth-child(2) {
      margin-left: 4px;
    }
</style>
