<template>
  <div>
    <div v-if="!isLoaded">
      <Loader />
    </div>
    <div v-else>
      <ul
        v-if="channelStat"
        class="uk-list uk-list-divider uk-text-left uk-width-expand"
      >
        <li>
          <router-link
            class="stat-link"
            :to="{ 
              name: 'tgcp_channel_detail',
              hashCode:'messages_tab',
              params: { id: String(id) },
              query: { details: 'messages_tab', info: 'current_tab' }
            }"
          >
            {{ $t('tgcp.channel_details.messages_title') }}
            <span class="uk-float-right">
              {{ channelStat.messages_count }}
            </span>
          </router-link>
        </li>
        <li v-if="channelStat.all_users_count || channelStat.all_admins_count">
          <router-link
            class="stat-link"
            :to="{
              name: 'tgcp_channel_detail',
              params: {
                id: String(id)
              },
              query: { details: 'users_tab', info: 'current_tab' }
            }"
          >
            {{ $t('tgcp.channel_details.users_title') }} <span class="uk-float-right">
              {{ channelStat.users_count }}
            </span>
          </router-link>
        </li>
        <li v-if="channelStat.all_users_count || channelStat.all_admins_count">
          <router-link
            class="stat-link"
            :to="{ 
              name: 'tgcp_channel_detail',
              params: { id: String(id) },
              query: { details: 'admins_tab', info: 'current_tab'}
            }"
          >
            {{ $t('tgcp.channel_details.admins_title') }} <span class="uk-float-right">
              {{ channelStat.admins_count }}
            </span>
          </router-link>
        </li>
      </ul>
      <span v-else>
        {{ $t('tgcp.channel_details.no-stat') }}
      </span>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions } from 'vuex'

export default {
  components: {
    Loader
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      channelStat: null,
      isLoaded: false
    }
  },
  watch: {
    id () {
      this.update()
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchChannelStatsById'
    ]),
    async update () {
      try {
        this.channelStat = await this.fetchChannelStatsById(this.id)
      } catch {
        this.channelStat = null
      } finally {
        this.isLoaded = true
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>

<style scoped lang="scss">
.stat-link {
  cursor:pointer; 
  color: #666;
  &:hover {
    text-decoration: none;
  }
}
</style>
