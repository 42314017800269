<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('tgcp.user_details.year_activity_title') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <div
          v-if="!isLoaded"
        >
          <Loader/>
        </div>
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/TGCP/Loader'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'HoopChannelYearGraph',
  components: {
    Loader,
    VueApexCharts
  },
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      changed: false,
      dateStart: '',
      dateEnd: '',
      flatPickrConfig: {
        maxDate: new Date(),
        mode: 'range',
        plugins: [
          new MonthSelectPlugin({
            shorthand: true, // defaults to false
            dateFormat: 'Y-m', // defaults to "F Y"
            mode: 'range'
          })
        ]
      },
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          formatter: val => +val > 2 ? val : ''
        },
        xaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_months')
          }
        },
        yaxis: {
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          }
        }
      }
    }
  },
  watch: {
    id (from, to) {
      this.update()
    }
  },
  computed: {
    chatsActivity () {
      if (!this.id) return
      const dayActivity = this.$store.state.hoop.channels[this.id]['activity']['year']['activity']
      const series = [{
        name: this.$i18n.t('tgcp.user_details.graph_messages'),
        data: []
      }]
      for (const i in dayActivity) {
        series[0].data.push({
          x: this.$i18n.t('tgcp.user_details.graph_months_' + i),
          y: dayActivity[i]
        })
      }
      return series
    },
    isLoaded () {
      return this.$store.state.hoop.isLoaded['channel_' + this.id + '_activity_year']
    }
  },
  methods: {
    update (query) {
      const action = 'hoop/fetchChannelActivityById'
      this.$store.dispatch(action, [this.id, 'year', query])
      this.changed = false
    },
    changeDate (e) {
      if (!this.dateStart || !this.dateEnd) return
      this.changed &&
        this.update({
          from_date: dayjs(this.dateStart)
            .startOf('month')
            .format('YYYY-MM-DD'), // dayjs global filter exeption
          to_date: dayjs(this.dateEnd)
            .endOf('month')
            .format('YYYY-MM-DD')
        })
    }
  },
  mounted () {
    this.update()
  }
}
</script>
