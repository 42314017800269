<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div class="uk-flex uk-flex-center">
        <form
          class="uk-form-horizontal"
          @submit.prevent="onSubmit"
        >
          <div class="uk-flex uk-flex-around uk-flex-wrap uk-flex-bottom uk-margin">
            <input
              v-if="!search"
              v-model.trim="state.query.search"
              type="text"
              :placeholder="$t('tgcp.messages.search')"
              class="uk-input uk-width-auto"
            />
            <vueSelect
              v-model="currentOrder"
              :options="orderComputed"
              label="label"
              class="uk-form-width-small uk-form-width-medium"
            />
            <vueSelect
              v-if="!search && chats.length && !containMedia"
              id="country"
              v-model="currentChat"
              :options="chats"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <label
              class="uk-flex uk-flex-column uk-flex-top"
            >
              <div class="uk-inline">
                <button
                  class="uk-form-icon uk-form-icon-flip"
                  uk-icon="icon: close"
                  style="outline: 0"
                  @click="fromDate = ''"
                />
                <flatPickr
                  v-model="fromDate"
                  class="uk-input uk-form-width-small uk-form-width-medium"
                  :placeholder="$t('tgcp.messages.from_date')"
                  :config="{
                    maxDate: toDate || new Date(),
                    wrap: true
                  }"
                />
              </div>
            </label>
            <label class="uk-flex uk-flex-column uk-flex-top">
              <div class="uk-inline">
                <button
                  class="uk-form-icon uk-form-icon-flip"
                  uk-icon="icon: close"
                  style="outline: 0"
                  @click="toDate = ''"
                />
                <flatPickr
                  v-model="toDate"
                  :placeholder="$t('tgcp.messages.to_date')"
                  class="uk-input uk-form-width-small uk-form-width-medium"
                  :config="{
                    minDate: fromDate || '2010-05-11',
                    maxDate: new Date(),
                    wrap: true
                  }"
                />
              </div>
            </label>
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="onSubmit"
            >
              <span uk-search-icon/>
            </button>
          </div>
        </form>
      </div>
      <div v-if="!isLoaded">
        <Loader />
      </div>
      <div v-else>
        <div
          v-for="message in messages"
          :key="message.message_id"
          class="uk-card uk-card-default uk-card-small uk-margin-top"
        >
          <MessageListMiniItem
            :key="message.id"
            v-scroll-into-view="$route.query['message_id']"
            :data-scroll-into-view="message.message_id"
            tabindex="0"
            :searchWord="!search ? state.query.search : ''"
            :message="message"
            :user="user[message.user_id]"
            :chat="chat(message.chat_id)"
            :checked="!!selectedMessages.find(el => el.message_id === message.message_id)"
            :showCheckbox="!!selectedMessages.length"
            :forceCheckbox="true"
            @showNested="messageSelected = message"
            @message-select="selectedMessagesComputed = $event"
          />
        </div>

        <ModalCenter
          v-if="messageSelected"
          :message="messageSelected"
          @hidden="messageSelected = null"
        >
          <MessageListMiniItemModal
            :message="messageSelected"
          />
        </ModalCenter>

        <div
          v-if="!hidePagination"
          class="uk-margin"
        >
          <ul class="uk-pagination uk-flex-center">
            <li
              class="uk-inline uk-margin-small-right"
              :class="isPrevPage"
            >
              <a
                class="uk-position-center"
                @click="prevPage"
              >
                <span uk-pagination-previous/>
              </a>
            </li>
            <li
              class="uk-padding-remove"
            >
              <input
                v-model="goToPage"
                class="uk-input uk-text-center uk-form-blank uk-text-truncate"
                style="max-width: 250px; width: 40px"
                :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
                @change="setPage"
                @focus="$event.target.select()"
              />
            </li>
            <li
              class="uk-inline uk-margin-small-left"
              :class="isNextPage ? 'uk-active' : 'uk-disabled'"
            >
              <a
                class="uk-position-center"
                @click="nextPage"
              >
                <span
                  uk-pagination-next
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <NotFound v-if="isLoaded && !messages.length" />
    </div>
    <ExportDialog
      v-if="isLoaded && messages.length"
      :id="chatId || userId"
      :search="state.query.search"
      :subtype="subtype"
      namespace="tgcp"
      type="messages"
      :query="state.query"
      :messages="selectedMessages"
      :totalCount="0"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'MessageListMini',
  components: {
    Loader,
    vueSelect,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    flatPickr,
    MessageListMiniItem: defineAsyncComponent(() => import('@/components/TGCP/MessageList/MessageListMiniItem')),
    MessageListMiniItemModal: defineAsyncComponent(() => import('@/components/TGCP/MessageList/MessageListMiniItemModal')),
    ModalCenter: defineAsyncComponent(() => import('@/components/ModalCenter'))
  },
  props: {
    chatId: {
      type: String
    },
    userId: {
      type: [String, Number]
    },
    containMedia: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    offset: {
      type: Number,
      default: 0
    },
    search: {
      type: String
    }
  },
  data () {
    return {
      messages: [],
      value: null,
      from_date: '',
      to_date: '',
      goToPage: this.page,
      chats: [],
      users: {},
      userChats: {},
      state: {
        query: {
          search: this.$route.query.message_search || this.search || '',
          limit: 21,
          offset: this.offset,
          user_id: this.userId,
          chat_id: this.chatId,
          order_type: 'asc',
          order_by: 'date',
          contain_media: this.containMedia,
          from_date: null,
          to_date: null
        },
        pagination: {
          page: this.page,
          isNext: this.isNextPage
        }
      },
      isLoaded: false,
      messageSelected: null,
      selectedMessages: []
    }
  },
  watch: {
    '$route.query.message_id': {
      immediate: false,
      handler () {
        try {
          this.init()
        } catch (error) {}
      }
    },
    offset (to) {
      this.state.query.search = ''
      this.state.query.offset = to
      this.containMedia ? this.clearMediaMessagesToView() : this.clearMessagesToView()
      this.fetchMessages(this.userId)
    },
    page (to) {
      this.state.pagination.page = to
    },
    '$route.params' () {
      this.init()
    },
    goToPage (newVal, oldVal) {
      if (!/^[0-9]*$/.test(newVal) || !+newVal) {
        this.goToPage = oldVal
      }
    },
    'state.pagination.page' (newVal) {
      this.goToPage = newVal
    }
  },
  computed: {
    ...mapGetters('tgcp', [
      'isPrevPage',
      'allListMessages',
      'allUsers',
      'allChannels',
      'allMessages',
      'allListMediaMessages'
    ]),
    toDate : {
      get () {
        return this.to_date ?? null
      },
      set (value) {
        if (this.to_date === value) return
        this.to_date = value
        this.state.query['to_date'] = value
      }
    },
    fromDate : {
      get () {
        return this.from_date ?? null
      },
      set (value) {
        if (this.from_date === value) return
        this.from_date = value
        this.state.query['from_date'] = value
      }
    },
    selectedMessagesComputed: {
      get () {
        return this.selectedMessages
      },
      set (message) {
        const id = message.chat_id + message.message_id
        if (this.selectedMessages.find(el => el.chat_id + el.message_id === id)) {
          this.selectedMessages = this.selectedMessages.filter(el => el.chat_id + el.message_id !== id)
        } else {
          this.selectedMessages = [ message, ...this.selectedMessages ]
        }
      }
    },
    currentChat: {
      get () {
        return this.state.query.chat_id
      },
      set (obj) {
        this.state.query.chat_id = obj && obj.value
        this.setOrder()
      }
    },
    currentOrder: {
      get () {
        return this.order
      },
      set (obj) {
        this.order = obj && obj.value
        this.setOrder()
      }
    },
    orderComputed () {
      return [
        ['relevancy', 'sort_by_relevance'],
        ['asc_date', 'sort_by_date_asc'],
        ['desc_date', 'sort_by_date_desc'],
        ['asc_user', 'sort_by_user_asc'],
        ['desc_user', 'sort_by_user_desc'],
        ['asc_chat', 'sort_by_chat_asc'],
        ['desc_chat', 'sort_by_chat_desc']
      ].map(([value, label]) => ({ value, label: this.$t('tgcp.messages.' + label) }))
    },
    messagesOnPage () {
      if (!this.list || !this.list[this.currentPage]) return
      if (this.state.pagination.nextPage) {
        return this.list[this.currentPage].slice(0, -1)
      }
      return this.list[this.currentPage]
    },
    currentPage () {
      return this.state.pagination.page
    },
    type () {
      return 'message'
    },
    subtype () {
      if (this.userId) {
        return 'user'
      }
      if (this.chatId) {
        return 'chat'
      }
      return 'all'
    },
    hasDataToView () {
      return this.messages.length > 0
    },
    userName (message) {
      const userId = message.user_id
      if (userId) {
        return this.$store.state.tgcp.users[message.user_id]
      } else {
        return this.$store.state.tgcp.channels[message.chat_id]
      }
    },
    isNextPage () {
      return this.messages.length === this.state.query.limit - 1
    },
    isPrevPage () {
      return this.state.pagination.page === 1 ? 'uk-disabled' : 'uk-active'
    },
    chat: {
      set (chats) {
        chats.forEach(chat => {
          this.userChats[chat.tg_id] = chat
        })
      },
      get () {
        return id => this.userChats[id]
      }
    },
    user: {
      set (users) {
        users.forEach(user => {
          this.users[user.tg_id] = user
        })
      },
      get () {
        return this.users
      }
    },
    list () {
      return this.containMedia ? this.allListMediaMessages : this.allListMessages
    },
    userOrChat () {
      const type = this.userId ? 'user_details' : 'chat_details'
      return type + (this.userId || this.chatId)
    },
    hidePagination () {
      if (this.isNextPage === false && this.state.pagination.page === 1) return true
      else return false
    },
    order: {
      get () {
        const { order_type: type, order_by: by } = this.state.query
        if (!type) return by
        return type + '_' + by
      },
      set (value) {
        const splitted = value.split('_')
        if (splitted.length === 1) {
          ;[this.state.query.order_by, this.state.query.order_type] = splitted
          return
        }
        ;[this.state.query.order_type, this.state.query.order_by] = splitted
      }
    }
  },
  methods: {
    ...mapActions('tgcp', [
      'fetchAllMessages',
      'fetchMessagesForLink',
      'clearMessagesToView',
      'clearMessages',
      'fetchAllmediamessages',
      'clearMediaMessagesToView',
      'fetchUserMessagesChats',
      'getBatchUsers',
      'getBatchChats'
    ]),
    async init () {
      [
        ['offset', this.offset],
        ['user_id', this.userId],
        ['chat_id', this.chatId]
      ].forEach(([prop, value]) => (this.state.query[prop] = value))
      if (this.$route.query['message_id'] && !this.offset) {
        this.state.query.messageId = this.$route.query['message_id']
      } else {
        this.state.query.messageId = null
      }
      this.state.pagination.page = this.page
      this.state.pagination.isNext = this.isNextPage
      this.fromDate = null
      this.toDate = null
      this.containMedia ? this.clearMediaMessagesToView() : this.clearMessagesToView()
      await this.fetchMessages(this.state.query)
      this.state.query.messageId = null
      if (this.userId) {
        const chats = (await this.fetchUserMessagesChats(this.userId))['data']
          .map(({ chat, messages_count: count }) => ({
            value: chat.tg_id,
            label: chat.name || chat.username,
            second: this.$t('tgcp.messages.messages') + ': ' + count
          }))
        this.chats = [{ value: undefined, label: this.$t('tgcp.messages.select-chats') }, ...chats]
      }
    },
    async setPage ({ target }) {
      for (let i = 5; i > 0; i--) {
        this.state.query.offset = (this.state.query.limit - 1) * (--target.value)
        await this.fetchMessages()
        if (this.messages.length) {
          this.goToPage = +target.value + 1
          this.state.pagination.page = +target.value + 1
          return
        }
      }
      this.state.query.offset = this.state.query.limit * (this.state.pagination.page - 1)
      await this.fetchMessages()
      this.goToPage = this.state.pagination.page
      this.$notify({ group: 'general', title: `Page not found`, type: 'error' })
    },
    nextPage () {
      const page = this.state.pagination.page
      const offset = (this.state.query.limit - 1) * page
      this.state.query['offset'] = offset
      this.fetchMessages(this.state.query)
      this.state.pagination.page = +page + 1
    },
    prevPage () {
      const limit = this.state.query.limit - 1
      const page = this.state.pagination.page
      const offset = this.state.query.offset
      this.state.query['offset'] = offset - limit
      this.state.pagination.page = +page - 1
      this.fetchMessages(this.state.query)
    },
    onSubmit () {
      this.state.pagination.page = 1
      this.state.query.offset = 0
      this.containMedia ? this.clearMediaMessagesToView() : this.clearMessagesToView()
      this.fetchMessages(this.state.query)
    },
    setOrder () {
      this.state.query.offset = 0
      this.state.pagination.page = 1
      this.containMedia ? this.clearMediaMessagesToView() : this.clearMessagesToView()
      this.fetchMessages(this.state.query)
    },
    isUser (message) {
      const userId = this.messages[message].user_id
      if (userId) return true
      else return false
    },
    async fetchMessages () {
      this.isLoaded = false
      let messages = []
      try {
        messages = await this[this.containMedia ? 'fetchAllmediamessages' : 'fetchAllMessages'](this.state.query)
        this.userChats = {}
        this.users = {}
        this.user = await this.getBatchUsers({ IDs: [ ...new Set(messages.map(el => el.user_id).filter(el => el)) ] })
        this.chat = await this.getBatchChats({ IDs: [ ...new Set(messages.map(el => el.chat_id).filter(el => el)) ] })
        this.messages = messages.length === (this.state.query.limit || 1) ? messages.slice(0, -1) : (messages || [])
        if (this.$route.query.message_search) this.$router.replace({ query: { info : this.$route.query.info, details: this.$route.query.details}})
      } catch (err) {
        this.messages = []
        this.$notify({ group: 'general', title: this.$t('notifications.error'), text: err.message, type: 'error' })
      } finally {
        this.isLoaded = true
      }
    }
  },
  created () {
    this.init()
  },
  beforeUnmount () {
    this.containMedia ? this.clearMediaMessagesToView() : this.clearMessagesToView()
  }
}
</script>
