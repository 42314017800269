import PDF from '@/utils/pdf'
import config from '@/config'
import router from '@/router'
import { apiRequest, getQueryString, apiFileRequest } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'

/* eslint-disable camelcase */
const { servers: { asiris_api, monitoring, tools, fetcher_proxy } } = config

export default {
  namespaced: true,
  state: {
    attachProgress: {},
    cases: {},
    notes: {},
    storedObjects: {},
    generalUsersCache: {},
    isLoaded: {},
    profiles: {},
    lastEventTime: Date.now(),
    suggestions: {},
    caseStats: {},
    monitor: {},
    reports: {},
    phones: {},
    currentCase: false,
    archived: {
      cases: {}
    },
    users: [],
    tags: [],
    triggerHiddenColumns: [],
    triggerColumns: ['name', 'username', 'channelUsername', 'sourceName', 'tableText', 'date', 'actions'],
    isTableView: false,
    feed: {},
    web: {},
    profileCollapsedSections: {}
  },
  getters: {
    attachProgress: state => state.attachProgress,
    allCases: state => state.cases,
    allNotes: state => state.notes,
    allStoredObjects: state => state.storedObjects,
    currentCase: state => state.currentCase || false,
    archivedCases: state => state.archived.cases,
    profiles: state => state.profiles,
    profilesTotal: state => state.profiles.count,
    caseStats: state => state.caseStats,
    users: state => state.users,
    isLoaded: state => state.isLoaded,
    monitor: state => state.monitor,
    caseTags: state => caseId => state.cases[caseId].tags || [],
    savedMessages: state => caseId => state.feed[caseId] || [],
    savedArticles: state => caseId => state.web[caseId] || [],
    savedMessageIn: state => (message, caseId) => state.feed[caseId]
      ? state.feed[caseId].find(el => el.id === message.id)
      : false,
    savedArticleIn: state => (article, caseId) => state.web[caseId]
      ? state.web[caseId].find(el => el.id === article.id)
      : false,
    messageTags: state => (caseId, messageId) => state.feed[caseId] &&
    state.feed[caseId].find(el => el.id === messageId) &&
    state.feed[caseId].find(el => el.id === messageId).tags,
    articleTags: state => (caseId, articleId) => state.web[caseId] &&
    state.web[caseId].find(el => el.id === articleId) &&
    state.web[caseId].find(el => el.id === articleId).tags,
    allMessagesTags: state => caseId => state.feed[caseId]
    ? [ ...new Set(state.feed[caseId].filter(el => el).map(el => el.tags).flat()) ].filter(el => el)
    : [],
    allArticlesTags: state => caseId => state.web[caseId]
    ? [ ...new Set(state.web[caseId].filter(el => el).map(el => el.tags).flat()) ].filter(el => el)
    : [],
    accessLevel: state => id => ['rw', 'f'].includes(state.cases[id] && state.cases[id].access_level),
    reports: state => caseId => state.reports[caseId] || [],
    phoneDescription: state => phone => state.phones[phone] && state.phones[phone].description,
    caseExcludedSources: state => caseId => {
      try {
        return state.cases[caseId].case_settings.profiler.excluded_sources
      } catch {
        return []
      }
    },
    webReaderSettings: state => caseId => {
      try {
        return state.cases[caseId].case_settings.web_reader
      } catch {

      }
    },
    caseNotes: state => caseId => state.notes[caseId] || [],
    collapsedProfileSection: state => (profileId, section) => {
      try {
        return state.profileCollapsedSections[profileId].includes(section)
      } catch (err){
        return false
      }
    }
  },
  mutations: {
    setAttachProgress: (state, { key, progressPercent }) => {
      if (key) {
        state.attachProgress[key] = progressPercent
      }
    },
    setCollapsedProfileSection: (state, { profileId, section }) => {
      if (!profileId || !section ) return
      const values = state.profileCollapsedSections[profileId] || []
      state.profileCollapsedSections[profileId] = values.includes(section ? values.filter(el => el !== section) : [ ...new Set([...values, section]) ])
    },
    unsetAttachProgress: (state, key) => {
      if (key) {
        delete state.attachProgress[key]
      }
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    clearProfile: (state, profileId) => delete state.profiles[profileId],
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    getCaseTags: (state, { caseId, tags }) => {
      if (Array.isArray(tags)) state.cases[caseId].tags = tags
      else state.cases[caseId].tags = [ ...new Set([ ...state.cases[caseId].tags, tags ]) ]
    },
    setSavedMessages: (state, { payload, caseId, type = 'feed' }) => {
      if (Array.isArray(payload)) {
        if (type === 'feed') state.feed[caseId] = [ ...payload ]
        else state.web[caseId] = [ ...payload ]
      }
      else {
        switch (payload.type) {
          case 'message':
            if (state.feed[caseId]) {
              state.feed[caseId] = state.feed[caseId].find(el => el.id === payload.id
                ? state.feed[caseId].filter(el => el.id !== payload.id)
                : [ ...state.feed[caseId], payload ])
            } else {
              state.feed[caseId] = [ payload ]
            }
            break
          case 'article':
            if (state.web[caseId]) {
              state.web[caseId] = state.web[caseId].find(el => el.id === payload.id
                ? state.web[caseId].filter(el => el.id !== payload.id)
                : [ ...state.web[caseId], payload ])
            } else {
              state.web[caseId] = [ payload ]
            }
            break
        }
      }
    },
    removeTagFromMessage: (state, { caseId, tag, id }) => {
      const index = state.feed[caseId].findIndex(el => el.id === id)
      state.feed[caseId][index].tags = state.feed[caseId][index].tags.filter(el => el !== tag)
    },
    removeTagFromArticle: (state, { caseId, tag, id }) => {
      const index = state.web[caseId].findIndex(el => el.id === id)
      state.web[caseId][index].tags = state.web[caseId][index].tags.filter(el => el !== tag)
    },
    addTagToMessage: (state, { caseId, tag, index }) => {
      const message = state.feed[caseId][index]
      if (message.tags) {
        message.tags = [ ...new Set([ ...message.tags, tag ]) ]
      } else {
        message.tags = [ tag ]
      }
      state.feed[caseId][index] = message
    },
    addTagToArticle: (state, { caseId, tag, index }) => {
      const article = state.web[caseId][index]
      if (article.tags) {
        article.tags = [ ...new Set([ ...article.tags, tag ]) ]
      } else {
        article.tags = [ tag ]
      }
      state.web[caseId][index] = article
    },
    getCasesTags: (state, tags) => {
      state.tags = tags
    },
    addProfileTag: (state, tag) => {
      state.profiles[tag.profile_id].tags[state.profiles[tag.profile_id].tags.length] = tag
    },
    deleteProfileTag: (state, tag) => {
      if (!tag) return
      const tags = state.profiles[tag.profile_id].tags
      state.profiles[tag.profiles_id].tags = tags.filter(el => el.tag_id !== tag.tag_id)
    },
    fetchCases: (state, cases) => {
      if (cases.length === 0) return
      cases.map(caseInfo => {
        state.cases[caseInfo.case_id] = caseInfo
      })
    },
    getCaseById: (state, caseInfo) => {
      state.cases[caseInfo.case_id] = caseInfo
    },
    statsLoaded: (state, result) => {
      state.caseStats = result
    },
    fetchNotes: (state, notes) => {
      if (notes.length === 0) return state.notes = []
      state.notes[notes[0].case_id] = notes.sort(((a, b) => b.note_id - a.note_id))
    },
    fetchStoredObjects: (state, storedObjects) => {
      if (!storedObjects.length) return
      const caseId = storedObjects[0].case_id
      if (!state.storedObjects[caseId]) {
        state.storedObjects[caseId] = {}
      }
      storedObjects.map(stored => {
        state.storedObjects[stored.case_id][stored.ext_id] = stored
      })
    },
    deleteCaseTag: (state, { caseId, tag }) => {
      state.cases[caseId].tags = state.cases[caseId].tags.filter(el => el.tag_name !== tag )
    },
    deleteStoredObject: (state, { storedId, caseId }) => {
      const storeds = state.storedObjects[caseId]
      if (!storeds) return
      const id = Object.keys(storeds).find(el => storeds[el].stored_id === storedId)
      if (!id) return
      delete storeds[id]
    },
    changeNoteText: (state, params) => {
      state.notes[params.caseId][state.notes[params.caseId].findIndex(el => el.note_id === params.noteId)].note_text = params.text
    },
    changeCase: (state, { id, caseInfo }) => {
      state.cases[id].case_title = caseInfo.case_title
      state.cases[id].case_description = caseInfo.case_description
    },
    changeStoredObjectText: (state, params) => {
      state.storedObjects[params.caseId][params.storedId].comment = params.text.comment
    },
    setCurrentCase: (state, caseId) => {
      localStorage.setItem('current-case', caseId)
      state.currentCase = caseId
    },
    clearNotesAndStoredObjects: (state) => {
      delete state.notes
      delete state.storedObjects
      state.notes = {}
      state.storedObjects = {}
    },
    deleteCase: (state, id) => {
      delete state.archived.cases[id]
    },
    archiveCase: (state, caseData) => {
      caseData.is_archived = true
      delete state.cases[caseData.case_id]
      state.archived.cases[caseData.case_id] = caseData
    },
    unarchiveCase: (state, caseData) => {
      caseData.is_archived = false
      delete state.archived.cases[caseData.case_id]
      state.cases[caseData.case_id] = caseData
    },
    fetchArchivedCases: (state, cases) => {
      if (cases.length === 0) return
      cases.map(caseInfo => {
        if (!state.cases[caseInfo.case_id]) {
          state.archived.cases[caseInfo.case_id] = caseInfo
        }
      })
    },
    getProfiles: (state, { caseId, profiles }) => {
      state.profiles[caseId] = profiles
    },
    getProfileById: (state, profile) => {
      if (!profile) return
      if (!state.profiles[profile.profile_id]) state.profiles[profile.profile_id] = {}
      for (var key in profile) {
        state.profiles[profile.profile_id][key] = profile[key]
      }
    },
    getAllCaseUsers: (state, users) => {
      state.users = users
    },
    initProfileTag: (state, tag) => {
      state.profiles[tag.profile_id] = { tags: tag }
    },
    lastEventTime: (state, time) => {
      state.lastEventTime = Date.parse(time)
    },
    addSuggestion: (state, suggestion) => {
      if (!suggestion) return
      if (!state.profiles[suggestion.profile_id].suggestions) state.profiles[suggestion.suggestion_id].suggestions = {}
      if (!state.suggestions[suggestion.profile_id]) state.suggestions[suggestion.profile_id] = {}
      state.profiles[suggestion.profile_id].suggestions[suggestion.suggestion_id] = suggestion
      state.suggestions[suggestion.profile_id][suggestion.suggestion_id] = suggestion
    },
    removeSuggestion: (state, obj) => {
      delete state.suggestions[obj.profileId][obj.suggestionId]
    },
    addPhones: (state, phones) => {
      phones.forEach(phone => {
        state.phones[phone.phone_number] = phone
      })
    },
    getStates: (state, payload) => {
      if (state[payload.phone_number] && !state[payload.phone_number].user_states && !state[payload.phone_number].user_states) {
        return
      }
      try {
        var oldValue = state.monitor[payload.phone_number].user_states.slice(-1)[0].timestamp
        var newValue = payload.user_states.slice(-1)[0].timestamp
        if (String(oldValue) !== String(newValue)) {
          throw new Error('Data was updated')
        } else {
          return
        }
      } catch {
        !state.monitor[payload.phone_number]
          ? state.monitor[payload.phone_number] = Object.freeze(payload)
          : state.monitor[payload.phone_number] = Object.freeze(payload)
      }
    },
    clearMonitor: (state) => {
      state.monitor = {}
    },
    addReport: (state, report) => {
      state.reports[report.case_id] = [report, ...state.reports[report.case_id] || []]
    },
    addBulkReport: (state, { reports, caseId }) => {
      state.reports[caseId] = reports
    },
    editReport: (state, report) => {
      let index = state.reports[report.case_id].findIndex(el => el.report_id === report.report_id)
      if (index > -1) {
        state.reports[report.case_id][index] = report
      } else {
        state.reports[report.case_id] = [ report, ...state.reports[report.case_id] || [] ]
      }
    },
    deleteReport: (state, report) => {
      state.reports[report.case_id] = state.reports[report.case_id].filter(el => el.report_id !== report.report_id)
    },
    setTableView: (state, value) => (state.isTableView = value),
    setTriggerHiddenColumns: (state, arr) => state.triggerHiddenColumns = arr,
    setTriggerColumns: (state, arr) => state.triggerColumns = arr,
    updateCase: (state, payload) => {
      state.cases[payload.case_id] = payload
    },
    setGeneralUserToCache: (state, { id, value }) => {
      state.generalUsersCache[id] = value
    }
  },
  actions: {
    async attachUserCardsToProfile ({ state, dispatch, commit, rootState }, { cards, profileName, caseId,  profileId, user }) {
      let completedCards = 0
      let status = 'updated'
      const progressKey = 'progress_' + (Math.random() * 10000) + (Date.now())
      const totalItems = cards.length + (profileId >= 0 ? 0 : 1)

      const sleep = delay => new Promise(resolve => setTimeout(resolve, delay))

      const dispatchWithRetry = async ( methodName, methodData, delays = [300, 600, 1200]) => {
        // Extract the iterator from the iterable.
        const iterator = delays[Symbol.iterator]()
        while (true) {
          try {
            return await dispatch(methodName, methodData)
          } catch (error) {
            const { done, value } = iterator.next()
            if (!done) {
              await sleep(value)
            } else {
              throw error
            }
          }
        }
      }

      const safeString = (value) => {
        if (!value) return ''
        if (typeof value === 'string') return value.trim()
        if (Array.isArray(value)) {
          let joinedValues = []
          for (const item of value) {
            if (typeof item === 'string') {
              joinedValues.push(item.trim())
            }
          }
          return joinedValues.join(', ')
        }
        return ''
      }
      commit('setAttachProgress', { key: progressKey, progressPercent: 0 })
      if (profileId === -1) {
        try {
          const res = await dispatchWithRetry('profileAdd', {
            data: {
              seed_data: profileName
            },
            caseId: caseId
          })
          status = 'created'
          profileId = res.data.profile.profile_id
          completedCards++
        } finally {
          commit('unsetAttachProgress',  progressKey)
        }
      }

      if (user) {
        try {
          let phone = null
          let userData = rootState[user.provider].users[user.id]
          if (!userData) userData = await dispatch(user.provider + '/fetchUserById', user.id, { root: true })
          if (userData.phone) {
            phone = userData.phone
          } else if (userData.has_phone) {
            phone = await dispatch(user.provider + '/fetchUserPhoneById', user.id, { root: true })
          }
          if (phone) {
            phone = safeString(phone)
            await dispatchWithRetry('addPhoneToProfile', { profileId, phoneNumber: phone, ignoreProfileRefresh: true })
          }
        } catch (err) {
          console.debug(err)
        }
      }

      for (const card of cards) {
        const phones = card.phones || card.phone ? [ card.phone ] : []
        const emails = card.emails || card.email ? [ card.email ] : []
        // const ip = card.ip

        for(let phone of phones) {
          phone = safeString(phone)
          if (phone) {
            try {
              await dispatchWithRetry('addPhoneToProfile', { profileId, phoneNumber: phone, ignoreProfileRefresh: true })
            } catch (e) {
              console.error(e)
            }
          }
        }
        for(let email of emails) {
          email = safeString(email)
          if (email) {
            try {
              await dispatchWithRetry('addEmailToProfile', { profileId, email: email, ignoreProfileRefresh: true })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const fullName = safeString(card.fullName)
        if (fullName) {
          try {
            await dispatchWithRetry('addNameToProfile', { profileId, name: fullName, ignoreProfileRefresh: true })
          } catch (e) {
            console.error(e)
          }
        }
        const name = safeString(card.name)
        if (name) {
          try {
            await dispatchWithRetry('addNickNameToProfile', { profileId, name:name, ignoreProfileRefresh: true })
          } catch (e) {
            console.error(e)
          }
        }
        const ip = safeString(card.ip)
        if (ip) {
          try {
            await dispatchWithRetry('addIpToProfile', { profileId, ip: ip })
          } catch (e) {
            console.error(e)
          }
        }
        if (card.genericType === 'messenger') {
          try {
            await dispatchWithRetry('addMessengerToProfile', { profileId, card })
          } catch (e) {
            console.error(e)
          }
        }
        if (card.links && card.links.length > 0) {
          for (let link of card.links) {
            link = safeString(link)
            if (link) {
              try {
                await dispatchWithRetry('addLinkToProfile', { profileId, link: link, ignoreProfileRefresh: true })
              } catch (e) {
                console.error(e)
              }
            }
          }
        }
        const avatar = safeString(card.avatar)
        if (avatar) {
          try {
            await dispatchWithRetry('addImageToProfile', { profileId, image: avatar, ignoreProfileRefresh: true })
          } catch (e) {
            console.error(e)
          }
        }
        // Additional data
        const bio = safeString(card.bio)
        if (bio) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: 'bio',
                propertyValue: bio,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const sex = safeString(card.sex)
        if (sex) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: 'sex',
                propertyValue: sex,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const age = safeString(card.age)
        if (age) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: 'age',
                propertyValue: age,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const password = safeString(card.password)
        if (password) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: 'password',
                propertyValue: password,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const birthday = safeString(card.birthday)
        if (birthday) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: 'birthday',
                propertyValue: birthday,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const location = safeString(card.location)
        if (location) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: `location`,
                propertyValue: location,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        const title = safeString(card.title)
        if (title) {
          if (card.type !== 'ads') {
            try {
              await dispatchWithRetry('addPropertyToProfile', {
                profileId,
                propertyName: `${card.type}_title`,
                propertyValue: title,
                propertySection: 'personal',
                auxiliaryData: { sourceType: card.type, source: '' },
                ignoreProfileRefresh: true
              })
            } catch (e) {
              console.error(e)
            }
          }
        }
        completedCards++
        commit('setAttachProgress', { key: progressKey, progressPercent: (completedCards * 100) / totalItems })
      }

      commit('unsetAttachProgress', progressKey)
      return { status , profileId }
    },
    async fetchScheduledTasks ({ commit }, query) {
      commit('startLoad', `fetchScheduledTasks`)
      return new Promise((resolve, reject) => {
        apiRequest(`/api/tasks?${getQueryString(query)}`, 'GET', {}, asiris_api)
          .then(response => {
            if (response.data.success) {
              resolve(response.data || { count: 0, tasks: [] })
            } else {
              reject(new Error('Response unsuccess'))
            }
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('stopLoad', `fetchScheduledTasks`)
          })
      })
    },
    async fetchScheduledTaskById ({ commit }, taskId) {
      return apiRequest(`/api/tasks/${taskId}`, 'GET', {}, asiris_api)
    },
    async deleteTask ({ commit }, id) {
      await apiRequest(`/api/tasks/${id}`, 'DELETE', {}, asiris_api)
    },
    async editScheduledTask ({ commit }, task) {
      console.debug(task)
      return apiRequest(`/api/tasks/${task.task_id}`, 'PATCH', {}, asiris_api, task)
    },
    async addScheduledTask ({ commit }, task) {
      return apiRequest(`/api/tasks`, 'POST', {}, asiris_api, task)
    },
    async addMessengerToProfile ({ state, dispatch, commit }, params = {}) {
      return apiRequest(`/api/profiles/${params.profileId}/messengers`, 'POST', {}, asiris_api, {
        messenger_type: params.card.type,
        messenger_user_id: params.card.user_id,
        messenger_username: params.card.name
      })
    },
    async addIpToProfile ({ state, dispatch, commit }, { profileId, ip }) {
      return apiRequest(`/api/profiles/${profileId}/ips`, 'POST', {}, asiris_api, { ip })
    },
    attachSettings ({ state, dispatch, commit }, params = {}) {
      if (!params.storedObject) return
      if (!params.caseId) params.caseId = state.currentCase
      if (!params.caseId) return
      return apiRequest(`/api/cases/${params.caseId}/settings`, 'POST', {}, asiris_api, { web_reader: params.storedObject })
        .then((res) => {
          if (res.data.case) commit('updateCase', res.data.case)
        })
    },
    async putProfilerSettings ({ commit }, { caseId, settings }) {
      commit('startLoad', 'settings')
      try {
        const { data } = await apiRequest(`/api/cases/${caseId}/settings`, 'POST', {}, asiris_api, { profiler: settings })
        commit('updateCase', data.case)
      } catch (err) {
        console.dir(err)
        throw err
      }
    },
    getSuggestions ({ state, dispatch, commit }, profileId) {
      commit('startLoad', 'suggestions_' + profileId)
      return new Promise((resolve, reject) => {
        if (!profileId) reject(new Error('profile id must be provided'))
        apiRequest(`/api/profiles/${profileId}/suggestions`, 'GET', {}, asiris_api)
          .then(res => {
            let suggestions = res.data.suggestions
            suggestions.map(suggestion => {
              if (!state.profiles[suggestion.profile_id].profile_id !== suggestion.profile_id) {
                dispatch('getProfileById', suggestion.profile_id)
                  .then(commit('addSuggestion', suggestion))
              } else {
                commit('addSuggestion', suggestion)
              }
            })
            resolve(suggestions)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'suggestions_' + profileId))
      })
    },
    approveSuggestion ({ commit }, { profileId, suggestionId }) {
      commit('startLoad', 'approveSuggestion_' + suggestionId)
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/suggestions/${suggestionId}/approve`, 'PATCH', {}, asiris_api)
          .then(res => {
            return resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(() => {
            return commit('stopLoad', 'approveSuggestion_' + suggestionId)
          })
      })
    },
    rejectSuggestion ({ commit }, { profileId, suggestionId }) {
      commit('startLoad', 'approveSuggestion')
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/suggestions/${suggestionId}/reject`, 'PATCH', {}, asiris_api)
          .then(res => {
            return resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(() => {
            return commit('stopLoad', 'approveSuggestion')
          })
      })
    },
    caseAdd ({ state, dispatch, commit, rootState }, caseInfo) {
      return new Promise((resolve, reject) => {
        if (!caseInfo) return reject(new Error('Title required'))
        apiRequest('/api/cases', 'POST', {}, asiris_api, caseInfo)
          .then(data => {
            let caseInfo = data.data
            commit('setCurrentCase', caseInfo.case_id)
            dispatch('fetchCases')
              .then(() => {
                resolve(caseInfo)
              })
              .catch((err) => {
                reject(err)
              })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    csvExport ({ state, dispatch, commit }, params) {
      let groupBy = 'entity'
      if (params.groupBySource) groupBy = 'source'
      return new Promise((resolve, reject) => {
        if (!params.caseId) reject(new Error('caseId requeired'))
        apiRequest(`/export_case_csv/${params.caseId}?groupBy=${groupBy}`, 'POST', {}, asiris_api, { note_text: params.text })
          .then(data => {
            let note = data.data
            resolve(note)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    noteAdd ({ state, dispatch, commit }, params) {
      if (!params.text) return
      if (!params.caseId) params.caseId = state.currentCase
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${params.caseId}/notes`, 'POST', {}, asiris_api, { note_text: params.text })
          .then(data => {
            let note = data.data
            resolve(note)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    storedObjectsAdd ({ state, dispatch }, params = {}) {
      if (!params.storedObject) return
      if (params.storedObject.provider === 'telegram' && (params.storedObject.data?.broadcast || params.storedObject.data?.raw?.broadcast)) {
        params.storedObject.type = 'channel'
      }
      if (!params.caseId) params.caseId = state.currentCase
      if (!params.caseId) return
      return apiRequest(`/api/cases/${params.caseId}/stored`, 'POST', {}, asiris_api, params.storedObject)
        .then(({ data: { data: stored } }) => {
          if (!params.batch) dispatch('fetchStoredObjects', { query: { short: true } }).catch(console.error)
          return stored
        })
    },
    async fetchCases ({ commit, dispatch, rootState }, query) {
      query = getQueryString(query)
      const { data: { cases: { rows, count } } } = await apiRequest(`/api/cases/?${query}`, 'GET', {}, asiris_api)
      commit('fetchCases', rows)
      return { cases: rows, total: count }
    },
    async fetchArchivedCases ({ commit }, query) {
      const { data: { cases } } = await apiRequest(`/api/cases/?archived=true&${getQueryString(query)}`, 'GET', {}, asiris_api)
      commit('fetchArchivedCases', cases.rows)
      return cases
    },
    createCasePDF ({ state, dispatch }, caseId) {
      return new Promise((resolve, reject) => {
        apiFileRequest(`/api/cases/${caseId}/pdf`, 'GET', asiris_api)
          .then(res => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', res.headers.filename)
            document.body.appendChild(fileLink)
            fileLink.click()
            fileLink.remove()
            resolve()
          })
          .catch(err => reject(err))
      })
    },
    async createProfilePDF ({ state }, profileId) {
      const profile = state.profiles[profileId]

      const names = profile.names.filter((n) => !n.is_nickname).map(n => {
        let result = n.name

        if (n.auxiliary_data?.sourceType && n.auxiliary_data?.source) {
          const { sourceType, source } = n.auxiliary_data

          result += ` (Source is ${sourceType} ${source})`
        }

        return result
      })
      const nicknames = profile.names.filter((n) => n.is_nickname).map(n => n.name)
      const phones = profile.phones.map((p) => p.phone_number)
      const emails = profile.emails.map((e) => {
        let result = e.email

        if (e.auxiliary_data?.sourceType && e.auxiliary_data?.source) {
          const { sourceType, source } = e.auxiliary_data

          result += ` (Source is ${sourceType} ${source})`
        }

        return result
      })
      const links = profile.links.map((l) => {
        let result = l.link

        if (l.auxiliary_data?.sourceType && l.auxiliary_data?.source) {
          const { sourceType, source } = l.auxiliary_data

          result += ` (Source is ${sourceType} ${source})`
        }

        return result
      })
      const messengers = profile.messengers.map((m) => {
        return `Account in ${m.messenger_type}: #${m.messenger_user_id} @${m.messenger_username}`
      })
      const wallets = profile.wallets.map((w) => {
        return `${w.wallet_type}: ${w.wallet_address}`
      })

      const propertiesBySections = profile.properties.reduce(
        (acc, item) => ({
          ...acc,
          [item.property_section]: (acc[item.property_section] || []).concat(item)
        }),
        {}
      )

      const pdf = new PDF()

      await pdf.addLogo()

      pdf.addItem({
        text: `${config.siteTitle} Report`,
        margin: [0, 100, 0, 10],
        style: 'header',
        alignment: 'center'
      })

      pdf.addItem({
        text: `Profile "${profile.name}" report`,
        margin: [0, 10, 0, 20],
        style: 'subheader',
        alignment: 'center',
        pageBreak: 'after'
      })

      pdf.addTOC()

      pdf.addHeader(`Profile information - ${profile.name}`)

      if (profile.avatar) {
        await pdf.addImage(profile.avatar)
      }

      if (names.length) {
        pdf.addHeader('Known names', 2)
        pdf.addItem({ ul: names })
      }

      if (nicknames.length) {
        pdf.addHeader('Known nicknames', 2)
        pdf.addItem({ ul: nicknames })
      }

      if (phones.length) {
        pdf.addHeader('Known phone numbers', 2)
        pdf.addItem({ ul: phones })
      }

      if (emails.length) {
        pdf.addHeader('Known emails', 2)
        pdf.addItem({ ul: emails })
      }

      if (links.length) {
        pdf.addHeader('Web presence', 2)
        pdf.addItem({ ul: links })
      }

      if (messengers.length) {
        pdf.addHeader('Messengers', 2)
        pdf.addItem({ ul: messengers })
      }

      if (wallets.length) {
        pdf.addHeader('Wallets', 2)
        pdf.addItem({ ul: wallets })
      }

      if (Object.keys(propertiesBySections).length) {
        for (const sectionName in propertiesBySections) {
          pdf.addHeader(sectionName, 2)

          const ul = []
          propertiesBySections[sectionName].forEach((p) => {
            ul.push(`${p.property_name}: ${p.property_value}`)
          })
          pdf.addItem({ ul })
        }
      }

      if (profile.images.length) {
        pdf.addHeader('Photographs', 2)
        for (const image of profile.images) {
          await pdf.addImage(image.path)
        }
      }

      pdf.addHeader('Tools used')
      pdf.addItem({
        text: `${config.siteTitle} - In-House Web intelligence system. Used for data retrieval and hypothesis testing.`
      })
      pdf.addItem({
        text: 'This report was automatically generated based on in-system data',
        margin: [0, 600, 0, 0],
        italics: true
      })

      await pdf.download(`${profile.name}.pdf`)

      return Promise.resolve()
    },
    fetchEvents ({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        let lastEvent = dayjs(state.lastEventTime).toISOString()
        apiRequest(`/api/cases/events?time_start=${lastEvent}`, 'GET', {}, asiris_api)
          .then(data => {
            let events = data.data.events
            if (events.length) { commit('lastEventTime', events[events.length - 1].createdAt) }
            resolve(events)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getEvents ({ state }, { query }) {
      const { data: { events, count } } = await apiRequest(`/api/cases/events?${getQueryString(query)}`, 'GET', {}, asiris_api)
      return { events, count }
    },
    fetchNotes ({ state, dispatch, commit }, params = {}) {
      params.query = getQueryString(params.query)
      if (!params.caseId) params.caseId = state.currentCase
      commit('startLoad', 'notes_' + params.caseId)
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${params.caseId}/notes?${params.query}`, 'GET', {}, asiris_api)
          .then(data => {
            let notes = data.data.data.rows
            commit('fetchNotes', notes)
            resolve(notes)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'notes_' + params.caseId))
      })
    },
    getStats ({ state, dispatch, commit }, params = {}) {
      params.query = getQueryString(params.query)
      if (!params.caseId) params.caseId = state.currentCase
      commit('startLoad', 'stats_' + params.caseId)
      return new Promise((resolve, reject) => {
        apiRequest(`/case_analysis/?case_id=${params.caseId}`, 'GET', {}, asiris_api)
          .then(data => {
            let result = data.data
            commit('statsLoaded', result)
            resolve(result)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'stats_' + params.caseId))
      })
    },
    async fetchStoredObjects ({ state, dispatch, commit }, params = {}) {
      params.query = getQueryString(params.query)
      if (!params.caseId) params.caseId = state.currentCase
      if (!params.caseId) return
      commit('startLoad', 'stored_' + params.caseId)
      try {
        const res = await apiRequest(`/api/cases/${params.caseId}/stored?${params.query}`, 'GET', {}, asiris_api)
        const { data: { stored: { rows: stored, count: total } } } = res
        commit('fetchStoredObjects', stored)
        return [stored, total]
      } finally {
        commit('stopLoad', 'stored_' + params.caseId)
      }
    },
    async fetchAllStoredObjects ({ state, commit }, query) {
      query = getQueryString(query)
      const { data: { stored = [] } = {} } = (await apiRequest(`/api/cases/stored?${query}`, 'GET', {}, asiris_api)) || {}
      return stored
    },
    deleteNote ({ state, dispatch, commit }, params = {}) {
      if (!params.caseId) params.caseId = state.currentCase
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${params.caseId}/notes/${params.noteId}`, 'DELETE', {}, asiris_api)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    editNote ({ state, dispatch, commit }, params = {}) {
      if (!params.caseId) params.caseId = state.currentCase
      return new Promise((resolve, reject) => {
        if (!params.caseId) reject(new Error('No case selected'))
        apiRequest(`/api/cases/${params.caseId}/notes/${params.noteId}`, 'PATCH', {}, asiris_api, params.text)
          .then(() => {
            commit('changeNoteText', params)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async deleteStoredObject ({ state, dispatch, commit }, params = {}) {
      if (!params.caseId) params.caseId = state.currentCase
      await apiRequest(`/api/cases/${params.caseId}/stored/${params.storedId}`, 'DELETE', {}, asiris_api)
      commit('deleteStoredObject', { caseId: params.caseId, storedId: params.storedId })
    },
    editStoredObject ({ state, dispatch, commit }, params = {}) {
      if (!params.caseId) params.caseId = state.currentCase
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${params.caseId}/stored/${params.storedId}`, 'PATCH', {}, asiris_api, params.text)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async addTagToCase ({ dispatch, commit }, { caseId, tag_name }) {
      try {
        const { data: { tag } } = await apiRequest(`/api/cases/${caseId}/tags`, 'POST', {}, asiris_api, { tag_name })
        commit('getCaseTags', { caseId, tags: tag })
        dispatch('getCaseById', caseId)
      } catch (err) {
        console.debug(err)
      }
    },
    addTagToMessage ({ state, commit }, { message, caseId, tag }) {
      const index = state.feed[caseId]
        ? state.feed[caseId].findIndex(el => '' + el.id === message.id)
        : -1
      if (index > -1) {
        commit('addTagToMessage', { index, caseId, tag })
      } else {
        message.tags = [ tag ]
        message.type = 'message'
        commit('setSavedMessages', { payload: message, caseId })
      }
    },
    addTagToArticle ({ state, commit }, { message, caseId, tag }) {
      const index = state.web[caseId]
        ? state.web[caseId].findIndex(el => '' + el.id === message.id)
        : -1
      if (index > -1) {
        commit('addTagToArticle', { index, caseId, tag })
      } else {
        message.tags = [ tag ]
        message.type = 'article'
        commit('setSavedMessages', { payload: message, caseId })
      }
    },
    async setCurrentCase ({ state, dispatch, commit }, caseId) {
      commit('setCurrentCase', caseId)
      commit('clearNotesAndStoredObjects')
      if (!caseId) return
      try {
        if (state.currentCase) { await dispatch('fetchNotes') }
      } catch (err) {
        console.debug(err)
      }
      try {
        if (state.currentCase) { await dispatch('fetchStoredObjects', { query: { short: true } }) }
      } catch (err) {
        console.debug(err)
      }
    },
    async deleteCaseTag ({ commit, dispatch }, { caseId, tag }) {
      try {
        await apiRequest(`/api/cases/${caseId}/tags/${tag}`, 'DELETE', {}, asiris_api)
        commit('deleteCaseTag', { caseId, tag })
      } catch (err) {
        throw (err)
      }
    },
    deleteCase ({ state, commit }, id) {
      if (!id) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${id}`, 'DELETE', {}, asiris_api)
          .then(() => {
            commit('deleteCase', id)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    editCase ({ dispatch, commit }, { id, caseData }) {
      if (!id) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${id}`, 'PATCH', {}, asiris_api, caseData)
          .then(() => {
            commit('changeCase', { id: id, caseInfo: caseData })
            resolve()
          })
          .catch((err) => reject(err))
      })
    },
    mergeProfiles ({ commit }, { accessor, donor }) {
      commit('startLoad', 'mergeProfiles')
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${accessor}/merge_with/${donor}`, 'POST', {}, asiris_api)
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
          .finally(() => commit('stopLoad', 'mergeProfiles'))
      })
    },
    async archiveCase ({ state, commit, dispatch, rootState }, caseData) {
      const archive = !caseData.is_archived
      if (!caseData) return
      await apiRequest(`/api/cases/${caseData.case_id}`, 'PATCH', {}, asiris_api, { is_archived: archive })
      if (state.currentCase === caseData.case_id) {
        await dispatch('setCurrentCase', null)
      }
      if (rootState.settings.defaultCase === caseData.case_id) {
        await dispatch('setDefaultCase', { defaultCase: null }, { root: true })
      }
      if (archive) commit('archiveCase', caseData)
      else commit('unarchiveCase', caseData)
      dispatch('fetchCases')
    },
    getProfiles ({ state, commit, dispatch }, params) {
      if (!params.caseId) params.caseId = state.currentCase
      if (!params.caseId) return new Error('caseId must be provided')
      const query = getQueryString(params.query, true)
      commit('startLoad', 'profile_list_' + params.caseId)
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${params.caseId}/profiles?` + query, 'GET', {}, asiris_api)
          .then((res) => {
            const profiles = res.data.profiles
            const total = res.data.count
            const tags = res.data.tags
            commit('getProfiles', { caseId: params.caseId, profiles: res.data })
            resolve([profiles, total, tags])
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('stopLoad', 'profile_list_' + params.caseId)
          })
      })
    },
    getProfilesByPhone ({ state, dispatch }, phone) {
      return new Promise((resolve, reject) => {
        if (!phone) return reject(new Error('Phone required'))
        phone = phone.replace('+', '%2B')
        apiRequest(`/api/profiles?phone=` + phone, 'GET', {}, asiris_api)
          .then(res => {
            const profiles = res.data.profiles
            resolve(profiles)
          })
          .catch(err => reject(err))
      })
    },
    getProfilesByEmail ({ state, dispatch }, email) {
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles?email=` + email, 'GET', {}, asiris_api)
          .then(res => {
            const profiles = res.data.profiles
            resolve(profiles)
          })
          .catch(err => reject(err))
      })
    },
    getProfileById ({ state, commit, dispatch }, profileId) {
      if (!state.profiles[profileId]) commit('startLoad', 'profile_' + profileId)
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/all`, 'GET', {}, asiris_api)
          .then((res) => {
            const profile = res.data.profile
            commit('getProfileById', profile)
            resolve(profile)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'profile_' + profileId))
      })
    },
    async addProfileNote ({ dispatch }, { text, profileId }) {
      await apiRequest(`/api/profiles/${profileId}/notes`, 'POST', {}, asiris_api, {
        note_text: text
      })
      await dispatch('getProfileById', profileId)
    },
    async deleteProfileNote ({ dispatch }, { profileId, noteId }) {
      await apiRequest(`/api/profiles/${profileId}/notes/${noteId}`, 'DELETE', {}, asiris_api)
      dispatch('getProfileById', profileId)
    },
    addPhoneToProfile ({ state, dispatch, commit }, { profileId, phoneNumber, ignoreProfileRefresh = false}) {
      phoneNumber = phoneNumber.replace(/\D/g, '')
      if (!phoneNumber) return
      phoneNumber = '+' + phoneNumber
      return new Promise((resolve, reject) => {
        if (!phoneNumber) reject(new Error('invalid phone'))
        apiRequest(`/api/profiles/${profileId}/phones`, 'POST', {}, asiris_api, { phone_number: phoneNumber })
          .then(data => {
            if (!ignoreProfileRefresh) {
              dispatch('getProfileById', profileId)
            }
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    recheckCase ({ state, dispatch, commit }, caseId) {
      if (!caseId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${caseId}/recheck`, 'POST', {}, asiris_api)
          .then(data => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async recheckProfile ({ state }, profileId) {
      try {
        const { data } = await apiRequest(`/api/profiles/${profileId}/recheck`, 'POST', {}, asiris_api)
        return data
      } catch (err) {
        throw err
      }
    },
    updateProfile ({ state, dispatch, commit }, { profileId, field, value }) {
      if (!value) return
      let obj = {}
      obj[field] = value
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}`, 'PATCH', {}, asiris_api, obj)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addImageToProfile ({ state, dispatch, commit }, { profileId, image, ignoreProfileRefresh = false }) {
      if (!image) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/images`, 'POST', {}, asiris_api, { path: image })
          .then(data => {
            if (!ignoreProfileRefresh) {
              dispatch('getProfileById', profileId)
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfile ({ state, dispatch, commit }, { profileId, caseId }) {
      if (!profileId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}`, 'DELETE', {}, asiris_api)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileEmail ({ state, dispatch, commit }, { profileId, emailId }) {
      if (!emailId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/emails/${emailId}`, 'DELETE', {}, asiris_api)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileName ({ state, dispatch, commit }, { profileId, nameId }) {
      if (!nameId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/names/${nameId}`, 'DELETE', {}, asiris_api)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async deleteProfileMessenger ({ dispatch }, { profileId, messengerId }) {
      if (!messengerId) return
      try {
        await apiRequest(`/api/profiles/${profileId}/messengers/${messengerId}`, 'DELETE', {}, asiris_api)
        await dispatch('getProfileById', profileId)
        return
      } catch (err) {
        throw err
      }
    },
    deleteProfileWallet ({ state, dispatch, commit }, {
      profileId,
      id
    }) {
      if (!profileId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/wallets/${id}`, 'DELETE', {}, asiris_api)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileOrganization ({ state, dispatch, commit }, { profileId, organizationId }) {
      if (!organizationId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/organizations/${organizationId}`, 'DELETE', {}, asiris_api)
          .then(() => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileGeo ({ state, dispatch, commit }, { profileId, geoId }) {
      if (!geoId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/geo/${geoId}`, 'DELETE', {}, asiris_api)
          .then(() => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileLink ({ state, dispatch, commit }, { profileId, linkId }) {
      if (!linkId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/links/${linkId}`, 'DELETE', {}, asiris_api)
          .then(() => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileProperty ({ state, dispatch, commit }, { profileId, propertyId }) {
      if (!propertyId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/properties/${propertyId}`, 'DELETE', {}, asiris_api)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfilePhone ({ state, dispatch, commit }, { profileId, phoneId }) {
      if (!phoneId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/phones/${phoneId}`, 'DELETE', {}, asiris_api)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteProfileImage ({ state, dispatch, commit }, { profileId, imageId }) {
      if (!imageId) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/images/${imageId}`, 'DELETE', {}, asiris_api)
          .then(data => {
            dispatch('getProfileById', profileId)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addOrganizationToProfile ({ state, dispatch, commit }, { profileId, name, link }) {
      if (!name) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/organizations`, 'POST', {}, asiris_api, { name: name })
          .then(data => {
            dispatch('getProfileById', profileId)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addGeoToProfile ({ state, dispatch, commit }, { profileId, geo }) {
      if (!geo) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/geo`, 'POST', {}, asiris_api, { geo })
          .then(data => {
            dispatch('getProfileById', profileId)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addLinkToProfile ({ state, dispatch, commit }, { profileId, link, ignoreProfileRefresh = false }) {
      if (!link) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/links`, 'POST', {}, asiris_api, { link: link })
          .then(data => {
            if (!ignoreProfileRefresh) {
              dispatch('getProfileById', profileId)
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addPropertyToProfile ({ state, dispatch, commit }, {
      profileId,
      propertyName,
      propertyValue,
      propertySection,
      auxiliaryData = '',
      ignoreProfileRefresh = false
    }) {
      if (!propertyValue) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/properties`, 'POST', {}, asiris_api, { property_name: propertyName, property_value: propertyValue, property_section: propertySection, auxiliary_data: auxiliaryData })
          .then(data => {
            if (!ignoreProfileRefresh) {
              dispatch('getProfileById', profileId)
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    approveProperty ({ state, dispatch, commit }, {
      propertyName,
      profileId,
      propertyId,
      isVerified
    }) {
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/${propertyName}/${propertyId}`,
          'PATCH', {},
          asiris_api, {
            is_verified: isVerified
          })
          .then(() => {
            dispatch('getProfileById', profileId)
              .then(() => resolve())
              .catch(err => console.debug(err))
          })
          .catch((err) => reject(err))
      })
    },
    async addWalletToProfile ({ state, dispatch, commit }, { profileId, walletType, walletAddress }) {
      if (!walletAddress) throw new Error('wallet required')
      await apiRequest(`/api/profiles/${profileId}/wallets`, 'POST', {}, asiris_api, { wallet_type: walletType, wallet_address: walletAddress })
      return dispatch('getProfileById', profileId)
    },
    async addEmailToProfile ({ state, dispatch, commit }, { profileId, email, ignoreProfileRefresh = false }) {
      if (!email) throw new Error('Email required')
      await apiRequest(`/api/profiles/${profileId}/emails`, 'POST', {}, asiris_api, { email })
      if (!ignoreProfileRefresh) {
        dispatch('getProfileById', profileId)
      }
    },
    addNameToProfile ({ state, dispatch, commit }, { profileId, name, ignoreProfileRefresh = false }) {
      if (!name) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/names`, 'POST', {}, asiris_api, { name: name, is_nickname: 0 })
          .then(data => {
            if (!ignoreProfileRefresh) {
              dispatch('getProfileById', profileId)
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addNickNameToProfile ({ state, dispatch, commit }, { profileId, name, ignoreProfileRefresh = false }) {
      if (!name) return
      return new Promise((resolve, reject) => {
        apiRequest(`/api/profiles/${profileId}/names`, 'POST', {}, asiris_api, { name: name, is_nickname: 1 })
          .then(data => {
            if (!ignoreProfileRefresh) {
              dispatch('getProfileById', profileId)
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    profileAdd ({ state, dispatch, commit, rootState }, { data = { seed_data: '' }, caseId, user }) {
      return new Promise(async (resolve, reject) => {
        if (!data.seed_data && !user) return reject(new Error('data or user id required'))
        if (user) {
          try {
            var userData = rootState[user.provider].users[user.id]
            if (!userData) userData = await dispatch(user.provider + '/fetchUserById', user.id, { root: true })
            if (userData.phone) {
              data.phone = userData.phone
            } else if (userData.has_phone) {
              data.phone = await dispatch(user.provider + '/fetchUserPhoneById', user.id, { root: true })
            }
          } catch (err) {
            console.debug(err)
          }
          if (user.type === 'messenger' && user.provider === 'tgcp') {
            data.messenger = {
              messenger_type: 'telegram',
              messenger_user_name: userData?.username,
              messenger_user_id: userData?.tg_id,
              auxiliary_data: { ...userData, avatar: userData?.avatar && userData?.avatar?.file_location }
            }
            data.avatar = userData?.avatar && userData?.avatar?.file_location
            data.name = (userData?.first_name || '' + ' ' + userData?.last_name || '').trim()
          }
        }
        if (!data.seed_data) {
          try {
            data.seed_data = data.phone || userData.username || (user && `${user.provider}: #${user.id}`)
          } catch {
            return reject(new Error('Seed data reuired'))
          }
        }

        commit('startLoad', 'profileAdd')
        apiRequest(`/api/cases/${caseId}/profiles`, 'POST', {}, asiris_api, data)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('stopLoad', 'profileAdd'))
      })
    },
    getAccessLevel ({ rootState }, caseId) {
      return new Promise((resolve, reject) => {
        if (!caseId) reject(new Error('case id required'))
        const email = rootState.user_name
        apiRequest(`/api/acl/${caseId}/user`, 'GET', {}, asiris_api, { email: email })
          .then((accessLevel) => resolve(accessLevel))
          .catch(err => reject(err))
      })
    },
    getAllCaseUsers ({ commit }, caseId) {
      return new Promise((resolve, reject) => {
        if (!caseId) reject(new Error('case id required'))
        apiRequest(`/api/acl/${caseId}/`, 'GET', {}, asiris_api)
          .then(res => {
            let users = res.data.users
            commit('getAllCaseUsers', users)
            resolve(users)
          })
          .catch(err => reject(err))
      })
    },
    async getCaseById ({ commit, state }, caseId) {
      if (state.cases[caseId]) return state.cases[caseId]
      const { data: { data } } = await apiRequest(`/api/cases/${caseId}`, 'GET', {}, asiris_api)
      commit('getCaseById', data)
      return data
    },
    async getCasesTags ({ commit }) {
      const { data: { tags } } = await apiRequest(`/api/cases/tags`, 'GET', {}, asiris_api)
      commit('getCasesTags', tags)
    },
    async getCaseTags ({ commit, state, dispatch }, caseId) {
      try {
        if (!state.cases[caseId]) dispatch('getCaseById', caseId)
        const { data: { tags } } = await apiRequest(`/api/cases/${caseId}/tags`, 'GET', {}, asiris_api)
        commit('getCaseTags', { caseId, tags })
        return tags
      } catch (err) {
        throw err
      }
    },
    async addProfileTag ({ commit, state }, [caseId, profileId, tagName]) {
      if (!tagName) throw new Error('tag required')
      const { status, data: { tag } } = await apiRequest(`/api/cases/${caseId}/profiles/${profileId}/tags/`, 'POST', {}, asiris_api, { tag_name: tagName })
      tag.profile_id = profileId
      if (status === 201) {
        if (state.profiles.hasOwnProperty(tag.profile_id)) {
          commit('addProfileTag', tag)
        } else {
          commit('initProfileTag', tag)
        }
      }
      commit('getCaseTags', { caseId, tags: tag })
      return tag
    },
    async deleteProfileTag ({ commit, dispatch }, [profileId, tag]) {
      if (!tag) throw new Error('tag required')
      const res = await apiRequest(`/api/profiles/${profileId}/tags/${tag.tag_id}`, 'DELETE', {}, asiris_api)
      dispatch('getProfileById', profileId)
      return res
    },
    confirmInvintation ({ dispatch, rootState }, code) {
      return new Promise((resolve, reject) => {
        if (!code) reject(new Error('code required'))
        apiRequest(`/api/acl/verify-code`, 'POST', {}, asiris_api, { code: code })
          .then(() => {
            dispatch('fetchCases')
              .then(() => resolve())
          })
          .catch(err => reject(err))
      })
    },
    sendInvintation ({ state }, { email, caseId, accessLevel }) {
      return new Promise(async (resolve, reject) => {
        if (!email || !caseId) reject(new Error('email and case id required'))
        try {
          const { data } = await apiRequest(`/api/acl/${caseId}/`, 'POST', {}, asiris_api, { email: email, access_level: accessLevel })
          resolve(data) 
        }
        catch (err) {
          reject(err)
        }
      })
    },
    changeUserPermissions ({ state }, { userId, caseId, accessLevel }) {
      return new Promise((resolve, reject) => {
        apiRequest(`/api/acl/${caseId}/`, 'PATCH', {}, asiris_api, { user_id: userId, access_level: accessLevel })
          .then(() => {
            resolve()
          })
          .catch(err => reject(err))
      })
    },
    deleteUserFromCase ({ dispatch }, { userId, caseId, email }) {
      return apiRequest(`/api/acl/${caseId}/`, 'DELETE', {}, asiris_api, { user_id: userId, email })
    },
    addParamToQuery: (state, param) => {
      const oldQuery = router.currentRoute.value.query
      if (!(Object.keys(oldQuery).every((key) =>  String(oldQuery[key]) === String(param[key] ?? ''))
        && Object.keys(param).every((key) =>  String(param[key]) === String(oldQuery[key] ?? ''))))
        router.replace({ query: param, hash: router.currentRoute.value.hash })
    },
    fetchAllTriggers ({ commit }, { caseId, query }) {
      commit('startLoad', 'triggers')
      query = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${caseId}/triggers/?${query}`, 'GET', {}, asiris_api)
          .then(res => resolve(res.data.data))
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'triggers'))
      })
    },
    async triggerClearResults ({ commit }, id) {
      try {
        await apiRequest(`/web/notifies/${id}/clean`, 'GET', {}, fetcher_proxy)
      } catch (err) {
        throw err
      }
    },
    editTrigger: ({ commit }, { caseId, trigger }) => apiRequest(`/api/cases/${caseId}/triggers/${trigger.trigger_id}`, 'PATCH', {}, asiris_api, trigger)
      .then(({ data: { trigger } }) => trigger).finally(() => commit('fetcher/clearTrigger', trigger.trigger_id, { root: true })),
    deleteTrigger: ({ commit }, trigger) => apiRequest(`/api/cases/${trigger.case_id}/triggers/${trigger.trigger_id}`, 'DELETE', {}, asiris_api),
    getTriggerById ({ commit }, { caseId, triggerId }) {
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${caseId}/triggers/${triggerId}`)
          .then(res => resolve(res.data))
          .catch(err => reject(err))
      })
    },
    addTrigger ({ commit }, { caseId, trigger }) {
      commit('startLoad', 'triggerAdd')
      return new Promise((resolve, reject) => {
        apiRequest(`/api/cases/${caseId}/triggers/`, 'POST', {}, asiris_api, trigger)
          .then(res => resolve(res.data))
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'triggerAdd'))
      })
    },
    getStates ({ commit, state }, phone) {
      return new Promise((resolve, reject) => {
        phone = phone.replace('+', '')
        if (state.monitor['+' + phone] && state.monitor['+' + phone].exists === false) {
          return reject(new Error('No data for phone'))
        }
        commit('startLoad', 'states')
        return apiRequest('get_target_states?phone_number=' + phone + '&format=json', 'GET', {}, monitoring)
          .then(res => {
            var data = res.data
            commit('getStates', data)
            return resolve(data)
          })
          .catch(err => {
            if (err.response && err.response.status === 404) {
              var data = {
                phone_number: '+' + phone,
                exists: false
              }
              commit('getStates', data)
              return resolve()
            } else {
              return reject(err)
            }
          })
          .finally(() => commit('stopLoad', 'states'))
      })
    },
    getPhonesToMonitor ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'phones_monitor_services')
        apiRequest('/api/services/phone_monitor', 'GET', {}, asiris_api)
          .then(res => {
            var phones = res.data.phones
            commit('addPhones', phones)
            resolve(phones)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'phones_monitor_services'))
      })
    },
    async addPhoneToMonitor ({ dispatch }, { phone, description = null }) {
      phone = String(phone)
      if (!phone) throw new Error('Invalid phone')
      try {
        const { status } = await apiRequest('/api/services/phone_monitor', 'POST', {}, asiris_api, { phone_number: phone, description })
        switch (status) {
          case 201:
            notify({ group: 'general', text: 'Phone was added', type: 'success' })
            break
          case 200:
            notify({ group: 'general', text: 'Phone already exists', type: 'warn' })
            break
        }
      } catch (err) {
        notify({ group: 'general', title: 'Unable to add phone', type: 'error' })
        throw err
      }
      try {
        await dispatch('osint/addMonitorTarget', phone, { root: true })
      } catch {}

      return phone
    },
    async editDescriptionPhoneMonitor ({ commit }, { phone_number, description }) {
      phone_number = String(phone_number)
      if (!phone_number) throw new Error('Invalid phone')
      try {
        const { data } = await apiRequest('/api/services/phone_monitor/' + phone_number, 'PATCH', {}, asiris_api, { description })
        commit('addPhones', data.phone)
      } catch (err) {
        notify({ group: 'general', title: 'Unable to edit phone description', type: 'error' })
      }
    },
    removePhoneFromMonitor ({ commit }, phone) {
      return new Promise((resolve, reject) => {
        phone = String(phone)
        if (!phone) reject(new Error('Invalid phone'))
        apiRequest('/api/services/phone_monitor/' + phone, 'DELETE', {}, asiris_api, { phone_number: phone })
          .then(() => {
            resolve(phone)
          })
          .catch(err => reject(err))
      })
    },
    async addReport ({ commit }, { caseId, newReport, method }) {
      try {
        const { data } = await apiRequest(`/api/cases/${caseId}/reports/${newReport.report_id || ''}`, method, {}, asiris_api, newReport)
        if (method === 'POST') {
          commit('addReport', data.report)
        } else {
          commit('editReport', data.newReport)
        }
        return data.report || data.newReport
      } catch (err) {
        console.dir(err)
        throw err
      }
    },
    async getReports ({ commit }, caseId) {
      try {
        const { data: { reports } } = await apiRequest(`/api/cases/${caseId}/reports/`, 'GET', {}, asiris_api)
        commit('addBulkReport', { reports, caseId })
      } catch (err) {
        console.dir(err)
        throw err
      }
    },
    async editReport ({ commit }, report) {
      try {
        const { data } = await apiRequest(`/api/cases/${report.case_id}/reports/${report.report_id}`, 'PATCH', {}, asiris_api, report)
        commit('editReport', data.newReport)
        return data.newReport
      } catch (err) {
        throw (err)
      }
    },
    async deleteReport ({ commit }, report) {
      try {
        await apiRequest(`/api/cases/${report.case_id}/reports/${report.report_id}`, 'DELETE', {}, asiris_api)
        commit('deleteReport', report)
      } catch (err) {
        throw err
      }
    },
    async getReport ({ commit }, { query, caseId }) {
      try {
        let { data } = await apiFileRequest(`/reports/${caseId}?${getQueryString(query)}`, 'GET', 'arraybuffer', tools)
        const fileURL = window.URL.createObjectURL(new Blob([data], { type: `application/${query.format}` }))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `report-${caseId}.${query.format}`)
        document.body.appendChild(fileLink)
        fileLink.click()
      } catch (err) {
        console.dir(err)
      }
    },
    async getFeedExport ({ state, rootState }, options) {
      const {
        lang,
        caseId,
        type = 'feed',
        format = 'pdf'
      } = options
      const caseData = state[type][caseId]

      if (!caseData) {
        throw new Error('nothing to export')
      }

      const feedHandler = (message) => ({
        provider: 'telegram',
        message_id: message.raw.message_id,
        chat_id: message.raw.chat_id,
        type: 'message',
        tags: message.tags
      })

      const webHandler = (message) => ({
        provider: 'web',
        article_id: message.id,
        source_id: message.source_id,
        type: 'article',
        tags: message.tags
      })

      const messageHandler = type === 'feed' ? feedHandler : webHandler

      const messages = caseData.map(messageHandler)

      const queryString = getQueryString({
        lang,
        format,
        timezone: rootState.settings.timezone
      })

      const response = await apiFileRequest(
        `/feed_export?${queryString}`,
        'POST',
        'arraybuffer',
        tools,
        {},
        messages
      )

      const blob = new Blob([response.data], {
        type: `application/${format}`
      })
      const content = URL.createObjectURL(blob)
      const linkElement = document.createElement('a')
      linkElement.download = `messages-export.${format}`
      linkElement.href = content
      linkElement.click()

      URL.revokeObjectURL(content)
      linkElement.remove()
    },
    async mergeCases ({ commit }, { caseId, params }) {
      try {
        const { data } = await apiRequest(`/api/cases/${caseId}/copy/`, 'POST', {}, asiris_api, params)
        return data.newCase
      } catch (err) {
        throw err
      }
    },
    async resolveAsGeneralUser ({ state, commit, rootState }, { id, provider }) {
      if (!id) throw new Error('Empty id')
      if (!provider) throw new Error('Empty provider')
      if (state.generalUsersCache[id]) return state.generalUsersCache[id]
      const response = await apiRequest(`/id_lookup_services/${provider}/?search=${id}`, 'GET',{}, asiris_api)
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        commit('setGeneralUserToCache', { id, value: response.data.data[0]})
        return response.data.data[0]
      } else {
        return null
      }
    },
    async getStoredObjectsFilters ({ state, commit}, caseId) {
      const response = await apiRequest(`/api/cases/${caseId}/stored/types`, 'GET',{}, asiris_api)
      if (response && response.data && response.data.types) {
        return response.data.types
      } else {
        return null
      }
    }
  }
}
